import { Component, OnInit,AfterContentInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from "@angular/router";

import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';

import { ClientServiceService } from "../services/client-service.service";
import {ConsultantCrmService} from '../services/consultantcrm.service'
import { DatePipe } from "@angular/common";
import {Title} from "@angular/platform-browser";
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { TokenStorageService } from '../services/token-storage.service';
import { SpecBesoin } from '../Model/spcbesoin.model';
import { BASE_URL, CRM_BACK_ENDPOINT } from '../config';

@Component({

  selector: 'app-pagebesoin',
  templateUrl: './pagebesoin.component.html',
  styleUrls: ['./pagebesoin.component.scss']
})
export class PagebesoinComponent implements OnInit {
  sub:any;
	nomClient='';
	nomContact='';
  besoin='';
  id=""
  trig: String='';
  constructor(
    private dialog: MatDialog,

    private router: Router,
    private token: TokenStorageService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private titleService:Title,
    private consultant : ConsultantCrmService,
    private serviceclient:ClientServiceService
    ) { }
  message1: String = "Besoin";
  message: String = "";
  bool = true;
  cv:any
  idbesoin:String
  clientId = ""
  contactId=""
  consultantnamedata=""
  datesend=""
  prenomcontact=""
  datedata=""
  date=""
  ao=""
  gestionnairerh=""
  ic=""
  commercial=""
  obsdata=""
  consultantname=""
  consultantname2=""
  statut=""
  retenudata=null
  data:any
  CvData:SpecBesoin[] = []


  ngOnInit() {

    this.sub = this.route
    .queryParams
    .subscribe(params => {
      this.idbesoin=params.besoin;
      this.consultant.getallCV(this.idbesoin).subscribe((data)=>{

        if(data){
          this.CvData=data;

        }
      })
      this.consultant.getbesoinbyid(this.idbesoin).subscribe((data)=>{
        if(data!==null){
          console.log(data)
          this.besoin=data.description
          this.statut=data.statut
          this.gestionnairerh = data.trigGestionnaireRH
          this.date = data.dateSaisie
          this.ao = data.ao.nomFichier
          this.ic = data.indiceDeConfiance===null ? 'Faible' :data.indiceDeConfiance
          this.commercial = data.trigBesoin
          window.localStorage.setItem("contact",data.idContact);
          window.localStorage.setItem("client",data.idClient);
          window.localStorage.setItem("idbesoin",params.besoin);

          this.serviceclient.FindById(data.idClient).subscribe((data) => {

            this.nomClient = data.nom;
        this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)

          });

          this.serviceclient.FindCotactById(data.idContact).subscribe((data) => {
            this.nomContact = data.nom + " "+ data.prenom
        this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)

          });
        }
        this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)
      })

      sessionStorage.setItem('Besoin',this.besoin);
      sessionStorage.setItem('Nomclient',this.nomClient);
      sessionStorage.setItem('NomContact',this.nomContact);
    });


  }

  proncate_in(event,title){

    // let k = document.getElementById(event.path[0].id)
    // if(k.scrollWidth > k.clientWidth){
    //   k.style.backgroundColor = "#ffe268"
    //   k.setAttribute('title',title)
    // }
    // else{
    //   if(k.hasAttribute('title')){
    //     k.removeAttribute('title')
    //   }
    // }

  }
  proncate_out(event){
    // let k = document.getElementById(event.path[0].id)
    // if(k.scrollWidth > k.clientWidth){
    //   k.style.backgroundColor = "transparent"

    // }
  }
  navigate(){
    this.router.navigate(["rechmcrhcrm"], { queryParams: {besoin:this.idbesoin}});

  }


visualiser(fileURL){
  //

  this.Loader()
  this.consultant.verifyCV(fileURL).subscribe(data=>{
   if(data===1){
     this.dialog.closeAll()
     window.open(CRM_BACK_ENDPOINT+"/downloadFile/" + fileURL,"_blank");
   }else{
    this.dialog.closeAll()
    this.OpenPopupAlertMode("Le CV est absent");
   }
  })
}

Loader(){
  this.dialog.open(ProgressSpinnerDialogComponent, {
    data: {

      message: `
       Chargement en Cours`

    },
    disableClose: true,
  });
}
OpenPopupAlertMode(text){
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message:text,
    },
  });
}

  consultAO() {

    window.open(CRM_BACK_ENDPOINT+"/downloadFile/" + this.ao,"_blank");

  }
  consultCV(cv) {

    window.open(CRM_BACK_ENDPOINT+"/downloadFile/" + cv,"_blank");
  }
  updateBesoin(id,input,type){
    this.CvData.map((data)=>{
      if(data._id==id){
        data[type]=input
      }
    })
    this.consultant.validate(this.CvData,this.idbesoin).subscribe((data)=>{

    })
  }
 valider(){


   this.consultant.validate(this.CvData,this.idbesoin).subscribe((data)=>{
     if(data){

     this.router.navigate(["/RechercheBesoin", window.localStorage.getItem("client"),window.localStorage.getItem("contact")]);

     }
   })
 }
 cancel(){

 }
 deleteCv(id,input,type,name){
  this.updateBesoin(id,input,type)
  this.updateBesoin(id,input,"cheminCV")
  this.consultant.validate(this.CvData,this.idbesoin).subscribe((data)=>{

  })
  this.consultant.deteletecv(name).subscribe((data)=>{})
  this.consultant.getallCV(this.idbesoin).subscribe((data)=>{
    if(data){
     this.ngOnInit()

    }
  })
 }
 Select(row){

 window.open(BASE_URL+"/fichec?id="+row.idConsultant,"_blank")

}
 ChangeCv(event) {

  this.cv = event.target.files[0];
  const data: FormData = new FormData();
  data.append("cv", this.cv);
  this.updateBesoin(this.id,this.cv.name,"nomCV")
  this.consultant.cv(data).subscribe((data) => {


  });
}
setid(id){
  this.id = id._id
}
voir(e){

}
deleteconsultants(item){
  this.consultant.deleteconsultants(item._id).subscribe((data)=>{
    this.consultant.getallCV(this.idbesoin).subscribe((data)=>{
      if(data){
        this.CvData=data;
      }
      this.CvData=data;
    })
  })
  this.consultant.deteletecv(item.nomCV).subscribe((data)=>{
    this.consultant.getallCV(this.idbesoin).subscribe((data)=>{
      if(data){
        this.CvData=data;
      }
      this.CvData=data;
    })
  })

}


Annuler(){

  if(window.localStorage.getItem(this.token.getUser()['trigramme'])){
    let data = JSON.parse(window.localStorage.getItem(this.token.getUser()['trigramme'])).data.map((data)=>{
      this.deleteconsultants(data)
    })
     this.router.navigate(["/RechercheBesoin"]);


  }else{
     this.router.navigate(["/RechercheBesoin"]);

  }
}

AddConsultant(){

  if(this.consultantnamedata.trim()!==""){
    let data = {
      "idConsultant" : "",
      "nomConsultant" : this.consultantnamedata,
      "prenomConsultant" : " ",
      "nomCV" : this.cv ?  this.cv.name :  '',
      "dateEnvoiCV" : this.datedata ?  this.datePipe.transform(
        this.datedata,
        "yyyy-MM-dd"
      ) : "",
      "observation" : this.obsdata,
      "retenu":this.retenudata,
      "idbesoin" : this.idbesoin,
    }
    this.consultantnamedata=""
    this.datedata=""
    this.obsdata=''
    this.consultant.addconsultanttoselction(data).subscribe((data)=>{

      if(data){
        this.consultantnamedata=""
        this.datedata=""
        this.obsdata=''
        this.ngOnInit()

      }
    })
  }else{
    this.Alert("Veuillez entrer le nom du consultant")
  }

  this.consultantnamedata=""
  this.datedata=""
  this.obsdata=''
}




Alert(msg) {
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message: msg,
      buttonText: {
        cancel: 'OK'
      }
    },
  });
  dialogRef.afterClosed().subscribe(result => {
  });
}

}
