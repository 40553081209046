import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { TokenStorageService } from '../token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
      import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean |
          import("@angular/router").UrlTree> {

      let role = this.tokenStorage.getRole();
      if (role == "Facturation") {
          return true;
      } else {
          this.tokenStorageService.signOut();
          this.router.navigate(["login"]);
          return false;
      }
  }

  constructor(private tokenStorage: AuthService, private router: Router, private tokenStorageService: TokenStorageService) {

  }

}
