import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { Chart } from 'chart.js';
import { backEndpoint } from '../../config';
import { DashboardService } from '../services/dashboard.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json'
  })
};

const API_URL = backEndpoint+"/api/drh/";

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit, AfterViewInit{

  constructor(private dservice: DashboardService, private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal, private tokenService : TokenStorageService) { }

  @Input() selections: any;
  @Input() consultantData: any;

  trigramme: string = "";
  isAdmin: boolean = false;

  ngOnInit(): void {
    const user = this.tokenService.getUser();
    this.trigramme = user.trigramme;
    this.isAdmin = user.roles.lastIndexOf('Gestionnaire')>0 || user.roles.lastIndexOf('Facturation')>0;

    this.trigrammes =  this.selections.trigrammes
    this.tagfoncs = this.selections.tagfoncs
    this.tagmets = this.selections.tagmets
    this.tagtechs = this.selections.tagtechs

    if(this.isAdmin){
      this.dservice.getConsultants().subscribe(
        (data: any) => {
          this.consultantData = data;
          this.filtrerQualiteCv();
          this.filtrerTop5Tags();
          this.isLoading = false;
          this.loadChanged.emit(this.isLoading);

        }
      )
    } else {
      this.trigQualiteCv = this.trigramme;
      this.trigTop5Tag = this.trigramme;
      this.filtrerQualiteCv();
      this.filtrerTop5Tags();
      this.isLoading = false;
      this.loadChanged.emit(this.isLoading);
    }

  }

  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();

  myChart : any;
  top5: any;

  trigrammes = []
  tagtechs = []
  tagfoncs = []
  tagmets = []

  @ViewChild('qualiteCv') qualiteCvCanvas!: ElementRef;
  @ViewChild('qualiteCvContainer') qualiteCvContainer!: ElementRef;

  periodeQualiteCv = "semaine";
  trigQualiteCv = "Tous";

  secteurQualiteCv = null;
  fonctionQualiteCv = null;
  technoQualiteCv = null;

  data: number[] = []

  setPeriodeQualiteCv() : void {
    this.filtrerQualiteCv()
  }

  setTrigQualiteCv () : void {
    this.filtrerQualiteCv()
  }

  setSecteurQualiteCv (): void {
    this.filtrerQualiteCv()
  }

  setFonctionQualiteCv (): void {
    this.filtrerQualiteCv()
  }

  setTechnoQualiteCv (): void {
    this.filtrerQualiteCv()
  }

  filtrerQualiteCv(): void  {

    this.data = [0,0,0,0]
    let result0: any[] = []
    let result1: any[] = []
    let result2: any[] = []
    let result: any[] = []

    if (this.periodeQualiteCv === "semaine") {

      let today: Date = new Date();

      if (this.trigQualiteCv === "Tous") {

        // CHECK DATE

        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
            result0.push(element);
          }
        });

        // CHECK TAG METIER

        if (this.secteurQualiteCv === null) {
          result1 = result0;
        } else {
          result0.forEach((element: any) => {
            element.tagmetier.forEach((elt: any) => {
              if (elt.libelle === this.secteurQualiteCv) {
                result1.push(element);
              }
            });
          });
        }

        // CHECK TAG FONCTION

        if (this.fonctionQualiteCv === null) {
          result2 = result1;
        } else {
          result1.forEach((element: any) => {
            element.tagfonc.forEach((elt: any) => {
              if (elt.libelle === this.fonctionQualiteCv) {
                result2.push(element);
              }
            });
          });
        }


        // CHECK TAG TECHNIQUES

        if (this.technoQualiteCv === null) {
          result = result2;
        } else {
          result2.forEach((element: any) => {
            element.tagtech.forEach((elt: any) => {
              if (elt.libelle === this.technoQualiteCv) {
                result.push(element);
              }
            });
          });
        }

        // GENERATE DATA
        result.forEach(element => {
          if (element.qualiteCv.libelle === "Jouable") {
            this.data[0] += 1;
          }
          if (element.qualiteCv.libelle === "Neutre") {
            this.data[1] += 1;
          }
          if (element.qualiteCv.libelle === "Moyen") {
            this.data[2] += 1;
          }
          if (element.qualiteCv.libelle === "Injouable") {
            this.data[3] += 1;
          }
        });

        // REDRAW THE CHART

        this.qualiteCvCanvas.nativeElement.remove();
        this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

        this.myChart = new Chart("cvChart", {
          type: 'doughnut',
          data: {
              labels: [
                'Jouable',
                'Neutre',
                'Moyen',
                'Injouable'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: this.data,
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',

                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      } else {
        this.dservice.getUserConsultants(this.trigQualiteCv).subscribe(
          (data: any) => {

            // CHECK DATE

            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
                result0.push(element);
              }
            });

            // CHECK TAG METIER

            if (this.secteurQualiteCv === null) {
              result1 = result0;
            } else {
              result0.forEach((element: any) => {
                element.tagmetier.forEach((elt: any) => {
                  if (elt.libelle === this.secteurQualiteCv) {
                    result1.push(element);
                  }
                });
              });
            }

            // CHECK TAG FONCTION

            if (this.fonctionQualiteCv === null) {
              result2 = result1;
            } else {
              result1.forEach((element: any) => {
                element.tagfonc.forEach((elt: any) => {
                  if (elt.libelle === this.fonctionQualiteCv) {
                    result2.push(element);
                  }
                });
              });
            }


            // CHECK TAG TECHNIQUES

            if (this.technoQualiteCv === null) {
              result = result2;
            } else {
              result2.forEach((element: any) => {
                element.tagtech.forEach((elt: any) => {
                  if (elt.libelle === this.technoQualiteCv) {
                    result.push(element);
                  }
                });
              });
            }

            // GENERATE DATA
            result.forEach(element => {
              if (element.qualiteCv.libelle === "Jouable") {
                this.data[0] += 1;
              }
              if (element.qualiteCv.libelle === "Neutre") {
                this.data[1] += 1;
              }
              if (element.qualiteCv.libelle === "Moyen") {
                this.data[2] += 1;
              }
              if (element.qualiteCv.libelle === "Injouable") {
                this.data[3] += 1;
              }
            });

            this.qualiteCvCanvas.nativeElement.remove();
            this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

            this.myChart = new Chart("cvChart", {
              type: 'doughnut',
              data: {
                  labels: [
                    'Jouable',
                    'Neutre',
                    'Moyen',
                    'Injouable'
                  ],
                  datasets: [{
                    label: 'My First Dataset',
                    data: this.data,
                    backgroundColor: [
                      'rgb(3, 153, 26)',
                      'rgb(48, 44, 102)',
                      'rgb(252, 186, 3)',
                      'rgb(252, 3, 3)'
                    ],
                  }]
                },
              options: {
                  cutoutPercentage: 60,
                  responsive: false,
                  legend: {
                      position: 'right',

                      labels: {
                          boxWidth: 20,
                          padding: 10
                      }
                  }
              }
            });
          }
        )
      }



    }

    if (this.periodeQualiteCv === "mois") {

      let today: Date = new Date();

      if (this.trigQualiteCv === "Tous") {

        // CHECK DATE

        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
            result0.push(element);
          }
        });

        // CHECK TAG METIER

        if (this.secteurQualiteCv === null) {
          result1 = result0;
        } else {
          result0.forEach((element: any) => {
            element.tagmetier.forEach((elt: any) => {
              if (elt.libelle === this.secteurQualiteCv) {
                result1.push(element);
              }
            });
          });
        }

        // CHECK TAG FONCTION

        if (this.fonctionQualiteCv === null) {
          result2 = result1;
        } else {
          result1.forEach((element: any) => {
            element.tagfonc.forEach((elt: any) => {
              if (elt.libelle === this.fonctionQualiteCv) {
                result2.push(element);
              }
            });
          });
        }


        // CHECK TAG TECHNIQUES

        if (this.technoQualiteCv === null) {
          result = result2;
        } else {
          result2.forEach((element: any) => {
            element.tagtech.forEach((elt: any) => {
              if (elt.libelle === this.technoQualiteCv) {
                result.push(element);
              }
            });
          });
        }

        // GENERATE DATA
        result.forEach(element => {
          if (element.qualiteCv.libelle === "Jouable") {
            this.data[0] += 1;
          }
          if (element.qualiteCv.libelle === "Neutre") {
            this.data[1] += 1;
          }
          if (element.qualiteCv.libelle === "Moyen") {
            this.data[2] += 1;
          }
          if (element.qualiteCv.libelle === "Injouable") {
            this.data[3] += 1;
          }
        });

        // REDRAW THE CHART

        this.qualiteCvCanvas.nativeElement.remove();
        this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

        this.myChart = new Chart("cvChart", {
          type: 'doughnut',
          data: {
              labels: [
                'Jouable',
                'Neutre',
                'Moyen',
                'Injouable'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: this.data,
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',

                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      } else {
        this.dservice.getUserConsultants(this.trigQualiteCv).subscribe(
          (data: any) => {
            // CHECK DATE

            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
                result0.push(element);
              }
            });

            // CHECK TAG METIER

            if (this.secteurQualiteCv === null) {
              result1 = result0;
            } else {
              result0.forEach((element: any) => {
                element.tagmetier.forEach((elt: any) => {
                  if (elt.libelle === this.secteurQualiteCv) {
                    result1.push(element);
                  }
                });
              });
            }

            // CHECK TAG FONCTION

            if (this.fonctionQualiteCv === null) {
              result2 = result1;
            } else {
              result1.forEach((element: any) => {
                element.tagfonc.forEach((elt: any) => {
                  if (elt.libelle === this.fonctionQualiteCv) {
                    result2.push(element);
                  }
                });
              });
            }


            // CHECK TAG TECHNIQUES

            if (this.technoQualiteCv === null) {
              result = result2;
            } else {
              result2.forEach((element: any) => {
                element.tagtech.forEach((elt: any) => {
                  if (elt.libelle === this.technoQualiteCv) {
                    result.push(element);
                  }
                });
              });
            }

            // GENERATE DATA
            result.forEach(element => {
              if (element.qualiteCv.libelle === "Jouable") {
                this.data[0] += 1;
              }
              if (element.qualiteCv.libelle === "Neutre") {
                this.data[1] += 1;
              }
              if (element.qualiteCv.libelle === "Moyen") {
                this.data[2] += 1;
              }
              if (element.qualiteCv.libelle === "Injouable") {
                this.data[3] += 1;
              }
            });

            // REDRAW THE CHART

            this.qualiteCvCanvas.nativeElement.remove();
            this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

            this.myChart = new Chart("cvChart", {
              type: 'doughnut',
              data: {
                  labels: [
                    'Jouable',
                    'Neutre',
                    'Moyen',
                    'Injouable'
                  ],
                  datasets: [{
                    label: 'My First Dataset',
                    data: this.data,
                    backgroundColor: [
                      'rgb(3, 153, 26)',
                      'rgb(48, 44, 102)',
                      'rgb(252, 186, 3)',
                      'rgb(252, 3, 3)'
                    ],
                  }]
                },
              options: {
                  cutoutPercentage: 60,
                  responsive: false,
                  legend: {
                      position: 'right',

                      labels: {
                          boxWidth: 20,
                          padding: 10
                      }
                  }
              }
            });
          }
        )
      }

    }

    if (this.periodeQualiteCv === "semestre") {

      let today: Date = new Date();

      if (this.trigQualiteCv === "Tous") {

        // CHECK DATE

        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
            result0.push(element);
          }
        });

        // CHECK TAG METIER

        if (this.secteurQualiteCv === null) {
          result1 = result0;
        } else {
          result0.forEach((element: any) => {
            element.tagmetier.forEach((elt: any) => {
              if (elt.libelle === this.secteurQualiteCv) {
                result1.push(element);
              }
            });
          });
        }

        // CHECK TAG FONCTION

        if (this.fonctionQualiteCv === null) {
          result2 = result1;
        } else {
          result1.forEach((element: any) => {
            element.tagfonc.forEach((elt: any) => {
              if (elt.libelle === this.fonctionQualiteCv) {
                result2.push(element);
              }
            });
          });
        }


        // CHECK TAG TECHNIQUES

        if (this.technoQualiteCv === null) {
          result = result2;
        } else {
          result2.forEach((element: any) => {
            element.tagtech.forEach((elt: any) => {
              if (elt.libelle === this.technoQualiteCv) {
                result.push(element);
              }
            });
          });
        }

        // GENERATE DATA
        result.forEach(element => {
          if (element.qualiteCv.libelle === "Jouable") {
            this.data[0] += 1;
          }
          if (element.qualiteCv.libelle === "Neutre") {
            this.data[1] += 1;
          }
          if (element.qualiteCv.libelle === "Moyen") {
            this.data[2] += 1;
          }
          if (element.qualiteCv.libelle === "Injouable") {
            this.data[3] += 1;
          }
        });

        // REDRAW THE CHART

        this.qualiteCvCanvas.nativeElement.remove();
        this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

        this.myChart = new Chart("cvChart", {
          type: 'doughnut',
          data: {
              labels: [
                'Jouable',
                'Neutre',
                'Moyen',
                'Injouable'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: this.data,
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',

                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      } else {
        this.dservice.getUserConsultants(this.trigQualiteCv).subscribe(
          (data: any) => {
            // CHECK DATE

            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
                result0.push(element);
              }
            });

            // CHECK TAG METIER

            if (this.secteurQualiteCv === null) {
              result1 = result0;
            } else {
              result0.forEach((element: any) => {
                element.tagmetier.forEach((elt: any) => {
                  if (elt.libelle === this.secteurQualiteCv) {
                    result1.push(element);
                  }
                });
              });
            }

            // CHECK TAG FONCTION

            if (this.fonctionQualiteCv === null) {
              result2 = result1;
            } else {
              result1.forEach((element: any) => {
                element.tagfonc.forEach((elt: any) => {
                  if (elt.libelle === this.fonctionQualiteCv) {
                    result2.push(element);
                  }
                });
              });
            }


            // CHECK TAG TECHNIQUES

            if (this.technoQualiteCv === null) {
              result = result2;
            } else {
              result2.forEach((element: any) => {
                element.tagtech.forEach((elt: any) => {
                  if (elt.libelle === this.technoQualiteCv) {
                    result.push(element);
                  }
                });
              });
            }

            // GENERATE DATA
            result.forEach(element => {
              if (element.qualiteCv.libelle === "Jouable") {
                this.data[0] += 1;
              }
              if (element.qualiteCv.libelle === "Neutre") {
                this.data[1] += 1;
              }
              if (element.qualiteCv.libelle === "Moyen") {
                this.data[2] += 1;
              }
              if (element.qualiteCv.libelle === "Injouable") {
                this.data[3] += 1;
              }
            });

            // REDRAW THE CHART

            this.qualiteCvCanvas.nativeElement.remove();
            this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

            this.myChart = new Chart("cvChart", {
              type: 'doughnut',
              data: {
                  labels: [
                    'Jouable',
                    'Neutre',
                    'Moyen',
                    'Injouable'
                  ],
                  datasets: [{
                    label: 'My First Dataset',
                    data: this.data,
                    backgroundColor: [
                      'rgb(3, 153, 26)',
                      'rgb(48, 44, 102)',
                      'rgb(252, 186, 3)',
                      'rgb(252, 3, 3)'
                    ],
                  }]
                },
              options: {
                  cutoutPercentage: 60,
                  responsive: false,
                  legend: {
                      position: 'right',

                      labels: {
                          boxWidth: 20,
                          padding: 10
                      }
                  }
              }
            });
          }
        )
      }



    }

    if (this.periodeQualiteCv === "annee") {

      let today: Date = new Date();

      if (this.trigQualiteCv === "Tous") {

        // CHECK DATE

        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
            result0.push(element);
          }
        });

        // CHECK TAG METIER

        if (this.secteurQualiteCv === null) {
          result1 = result0;
        } else {
          result0.forEach((element: any) => {
            element.tagmetier.forEach((elt: any) => {
              if (elt.libelle === this.secteurQualiteCv) {
                result1.push(element);
              }
            });
          });
        }

        // CHECK TAG FONCTION

        if (this.fonctionQualiteCv === null) {
          result2 = result1;
        } else {
          result1.forEach((element: any) => {
            element.tagfonc.forEach((elt: any) => {
              if (elt.libelle === this.fonctionQualiteCv) {
                result2.push(element);
              }
            });
          });
        }


        // CHECK TAG TECHNIQUES

        if (this.technoQualiteCv === null) {
          result = result2;
        } else {
          result2.forEach((element: any) => {
            element.tagtech.forEach((elt: any) => {
              if (elt.libelle === this.technoQualiteCv) {
                result.push(element);
              }
            });
          });
        }

        // GENERATE DATA
        result.forEach(element => {
          if (element.qualiteCv.libelle === "Jouable") {
            this.data[0] += 1;
          }
          if (element.qualiteCv.libelle === "Neutre") {
            this.data[1] += 1;
          }
          if (element.qualiteCv.libelle === "Moyen") {
            this.data[2] += 1;
          }
          if (element.qualiteCv.libelle === "Injouable") {
            this.data[3] += 1;
          }
        });

        // REDRAW THE CHART

        this.qualiteCvCanvas.nativeElement.remove();
        this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

        this.myChart = new Chart("cvChart", {
          type: 'doughnut',
          data: {
              labels: [
                'Jouable',
                'Neutre',
                'Moyen',
                'Injouable'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: this.data,
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',

                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      } else {
        this.dservice.getUserConsultants(this.trigQualiteCv).subscribe(
          (data: any) => {
            // CHECK DATE

            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
                result0.push(element);
              }
            });

            // CHECK TAG METIER

            if (this.secteurQualiteCv === null) {
              result1 = result0;
            } else {
              result0.forEach((element: any) => {
                element.tagmetier.forEach((elt: any) => {
                  if (elt.libelle === this.secteurQualiteCv) {
                    result1.push(element);
                  }
                });
              });
            }

            // CHECK TAG FONCTION

            if (this.fonctionQualiteCv === null) {
              result2 = result1;
            } else {
              result1.forEach((element: any) => {
                element.tagfonc.forEach((elt: any) => {
                  if (elt.libelle === this.fonctionQualiteCv) {
                    result2.push(element);
                  }
                });
              });
            }


            // CHECK TAG TECHNIQUES

            if (this.technoQualiteCv === null) {
              result = result2;
            } else {
              result2.forEach((element: any) => {
                element.tagtech.forEach((elt: any) => {
                  if (elt.libelle === this.technoQualiteCv) {
                    result.push(element);
                  }
                });
              });
            }

            // GENERATE DATA
            result.forEach(element => {
              if (element.qualiteCv.libelle === "Jouable") {
                this.data[0] += 1;
              }
              if (element.qualiteCv.libelle === "Neutre") {
                this.data[1] += 1;
              }
              if (element.qualiteCv.libelle === "Moyen") {
                this.data[2] += 1;
              }
              if (element.qualiteCv.libelle === "Injouable") {
                this.data[3] += 1;
              }
            });

            // REDRAW THE CHART

            this.qualiteCvCanvas.nativeElement.remove();
            this.qualiteCvContainer.nativeElement.innerHTML = `<canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>`

            this.myChart = new Chart("cvChart", {
              type: 'doughnut',
              data: {
                  labels: [
                    'Jouable',
                    'Neutre',
                    'Moyen',
                    'Injouable'
                  ],
                  datasets: [{
                    label: 'My First Dataset',
                    data: this.data,
                    backgroundColor: [
                      'rgb(3, 153, 26)',
                      'rgb(48, 44, 102)',
                      'rgb(252, 186, 3)',
                      'rgb(252, 3, 3)'
                    ],
                  }]
                },
              options: {
                  cutoutPercentage: 60,
                  responsive: false,
                  legend: {
                      position: 'right',

                      labels: {
                          boxWidth: 20,
                          padding: 10
                      }
                  }
              }
            });
          }
        )
      }


    }

  }




  periodeTop5Tag: string = "semaine";
  trigTop5Tag = "Tous";

  checkIfElementInArray(element: string, array: any[]): number {
    let index = -1;
    if (array.length === 0) {
      return -1
    } else {
      array.forEach((elt: any) => {
        if (elt.name === element && index === -1) {
          index = array.indexOf(elt);
        }
      });
      return index;
    }
  }

  compare(a: any, b: any) {
    const numA = a.nombre;
    const numB = b.nombre;

    let comparison = 0;
    if (numA > numB) {
      comparison = -1;
    } else if (numA < numB) {
      comparison = 1;
    }
    return comparison;
  }

  setPeriodeTop5Tag (): void {
    this.filtrerTop5Tags()
  }

  setTrigTop5Tag (): void {
    this.filtrerTop5Tags()
  }

  filtrerTop5Tags (): void {

    let today = new Date();
    let fonctions: any[] = []
    let techniques: any[] = []
    let secteurs: any[] = []

    if (this.periodeTop5Tag === "semaine") {

      let result: any[] = []

      if (this.trigTop5Tag === "Tous") {

        // CHECK DATE
        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
            result.push(element);
          }
        });

        result.forEach((element: any) => {

          element.tagtech.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
              techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
            } else {
              techniques.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagmetier.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
              secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
            } else {
              secteurs.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagfonc.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
              fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
            } else {
              fonctions.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

        });

        fonctions = fonctions.sort(this.compare)
        techniques = techniques.sort(this.compare)
        secteurs = secteurs.sort(this.compare)

        if(fonctions.length < 5) {
          let k = fonctions.length;
          for (let i = k; i < 5; i++) {
            fonctions.push({name: "", nombre: 0})
          }
        }

        if(techniques.length < 5) {
          let k = techniques.length;
          for (let i = k; i < 5; i++) {
            techniques.push({name: "", nombre: 0})
          }
        }

        if(secteurs.length < 5) {
          let k = secteurs.length;
          for (let i = k; i < 5; i++) {
            secteurs.push({name: "", nombre: 0})
          }
        }

        this.top5 = Highcharts.chart('top5', {
          chart: {
              type: 'column'
          },
          title: {
            text: ''
          },
          legend: {
              enabled: false
          },
          xAxis: {
              categories: [
                  'Technologies',
                  'Fonctions',
                  'Secteurs',
              ],
              crosshair: true
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Nombre'
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0
              }
          },
          series: [ {
              type: 'column',
              data: [
                  [techniques[0].name, techniques[0].nombre],
                  [fonctions[0].name, fonctions[0].nombre],
                  [secteurs[0].name, secteurs[0].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[1].name, techniques[1].nombre],
              [fonctions[1].name, fonctions[1].nombre],
              [secteurs[1].name, secteurs[1].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }, {
          type: 'column',
          data: [
            [techniques[2].name, techniques[2].nombre],
            [fonctions[2].name, fonctions[2].nombre],
            [secteurs[2].name, secteurs[2].nombre],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
              type: 'column',
              data: [
                [techniques[3].name, techniques[3].nombre],
                [fonctions[3].name, fonctions[3].nombre],
                [secteurs[3].name, secteurs[3].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[4].name, techniques[4].nombre],
              [fonctions[4].name, fonctions[4].nombre],
              [secteurs[4].name, secteurs[4].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }]
        })

      } else {

        this.dservice.getUserConsultants(this.trigTop5Tag).subscribe(
          (data: any) => {
             // CHECK DATE
            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
                result.push(element);
              }
            });

            result.forEach((element: any) => {

              element.tagtech.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
                  techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
                } else {
                  techniques.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagmetier.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
                  secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
                } else {
                  secteurs.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagfonc.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
                  fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
                } else {
                  fonctions.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

            });

            fonctions = fonctions.sort(this.compare)
            techniques = techniques.sort(this.compare)
            secteurs = secteurs.sort(this.compare)

            if(fonctions.length < 5) {
              let k = fonctions.length;
              for (let i = k; i < 5; i++) {
                fonctions.push({name: "", nombre: 0})
              }
            }

            if(techniques.length < 5) {
              let k = techniques.length;
              for (let i = k; i < 5; i++) {
                techniques.push({name: "", nombre: 0})
              }
            }

            if(secteurs.length < 5) {
              let k = secteurs.length;
              for (let i = k; i < 5; i++) {
                secteurs.push({name: "", nombre: 0})
              }
            }

            this.top5 = Highcharts.chart('top5', {
              chart: {
                  type: 'column'
              },
              title: {
                text: ''
              },
              legend: {
                  enabled: false
              },
              xAxis: {
                  categories: [
                      'Technologies',
                      'Fonctions',
                      'Secteurs',
                  ],
                  crosshair: true
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: 'Nombre'
                  }
              },
              plotOptions: {
                  column: {
                      pointPadding: 0,
                      borderWidth: 0
                  }
              },
              series: [ {
                  type: 'column',
                  data: [
                      [techniques[0].name, techniques[0].nombre],
                      [fonctions[0].name, fonctions[0].nombre],
                      [secteurs[0].name, secteurs[0].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[1].name, techniques[1].nombre],
                  [fonctions[1].name, fonctions[1].nombre],
                  [secteurs[1].name, secteurs[1].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }, {
              type: 'column',
              data: [
                [techniques[2].name, techniques[2].nombre],
                [fonctions[2].name, fonctions[2].nombre],
                [secteurs[2].name, secteurs[2].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
                  type: 'column',
                  data: [
                    [techniques[3].name, techniques[3].nombre],
                    [fonctions[3].name, fonctions[3].nombre],
                    [secteurs[3].name, secteurs[3].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[4].name, techniques[4].nombre],
                  [fonctions[4].name, fonctions[4].nombre],
                  [secteurs[4].name, secteurs[4].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }]
            })
          }
        )

      }

    }

    if (this.periodeTop5Tag === "mois") {

      let result: any[] = []

      if (this.trigTop5Tag === "Tous") {

        // CHECK DATE
        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
            result.push(element);
          }
        });

        result.forEach((element: any) => {

          element.tagtech.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
              techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
            } else {
              techniques.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagmetier.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
              secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
            } else {
              secteurs.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagfonc.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
              fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
            } else {
              fonctions.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

        });

        fonctions = fonctions.sort(this.compare)
        techniques = techniques.sort(this.compare)
        secteurs = secteurs.sort(this.compare)

        if(fonctions.length < 5) {
          let k = fonctions.length;
          for (let i = k; i < 5; i++) {
            fonctions.push({name: "", nombre: 0})
          }
        }

        if(techniques.length < 5) {
          let k = techniques.length;
          for (let i = k; i < 5; i++) {
            techniques.push({name: "", nombre: 0})
          }
        }

        if(secteurs.length < 5) {
          let k = secteurs.length;
          for (let i = k; i < 5; i++) {
            secteurs.push({name: "", nombre: 0})
          }
        }

        this.top5 = Highcharts.chart('top5', {
          chart: {
              type: 'column'
          },
          title: {
            text: ''
          },
          legend: {
              enabled: false
          },
          xAxis: {
              categories: [
                  'Technologies',
                  'Fonctions',
                  'Secteurs',
              ],
              crosshair: true
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Nombre'
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0
              }
          },
          series: [ {
              type: 'column',
              data: [
                  [techniques[0].name, techniques[0].nombre],
                  [fonctions[0].name, fonctions[0].nombre],
                  [secteurs[0].name, secteurs[0].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[1].name, techniques[1].nombre],
              [fonctions[1].name, fonctions[1].nombre],
              [secteurs[1].name, secteurs[1].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }, {
          type: 'column',
          data: [
            [techniques[2].name, techniques[2].nombre],
            [fonctions[2].name, fonctions[2].nombre],
            [secteurs[2].name, secteurs[2].nombre],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
              type: 'column',
              data: [
                [techniques[3].name, techniques[3].nombre],
                [fonctions[3].name, fonctions[3].nombre],
                [secteurs[3].name, secteurs[3].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[4].name, techniques[4].nombre],
              [fonctions[4].name, fonctions[4].nombre],
              [secteurs[4].name, secteurs[4].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }]
        })

      } else {
        this.dservice.getUserConsultants(this.trigTop5Tag).subscribe(
          (data: any) => {
             // CHECK DATE
            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
                result.push(element);
              }
            });

            result.forEach((element: any) => {

              element.tagtech.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
                  techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
                } else {
                  techniques.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagmetier.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
                  secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
                } else {
                  secteurs.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagfonc.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
                  fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
                } else {
                  fonctions.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

            });

            fonctions = fonctions.sort(this.compare)
            techniques = techniques.sort(this.compare)
            secteurs = secteurs.sort(this.compare)

            if(fonctions.length < 5) {
              let k = fonctions.length;
              for (let i = k; i < 5; i++) {
                fonctions.push({name: "", nombre: 0})
              }
            }

            if(techniques.length < 5) {
              let k = techniques.length;
              for (let i = k; i < 5; i++) {
                techniques.push({name: "", nombre: 0})
              }
            }

            if(secteurs.length < 5) {
              let k = secteurs.length;
              for (let i = k; i < 5; i++) {
                secteurs.push({name: "", nombre: 0})
              }
            }

            this.top5 = Highcharts.chart('top5', {
              chart: {
                  type: 'column'
              },
              title: {
                text: ''
              },
              legend: {
                  enabled: false
              },
              xAxis: {
                  categories: [
                      'Technologies',
                      'Fonctions',
                      'Secteurs',
                  ],
                  crosshair: true
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: 'Nombre'
                  }
              },
              plotOptions: {
                  column: {
                      pointPadding: 0,
                      borderWidth: 0
                  }
              },
              series: [ {
                  type: 'column',
                  data: [
                      [techniques[0].name, techniques[0].nombre],
                      [fonctions[0].name, fonctions[0].nombre],
                      [secteurs[0].name, secteurs[0].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[1].name, techniques[1].nombre],
                  [fonctions[1].name, fonctions[1].nombre],
                  [secteurs[1].name, secteurs[1].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }, {
              type: 'column',
              data: [
                [techniques[2].name, techniques[2].nombre],
                [fonctions[2].name, fonctions[2].nombre],
                [secteurs[2].name, secteurs[2].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
                  type: 'column',
                  data: [
                    [techniques[3].name, techniques[3].nombre],
                    [fonctions[3].name, fonctions[3].nombre],
                    [secteurs[3].name, secteurs[3].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[4].name, techniques[4].nombre],
                  [fonctions[4].name, fonctions[4].nombre],
                  [secteurs[4].name, secteurs[4].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }]
            })
          }
        )
      }

    }

    if (this.periodeTop5Tag === "semestre") {

      let result: any[] = []

      if (this.trigTop5Tag === "Tous") {

        // CHECK DATE
        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
            result.push(element);
          }
        });

        result.forEach((element: any) => {

          element.tagtech.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
              techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
            } else {
              techniques.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagmetier.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
              secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
            } else {
              secteurs.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagfonc.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
              fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
            } else {
              fonctions.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

        });

        fonctions = fonctions.sort(this.compare)
        techniques = techniques.sort(this.compare)
        secteurs = secteurs.sort(this.compare)

        if(fonctions.length < 5) {
          let k = fonctions.length;
          for (let i = k; i < 5; i++) {
            fonctions.push({name: "", nombre: 0})
          }
        }

        if(techniques.length < 5) {
          let k = techniques.length;
          for (let i = k; i < 5; i++) {
            techniques.push({name: "", nombre: 0})
          }
        }

        if(secteurs.length < 5) {
          let k = secteurs.length;
          for (let i = k; i < 5; i++) {
            secteurs.push({name: "", nombre: 0})
          }
        }

        this.top5 = Highcharts.chart('top5', {
          chart: {
              type: 'column'
          },
          title: {
            text: ''
          },
          legend: {
              enabled: false
          },
          xAxis: {
              categories: [
                  'Technologies',
                  'Fonctions',
                  'Secteurs',
              ],
              crosshair: true
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Nombre'
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0
              }
          },
          series: [ {
              type: 'column',
              data: [
                  [techniques[0].name, techniques[0].nombre],
                  [fonctions[0].name, fonctions[0].nombre],
                  [secteurs[0].name, secteurs[0].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[1].name, techniques[1].nombre],
              [fonctions[1].name, fonctions[1].nombre],
              [secteurs[1].name, secteurs[1].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }, {
          type: 'column',
          data: [
            [techniques[2].name, techniques[2].nombre],
            [fonctions[2].name, fonctions[2].nombre],
            [secteurs[2].name, secteurs[2].nombre],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
              type: 'column',
              data: [
                [techniques[3].name, techniques[3].nombre],
                [fonctions[3].name, fonctions[3].nombre],
                [secteurs[3].name, secteurs[3].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[4].name, techniques[4].nombre],
              [fonctions[4].name, fonctions[4].nombre],
              [secteurs[4].name, secteurs[4].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }]
        })

      } else {
        this.dservice.getUserConsultants(this.trigTop5Tag).subscribe(
          (data: any) => {
             // CHECK DATE
            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
                result.push(element);
              }
            });

            result.forEach((element: any) => {

              element.tagtech.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
                  techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
                } else {
                  techniques.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagmetier.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
                  secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
                } else {
                  secteurs.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagfonc.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
                  fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
                } else {
                  fonctions.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

            });

            fonctions = fonctions.sort(this.compare)
            techniques = techniques.sort(this.compare)
            secteurs = secteurs.sort(this.compare)

            if(fonctions.length < 5) {
              let k = fonctions.length;
              for (let i = k; i < 5; i++) {
                fonctions.push({name: "", nombre: 0})
              }
            }

            if(techniques.length < 5) {
              let k = techniques.length;
              for (let i = k; i < 5; i++) {
                techniques.push({name: "", nombre: 0})
              }
            }

            if(secteurs.length < 5) {
              let k = secteurs.length;
              for (let i = k; i < 5; i++) {
                secteurs.push({name: "", nombre: 0})
              }
            }

            this.top5 = Highcharts.chart('top5', {
              chart: {
                  type: 'column'
              },
              title: {
                text: ''
              },
              legend: {
                  enabled: false
              },
              xAxis: {
                  categories: [
                      'Technologies',
                      'Fonctions',
                      'Secteurs',
                  ],
                  crosshair: true
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: 'Nombre'
                  }
              },
              plotOptions: {
                  column: {
                      pointPadding: 0,
                      borderWidth: 0
                  }
              },
              series: [ {
                  type: 'column',
                  data: [
                      [techniques[0].name, techniques[0].nombre],
                      [fonctions[0].name, fonctions[0].nombre],
                      [secteurs[0].name, secteurs[0].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[1].name, techniques[1].nombre],
                  [fonctions[1].name, fonctions[1].nombre],
                  [secteurs[1].name, secteurs[1].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }, {
              type: 'column',
              data: [
                [techniques[2].name, techniques[2].nombre],
                [fonctions[2].name, fonctions[2].nombre],
                [secteurs[2].name, secteurs[2].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
                  type: 'column',
                  data: [
                    [techniques[3].name, techniques[3].nombre],
                    [fonctions[3].name, fonctions[3].nombre],
                    [secteurs[3].name, secteurs[3].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[4].name, techniques[4].nombre],
                  [fonctions[4].name, fonctions[4].nombre],
                  [secteurs[4].name, secteurs[4].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }]
            })
          }
        )
      }

    }

    if (this.periodeTop5Tag === "annee") {

      let result: any[] = []

      if (this.trigTop5Tag === "Tous") {

        // CHECK DATE
        this.consultantData.consultants.forEach((element: any) => {
          if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
            result.push(element);
          }
        });

        result.forEach((element: any) => {

          element.tagtech.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
              techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
            } else {
              techniques.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagmetier.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
              secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
            } else {
              secteurs.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

          element.tagfonc.forEach((elt: any) => {
            if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
              fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
            } else {
              fonctions.push({
                name: elt.libelle,
                nombre: 1
              })
            }
          });

        });

        fonctions = fonctions.sort(this.compare)
        techniques = techniques.sort(this.compare)
        secteurs = secteurs.sort(this.compare)

        if(fonctions.length < 5) {
          let k = fonctions.length;
          for (let i = k; i < 5; i++) {
            fonctions.push({name: "", nombre: 0})
          }
        }

        if(techniques.length < 5) {
          let k = techniques.length;
          for (let i = k; i < 5; i++) {
            techniques.push({name: "", nombre: 0})
          }
        }

        if(secteurs.length < 5) {
          let k = secteurs.length;
          for (let i = k; i < 5; i++) {
            secteurs.push({name: "", nombre: 0})
          }
        }

        this.top5 = Highcharts.chart('top5', {
          chart: {
              type: 'column'
          },
          title: {
            text: ''
          },
          legend: {
              enabled: false
          },
          xAxis: {
              categories: [
                  'Technologies',
                  'Fonctions',
                  'Secteurs',
              ],
              crosshair: true
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Nombre'
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0
              }
          },
          series: [ {
              type: 'column',
              data: [
                  [techniques[0].name, techniques[0].nombre],
                  [fonctions[0].name, fonctions[0].nombre],
                  [secteurs[0].name, secteurs[0].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[1].name, techniques[1].nombre],
              [fonctions[1].name, fonctions[1].nombre],
              [secteurs[1].name, secteurs[1].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }, {
          type: 'column',
          data: [
            [techniques[2].name, techniques[2].nombre],
            [fonctions[2].name, fonctions[2].nombre],
            [secteurs[2].name, secteurs[2].nombre],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
              type: 'column',
              data: [
                [techniques[3].name, techniques[3].nombre],
                [fonctions[3].name, fonctions[3].nombre],
                [secteurs[3].name, secteurs[3].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
            type: 'column',
            data: [
              [techniques[4].name, techniques[4].nombre],
              [fonctions[4].name, fonctions[4].nombre],
              [secteurs[4].name, secteurs[4].nombre],
            ],
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.name}', // one decimal
                y: 10, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }

        }]
        })

      } else {
        this.dservice.getUserConsultants(this.trigTop5Tag).subscribe(
          (data: any) => {
            // CHECK DATE
            data.consultants.forEach((element: any) => {
              if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
                result.push(element);
              }
            });

            result.forEach((element: any) => {

              element.tagtech.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, techniques)>=0) {
                  techniques[this.checkIfElementInArray(elt.libelle, techniques)].nombre += 1;
                } else {
                  techniques.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagmetier.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, secteurs)>=0) {
                  secteurs[this.checkIfElementInArray(elt.libelle, secteurs)].nombre += 1;
                } else {
                  secteurs.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

              element.tagfonc.forEach((elt: any) => {
                if (this.checkIfElementInArray(elt.libelle, fonctions)>=0) {
                  fonctions[this.checkIfElementInArray(elt.libelle, fonctions)].nombre += 1;
                } else {
                  fonctions.push({
                    name: elt.libelle,
                    nombre: 1
                  })
                }
              });

            });

            fonctions = fonctions.sort(this.compare)
            techniques = techniques.sort(this.compare)
            secteurs = secteurs.sort(this.compare)

            if(fonctions.length < 5) {
              let k = fonctions.length;
              for (let i = k; i < 5; i++) {
                fonctions.push({name: "", nombre: 0})
              }
            }

            if(techniques.length < 5) {
              let k = techniques.length;
              for (let i = k; i < 5; i++) {
                techniques.push({name: "", nombre: 0})
              }
            }

            if(secteurs.length < 5) {
              let k = secteurs.length;
              for (let i = k; i < 5; i++) {
                secteurs.push({name: "", nombre: 0})
              }
            }

            this.top5 = Highcharts.chart('top5', {
              chart: {
                  type: 'column'
              },
              title: {
                text: ''
              },
              legend: {
                  enabled: false
              },
              xAxis: {
                  categories: [
                      'Technologies',
                      'Fonctions',
                      'Secteurs',
                  ],
                  crosshair: true
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: 'Nombre'
                  }
              },
              plotOptions: {
                  column: {
                      pointPadding: 0,
                      borderWidth: 0
                  }
              },
              series: [ {
                  type: 'column',
                  data: [
                      [techniques[0].name, techniques[0].nombre],
                      [fonctions[0].name, fonctions[0].nombre],
                      [secteurs[0].name, secteurs[0].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[1].name, techniques[1].nombre],
                  [fonctions[1].name, fonctions[1].nombre],
                  [secteurs[1].name, secteurs[1].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }, {
              type: 'column',
              data: [
                [techniques[2].name, techniques[2].nombre],
                [fonctions[2].name, fonctions[2].nombre],
                [secteurs[2].name, secteurs[2].nombre],
              ],
              dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.name}', // one decimal
                  y: 10, // 10 pixels down from the top
                  style: {
                      fontSize: '12px',
                      fontFamily: 'Verdana, sans-serif'
                  }
              }

          }, {
                  type: 'column',
                  data: [
                    [techniques[3].name, techniques[3].nombre],
                    [fonctions[3].name, fonctions[3].nombre],
                    [secteurs[3].name, secteurs[3].nombre],
                  ],
                  dataLabels: {
                      enabled: true,
                      rotation: -90,
                      color: '#FFFFFF',
                      align: 'right',
                      format: '{point.name}', // one decimal
                      y: 10, // 10 pixels down from the top
                      style: {
                          fontSize: '12px',
                          fontFamily: 'Verdana, sans-serif'
                      }
                  }

              }, {
                type: 'column',
                data: [
                  [techniques[4].name, techniques[4].nombre],
                  [fonctions[4].name, fonctions[4].nombre],
                  [secteurs[4].name, secteurs[4].nombre],
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.name}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }

            }]
            })
          }
        )
      }

    }

  }



  ngAfterViewInit(): void {
    this.top5 = Highcharts.chart('top5', {
      chart: {
          type: 'column'
      },
      title: {
        text: ''
      },
      legend: {
          enabled: false
      },
      xAxis: {
          categories: [
              'Technologies',
              'Fonctions',
              'Secteurs',
          ],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Nombre'
          }
      },
      plotOptions: {
          column: {
              pointPadding: 0,
              borderWidth: 0
          }
      },
      series: [ {
          type: 'column',
          data: [
            ['', 0],
            ['', 0],
            ['', 0],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
        type: 'column',
        data: [
            ['', 0],
            ['', 0],
            ['', 0],
        ],
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.name}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
                fontSize: '12px',
                fontFamily: 'Verdana, sans-serif'
            }
        }

    }, {
      type: 'column',
      data: [
          ['',0],
          ['', 0],
          ['', 0],
      ],
      dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.name}', // one decimal
          y: 10, // 10 pixels down from the top
          style: {
              fontSize: '12px',
              fontFamily: 'Verdana, sans-serif'
          }
      }

  }, {
          type: 'column',
          data: [
              ['', 0],
              ['', 0],
              ['', 0],
          ],
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.name}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                  fontSize: '12px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }

      }, {
        type: 'column',
        data: [
            ['', 0],
            ['', 0],
            ['', 0],
        ],
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.name}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
                fontSize: '12px',
                fontFamily: 'Verdana, sans-serif'
            }
        }

    }]
    })
  }

}
