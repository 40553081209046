import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { backEndpoint } from 'src/app/config';

const endpoint  = backEndpoint+ "/api/skills";

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http: HttpClient){}

  getSkills(consultantid: string) : Observable<any> {
    return this.http.get(endpoint+"/get-data", {params : {"consultantid": consultantid}});
  }

  getDomains(name: string, sortby: string, sortdirection: string, page: number, size: number): Observable<any>{
      return this.http.get(endpoint+"/find-domains", {params: {
          "name": name,
          "sortby": sortby,
          "sortdirection": sortdirection,
          "page": page.toString(),
          "size": size.toString()
      }});
  }

  findAllDomains(): Observable<any>{
    return this.http.get(endpoint+"/find-all-domains");
  }

  addDomain(data: any): Observable<any>{
    return this.http.post(endpoint+"/add-domain", data);
  }

  updateDomain(data: any): Observable<any>{
    return this.http.put(endpoint+"/update-domain", data);
  }

  deleteDomain(id: String): Observable<any>{
    return this.http.delete(endpoint+"/delete-domain/"+id);
  }

    getSubdomains(name: string, domain: string, sortby: string, sortdirection: string, page: number, size: number): Observable<any>{
        return this.http.get(endpoint+"/find-subdomains", {params: {
            "name": name,
            "domain": domain,
            "sortby": sortby,
            "sortdirection": sortdirection,
            "page": page.toString(),
            "size": size.toString()
        }});
    }

    addSubdomain(data: any): Observable<any>{
      return this.http.post(endpoint+"/add-subdomain", data);
    }

    updateSubdomain(data: any): Observable<any>{
      return this.http.put(endpoint+"/update-subdomain", data);
    }

    deleteSubdomain(id: String): Observable<any>{
      return this.http.delete(endpoint+"/delete-subdomain/"+id);
    }

    findAllSubdomains(): Observable<any>{
      return this.http.get(endpoint+"/find-all-subdomains");
    }

    findAllTechnologies(): Observable<any>{
      return this.http.get(endpoint+"/find-all-technologies");
    }

    getTechnologies(name: string, sortby: string, sortdirection: string, page: number, size: number): Observable<any>{
      return this.http.get(endpoint+"/find-technologies", {params: {
          "name": name,
          "sortby": sortby,
          "sortdirection": sortdirection,
          "page": page.toString(),
          "size": size.toString()
      }});
    }

    addTechno(data: any): Observable<any>{
      return this.http.post(endpoint+"/add-technology", data);
    }

    updateTechno(data: any): Observable<any>{
      return this.http.put(endpoint+"/update-technology", data);
    }

    deleteTechno(id: String): Observable<any>{
      return this.http.delete(endpoint+"/delete-technology/"+id);
    }

    findSubdomainByDomain(domain: string): Observable<any>{
      return this.http.get(endpoint+"/find-subdomains-by-domain", {params: {"domain": domain}});
    }

    findTechnoBySubdomain(subdomain: string): Observable<any>{
      return this.http.get(endpoint+"/find-technologies-by-subdomain", {params: {"subdomain": subdomain}});
    }

}
