import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Output, TemplateRef, ViewChild, SimpleChanges, OnChanges} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import { EventEmitter } from '@angular/core';
import { backEndpoint } from '../../config'
import { DashboardService } from '../services/dashboard.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json'
  })
};

const API_URL = backEndpoint+"/api/drh/"

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent implements OnInit {

  constructor(private dservice: DashboardService, private datePipe: DatePipe, private http: HttpClient,
     private modalService: NgbModal, private tokenStorage: TokenStorageService) { }

  @Input() rechercheData: any;

  trigramme: string = "";
  isAdmin: boolean = false;
  userid: string = "";

  ngOnInit(): void {

    const user = this.tokenStorage.getUser();
    this.trigramme = user.trigramme;
    this.isAdmin = user.roles.lastIndexOf('Gestionnaire')>0 || user.roles.lastIndexOf('Facturation')>0;
    this.userid = user.id;

    this.adeqChart = new Chart("adequation", {
      type: 'radar',
      data: {
        labels: [
          'Nombre de CVs',
          'Expérience',
          'Tags fonctions',
          'Tags metiers',
          'Tags techniques',
          'Qualités CVs',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: [0, 0, 0, 0, 0, 0],
            fill: true,
            backgroundColor: 'rgba(30, 36, 46, 0.2)',
            borderColor: 'rgb(30, 36, 46)',
            pointBackgroundColor: 'rgb(20, 20, 20)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(30, 36, 46)'
          },
        ]
      },
      options: {
        legend: {
          display: false,
            position: 'bottom',

            labels: {
                boxWidth: 20,
                padding: 10
            }
        }
    }
    });

    this.dservice.getDataRecherche().subscribe(
      (data: any)=>{
        this.rechercheData = data
        this.setTop1OMots();
        this.isLoading = false;
        this.loadChanged.emit(this.isLoading);
      }
    )
  }

  adeqChart: any;

  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('qualiteCvCanvas') adequationCanvas!: ElementRef;
  @ViewChild('qualiteCvCanvasContainer') adequationContainer!: ElementRef;

  checkIfMotInArray(mot: string, array: any[]): number {
    let index = -1;
    if (array.length === 0) {
      return -1
    } else {
      array.forEach((elt: any) => {
        if (elt.motCle === mot && index === -1) {
          index = array.indexOf(elt);
        }
      });
      return index;
    }
  }

  compareMot(a: any, b: any) {
    const numA = a.occurence;
    const numB = b.occurence;

    let comparison = 0;
    if (numA > numB) {
      comparison = -1;
    } else if (numA < numB) {
      comparison = 1;
    }
    return comparison;
  }

  setTop1OMots(): void {

    let today: Date = new Date();
    let result : any[] = []

    this.top10MotRecherche = []

    if (this.periodeRecherche === "semaine") {
      this.rechercheData.motRecherches.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
          result.push(element);
        }
      });
    }

    if (this.periodeRecherche === "mois") {
      this.rechercheData.motRecherches.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
          result.push(element);
        }
      });
    }

    if (this.periodeRecherche === "semestre") {
      this.rechercheData.motRecherches.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
          result.push(element);
        }
      });
    }

    if (this.periodeRecherche === "annee") {
      this.rechercheData.motRecherches.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
          result.push(element);
        }
      });
    }

    result.forEach(element => {
      if (this.checkIfMotInArray(element.mot, this.top10MotRecherche)>=0) {
        this.top10MotRecherche[this.checkIfMotInArray(element.mot, this.top10MotRecherche)].occurence += 1;
      } else {
        this.top10MotRecherche.push({
          motCle: element.mot,
          tendance: "",
          occurence: 1
        })
      }
    });

    this.top10MotRecherche = this.top10MotRecherche.sort(this.compareMot)

    if (this.top10MotRecherche.length>10) {
      let temp = this.top10MotRecherche.slice(0,11)
      this.top10MotRecherche = temp
    }

    this.calculTendance(this.rechercheData.motRecherches);

  }

  calculTendance(data: any): void {
    let today = new Date();
    let nbre : number = 0;
    for (let i = 0; i < this.top10MotRecherche.length; i++) {
      data.forEach((element:any) => {
         if (element.mot === this.top10MotRecherche[i].motCle && (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-2, today.getDay())) && (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-1, today.getDay()))) {
           nbre +=1
         }
      });
      if (nbre > this.top10MotRecherche[i].occurence) {
        this.top10MotRecherche[i].tendance = "down"
      } else if(nbre < this.top10MotRecherche[i].occurence){
        this.top10MotRecherche[i].tendance = "up"
      } else {
        this.top10MotRecherche[i].tendance = "const"
      }

      nbre = 0;
    }

  }

  nbreMot: number = 0;
  tendanceMot: string = "";
  alertMessage = "";

  @ViewChild('alertRechercheModal', { read: TemplateRef }) alertModal!: TemplateRef<any>;

  calculTendanceMot(mot: any): void {
    let today = new Date();
    let tend : number = 0;
    let nbre : number = 0;
    this.rechercheData.motRecherches.forEach((element:any) => {
      if (element.mot === mot && (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-2, today.getDay())) && (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-1, today.getDay()))) {
        tend +=1
      }
    });
    this.rechercheData.motRecherches.forEach((element : any) => {
      if (element.mot === mot){
        nbre +=1
      }
    });
    if (tend > nbre ) {
      this.tendanceMot = "down"
    } else if(tend < nbre){
      this.tendanceMot = "up"
    } else {
      this.tendanceMot = "const"
    }

    this.nbreMot = nbre;

    if (nbre === 0) {
      this.alertMessage = "Nouveau mot de recherche"
      this.modalService.open(this.alertModal, {centered: true})
    }
  }

  top10MotRecherche : any[] = []

  periodeRecherche = "semaine";
  periodeAdequation = "semaine";
  motRecherche = "";

  setMotRecherche(mot:any): void {

    this.motRecherche = mot;

    this.calculTendanceMot(mot);

    let result: any[] = []
    let today = new Date()

    let total = 0;

    if (this.periodeAdequation === "semaine") {
      this.rechercheData.resultatRMCS.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), today.getDay()-6)) {
          total++;
          element.motCles.forEach((elt: any) => {
            if (elt.mot === this.motRecherche) {
              result.push(element)
            }
          });
        }
      });
    }

    if (this.periodeAdequation === "mois") {
      this.rechercheData.resultatRMCS.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-1, today.getDay())) {
          total++;
          element.motCles.forEach((elt: any) => {
            if (elt.mot === this.motRecherche) {
              result.push(element)
            }
          });
        }
      });
    }

    if (this.periodeAdequation === "semestre") {
      this.rechercheData.resultatRMCS.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-6, today.getDay())) {
          total++;
          element.motCles.forEach((elt: any) => {
            if (elt.mot === this.motRecherche) {
              result.push(element)
            }
          });
        }
      });
    }

    if (this.periodeAdequation === "annee") {
      this.rechercheData.resultatRMCS.forEach((element: any) => {
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear()-1, today.getMonth(), today.getDay())) {
          total++;
          element.motCles.forEach((elt: any) => {
            if (elt.mot === this.motRecherche) {
              result.push(element)
            }
          });
        }
      });
    }

    // CALCULER LES MOYENNES POUR L'ADEQUATION

    let tagfonc = 0;
    let tagtech = 0;
    let tagmet = 0;
    let exp = 0;
    let qualite = 0;
    result.forEach((element: any) => {
      tagtech += element.tagtech.length
      tagmet += element.tagmetier.length
      tagfonc += element.tagfonc.length
      if(element.exp){
        if(element.exp = "0-3 ans"){
          exp += 1.5;
        } else if(element.exp = "4-7 ans"){
          exp += 5.5;
        } else if(element.exp = ">7 ans") {
          exp += 10;
        }
      }
      qualite += element.qualiteCv.ordre
    });

    let data: number[] = [result.length*5/total, exp/result.length, tagfonc/result.length, tagmet/result.length, tagtech/result.length, qualite/result.length]

    if (result.length === 0) {
      data = [0,0,0,0,0,0]
    }

    for (let i = 0; i < data.length; i++) {
      data[i] = Math.round(data[i]);
    }

    // RETRACER LE GRAPHE

    this.adequationCanvas.nativeElement.remove();
    this.adequationContainer.nativeElement.innerHTML = `<canvas id="adequation" width="350" height="350" #qualiteCv></canvas>`

    this.adeqChart = new Chart("adequation", {
      type: 'radar',
      data: {
        labels: [
          'Moyenne CVs',
          'Moy. Expérience',
          'Moy. Tags fonctions',
          'Moy. Tags metiers',
          'Moy. Tags techniques',
          'Moy. Qualités CVs',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: data,
            fill: true,
            backgroundColor: 'rgba(30, 36, 46, 0.2)',
            borderColor: 'rgb(30, 36, 46)',
            pointBackgroundColor: 'rgb(20, 20, 20)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(30, 36, 46)'
          },
        ]
      },
      options: {
        legend: {
          display: false,
            position: 'bottom',

            labels: {
                boxWidth: 20,
                padding: 10
            }
        }
    }
    });

  }

  setPeriodeRecherche(): void {
    this.motRecherche = ""
    this.setTop1OMots()
  }

  setPeriodeAdequation() : void {
    if (this.motRecherche !== "") {
      this.setMotRecherche(this.motRecherche);
    }
  }

}
