import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Tagtech } from '../Model/tagtech';
import { backEndpoint } from '../config'

const endpoint =  backEndpoint +'/actions/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  windowRefTag=[]
  windowRefMc=[]


  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  addTabsTags(window:any){
    this.windowRefTag.push(window)
  }
  closetabsTags(){
    this.windowRefTag.forEach(element => {
      element.close()
  });
  }

  addTabsMc(window:any){
    this.windowRefMc.push(window)
  }
  closetabsMc(){
    this.windowRefMc.forEach(element => {
      element.close()
  });
  }

  findActionsbyId(id): Observable<any> {
    return this.http.get<any>(endpoint + 'public/findbyuser/' + id, httpOptions);
  }


  addActions(action): Observable<any> {
    return this.http.post<any>(endpoint + 'mod/add', JSON.stringify(action), httpOptions);
  }

 

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}