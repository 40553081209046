import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CRM_BACK_ENDPOINT, backEndpoint } from '../config';
import { TokenStorageService } from './token-storage.service';

const endpoint = CRM_BACK_ENDPOINT+'/consultant';
const endpoint2 = backEndpoint+'/consultant';
const endpoint24 = backEndpoint+'/client';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConsultantCrmService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }
  verifyCV(fileName) {

    return this.http.get(endpoint +'/verifyfile/' + fileName);

  }


  cv(cv) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token.getToken(),
    });

    return this.http.post(endpoint + '/postcv', cv,{headers});
  }

  getConsultants(): Observable<any> {
    return this.http.get(endpoint + '/public/all').pipe(
      map(this.extractData));
  }

  getLimitConsultants(): Observable<any> {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    //,{ headers: reqHeader }
    return this.http.get(endpoint + '/public/get').pipe(
      map(this.extractData));
  }

  findConsultantbyId(id): Observable<any> {
    return this.http.get<any>(endpoint2 + '/public/findbyid/' + id, httpOptions);
  }

  findConsultantbyEmail(email): Observable<any> {
    return this.http.get<any>(endpoint + '/public/findbyemail/' + email, httpOptions);
  }

  findConsultantbyProp(nom,prenom,email): Observable<any> {
    return this.http.get<any>(endpoint + `/public/findbyprop/${nom}/${prenom}/${email}`, httpOptions);
  }

  addConsultant(consultant): Observable<any> {
    return this.http.post<any>(endpoint + '/mod/add', JSON.stringify(consultant), httpOptions);
  }

  updateConsultant(id, consultant): Observable<any> {
    return this.http.put(endpoint + '/mod/update/' + id, JSON.stringify(consultant), httpOptions);
  }

  FindByQuery(ttech,tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();
    ttech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagMech`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagFech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + '/public/rechtag56',{params});
  }
  FindByTagtech(tagTech): Observable<any>{
    let params = new HttpParams();
    tagTech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagtech',{params});
  }

  FindByTagmet(tagMet): Observable<any>{
    let params = new HttpParams();
    tagMet.forEach((techn: string) => {
      params = params.append(`tagMet`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagmet',{params});
  }

  FindByTagfonc(tagFonc): Observable<any>{
    let params = new HttpParams();
    tagFonc.forEach((techn: string) => {
      params = params.append(`tagFonc`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagfonc',{params});
  }

  FindByTags(tagTech,tagMet,tagFonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagFonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagMet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagTech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });

    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtags`, {params});
  }

  FindByQuali(quali): Observable<any>{
    let params = new HttpParams();
    quali.forEach((techn: any) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + '/public/rechquali',  {params});
  }

  FindByDispo(dis): Observable<any>{
    let params = new HttpParams();
    dis.forEach((techn: any) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + '/public/rechdispo', {params});
  }

  FindByExp(expert): Observable<any>{
    let params = new HttpParams();
    expert.forEach((techn: any) => {
      params = params.append(`expert`, techn);
    });
    return this.http.get(endpoint + `/public/rechexp`, {params});
  }

  FindByPostal(post): Observable<any>{
    let params = new HttpParams();
    post.forEach((techn: any) => {
      params = params.append(`postal`, techn);
    });
    return this.http.get(endpoint + `/public/rechpost`, {params});
  }

  FindByQuery1(tagtech,tagmet): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag1`, {params});
  }

  FindByQuery2(tagtech,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag2`, {params});
  }

  FindByQuery3(tagmet,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag3`, {params});
  }

  FindByQuery4(tagtech,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    //let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag4`, {params});
  }

  FindByQuery5(tagtech,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + '/public/rechtag5', {params});
  }

  FindByQuery6(tagmet,qualite): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag6`, {params});
  }

  FindByQuery7(tagmet,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag7`, {params});
  }

  FindByQuery8(tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag8`, {params});
  }

  FindByQuery9(tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag9`, {params});
  }

  FindByQuery10(qualite,dispo): Observable<any>{
    let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });

    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag10`, {params});
  }

  FindByQuery11(tagtech,tagmet,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag11`, {params});
  }

  FindByQuery12(tagtech,tagmet,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag12`, {params});
  }

  FindByQuery13(tagtech,tagmet,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag13`, {params});
  }

  FindByQuery14(tagtech,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag14`, {params});
  }

  FindByQuery15(tagtech,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag15`, {params});
  }

  FindByQuery17(tagmet,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag17`, {params});
  }

  FindByQuery18(tagmet,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag18`, {params});
  }

  FindByQuery19(tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag19`, {params});
  }

  FindByQuery20(tagtech,tagmet,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag20`, {params});
  }

  FindByQuery21(tagtech,tagmet,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag21`, {params});
  }

  FindByQuery22(tagmet,tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag22`, {params});
  }

  FindByQuery23(tagtech,tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag23`, {params});
  }

  FindByQuery24(tagtech,tagmet,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag24`, {params});
  }

  FindByQuery29(tagtech,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag29`, {params});
  }

  FindByQuery30(tmet,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag30`, {params});
  }

  FindByQuery31(tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag31`, {params});
  }

  FindByQuery32(qualite,postal): Observable<any>{
    let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    //let params = new HttpParams();
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag32`, {params});
  }

  FindByQuery33(dispo,postal): Observable<any>{
    let params = new HttpParams();
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    //let params = new HttpParams();
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag33`, {params});
  }

  FindByQuery34(tagtech,tmet,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag34`, {params});
  }

  FindByQuery35(tagtech,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag35`,{params});
  }

  FindByQuery36(tagtech,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag36`, {params});
  }

  FindByQuery37(tagtech,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag37`, {params});
  }

  FindByQuery38(tmet,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag38`, {params});
  }

  FindByQuery39(tmet,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag39`, {params});
  }

  FindByQuery40(tmet,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag40`, {params});
  }

  FindByQuery41(tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag41`, {params});
  }

  FindByQuery42(tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag42`, {params});
  }

  FindByQuery43(tagtech,tmet,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag43`, {params});
  }

  FindByQuery44(tagtech,tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });

    tfonc.forEach((techn: string) => {
      params = params.append(`tagFech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag44`, {params});
  }

  FindByQuery45(tagtech,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag45`, {params});
  }

  FindByQuery46(tagtech,tmet,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag46`, {params});
  }

  FindByQuery47(tagtech,tmet,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag47`, {params});
  }

  FindByQuery48(tmet,tfonc,qualite,postal): Observable<any>{
    let headers =  new HttpHeaders({
      'Content-Type':  'application/json'
    })
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag48`, {params});
  }

  FindByQuery49(tmet,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag49`, {params});
  }

  FindByQuery50(tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag50`, {params});
  }

  FindByQuery51(tagtech,tmet,tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });

    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag51`, {params});
  }

  FindByQuery52(tagtech,tmet,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });

    return this.http.get(endpoint + `/public/rechtag52`, {params});
  }

  FindByQuery53(tagtech,tmet,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag53`, {params});
  }

  FindByQuery54(tagtech,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag54`, {params});
  }

  FindByQuery55(tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag55`, {params});
  }

  FindByQuery56(ttech,tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    ttech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag56`, {params});
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  Consultants(request) {
    //let url = 'http://localhost:8080/tags';
    const params = request;
    return this.http.get(endpoint, { params });
  }

  selectCV(cv,id): Observable<any> {

    console.log("send data",id,cv)
    return this.http.post<any>(endpoint + '/select/cv/?idbesoin='+id, JSON.stringify(cv), httpOptions);
  }
  getallCV(id): Observable<any> {
    return this.http.get<any>(endpoint + '/getallcv/?idbesoin='+id,httpOptions);
  }

  validate(cv,id): Observable<any> {
    return this.http.post<any>(endpoint + '/validate/cv/?idbesoin='+id, JSON.stringify(cv), httpOptions);
  }

  deteletecv(name): Observable<any> {
    return this.http.get<any>(endpoint + '/delete/cv/?name='+name,httpOptions);
  }
  deleteconsultants(id): Observable<any> {
    return this.http.get<any>(endpoint + '/delete/consultant/?id='+id,httpOptions);
  }
  updateindice(id,value): Observable<any> {
    return this.http.get<any>(endpoint + '/updateindice/?id='+id+"&indice="+value,httpOptions);
  }

  updatestatut(id,value): Observable<any> {
    return this.http.get<any>(endpoint + '/updatestatut/?id='+id+"&statut="+value,httpOptions);
  }

  updatetrigGestionnaireRH(id,value): Observable<any> {
    return this.http.get<any>(endpoint + '/updatetrigGestionnaireRH/?id='+id+"&trigramme="+value,httpOptions);
  }

  updatedescription(id,value): Observable<any> {
    return this.http.get<any>(endpoint + '/updatedescription/?id='+id+"&description="+value,httpOptions);
  }

  updateao(id,value): Observable<any> {
    return this.http.post<any>(endpoint + '/updateao/?id='+id,JSON.stringify(value),httpOptions);
  }

  getsummary(): Observable<any> {
    return this.http.get<any>(endpoint + '/summary/cv',httpOptions);
  }

  postbesoin(besoin,id1,id2): Observable<any> {
    return this.http.post<any>(endpoint + '/addbesoin/?idClient='+id1+'&idContact='+id2, JSON.stringify(besoin), httpOptions);
  }
  addconversation(conv,id1,id2): Observable<any> {
    return this.http.post<any>(endpoint + '/addconversation/?idClient='+id1+'&idContact='+id2, JSON.stringify(conv), httpOptions);
  }
  getfile(name){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token.getToken()
    });
    return this.http.get<any>(endpoint24 + '/5f932d49e415b7eae227a788.pdf',{ headers });
  }

  addconsultanttoselction(conv): Observable<any> {
    return this.http.post<any>(endpoint + '/addconsultanttoselction', JSON.stringify(conv), httpOptions);
  }

  verifyconsultant(nom,prenom): Observable<any> {
    return this.http.get<any>(endpoint + '/verifyselection/?idClient='+nom+'&idContact='+prenom,httpOptions);
  }

  getbesoinbyid(id): Observable<any> {
    return this.http.get<any>(endpoint + '/getbesoinbyid/?id='+id,httpOptions);
  }

}
