<div class="container-fluid mb-5">

    <div class="row">

        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="text-center mb-1 title">Suivi des rappels</div>
                    <div class="row d-flex justify-content-around">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeSuiviRappel()" [(ngModel)]="periodeSuiviRappel" name="periodeSuiviRappel">
                              <option value="semaine" selected>1 Semaine</option>
                              <option value="mois">1 Mois</option>
                              <option value="semestre">1 Semestre</option>
                              <option value="annee">1 Année</option>
                            </select>
                        </div>

                        <div class="row" *ngIf="isAdmin">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigSuiviRappel()" placeholder="" style="width: 100px;" [(ngModel)]="trigSuiviRappel">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-9">
                            <div id="chartRappel"></div>
                        </div>
                        <div class="col-3 justify-content-center" style="margin-top: 40px;">
                            <div class="indicator" style="background-color: red;"></div>Traités en retard <br>
                            <div class="indicator" style="background-color: green;"></div>Traités à temps
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>