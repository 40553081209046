import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { DisponibiliteService } from '../services/disponibilite.service';
import { RechercheService } from '../services/recherche.service';
import { Router } from '@angular/router';
import { PrioriteService } from '../services/priorite.service';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { TokenStorageService } from '../services/token-storage.service';
import {PopuploaderComponent} from "../popuploader/popuploader.component"
import {Title} from "@angular/platform-browser";
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { ActionsService } from '../services/actions.service';
function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
enum checkBoxStateTop {TTZ,ZIDF,ZHIDF,ZIDFR,ZIDFNR,NONE}
enum checkBoxStateBottom  {ONE,TWO,THREE,FOUR,FIVE,SIX,SEVEN,EIGHT,NONE}

@Component({
  selector: 'app-rechmc',
  templateUrl: './rechmc.component.html',
  styleUrls: ['./rechmc.component.scss']
})
export class RechmcComponent implements OnInit {
  check_box_type = checkBoxStateTop;
  currentlyChecked: checkBoxStateTop;
  check_box_type2 = checkBoxStateBottom;
  currentlyChecked2: checkBoxStateBottom;

  Dispo = [];
  dialogRef:any;
  dialogRef2:any;
  dialogRef3:any;
  dialogRef4:any;
  AndOrSearch=true;
  dialogRef5:any;
  quali: any[] = [];
  dispo: any[] = [];
  expert: any[] = [];
  input:any=[];
  zone: String = "";
  code: any[] = ['75','77','78','91','92','93','94','95','vide']
  check = false;
  checkk = false;
  check2=false
  Poids : any = [];
  un = '1';
  deux = '2';
  trois = '3';
  quatre = '4';
  cinq = '5';


  state=true;
  codes: any = [];
  codes2: any = [];
  msg = `${this.input[0]}: recherche en cours`
  msg2 = `
  ${this.input[0]}: recherche terminée <br />
  ${this.input[1]}: recherche en cours`
  msg3 = `
  ${this.input[0]}: recherche terminée <br />
  ${this.input[1]}: recherche terminée <br />
  ${this.input[2]} recherche en cours`
  msg4 = `
  ${this.input[0]}: recherche terminée <br />
  ${this.input[1]}: recherche terminée <br />
  ${this.input[2]}: recherche terminée <br />
  ${this.input[3]}: recherche en cours`
  msg5 = `
  ${this.input[0]}: recherche terminée <br />
  ${this.input[1]}: recherche terminée <br />
  ${this.input[2]}: recherche terminée <br />
  ${this.input[3]}: recherche terminée <br />
  ${this.input[4]}: recherche en cours
  `
  dispos=[]
msgswitch:any
  constructor(private ds: DisponibiliteService,
    private actionservice:ActionsService,
    public communicationService: CommunicationService,
    private rs: RechercheService,
    private router: Router,
    private pos: PrioriteService,
    private titleService:Title,
    private dialog: MatDialog,private tokenStorageService: TokenStorageService) {
    this.communicationService.Broadcast('OnChild7', 'Recherche par mots clés');

   }

  ngOnInit() {
    this.loader()
    this.titleService.setTitle("Portail RH")
    window.scroll(0,0);
    this.getDispo();
    this.getPrio();
    this.getexp()

    this.currentlyChecked = this.check_box_type.ZIDFR

  this.msg = this.msg +1

  }

  getexp(){
    this.rs.getexperience().subscribe((data)=>{
      this.dispos=data
      this.dialog.closeAll()
    })
  }

  getDispo() {
    this.ds.getRefs().subscribe((data2: {}) => {
// tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i].libelle);
    }
    });
    this.dialog.closeAll()

  }

  getPrio(){
    this.loader();
    this.pos.getPriorite().subscribe(data => {
      if(data){
        for (const i in data) {
          this.Poids.push(data[i]);
      }
      this.dialog.closeAll()
      }

    });

  }

  RechQualite(t1:String , isChecked: boolean){
    if (isChecked) {
        this.quali.push(t1);
      }
   else {
    let index = this.quali.findIndex(
      (x) => x === t1
    );
    if (index != -1) this.quali.splice(index,1);
   }

  }

  RechDispo(t1:any , isChecked: boolean){
    if (isChecked) {

        this.dispo.push(this.getname(t1));
      }
   else {
    let index = this.dispo.findIndex(
      (x) => x === this.getname(t1)
    );
    if (index != -1) this.dispo.splice(index,1);
   }
  }

  RechExp(t1:String , isChecked: boolean){

    if (isChecked) {
        this.expert.push(t1);
      }
   else {
    let index = this.expert.findIndex(
      (x) => x === t1
    );
    if (index != -1) this.expert.splice(index,1);
   }

  }

  /*RechZone(param){
    if(this.zone === param){
      this.zone = "";
    }
    else{
      this.zone = param;
    }
  }*/

  selectCheckBox(targetType: checkBoxStateTop,num) {
    this.codes = []
    if(this.codes){
      this.codes.slice(0,this.codes.length)
    }
    this.codes2.push(num)
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = checkBoxStateTop.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }
  RechCodePostal(targetType) {
    this.codes2 = []
    this.currentlyChecked = checkBoxStateTop.NONE;
    this.codes.push(targetType)

  }
  getname(e){
    if(e[0]==="+"){
      e = e.slice(1,e.lenght)
    }
    return e
  }
  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
testInput(input,poids){

  if(input.length!=poids.length){
    this.Alert("Vous devez saisir un Mot-Clé et une Priorité associée")
    return false
  }
  if(input.length===0 || poids.length===0){
    this.Alert("Vous devez saisir un Mot-Clé et une Priorité associée")
    return false
  }
  return true;
}
testInputr(input){
  const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  for(let j =0;j<input.length;j++){
    if(countOccurrences(input,input[j])!==1){
      this.Alert("Les mots clés ne doivent pas être identiques")
      return false

    }
  }
  return true
}

  save(){
    let codegeo= [];
    this.codes = this.codes.concat(this.codes2)
    if(this.codes.length===0){
      this.codes.push(3)
    }
    codegeo=this.codes;

    const user = this.tokenStorageService.getUser();

    var poids1 = Number((<HTMLInputElement>document.getElementById("select1")).value);
    var poids2 = Number((<HTMLInputElement>document.getElementById("select2")).value);
    var poids3 = Number((<HTMLInputElement>document.getElementById("select3")).value);
    var poids4 = Number((<HTMLInputElement>document.getElementById("select4")).value);
    var poids5 = Number((<HTMLInputElement>document.getElementById("select5")).value);

    var poidstest = [poids1,poids2,poids3,poids4,poids5];
    var poids = [];

    var input1 = (<HTMLInputElement>document.getElementById("input1")).value;
    var input2 = (<HTMLInputElement>document.getElementById("input2")).value;
    var input3 = (<HTMLInputElement>document.getElementById("input3")).value;
    var input4 = (<HTMLInputElement>document.getElementById("input4")).value;
    var input5 = (<HTMLInputElement>document.getElementById("input5")).value;

    var inputtest = [input1,input2,input3,input4,input5];
    var input = [];

    poidstest.forEach((item,index)=>{
        if(item!=0){
          poids.push(item);
        }
    });

    inputtest.forEach((item,index)=>{
      if(item!=""){
        input.push(item);
      }
  });

  if(!this.testInput(input,poids) || !this.testInputr(input)) {

  }
  else {
    poids.forEach((item,index)=>{
      this.Poids.push(item)
    });

    input.forEach((item,index)=>{
      this.input.push(item.toLowerCase())
    });
    this.rs.verifymc(this.input[0]).subscribe(rmcdata1=>{
      if(rmcdata1===null){

      }
    })

    this.dialogRef2 = this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         recherche en cours`

      },
      disableClose: true,
    });

    this.rs.RechercheCompetence(this.AndOrSearch,this.input,poids,user.trigramme).subscribe(ping5=>{
      if(ping5===1){
          let temp = []
          this.dialog.closeAll()
          this.codes.forEach(function(item, i) {
          if (item === 1) {temp.push("Toutes les zones"); }
          if (item === 2) {temp.push("Zone IDF"); }
          if (item === 3){ temp.push("Zone IDF ou non renseigné"); }
          if (item === 4) {temp.push("Zone hors IDF");}
          if (item === 5) {temp.push("Zone hors IDF ou non renseigné"); }
          if (item === 0){ temp.push("Non renseigné"); }
          if (item === 96) {temp.push("Non renseigné");}
          else{
          temp.push(item)
          }
          })
          if(temp[temp.length-1]===1 || temp[temp.length-1]===2 || temp[temp.length-1]===3 || temp[temp.length-1]===0  ){
            temp.pop()

          }
          window.localStorage.setItem("qualite",this.quali.toString());
          window.localStorage.setItem("exp",this.expert.toString());
          window.localStorage.setItem("dispo",this.dispo.toString());
          window.localStorage.setItem("geo",codegeo.toString());
          window.localStorage.setItem("geotemp",temp.toString());
          if(this.AndOrSearch==true){
            window.localStorage.setItem("and","ET")
          }else{
            window.localStorage.setItem("and","OU")
          }
          this.router.navigate(['/rechmcr'], { queryParams: { searchtype:this.AndOrSearch,qualite: this.quali, dispo: this.dispo, experience: this.expert, zone: temp,input:input,poids,codegeo}});
      }
    })

    }
  }

  async renderMessage (input){
    if(input.length!==0){
      let msgtemp = 0
      setInterval(() => {
        msgtemp = msgtemp +1
      }, 1000);

    return msgtemp
    }
  }

  renderText(state){
    return state ? "recherche en cours ..." : " recherche terminée"
  }

  async getState(word){
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
       // console.log(data)
        return data;
      }
    })
  }
  checkData(){
    this.AndOrSearch=!this.AndOrSearch
  }
  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en cours`

      },
      disableClose: true,
    });
  }
}

