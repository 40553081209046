<p style="text-align: center; font-size: 20px">Logo RH</p>
<hr style="border: 1px solid black">
<mat-dialog-content>
    <table class="table">
        <tr>
            <th>Priorité</th>
            <th>Mots Clés</th>
            <th>Occurence</th>
        </tr>
        <tr *ngFor="let rech of recherche;">
            <td>{{rech.poids}}</td>
            <td>{{rech.mot}}</td>
            <td>{{rech.nbre_occurence}}</td>
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="center">
<button mdbBtn style="background-color: #BBE2BE; color:black;position:relative;font-size: 15px;font-weight: bold" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>