<div class="container-fluid">

    <div class="d-flex justify-content-center" style="min-height: 200px;" *ngIf="isLoading">
        <div class="spinner-border text-secondary mt-5" style="height: 4rem; width: 4rem;" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>

    <div class="row d-flex justify-content-around" *ngIf="!isLoading">

        <div class="col-md-5">
            <div class="text-center mb-2 title">Renseignement des "Fiches consultants"</div>
            <table class="table">
                <thead class="table-primary">
                    <th>Champs</th>
                    <th>Taux</th>
                    <th>Nombre</th>
                    <th>Tendance</th>
                    <th>Consutants</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="pl-3">Tel ou Email</td>
                        <td class="text-center" [ngClass]="{'up': pemail>60, 'down': pemail<=30, 'medium': (pemail>30 && pemail<=60)}">{{pemail | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.email}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pemail===tendanceFiche.email"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pemail<tendanceFiche.email"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pemail>tendanceFiche.email"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheEmail(ficheEmail)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Qualité CV à neutre</td>
                        <td class="text-center" [ngClass]="{'up':pqualite>60, 'down': pqualite<=30, 'medium': (pqualite>30 && pqualite<=60)}">{{pqualite | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.qualite}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pqualite===tendanceFiche.qualite"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pqualite<tendanceFiche.qualite"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pqualite>tendanceFiche.qualite"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheQualite(ficheQualite)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">CV</td>
                        <td class="text-center" [ngClass]="{'up': pcv>60, 'down': pcv<=30, 'medium': (pcv>30 && pcv<=60)}">{{pcv | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.cv}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pcv===tendanceFiche.cv"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pcv<tendanceFiche.cv"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pcv>tendanceFiche.cv"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheCv(ficheCv)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Disponibilité</td>
                        <td class="text-center" [ngClass]="{'up': pdisponibilite>60, 'down': pdisponibilite<=30, 'medium': (pdisponibilite>30 && pdisponibilite<=60)}">{{pdisponibilite | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.disponibilite}}</td>
                        <td>
                            <div class="const" *ngIf="pdisponibilite===tendanceFiche.disponibilite"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pdisponibilite<tendanceFiche.disponibilite"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pdisponibilite>tendanceFiche.disponibilite"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheDispo(ficheDispo)"></i></td>
                    </tr>


                    <tr>
                        <td class="pl-3">Titre</td>
                        <td class="text-center" [ngClass]="{'up': (globalQualiteData.titre/globalQualiteData.total)*100>60, 'down': (globalQualiteData.titre/globalQualiteData.total)*100<=30, 'medium': ((globalQualiteData.titre/globalQualiteData.total)*100>30 && (globalQualiteData.titre/globalQualiteData.total)*100<=60)}">{{(globalQualiteData.titre/globalQualiteData.total)*100 | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.titre}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="(globalQualiteData.titre/globalQualiteData.total)*100===tendanceFiche.titre"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="(globalQualiteData.titre/globalQualiteData.total)*100<tendanceFiche.titre"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="(globalQualiteData.titre/globalQualiteData.total)*100>tendanceFiche.titre"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTitre(ficheTitre)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Date debut activité</td>
                        <td class="text-center" [ngClass]="{'up': pdda>60, 'down': pdda<=30, 'medium': (pdda>30 && pdda<=60)}">{{pdda | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.dda}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pdda===tendanceFiche.dda"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pdda<tendanceFiche.dda"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pdda>tendanceFiche.dda"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheDda(ficheDda)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">TJM</td>
                        <td class="text-center" [ngClass]="{'up': ptjm>60, 'down': ptjm<=30, 'medium': (ptjm>30 && ptjm<=60)}">{{ptjm | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.tjm}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="ptjm===tendanceFiche.tjm"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="ptjm<tendanceFiche.tjm"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="ptjm>tendanceFiche.tjm"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTjm(ficheTjm)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Technos</td>
                        <td class="text-center" [ngClass]="{'up': ptechno>60, 'down': ptechno<=30, 'medium': (ptechno>30 && ptechno<=60)}">{{ptechno | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.techno}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="ptechno===tendanceFiche.techno"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="ptechno<tendanceFiche.techno"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="ptechno>tendanceFiche.techno"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTechno(ficheTechno)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags techniques</td>
                        <td class="text-center" [ngClass]="{'up': ptagtech>60, 'down': ptagtech<=30, 'medium': (ptagtech>30 && ptagtech<=60)}">{{ptagtech | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.tagtech}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="ptagtech===tendanceFiche.tagtech"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="ptagtech<tendanceFiche.tagtech"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="ptagtech>tendanceFiche.tagtech"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTagtech(ficheTagtech)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags metiers</td>
                        <td class="text-center" [ngClass]="{'up': ptagmetier>60, 'down': ptagmetier<=30, 'medium': (ptagmetier>30 && ptagmetier<=60)}">{{ptagmetier | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.tagmetier}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="ptagmetier===tendanceFiche.tagmetier"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="ptagmetier<tendanceFiche.tagmetier"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="ptagmetier>tendanceFiche.tagmetier"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTagmetier(ficheTagmetier)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags fonctions</td>
                        <td class="text-center" [ngClass]="{'up': ptagfonc>60, 'down': ptagfonc<=30, 'medium': (ptagfonc>30 && ptagfonc<=60)}">{{ptagfonc | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.tagfonc}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="ptagfonc===tendanceFiche.tagfonc"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="ptagfonc<tendanceFiche.tagfonc"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="ptagfonc>tendanceFiche.tagfonc"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheTagfonc(ficheTagfonc)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Clients</td>
                        <td class="text-center" [ngClass]="{'up': pclient>60, 'down': pclient<=30, 'medium': (pclient>30 && pclient<=60)}">{{pclient | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{globalQualiteData.client}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pclient===tendanceFiche.client"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pclient<tendanceFiche.client"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pclient>tendanceFiche.client"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openFicheClient(ficheClient)"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-md-6">
            <div class="text-center mb-2 title">Alignement des Renseignements Fiches Consultants par rapport aux CVs</div>
            <table class="table mb-4">
                <thead class="table-primary">
                    <th>Champs</th>
                    <th>Taux d'alignement</th>
                    <th>Nombre</th>
                    <th>Tendance</th>
                    <th>Consultants</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="pl-3">Technologies</td>
                        <td class="text-center" [ngClass]="{'up': patechno>60, 'down': patechno<=30, 'medium': (patechno>30 && patechno<=60)}">{{patechno | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{aligneData.techno.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="patechno===aligneTendance.techno"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="patechno<aligneTendance.techno"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="patechno>aligneTendance.techno"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openAlignTechno(alignTechno)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags techniques</td>
                        <td class="text-center" [ngClass]="{'up': patagtech>60, 'down': patagtech<=30, 'medium': (patagtech>30 && patagtech<=60)}">{{patagtech | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{aligneData.tagtech.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="patagtech===aligneTendance.tagtech"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="patagtech<aligneTendance.tagtech"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="patagtech>aligneTendance.tagtech"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openAlignTagtech(alignTagtech)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags Métiers</td>
                        <td class="text-center" [ngClass]="{'up': patagmetier>60, 'down': patagmetier<=30, 'medium': (patagmetier>30 && patagmetier<=60)}">{{patagmetier | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{aligneData.tagmetier.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="patagmetier===aligneTendance.tagmetier"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="patagmetier<aligneTendance.tagmetier"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="patagmetier>aligneTendance.tagmetier"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openAlignTagmetier(alignTagmetier)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags Fonctions</td>
                        <td class="text-center" [ngClass]="{'up': patagfonc>60, 'down': patagfonc<=30, 'medium': (patagfonc>30 && patagfonc<=60)}">{{patagfonc | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{aligneData.tagfonc.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="patagfonc===aligneTendance.tagfonc"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="patagfonc<aligneTendance.tagfonc"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="patagfonc>aligneTendance.tagfonc"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openAlignTagfonc(alignTagfonc)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Clients</td>
                        <td class="text-center" [ngClass]="{'up': paclient>60, 'down': paclient<=30, 'medium': (paclient>30 && paclient<=60)}">{{paclient | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{aligneData.client.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="paclient===aligneTendance.client"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="paclient<aligneTendance.client"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="paclient>aligneTendance.client"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openAlignClient(alignClient)"></i></td>
                    </tr>
                </tbody>
            </table>

            <div class="text-center mb-2 title">Absence de Renseignements Fiches Consultants par rapport aux CVs</div>
            <table class="table mb-4">
                <thead class="table-primary">
                    <th>Champs</th>
                    <th>Taux d'absence</th>
                    <th>Nombre</th>
                    <th>Tendance</th>
                    <th>Consultants</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="pl-3">Technologies</td>
                        <td class="text-center" [ngClass]="{'up': pntechno>60, 'down': pntechno<=30, 'medium': (pntechno>30 && pntechno<=60)}">{{pntechno | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{nonaligneData.techno.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pntechno===nonaligneTendance.techno"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pntechno<nonaligneTendance.techno"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pntechno>nonaligneTendance.techno"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openNAlignTechno(nalignTechno)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags techniques</td>
                        <td class="text-center" [ngClass]="{'up': pntagtech>60, 'down': pntagtech<=30, 'medium': (pntagtech>30 && pntagtech<=60)}">{{pntagtech | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{nonaligneData.tagtech.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pntagtech===nonaligneTendance.tagtech"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pntagtech<nonaligneTendance.tagtech"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pntagtech>nonaligneTendance.tagtech"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openNAlignTagtech(nalignTagtech)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags Métiers</td>
                        <td class="text-center" [ngClass]="{'up': pntagmetier>60, 'down': pntagmetier<=30, 'medium': (pntagmetier>30 && pntagmetier<=60)}">{{pntagmetier | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{nonaligneData.tagmetier.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pntagmetier===nonaligneTendance.tagmetier"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pntagmetier<nonaligneTendance.tagmetier"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pntagmetier>nonaligneTendance.tagmetier"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openNAlignTagmetier(nalignTagmetier)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Tags Fonctions</td>
                        <td class="text-center" [ngClass]="{'up': pntagfonc>60, 'down': pntagfonc<=30, 'medium': (pntagfonc>30 && pntagfonc<=60)}">{{pntagfonc | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{nonaligneData.tagfonc.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pntagfonc===nonaligneTendance.tagfonc"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pntagfonc<nonaligneTendance.tagfonc"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pntagfonc>nonaligneTendance.tagfonc"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openNAlignTagfonc(nalignTagfonc)"></i></td>
                    </tr>
                    <tr>
                        <td class="pl-3">Clients</td>
                        <td class="text-center" [ngClass]="{'up': pnclient>60, 'down': pnclient<=30, 'medium': (pnclient>30 && pnclient<=60)}">{{pnclient | number:'1.0-0'}} %</td>
                        <td class="font-weight-bold text-center">{{nonaligneData.client.length}}</td>
                        <td class="font-weight-bold text-center">
                            <div class="const" *ngIf="pnclient===nonaligneTendance.client"><i class="bi bi-arrow-right"></i></div>
                            <div class="down" *ngIf="pnclient<nonaligneTendance.client"><i class="bi bi-arrow-down-right"></i></div>
                            <div class="up text-center" *ngIf="pnclient>nonaligneTendance.client"><i class="bi bi-arrow-up-right"></i></div>
                        </td>
                        <td class="font-weight-bold text-center"><i class="bi bi-person-lines-fill" style="font-size: 20px;" (click)="openNAlignClient(nalignClient)"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>


<ng-template #ficheEmail let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants avec email ou téléphone non renseigné</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Email</th>
                <th>Telephone</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.email !== ''">{{consultant.email}}</span><span class="text-danger" *ngIf="consultant.email === ''">Pas d'email renseigné</span></td>
                    <td><span *ngIf="consultant.telephone_principal !== ''">{{consultant.telephone_principal}}</span><span class="text-danger" *ngIf="consultant.telephone_principal === ''">Pas de téléphone renseigné</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages1.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages1.length>=2 && page1>0">
                        <a class="nav-link" href="" (click)="setPage1(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages1.length>=2 && page1>0">
                        <a class="nav-link" href="" (click)="setPage1(page1-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages1; let i=index">
                        <a class="nav-link" *ngIf="i===page1 || i===page1-1 || i===page1+1" [ngClass]="{active: i===page1}" href="" (click)="setPage1(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages1.length-1>page1">
                        <a class="nav-link" href="" (click)="setPage1(page1+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages1.length-1>page1">
                        <a class="nav-link" href="" (click)="setPage1(pages1.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page1+1}} sur {{pages1.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size" #selectSize1 [ngModel]="size1" (change)="setSize1(+(selectSize1.value))">
                <option value="5">5 elts/page</option>
                <option value="10">10 elts/page</option>
                <option value="15">15 elts/page</option>
            </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheQualite let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants avec qualité de CV neutre</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages2.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages2.length>=2 && page2>0">
                        <a class="nav-link" href="" (click)="setPage2(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages2.length>=2 && page2>0">
                        <a class="nav-link" href="" (click)="setPage2(page2-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages2; let i=index">
                        <a class="nav-link" *ngIf="i===page2 || i===page2-1 || i===page2+1" [ngClass]="{active: i===page2}" href="" (click)="setPage2(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages2.length-1>page2">
                        <a class="nav-link" href="" (click)="setPage2(page2+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages2.length-1>page2">
                        <a class="nav-link" href="" (click)="setPage2(pages2.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page2+1}} sur {{pages2.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size2" #selectSize2 [ngModel]="size2" (change)="setSize2(+(selectSize2.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheCv let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas de CV enregistré</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages3.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages3.length>=2 && page3>0">
                        <a class="nav-link" href="" (click)="setPage3(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages3.length>=2 && page3>0">
                        <a class="nav-link" href="" (click)="setPage3(page3-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages3; let i=index">
                        <a class="nav-link" *ngIf="i===page3 || i===page3-1 || i===page3+1" [ngClass]="{active: i===page3}" href="" (click)="setPage3(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages3.length-1>page3">
                        <a class="nav-link" href="" (click)="setPage3(page3+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages3.length-1>page3">
                        <a class="nav-link" href="" (click)="setPage3(pages3.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page3+1}} sur {{pages3.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size3" #selectSize3 [ngModel]="size3" (change)="setSize3(+(selectSize3.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheDispo let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas enregistré de disponibilité</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages4.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages4.length>=2 && page4>0">
                        <a class="nav-link" href="" (click)="setPage4(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages4.length>=2 && page4>0">
                        <a class="nav-link" href="" (click)="setPage4(page4-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages4; let i=index">
                        <a class="nav-link" *ngIf="i===page4 || i===page4-1 || i===page4+1" [ngClass]="{active: i===page4}" href="" (click)="setPage4(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages4.length-1>page4">
                        <a class="nav-link" href="" (click)="setPage4(page4+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages4.length-1>page4">
                        <a class="nav-link" href="" (click)="setPage4(pages4.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page4+1}} sur {{pages4.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size4" #selectSize4 [ngModel]="size4" (change)="setSize4(+(selectSize4.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTitre let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de titre</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages5.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages5.length>=2 && page5>0">
                        <a class="nav-link" href="" (click)="setPage5(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages5.length>=2 && page5>0">
                        <a class="nav-link" href="" (click)="setPage5(page5-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages5; let i=index">
                        <a class="nav-link" *ngIf="i===page5 || i===page5-1 || i===page5+1" [ngClass]="{active: i===page5}" href="" (click)="setPage5(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages5.length-1>page5">
                        <a class="nav-link" href="" (click)="setPage5(page5+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages5.length-1>page5">
                        <a class="nav-link" href="" (click)="setPage5(pages5.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page5+1}} sur {{pages5.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size5" #selectSize5 [ngModel]="size5" (change)="setSize5(+(selectSize5.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheDda let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de date de debut d'activité</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages6.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages6.length>=2 && page6>0">
                        <a class="nav-link" href="" (click)="setPage6(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages6.length>=2 && page6>0">
                        <a class="nav-link" href="" (click)="setPage6(page6-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages6; let i=index">
                        <a class="nav-link" *ngIf="i===page6 || i===page6-1 || i===page6+1" [ngClass]="{active: i===page6}" href="" (click)="setPage6(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages6.length-1>page6">
                        <a class="nav-link" href="" (click)="setPage6(page6+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages6.length-1>page6">
                        <a class="nav-link" href="" (click)="setPage6(pages6.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page6+1}} sur {{pages6.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size6" #selectSize6 [ngModel]="size6" (change)="setSize6(+(selectSize6.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTjm let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de TJM</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages7.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages7.length>=2 && page7>0">
                        <a class="nav-link" href="" (click)="setPage7(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages7.length>=2 && page7>0">
                        <a class="nav-link" href="" (click)="setPage7(page7-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages7; let i=index">
                        <a class="nav-link" *ngIf="i===page7 || i===page7-1 || i===page7+1" [ngClass]="{active: i===page7}" href="" (click)="setPage7(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages7.length-1>page7">
                        <a class="nav-link" href="" (click)="setPage7(page7+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages7.length-1>page7">
                        <a class="nav-link" href="" (click)="setPage7(pages7.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page7+1}} sur {{pages7.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size7" #selectSize7 [ngModel]="size7" (change)="setSize7(+(selectSize7.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTechno let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de technologie</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages8.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages8.length>=2 && page8>0">
                        <a class="nav-link" href="" (click)="setPage8(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages8.length>=2 && page8>0">
                        <a class="nav-link" href="" (click)="setPage8(page8-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages8; let i=index">
                        <a class="nav-link" *ngIf="i===page8 || i===page8-1 || i===page8+1" [ngClass]="{active: i===page8}" href="" (click)="setPage8(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages8.length-1>page8">
                        <a class="nav-link" href="" (click)="setPage8(page8+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages8.length-1>page8">
                        <a class="nav-link" href="" (click)="setPage8(pages8.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page8+1}} sur {{pages8.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size8" #selectSize8 [ngModel]="size8" (change)="setSize8(+(selectSize8.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTagtech let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de tag technique</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages9.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages9.length>=2 && page9>0">
                        <a class="nav-link" href="" (click)="setPage9(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages9.length>=2 && page9>0">
                        <a class="nav-link" href="" (click)="setPage9(page9-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages9; let i=index">
                        <a class="nav-link" *ngIf="i===page9 || i===page9-1 || i===page9+1" [ngClass]="{active: i===page9}" href="" (click)="setPage9(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages9.length-1>page9">
                        <a class="nav-link" href="" (click)="setPage9(page9+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages9.length-1>page9">
                        <a class="nav-link" href="" (click)="setPage9(pages9.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page9+1}} sur {{pages9.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size9" #selectSize9 [ngModel]="size9" (change)="setSize9(+(selectSize9.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTagmetier let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de tag metier</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages10.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages10.length>=2 && page10>0">
                        <a class="nav-link" href="" (click)="setPage10(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages10.length>=2 && page10>0">
                        <a class="nav-link" href="" (click)="setPage10(page10-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages10; let i=index">
                        <a class="nav-link" *ngIf="i===page10 || i===page10-1 || i===page10+1" [ngClass]="{active: i===page10}" href="" (click)="setPage10(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages10.length-1>page10">
                        <a class="nav-link" href="" (click)="setPage10(page10+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages10.length-1>page10">
                        <a class="nav-link" href="" (click)="setPage10(pages10.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page10+1}} sur {{pages10.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size10" #selectSize10 [ngModel]="size10" (change)="setSize10(+(selectSize10.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheTagfonc let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de tag fonction</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages11.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages11.length>=2 && page11>0">
                        <a class="nav-link" href="" (click)="setPage11(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages11.length>=2 && page11>0">
                        <a class="nav-link" href="" (click)="setPage11(page11-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages11; let i=index">
                        <a class="nav-link" *ngIf="i===page11 || i===page11-1 || i===page11+1" [ngClass]="{active: i===page11}" href="" (click)="setPage11(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages11.length-1>page11">
                        <a class="nav-link" href="" (click)="setPage11(page11+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages11.length-1>page11">
                        <a class="nav-link" href="" (click)="setPage11(pages11.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page11+1}} sur {{pages11.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size11" #selectSize11 [ngModel]="size11" (change)="setSize11(+(selectSize11.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>

<ng-template #ficheClient let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultant n'ayant pas renseigné de clients</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages12.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages12.length>=2 && page12>0">
                        <a class="nav-link" href="" (click)="setPage12(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages12.length>=2 && page12>0">
                        <a class="nav-link" href="" (click)="setPage12(page12-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages12; let i=index">
                        <a class="nav-link" *ngIf="i===page12 || i===page12-1 || i===page12+1" [ngClass]="{active: i===page12}" href="" (click)="setPage12(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages12.length-1>page12">
                        <a class="nav-link" href="" (click)="setPage12(page12+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages12.length-1>page12">
                        <a class="nav-link" href="" (click)="setPage12(pages12.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page12+1}} sur {{pages12.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size12" #selectSize12 [ngModel]="size12" (change)="setSize12(+(selectSize12.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>






<ng-template #alignTechno let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants alignés aux cvs par rapport aux technologies</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages13.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages13.length>=2 && page13>0">
                        <a class="nav-link" href="" (click)="setPage13(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages13.length>=2 && page13>0">
                        <a class="nav-link" href="" (click)="setPage13(page13-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages13; let i=index">
                        <a class="nav-link" *ngIf="i===page13 || i===page13-1 || i===page13+1" [ngClass]="{active: i===page13}" href="" (click)="setPage13(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages13.length-1>page13">
                        <a class="nav-link" href="" (click)="setPage13(page13+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages13.length-1>page13">
                        <a class="nav-link" href="" (click)="setPage13(pages13.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page13+1}} sur {{pages13.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size13" #selectSize13 [ngModel]="size13" (change)="setSize13(+(selectSize13.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>



<ng-template #alignTagtech let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants alignés aux cvs par rapport aux tags techniques</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages14.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages14.length>=2 && page14>0">
                        <a class="nav-link" href="" (click)="setPage14(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages14.length>=2 && page14>0">
                        <a class="nav-link" href="" (click)="setPage14(page14-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages14; let i=index">
                        <a class="nav-link" *ngIf="i===page14 || i===page14-1 || i===page14+1" [ngClass]="{active: i===page14}" href="" (click)="setPage14(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages14.length-1>page14">
                        <a class="nav-link" href="" (click)="setPage14(page14+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages14.length-1>page14">
                        <a class="nav-link" href="" (click)="setPage14(pages14.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page14+1}} sur {{pages14.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size14" #selectSize14 [ngModel]="size14" (change)="setSize14(+(selectSize14.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>



<ng-template #alignTagmetier let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants alignés aux cvs par rapport aux tags metiers</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages15.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages15.length>=2 && page15>0">
                        <a class="nav-link" href="" (click)="setPage15(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages15.length>=2 && page15>0">
                        <a class="nav-link" href="" (click)="setPage15(page15-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages15; let i=index">
                        <a class="nav-link" *ngIf="i===page15 || i===page15-1 || i===page15+1" [ngClass]="{active: i===page15}" href="" (click)="setPage15(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages15.length-1>page15">
                        <a class="nav-link" href="" (click)="setPage15(page15+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages15.length-1>page15">
                        <a class="nav-link" href="" (click)="setPage15(pages15.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page15+1}} sur {{pages15.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size15" #selectSize15 [ngModel]="size15" (change)="setSize15(+(selectSize15.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>



<ng-template #alignTagfonc let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants alignés aux cvs par rapport aux tags fonctions</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages16.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages16.length>=2 && page16>0">
                        <a class="nav-link" href="" (click)="setPage16(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages16.length>=2 && page16>0">
                        <a class="nav-link" href="" (click)="setPage16(page16-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages16; let i=index">
                        <a class="nav-link" *ngIf="i===page16 || i===page16-1 || i===page16+1" [ngClass]="{active: i===page16}" href="" (click)="setPage16(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages16.length-1>page16">
                        <a class="nav-link" href="" (click)="setPage16(page16+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages16.length-1>page16">
                        <a class="nav-link" href="" (click)="setPage16(pages16.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page16+1}} sur {{pages16.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size16" #selectSize16 [ngModel]="size16" (change)="setSize16(+(selectSize16.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>



<ng-template #alignClient let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants alignés aux cvs par rapport aux clients</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages17.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages17.length>=2 && page17>0">
                        <a class="nav-link" href="" (click)="setPage17(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages17.length>=2 && page17>0">
                        <a class="nav-link" href="" (click)="setPage17(page17-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages17; let i=index">
                        <a class="nav-link" *ngIf="i===page17 || i===page17-1 || i===page17+1" [ngClass]="{active: i===page17}" href="" (click)="setPage17(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages17.length-1>page17">
                        <a class="nav-link" href="" (click)="setPage17(page17+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages17.length-1>page17">
                        <a class="nav-link" href="" (click)="setPage17(pages17.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page17+1}} sur {{pages17.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size17" #selectSize17 [ngModel]="size17" (change)="setSize17(+(selectSize17.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>











<ng-template #nalignTechno let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants non alignés aux cvs par rapport aux technologies</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages18.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages18.length>=2 && page18>0">
                        <a class="nav-link" href="" (click)="setPage18(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages18.length>=2 && page18>0">
                        <a class="nav-link" href="" (click)="setPage18(page18-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages18; let i=index">
                        <a class="nav-link" *ngIf="i===page18 || i===page18-1 || i===page18+1" [ngClass]="{active: i===page18}" href="" (click)="setPage18(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages18.length-1>page18">
                        <a class="nav-link" href="" (click)="setPage18(page18+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages18.length-1>page18">
                        <a class="nav-link" href="" (click)="setPage18(pages18.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page18+1}} sur {{pages18.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size18" #selectSize18 [ngModel]="size18" (change)="setSize18(+(selectSize18.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>


<ng-template #nalignTagtech let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants non alignés aux cvs par rapport aux tags techniques</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages19.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages19.length>=2 && page19>0">
                        <a class="nav-link" href="" (click)="setPage19(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages19.length>=2 && page19>0">
                        <a class="nav-link" href="" (click)="setPage19(page19-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages19; let i=index">
                        <a class="nav-link" *ngIf="i===page19 || i===page19-1 || i===page19+1" [ngClass]="{active: i===page19}" href="" (click)="setPage19(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages19.length-1>page19">
                        <a class="nav-link" href="" (click)="setPage19(page19+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages19.length-1>page19">
                        <a class="nav-link" href="" (click)="setPage19(pages19.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page19+1}} sur {{pages19.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size19" #selectSize19 [ngModel]="size19" (change)="setSize19(+(selectSize19.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>


<ng-template #nalignTagmetier let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants non alignés aux cvs par rapport aux tags metiers</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages20.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages20.length>=2 && page20>0">
                        <a class="nav-link" href="" (click)="setPage20(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages20.length>=2 && page20>0">
                        <a class="nav-link" href="" (click)="setPage20(page20-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages20; let i=index">
                        <a class="nav-link" *ngIf="i===page20 || i===page20-1 || i===page20+1" [ngClass]="{active: i===page20}" href="" (click)="setPage20(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages20.length-1>page20">
                        <a class="nav-link" href="" (click)="setPage20(page20+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages20.length-1>page20">
                        <a class="nav-link" href="" (click)="setPage20(pages20.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page20+1}} sur {{pages20.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size20" #selectSize20 [ngModel]="size20" (change)="setSize20(+(selectSize20.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>


<ng-template #nalignTagfonc let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants non alignés aux cvs par rapport aux tags fonctions</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages21.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages21.length>=2 && page21>0">
                        <a class="nav-link" href="" (click)="setPage21(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages21.length>=2 && page21>0">
                        <a class="nav-link" href="" (click)="setPage21(page21-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages21; let i=index">
                        <a class="nav-link" *ngIf="i===page21 || i===page21-1 || i===page21+1" [ngClass]="{active: i===page21}" href="" (click)="setPage21(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages21.length-1>page21">
                        <a class="nav-link" href="" (click)="setPage21(page21+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages21.length-1>page21">
                        <a class="nav-link" href="" (click)="setPage21(pages21.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page21+1}} sur {{pages21.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size21" #selectSize21 [ngModel]="size21" (change)="setSize21(+(selectSize21.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>


<ng-template #nalignClient let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Consultants non alignés aux cvs par rapport aux clients</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="background-color: white;">

        <table class="table mb-4">
            <thead class="table-primary">
                <th>Nom</th>
                <th>Prenom</th>
                <th>Titre</th>
                <th>Qualité CV</th>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of consultants1" (click)="ouvrirFiche(consultant)">
                    <td>{{consultant.nom}}</td>
                    <td>{{consultant.prenom}}</td>
                    <td>{{consultant.titre}}</td>
                    <td><span *ngIf="consultant.qualitecv">{{consultant.qualitecv.libelle}}</span><span class="text-danger" *ngIf="!consultant.qualitecv">Pas de qualité</span></td>
                </tr>
            </tbody>
        </table>

        <div class="row d-flex" *ngIf="pages22.length>0">
            <div class="col-md-4 d-flex justify-content-center">
                <ul class="nav nav-pills">
                    <li class="nav-item" *ngIf="pages22.length>=2 && page22>0">
                        <a class="nav-link" href="" (click)="setPage22(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages22.length>=2 && page22>0">
                        <a class="nav-link" href="" (click)="setPage22(page22-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>


                    <li class="nav-item" *ngFor="let p of pages22; let i=index">
                        <a class="nav-link" *ngIf="i===page22 || i===page22-1 || i===page22+1" [ngClass]="{active: i===page22}" href="" (click)="setPage22(i, $event)">{{i+1}}</a>
                    </li>


                    <li class="nav-item" *ngIf="pages22.length-1>page22">
                        <a class="nav-link" href="" (click)="setPage22(page22+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item" *ngIf="pages22.length-1>page22">
                        <a class="nav-link" href="" (click)="setPage22(pages22.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                page {{page22+1}} sur {{pages22.length}}
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <select class="form-select form-select-sm" name="size22" #selectSize22 [ngModel]="size22" (change)="setSize22(+(selectSize22.value))">
            <option value="5">5 elts/page</option>
            <option value="10">10 elts/page</option>
            <option value="15">15 elts/page</option>
        </select>
            </div>
        </div>

    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Fermer</button>
    </div>
</ng-template>