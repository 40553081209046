import { Component, OnInit, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MatPaginator, MatDialog } from '@angular/material';
import {MatSort} from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { ConsultantService } from '../services/consultant.service';
import { RechercheService } from '../services/recherche.service';

import { Router } from '@angular/router';
import { QualiteService } from '../services/qualite.service';
import { DisponibiliteService } from '../services/disponibilite.service';
import { CommunicationService } from '../services/communication.service';
import { Consultant } from '../Model/Consultant';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import {PopupdataComponent} from '../popupdata/popupdata.component'
import {Title} from "@angular/platform-browser";
import { TokenStorageService } from '../services/token-storage.service';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { BASE_URL } from '../config';
import * as XLSX from 'xlsx';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}



export interface Dispo {
  value: string;
  name: string;
}

export interface Quali {
  value: string;
  name: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
  })
  export class HomeComponent implements OnInit {
    consultants: any = [];
    newArray: any = [];
    test: string;
    total: string;
    sortBy:string;

    Dispo: Dispo[] = [
      {value:"",name:"Tout"}
    ];
    showfilter=false

    Quali: Quali[] = [
      {value:"",name:"Tout"}
    ];
    Clients = [];
    TagTech = [];
    TagMetier = [];
    TagFonc = [];
    Tech = [];
    currentItemsToShow: Element[] = [];
    shownext=false;
    showprevious=false
    showlastpage=false;
    showfirstpage=false
    filtest: boolean = false;
    isExporting: boolean = false;





    //
    titrepopup;
    //

    constructor(public us: ConsultantService, public communicationService: CommunicationService,
               private titleService:Title,  private rs: RechercheService, public ts: QualiteService, public ds: DisponibiliteService,
                private SpinnerService: NgxSpinnerService,public dialog: MatDialog,private tokenStorageService: TokenStorageService) {

                  this.communicationService.Broadcast('OnChild8', 'Accueil');
     }

     title = 'table';


      //dataSource = new MatTableDataSource(this.data);
      isquali = false;
      isnom = false;
      isprenom = false;
      istitre = false;
      istech = false;
      isdispo = false;
      isclient = false;
      isexp = false;
      isobservation = false;
      isdmaj = false;
      isemail = false;
      iscode_postal = false;
      istelephone_principal = false;
      nbrfilter = 0;
      titredesc=false
      clientdesc=false
      postaldesc=false
      emaildesc=false
      nomdesc=false
      prenomdesc=false
      qualitedesc=false
      teldesc=false
      dispodesc=false
      modifdesc=false
      obsdesc=false
      expdesc=false
      techdesc=false
      trigdesc=false

  //Couleur
    isQualiteFilter = false;
    isNomFilter  = false;
    isPrenomFilter = false;
    isTitreFilter = false;
    isTechFilter = false;
    isExpFilter = false;
    isDispoFilter = false;
    isClientFilter = false;
    isObsFilter = false;
    idDateFilter = false;



      orderdesc="dateDerniereMaj";
      orderasc="dateDerniereMajg";
      dispoFilter = new FormControl();
      qualiFilter = new FormControl();
      clientFilter = new FormControl("");
      tagtechFilter = new FormControl("");
      tagmetierFilter = new FormControl("");
      tagfoncFilter = new FormControl("");
      observationFilter = new FormControl("");
      techFilter = new FormControl("");
      expFilter = new FormControl();
      nameFilter = new FormControl("");
      prenomFilter = new FormControl("");
      titreFilter = new FormControl("");
      emailFilter = new FormControl("");
      telephone_principalFilter = new FormControl("");
      code_postalFilter = new FormControl("");
      date_derniere_majFilter = new FormControl("");
      globalFilter = '';
      pagination=0;
      qualitecv:any="";
      exp:any=""
      disponibilite:any="";
      filteredValues = {
        _id: '',
        nom: '',
        prenom: '',
        titre: '',
        code_dispo: '',
        code_qualite: '',
        tjm: '',
        telephone_principal: '',
        email: '',
        linkedin: '',
        code_postal: '',
        ville: '',
        cv: '',
        dateDerniereMaj: '',
        tech: '',
        client: '',
        observation: '',
        tagtech: '',
        tagmetier: '',
        tagfonc: '',
        exp:''
      };

       /* Techno Pagination */

       @ViewChildren("inputs") inputs: QueryList<ElementRef>;
       totalElementsConsultant: number = 0;
       ConsultantPage: Consultant[] = [];
       loadingConsultant: boolean;
       dispos=[{value:"",name:"Tout"},{value:"^(?!.*(ans)).*$",name:"N.R"}]
       suivantConsultant = 0;
       regex: string = "";
       sizeConsultant: number = 100;
       totalPage: number;
       ConsultantPageUpdate: Consultant[] = [];
       ConsultantFilter: Consultant[] = [];
       public pageSize = 120;
       public currentPage = 1;
       public totalSize = 0;
       styleState=false

       pageSizeElement = [30,86,120]
       width;
       height;
       ngOnInit() {

        //Récupération de la largeur et la hauteur de l'écran
         let {width,height} = window.screen
         this.width = width;
         this.height = height;

         //changement du nom d'élément par page ainsi que de la liste des éléments par page
         //en fonction de la taille de l'écran
         if((width>=1366*0.9 && width<=1366*1.1) && (height>=768*0.9 && height<=768*1.1) ){
           this.pageSizeElement = [25,50,100, 120]
           this.pageSize=120
         }
         else if((width>=1920*0.9 && width<=1920*1.1) && (height>=1080*0.9 && height<=1080*1.1) ){
           this.pageSizeElement = [30,60,120]
           this.pageSize=120
         }

      window.scroll(0,0);
      this.loader()

      this.titleService.setTitle("Portail RH")

      //Récupération des données en base  (liste des consultants)
      this.Search();

      //Récupératon de la liste des disponibilté
      this.getDisponibilite();

      //Récupération de la liste des qualités Cvs
      this.getQualite();

      //Récupération de la liste des expériences
      this.getexp()
    }



  /**
 * Fonction qui fait appel au Loader  pour le chargement des données
 */
    loader(){
      this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {

          message: `
           Chargement en cours`

        },
        disableClose: true,
      });
    }



  /**
 * Récupération de la liste des expérience
 * @return {void}
 */
    getexp(){
      this.rs.getexperience().subscribe((data)=>{

        for (const i in data) {
          this.dispos.push({ value: data[i].libelle, name: data[i].libelle });
      }
      })

    }


  /**
   * Unitile
 */
    emitPagination(e){
      this.gotoTop()
      this.currentPage=e.pageIndex
      this.pageSize=e.pageSize
      this.currentPage=this.currentPage+1
          this.goto(e.pageIndex+1);



          this.iterator();
            }
            private iterator() {
              (async () => {
                const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
                  data: {
                    message: "Chargement en cours..."
                  },
                });
                await delay(2000);
                dialogRef.close();
              })();

  }
    getConsultantPage(request) {
      this.loadingConsultant = true;
     // this.SpinnerService.show();
      this.us.Consultants(request)
        .subscribe(data => {

          this.ConsultantPage = data['content'];

          this.totalElementsConsultant = data['totalElements'];

          this.loadingConsultant = false;
         // this.SpinnerService.hide();
        }, error => {
          this.loadingConsultant = false;
        }
        );
    }


//Changer la couleur des mots de la recherche
   yvesColorEltSearch(index,data){

      data[index]['nom'] =  (this.nameFilter.value != "")? data[index]['nom'].replace(new RegExp('('+this.escapeRegExp(this.nameFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['nom']
      data[index]['prenom'] =  ( this.prenomFilter.value != "")? data[index]['prenom'].toString().replace(new RegExp('('+this.escapeRegExp(this.prenomFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :  data[index]['prenom']
      data[index]['titre'] =  ( this.titreFilter.value != "")?  data[index]['titre'].toString().replace(new RegExp('('+this.escapeRegExp(this.titreFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['titre']
      data[index]['tech'] =  (this.techFilter.value != "")?  (data[index]['tech'] ?? "").toString().replace(new RegExp('('+this.escapeRegExp(this.techFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['tech']
      data[index]['exp'] =  (this.expFilter.value != "")? (data[index]['exp'] ?? "").toString().replace(new RegExp('('+this.escapeRegExp(this.expFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['exp']
      data[index]['codedispo']["libelle"] =  (this.dispoFilter.value != "")? data[index]['codedispo']["libelle"].replace(new RegExp('('+this.escapeRegExp(this.dispoFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['codedispo']["libelle"]
      data[index]['listClient'] =  (this.clientFilter.value != "")?data[index]['listClient'].toString().replace(new RegExp('('+this.escapeRegExp(this.clientFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['listClient']
      data[index]['derniereObs'] =  (this.observationFilter.value != "")?  data[index]['derniereObs'].toString().replace(new RegExp('('+this.escapeRegExp(this.observationFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['derniereObs']
      data[index]['dateDerniereMaj'] =  (this.date_derniere_majFilter.value != "")? data[index]['dateDerniereMaj'].toString().replace(new RegExp('('+this.escapeRegExp(this.date_derniere_majFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['dateDerniereMaj']

  }


   escapeRegExp(s) {
     if(s!=null){
      return s.replace(/[\\^$.*+?()[\]{}|]/g,"\\$&");

     }
     return ""
  }
    getUsers(data2) {
      this.consultants = [];
      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();

    this.consultants = data2.consultants;


      //Récupération des clients , technologies et observation afin dans une liste
      //Ensuite convertir la liste en chain espacé par une virgule (),
    // tslint:disable-next-line: forin
          for (const i in data2.consultants) {

            let cli = new Array();
            let techno = new Array();
            let obsrv = new Array();
            for(const j in data2.consultants[i].client){
              cli.push(data2.consultants[i].client[j].libelle);
            }
            for(const j in data2.consultants[i].tech){
              techno.push(data2.consultants[i].tech[j].libelle);
            }
            for(const j in data2.consultants[i].observation){
              obsrv.push(data2.consultants[i].observation[j].libelleObs);
            }

            let cl = "";
            let tech = "";
            let obs = "";
            cl = cli.join(", ");
            tech = techno.join(", ");
            obs = obsrv.join(",");


            this.consultants[i].client = cl;
            this.consultants[i].tech = tech;
            //this.consultants[i].observation = obs;
            if(this.consultants[i].observation && this.consultants[i].observation.length > 0){
              let obstab: any[] = [];
              obstab = [...this.consultants[i].observation]

              //Classement des Observations de la plus récente à la plus ancienne
              obstab = obstab.sort(function(a,b){
                if(new Date(a.dateObs) > new Date(b.dateObs)) return -1;
                if(new Date(a.dateObs) < new Date(b.dateObs)) return 1;
                return 0;
              })
              this.consultants[i].trig = obstab[0].trigrammeObs ;
            } else {
              this.consultants[i].trig = "";
            }
            this.test = this.consultants.length;
            this.total = this.consultants.length;


            this.yvesColorEltSearch(i,this.consultants)
          }
        this.dialog.closeAll()

        this.totalSize = data2.total;

        this.totalPage = data2.totalPage




  }


  /**
 *Récupération d'une ligne de la liste correspondant à un consultant puis
 //via son ID il y'a l'ouverture la fiche consultant dans un nouvel onglet
 * @param  {Consultant} row
 * @return {void}
 */

    Select(row) {
      //this.router.navigate(['/fichec'], { queryParams: { id: row._id } });
      window.open(BASE_URL+"/fichec?id="+row._id,"_blank")
  }

  getDisponibilite() {
    this.ds.getRefs().subscribe((data2: {}) => {
  // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push({ value: data2[i].libelle, name: data2[i].libelle });
    }
    });
  }
  getQualite() {
    this.ts.getRefs().subscribe((data2: {}) => {

  // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Quali.push({ value: data2[i].libelle, name: data2[i].libelle });
    }
    });

  }







    sort(e){
      this.sortBy = e;
      if(e==="trigrh"){
        this.trigdesc=!this.trigdesc
        if(this.trigdesc){
          this.orderasc = 'postakl';
          this.orderdesc='trigrh'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'trigrh';
          this.orderdesc='po5stal'
          this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.expdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.qualitedesc=false

      }
      if(e==="postal"){
        this.postaldesc=!this.postaldesc
        if(this.postaldesc){
          this.orderasc = 'postakl';
          this.orderdesc='postal'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'postal';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.expdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.qualitedesc=false
        this.trigdesc=false
      }
      if(e==="email"){
        this.emaildesc=!this.emaildesc
        if(this.emaildesc){
          this.orderasc = 'ema5il';
          this.orderdesc='email'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'email';
          this.orderdesc='ema5il'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.expdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.teldesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.trigdesc = false;
      }
      if(e==="nom"){
        this.nomdesc=!this.nomdesc
        if(this.nomdesc){
          this.orderasc = 'nokm';
          this.orderdesc='nom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'nom';
          this.orderdesc='nomm'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.expdesc=false
        this.postaldesc=false
        this.prenomdesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.teldesc=false
        this.qualitedesc=false
        this.trigdesc = false;
      }
      if(e==="prenom"){
        this.prenomdesc=!this.prenomdesc
        if(this.prenomdesc){
          this.orderasc = 'postakl';
          this.orderdesc='prenom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'prenom';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.expdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.postaldesc=false
        this.teldesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.trigdesc=false
      }
      if(e==="client"){
        this.clientdesc=!this.clientdesc
        if(this.clientdesc){
          this.orderasc = 'nokm';
          this.orderdesc='listClient'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'listClient';
          this.orderdesc='listClientm'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.expdesc=false
        this.emaildesc=false
        this.postaldesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.teldesc=false
        this.trigdesc=false
      }
      if(e==="titre"){
        this.titredesc=!this.titredesc
        if(this.titredesc){
          this.orderasc = 'titre5';
          this.orderdesc='titre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'titre';
          this.orderdesc='qua55lite'
          this.SearchTriFiltrage()
        }

        this.clientdesc=false
        this.dispodesc=false
        this.expdesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.teldesc=false
        this.trigdesc = false
      }
      if(e==="qualitecv"){
        this.qualitedesc=!this.qualitedesc
        if(this.qualitedesc){
          this.orderasc = 'null';
          this.orderdesc='qualitecv.ordre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'qualitecv.ordre';
          this.orderdesc='null'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.expdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.trigdesc=false
      }
      if(e==="tel"){
        this.teldesc=!this.teldesc
        if(this.teldesc){
          this.orderasc = 'postakl';
          this.orderdesc='telephone_pricipal'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'telephone_principal';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.expdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.trigdesc=false
      }
      if(e==="obs"){
        this.obsdesc=!this.obsdesc
        if(this.obsdesc){
          this.orderasc = 'postakl';
          this.orderdesc='derniereObs'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'derniereObs';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.expdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.teldesc=false
        this.dispodesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false

        this.techdesc=false
        this.trigdesc=false

      }
      if(e==="tech"){
        this.techdesc=!this.techdesc
        if(this.techdesc){
          this.orderasc = 'postakl';
          this.orderdesc='tech'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'tech';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.expdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.teldesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.trigdesc=false

      }

      if(e==="modif"){
        this.modifdesc=!this.modifdesc
        if(this.modifdesc){
          this.orderasc = 'postakl';
          this.orderdesc='dateDerniereMaj'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'dateDerniereMaj';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.expdesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.techdesc=false
        this.teldesc=false


        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.trigdesc=false

      }

      if(e==="dispo"){
        this.dispodesc=!this.dispodesc
        if(this.dispodesc){
          this.orderasc = 'postakl';
          this.orderdesc='codedispo.ordre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'codedispo.ordre';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.expdesc=false
        this.qualitedesc=false
        this.techdesc=false
        this.teldesc=false

        this.modifdesc=false
        this.obsdesc=false
        this.trigdesc = false

      }
      if(e==="exp"){
        this.expdesc=!this.expdesc
        if(this.expdesc){
          this.orderasc = 'postakl';
          this.orderdesc='exp'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'exp';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false

        this.qualitedesc=false
        this.techdesc=false
        this.teldesc=false

        this.modifdesc=false
        this.obsdesc=false
        this.trigdesc=false
      }

    }

    getByName(nom,prenom,dispo,qualite,email,postal,titre,client,tel,datamodif,observation,tech,exp,page) {
      const user = this.tokenStorageService.getUser()
      this.consultants = [];

      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();

      this.us.GetAllConsultants(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page-1,this.pageSize,datamodif,observation,tech,exp,this.orderdesc,this.orderasc,user.trigramme
      ).subscribe(data2 => {


       this.getUsers(data2)


      });
    }


    gotopage(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,date,obs,tech,exp) {

      this.gotoTop()

      const user = this.tokenStorageService.getUser()
      this.consultants = [];

      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();
      this.us.getLimitConsultants(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,25,date,obs,tech,exp,this.orderdesc,this.orderasc,user.trigramme
        ).subscribe((data2) => {


       this.getUsers(data2)

      });

    }
    Search(){

      this.loader()
        this.styleState=true
        this.getByName(
          this.nameFilter.value,
            this.prenomFilter.value,
            this.disponibilite,
            this.qualitecv,

            this.emailFilter.value,
            this.code_postalFilter.value,
            this.titreFilter.value,
            this.clientFilter.value,

            this.telephone_principalFilter.value,
            this.date_derniere_majFilter.value,
            this.observationFilter.value,
            this.techFilter.value,
            this.exp,
          this.currentPage
          )
      }

      SearchTriFiltrage(){

        this.showfilter=true
        this.currentPage=1
          this.styleState=true
          this.getByName(
            this.nameFilter.value,
            this.prenomFilter.value,
            this.disponibilite,
            this.qualitecv,
            this.emailFilter.value,
            this.code_postalFilter.value,
            this.titreFilter.value,
            this.clientFilter.value,

            this.telephone_principalFilter.value,
            this.date_derniere_majFilter.value,
            this.observationFilter.value,
            this.techFilter.value,
            this.exp,
            1
            )
        }

        filterData(data: any[]): any[] {
          return data.map(item => ({
            Nom: item.nom,
            Prenom: item.prenom,
            Email: item.email,
            Telephone: item.telephone_principal,
            Titre: item.titre,
            Technologies: item.tech,
            LinkedIn: item.linkedin,
            Clients: item.client,
            Code_postal: item.postal,
            Trigramme : item.trigrh,
            Derniere_MAJ: item.dateDerniereMaj
          }));
        }

        exportToExcel(data: any[], filename: string): void {
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, filename);
        }

        exportData() {
          if(this.isExporting) return ;

          this.isExporting = true;
          this.us.getConsultantsForExportation(
            this.nameFilter.value,
            this.nameFilter.value,
            this.dispoFilter.value,
            this.qualitecv,
            this.emailFilter.value,
            this.code_postalFilter.value,
            this.titreFilter.value,
            this.telephone_principalFilter.value,
            this.clientFilter.value,
            this.techFilter.value,
            this.date_derniere_majFilter.value,
            '',
            '',
            this.exp,
            this.observationFilter.value,
            this.orderdesc,
            this.orderasc
          ).subscribe(
            (data : any[]) => {
              data.forEach(element => {
                let cli = new Array();
                let techno = new Array();

                element.client.forEach(client => {
                  cli.push(client.libelle);
                });

                element.tech.forEach(tech => {
                  techno.push(tech.libelle);
                });

                let cl = "";
                let tech = "";
                cl = cli.join(", ");
                tech = techno.join(", ");

                element.client = cl;
                element.tech = tech;
              });

              const filteredData = this.filterData(data);
              this.exportToExcel(filteredData, 'consultants.xlsx');
              this.isExporting = false;
            },
            error => {
              this.isExporting = false;
            }
          );
        }


      resetFilter(){
        this.qualitecv=""
        this.exp=""
        this.disponibilite=""
        this.dispoFilter = new FormControl();
        this.qualiFilter = new FormControl();
        this.clientFilter = new FormControl("");
        this.tagtechFilter = new FormControl("");
        this.tagmetierFilter = new FormControl("");
        this.tagfoncFilter = new FormControl("");
       this.observationFilter = new FormControl("");
        this.techFilter = new FormControl("");
        this.nameFilter = new FormControl("");
        this.prenomFilter = new FormControl("");
        this.titreFilter = new FormControl("");
        this.emailFilter = new FormControl("");
        this.expFilter = new FormControl();
        this.telephone_principalFilter = new FormControl("");
        this.code_postalFilter = new FormControl("");
        this.date_derniere_majFilter = new FormControl("");
        this.filteredValues.nom=""
        this.filteredValues.prenom=""
        this.filteredValues.code_dispo=""
        this.filteredValues.code_qualite=""
        this.filteredValues.email=""
        this.filteredValues.code_postal=""
        this.filteredValues.titre=""
        this.filteredValues.client=""

        this.filteredValues.telephone_principal=""
        this.filteredValues.dateDerniereMaj=""
        this.filteredValues.observation=""
        this.filteredValues.tech=""
        this.isquali = false;
        this.isnom = false;
        this.isprenom = false;
        this.istitre = false;
        this.istech = false;
        this.isexp = false;
        this.isdispo = false;
        this.isclient = false;
        this.isobservation = false;
        this.isdmaj = false;
        this.isemail = false;
        this.iscode_postal = false;
        this.istelephone_principal = false;
        this.showfilter=false
        this.Search()
      }


      openPopup(e,data){
        e.preventDefault()
        const dialogRef = this.dialog.open(PopupdataComponent, {
         data: {
           message:data,
         },
       });

      }
      Truncate(text){
        return text.substr(0,40)
      }
      TruncateObs(text){
        return text.substr(0,40)
      }
      gotoTop() {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      Alert(msg) {
        const dialogRef = this.dialog.open(AlertEmptyComponent, {
          data: {
            message: msg,
            buttonText: {
              cancel: 'OK'
            }
          },
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }

      goto(page) {
      if(parseInt(page)){

        if(page-1 <= this.totalPage) {

          this.currentPage=page
          this.Search()

        }
        else {
          let total = this.totalPage+1
          this.Alert("il n'existe que " + total+" pages")
      }
      }

      else {
        this.Alert("Page invalide")
    }
      }

      NexPage(){
        this.currentPage = this.currentPage-1
        this.currentPage = this.currentPage+2
        this.Search()
      }
      previousPage(){
        this.currentPage = this.currentPage-1
        this.Search()
      }
      FirstPage(){
        this.currentPage =1
        this.showprevious=false;
        this.showfirstpage=false;

        this.shownext=true;
        this.showlastpage=true;
        this.Search()
      }
      LastPage(){
        this.currentPage = this.totalPage+1
        this.shownext=true;
        this.showlastpage=true;
        this.showprevious=false;
        this.showfirstpage=false;
        if(this.totalPage+1===this.currentPage){
          this.shownext=false
          this.showlastpage=false
        }
        this.Search()
      }

      getNext(){
        if(this.currentPage<this.totalPage+1){
          return true
        }return false
      }
      getpreviousMove(){
        if(this.currentPage>1){
          return true
        }return false
      }
      getPrevious(){
        if(this.currentPage>1){
          return true
        }return false
      }

      getlastpage(){
        if(this.currentPage<this.totalPage+1){
          return true
        }return false
      }
      ChangeItemPerPage(e){
        this.pageSize=e
        this.SearchTriFiltrage()
      }


      TruncateTitre(text){
        return text.substring(0,26)
      }
      TruncateIntitule(text){
        return text.substring(0,26)
      }
      renderDispo(item){
        return item? item.libelle : ''
      }
      renderPrenom(prenom){
        return prenom[0].toUpperCase()+prenom.slice(1).toLowerCase()
      }


      stripHtml(html)
      {
         let tmp = document.createElement("DIV");
         tmp.innerHTML = html;
         return tmp.textContent || tmp.innerText || "";
      }

      setdata(e: string, event: any) {
        let k = event.target;
        this.title = e;
        if (k) {
          if (k.scrollWidth > k.clientWidth) {
            k.style.backgroundColor = "#FFFFCC";

            k.setAttribute("title", e);
            this.open(event);
          }
        }
      }

        open(e: any) {

          const ele = document.getElementById('element');

          const menu = document.getElementById('menu');
          if (ele != null && menu != null) {
            e.preventDefault();

            const rect = ele.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            // Set the position for menu
            menu.style.top = `${y + 300}px`;
            menu.style.left = `${x}px`;

            // Show the menu
            menu.classList.remove('hidden');
          }
        }
   hover() {
          const ele = document.getElementById('element');
          const menu = document.getElementById('menu');
          if (ele != null && menu != null) {
            ele.addEventListener('context', function (e: any) {
              e.preventDefault();

              const rect = ele.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;

              // Set the position for menu
              menu.style.top = `${y + 40}px`;
              menu.style.left = `${x}px`;

              // Show the menu
              menu.classList.remove('hidden');

              document.addEventListener('click', documentClickHandler);
            });

            // Hide the menu when clicking outside of it
            const documentClickHandler = function (e: any) {
              const isClickedOutside = !menu.contains(e.target);
              if (isClickedOutside) {
                menu.classList.add('hidden');
                document.removeEventListener('click', documentClickHandler);
              }
            };
          }
   }

   openLink(link: string) {
    window.open(link,"_blank")
  }

        close(e: any) {
        e.target.style.backgroundYezaColor="transparent";

          const menu = document.getElementById('menu');
          if (menu != null) {
            const isClickedOutside = !menu.contains(e.target);
            if (isClickedOutside) {
              menu.classList.add('hidden');
            }
          }
        }
      }






