import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommunicationService } from './services/communication.service';
import { TokenStorageService } from './services/token-storage.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';

import { AuthService } from './services/auth.service';
import jwt_decode from "jwt-decode";
import { SidebarService } from './sidebar/sidebar.service';
import { BASE_URL } from './config';
import { Subscription } from 'rxjs';
import { NotificationService } from './services/notification.service';


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnChanges {
  roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;
  email: string;
  trigramme: string;
  shownav=true

  title = 'Portail RH';

  notifications : any[] = [];
  authSubscription!: Subscription;

  constructor(public authService: AuthService,private tokenStorageService: TokenStorageService, private router: Router,private dialog: MatDialog,
    public sidebarservice: SidebarService, private communicationService: CommunicationService, private notifService: NotificationService){
    this.communicationService.On('OnChild1').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild2').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild3').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild4').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild5').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild6').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild7').subscribe((res) => { this.title = res; });
    this.communicationService.On('OnChild8').subscribe((res) => { this.title = res; });
   }
  ngOnChanges(changes: SimpleChanges): void {
      window.scrollTo(0,0);
  }

  ngOnInit() {
    window.scroll(0,0);
    this.authSubscription = this.authService.authSubject.subscribe(
      (data) => {
        const user1 = this.tokenStorageService.getUser();
        this.roles = user1.roles;
        this.trigramme = user1.trigramme;
        this.getNotifications();
      }
    )

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if(this.isLoggedIn){
      let tokendata = jwt_decode(this.tokenStorageService.getToken());
      if( Date.now() >= tokendata["exp"] * 1000){
       window.localStorage.clear()
        this.router.navigate(["/login"]);
      }
    }


    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.trigramme = user.trigramme;
      this.roles = user.roles;
      this.username = user.username;
      this.email = user.email;

      this.getNotifications();

    }else{
      this.router.navigate(["/login"]);
    }

  }

  getNotifications(){
    this.notifications = [];
    this.notifService.getNotificationsFromCRM(this.trigramme).subscribe(
      (data: any) => {
        this.notifications.push(...data);
      },
      error => {
        console.log(error);
      }
    )
    this.notifService.getNotifications(this.trigramme).subscribe(
      (data: any) => {
        this.notifications.push(...data);
      },
      error => {
        console.log(error);
      }
    )

  }

  openBesoin(notif: any) {
    window.open(BASE_URL + "/page-besoin?besoin=" + notif.idbesoin, "_blank");
  }

  deleteNotification(notif, event: Event){
    event.preventDefault();
    notif.statut = "TRAITE"
    this.notifService.updateNotification(notif).subscribe(
      (data) => {
        this.getNotifications();
      }
    )
  }

  deleteCrmNotification(notif, event: Event){
    event.preventDefault();
    notif.statut = "TRAITE"
    this.notifService.updateCrmNotification(notif).subscribe(
      (data) => {
        this.getNotifications();
      }
    )
  }

  openFicheConsultant(notif){
    this.router.navigate(['./valider-profil', notif.idprofile]);
  }

  logout() {
    this.tokenStorageService.signOut();
    window.location.reload();
    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Déconnexion..."
        },
      });
      await delay(3000);
      this.authService.setauth(false)
      dialogRef.close();
      this.router.navigate(["/login"]);

  })();
  }

  onActivate(event) {

    window.scroll(0,0);
}

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }
  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage = !this.sidebarservice.hasBackgroundImage;
  }
  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }
}
