import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../services/dashboard.service';
import { BASE_URL } from '../../config';

@Component({
  selector: 'app-qualite',
  templateUrl: './qualite.component.html',
  styleUrls: ['./qualite.component.scss']
})
export class QualiteComponent implements OnInit {

  constructor(private dservice: DashboardService, private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dservice.getQualiteFiche().subscribe(
      (data: any) => {
        this.globalQualiteData = data.data;
        this.tendanceFiche = data.tendance;
        let total = this.globalQualiteData.total;

        this.pemail = (this.globalQualiteData.email / total)*100;
        this.pqualite = (this.globalQualiteData.qualite / total)*100;
        this.pcv = (this.globalQualiteData.cv / total)*100;
        this.pdisponibilite = (this.globalQualiteData.disponibilite / total)*100;
        this.ptitre = (this.globalQualiteData.titre / total)*100;
        this.pdda = (this.globalQualiteData.dda / total)*100;
        this.ptjm = (this.globalQualiteData.tjm / total)*100;
        this.ptechno = (this.globalQualiteData.techno / total)*100;
        this.ptagtech = (this.globalQualiteData.tagtech / total)*100;
        this.ptagmetier = (this.globalQualiteData.tagmetier / total)*100;
        this.ptagfonc = (this.globalQualiteData.tagfonc / total)*100;
        this.pclient = (this.globalQualiteData.client / total)*100;

      }
    )

    this.dservice.getTendanceFiche().subscribe(
      (data: any) => {
        this.aligneData = data.dataAligne;
        this.nonaligneData = data.dataNonAligne;
        this.aligneTendance = data.tendanceAligne;
        this.nonaligneTendance = data.tendanceNonAligne;
        let totala = this.aligneData.total;
        let totaln = this.nonaligneData.total;

        this.patechno = (this.aligneData.techno.length / totala)*100;
        this.patagtech = (this.aligneData.tagtech.length / totala)*100;
        this.patagmetier = (this.aligneData.tagmetier.length / totala)*100;
        this.patagfonc = (this.aligneData.tagfonc.length / totala)*100;
        this.paclient = (this.aligneData.client.length / totala)*100;

        this.pntechno = (this.nonaligneData.techno.length / totaln)*100;
        this.pntagtech = (this.nonaligneData.tagtech.length / totaln)*100;
        this.pntagmetier = (this.nonaligneData.tagmetier.length / totaln)*100;
        this.pntagfonc = (this.nonaligneData.tagfonc.length / totaln)*100;
        this.pnclient = (this.nonaligneData.client.length / totaln)*100;

        this.isLoading = false;
        this.loadChanged.emit(this.isLoading);
      }
    )
  }

  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();


  ouvrirFiche(consultant: any) {
    //this.router.navigate(['/fichec'], { queryParams: { id: row._id } });
    window.open(BASE_URL+"/fichec?id="+consultant._id,"_blank");
  }

  // QUALITE DE DONNEES
  // QUALITE DE DONNEES
  // QUALITE DE DONNEES

  globalQualiteData = {
    client: 0,
    cv: 0,
    dda: 0,
    disponibilite: 0,
    email: 0,
    qualite: 0,
    tagfonc: 0,
    tagtech: 0,
    tagmetier: 0,
    techno: 0,
    titre: 0,
    tjm: 0,
    total: 0
  };
  tendanceFiche = {
    client: 0,
    cv: 0,
    dda: 0,
    disponibilite: 0,
    email: 0,
    qualite: 0,
    tagfonc: 0,
    tagtech: 0,
    tagmetier: 0,
    techno: 0,
    titre: 0,
    tjm: 0
  };

  aligneData = {
    client: [],
    tagfonc: [],
    tagmetier: [],
    tagtech: [],
    techno: [],
    total: 0
  };
  nonaligneData = {
    client: [],
    tagfonc: [],
    tagmetier: [],
    tagtech: [],
    techno: [],
    total: 0
  };
  aligneTendance = {
    client: 0,
    tagfonc: 0,
    tagmetier: 0,
    tagtech: 0,
    techno: 0
  };
  nonaligneTendance = {
    client: 0,
    tagfonc: 0,
    tagmetier: 0,
    tagtech: 0,
    techno: 0
  };

  pemail = 0;
  pqualite = 0;
  pcv = 0;
  pdisponibilite= 0;
  ptitre= 0;
  pdda= 0;
  ptjm = 0;
  ptechno = 0;
  ptagtech = 0;
  ptagmetier = 0;
  ptagfonc = 0;
  pclient = 0;

  patechno = 0;
  patagtech = 0;
  patagmetier = 0;
  patagfonc = 0;
  paclient = 0;

  pntechno = 0;
  pntagtech = 0;
  pntagmetier = 0;
  pntagfonc = 0;
  pnclient = 0;

  // Email et phone

  page1: number=0;
  size1: number=15;
  pages1: number[] = [];
  consultants1: any[] = [];
  consultant1: any;

  setPage1(i: number, event: Event) {
    event.preventDefault();
    this.page1 = i;
    // Appel d'api
    this.dservice.getFilter1(this.page1, this.size1).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages1 = new Array(data['totalPages']);
      }
    )
  }

  setSize1(i: number) {
    this.size1 = i;
    this.page1 = 0;
    // Appel d'api
    this.dservice.getFilter1(this.page1, this.size1).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages1 = new Array(data['totalPages']);
      }
    )
  }

  openFicheEmail(content: any) {
    this.dservice.getFilter1(this.page1, this.size1).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages1 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }



  // Qualité

  page2: number=0;
  size2: number=15;
  pages2: number[] = [];

  setPage2(i: number, event: Event) {
    event.preventDefault();
    this.page2 = i;
    // Appel d'api
    this.dservice.getFilter2(this.page2, this.size2).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages2 = new Array(data['totalPages']);
      }
    )
  }

  setSize2(i: number) {
    this.size2 = i;
    this.page2 = 0;
    // Appel d'api
    this.dservice.getFilter2(this.page2, this.size2).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages2 = new Array(data['totalPages']);
      }
    )
  }

  openFicheQualite(content: any) {
    this.dservice.getFilter2(this.page2, this.size2).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages2 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  
  // CV

  page3: number=0;
  size3: number=15;
  pages3: number[] = [];

  setPage3(i: number, event: Event) {
    event.preventDefault();
    this.page3 = i;
    // Appel d'api
    this.dservice.getFilter3(this.page3, this.size3).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages3 = new Array(data['totalPages']);
      }
    )
  }

  setSize3(i: number) {
    this.size3 = i;
    this.page3 = 0;
    // Appel d'api
    this.dservice.getFilter3(this.page3, this.size3).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages3 = new Array(data['totalPages']);
      }
    )
  }

  openFicheCv(content: any) {
    this.dservice.getFilter3(this.page3, this.size3).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages3 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // disponibilite

  page4: number=0;
  size4: number=15;
  pages4: number[] = [];

  setPage4(i: number, event: Event) {
    event.preventDefault();
    this.page4 = i;
    // Appel d'api
    this.dservice.getFilter4(this.page4, this.size4).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages4 = new Array(data['totalPages']);
      }
    )
  }

  setSize4(i: number) {
    this.size4 = i;
    this.page4 = 0;
    // Appel d'api
    this.dservice.getFilter4(this.page4, this.size4).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages4 = new Array(data['totalPages']);
      }
    )
  }

  openFicheDispo(content: any) {
    this.dservice.getFilter4(this.page4, this.size4).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages4 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // titre

  page5: number=0;
  size5: number=15;
  pages5: number[] = [];

  setPage5(i: number, event: Event) {
    event.preventDefault();
    this.page5 = i;
    // Appel d'api
    this.dservice.getFilter5(this.page5, this.size5).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages5 = new Array(data['totalPages']);
      }
    )
  }

  setSize5(i: number) {
    this.size5 = i;
    this.page5 = 0;
    // Appel d'api
    this.dservice.getFilter5(this.page5, this.size5).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages5 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTitre(content: any) {
    this.dservice.getFilter5(this.page5, this.size5).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages5 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // dda

  page6: number=0;
  size6: number=15;
  pages6: number[] = [];

  setPage6(i: number, event: Event) {
    event.preventDefault();
    this.page6 = i;
    // Appel d'api
    this.dservice.getFilter6(this.page6, this.size6).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages6 = new Array(data['totalPages']);
      }
    )
  }

  setSize6(i: number) {
    this.size6 = i;
    this.page6 = 0;
    // Appel d'api
    this.dservice.getFilter6(this.page6, this.size6).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages6 = new Array(data['totalPages']);
      }
    )
  }

  openFicheDda(content: any) {
    this.dservice.getFilter6(this.page6, this.size6).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages6 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // tjm

  page7: number=0;
  size7: number=15;
  pages7: number[] = [];

  setPage7(i: number, event: Event) {
    event.preventDefault();
    this.page7 = i;
    // Appel d'api
    this.dservice.getFilter7(this.page7, this.size7).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages7 = new Array(data['totalPages']);
      }
    )
  }

  setSize7(i: number) {
    this.size7 = i;
    this.page7 = 0;
    // Appel d'api
    this.dservice.getFilter7(this.page7, this.size7).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages7 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTjm(content: any) {
    this.dservice.getFilter7(this.page7, this.size7).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages7 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // technos

  page8: number=0;
  size8: number=15;
  pages8: number[] = [];

  setPage8(i: number, event: Event) {
    event.preventDefault();
    this.page8 = i;
    // Appel d'api
    this.dservice.getFilter8(this.page8, this.size8).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages8 = new Array(data['totalPages']);
      }
    )
  }

  setSize8(i: number) {
    this.size8 = i;
    this.page8 = 0;
    // Appel d'api
    this.dservice.getFilter8(this.page8, this.size8).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages8 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTechno(content: any) {
    this.dservice.getFilter8(this.page8, this.size8).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages8 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // tagtech

  page9: number=0;
  size9: number=15;
  pages9: number[] = [];

  setPage9(i: number, event: Event) {
    event.preventDefault();
    this.page9 = i;
    // Appel d'api
    this.dservice.getFilter9(this.page9, this.size9).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages9 = new Array(data['totalPages']);
      }
    )
  }

  setSize9(i: number) {
    this.size9 = i;
    this.page9 = 0;
    // Appel d'api
    this.dservice.getFilter9(this.page9, this.size9).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages9 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTagtech(content: any) {
    this.dservice.getFilter9(this.page9, this.size9).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages9 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // tagmetier

  page10: number=0;
  size10: number=15;
  pages10: number[] = [];

  setPage10(i: number, event: Event) {
    event.preventDefault();
    this.page10 = i;
    // Appel d'api
    this.dservice.getFilter10(this.page10, this.size10).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages10 = new Array(data['totalPages']);
      }
    )
  }

  setSize10(i: number) {
    this.size10 = i;
    this.page10 = 0;
    // Appel d'api
    this.dservice.getFilter10(this.page10, this.size10).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages10 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTagmetier(content: any) {
    this.dservice.getFilter10(this.page10, this.size10).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages10 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // tagfonc

  page11: number=0;
  size11: number=15;
  pages11: number[] = [];

  setPage11(i: number, event: Event) {
    event.preventDefault();
    this.page11 = i;
    // Appel d'api
    this.dservice.getFilter11(this.page11, this.size11).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages11 = new Array(data['totalPages']);
      }
    )
  }

  setSize11(i: number) {
    this.size11 = i;
    this.page11 = 0;
    // Appel d'api
    this.dservice.getFilter11(this.page11, this.size11).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages11 = new Array(data['totalPages']);
      }
    )
  }

  openFicheTagfonc(content: any) {
    this.dservice.getFilter11(this.page11, this.size11).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages11 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // client

  page12: number=0;
  size12: number=15;
  pages12: number[] = [];

  setPage12(i: number, event: Event) {
    event.preventDefault();
    this.page12 = i;
    // Appel d'api
    this.dservice.getFilter12(this.page12, this.size12).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages12 = new Array(data['totalPages']);
      }
    )
  }

  setSize12(i: number) {
    this.size12 = i;
    this.page12 = 0;
    // Appel d'api
    this.dservice.getFilter12(this.page12, this.size12).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages12 = new Array(data['totalPages']);
      }
    )
  }

  openFicheClient(content: any) {
    this.dservice.getFilter12(this.page12, this.size12).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages12 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // Alignement des fiches CV
  // Alignement des fiches CV
  // Alignement des fiches CV
  // Alignement des fiches CV

  // techno

  page13: number=0;
  size13: number=15;
  pages13: number[] = [];

  setPage13(i: number, event: Event) {
    event.preventDefault();
    this.page13 = i;
    // Appel d'api
    this.dservice.getFilter13(this.page13, this.size13).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages13 = new Array(data['totalPages']);
      }
    )
  }

  setSize13(i: number) {
    this.size13 = i;
    this.page13 = 0;
    // Appel d'api
    this.dservice.getFilter13(this.page13, this.size13).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages13 = new Array(data['totalPages']);
      }
    )
  }

  openAlignTechno(content: any) {
    this.dservice.getFilter13(this.page13, this.size13).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages13 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // tagtech

  page14: number=0;
  size14: number=15;
  pages14: number[] = [];

  setPage14(i: number, event: Event) {
    event.preventDefault();
    this.page14 = i;
    // Appel d'api
    this.dservice.getFilter14(this.page14, this.size14).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages14 = new Array(data['totalPages']);
      }
    )
  }

  setSize14(i: number) {
    this.size14 = i;
    this.page14 = 0;
    // Appel d'api
    this.dservice.getFilter14(this.page14, this.size14).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages14 = new Array(data['totalPages']);
      }
    )
  }

  openAlignTagtech(content: any) {
    this.dservice.getFilter14(this.page14, this.size14).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages14 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // tagmetier

  page15: number=0;
  size15: number=15;
  pages15: number[] = [];

  setPage15(i: number, event: Event) {
    event.preventDefault();
    this.page15 = i;
    // Appel d'api
    this.dservice.getFilter15(this.page15, this.size15).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages15 = new Array(data['totalPages']);
      }
    )
  }

  setSize15(i: number) {
    this.size15 = i;
    this.page15 = 0;
    // Appel d'api
    this.dservice.getFilter15(this.page15, this.size15).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages15 = new Array(data['totalPages']);
      }
    )
  }

  openAlignTagmetier(content: any) {
    this.dservice.getFilter15(this.page15, this.size15).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages15 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // tagfonc

  page16: number=0;
  size16: number=15;
  pages16: number[] = [];

  setPage16(i: number, event: Event) {
    event.preventDefault();
    this.page16 = i;
    // Appel d'api
    this.dservice.getFilter16(this.page16, this.size16).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages16 = new Array(data['totalPages']);
      }
    )
  }

  setSize16(i: number) {
    this.size16 = i;
    this.page16 = 0;
    // Appel d'api
    this.dservice.getFilter16(this.page16, this.size16).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages16 = new Array(data['totalPages']);
      }
    )
  }

  openAlignTagfonc(content: any) {
    this.dservice.getFilter16(this.page16, this.size16).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages16 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }


  // client

  page17: number=0;
  size17: number=15;
  pages17: number[] = [];

  setPage17(i: number, event: Event) {
    event.preventDefault();
    this.page17 = i;
    // Appel d'api
    this.dservice.getFilter17(this.page17, this.size17).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages17 = new Array(data['totalPages']);
      }
    )
  }

  setSize17(i: number) {
    this.size17 = i;
    this.page17 = 0;
    // Appel d'api
    this.dservice.getFilter17(this.page17, this.size17).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages17 = new Array(data['totalPages']);
      }
    )
  }

  openAlignClient(content: any) {
    this.dservice.getFilter17(this.page17, this.size17).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages17 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }





  // non techno

  page18: number=0;
  size18: number=15;
  pages18: number[] = [];

  setPage18(i: number, event: Event) {
    event.preventDefault();
    this.page18 = i;
    // Appel d'api
    this.dservice.getFilter18(this.page18, this.size18).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages18 = new Array(data['totalPages']);
      }
    )
  }

  setSize18(i: number) {
    this.size18 = i;
    this.page18 = 0;
    // Appel d'api
    this.dservice.getFilter18(this.page18, this.size18).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages18 = new Array(data['totalPages']);
      }
    )
  }

  openNAlignTechno(content: any) {
    this.dservice.getFilter18(this.page18, this.size18).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages18 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // non tagtech

  page19: number=0;
  size19: number=15;
  pages19: number[] = [];

  setPage19(i: number, event: Event) {
    event.preventDefault();
    this.page19 = i;
    // Appel d'api
    this.dservice.getFilter19(this.page19, this.size19).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages19 = new Array(data['totalPages']);
      }
    )
  }

  setSize19(i: number) {
    this.size19 = i;
    this.page19 = 0;
    // Appel d'api
    this.dservice.getFilter19(this.page19, this.size19).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages19 = new Array(data['totalPages']);
      }
    )
  }

  openNAlignTagtech(content: any) {
    this.dservice.getFilter19(this.page19, this.size19).subscribe(
      (data: any) => {
        this.consultants1 = data['content']
        this.pages19 = new Array(data['totalPages']);
      }
    )
    this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
  }

  // non tagmetier

  page20: number=0;
size20: number=15;
pages20: number[] = [];

setPage20(i: number, event: Event) {
  event.preventDefault();
  this.page20 = i;
  // Appel d'api
  this.dservice.getFilter20(this.page20, this.size20).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages20 = new Array(data['totalPages']);
    }
  )
}

setSize20(i: number) {
  this.size20 = i;
  this.page20 = 0;
  // Appel d'api
  this.dservice.getFilter20(this.page20, this.size20).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages20 = new Array(data['totalPages']);
    }
  )
}

openNAlignTagmetier(content: any) {
  this.dservice.getFilter20(this.page20, this.size20).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages20 = new Array(data['totalPages']);
    }
  )
  this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
}

  // non tagfonc

  page21: number=0;
size21: number=15;
pages21: number[] = [];

setPage21(i: number, event: Event) {
  event.preventDefault();
  this.page21 = i;
  // Appel d'api
  this.dservice.getFilter21(this.page21, this.size21).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages21 = new Array(data['totalPages']);
    }
  )
}

setSize21(i: number) {
  this.size21 = i;
  this.page21 = 0;
  // Appel d'api
  this.dservice.getFilter21(this.page21, this.size21).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages21 = new Array(data['totalPages']);
    }
  )
}

openNAlignTagfonc(content: any) {
  this.dservice.getFilter21(this.page21, this.size21).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages21 = new Array(data['totalPages']);
    }
  )
  this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
}

  // non client

  page22: number=0;
size22: number=15;
pages22: number[] = [];

setPage22(i: number, event: Event) {
  event.preventDefault();
  this.page22 = i;
  // Appel d'api
  this.dservice.getFilter22(this.page22, this.size22).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages22 = new Array(data['totalPages']);
    }
  )
}

setSize22(i: number) {
  this.size22 = i;
  this.page22 = 0;
  // Appel d'api
  this.dservice.getFilter22(this.page22, this.size22).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages22 = new Array(data['totalPages']);
    }
  )
}

openNAlignClient(content: any) {
  this.dservice.getFilter22(this.page22, this.size22).subscribe(
    (data: any) => {
      this.consultants1 = data['content']
      this.pages22 = new Array(data['totalPages']);
    }
  )
  this.modalService.open(content, { centered: true, size: 'xl', scrollable: true });
}

}
