import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConsultantService } from '../services/consultant.service';
import * as fileSaver from 'file-saver';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { BASE_URL } from '../config';

@Component({
  selector: 'app-page-manager',
  templateUrl: './page-manager.component.html',
  styleUrls: ['./page-manager.component.scss']
})
export class PageManagerComponent implements OnInit {

  today = new Date().toJSON().split('T')[0];

  // CONSULTANT

  sortBy1: string = "datedebut";
  sortDirection1 = "DESC";
  nomclient1 = "";
  datedebut1 = "";
  datefin1 = "";
  caracteristiques1 = "";
  nomconsultant1 = "";
  prenomconsultant1 = "";
  trigrh = "";
  missions1: any[] = []
  missionToUpdate1: any;
  facturation1: any = {
    "paiementsociete" : "",
    "paiementrc" : "",
    "paiementmf" : "",
    "paiementtva" : "",
    "paiementtitulaire" : "",
    "paiementiban" : "",
    "paiementbic" : "",
    "paiementdomiciliation" : "",
    "paiementadresse" : ""
  }
  idcra1: string = "";
  cra1: any;
  tjmclient1: string = "";
  tjmconsultant1: string = "";
  contrath1: string = "";
  page1: number=0;
  currentPage1: number = 1;
  size1: number=10;
  pages1: number[] = [0,1];
  totalelements1: number = 0;

  // CLIENT
  sortBy: string = "datedebut";
  sortDirection = "DESC";
  nomconsultant = "";
  prenomconsultant = "";
  datedebut = "";
  datefin = "";
  nomclient = "";
  elementFacturation: any = [];
  contrats: string[] = [];
  contrat: string = "";
  trigcrm:string = "";
  missions: any[] = [];
  missionsToDisplay: any[] = [];
  consultants: any[] = [];
  missionToUpdate: any;
  cra: any;
  facturation = {
    id: "",
    contratcadre : "",
    contrat : "",
    contratsclient : "",
    facturationrc : "",
    facturationmf : "",
    facturationtva : "",
    facturationadresse : "",
    facturationreferantnom : "",
    facturationreferantprenom : "",
    facturationreferantfonction : "",
    facturationreferanttelephone : "",
    facturationreferantemail : "",
    facturationintermediairetitulaire : "",
    facturationintermediaireiban : "",
    facturationintermediairebic : "",
    facturationintermediairedomiciliation : "",
    facturationintermediaireadresse : ""
  }
  tjmclient: string = "";
  tjmconsultant: string = "";
  contrath: string = "";
  page: number=0;
  currentPage: number = 1;
  size: number=10;
  pages: number[] = [0,1];
  totalelements: number = 0;
  @ViewChild('craModalClient', {read: TemplateRef}) craModal! : TemplateRef<any>;


  constructor(public cs: ConsultantService, private dialog: MatDialog, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.Loader();
    this.filterMissions1();
    this.filterMissions();
  }

  // CLIENT

  showConsultant(id: string){
    window.open(BASE_URL+"/fichec?id="+id,"_blank");
  }

  openFacturationModal(mission: any, content){
    this.facturation.id = mission.id;
    this.facturation.contrat = mission.contrat;
    this.facturation.contratcadre = mission.contratcadre;
    this.facturation.facturationrc = mission.facturationrc;
    this.facturation.facturationmf = mission.facturationmf;
    this.facturation.facturationtva = mission.facturationtva;
    this.facturation.facturationadresse = mission.facturationadresse;
    this.facturation.facturationreferantnom = mission.facturationreferantnom;
    this.facturation.facturationreferantprenom = mission.facturationreferantprenom;
    this.facturation.facturationreferantfonction = mission.facturationreferantfonction;
    this.facturation.facturationreferanttelephone = mission.facturationreferanttelephone;
    this.facturation.facturationreferantemail = mission.facturationreferantemail;
    this.facturation.facturationintermediairetitulaire = mission.facturationintermediairetitulaire;
    this.facturation.facturationintermediaireiban = mission.facturationintermediaireiban;
    this.facturation.facturationintermediairebic = mission.facturationintermediairebic;
    this.facturation.facturationintermediairedomiciliation = mission.facturationintermediairedomiciliation;
    this.facturation.facturationintermediaireadresse= mission.facturationintermediaireadresse;

    this.openModal(content);
  }

  updateFacturation(){
    this.modalService.dismissAll();
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == this.facturation.id) {
        elt.facturationrc = this.facturation.facturationrc;
        elt.facturationmf = this.facturation.facturationmf;
        elt.facturationtva = this.facturation.facturationtva;
        elt.facturationadresse = this.facturation.facturationadresse;
        elt.facturationreferantnom = this.facturation.facturationreferantnom;
        elt.facturationreferantprenom = this.facturation.facturationreferantprenom;
        elt.facturationreferantfonction = this.facturation.facturationreferantfonction;
        elt.facturationreferanttelephone = this.facturation.facturationreferanttelephone;
        elt.facturationreferantemail = this.facturation.facturationreferantemail;
        elt.facturationintermediairetitulaire = this.facturation.facturationintermediairetitulaire;
        elt.facturationintermediaireiban = this.facturation.facturationintermediaireiban;
        elt.facturationintermediairebic = this.facturation.facturationintermediairebic;
        elt.facturationintermediairedomiciliation = this.facturation.facturationintermediairedomiciliation;
        elt.facturationintermediaireadresse = this.facturation.facturationintermediaireadresse;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.facturation = {
              id: "",
              contratcadre : "",
              contrat : "",
              contratsclient : "",
              facturationrc : "",
              facturationmf : "",
              facturationtva : "",
              facturationadresse : "",
              facturationreferantnom : "",
              facturationreferantprenom : "",
              facturationreferantfonction : "",
              facturationreferanttelephone : "",
              facturationreferantemail : "",
              facturationintermediairetitulaire : "",
              facturationintermediaireiban : "",
              facturationintermediairebic : "",
              facturationintermediairedomiciliation : "",
              facturationintermediaireadresse : ""
            }
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  openCraModalClient(idcra: string){
    this.cs.getCra(idcra).subscribe(
      (data) => {
        this.cra = data;
        this.openXlModal(this.craModal)
      }
    )
  }

  validateCra(mission: any){
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == mission.id) {
        elt.cras.forEach(cra => {
          if(cra.idra == mission.cra.idcra){
            cra.validationcra = mission.cra.validationcra
          }
          this.cs.updateMission(elt).subscribe(
            (data:any) => {
              this.filterMissions();
              this.missionToUpdate = null;
              this.dialog.closeAll();
            },
            (error) => {
              this.dialog.closeAll();
              console.log(error);
            }
          );
        });
      }
    })
  }

  openDateRenouvModal(mission: any, content){
    this.missionToUpdate = {...mission}
    this.modalService.open(content, { centered: true });
  }

  updateDate(){
    this.modalService.dismissAll();
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == this.missionToUpdate.id) {
        elt.datefin = this.missionToUpdate.datefin;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.missionToUpdate = null;
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  updateSatisfaction(mission:any){
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == mission.id) {
        elt.satisfactionclient = mission.satisfactionclient;
        elt.datesatclient = this.today;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.missionToUpdate = null;
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  setElementFacturation(){
    this.contrats = this.elementFacturation.contrats
  }

  setSort(sortcolumn: string){
    if(this.sortBy == sortcolumn){
      this.sortDirection == "ASC" ? this.sortDirection = "DESC" : this.sortDirection = "ASC";
    } else {
      this.sortBy = sortcolumn
      this.sortDirection = "ASC";
    }
    this.filterMissions();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.filterMissions();
    }
  }

  changePage(){
    if(this.currentPage>0 && this.currentPage<=this.pages.length){
      this.page = this.currentPage - 1;
      this.filterMissions();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.filterMissions();
  }

  filterMissions(){
    this.cs.getMissions("", "", this.datedebut, this.datefin, this.nomclient, this.nomconsultant, this.prenomconsultant,
     "", "", "", this.trigcrm, this.tjmclient, this.tjmconsultant, this.sortBy, this.sortDirection, this.page.toString(), this.size.toString()).subscribe(
      (data) => {
        this.missions = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.displayMissionPerMonth();
      },
      (error) => {
        console.log(error);
      }
     )
  }

  displayMissionPerMonth(){
    this.missionsToDisplay = [];
    let now = new Date(this.today)
    this.missions.forEach((elt: any) => {
      let fin = new Date(elt.datefin);
      for (let d = new Date(new Date(elt.datedebut).setDate(1)); d <= now; d.setMonth(d.getMonth() + 1)) {
        if (d < fin ) {
          let mis = {...elt};
          mis.date = new Date(d);
          mis.cra = null;
          elt.cras.forEach(cra => {
            if(cra.mois-1 == d.getMonth()){
              mis.cra = cra;
            }
          });
          this.missionsToDisplay.push(mis)
        } else {
          break;
        }
      }
    });
    this.dialog.closeAll()
  }


  // CONSULTANT

  setSort1(sortcolumn: string){
    if(this.sortBy1 == sortcolumn){
      this.sortDirection1 == "ASC" ? this.sortDirection1 = "DESC" : this.sortDirection1 = "ASC";
    } else {
      this.sortBy1 = sortcolumn
      this.sortDirection1 = "ASC";
    }
    this.filterMissions1();
  }

  setPage1(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages1.length){
      this.page1 = i;
      this.currentPage1 = this.page+1;
      this.filterMissions1();
    }
  }

  changePage1(){
    if(this.currentPage1>0 && this.currentPage1<this.pages1.length){
      this.page1 = this.currentPage1 - 1;
      this.filterMissions1();
    }
  }

  setSize1(i: number) {
    this.size1 = i;
    this.filterMissions1();
  }

  filterMissions1(){
    this.cs.getMissions("", "", this.datedebut1, this.datefin1, this.nomclient1, this.nomconsultant1, this.prenomconsultant1,
    this.contrath1, this.caracteristiques1, this.trigrh, "", "0", this.tjmconsultant1, this.sortBy1, this.sortDirection1, this.page1.toString(), this.size1.toString()).subscribe(
      (data) => {
        this.missions1 = [...data.content];
        this.pages1 = new Array(data.totalPages);
        this.totalelements1 = data.numberOfElements;
      },
      (error) => {
        console.log(error);
      }
     )
  }

  openModal(content) {
    this.modalService.open(content, {centered: true})
  }

  updateSatisfactionConsultant(mission:any){
    this.Loader()
    mission.datesatconsultant = this.today;
    this.cs.updateMission(mission).subscribe(
      (data:any) => {
        this.filterMissions1();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );
  }

  updateSatisfactionContact(mission:any){
    this.Loader()
    mission.datesatcontact = this.today;
    this.cs.updateMission(mission).subscribe(
      (data:any) => {
        this.filterMissions1();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );
  }

  openPaiementModal(mission: any, content){
    this.missionToUpdate1 = {...mission}
    this.facturation1.paiementsociete = mission.paiementsociete;
    this.facturation1.paiementrc = mission.paiementrc;
    this.facturation1.paiementmf = mission.paiementmf;
    this.facturation1.paiementtva = mission.paiementtva;
    this.facturation1.paiementtitulaire = mission.paiementtitulaire;
    this.facturation1.paiementiban = mission.paiementiban;
    this.facturation1.paiementbic = mission.paiementbic;
    this.facturation1.paiementdomiciliation = mission.paiementdomiciliation;
    this.facturation1.paiementadresse = mission.paiementadresse;

    this.openModal(content);
  }

  updatePaiement(){
    this.modalService.dismissAll();
    this.Loader()
    this.missionToUpdate1.paiementsociete = this.facturation1.paiementsociete;
    this.missionToUpdate1.paiementrc = this.facturation1.paiementrc;
    this.missionToUpdate1.paiementmf = this.facturation1.paiementmf;
    this.missionToUpdate1.paiementtva = this.facturation1.paiementtva;
    this.missionToUpdate1.paiementtitulaire = this.facturation1.paiementtitulaire;
    this.missionToUpdate1.paiementiban = this.facturation1.paiementiban;
    this.missionToUpdate1.paiementbic = this.facturation1.paiementbic;
    this.missionToUpdate1.paiementdomiciliation = this.facturation1.paiementdomiciliation;
    this.missionToUpdate1.paiementadresse = this.facturation1.paiementadresse;
    this.cs.updateMission(this.missionToUpdate1).subscribe(
      (data:any) => {
        this.filterMissions1();
        this.facturation1 = {
          "paiementsociete" : "",
          "paiementrc" : "",
          "paiementmf" : "",
          "paiementtva" : "",
          "paiementtitulaire" : "",
          "paiementiban" : "",
          "paiementbic" : "",
          "paiementdomiciliation" : "",
          "paiementadresse" : ""
        }
        this.missionToUpdate1 = null;
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );

  }

  openCraModal(content){
    if(this.idcra1 != ""){
      this.cs.getCra(this.idcra1).subscribe(
        (data) => {
          this.cra1 = data;
          this.openXlModal(content)
        }
      )
    }
  }

  openDelaiModal(mission:any, content){
    this.missionToUpdate1 = {...mission};
    this.openModal(content);
  }

  updateDelai(){
    this.modalService.dismissAll();
    this.Loader();
    this.cs.updateMission(this.missionToUpdate1).subscribe(
      (data) => {
        this.filterMissions1();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        this.Alert('Erreur lors de la mise à jour');
      }
    )
  }

  selectcolor1(mission){
    switch (mission.satisfactionclient) {
      case "1":
        return "red"
        break;
      case "2":
        return "yellow"
        break;
      case "3":
        return "orange"
        break;
      case "4":
        return "blue"
        break;
      case "5":
        return "green"
        break;

      default:
        break;
    }
  }

  selectcolor2(mission){
    switch (mission.satisfactioncontact) {
      case "1":
        return "red"
        break;
      case "2":
        return "yellow"
        break;
      case "3":
        return "orange"
        break;
      case "4":
        return "blue"
        break;
      case "5":
        return "green"
        break;

      default:
        break;
    }
  }

  selectcolor3(mission){
    switch (mission.satisfactionconsultant) {
      case "1":
        return "red"
        break;
      case "2":
        return "yellow"
        break;
      case "3":
        return "orange"
        break;
      case "4":
        return "blue"
        break;
      case "5":
        return "green"
        break;

      default:
        break;
    }
  }

  moisCra = ["Jan","Fev","Mars","Avr","Mai","Juin","Juil","Aou","Sept","Oct","Nov","Dec"]

  downloadFile(location: string){
    this.cs.downloadFile(location).subscribe(
      blob => {
        this.saveFile(blob, location.slice(location.lastIndexOf("/")+1));
      },
      error => console.log(error)
    )
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data]);
    fileSaver.saveAs(blob, filename);
  }

  openXlModal(content) {
		this.modalService.open(content, { size: 'xl', centered: true });
	}

  Loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en Cours`

      },
      disableClose: true,
    });
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
