import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { expressionType } from '@angular/compiler/src/output/output_ast';
import { backEndpoint } from '../config';

const endpoint =  backEndpoint+'/temprecherche';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class RechercheService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  RechercheCompetence(searchtype,input, poids, trigramme): Observable<any> {
    const formdata: FormData = new FormData();
    let size = "5";
    if (typeof input === 'string' || input instanceof String){
      size="1";
    }

    formdata.append('poids', poids);
    formdata.append('input', input);
    formdata.append('searchtype', searchtype);
    formdata.append('trigramme', trigramme);
    formdata.append('type', "RH");
    formdata.append('inputsize', size);
    return this.http.post(endpoint+"/competence", formdata);
  }

  getRecherche(trigramme): Observable<any> {

    return this.http.get(endpoint + '/find/' + trigramme).pipe(
      map(this.extractData));
  }


  getRechercheConsultantsMC(inputsize,searchtype,trigramme,qualitecv,dispo,experience, orderdesc,orderasc, nom,prenom,titre,observation,tech,tagtechs,tagmetiers,tagfoncs,zones,dispos,exps,qualitecvs,page,size) : Observable<any>{

    console.log(dispos);
    let fd = new FormData();
    fd.append('tagTech',tagtechs)
    fd.append('inputsize',inputsize)
    fd.append('tagFonc',tagfoncs)
    fd.append('tagmetier',tagmetiers)
    fd.append('zone',zones)
    fd.append('qualitecv',qualitecvs)
    fd.append('exp',exps)
    fd.append('dispo',dispos)
    fd.append('qualitesearch',qualitecv)
    fd.append('nom',nom)
    fd.append('prenom',prenom)
    fd.append('titre',titre)
    fd.append('exposearch',experience)
    fd.append('tech',tech)
    fd.append('searchtype',searchtype)
    fd.append('disposearch',dispo)
    fd.append('observation',observation)

    fd.append('ordername',orderdesc)
    fd.append('ordertype',orderasc)
    fd.append('page',page)
    fd.append('size',size)

    return this.http.post(endpoint + '/findmc/'+trigramme
      ,fd).pipe(
      map(this.extractData));
  }

  getRechercheConsultantsTags(qualitecv,dispo, orderdesc,orderasc, nom,prenom,titre,client,date,tech,tagtechs,tagmetiers,tagfoncs,zones,dispos,exps,qualitecvs,page,size) : Observable<any>{
    let fd = new FormData();
    fd.append('tagTech',tagtechs)
    fd.append('tagFonc',tagfoncs)
    fd.append('tagmetier',tagmetiers)
    fd.append('zone',zones)
    fd.append('qualitecv',qualitecvs)
    fd.append('exp',exps)
    fd.append('dispo',dispos)
    fd.append('qualitesearch',qualitecv)
    fd.append('nom',nom)
    fd.append('prenom',prenom)
    fd.append('titre',titre)
    fd.append('tech',tech)
    fd.append('disposearch',dispo)
    fd.append('client',client)
    fd.append('date',date)
    fd.append('ordername',orderdesc)
    fd.append('ordertype',orderasc)
    fd.append('page',page)
    fd.append('size',size)
    return this.http.post(endpoint + '/find13/XXX'
      ,fd).pipe(
      map(this.extractData));
  }

  getRechercheTags(tagtechs,tagmetiers,tagfoncs,zones,dispos,exps,qualitecvs,page,size): Observable<any> {

    return this.http.get(endpoint +
      '/public/rechtag1/?name='+qualitecvs+
      "&qualitecvs="+qualitecvs+
      "&exps="+exps+
      "&dispos="+dispos+
      "&zones="+zones+
      "&tagfoncs="+tagfoncs+
      "&tagmetiers="+tagmetiers+
      "&page="+page+
      "&size="+size+
      "&tagtechs="+tagtechs

      ).pipe(
      map(this.extractData));
  }
  getRecherche1(trigramme,qualite): Observable<any> {
    let params = new HttpParams();
    params = params.append(`qualite`, qualite);
        console.log("recherche 2",params)
    return this.http.get(endpoint + '/find1/' + trigramme, {params});
  }
    calculate(): Observable<any> {

    return this.http.get(endpoint + '/calculate');
  }
  getRecherche2(trigramme,dis): Observable<any> {
    let params = new HttpParams();
    params = params.append(`dis`, dis);
    console.log("recherche 3",params)
    return this.http.get(endpoint + '/find2/' + trigramme, {params});
  }
  getRecherche3(trigramme,exp): Observable<any> {
    console.log("recherche 4")
    let params = new HttpParams();
    params = params.append(`exp`, exp);
    return this.http.get(endpoint + '/find3/' + trigramme, {params});
  }
  getRecherche4(trigramme,zone): Observable<any> {
    let params = new HttpParams();
    params = params.append(`zone`, zone);
    console.log("recherche 5",params)
    return this.http.get(endpoint + '/find4/' + trigramme, {params});
  }
  getRecherche5(trigramme,qu,dis): Observable<any> {

    let params = new HttpParams();
    params = params.append(`qu`, qu);

    params = params.append(`dis`, dis);
    console.log("recherche 5",params)
    return this.http.get(endpoint + '/find5/' + trigramme, {params});
  }
  getRecherche6(trigramme,qu,expert): Observable<any> {
    let params = new HttpParams();
    params = params.append(`qu`, qu);

    params = params.append(`expert`, expert);
    console.log("recherche 6",params)
    return this.http.get(endpoint + '/find6/' + trigramme, {params});
  }
  getRecherche7(trigramme,qu,zone): Observable<any> {

    let params = new HttpParams();
    params = params.append(`qu`, qu);

    params = params.append(`zone`, zone);
    console.log("recherche 8",params)
    return this.http.get(endpoint + '/find7/' + trigramme, {params});
  }
  getRecherche8(trigramme,dis,expert): Observable<any> {

    let params = new HttpParams();
    params = params.append(`dis`, dis);

    params = params.append(`expert`, expert);
    console.log("recherche 8",params)
    return this.http.get(endpoint + '/find8/' + trigramme, {params});
  }
  getRecherche9(trigramme,dis,zone): Observable<any> {

    let params = new HttpParams();
    params = params.append(`dis`, dis);

    params = params.append(`zone`, zone);
    console.log("recherche 9",params)
    return this.http.get(endpoint + '/find9/' + trigramme, {params});
  }
  getRecherche10(trigramme,expert,zone): Observable<any> {
    console.log("recherche 10")
    let params = new HttpParams();
    params = params.append(`expert`, expert);

    params = params.append(`zone`, zone);
    return this.http.get(endpoint + '/find10/' + trigramme, {params});
  }
  getRecherche11(nom,prenom,dispo,qualite,techno,titre,client,date,page,size,ordername,ordertype,tri): Observable<any> {

    const formdata: FormData = new FormData();
    formdata.append(`name`, nom);
    formdata.append(`prenom`, prenom);
    formdata.append(`dispo`, dispo);
    formdata.append(`qualite`, qualite);
    formdata.append(`techno`, techno);
    formdata.append(`titre`, titre);
    formdata.append(`client`, client);
    formdata.append(`date`, date);
    formdata.append(`page`, page);
    formdata.append(`size`, size);
    formdata.append(`ordername`, ordername);
    formdata.append(`ordertype`, ordertype);
    return this.http.post(endpoint + '/find11/' + tri, formdata);
  }
  getRecherche12(trigramme,qu,dis,zone): Observable<any> {

    let params = new HttpParams();
    params = params.append(`dis`, dis);
    params = params.append(`qu`, qu);
    params = params.append(`zone`, zone);
    console.log("recherche 13",params)
    return this.http.get(endpoint + '/find12/' + trigramme, {params});
  }
  getRecherche13(tagtechs,tagmetiers,tagfoncs,zones,dispos,exps,qualitecvs,page,size,trigramme): Observable<any> {

    const formdata: FormData = new FormData();
    formdata.append(`tagTech`, tagtechs);
    formdata.append(`tagFonc`, tagfoncs);
    formdata.append(`tagmetier`, tagmetiers);
    formdata.append(`qualitecv`, qualitecvs);
    formdata.append(`zone`, zones);
    formdata.append(`dispo`, dispos);
    formdata.append(`page`, page);
    formdata.append(`size`, size);
    formdata.append(`exp`, exps);
    console.log(tagtechs)
    return this.http.post(endpoint + '/find13/' + trigramme,formdata);
  }


  getRecherche14(inputsize,searchtype,tagtechs,tagmetiers,tagfoncs,nom,prenom,qualite,techno,titre,obs,dispo,score,page,size,ordername,ordertype,tri,exp): Observable<any> {

    for (let i =0;i<tagfoncs.length;i++){
      tagfoncs[i]=encodeURIComponent(tagfoncs[i]);
    }
    for (let i =0;i<tagtechs.length;i++){
      tagtechs[i]=encodeURIComponent(tagtechs[i]);
    }
      for (let i =0;i<tagmetiers.length;i++){
        tagmetiers[i]=encodeURIComponent(tagmetiers[i]);
    }

    const formdata: FormData = new FormData();

    formdata.append(`searchtype`, searchtype);
    formdata.append(`inputsize`, inputsize);
    formdata.append(`tagTech`, tagtechs);
    formdata.append(`tagFonc`, tagfoncs);
    formdata.append(`dispo`, dispo);
    formdata.append(`tagmetier`, tagmetiers);
    formdata.append(`name`, nom);
    formdata.append(`prenom`, prenom);
    formdata.append(`qualite`, qualite);
    formdata.append(`techno`, techno);
    formdata.append(`titre`, titre);
    formdata.append(`obs`, obs);
    formdata.append(`exp`, exp);
    formdata.append(`page`, page);
    formdata.append(`score`, score);
    formdata.append(`size`, size);
    formdata.append(`ordername`, ordername);
    formdata.append(`ordertype`, ordertype);

    return this.http.post(endpoint + '/find14/' + tri, formdata);
  }
  getRecherche15(zones,dispos,exps,qualitecvs,page,size,trigramme): Observable<any> {
    let params = new HttpParams();

    params = params.append(`qualitecv`, qualitecvs);
    params = params.append(`zone`, zones);
    params = params.append(`dispo`, dispos);
    params = params.append(`page`, page);
    params = params.append(`size`, size);
    params = params.append(`exp`, exps);

    return this.http.get(endpoint + '/find15/' + trigramme, {params});
  }

  getConsultants(input): Observable<any> {
    let params = new HttpParams();
    params = params.append('input',input);
    return this.http.get<any>(endpoint + `/state/`,{params});
  }
  state(input): Observable<any> {
    let params = new HttpParams();
    params = params.append('input',input);
    return this.http.get<any>(endpoint + `/state/`,{params});
  }

  findbyId(id,trigramme): Observable<any> {
    console.log("recherche 1findid")
    return this.http.get<any>(endpoint + '/findbyid/' + id+"/"+trigramme, httpOptions);
  }
  verifymc(input): Observable<any> {
    let params = new HttpParams();
    params = params.append('input',input);
    return this.http.get<any>(endpoint+"/verifymc",{params})
  }
  getexperience(): Observable<any>{
    return this.http.get<any>(endpoint+"/getexp")
  }
}
