import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { AuthService } from '../services/auth.service';
import { DashboardService } from './services/dashboard.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private modalService: NgbModal, private dialog: MatDialog, private dservice: DashboardService, private authService: AuthService){}

  ngOnInit(): void {
      //this.modalService.open(this.spinnerModal, { centered: true })
      this.loader()
      this.authService.getTrigrammes().subscribe(
        (data) => {
          this.selections.trigrammes = data;
        }
      )
      this.dservice.getSeclections().subscribe(
        (data: any) => {
          this.selections.tagfoncs = data.tagfoncs;
          this.selections.tagtechs = data.tagtechs;
          this.selections.tagmets = data.tagmets;
        }
      );
  }

  loadRecherche = true;
  loadAction = true;
  loadConsultant = true;
  loadQualite = true;
  loadRappel = true;
  loadSynthese = true;

  selections = {
    trigrammes: [],
    tagfoncs: [],
    tagtechs: [],
    tagmets: []
  }

  rechercheChangedHandler(value: boolean) {
    this.loadRecherche = value
  }
  actionChangedHandler(value: boolean) {
    this.loadAction = value;
  }
  consultantChangedHandler(value: boolean) {
    this.loadConsultant = value;
  }
  qualiteChangedHandler(value: boolean) {
    this.loadQualite = value;
  }
  rappelChangedHandler(value: boolean) {
    this.loadRappel = value;
  }
  syntheseChangedHandler(value: boolean) {
    this.loadSynthese = value;
    if(!this.loadSynthese){
      this.dialog.closeAll()
    }
  }

  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en cours`

      },
      disableClose: true,
    });
  }

  @ViewChild('spinnerModal', { read: TemplateRef }) spinnerModal!: TemplateRef<any>;

}
