<div style="display: flex;background-color: #EFEEEE;width: 100%; justify-content: center;padding: 14px;">
    <p style="font-size: 20px; font-weight: bolder;color: red;margin: 0;text-align: center;"> Nombre de rappels: <span style="color: #ff0000">{{totalSize}}</span>

    </p>
    <button *ngIf="showfilter" style="border: none;background-color: transparent;margin-top: -18px;margin-left: 10px;" (click)="resetFilter()">
    <img width="30" src="../../assets/filter.png" alt="">
  </button>

</div>

<div class="table-container">
    <table id="element" style="position:relative;" class="table table-striped table-hover">

        <thead>
            <tr class="table-secondary">
                <th scope="col" class="date">
                    <div class="input" id="date">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Date</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="dateFilter">
                        </mat-form-field>
                        <div (click)='sort("qualite")' class="sortbtn">
                            <img *ngIf="this.sortBy!='qualite'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="nom">
                    <div class="input" id="nom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Nom</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="nameFilter">
                        </mat-form-field>
                        <div (click)='sort("nom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='nom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!nomdesc  && this.sortBy=='nom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="nomdesc && this.sortBy=='nom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="prenom">
                    <div class="input" id="prenom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Prénoms</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="prenomFilter">
                        </mat-form-field>
                        <div (click)='sort("prenom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='prenom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="titre">
                    <div class="input" id="titre">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Titre</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="titreFilter">
                        </mat-form-field>
                        <div (click)='sort("titre")' class="sortbtn">
                            <img *ngIf="this.sortBy!='titre'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="email">
                    <div class="input" id="email">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Email</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="emailFilter">
                        </mat-form-field>
                        <div (click)='sort("email")' class="sortbtn">
                            <img *ngIf="this.sortBy!='email'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!emaildesc && this.sortBy=='email'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="emaildesc && this.sortBy=='email'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="rappel">
                    <div class="input" id="rappel">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Intitulé du rappel</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="rappelFilter">
                        </mat-form-field>
                        <div (click)='sort("rappel")' class="sortbtn">
                            <img *ngIf="this.sortBy!='rappel'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!rappeldesc && this.sortBy=='rappel'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="rappeldesc && this.sortBy=='rappel'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="obs">
                    <div class="input" id="obs">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Dernière observation</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="observationFilter">
                        </mat-form-field>
                        <div (click)='sort("obs")' class="sortbtn">
                            <img *ngIf="this.sortBy!='obs'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!obsdesc  && this.sortBy=='obs'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="obsdesc && this.sortBy=='obs'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>
                <th scope="col" class="check">
                    <div class="input" id="check">
                        <img width="25" src="../../assets/tick.png" id='up ' alt="">
                    </div>
                </th>

                <th scope="col" class="trig" *ngIf="roles.indexOf('Gestionnaire')>0 || roles.indexOf('Facturation')>0">
                    <div class="input" id="trig">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Trig</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="trigrammeFilter">
                        </mat-form-field>
                        <div (click)='sort("trig")' class="sortbtn">
                            <img *ngIf="this.sortBy!='trig'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!trigdesc  && this.sortBy=='trig'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="trigdesc && this.sortBy=='trig'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody style="position: relative;">
            <tr *ngFor="let item of rappels; let i = index">

                <td [innerHTML]="item.date" (click)="Select(item)" [ngClass]="{ 'highlight ': idDateFilter, 'default ': !idDateFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.date,$event)" style="text-align: center;" [id]="'date '+i"></td>
                <td [innerHTML]="item.nom" (click)="Select(item)" [ngClass]="{ 'highlight ': isNomFilter, 'default ': !isNomFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.nom,$event)" [id]="'nom '+i"></td>
                <td [innerHTML]="renderPrenom(item.prenom)" (click)="Select(item)" [ngClass]="{ 'highlight': isPrenomFilter, 'default': !isPrenomFilter  }" (mouseleave)="close($event)" [id]="'prenom '+i" (mouseenter)="setdata(item.prenom,$event)"></td>

                <td [innerHTML]="item.titre" (click)="Select(item)" [ngClass]="{ 'highlight': isTitreFilter, 'default': !isTitreFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.titre,$event)" class="titrepx" [id]="'titre '+i"></td>
                <td [innerHTML]="item.email" (click)="Select(item)" [ngClass]="{ 'highlight': isEmailFilter, 'default': !isEmailFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.email,$event)" class="emailpx" [id]="'email '+i"></td>
                <td [innerHTML]="item.libelleRappel" (click)="Select(item)" [ngClass]="{ 'highlight': isRappelFilter, 'default': !isRappelFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.libelleRappel,$event)" class="telpx" [id]="'tel '+i"></td>
                <td [innerHTML]="item.observation" (click)="Select(item)" [ngClass]="{ 'highlight': isObsFilter, 'default': !isObsFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.observation,$event)" class="observationpx" [id]="'observation '+i"></td>

                <td style="position: relative;">
                    <input autocomplete="off" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);" (change)="updateRappels(item)" type="checkbox" name="" id="">
                </td>

                <td *ngIf="roles.indexOf('Gestionnaire')>0 || roles.indexOf('Facturation')>0" [innerHTML]="item.trigramme" (click)="Select(item)" [ngClass]="{ 'highlight': isTrigrammeFilter, 'default': !isTrigrammeFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.trigramme,$event)"
                    class="trigrammepx" style="text-align: center;" [id]="'trigramme '+i"></td>


            </tr>

        </tbody>
    </table>
</div>


<div class="has-text-centered " style="margin: auto;display: flex;justify-content: center;margin-top: 1vw;">

    <div style="flex-grow: 1;display: flex;justify-content: center;">
        <img *ngIf="getpreviousMove()" (click)="FirstPage()" style="cursor: pointer; margin-right: 1vw;" width="25" src="../../assets/fisrtPageActive.png" alt="">
        <img *ngIf="getPrevious()" (click)="previousPage()" style="cursor: pointer; margin-right: 3vw" width="20" src="../../assets/previousPageActive.png" alt="">

        <img *ngIf="!getpreviousMove()" style="margin-right: 1vw" width="25" src="../../assets/firstPage.png" alt="">
        <img *ngIf="!getPrevious()" style="margin-right: 3vw" width="20" src="../../assets/previousPage.png" alt="">

        <input autocomplete="off" (keyup.enter)="goto($event.target.value)" [(ngModel)]="currentPage" type="text" style="width: 90px;color: red;font-weight: bold;height: 25px;"> / {{totalPage+1}}
        <img *ngIf="getNext()" (click)="NexPage()" style="cursor: pointer;margin-left: 3vw; " width="20" src="../../assets/nextPageActive.png" alt="">
        <img *ngIf="getlastpage()" (click)="LastPage()" style="cursor: pointer;margin-left: 1vw;" width="25" src="../../assets/lastPageActive.png" alt="">

        <img *ngIf="!getNext()" style="margin-left: 3vw;" width="20" src="../../assets/nextPage.png" alt="">
        <img *ngIf="!getlastpage()" style="margin-left: 1vw;" width="25" src="../../assets/lastPage.png" alt="">
    </div>
    <div style="display: flex;justify-content: center;align-items: center;">
        <p style="margin: 0;margin-right: 5px;">Par Page:</p>
        <select (change)="ChangeItemPerPage($event.target.value)" name="size" [(ngModel)]="pageSize" id="">
            <option *ngFor="let item of pageSizeElement" [value]="item" >{{item}}</option>

          </select>
    </div>
</div>
