import { Component, OnInit, Input, HostListener, ViewChildren, ElementRef,QueryList } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DisponibiliteService } from '../services/disponibilite.service';
import { QualiteService } from '../services/qualite.service';
import { ConsultantService } from '../services/consultant.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UploadFileService } from '../upload/upload-file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CommunicationService } from '../services/communication.service';
import { ActionsService } from '../services/actions.service';
import { TechnologieService } from '../services/technologie.service';
import { ClientService } from '../services/client.service';
import { TagtechService } from '../services/tagtech.service';
import { TagfoncService } from '../services/tagfonc.service';
import { TagmetService } from '../services/tagmet.service';
import { Rappels } from '../Model/rappels';
import { RappelsService } from '../services/rappels.service';
import { CV } from '../Model/cv';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { RefRechercheService } from '../services/ref-recherche.service';
import { Tagtech } from '../Model/tagtech';
import { Tagfonc } from '../Model/tagfonc';
import { Tagmet } from '../Model/tagmet';
import { Client } from '../Model/client';
import { Technologie } from '../Model/technologie';
import { Observation } from '../Model/observation';
import { DictioService } from '../services/dictio.service';
import { Dictionnaire } from '../Model/dictionnaire';
import { MotsDictionnaire } from '../Model/mots-dictionnaire';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';

//unitile
//function arrayRemove(arr, value) { return arr.filter(function(ele){ return ele != value; });}

@Component({
  selector: 'app-ajout-consultant',
  templateUrl: './ajout-consultant.component.html',
  styleUrls: ['./ajout-consultant.component.scss'],
  styles: [`
  :host {
      position: fixed,
      top: 0,
      left: 0
  }
`],
  providers: [DatePipe]
})

export class AjoutConsultantComponent implements OnInit {


  @HostListener("window:keyup", ["$event"]) Getregex(event: any) {
    if (event.target.id === "regTag") {
      this.regex = event.target.value;
      this.getTagTechPage({
        page: "0",
        size: this.size,
        regex: this.regex,
      });
    }

    if (event.target.id === "client") {
      this.regexClient = event.target.value;
      this.getClientPage({
        page: "0",
        size: this.sizeClient,
        regex: event.target.value,
      });
    }
    if (event.target.id === "metier") {
      this.regexMetier = event.target.value;
      this.getMetierPage({
        page: "0",
        size: this.sizeMetier,
        regex: event.target.value,
      });
    }
    if (event.target.id === "fonction") {
      this.regexFonction = event.target.value;
      this.getFonctionPage({
        page: "0",
        size: this.sizeFonction,
        regex: event.target.value,
      });
    }
  }

  TagTechTabFilter: Tagtech[] = [];
  TechnoTabFilter: Technologie[] = [];
  ClientTabFilter: Client[] = [] ;
  MetierTabFilter: Tagmet[] = [];
  FonctionTabFilter: Tagfonc[] = [];

  initcv= new CV();
  filename: string;

  tabcv : any = [];
  tcv = new CV();

  dictio= new Dictionnaire();
  motsDic = new MotsDictionnaire();

  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();

  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  colo = 'green';
  red = false;
  green = false;
  orange =false;
  grey = false;

  today = new Date().toJSON().split('T')[0];
  rappel= new Rappels();
  conrappel: any = [];
  rptest= new Rappels();
  obstab : any = [];
  obstest = new Observation();
  numrappel: number = 0;

  isModerateur = false;
  /*  TagTech Pagination */
  libelle: string = '';
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  tagTechUpdate: Tagtech = new Tagtech();
  totalElements: number = 0;
  tagTechPage: Tagtech[] = [];
  loading: boolean;
  suivant = 0;
  ordre = 0;
  size: number = 25;
  totalPage: number;
  regex: string = "";
  technologie="";
  TagTechPageUpdate: Tagtech[] = [];
  TagTechFilter: Tagtech[] = [];
  //isChecked: boolean = false;

  /* Techno Pagination */
  technoUpdate: Technologie = new Technologie();
  totalElementsTechno: number = 0;
  TechnoPage: Technologie[] = [];
  loadingTechno: boolean;
  suivantTechno = 0;
  dispoordre=0;
  sizeTechno: number = 25;
  totalPageTechno: number;
  regexTechno: string = "";
  TechnoPageUpdate: Technologie[] = [];
  TechnoFilter: Technologie[] = [];


  /*  Client Pagination */
  clientUpdate: Client = new Client();
  totalElementsClient: number = 0;
  clientPage: Client[] = [];
  loadingClient: boolean;
  suivantClient = 0;
  sizeClient: number = 25;
  totalPageClient: number;
  regexClient: string = "";
  ClientPageUpdate: Client[] = [];
  ClientFilter: Client[] = [];

   /*  Fonc Pagination */
   FonctionUpdate: Tagfonc = new Tagfonc();
   totalElementsFonction: number = 0;
   fonctionPage: Tagfonc[] = [];
   loadingFonction: boolean;
   suivantFonction = 0;
   sizeFonction: number = 25;
   totalPageFonction: number;
   regexFonction: string = "";
   FonctionPageUpdate: Tagfonc[] = [];
   FonctionFilter: Tagfonc[] = [];

   /*  metier Pagination */
   MetierUpdate: Tagmet = new Tagmet();
   totalElementsMetier: number = 0;
   metierPage: Tagmet[] = [];
   loadingMetier: boolean;
   suivantMetier = 0;
   sizeMetier: number = 25;
   totalPageMetier: number;
   regexMetier: string = "";
   MetierPageUpdate: Tagmet[] = [];
   MetierFilter: Tagmet[] = [];

   user: any;

  constructor(public ds: DisponibiliteService, public qs: QualiteService,
              public us: ConsultantService, public as: ActionsService, private formBuilder: FormBuilder,
              private dialog: MatDialog, private router: Router,
              private datePipe: DatePipe, private fb: FormBuilder,
              private uploadService: UploadFileService,
              private communicationService: CommunicationService,
              private dcs: DictioService,
              private ts: TechnologieService,
              private cl: ClientService,
              private SpinnerService: NgxSpinnerService,
              private tt: TagtechService,
              private tf: TagfoncService,
              private tm: TagmetService,private tokenStorageService: TokenStorageService,
              private rs: RappelsService, private rrs: RefRechercheService,
              private auth : AuthService
    ) {
      this.communicationService.Broadcast('OnChild2', 'Ajout Consultant');
      const formControls = this.Tech.map(control => new FormControl(false));
      const formControls2 = this.Clients.map(control => new FormControl(false));
      const formControls3 = this.TagTech.map(control => new FormControl(false));
      const formControls4 = this.TagMetier.map(control => new FormControl(false));
      const formControls5 = this.TagFonc.map(control => new FormControl(false));


//Mise en place du modèle pour la récupération des données du formulaire
//modification des paramètre de registerForm lors du changement des données du formulaire
      this.registerForm = this.formBuilder.group({
        nom: ['', Validators.required],
        prenom: ['', Validators.required],
        titre: ['', Validators.required],
       // tjm: new FormControl('', Validators.pattern('[0-9]+')),
        tjm: [''],
        dis: [''],
        quali: [''],
        linkedin: [''],
        code: [''],
        ville: [''],
        dmaj: [''],
        observation: [''],
        tech: [''],
        tech2: [''],
        client: [''],
        client2: [''],
        tagtech: [''],
        tagmetier: [''],
        tagfonc: [''],
        collaborateur: [''],
        dda: [''],
        addresse: [''],
        addresse2: [''],
        exp: [''],
        source: ['', Validators.required],
        telp:  new FormControl(''),
        email: new FormControl('@' ),
        cv: ['', Validators.required]
    });
  }


    get f() { return this.registerForm.controls; }
  registerForm: FormGroup;
   bodyText: string;
  submitted = false;
  Dispo = [];
  Qualite = [];
  Clients = [];
  TagTech = [];
  TagMetier = [];
  TagFonc = [];
  Tech = [];
  dispolibelle=""
  Techno = [];
  Cli = [];
  Tagtech = [];
  Tagmet = [];
  Tagfonc = [];
  @Input() consultantData = { nom: '', prenom: '', titre: '',
  tjm: '', telephone_principal: '', email: '', linkedin: '', source:'', code_postal: '',
  ville: '', cv: [], dateDerniereMaj: '', observation: [], tech: [], client: [],collaborateur: '', tagtech: [],
  tagmetier: [], tagfonc: [], dda:'', exp:'', rappels: [],qualitecv:{},codedispo:{}};

  @Input() actionsData = { action: '', date: '', trigramme: '', consultantId: ''};

  nom = '';
  prenom = '';
  titre = '';
  tjm = '';
  dis = '';
  quali = '';
  telp = '';
  email = '';
  linkedin = '';
  code = '';
  ville = '';
  cv = [];
  dmaj = '';
  observation = [];
  tech = [];
  client = [];
  tagtech = [];
  tagmetier = [];
  tagfonc = [];
  collaborateur = '';
  dda= '';
  exp= '';
  rappels = '';
  closeResult: string;
  collaborateurdata="";
  myDate = new Date();

  expanded1 = false;
  expanded2 = false;
  expanded3 = false;
  expanded4 = false;
  expanded5 = false;

  //CV propriété :
  days = new Date().getDate().toString();
  month = new Date().getMonth().toString();
  year = new Date().getFullYear().toString();
  hours = new Date().getHours().toString();
  minutes = new Date().getMinutes().toString();
  secondes = new Date().getSeconds().toString();

  date: string = this.year+this.month+this.days+"_"+this.hours+this.minutes+this.secondes;

  ngOnInit() {

    //Rédiection sur le Dashboard si l'utilisateur n'est pas un admin
    if(!this.auth.canModerate()){
      this.router.navigate(['/dashboard']);
    }

    //Scroll en HAUT DE PAGE
    window.scroll(0,0);

    this.isModerateur = this.auth.canModerate();
    this.bodyText = 'This text can be updated in modal 1';
    this.getDispo();
    this.getQualite();

    //Récupération des tags tech en base en fonction
    //du nombre d'élément par page  et du mot de la recherche ici c'est vide
    this.getTagTechPage({ page: "0", size: "25", regex: this.regex });

    //Récupération des Clients en base en fonction
    //du nombre d'élément par page  et du mot de la recherche ici c'est vide
    this.getClientPage({ page: "0", size: "25", regex: this.regexClient });


    //Récupération des tags fonction en base en fonction
    //du nombre d'élément par page  et du mot de la recherche ici c'est vide
    this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });


    //Récupération des tags mettier en base en fonction
    //du nombre d'élément par page  et du mot de la recherche ici c'est vide
    this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });
    this.user = this.tokenStorageService.getUser();
  }

  /* fonction get page tagtech*/

  /**
 * Récupération des tags Tech dans la base
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */

  getTagTechPage(request) {
    this.loading = true;
    this.SpinnerService.show();
    this.tt.Tags(request)
      .subscribe(data => {
        this.tagTechPage = data['content'];
        this.TagTechTabFilter = this.tagTechPage;
        this.totalElements = data['totalElements'];
        this.totalPage = Math.ceil((this.totalElements/this.size));
        this.loading = false;
        this.SpinnerService.hide();

        //Supprmer les tags déjà ajouter dans la liste des tags
        if (this.TagTechPageUpdate.length !== 0) {
          this.TagTechPageUpdate.forEach(
          (action) =>
            (this.TagTechTabFilter = this.TagTechTabFilter.filter((u) => u.libelle !== action.libelle))
        );

    }
      }, error => {
        this.loading = false;
      }
      );
  }


  /**
 * Changer le nombre d'éléments par page
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nouveau nombre d'éléments par page dans request
 * Relancer la recherche des Tags  avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  ChangerTagsPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.size;
    request["regex"] = this.regex;

    this.getTagTechPage(request);
    this.suivant = 0;
  }

  /**
 * Revenir à la première page dans la liste des tags tech
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Tech avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PremiereTagsPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.size
    request["regex"] = this.regex;
    this.getTagTechPage(request);
    this.suivant = 0;
  }


  /**
 * Revenir à la dernière page dans la liste des tags tech
 * Passer à la dernière  page
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Tech avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  FinalTagsPage() {
    const request = {};
    this.suivant = this.totalPage - 1;
    request["page"] = this.totalPage - 1;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
  }



  /**
 * Naviger vers la page suivant des Tags tech
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Tech avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  nextTagsPage() {
    const request = {};
    request["page"] = ++this.suivant;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);

  }


  /**
 * Naviger vers la page précedents des Tags tech
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Tech avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PreviousTagsPage() {
    const request = {};
    request["page"] = --this.suivant;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
  }


 /**
 * Enlever le Tag tech dans la liste des Tags tech du consultant
 * @param  {Tagtech} tgt Tag tech sélectionné
 * @param  {boolean} isChecked Etat du tag Tag tech  ou Non
 * @return {void}
 */
  ChangeTagTech(tg: Tagtech, isChecked: boolean) {
    if (!isChecked) {
      let index = this.TagTechPageUpdate.findIndex(
        (x) => x === tg
      );
      if (index != -1) this.TagTechPageUpdate.splice(index,1);
      this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
}
}

 /**
 * Ajout d'un tag tech dans la liste des tags tech du consultant
 * @param  {Tagtech} tgt Tag tech sélectionné
 * @param  {boolean} isChecked Etat du tag Tag tech  ou Non
 * @return {void}
 */
AddTagsToUpdate(tgt: Tagtech, isChecked: boolean) {
if (isChecked) {
  this.TagTechFilter.push(tgt);
    this.TagTechPageUpdate.push(tgt);
    this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
  }
else {
let index = this.TagTechPageUpdate.findIndex(
  (x) => x === tgt
);
if (index != -1) this.TagTechPageUpdate.splice(index,1);
}

}



////////////////////////////////////////////  /* Fin Pagination*/


   /* fonction get page tagmet*/
  /**
 * Récupération des tags Métier dans la base
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  getMetierPage(request) {
    this.loadingMetier = true;
    this.SpinnerService.show();
    this.tm.mets(request)
      .subscribe(data => {
        this.metierPage = data['content'];
        this.MetierTabFilter = this.metierPage;
        this.totalElementsMetier = data['totalElements'];
        this.totalPageMetier = Math.ceil((this.totalElementsMetier/this.sizeMetier));
        this.loadingMetier = false;
        this.SpinnerService.hide();
        if (this.MetierPageUpdate.length !== 0) {
          this.MetierPageUpdate.forEach(
          (action) =>
            (this.MetierTabFilter = this.MetierTabFilter.filter((u) => u.libelle !== action.libelle))
        );

    }
      }, error => {
        this.loadingMetier = false;
      }
      );
  }

    /**
 * Changer le nombre d'éléments par page
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nouveau nombre d'éléments par page dans request
 * Relancer la recherche des Tags  avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  ChangerMetierPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;

    this.getMetierPage(request);
    this.suivantMetier = 0;
  }

    /**
 * Revenir à la première page dans la liste des tags Métier
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Métier avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PremiereMetierPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeMetier
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
    this.suivantMetier = 0;
  }

    /**
 * Revenir à la dernière page dans la liste des tags Métier
 * Passer à la dernière  page
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Métier avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  FinalMetierPage() {
    const request = {};
    this.suivantMetier = this.totalPageMetier - 1;
    request["page"] = this.totalPageMetier - 1;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
  }


    /**
 * Naviger vers la page suivant des Tags Métier
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Métier avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  nextMetierPage() {
    const request = {};
    request["page"] = ++this.suivantMetier;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);

  }


  /**
 * Naviger vers la page précedents des Tags Métier
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Métier avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PreviousMetierPage() {
    const request = {};
    request["page"] = --this.suivantMetier;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
  }



 /**
 * Enlever le Tag métier dans la liste des Tags métier du consultant
 * @param  {Tagmet} tgt Tag métier sélectionné
 * @param  {boolean} isChecked Etat du tag Tag métier  ou Non
 * @return {void}
 */
  ChangeMetier(tg: Tagmet, isChecked: boolean) {
    if (!isChecked) {
      let index = this.MetierPageUpdate.findIndex(
        (x) => x === tg
      );
      if (index != -1) this.MetierPageUpdate.splice(index,1);
      this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });
}
}


 /**
 * Ajout d'un tag Métier dans la liste des tags métier du consultant
 * @param  {Tagmet} tgt Tag tech sélectionné
 * @param  {boolean} isChecked Etat du Tag métier  ou Non
 * @return {void}
 */
AddMetierToUpdate(tgt: Tagmet, isChecked: boolean) {
if (isChecked) {
  this.MetierFilter.push(tgt);
    this.MetierPageUpdate.push(tgt);
    this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });

  }
else {
let index = this.MetierPageUpdate.findIndex(
  (x) => x === tgt
);
if (index != -1) this.MetierPageUpdate.splice(index,1);
}
//this.isChecked = false;
}


////////////////////////////////////////////  /* Fin Pagination*/



     /* fonction get page FOnction*/
  /**
 * Récupération des tags Fonction dans la base
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  getFonctionPage(request) {
    this.loadingFonction = true;
    this.SpinnerService.show();
    this.tf.foncs(request)
      .subscribe(data => {
        this.fonctionPage = data['content'];
        this.FonctionTabFilter = this.fonctionPage;
        this.totalElementsFonction = data['totalElements'];
        this.totalPageFonction = Math.ceil((this.totalElementsFonction/this.sizeFonction));
        this.loadingFonction = false;
        this.SpinnerService.hide();
        if (this.FonctionPageUpdate.length !== 0) {
          this.FonctionPageUpdate.forEach(
          (action) =>
            (this.FonctionTabFilter = this.FonctionTabFilter.filter((u) => u.libelle !== action.libelle))
        );

    }
      }, error => {
        this.loadingFonction = false;
      }
      );
  }

    /**
 * Changer le nombre d'éléments par page
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nouveau nombre d'éléments par page dans request
 * Relancer la recherche des Tags  avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */

  ChangerFonctionPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;

    this.getFonctionPage(request);
    this.suivantFonction = 0;
  }

    /**
 * Revenir à la première page dans la liste des tags Fonction
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Fonction avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PremiereFonctionPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeFonction
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
    this.suivantFonction = 0;
  }


  /**
 * Revenir à la dernière page dans la liste des tags Fonction
 * Passer à la dernière  page
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Fonction avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  FinalFonctionPage() {
    const request = {};
    this.suivantFonction = this.totalPageFonction - 1;
    request["page"] = this.totalPageFonction - 1;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
  }

  /**
 * Naviger vers la page suivant des Tags Fonction
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Fonction avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  nextFonctionPage() {
    const request = {};
    request["page"] = ++this.suivantFonction;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);

  }


    /**
 * Naviger vers la page précedents des Tags Fonction
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Tags Fonction avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des tags en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
  PreviousFonctionPage() {
    const request = {};
    request["page"] = --this.suivantFonction;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
  }


   /**
 * Enlever le Tag fonction dans la liste des Tags fonction du consultant
 * @param  {Tagfonc} tgt Tag fonction sélectionné
 * @param  {boolean} isChecked Etat du tag Tag fonction  ou Non
 * @return {void}
 */
  ChangeFonction(tg: Tagfonc, isChecked: boolean) {
    if (!isChecked) {
      let index = this.FonctionPageUpdate.findIndex(
        (x) => x === tg
      );
      if (index != -1) this.FonctionPageUpdate.splice(index,1);
      this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });
}
}

 /**
 * Ajout d'un tag Fonction dans la liste des tags fonction du consultant
 * @param  {Tagfonc} tgt Tag fonction sélectionné
 * @param  {boolean} isChecked Etat du tag fonction  ou Non
 * @return {void}
 */
AddFonctionToUpdate(tgt: Tagfonc, isChecked: boolean) {
if (isChecked) {
  this.FonctionFilter.push(tgt);
    this.FonctionPageUpdate.push(tgt);
    this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });
  }
else {
let index = this.FonctionPageUpdate.findIndex(
  (x) => x === tgt
);
if (index != -1) this.FonctionPageUpdate.splice(index,1);
}
//this.isChecked = false;
}


////////////////////////////////////////////  /* Fin Pagination*/



/**
 * Récupération des clients dans la base
 * @param  {String} request Paramètre de récupération des clients du nombre d'élément et du mot de la recherche
 * @return {void}
 */
getClientPage(request) {
  this.loadingClient = true;
  this.SpinnerService.show();
  this.cl.Clients(request)
    .subscribe(data => {
      this.clientPage = data['content'];
      this.ClientTabFilter = this.clientPage;
      this.totalElementsClient = data['totalElements'];
      this.totalPageClient = Math.ceil((this.totalElementsClient/this.sizeClient));
      this.loadingClient = false;
      this.SpinnerService.hide();
      if (this.ClientPageUpdate.length !== 0) {
        this.ClientPageUpdate.forEach(
        (action) =>
          (this.ClientTabFilter = this.ClientTabFilter.filter((u) => u.libelle !== action.libelle))
      );

  }
    }, error => {
      this.loadingClient = false;
    }
    );
}

/**
 * Changer le nombre d'éléments par page
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nouveau nombre d'éléments par page dans request
 * Relancer la recherche des clients  avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des clients en fonction du nombre d'élément et du mot de la recherche
 * @return {void}
 */
ChangerClientPageSize() {
  const request = {};
  request["page"] = 0;
  request["size"] = this.sizeClient;
  request["regex"] = this.regexClient;

  this.getClientPage(request);
  this.suivantClient = 0;
}

    /**
 * Revenir à la première page dans la liste des clients
 * Passer à la page 0
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des clients avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des clients du nombre d'élément et du mot de la recherche
 * @return {void}
 */
PremiereClientPage() {
  const request = {};
  request["page"] = 0;
  request["size"] = this.sizeClient
  request["regex"] = this.regexClient;
  this.getClientPage(request);
  this.suivantClient = 0;
}


  /**
 * Revenir à la dernière page dans la liste des clients
 * Passer à la dernière  page
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des clients avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des clients du nombre d'élément et du mot de la recherche
 * @return {void}
 */
FinalClientPage() {
  const request = {};
  this.suivantClient = this.totalPageClient - 1;
  request["page"] = this.totalPageClient - 1;
  request["size"] = this.sizeClient;
  request["regex"] = this.regexClient;
  this.getClientPage(request);
}


  /**
 * Naviger vers la page suivant des Client
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Client avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des clients du nombre d'élément et du mot de la recherche
 * @return {void}
 */
nextClientPage() {
  const request = {};
  request["page"] = ++this.suivantClient;
  request["size"] = this.sizeClient;
  request["regex"] = this.regexClient;
  this.getClientPage(request);

}


    /**
 * Naviger vers la page précedents des Clients
 * Garder les paramètres de recherche lors du changement d'éléments par page
 * Assigner le nombre d'éléments par page dans request
 * Relancer la recherche des Clients avec les nouveaux paramètres
 * @param  {String} request Paramètre de récupération des clients du nombre d'élément et du mot de la recherche
 * @return {void}
 */
PreviousClientPage() {
  const request = {};
  request["page"] = --this.suivantClient;
  request["size"] = this.sizeClient;
  request["regex"] = this.regexClient;
  this.getClientPage(request);
}

   /**
 * Enlever le Client dans la liste des Tags fonction du consultant
 * @param  {Client} clt Client sélectionné
 * @param  {boolean} isChecked Etat du tag Client  ou Non
 * @return {void}
 */
ChangeClient(clt: Client, isChecked: boolean) {
  if (!isChecked) {
    let index = this.ClientPageUpdate.findIndex(
      (x) => x === clt
    );
    if (index != -1) this.ClientPageUpdate.splice(index,1);
    this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
}
}


 /**
 * Ajout d'un Client dans la liste des tags fonction du consultant
 * @param  {Client} tgt Client sélectionné
 * @param  {boolean} isChecked Etat du Client  ou Non
 * @return {void}
 */
AddClientToUpdate(clt: Client, isChecked: boolean) {
if (isChecked) {
this.ClientFilter.push(clt);
  this.ClientPageUpdate.push(clt);
  this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
}
else {
let index = this.ClientPageUpdate.findIndex(
(x) => x === clt
);
if (index != -1) this.ClientPageUpdate.splice(index,1);
}
//this.isChecked = false;
}
Loader(){
  this.dialog.open(ProgressSpinnerDialogComponent, {
    data: {

      message: `
       Chargement en Cours`

    },
    disableClose: true,
  });
}
addClientTodatabase(libelle){
  this.Loader()
  var type = "Client";
  if(libelle===""){
    this.openAlertWarning();
  }

  else{
    let clienttemp = this.clientUpdate;

    this.cl.findByLibelle(libelle).subscribe((data) => {

      if(data===null) {

        this.ClientPageUpdate.push(clienttemp);

            this.cl.addClient(libelle).subscribe(data =>{
              if(data===1){
                this.dialog.closeAll()
    this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
    this.ClientTabFilter = this.ClientTabFilter.filter((a)=> a.libelle!=this.clientUpdate.libelle)


              }
        });
  }
  else {

    this.dialog.closeAll()
    this.OpenPopupAlertMode('client existe déjà, merci de le sélectionner dans la liste proposée');

  }
});
  }
}
AddClient() {

  if(this.clientUpdate.libelle === '')
    this.OpenPopupAlertMode("Merci de saisir un nouveau client");
  else {
    this.clientUpdate.libelle = this.clientUpdate.libelle.toUpperCase();
    //console.log(this.clientUpdate.libelle.toUpperCase);
    this.addClientTodatabase(this.clientUpdate.libelle)


    this.inputs.forEach((element) => {
      element.nativeElement.value = "";
    });

    this.clientUpdate = new Client();
  }
}
OpenPopupAlertMode(text){
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message:text,
    },
  });
}
////////////////////////////////////////////  /* Fin Pagination*/

//Liste des disponibilités
  getDispo() {
    this.ds.getRefs().subscribe((data2: {}) => {
// tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i]);
    }
    });
  }

//Liste des qualités Cv
  getQualite() {
    this.qs.getRefs().subscribe((data2: {}) => {
// tslint:disable-next-line: forin
      for (const i in data2) {
        this.Qualite.push(data2[i].libelle);
    }
    });
  }


//Liste des clients
  getClients(){
    this.cl.getClients().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.Clients.push(data2[i].libelle);
              }
          });
  }

  //Liste des tags
  //met ,Tech,fonc
  getTags(){
    this.tt.getTagtech().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagTech.push(data2[i].libelle);
              }
          });

    this.tm.getTagmet().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagMetier.push(data2[i].libelle);
              }
          });
    this.tf.getTagfonc().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagFonc.push(data2[i].libelle);
              }
          });
  }



  /**
 * Ajout d'un consultant dans la fiche consultant
 * @param  {void}
 * @return {void}
 */
  addConsultant() {
    this.Loader()
    this.onChangeQualiteCv(this.registerForm.value.quali);
    this.changeDispo(this.dispolibelle);
    const user = this.tokenStorageService.getUser();

    this.submitted = true;
      if (this.registerForm.invalid) {
        console.log("invalid form")
        this.dialog.closeAll();
        return;
    }

    //Calcul de l'expérience en fonction de la date saisie
    //Différence entre l'année de la date actuelle et la date saisie par l'utilisateur
    var dateDda = new Date(this.registerForm.value.dda);
    var daysDiff = this.myDate.getFullYear() - dateDda.getFullYear();

    var experience="";

    if (daysDiff<=3){
      experience = '0-3 ans';
    }
    if ((daysDiff>=4)&&(daysDiff<=7)){
      experience = '4-7 ans';
    }
    if (daysDiff>7){
      experience = '>7 ans';
    }

    this.tcv.cheminCV = "../upload-dir";
    this.tcv.dateCV = this.today.toString();
    this.tcv.nomCV = this.filename;

    this.tabcv.push(this.tcv);

    //Unitile
    //this.checkClient();

    if((this.registerForm.value.addresse!="")&&(this.registerForm.value.addresse2!="")){
      this.numrappel++;
      this.rptest.libelleRappel = this.registerForm.value.addresse;
      this.rptest.date = this.datePipe.transform(this.registerForm.value.addresse2, 'yyyy-MM-dd');
      this.rptest.actif = "OUI";
      this.rptest.trigramme = user.trigramme;
      this.rptest.numrappel = this.numrappel;
      this.conrappel.push(this.rptest);
    }

    if(this.registerForm.value.observation!=""){
      var datetoday = new Date();
      var hours = new Date().getHours();
      var Min = new Date().getMinutes();

      var obsdate = this.datePipe.transform(datetoday, 'yyyy-MM-dd');
      this.obstest.dateObs = obsdate.toString()+" "+hours.toString()+":"+Min.toString();
      this.obstest.libelleObs = this.registerForm.value.observation;
      this.obstest.trigrammeObs = user.trigramme;

      this.obstab.push(this.obstest);
    }

    //Ajout des données dans la variable consultantdata pour l'envoi en ligne
    this.consultantData = {
      nom: this.registerForm.value.nom.toUpperCase(),
      prenom: this.registerForm.value.prenom.toUpperCase(),
      titre: this.registerForm.value.titre,
      tjm: this.registerForm.value.tjm,
      telephone_principal: this.registerForm.value.telp,
      email: this.registerForm.value.email,
      linkedin: this.registerForm.value.linkedin,
      code_postal: this.registerForm.value.code,
      ville: this.registerForm.value.ville,
      cv: this.tabcv,
      source: this.registerForm.value.source,
      dateDerniereMaj: this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm'),
      dda: this.datePipe.transform(this.registerForm.value.dda, 'yyyy-MM-dd HH:mm'),
      exp: experience,
      observation: this.obstab,
      collaborateur: this.collaborateurdata,
      tech: [{libelle:this.technologie}],
      client: this.ClientPageUpdate,
      tagtech: this.TagTechPageUpdate,
      tagmetier: this.MetierPageUpdate,
      tagfonc: this.FonctionPageUpdate,
      rappels: this.conrappel,
      codedispo:{libelle: this.dispolibelle,ordre:this.dispoordre},
      qualitecv:{
        libelle:this.registerForm.value.quali,
        ordre:this.ordre
      }

    };

    if(this.registerForm.value.email === ""){
      this.openAlertMissing("Il faut qu'au moins l'email existe !");
    } else {
      if((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2==="")){

        this.us.addConsultant(this.consultantData, this.user.trigramme).subscribe(
          (result) => {
            let udata = {
              "email": this.registerForm.value.email,
              "trigramme": "",
              "nom": this.registerForm.value.nom,
              "prenom": this.registerForm.value.prenom,
              "password": "123456",
              "roles": ["Consultant"],
              "rolescrm": []
            };
            this.auth.register(udata).subscribe(
              (response) => {
                let pdata = {
                  "userid": response.id,
                  "consultantid": result.id,
                  "email": this.registerForm.value.email,
                  "nom": this.registerForm.value.nom,
                  "prenom": this.registerForm.value.prenom,
                  "titre": this.registerForm.value.titre
                }
                this.us.addProfileOnCreation(pdata).subscribe()
              }
            )
            if(result){
              this.dialog.closeAll();
              this.openAlertDialog();
            }
            this.upload();
            this.dialog.closeAll();
            this.openAlertDialog();
          },
          (error) => {
            console.log(error)
            this.Alert(error.error.message)
          }
        );
      }
      if((this.registerForm.value.addresse!="")&&(this.registerForm.value.addresse2!="")){
        this.us.addConsultant(this.consultantData, this.user.trigramme).subscribe((result) => {
          if(result){
            this.addRappel(user.trigramme,result);
            let udata = {
              "email": this.registerForm.value.email,
              "trigramme": "",
              "nom": this.registerForm.value.nom,
              "prenom": this.registerForm.value.prenom,
              "password": "123456",
              "roles": ["Consultant"],
              "rolescrm": []
            };
            this.auth.register(udata).subscribe(
              (response) => {
                let pdata = {
                  "userid": response.id,
                  "consultantid": result.id,
                  "email": this.registerForm.value.email,
                  "nom": this.registerForm.value.nom,
                  "prenom": this.registerForm.value.prenom,
                  "titre": this.registerForm.value.titre
                }
                this.us.addProfileOnCreation(pdata).subscribe()
              }
            )
          }
          this.upload();

          this.dialog.closeAll()
          this.openAlertDialog();


          });
      }
      if(((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2!=""))||((this.registerForm.value.addresse!="")&&(this.registerForm.value.addresse2===""))){
        this.openAlertMissing("Vérifier la date ou l'objet du rappel")
      }
    }
    //Vider les données des données des tableaux pour éviter des doublons
    this.TagTechPageUpdate = [];
    this.MetierPageUpdate = [];
    this.FonctionPageUpdate = [];
    this.TechnoPageUpdate = [];
    this.ClientPageUpdate = [];
  }


//Popup pour afficher un champs vide
openAlertMissing(msg) {
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message: msg,
      buttonText: {
        cancel: 'Retour'
      }
    },
  });
  dialogRef.afterClosed().subscribe(result => {
  });
}

  openAlertWarning() {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Vérifier la sélection des Tags (Technique, Métiers et Fonctions)",
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //Ajout de rappel
  addRappel(param,result){
    this.rappel.idC = result._id;
    this.rappel.nom = this.registerForm.value.nom;
    this.rappel.prenom = this.registerForm.value.prenom;
    this.rappel.observation = this.obstab[0];
    this.rappel.titre = this.registerForm.value.titre;
    this.rappel.libelleRappel = this.registerForm.value.addresse;
    this.rappel.date = this.datePipe.transform(this.registerForm.value.addresse2, 'yyyy-MM-dd');
    this.rappel.actif = "OUI";
    this.rappel.trigramme = param;
    this.rappel.numrappel = this.numrappel;
    this.rappel.email = this.email
    console.log("add rappel",this.rappel)
    //Ajout de rappel en base
    this.rs.addRappel(this.rappel).subscribe((result2) => {
      if(result2){
        this.dialog.closeAll()
        this.openAlertDialog();
      }
      });
  }

  //Popup de succès lors de l'ajout
  openAlertDialog() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: 'Consultant ajouté avec succès',
        buttonText: {
          cancel: 'Terminé'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      this.router.navigate(['/Liste']);
    });
  }

  //Popup de succès lors de l'ajout
  Alert(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
        buttonText: {
          cancel: 'Okay'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/Liste']);
    });
  }


  CancelAdd() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Annulation de l'Ajout",
        buttonText: {
          cancel: 'Terminé'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      this.router.navigate(['/Liste']);
    });
  }




/**
 * Changer le nom du fichier en fonction de la date
 * Tout en calculant la progression de chargement de l'envoi de fichier
 * Changement du nom du fichier en concaténant la date et le nom du fichier
 * Afin d'éviter des collision de nom
 * @param  {File} event fichier chargé par l'utilsateur
 * @return {void}
 */
selectFile(event) {
  this.selectedFiles = event.target.files;

  //Utilisation de la date dans le nom du fichier pour éviter les collisions
  this.filename = this.date+"_"+this.selectedFiles.item(0).name.toString();
}

/**
 * Changer le Cv sur le serveur
 * Tout en calculant la progression de chargement de l'envoi de fichier
 * @param  {void}
 * @return {void}
 */
upload() {
  this.progress.percentage = 0;
  this.currentFileUpload = this.selectedFiles.item(0);

  //Envoi du fichier sur le serveur en suivant la progression
  this.uploadService.pushFileToStorage(this.currentFileUpload,this.date).subscribe(event => {
    if (event.type === HttpEventType.UploadProgress) {

      //Calcul de la progression de chargement
      this.progress.percentage = Math.round(100 * event.loaded / event.total);

    } else if (event instanceof HttpResponse) {
      this.rrs.addRef(this.filename).subscribe(result=>{

      });
    }
  });
  this.selectedFiles = undefined;
}



/**
 * Changer la qualité CV avec l'ordre ainsi que la couleur
 * Chaque qualiteCV à un ordre mettre l'ordre dans une variable ordre
 * Ensuite mettre afficher la couleur qui dans la liste
 * @param  {String} qualite Paramètre unique qui est le texte de la liste déroulante
 * @return {void}
 */
onChangeQualiteCv(qualite) {
  this.red = false;
  this.green = false;
  this.orange = false;
  this.grey = false;
  if (qualite === 'Jouable') {
    this.green = true;
    this.ordre = 1;
  }

  if (qualite === 'Moyen') {
    this.orange = true;
    this.ordre = 3;

  }

  if (qualite === 'Injouable') {
    this.red = true;
    this.ordre =4;

  }

  if (qualite === 'Neutre') {
      this.grey = true;
      this.ordre =2;

  }
}

/**
 * Changer l'ordre en fonction de la disponibilité
 * Chaque disponibilité à un ordre mettre l'ordre dans une variable dispoordre
 * Ensuite mettre le libellé dans une variable dispolibelle
 * les deux variables seront utilisées lors de l'ajout du consultant
 * @param  {String} libelle Paramètre unique qui est le texte de la liste déroulante
 * @return {void}
 */
changeDispo(libelle){
  this.Dispo.map((el)=>{
    if(el.libelle===libelle){
      this.dispoordre=el.ordre
    }else if(libelle===""){
      this.dispoordre=999
    }
  })

  this.dispolibelle = libelle;
}



/**
 * Ajout d'une collaboration lors du choix dans la liste déroulante
 * @param  {String} collaboration Paramètre unique qui est le texte de la liste déroulante
 * @return {void}  Assigne la valeur de la liste déroulante à la variable collaborateurdata
 */
changeCollaborateur(collaboration){
  this.collaborateurdata = collaboration;
}


/**
 * Changement de technologie lors de la modification
 * @param  {String} Techno Paramètre unique qui est le texte de la zone de saisie
 * @return {void} Assigne la valeur tapée par l'utilisateur dans la zone de saisie de technologie technologie
 */

ChangeTEch(Techno){
  this.technologie = Techno;
}
}
