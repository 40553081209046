<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<!-- Extended material form grid -->
<form *ngIf='csdata' [formGroup]="registerForm">
    <div class="alert alert-danger mb-2" *ngIf="consultant.deleted"> Attention, le compte de ce consultant a été supprimé.</div>
    <!-- Grid row -->
    <div class="container-fluid mb-3">
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <div class="col-md-2 form-group">
                        <label for="">Nom <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" formControlName="nom" [(ngModel)]="consultant.nom" class="form-control" id="inputEmail4MD" name="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" required>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="">Prénom <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputEmail4MD" [(ngModel)]="consultant.prenom" formControlName="prenom" name="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" required>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="">Titre <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputPassword4MD" formControlName="titre" [(ngModel)]="consultant.titre" [ngClass]="{ 'is-invalid': submitted && f.titre.errors }" name="titre" required>
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="">TJM</label>
                        <input [ngModelOptions]="{standalone: true}" type="text" class="form-control" [(ngModel)]="consultant.tjm" id="inputPassword4MD" formControlName="tjm" name="tjm">
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="">Disponibilité</label>
                        <select [ngModelOptions]="{standalone: true}" class="browser-default custom-select" (change)="changeDispo($event.target.value)" formControlName="dis" name="dis" [(ngModel)]="consultant.codedispo.libelle">
                          <option  *ngFor="let disponibilitedata of Dispo" [value]="disponibilitedata.libelle">{{disponibilitedata.libelle}}</option>
                        </select>
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="">Qualité CV</label>
                        <select [ngModelOptions]="{standalone: true}" class="browser-default custom-select" [ngStyle]="{'color':changeColor()}"   style="padding: 0" (change)="onChange($event.target.value)" [ngModel]="consultant.qualitecv.libelle" formControlName="quali" name="quali"
                            style='font-family:Arial, FontAwesome;' id="selectquali">
                            <option *ngFor="let item of Qualite" [value]="item.libelle" [style.color]="item.color">&#xf111; &nbsp;{{item.libelle}}</option>
                        </select>
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="inputCVMD">CV <font size="2" face="georgia" color="red">(*)</font> </label><br>
                        <div style="display: flex;border: 1px solid  #dadada;padding: 2px;margin-left: 5px;">
                            <img width="33" height="33" src="../../assets/oeil.png" *ngIf="consultant.cv" style="margin-right:1.5vw ;font-weight: bold;padding: 2px;" (click)="visualiser(consultant.cv[0].nomCV)" target="_blank">
                            <a (click)="showCV(cvmodal)" style="border: 1px solid black;margin-right:1.5vw ;font-weight: bold;padding: 2px;">MàJ</a>
                        </div>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="">Tél Principal<font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputAddressMD" [(ngModel)]="consultant.telephone_principal" formControlName="telp" id="telp" name="telp" [ngClass]="{ 'is-invalid': submitted && f.telp.errors }">
                    </div>

                </div>

                <div class="row">

                    <div class="form-group col-md-2">
                        <label for="inputAddressMD">E-mail <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput mdbValidate type="email" [(ngModel)]="consultant.email" class="form-control" id="inputEmailMD" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" name="email">
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="inputAddressMD">C.P.</label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputPostalMD" [(ngModel)]="consultant.code_postal" formControlName="code" name="code">
                    </div>

                    <div class="col-md-2 form-group" style="margin-left:0px;margin-right: 0px;">
                        <label for="inputVilleMD">Ville</label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" [(ngModel)]="consultant.ville" id="inputAddress2MD" formControlName="ville" name="ville">
                    </div>

                    <div class="col-md-1 form-group " style="width: 110px; ">
                        <label for="inputCollabMD ">Collaborateur</label>
                        <select [ngModelOptions]="{standalone: true}" class="browser-default custom-select " (change)="changeCollaborateur($event.target.value)" [(ngModel)]="consultant.collaborateur" formControlName="collaborateur" [value]="consultant.collaborateur" name="collaborateur "
                            style='font-family:Arial, FontAwesome;'>
                            <option value="En cours ">En cours</option>
                            <option value="Non ">Non</option>
                            <option value="Ancien ">Ancien</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group ">
                        <label for="inputDDAMD ">Date début activité</label><br>
                        <input [ngModelOptions]="{standalone: true}" style="font-size: 1rem;" class="form-control " [(ngModel)]="consultant.dda" [max]="today " formControlName="dda" name="dda" type="date">
                    </div>

                    <div class="col-md-1">
                        <!-- Material input -->
                        <div class="form-group">
                            <label for="inputPassword4MD">Source</label>
                            <select class="browser-default custom-select" [(ngModel)]="consultant.source" [ngClass]="{ 'browser-default custom-selectred ng-valid ng-dirty ng-touched': red, 'browser-default custom-selectgreen ng-valid ng-dirty ng-touched': green, 'browser-default custom-selectgrey ng-valid ng-dirty ng-touched': grey  }"
                                formControlName="source" name="source" style='font-family:Arial, FontAwesome;'>
                            <option value=""></option>
                            <option value="Turnover IT">Turnover IT</option>
                            <option value="LinkedIn" >LinkendIn</option>
                            <option value="Autres jobboards" >Autres Jobboards</option>
                            <option value="Réseau">Réseau</option>
                          </select>
                        </div>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="inputDDMMD ">Date Dernière MAJ</label>
                        <div class="d-flex">
                            <input [ngModelOptions]="{standalone: true}" disabled mdbInput type="text " width="105 " class="form-control " [(ngModel)]="consultant.dateDerniereMaj " [value]="consultant.dateDerniereMaj" id="inputDDMMD ">
                            <div (click)="Forceupdate() " style="font-weight: bold;padding: 2px;cursor: pointer;margin-left: 10px;">
                                <img width="25 " height="20 " src="../../assets/flash.png " alt=" ">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-1 form-group ">
                        <label for="inputAddressMD ">LinkedIn</label>
                        <div style="display: flex;border: 1px solid  #dadada;padding: 2px; ">
                            <div style="margin-right:1.5vw ;font-weight: bold;padding: 2px; ">

                                <img width="25 " height="25 " style="color: blue;margin-left: 0.5vw;cursor: pointer;margin-right: 0.5vw; " *ngIf="linkedinTest(consultant.linkedin) " (click)="openLinkekin(consultant.linkedin) " src="../../assets/linkedin.png" alt=" ">
                            </div>
                            <a (click)="showLinkedin(linkedin); " style="border: 1px solid black;margin-right:1.5vw ;font-weight: bold;padding: 2px; ">MàJ</a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- Grid row -->

    <div class="container-fluid mb-3" *ngIf="!csdata.deleted">
        <a class="btn btn-primary" (click)="addToFavoris()" *ngIf="!favoris.includes(id)">
            <i class="bi bi-suit-heart-fill mr-2 mt-1"></i> Ajouter à mes favoris
        </a>

        <a class="btn btn-danger" (click)="removeFromFavoris()" *ngIf="favoris.includes(id)">
            <i class="bi bi-suit-heart-fill mr-2 mt-1"></i> Retirer des favoris
        </a>
    </div>

    <div class="container-fluid" *ngIf="!csdata.deleted">
        <div class="container-fluid mt-4 mb-5" *ngIf="profile">
            <span style="font-weight: bold; margin-left: 14px;">Compétences déclarées par le consultant</span><br>
            <div class="container-fluid card">
                <div class="card-body">
                    <div *ngFor="let tech of profile.competencestechnos" class="mb-1">
                        Technologie / {{tech.datemaj}} : {{tech.intitule}} / Niveau: {{tech.niveau}} / Pratique: {{tech.dureepratique}} mois / <i class="bi bi-person-fill action-icon mr-1" (click)="openListRefModal(listRefModal, tech)"></i> /
                        <i class="bi bi-eye-fill action-icon mx-1" *ngIf="tech.document!=''" (click)="visualiserDocument(tech.document)"></i> / <a (click)="openLinkekin(tech.lien)" *ngIf="tech.lient!=''">lien</a>
                    </div>
                    <div *ngFor="let act of profile.competencesactivites" class="mb-1">
                        Activité / {{act.datemaj}} : {{act.intitule}} / Niveau: {{act.niveau}} / Pratique: {{act.dureepratique}} mois / <i class="bi bi-person-fill action-icon mr-1" (click)="openListRefModal(listRefModal, act)"></i> /
                        <i class="bi bi-eye-fill action-icon mx-1" *ngIf="act.document!=''" (click)="visualiserDocument(act.document)"></i> / <a (click)="openLinkekin(act.lien)" *ngIf="act.lient!=''">lien</a>
                    </div>
                    <div *ngFor="let sect of profile.competencessecteurs" class="mb-1">
                        Secteur / {{sect.datemaj}} : {{sect.intitule}} / Pratique: {{sect.dureepratique}} mois / <i class="bi bi-person-fill action-icon mr-1" (click)="openListRefModal(listRefModal, sect)"></i> /
                        <i class="bi bi-eye-fill action-icon mx-1" *ngIf="sect.document!=''" (click)="visualiserDocument(sect.document)"></i> / <a (click)="openLinkekin(sect.lien)" *ngIf="sect.lient!=''">lien</a>
                    </div>
                    <div *ngFor="let clt of profile.competencesclients" class="mb-1">
                        Client / {{clt.datemaj}} : {{clt.intitule}} / Pratique: {{clt.dureepratique}} mois / <i class="bi bi-person-fill action-icon mr-1" (click)="openListRefModal(listRefModal, clt)"></i> /
                        <i class="bi bi-eye-fill action-icon mx-1" *ngIf="clt.document!=''" (click)="visualiserDocument(clt.document)"></i> / <a (click)="openLinkekin(clt.lien)" *ngIf="clt.lient!=''">lien</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid mt-4 mb-5" *ngIf="profile">
        <span style="font-weight: bold; margin-left: 14px;">Tests réalisés par le consultant</span><br>
        <div class="container-fluid card">
            <div class="card-body">
                <app-consultant-test [consultantid]="profile.consultantid"></app-consultant-test>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-4 mb-5" *ngIf="profile">
        <span style="font-weight: bold; margin-left: 14px;">Formations faites par le consultant</span><br>
        <div class="container-fluid card">
            <div class="card-body">
                <app-consultant-training [consultantid]="profile.consultantid"></app-consultant-training>
            </div>
        </div>
    </div>

    <div class="container-fluid mb-5">
        <span style="font-weight: bold; margin-left: 14px;">Technologies</span><br>
        <textarea class="form-control" rows="3" (change)="TextAreaVal(TechnoPageUpdate[0],$event) " placeholder="Saisir les technologies du consultant ">{{RenderTechno(TechnoPageUpdate[0])}}</textarea>
    </div>


    <div class="container-fluid mb-5">
        <div class="md-form md-outline ">
            <div style="overflow: auto;font-weight: bold; ">
                <table style="border:0px ">
                    <tr>
                        <th style="text-align: left;padding-left: 14px; ">Observations</th>
                        <th style="width: 10%;text-align: center ">Date</th>
                        <th style="width: 6%; ">Trigramme</th>
                    </tr>
                </table>
            </div>
            <div id="ob " style="max-height: 40vw; overflow: auto; border:1px solid black ">
                <table class="table ">
                    <tbody>
                        <tr *ngFor="let ob of obstab ">
                            <td style="padding: 5px;display: flex;width: 100%;justify-content: space-between;align-items: center; ">

                                <div style="width: 100%;display: flex; ">
                                    <p style="width: 100%;margin: 0;font-size: 18px; ">{{TruncateObs(ob.libelleObs)}}</p>
                                    <button (click)="OpenPopup(ob.libelleObs) " *ngIf="ob.libelleObs.length>=220" style="border: 2px solid black;font-weight: bold;">...</button>
                                </div>
                                <div style="width: auto;margin-left: 25px;display: flex;">
                                    <img (click)="showUpdateObs(ob,updateconv);" style="cursor: pointer;" width="20" height="20" src="../../assets/modify.png" alt="">
                                    <img (click)="deleteobs(ob)" style="cursor: pointer;margin-left: 5px;" width="20" height="20" src="../../assets/delete.png" alt="">
                                </div>
                            </td>
                            <td style="width: 10%;text-align: center;padding: 5px;">{{ob.dateObs}}</td>
                            <td style="width: 6%;text-align: center;padding: 5px;">{{ob.trigrammeObs}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="auth.getRole()!=='Lecture'" class="mt-2 d-flex">
                <button class="btn btn-sm mr-2 px-3" style="background-color: #86CB8C;" (click)="AjoutObservation()"> <i class="fa fa-plus"></i></button>

                <input type="text" id="obs" class="form-control" placeholder="Saisir les observations ..." dbInput formControlName="observation" name="observation">
            </div>
        </div>
    </div>

    <div class="container-fluid mb-3">
        <div class="row">

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3">
                        <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;">Tags Techniques </span><br>
                        <div style="display: flex;">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input [ngModelOptions]="{standalone: true}" class="form-control form-control-sm ml-2" id="regTag" name="regTag" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of TagTechPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer; height: auto;" checked (change)="ChangeTagTech(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of TagTechTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddTagsToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                                <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div class="marginright" style="float: left;">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="size" [ngModelOptions]="{standalone: true}" (change)="ChangerTagsPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivant+1}} sur {{totalPage}}</span>
                                <button class="fleche" (click)="PremiereTagsPage()" [disabled]="suivant===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                  class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                </svg>
              </button>
                                <button class="fleche" (click)="PreviousTagsPage()" [disabled]="suivant===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><i
                  class="fa fa-angle-right"></i>
                </button>
                                <button class="fleche" (click)="FinalTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><svg width="1em" height="1em"
                  viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                </svg>
              </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;">Tags Fonctions </span><br>

                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input class="form-control form-control-sm ml-2" [ngModelOptions]="{standalone: true}" type="text" id="fonction" name="fonction" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">

                                    <table style="border: none;">
                                        <tr *ngFor="let item of FonctionPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeFonction(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of FonctionTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddFonctionToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>


                        <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
                            <div style="float: left;" class="marginright">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeFonction" [ngModelOptions]="{standalone: true}" (change)="ChangerFonctionPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantFonction+1}} sur {{totalPageFonction}}</span>
                                <button class="fleche" (click)="PremiereFonctionPage()" [disabled]="suivantFonction===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                  class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                </svg>
              </button>
                                <button class="fleche" (click)="PreviousFonctionPage()" [disabled]="suivantFonction===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><i
                  class="fa fa-angle-right"></i>
                </button>
                                <button class="fleche" (click)="FinalFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><svg width="1em" height="1em"
                  viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                </svg>
              </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3">
                        <span style="padding-left: 43%;font-weight: bold;">Tags Métiers </span><br>
                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input [ngModelOptions]="{standalone: true}" type="text" class="form-control form-control-sm ml-2" id="metier" name="metier" placeholder="Filtre..."> <br>

                        </div>

                        <div class="row">

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of MetierPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeMetier(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of MetierTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddMetierToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div class="marginright" style="float: left;">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeMetier" [ngModelOptions]="{standalone: true}" (change)="ChangerMetierPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantMetier+1}} sur {{totalPageMetier}}</span>
                                <button class="fleche" (click)="PremiereMetierPage()" [disabled]="suivantMetier===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
          class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
        </svg>
      </button>
                                <button class="fleche" (click)="PreviousMetierPage()" [disabled]="suivantMetier===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><i
          class="fa fa-angle-right"></i>
        </button>
                                <button class="fleche" (click)="FinalMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><svg width="1em" height="1em"
          viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
        </svg>
      </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3" style="margin-bottom: 50px;">
                        <span style="font-weight: bold;text-align: center;padding-left: 47%;">Clients</span><br>
                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px" aria-hidden="true"></i>
                            <input [ngModelOptions]="{standalone: true}" type="text" class="form-control form-control-sm" id="client" name="client" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <div style="margin-top: 7px;">
                                        <input [ngModelOptions]="{standalone: true}" type="text" style="height: 20px;width: 177px; margin-right: 3px;font-size: 14px;" placeholder="Ajouter Client ..." [(ngModel)]="clientUpdate.libelle" [ngModelOptions]="{standalone: true}" #inputs>
                                        <button class="icon" (click)="AddClient()"> <i class="fa fa-plus"></i></button>
                                    </div>
                                    <table style="margin-top: 10px;border: none;">
                                        <tr *ngFor="let item of ClientPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeClient(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of ClientTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddClientToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div style="float: left;" class="marginright">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeClient" [ngModelOptions]="{standalone: true}" (change)="ChangerClientPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantClient+1}} sur {{totalPageClient}}</span>
                                <button class="fleche" (click)="PremiereClientPage()" [disabled]="suivantClient===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
              class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
            </svg>
          </button>
                                <button class="fleche" (click)="PreviousClientPage()" [disabled]="suivantClient===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><i
              class="fa fa-angle-right"></i>
            </button>
                                <button class="fleche" (click)="FinalClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><svg width="1em" height="1em"
              viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
            </svg>
          </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- Grid column -->
    </div>

    <!-- Grid row -->
    <div class="form-row" style="margin-bottom: 2vw;">
        <!-- Grid column -->
        <div class="col-md-12">
            <!--Material textarea-->
            <div class="md-form md-outline">
                <div style="overflow: auto;">
                    <table style="border: 1px solid black;">

                        <tr>
                            <th style="text-align: left;padding-left: 14px; width: 80%;font-weight: bold;">Rappels</th>
                            <th style="text-align: center;font-weight: bold;">Date</th>
                            <th style="text-align: center;font-weight: bold;">Actif</th>
                            <th style="width: 6%;font-weight: bold;">Trigramme</th>
                        </tr>

                        <tr *ngFor="let rp of conrappel">

                            <td style="display: flex;width: 100%;justify-content: space-between;align-items: center;padding: 0;" *ngIf="rp.trigramme == trigramme || roles.indexOf('Gestionnaire')>0 || roles.indexOf('Facturation')>0">

                                <div style="width: 100%;display: flex; ">
                                    <p style="width: 100%;margin: 0;margin-left: 12px;">{{Truncate(rp.libelleRappel)}}</p>
                                    <button (click)="OpenPopup(rp.libelleRappel)" *ngIf="rp.libelleRappel.length>=250" style="border: 4px solid black;font-weight: bold;">...</button>
                                </div>
                                <div style="display: flex;width: 50px;">
                                    <img (click)="showUpdateRappels(rp,updaterappels);" style="cursor: pointer;" width="20" height="20" src="../../assets/modify.png" alt="">
                                    <img (click)="deleterappel(rp)" style="cursor: pointer;margin-left: 5px;" width="20" height="20" src="../../assets/delete.png" alt="">
                                </div>
                            </td>
                            <td style="text-align: center;padding: 0px;" *ngIf="rp.trigramme == trigramme || roles.indexOf('Gestionnaire')>0">{{rp.date}}</td>
                            <td *ngIf="rp.trigramme == trigramme || roles.indexOf('Gestionnaire')>0" id="rpactif" (click)="ChangeAct(rp,rp.numrappel)" style="display: flex;justify-content: center;align-items: center;padding: 0px;">
                                <input [ngModelOptions]="{standalone: true}" *ngIf="auth.getRole()==='Lecture'" type="radio" id="OUI" [checked]="rp.actif==='OUI'">
                                <input [ngModelOptions]="{standalone: true}" *ngIf="auth.getRole()!=='Lecture'" type="radio" id="OUI" [checked]="rp.actif==='OUI'">
                                <label for="OUI" style="margin-left: 0.2vw;margin-right: 0.2vw;">OUI</label>
                                <input [ngModelOptions]="{standalone: true}" *ngIf="auth.getRole()!=='Lecture'" type="radio" class="radio" id="NON" [checked]="rp.actif==='NON'">
                                <input [ngModelOptions]="{standalone: true}" *ngIf="auth.getRole()==='Lecture'" type="radio" class="radio" id="NON" [checked]="rp.actif==='NON'">
                                <label for="NON" style="margin-left: 0.2vw;">NON</label>
                            </td>
                            <td *ngIf="rp.trigramme == trigramme || roles.indexOf('Gestionnaire')>0" style="text-align: center;padding: 0px;">{{rp.trigramme}}</td>
                        </tr>

                    </table>
                </div>

                <div *ngIf="auth.getRole()!=='Lecture'" style="display: flex;margin-bottom: 1px">

                    <button class="btn btn-rounded btn-sm" style="margin-bottom: 1px;margin-left: 5px;background-color: #86CB8C;" (click)="AjouterRappel()"> <i class="fa fa-plus"></i></button>

                    <input [ngModelOptions]="{standalone: true}" mdbInput type="text" style="float: left;width: 83%;border:1px solid black;margin-top: 2px;margin-bottom: 1px;margin-bottom: 1px;margin-right:1px;margin-left:20px;" class="form-control" id="inputObjetMD" placeholder="Saisir Objet du Rappel"
                        formControlName="addresse" id="rappeltext" value="addresse">
                    <input [ngModelOptions]="{standalone: true}" mdbInput type="date" id="dateinput" style="width: 13%;border:1px solid black;margin-top: 2px;margin-bottom: 1px;" class="form-control" placeholder="Sélectionner date" formControlName="addresse2" value="addresse2"
                        [min]="today">

                </div>
            </div>
        </div>
        <!-- Grid column -->
    </div>
    <!-- Grid row -->

    <!-- Grid row -->



    <!-- Grid row -->
    <!-- <div class="form-row">

<div class="col-md-12">

<div class="md-form form-group">

<table mat-table class="full-width-table" matSort [dataSource]="dataSource">


<ng-container matColumnDef="Actions">
  <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Actions</th>
  <td mat-cell *matCellDef="let element">{{element.action}}</td>
</ng-container>


<ng-container matColumnDef="Date">
  <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Date</th>
  <td mat-cell *matCellDef="let element">{{element.date}}</td>
</ng-container>


<ng-container matColumnDef="Trigramme">
  <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Trigramme</th>
  <td mat-cell *matCellDef="let element">{{element.trigramme}}</td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
</table>

</div>
</div>
</div> -->


</form>

<div class="container-fluid mt-5 mb-5" *ngIf='csdata'>
    <div class="w-100 text-center py-1" style="font-size: 18px; background-color: #EFEEEE; font-weight: 600;">
        Suivi des missions du consultant
    </div>
    <div class="table-container table-responsive">
        <table id="element" class="table table-striped table-hover">

            <thead>
                <tr class="table-secondary">

                    <th scope="col" style="width: 130px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 60%" title="date début">
                                <mat-label class="label">Date début</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="datedeb" [(ngModel)]="datedebut">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('datedebut')">
                                <img *ngIf="this.sortBy1 != 'datedebut'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'datedebut' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'datedebut' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" style="width: 130px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 60%" title="date fin">
                                <mat-label class="label">Date fin/Renouv</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="datefin" [(ngModel)]="datefin">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('datefin')">
                                <img *ngIf="this.sortBy1 != 'datefin'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'datefin' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'datefin' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" style="width: 200px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 80%" title="nom client">
                                <mat-label class="label">Nom client</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="nomcl" [(ngModel)]="nomclient">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('client')">
                                <img *ngIf="this.sortBy1 != 'client'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'client' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'client' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="note">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 80%" title="numéro contrat">
                                <mat-label class="label">Numéro contrat</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="numconh" [(ngModel)]="contrath">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('contrat')">
                                <img *ngIf="this.sortBy1 != 'contrat'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'contrat' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'contrat' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                    <th scope="col" style="width: 180px;">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 80%" title="TJM consultant">
                                <mat-label class="label">TJM consultant</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="nomcl" [(ngModel)]="tjmconsultanth">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('tjmconsultant')">
                                <img *ngIf="this.sortBy1 != 'tjmconsultant'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'tjmconsultant' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'tjmconsultant' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                    <th scope="col" class="note" style="width: 180px;">
                        Tarif unités d'astreinte consultant
                    </th>
                    <th scope="col" style="width: 130px;">
                        CRAs
                    </th>
                    <th scope="col" style="width: 180px;">
                        Délai paiement consultant
                    </th>
                    <th scope="col" style="width: 130px;">
                        Element de paiement
                    </th>
                    <th scope="col" style="width: 120px;">
                        Mission
                    </th>
                    <th scope="col" style="width: 500px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 95%" title="description besoin,  notes concernant la mission">
                                <mat-label class="label">Desc. Besoin</mat-label>
                                <input matInput (keyup.enter)="filterMissions()" name="carac" [(ngModel)]="caracteristiques">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('caracteristiques')">
                                <img *ngIf="this.sortBy1 != 'caracteristiques'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'caracteristiques' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'caracteristiques' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                    <th scope="col" style="width: 170px;">
                        <div class="input">
                            Satisfaction contact
                            <div class="sort" (click)="this.setSort('satisfactioncontact')">
                                <img *ngIf="this.sortBy1 != 'satisfactionconsultant'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'satisfactioncontact' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'satisfactioncontact' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                    <th scope="col" style="width: 170px;">
                        <div class="input">
                            Satisfaction consultant
                            <div class="sort" (click)="this.setSort('satisfactionconsultant')">
                                <img *ngIf="this.sortBy1 != 'satisfactionconsultant'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'satisfactionconsultant' && this.sortDirection1 == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy1 === 'satisfactionconsultant' && this.sortDirection1 == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody style="position: relative;">
                <tr *ngFor="let mission of missions; let i = index">
                    <td [innerHTML]="mission.datedebut | highlight:datedebut"></td>
                    <td [innerHTML]="mission.datefin | highlight:datefin"></td>
                    <td [title]="mission.client" [innerHTML]="mission.client | highlight:nomclient"></td>
                    <td [title]="mission.contrat">
                        <span [innerHTML]="mission.contrat | highlight:contrath"></span>
                        <i class="bi bi-flag-fill" *ngIf="mission.datefin < today"></i>
                    </td>
                    <td [innerHTML]="mission.tjmconsultant"></td>
                    <td> {{mission.tarifastreinteconsultant}}</td>
                    <td>
                        <select class="form-select form-select-sm" name="cracon" [(ngModel)]="idcra" style="width: 75%">
                        <option *ngFor="let item of mission.cras" [value]="item.idcra" >{{moisCra[item.mois-1]}}</option>
                      </select>
                        <i class="bi bi-eye-fill ml-2" style="cursor: pointer;" (click)="openCraModal(craModal)"></i>
                    </td>
                    <td> {{ mission.delaipaiementconsultant}} <i class="bi bi-pencil-fill ml-2" (click)="openDelaiModal(mission, delaiModal)"></i></td>
                    <td>
                        <div (click)="openPaiementModal(mission, paiementModal)">
                            <i class="bi bi-receipt mr-1"></i> <i class="bi bi-pencil-fill"></i>
                        </div>
                    </td>
                    <td> <i class="bi bi-eye-fill" style="cursor: pointer;" (click)="downloadFile(mission.missionfile)"></i> </td>
                    <td [title]="mission.caracteristiques" [innerHTML]="mission.caracteristiques | highlight:caracteristiques"></td>
                    <td>
                        <select class="form-select" [ngStyle]="{ color: selectcolor(mission) }" [name]="'satisfaction'+i" [(ngModel)]="mission.satisfactioncontact" (change)="updateSatisfactionContact(mission)" style="width: 40%;">
                          <option value="" style="color: white;"> </option>
                          <option value="1" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="2" style="color: yellow;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="3" style="color: orange;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="4" style="color: blue;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="5" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                        </select>
                        <span class="ml-2">{{mission.datesatcontact}}</span>
                    </td>
                    <td>
                        <select class="form-select" [ngStyle]="{ color: selectcolor1(mission) }" [name]="'satisfactionc'+i" [(ngModel)]="mission.satisfactionconsultant" (change)="updateSatisfactionConsultant(mission)" style="width: 40%;">
                          <option value="" style="color: white;"> </option>
                          <option value="1" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="2" style="color: yellow;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="3" style="color: orange;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="4" style="color: blue;"> <p>&#xf111; &nbsp;</p> </option>
                          <option value="5" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                        </select>
                        <span class="ml-2">{{mission.datesatconsultant}}</span>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

    <hr>

    <div class="row d-flex pb-1 mb-4" *ngIf="pages.length>0">
        <div class="col-md-4 d-flex justify-content-center">

        </div>
        <div class="col-md-4 align-self-center text-center page-info">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" role="button" href="" (click)="setPage(page-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                </li>

                <!-- <li class="nav-item" *ngFor="let p of pages; let i=index">
                    <a class="nav-link" *ngIf="i===page || i===page-1 || i===page+1" [ngClass]="{active: i===page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li> -->
                <div class="mt-2">
                    <input type="number" style="width: 50px; height: 25px; color: red;" [(ngModel)]="currentPage" #pageInput (keyup)="changePage()"> / <span>{{pages.length}}</span>
                </div>


                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(page+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(pages.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                </li>
            </ul>
        </div>
        <div class="col-md-4 d-flex justify-content-center">
            <span class="mt-1">Par page:</span>
            <select class="form-select form-select-sm" id="size-select" name="size" #selectSize [ngModel]="size1" (change)="setSize(+(selectSize.value))">
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="120">120</option>
            </select>
        </div>
    </div>

</div>


<div style="display: flex;justify-content: center;flex-wrap: wrap;" *ngIf='csdata && !csdata.deleted'>

    <button class="btnpadding" *ngIf="isModerateur" type="submit" style="background-color: #BBE2BE; color:black;position:relative; border: 1px solid black;
font-size: 20px;font-weight: bold" (click)="update()">Enregistrer</button>

    <button class="btnpadding" style="background-color: rgb(199, 196, 196); color:black;position:relative;border: 1px solid black;
  margin-left:7%;font-size: 20px;font-weight: bold" (click)="Annuler()">Annuler</button>


    <button class="btnpadding" *ngIf="!isModerateur" type="submit" style="background-color: #BBE2BE; color:grey;position:relative;border: 1px solid black;
font-size: 20px;font-weight: 400">Enregistrer</button>

    <button class="btnpadding" *ngIf="auth.getRole()==='Gestionnaire'" style="background-color: #F7931A; color:black;border: 1px solid black;position:relative;
margin-left:9%;font-size: 20px;font-weight: bold" (click)="SupprimerConsultant()">Supprimer</button>

    <button class="btnpadding" *ngIf="auth.getRole()!=='Gestionnaire'" style="background-color:  #F7931A;  color:grey;position:relative;
margin-left:9%;font-size: 20px;font-weight: 400">Supprimer</button>

</div>

<!-- Extended material form grid -->
<br><br>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="meduim" color="#fff" type="ball-pulse-sync">
    <p style="font-size: 15px; color: white">Patientez...</p>
</ngx-spinner>

<ng-template #updateconv class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier une conversation</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <label for="nom">Titre</label>
                <input type="text" class="form-control" value="{{updateobs.libelleObs}}" [(ngModel)]="updateobs.libelleObs" [ngModelOptions]="{standalone: true}" />
            </div>
            <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateObs()">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelobs()">Cancel</button>
    </div>
</ng-template>

<ng-template #updaterappels class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier un Rappel</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="cancelappel()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <label for="nom">Titre</label>
                <input type="text" class="form-control" value="{{updaterappeldata.libelleRappel}}" [(ngModel)]="updaterappeldata.libelleRappel" [ngModelOptions]="{standalone: true}" />
            </div>
            <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateRapels()">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelrappel()">Cancel</button>
    </div>
</ng-template>

<ng-template #linkedin class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier le lien</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" style="display: block;" [(ngModel)]="consultant.linkedin" name="linkedin">
            </div>
            <button (click)="changeLinkedin()" type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelrappel()">Cancel</button>
    </div>
</ng-template>

<ng-template #cvmodal class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier le fichier</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">


                <input [ngModelOptions]="{standalone: true}" mdbInput class="form-control" id="inputAddressMD" style="display: block;padding: 0;" [ngClass]="{ 'is-invalid': submitted && f.cv.errors }" type="file" (change)="selectFile($event)" formControlName="cv" name="cv"
                    id="chcv">

            </div>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelcv()">Annuler</button>
        <button type="submit" class="btn  btn-sm" style="margin:auto;display:block;width: 100px;background-color: white;border: 1px solid black;" data-dismiss="modal" (click)="cancelobs()">Fermer</button>

    </div>
</ng-template>

<ng-template #paiementModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Eléments de paiement consultant</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #updatePaiementForm="ngForm" (ngSubmit)="updatePaiement()">
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="facturation.paiementsociete">
                <input type="text" class="form-control form-control-sm" name="rc" placeholder="RC" [(ngModel)]="facturation.paiementrc">
                <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="facturation.paiementmf">
                <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA" [(ngModel)]="facturation.paiementtva">
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="missionToUpdate.contratcadre" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="missionToUpdate.contrat" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Informations bancaires</label>
                <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="facturation.paiementtitulaire" required>
                <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="facturation.paiementiban" required>
                <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="facturation.paiementbic" required>
                <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="facturation.paiementdomiciliation" required>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="facturation.paiementadresse"></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mr-4" [disabled]="!updatePaiementForm.valid">Enregistrer</button>
                <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Annuler</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #craModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">CRA consultant</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <div>
            <table class="table table-striped">
                <thead>
                    <tr class="table-secondary">
                        <th>
                            Date
                        </th>
                        <th>
                            Matin
                        </th>
                        <th>
                            Après-midi
                        </th>
                        <th style="max-width: 150px">
                            Unités d'astreinte
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let day of cra.days; let i = index">
                        <td class="text-center">
                            {{day.date | date:'fullDate'}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.matin}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.apresmidi}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.astreinte}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-100 text-center py-2" style=" background-color: #EFEEEE;">
                <div style="font-weight: bold; font-size: 20px;">
                    Total
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">Jours travaillés: <span class="text-info">{{cra.totaltravail}} j</span></div>
                    <div class="col-md-4">Astreintes: <span class="text-info">{{cra.totalastreintes}} Unités</span></div>
                    <div class="col-md-4">CP: <span class="text-info">{{cra.totalcp}} j</span></div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">RTT: <span class="text-info">{{cra.totalrtt}} j</span></div>
                    <div class="col-md-4">CSS: <span class="text-info">{{cra.totalcss}} j</span></div>
                    <div class="col-md-4">Maladie: <span class="text-info">{{cra.totalmaladie}} j</span></div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
        </div>
    </div>
</ng-template>

<ng-template #delaiModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Délai paiement</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <div class="form-group">
            <select type="text" class="form-select w-100" style="height: 35px;" name="delai" [(ngModel)]="missionToUpdate.delaipaiementconsultant">
          <option value="" selected hidden>Délai de paiement</option>
          <option value="30">30</option>
          <option value="45">45</option>
          <option value="60">60</option>
          <option value="90">90</option>
        </select>
        </div>

        <div class="d-flex justify-content-center mt-4">
            <button type="submit" class="btn btn-primary mr-4" (click)="updateDelai()">Enregistrer</button>
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Annuler</button>
        </div>
    </div>
</ng-template>

<ng-template #refModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Nouvelle référence</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #addReferenceForm="ngForm">
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Société</label>
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="reference.societe" disabled>
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nom</label>
                    <input type="text" class="form-control form-control-sm" name="nom" placeholder="Nom" [(ngModel)]="reference.nom" required disabled>
                </div>
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Prénom</label>
                    <input type="text" class="form-control form-control-sm" name="prenom" placeholder="Prénom" [(ngModel)]="reference.prenom" disabled>
                </div>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Fonction</label>
                <input type="text" class="form-control form-control-sm" name="fonction" [(ngModel)]="reference.fonction" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Téléphone</label>
                <input type="text" class="form-control form-control-sm" name="tel" [(ngModel)]="reference.telephone" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Email</label>
                <input type="email" class="form-control form-control-sm" name="email" [(ngModel)]="reference.email" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Adresse</label>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="reference.adresse" disabled></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="button" class="btn btn-secondary" (click)="modal.close('')">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #listRefModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Références</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Fonction</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of references">
                    <td>{{item.nom}}</td>
                    <td>{{item.prenom}}</td>
                    <td>{{item.fonction}}</td>
                    <td><i class="bi bi-eye-fill action-icon" (click)="openReference(refModal, item)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('click close button')">Fermer</button>
        </div>
    </div>
</ng-template>