import { Component, OnInit } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;
  error = null;
  spin = false;
  success = false;


  strongPasswordValidation(control: FormControl): { strong: string } | null {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);

    const valid = hasNumber && hasUpper && hasLower;
    if (!valid) {
      return {
        strong: 'Votre mot de passe doit contenir au moins une majuscule, une minuscule et un nombre.'
      };
    }
    return null;
  }

  confirmPasswordValidation(control: FormControl): { confirmation: string } | null {
    if ((control.value !== '') && (control.root.get('password')?.value !== '') && (control.value !== control.root.get('password')?.value)) {
      return { confirmation: 'Le mot de passe et la confirmation ne correspondent pas.' };
    }

    return null;
  }


  constructor(private fb: FormBuilder, private router: Router, private auth: AuthService) {
    this.form = this.fb.group({
      firstname: ['', [
        Validators.required
      ]],
      lastname: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        this.strongPasswordValidation
      ]],
      confirm: ['', [
        Validators.required,
        this.confirmPasswordValidation
      ]],
      codeParrain: ['']
    });
  }

  ngOnInit(): void {
  }

  register(): void {
    this.spin = true;
    this.error = null;
    this.auth.registerConsultant(this.form.value).subscribe(
      data => this.handleData(data),
      error => this.handleError(error)
    )
  }

  handleData(data: any): void {
    this.spin = false;
    this.success = true;
  }

  handleError(error: any): void {
    this.spin = false;
    if(error.error.message.length <= 80) {
      this.error = error.error.message;
    }
  }

}
