import { ConsultantProfile } from './../models/consultant-profile';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "./../../Model/user";
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChildren,
  QueryList,
  ElementRef,
  TemplateRef,
  AfterContentInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsultantService } from "../../services/consultant.service";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
} from "@angular/forms";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AlertDialogComponent } from "../../alert-dialog/alert-dialog.component";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DisponibiliteService } from "../../services/disponibilite.service";
import { QualiteService } from "../../services/qualite.service";
import { DatePipe } from "@angular/common";
import { TechnologieService } from "../../services/technologie.service";
import { CommunicationService } from "../../services/communication.service";
import { ActionsService } from "../../services/actions.service";
import { Rappels } from "../../Model/rappels";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { Tagtech } from "../../Model/tagtech";
import { Tagmet } from "../../Model/tagmet";
import { Tagfonc } from "../../Model/tagfonc";
import { ClientService } from "../../services/client.service";
import { TagtechService } from "../../services/tagtech.service";
import { TagfoncService } from "../../services/tagfonc.service";
import { TagmetService } from "../../services/tagmet.service";
import { RappelsService } from "../../services/rappels.service";
import * as _ from "lodash";
import { UploadFileService } from "../../upload/upload-file.service";
import { CV } from "../../Model/cv";
import { CvService } from "../../services/cv.service";
import { AlertEmptyComponent } from "../../alert-empty/alert-empty.component";
import { RefRechercheService } from "../../services/ref-recherche.service";
import { Technologie } from "../../Model/technologie";
import { Client } from "../../Model/client";
import { Observation } from "../../Model/observation";
import { DictioService } from "../../services/dictio.service";
import { Dictionnaire } from "../../Model/dictionnaire";
import { MotsDictionnaire } from "../../Model/mots-dictionnaire";
import { TokenStorageService } from "../../services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { ProgressSpinnerDialogComponent } from "../../progress-spinner-dialog/progress-spinner-dialog.component";
import { flattenDiagnosticMessageText } from "typescript";
import { backEndpoint } from "../../config";
import * as fileSaver from "file-saver";
import { AuthService } from "src/app/services/auth.service";
import { PopupdataComponent } from "src/app/popupdata/popupdata.component";
import { ConsultantProfileService } from "src/app/services/consultants/consultant-profile.service";
import { CraService } from 'src/app/services/cra.service';

export interface Element {
  _id: string;
  action: string;
  date: string;
  trigramme: string;
}

const ELEMENT_DATA: Element[] = [];

function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}

@Component({
  selector: 'app-infos-consultant',
  templateUrl: './infos-consultant.component.html',
  styleUrls: ['./infos-consultant.component.scss']
})
export class InfosConsultantComponent implements OnInit {

  clientConsultantData2: { client: any };

  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  addresse = "";
  addresse2 = "";
  linkedindata = "";
  private sub: any;
  titree: string;
  name: string;
  ddm: string;
  ddm2: string;
  consultant: any;
  user: any = [];
  rappel = new Rappels();
  initcv = new CV();
  tabcv: any = [];
  idcv: String;
  filename: string;
  modalRef: BsModalRef;

  isModerateur = false;

  dictio = new Dictionnaire();
  motsDic = new MotsDictionnaire();

  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();

  registerForm: FormGroup;
  bodyText: string;
  submitted = false;

  //Récupération de tous
  Dispo = [];
  Qualite = [];
  Tech = [];
  Clients = [];
  TagTech = [];
  TagMetier = [];
  TagFonc = [];

  //Checkbox
  Techno = [];
  Cli = [];
  Tag1 = [];
  Tag2 = [];
  Tag3 = [];

  trigramme: string = "";
  userid: string = "";
  roles: string[] = [];

  profileid: string = "";

  John = "aaaa";
  nom = "";
  prenom = "";
  oldrappel = new Rappels();
  oldrappel2 = new Rappels();
  titre = "";
  tjm = "";
  dis = "";
  observation = [];
  quali = "";
  telp = "";
  email = "";
  linkedin = "";
  code = "";
  ville = "";
  cv = "";
  dmaj = "";
  green = "red";
  obs = "";
  tech = [];
  client = [];
  tagtech = [];
  tagmetier = [];
  tagfonc = [];
  dda = "";
  exp = "";

  data = Object.assign( ELEMENT_DATA);
  dataSource = new MatTableDataSource(this.data);

  orange = "orange";
  closeResult: string;
  updaterappel;
  myDate = new Date();
  newDate = new Date().getFullYear();
  dateDda = new Date();
  today = new Date().toJSON().split("T")[0];

  expanded1 = false;
  expanded2 = false;
  expanded3 = false;
  expanded4 = false;
  expanded5 = false;
  test: string;

  //CV propriété :
  days = new Date().getDate().toString();
  month = new Date().getMonth().toString();
  year = new Date().getFullYear().toString();
  hours = new Date().getHours().toString();
  minutes = new Date().getMinutes().toString();
  secondes = new Date().getSeconds().toString();

  /*  TagTech Pagination */
  libelle: string = "Ancunes Technologies";
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  tagTechUpdate: Tagtech = new Tagtech();
  totalElements: number = 0;
  tagTechPage: Tagtech[] = [];
  loading: boolean;
  suivant = 0;
  ordre = 2;
  dispoordre = 2;
  size: number = 25;
  totalPage: number;
  regex: string = "";
  TagTechPageUpdate: Tagtech[] = [];
  TagTechFilter: Tagtech[] = [];
  //isChecked: boolean = false;
  forceupdate = false;

  dispolibelle = "";
  csdata: any;

  date: string =
    this.year +
    this.month +
    this.days +
    "_" +
    this.hours +
    this.minutes +
    this.secondes;

  @Input() consultantData : ConsultantProfile = { id: '', userid: '', consultantid: '', nom: '', prenom: '', codedispo:{},
    tjm: '', telephone_principal: '', email: '',  linkedin: '', postal: '',
    ville: '', cv: [], dateDerniereMaj: '', client: [], tagtech: [],
    tagmetier: [], tagfonc: [],dda: '',
    competencestechnos: [],
    competencesactivites: [],
    competencesclients: [],
    competencessecteurs: [],
  };

  competencestechnos: any[] = [];
  competencesactivites: any[] = [];
  competencesclients: any[] = [];
  competencessecteurs: any[] = [];

  file: any;

  facturation: any = {
    "paiementsociete" : "",
    "paiementrc" : "",
    "paiementmf" : "",
    "paiementtva" : "",
    "paiementtitulaire" : "",
    "paiementiban" : "",
    "paiementbic" : "",
    "paiementdomiciliation" : "",
    "paiementadresse" : ""
  }

  constructor(
    private router: Router,
    public cs: ConsultantService,
    public as: ActionsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public ds: DisponibiliteService,
    private titleService: Title,
    private bmService: BsModalService,
    public qs: QualiteService,
    private communicationService: CommunicationService,
    private tokenStorageService: TokenStorageService,
    private datePipe: DatePipe,
    private profileService: ConsultantProfileService,
    private modalService: NgbModal,
    private craService: CraService
  ) {
    this.communicationService.Broadcast(
      "OnChild3",
      "Consultation/Informations"
    );

    this.registerForm = this.formBuilder.group({
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      titre: ["", Validators.required],
      tjm: ["", Validators.pattern("[0-9]+")],
      dis: [""],
      linkedin: [""],
      code: [""],
      ville: [""],
      tech: [""],
      tech2: [""],
      client: [""],
      client2: [""],
      tagtech: [""],
      tagmetier: [""],
      tagfonc: [""],
      addresse: [""],
      addresse2: [""],
      telp: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      cv: [""],
      dda: [""]
    });
  }

  ngOnInit() {
    //Toujours en haut de page
    window.scrollTo(0, 0);

    const user = this.tokenStorageService.getUser();
    this.trigramme = user.trigramme;
    this.userid = user.id;
    this.roles = user.roles;

    //Vider toutes les listes

    this.Qualite = [];
    this.Dispo = [];
    this.Loader();
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);

    this.getDispo();

    this.getConsultantbyUserid(this.userid);
    this.getFacturationInfos();

  }

  getConsultantbyUserid(userid: string) {

    this.profileService.getProfileByUserid(userid).subscribe((data2: {}) => {
      if (data2) {
        this.consultant = data2;
        this.csdata = data2;
        if(!this.consultant.codedispo){
          this.consultant.codedispo = {
            "_id":null,
            "libelle":"ASAP",
            "ordre":1,
            "nbreConsultant":0
          }
        }

        this.titree = this.consultant.titre;
        this.name = this.consultant.nom;
        this.linkedindata = this.consultant.linkedin;

        //Ajout du Nom et du prénom du client dans l'onglet de la page
        this.titleService.setTitle(
          this.name
        );

        //Fermeture de la Popup
        this.dialog.closeAll();
      } else {
        this.openNothingAlert();
      }
    });
  }

  getFacturationInfos(){
    this.craService.getLastMission().subscribe(
      (data) => {
        if(data != null){
          this.facturation.paiementsociete = data.paiementsociete;
          this.facturation.paiementrc = data.paiementrc;
          this.facturation.paiementmf = data.paiementmf;
          this.facturation.paiementtva = data.paiementtva;
          this.facturation.paiementtitulaire = data.paiementtitulaire;
          this.facturation.paiementiban = data.paiementiban;
          this.facturation.paiementbic = data.paiementbic;
          this.facturation.paiementdomiciliation = data.paiementdomiciliation;
          this.facturation.paiementadresse = data.paiementadresse;
        }
      }
    )
  }


  openModal(content){
    this.modalService.open(content, {centered: true});
  }

  //Vérifier que le linkedin est bon avant d'afficher l'icône
  linkedinTest(value) {
    var inValid = /(^(http(s)?)|^(www))[a-zA-Z0-9.?§:!^$&/]+/;
    return inValid.test(value);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  /**
   * Récupérer la liste de toutes les Disponiblités
   * @param  {void}
   * @param  {void}
   * @return {void}
   */
  getDispo() {
    this.Dispo = [];
    this.ds.getRefs().subscribe((data2: {}) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i]);
      }
    });
  }

  cancelcv() {
    this.CancelModal();
    this.ngOnInit();
  }

  openAlertDialog() {
    this.registerForm.patchValue({
      observation: "",
    });

    window.scrollTo(0, 0);

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Enregistrement réussi",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.closeAll();

      this.getConsultantbyUserid(this.userid);
    });
  }

  openNothingAlert() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Désolé ce Consultant n'existe plus",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home"]);
    });
  }

  Annuler() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Abandon des modifications",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home"]);
    });
  }
  fermer() {
    this.router.navigate(["/home"]);
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }



  getTechno(t1) {
    var check = false;
    let tech = new Technologie();
    this.Techno.forEach((item, index) => {
      if (item === t1) {
        check = true;
        this.Techno = arrayRemove(this.Techno, item);
      }
    });
    if (check === false) {
      tech.libelle = t1;
      this.Techno.push(t1);
    }
  }

  getClient(t2) {
    var check = false;
    let cl = new Client();
    this.Cli.forEach((item, index) => {
      if (item === t2) {
        check = true;
        this.Cli = arrayRemove(this.Cli, item);
      }
    });
    if (check === false) {
      cl.libelle = t2;
      this.Cli.push(t2);
    }
  }


  Forceupdate() {
    this.forceupdate = true;
    this.update();
  }

  onFilechange(event: any){
    this.file = event.target.files[0];
    this.uploadFile();
  }

  uploadFile(){
    this.Loader();
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('profileid', this.consultant.id);
    this.profileService.addCv(formData).subscribe(
      (data) => {
        this.consultant.cv = data.cv;
        this.dialog.closeAll();
      },
      error => {
        this.dialog.closeAll();
      }
    )
  }

  deleteFile(){
    this.profileService.deleteCv(this.consultant.cv, this.consultant.id).subscribe(
      (data) => {
        this.consultant.cv = "";
        this.dialog.closeAll();
      },
     (error) => {
      this.dialog.closeAll();
      console.log(error)
     }
    )
  }

  update() {
    window.scrollTo(0, 0);
    this.Loader();

    this.changeDispo(this.registerForm.value.dis);

    this.submitted = true;
    console.log(this.registerForm)
    if (this.registerForm.invalid) {
      this.dialog.closeAll();
      return ;
    }

    var dateDda = new Date(this.registerForm.value.dda);
    var daysDiff = new Date().getFullYear() - dateDda.getFullYear();

    var experience = "";

    if (daysDiff <= 3) {
      experience = "0-3 ans";
    }
    if (daysDiff >= 4 && daysDiff <= 7) {
      experience = "4-7 ans";
    }
    if (daysDiff > 7) {
      experience = ">7 ans";
    }

    if (this.consultant.cv.length > 0) {
      this.consultant.nom = this.registerForm.value.nom.toUpperCase(),
      this.consultant.prenom = this.registerForm.value.prenom.toUpperCase(),
      this.consultant.tjm = this.registerForm.value.tjm,
      this.consultant.telephone_principal = this.registerForm.value.telp,
      this.consultant.email = this.registerForm.value.email,
      this.consultant.linkedin = this.linkedindata,
      this.consultant.postal = this.registerForm.value.code,
      this.consultant.ville = this.registerForm.value.ville,
      this.consultant.dda = this.registerForm.value.dda
        ? this.datePipe.transform(
            this.registerForm.value.dda,
            "yyyy-MM-dd"
          )
        : "",
      this.consultant.codedispo = { libelle: this.dispolibelle, ordre: this.dispoordre },
      this.consultant.dateDerniereMaj = this.consultantData.dateDerniereMaj = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      )

      this.profileService.updateProfile(this.consultant).subscribe(
        (data) => {
          this.consultant = data;
          this.dialog.closeAll();
        },
        error => this.dialog.closeAll()
      );

    } else {
      this.dialog.closeAll();
      this.Alert("Vous devez entrer un fichier CV");
    }

  }

  CancelModal() {
    this.modalRef.hide();
  }

  getColor(param) {
    var doc = param;
    if (doc === "Jouable") {
      document.getElementById("selectquali").style.color = "green";
    }

    if (doc === "Moyen") {
      document.getElementById("selectquali").style.color = "orange";
    }

    if (doc === "Injouable") {
      document.getElementById("selectquali").style.color = "red";
    }

    if (doc === "Neutre") {
      document.getElementById("selectquali").style.color = "grey";
    }
  }

  Changecv() {
    var doc1 = document.getElementById("telcv");
    var doc2 = document.getElementById("chcv");
    if (doc1.style.display === "block") {
      doc1.style.display = "none";
      doc2.style.display = "block";
    } else {
      doc1.style.display = "block";
      doc2.style.display = "none";
    }
  }

  visualiser(fileURL) {
    this.cs.downloadFile(fileURL).subscribe(
      (blob) => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }

  ChangeLinked() {
    var doc1 = document.getElementById("inputLinkedMD");
    var doc2 = document.getElementById("LinkedAcc");
    var doc3 = document.getElementById("link");
    var doc4 = document.getElementById("hide");
    if (doc1.style.display === "block") {
      doc1.style.display = "none";
    } else {
      doc1.style.display = "block";
    }
    if (doc2.style.display === "none") {
      doc2.style.display = "block";
    } else {
      doc2.style.display = "none";
    }
  }

  voircv(cv) {
    if (cv && cv.length !== 0) {
      window.open(backEndpoint + "/downloadFile/" + cv[0].nomCV);
    }
  }

  openAlertWarning(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Champs " + param + " vide",
        buttonText: {
          cancel: "Retour",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getConsultantbyUserid(this.userid);
    });
  }


  openLinkekin(e) {
    if (!e.includes("https") || !e.includes("http")) {
      e = "https://" + e;
    }
    window.open(e, "_blank");
  }

  Loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
       Chargement en Cours`,
      },
      disableClose: true,
    });
  }

  RenderTechno(e) {
    if (e) {
      return e.libelle;
    }
    return "";
  }


  Truncate(text) {
    if (text) {
      return text.substr(0, 125);
    }
  }

  TruncateObs(text) {
    if (text) {
      return text.substr(0, 220);
    }
  }

  OpenPopup(text) {
    const dialogRef = this.dialog.open(PopupdataComponent, {
      data: {
        message: text,
      },
    });
  }

  OpenPopupAlertMode(text) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: text,
      },
    });
  }

  changeDispo(e) {
    this.Dispo.map((el) => {
      if (el.libelle === e) {
        this.dispoordre = el.ordre;
      } else if (e === "") {
        this.dispoordre = this.consultant.codedispo.ordre;
      }
    });

    this.dispolibelle = e;
  }
  showLinkedin(template: TemplateRef<any>) {
    this.modalRef = this.bmService.show(template);
  }

  showCV(template: TemplateRef<any>) {
    this.modalRef = this.bmService.show(template);
  }

  changeLinkedin() {
    this.linkedindata = this.consultant.linkedin;
    this.CancelModal();
  }

  changeCV() {
    this.linkedindata = this.consultant.linkedin;
  }

  CamelCase(e) {
    return e[0] + e.substr(1, e.length);
  }
}

