import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { interval } from 'rxjs';

import { Router } from '@angular/router';
import { RechercheService } from '../services/recherche.service';

@Component({
  selector: 'app-popuploader',
  templateUrl: './popuploader.component.html',
  styleUrls: ['./popuploader.component.scss']
})
export class PopuploaderComponent implements OnInit {


  ngOnInit() {
    for(var i = 0; i < this.input.length; i++){ 
      let poids =[]
      poids.push(this.poids[i])
      let input =[]
      input.push(this.input[i])
      this.rs.RechercheCompetence("",input,poids,this.user).subscribe(result=>{
       
        if(result){ 
          this.counter=this.counter+1
           console.log("tour")
        }
      });
    }
    if(this.counter=this.input.length){
      this.router.navigate(['/rechmcr'], { queryParams: { qualite: this.quali, dispo: this.dispo, experience: this.expert, zone: this.zone,input:this.input}});
       
    } 
  }

  input: any ;
  counter:any=0
  counter1:any=0
  counter2:any=0
  counter3:any=0
  counter4:any=0
  mga:any=0;
  Dispo = [];
  quali: any[] = [];
  dispo: any[] = [];
  expert: any[] = [];
  poids: any[] = [];
  user:any
  zone: String = "";
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private rs: RechercheService,
    private router: Router,
    private dialogRef: MatDialogRef<PopuploaderComponent>) {
    if (data) {
      this.input = data.input || this.input;
      this.quali = data.qualite || this.quali;
      this.input = data.input || this.dispo;
      this.expert = data.experience || this.expert;
      this.zone = data.zone || this.zone;
      this.user = data.trigramme || this.user;
      this.poids = data.poids || this.poids;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    this.dialogRef.updateSize('300vw','300vw')
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  async renderMessage (input){
    if(input.length!==0){
      let msgtemp = 0
      setInterval(() => {
        msgtemp = msgtemp +1
      }, 1000);

    return msgtemp
    }
  }
  renderText(state){
    return state ? "recherche en cours ..." : " recherche terminée"
  }

  getState1(word){
    this.mga = this.mga+1
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
      this.counter=data
        return data;
      }
    })
  }
  getState2(word){
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
      this.counter1=data
        return data;
      }
    })
  }
  getState3(word){
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
      this.counter2=data
        return data;
      }
    })
  }
  getState4(word){
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
      this.counter3=data
        return data;
      }
    })
  }
    getState5(word){
    this.rs.getConsultants(word).subscribe(data=>{
      if(data){
      this.counter4=data
        return data;
      }
    })
  }

}
