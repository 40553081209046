import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Rappels } from '../Model/rappels';
import { backEndpoint } from '../config';

const endpoint =  backEndpoint+'/rappels/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RappelsService {

  constructor(private http: HttpClient) { }
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getRappels(email,
    nom,prenom,trigramme,obs,titre,rappel,date,page,size,
    ordername,
    ordertype,trigrammer): Observable<any> {

      const formdata: FormData = new FormData();
  formdata.append(`name`, nom);
  formdata.append(`prenom`, prenom);
  formdata.append(`date`, date);
  formdata.append(`rappel`, rappel);
  formdata.append(`observation`, obs);
  formdata.append(`trigramme`, trigramme);
  formdata.append(`trigrammer`, trigrammer);
  formdata.append(`titre`, titre);
  formdata.append(`email`, email);
  formdata.append(`page`, page);
  formdata.append(`size`, size);
  formdata.append(`ordername`, ordername);
  formdata.append(`ordertype`, ordertype);
  

    return this.http.post(endpoint + 'public/get',formdata);
  }

  addRappel(rappels): Observable<any> {
    
   
    return this.http.post<any>(endpoint + 'mod/add', JSON.stringify(rappels), httpOptions);
  }

  deleteRappel(id): Observable<any> {
    return this.http.delete(endpoint + 'mod/delete/'+ id, httpOptions);
  }
  updateRappel(id,rappel): Observable<any> {
    return this.http.put(endpoint + `mod/update/${id}`,JSON.stringify(rappel), httpOptions);
  }

  findRappels(id): Observable<any> {
    return this.http.get(endpoint + 'mod/find/'+id);
  }

  findSpRappels(idC, numrappel): Observable<any> {
    return this.http.get(endpoint + `mod/findsp/${idC}/${numrappel}` );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  updateRappelStatut(id): Observable<any> {
    return this.http.put(endpoint + `mod/update/statut/${id}`, httpOptions);
  }
  updateRappeldata(lib,oldlib,nom): Observable<any> {
    return this.http.put(endpoint + `mod/update/data/${lib}/${oldlib}/${nom}`, httpOptions);
  }

  deleterappel(lib,titre,date,idc,num): Observable<any> {
    return this.http.delete(endpoint + `mod/delete/data/${lib}/${titre}/${date}/${idc}/${num}`, httpOptions);
  }
  updateallrapel(id): Observable<any> {
    return this.http.put(endpoint + `mod/update/allstatut/`+id, httpOptions);
  }
}
