<div class="container-fluid my-5">
    <div class="card" style="background-color:#EEF6FC;">
        <div class="card-body">
            <div class="row">
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;text-align: left;">Nom du Client </label>
                    <input id="nom" class="form-control" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [value]="nomClient " disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;">Nom et Prenoms </label>
                    <input class="form-control" id="prenom" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="nomContact" disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;"> Description Besoins </label>
                    <textarea class="form-control" id="desc" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="besoin" disabled name="" cols="30" rows="1"></textarea>
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;text-align: left;"> Statut </label>
                    <input class="form-control" [value]="statut" type="text" [(ngModel)]="statut" disabled>
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;">Date</label>
                    <input class="form-control" type="text" [(ngModel)]="date" disabled>
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;">AO</label>
                    <img (click)="consultAO()" *ngIf="ao!=='non renseigné'&& ao!==''" width="35" src="../../assets//oeil.jpg" style="cursor: pointer;width: 30px;text-align: center;" alt="">
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;"> IdC </label>
                    <input class="form-control" id="indice" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="ic" disabled>
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;">Commercial </label>
                    <input class="form-control" type="text" [(ngModel)]="commercial" disabled>
                </div>
                <div class="col-md-1">
                    <label style="font-weight: bold;margin:0px;font-size: 15px; text-align: left;">Gest. RH </label>
                    <input class="form-control" type="text" [(ngModel)]="gestionnairerh" disabled>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="searchBox">

    <p class="BigText" style="font-weight: bold;">Recherche Consultant dans RH</p>
    <button style="background-color: transparent;" (click)="navigate()">
            <img style="cursor: pointer;margin: 0;" width="70" src="../../assets/recherche-removebg-preview.png" alt="">
        </button>
</div>

<div style="width: 100%;">

    <div class="table-container">
        <div class="entete">Liste des Consultants selectionnés</div>
        <table>
            <thead>
                <tr>
                    <th class="nomPrenom">
                        <div>Nom Prenoms</div>
                    </th>
                    <th class="cv">
                        <div>CV</div>
                    </th>
                    <th class="date">
                        <div>Date envoi CV</div>
                    </th>

                    <th class="retenu">
                        <div>Retenu</div>
                    </th>
                    <th class="observation">
                        <div>Observation</div>
                    </th>

                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let item of CvData;let i = index">
                    <td>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <p [id]="'nomPrenom' + i " (mouseenter)="this.proncate_in($event,item.nomConsultant+' ' +item.prenomConsultant)" (mouseleave)="this.proncate_out($event,true)"> {{item.nomConsultant}} {{item.prenomConsultant}} </p> <img style="cursor: pointer;" (click)="deleteconsultants(item)" width="16px" src="../../assets/delete.png" alt="">

                        </div>
                    </td>
                    <td style="display: flex;justify-content: space-around;align-items: center;">
                        <img style="cursor: pointer;margin-bottom: 6.4px;" (click)="visualiser(item.nomCV)" *ngIf=item.nomCV width="16px" src="../../assets/cv2.png" alt="">
                        <img style="cursor: pointer;margin-bottom: 6.4px;" (click)="Select(item)" *ngIf=item.idConsultant width="16px" src="../../assets/profil.png" alt="">
                        <img style="cursor: pointer;margin-bottom: 6.4px;" width="16px" src="../../assets/trombonne2.png" (click)="uploadAO.click(item._id)" (click)="setid(item)" alt="">
                    </td>
                    <td>
                        <input style="width: 100%;" (change)="updateBesoin(item._id,$event.target.value,'dateEnvoiCV')" type="date" name="" [value]="item.dateEnvoiCV">
                    </td>
                    <td>
                        <select style="width: 100%;" (change)="updateBesoin(item._id,$event.target.value,'iretenu')" [value]="item.iretenu" name="">
                            <option value="null"></option>
                            <option value="OUI">OUI</option>
                            <option value="NON">NON</option>
                            <option value="En Attende de retour">En Attende de retour</option>
                        </select>

                    </td>
                    <td>
                        <input style="border: none;height: 20px;width: 100%;" (change)="updateBesoin(item._id,$event.target.value,'observation')" type="text" name="" [value]="item.observation || ''" placeholder="Votre Observation">
                    </td>
            </tbody>
        </table>

    </div>




    <div style="display: flex;align-items: center; flex-wrap: wrap; justify-content: space-between;padding: 0px 40px;">

        <div style="display: flex;justify-content: space-around;align-items: center;">
            <span style="font-size: smaller;font-weight: bold;">Ajouter <br>Consultant</span>
            <button class="btn btn-rounded btn-sm icon" style="margin-left: 5px;background-color: #86CB8C;" (click)="AddConsultant()"> <i class="fa fa-plus"></i></button>
            <input type="text" style="height: 30px;margin-left: 5px;width: 220px;font-size: smaller;" [(ngModel)]="consultantnamedata">
        </div>
        <!--
        <div style="margin-left: 5px;">
            <img style="cursor: pointer;align-self: center;" width="16px" src="../../assets/trombonne2.png" (click)="uploadAO.click()" (click)="setid(item)" alt="">
        </div>

        <div style="margin-left: 5px;width:100px">
            <input style="width:100% " [(ngModel)]="datedata" type="date" name="">
        </div>

        <div style="width: 80px">
            <select style="width: 100%;margin: 2px;height: 30px;" [(ngModel)]="retenudata" name="">
                <option value="null"></option>
                <option value="OUI">OUI</option>
                <option value="NON">NON</option>
                <option value="En Attende de retour">En Attende de retour</option>
            </select>
        </div>

        <div style="width: 600px;margin: 2px;">
            <input [(ngModel)]="obsdata" type="text" name="" style="width: 100%;" placeholder="Votre Observation">
        </div> -->
    </div>
</div>
<div style="display: flex;
  justify-content: center;margin-top: 20px;margin-left: -5vw;">
    <button class="btn btn-rounded btn-secondary p-3" (click)="Annuler()">Annuler</button>

    <button class="btn btn-rounded btn-success p-3" (click)="valider()">Valider</button>
</div>

<input type="file" (click)="setid(id)" hidden #uploadAO (change)="ChangeCv($event)" enctype="multipart/form-data">
