<div class="container-fluid mb-4">
    <div class="row d-flex justify-content-around">

        <div class="card col-md-5">
            <div class="card-body">
                <div class="text-center mb-1 title">Top 10 mots recherchés</div>
                <div class="row d-flex justify-content-center">
                    <div>
                        Période
                        <select class="form-select" aria-label="Default select example" (change)="setPeriodeRecherche()" [(ngModel)]="periodeRecherche" name="periodeRecherche">
              <option value="semaine" selected>1 Semaine</option>
              <option value="mois">1 Mois</option>
              <option value="semestre">1 Semestre</option>
              <option value="annee">1 Année</option>
            </select>
                    </div>
                </div>

                <div class="mt-3 d-flex justify-content-center">
                    <div>
                        <div class="row mt-1" style="font-size: 18px;" *ngFor="let motCle of top10MotRecherche">
                            <div (click)="setMotRecherche(motCle.motCle)" class="badge badge-secondary mr-2">{{motCle.motCle}}</div>
                            <div class="mr-2" style="color: red; font-weight:bold;">{{motCle.occurence}}</div>
                            <div class="const" *ngIf="motCle.tendance === 'const'"><i class="bi bi-arrow-right"></i></div>
                            <div class="up" *ngIf="motCle.tendance === 'up'"><i class="bi bi-arrow-up-right"></i></div>
                            <div class="down" *ngIf="motCle.tendance === 'down'"><i class="bi bi-arrow-down-right"></i></div>
                        </div>

                        <div class="mt-4 row">
                            <div>Mot-Recherche :</div>
                            <div class="ms-1 col-6">
                                <input type="text" class="form-control" name="mot" [(ngModel)]="motRecherche" #motInput>
                            </div>
                            <div class="ml-3 mr-3 mt-1 row h5" *ngIf="motRecherche !== '' && top10MotRecherche.indexOf(motRecherche)<0">
                                <div class="ms-3 mr-2" style="color: red; font-weight:bold;">{{nbreMot}}</div>
                                <div class="const" *ngIf="tendanceMot === 'const'"><i class="bi bi-arrow-right"></i></div>
                                <div class="up" *ngIf="tendanceMot === 'up'"><i class="bi bi-arrow-up-right"></i></div>
                                <div class="down" *ngIf="tendanceMot === 'down'"><i class="bi bi-arrow-down-right"></i></div>
                            </div>
                            <div>
                                <button class="ml-2 btn btn-primary" (click)="setMotRecherche(motInput.value)">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card col-md-5">
            <div class="card-body">
                <div class="text-center mb-1 title">Adéquation CVs % Recherches</div>
                <div class="row d-flex justify-content-around">
                    <div>
                        Période
                        <select class="form-select" aria-label="Default select example" (change)="setPeriodeAdequation()" [(ngModel)]="periodeAdequation" name="periodeAdequation">
                  <option value="semaine" selected>1 Semaine</option>
                  <option value="mois">1 Mois</option>
                  <option value="semestre">1 Semestre</option>
                  <option value="annee">1 Année</option>
                </select>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-4" #qualiteCvCanvasContainer>
                    <canvas id="adequation" width="350" height="350" #qualiteCvCanvas></canvas>
                </div>

            </div>
        </div>

    </div>
</div>



<ng-template #alertRechercheModal let-modal>
    <div class="modal-body" style="background-color: white;">
        <div class="d-flex flex-row-reverse">
            <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="close">&times;</span>
        </div>
        <div class="text-center h4 mt-2 mb-2">{{alertMessage}}</div>
    </div>
</ng-template>