import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { backEndpoint,CRM_BACK_ENDPOINT, consultantEndpoint } from '../config'

const endpoint =  backEndpoint+'/consultant';
const endpoint2 = CRM_BACK_ENDPOINT+'/consultant';
const endpoint3 = consultantEndpoint+'/api/consultant-profile';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  validate(data:any): Observable<any>{
    return this.http.post(endpoint+"/validate", data);
  }

  addProfileOnCreation(data: any): Observable<any>{
      return this.http.post(endpoint3+"/rh-add", data);
  }

  updateMission(data: any): Observable<any>{
      return this.http.put(endpoint+"/mission/update-mission", data);
  }

  genererFactureClient(data: any){
    return this.http.post(endpoint+'/mission/generer-facture-client', data, {responseType: 'blob'});
  }

  genererFactureConsultant(data: any){
    return this.http.post(endpoint+'/mission/generer-facture-consultant', data, {responseType: 'blob'});
  }

  changeReglementClient(statut: string, idcra: string, idmission: string): Observable<any> {
    return this.http.get(endpoint+"/mission/update-reglementclient", {params: {"statut": statut, "idcra": idcra, "idmission": idmission}});
  }

  changeReglementConsultant(statut: string, idcra: string, idmission: string): Observable<any> {
    return this.http.get(endpoint+"/mission/update-reglementconsultant", {params: {"statut": statut, "idcra": idcra, "idmission": idmission}});
  }

  isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  getMissions(idclient: string, idconsultant: string, datedebut: string, datefin: string, nomclient: string, nomconsultant: string, prenomconsultant: string,
      contrat: string, caracteristique: string, trigrh: string, trigcrm: string, tjmclient: string, tjmconsultant: string, sortby: string, sortdirection: string, page: string, size: string): Observable<any>{

      return this.http.get(endpoint+"/mission/get-mission", {params: {
          "idconsultant": idconsultant,
          "idclient": idclient,
          "datedebut": datedebut,
          "nomconsultant": nomconsultant,
          "prenomconsultant": prenomconsultant,
          "datefin": datefin,
          "client": nomclient,
          "contrat": contrat,
          "caracteristiques": caracteristique,
          "sortby": sortby,
          "sortdirection": sortdirection,
          "trigrh": trigrh,
          "trigcrm": trigcrm,
          "tjmclient": this.isNumeric(tjmclient) ? tjmclient : "0",
          "tjmconsultant": this.isNumeric(tjmconsultant) ? tjmconsultant : "0",
          "page": page,
          "size": size
      }});
  }

  getMissionById(id: string): Observable<any>{
    return this.http.get(endpoint+"/mission/getById", {params: {"id": id}});
  }

  downloadFile(location: string){
    return this.http.get(endpoint+'/downloadFile', {params: {"filepath": location}, responseType: 'blob'});
  }

  getCra(idcra: string){
      return this.http.get(backEndpoint+'/CRA/getById', {params: {"idcra": idcra}});
  }


  selectCV(cv,id): Observable<any> {
    return this.http.post<any>(endpoint2 + '/select/cv/?idbesoin='+id, JSON.stringify(cv), httpOptions);
  }

  orderConsultantsClient(): Observable<any>{
    return this.http.get(endpoint+'/orderclient').pipe(
      map(this.extractData));

  }

  addToFavoris (rhid: string, cvid: string): Observable<any> {
    return this.http.get(endpoint+"/addFavori", {params: {"rhid": rhid, "cvid": cvid}});
  }

  removeFromFavoris(rhid: string, cvid: string): Observable<any> {
    return this.http.get(endpoint+"/removeFavori", {params: {"rhid": rhid, "cvid": cvid}});
  }

  getUserFavoris(rhid: string): Observable<any>{
    return this.http.get(endpoint+"/getRhFavoris", {params: {"rhid": rhid}});
  }


  getConsultants(
    nom,prenom,dispo,qualite,email,postal,titre,tel,client,tech,date,page,size,regex,trigramme,
    ordername,
    ordertype
    ): Observable<any> {

    const formdata: FormData = new FormData();
    formdata.append(`name`, nom);
    formdata.append(`prenom`, prenom);
    formdata.append(`date`, date);
    formdata.append(`dispo`, dispo);
    formdata.append(`qualite`, qualite);
    formdata.append(`email`, email);
    formdata.append(`postal`, postal);
    formdata.append(`titre`, titre);
    formdata.append(`tel`, tel);
    formdata.append(`page`, page);
    formdata.append(`size`, size);
    formdata.append(`client`, client);
    formdata.append(`tech`, tech);
    formdata.append(`ordername`, ordername);
    formdata.append(`ordertype`, ordertype)
    return this.http.post(endpoint +'/public/all',formdata);
  }

  getConsultantsForExportation(
    nom,prenom,dispo,qualite,email,postal,titre,tel,client,tech,date,regex,trigramme,
    exp,
    observation,
    ordername,
    ordertype
    ): Observable<any> {

    const formdata: FormData = new FormData();
    formdata.append(`name`, nom);
    formdata.append(`prenom`, prenom);
    formdata.append(`date`, date);
    formdata.append(`dispo`, dispo);
    formdata.append(`qualite`, qualite);
    formdata.append(`email`, email);
    formdata.append(`postal`, postal);
    formdata.append(`titre`, titre);
    formdata.append(`tel`, tel);
    formdata.append(`client`, client);
    formdata.append(`tech`, tech);
    formdata.append(`exp`, exp);
    formdata.append(`observation`, observation);
    formdata.append(`ordername`, ordername);
    formdata.append(`ordertype`, ordertype)
    return this.http.post(endpoint +'/public/export',formdata);
  }

  Filter(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,size,regex,trigramme): Observable<any> {

    return this.http.get(endpoint +
      '/public/all/?name='+nom+
      "&prenom="+prenom+
      "&dispo="+dispo+
      "&qualite="+qualite+
      "&email="+email+
      "&postal="+postal+
      "&titre="+titre+
      "&tel="+tel+
      "&page="+page+
      "&size="+size+
      "&client="+client

      ).pipe(
      map(this.extractData));
  }
  deletefile(filename) {

      return this.http.delete(endpoint + '/' + filename);

  }
  deleteConsultant(id) {

    return this.http.delete(endpoint + '/admin/delete/' + id);

}

verifyCV(fileName) {

  return this.http.get(endpoint +'/verifyfile/' + fileName);

}

GetAllConsultants(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,size,datamodif,observation,tech,exp,
  ordername,
  ordertype,trigramme){
  const formdata: FormData = new FormData();
  formdata.append(`name`, nom);
  formdata.append(`prenom`, prenom);
  formdata.append(`dispo`, dispo);
  formdata.append(`qualite`, qualite);
  formdata.append(`email`, email);
  formdata.append(`postal`, postal);
  formdata.append(`titre`, titre);
  formdata.append(`tel`, tel);
  formdata.append(`page`, page);
  formdata.append(`size`, size);
  formdata.append(`exp`, exp);
  formdata.append(`datamodif`, datamodif);
  formdata.append(`client`, client);
  formdata.append(`observation`, observation);
  formdata.append(`tech`, tech);
  formdata.append(`ordername`, ordername);
  formdata.append(`ordertype`, ordertype)

  return this.http.post<any>(endpoint + '/public/get/'+trigramme,formdata)

}

GetAllConsultantsByTags(nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,size,datamodif,observation,tech,exp,
  ordername,
  ordertype,trigramme){
  const formdata: FormData = new FormData();
  formdata.append(`name`, nom);
  formdata.append(`tag`, window.localStorage.getItem('tag'));
  formdata.append(`tagtype`, window.localStorage.getItem('tagtype'));
  formdata.append(`prenom`, prenom);
  formdata.append(`dispo`, dispo);
  formdata.append(`qualite`, qualite);
  formdata.append(`email`, email);
  formdata.append(`postal`, postal);
  formdata.append(`titre`, titre);
  formdata.append(`tel`, tel);
  formdata.append(`page`, page);
  formdata.append(`size`, size);
  formdata.append(`exp`, exp);
  formdata.append(`datamodif`, datamodif);
  formdata.append(`client`, client);
  formdata.append(`observation`, observation);
  formdata.append(`tech`, tech);
  formdata.append(`ordername`, ordername);
  formdata.append(`ordertype`, ordertype)

  return this.http.post<any>(endpoint + '/public/consultantsbytag/'+trigramme,formdata)

}
  getLimitConsultants(
    nom,prenom,dispo,qualite,email,postal,titre,tel,client,page,size,datamodif,observation,tech,exp,
    ordername,
    ordertype,trigramme
    ): Observable<any> {
      const formdata: FormData = new FormData();
      formdata.append(`name`, nom);
      formdata.append(`prenom`, prenom);
      formdata.append(`dispo`, dispo);
      formdata.append(`qualite`, qualite);
      formdata.append(`email`, email);
      formdata.append(`postal`, postal);
      formdata.append(`titre`, titre);
      formdata.append(`tel`, tel);
      formdata.append(`page`, page);
      formdata.append(`size`, size);
      formdata.append(`exp`, exp);
      formdata.append(`datamodif`, datamodif);
      formdata.append(`client`, client);
      formdata.append(`observation`, observation);
      formdata.append(`tech`, tech);
      formdata.append(`ordername`, ordername);
      formdata.append(`ordertype`, ordertype)

    return this.http.post<any>(endpoint + '/public/get/'+trigramme,formdata)
  }

  findConsultantbyId(id): Observable<any> {
    return this.http.get<any>(endpoint + '/public/findbyid/' + id, httpOptions);
  }

  findConsultantbyEmail(email): Observable<any> {
    return this.http.get<any>(endpoint + '/public/findbyemail/' + email, httpOptions);
  }

  findConsultantbyProp(nom,prenom,email): Observable<any> {
    return this.http.get<any>(endpoint + `/public/findbyprop/${nom}/${prenom}/${email}`, httpOptions);
  }

  addConsultant(consultant, trigramme: string): Observable<any> {
    return this.http.post<any>(endpoint + '/mod/add/'+trigramme, JSON.stringify(consultant), httpOptions);
  }

  updateConsultant(id, consultant, trigramme: string): Observable<any> {
    return this.http.put(endpoint + '/mod/update/' + id+"?trigramme="+trigramme, JSON.stringify(consultant), httpOptions);
  }

  FindByQuery(ttech,tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();
    ttech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagMech`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagFech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + '/public/rechtag56',{params});
  }
  FindByTagtech(tagTech): Observable<any>{
    let params = new HttpParams();
    tagTech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagtech',{params});
  }

  FindByTagmet(tagMet): Observable<any>{
    let params = new HttpParams();
    tagMet.forEach((techn: string) => {
      params = params.append(`tagMet`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagmet',{params});
  }

  FindByTagfonc(tagFonc): Observable<any>{
    let params = new HttpParams();
    tagFonc.forEach((techn: string) => {
      params = params.append(`tagFonc`, techn);
    });
    return this.http.get(endpoint + '/public/rechtagfonc',{params});
  }

  FindByTags(tagTech,tagMet,tagFonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagFonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagMet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagTech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });

    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtags`, {params});
  }

  FindByQuali(quali): Observable<any>{
    let params = new HttpParams();
    quali.forEach((techn: any) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + '/public/rechquali',  {params});
  }

  FindByDispo(dis): Observable<any>{
    let params = new HttpParams();
    dis.forEach((techn: any) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + '/public/rechdispo', {params});
  }

  FindByExp(expert): Observable<any>{
    let params = new HttpParams();
    expert.forEach((techn: any) => {
      params = params.append(`expert`, techn);
    });
    return this.http.get(endpoint + `/public/rechexp`, {params});
  }

  FindByPostal(post): Observable<any>{
    let params = new HttpParams();
    post.forEach((techn: any) => {
      params = params.append(`postal`, techn);
    });
    return this.http.get(endpoint + `/public/rechpost`, {params});
  }

  FindByQuery1(tagtech,tagmet): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag1`, {params});
  }

  FindByQuery2(tagtech,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag2`, {params});
  }

  FindByQuery3(tagmet,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag3`, {params});
  }

  FindByQuery4(tagtech,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    //let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag4`, {params});
  }

  FindByQuery5(tagtech,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + '/public/rechtag5', {params});
  }

  FindByQuery6(tagmet,qualite): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag6`, {params});
  }

  FindByQuery7(tagmet,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag7`, {params});
  }

  FindByQuery8(tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag8`, {params});
  }

  FindByQuery9(tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag9`, {params});
  }

  FindByQuery10(qualite,dispo): Observable<any>{
    let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });

    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag10`, {params});
  }

  FindByQuery11(tagtech,tagmet,tagfonc): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag11`, {params});
  }

  FindByQuery12(tagtech,tagmet,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag12`, {params});
  }

  FindByQuery13(tagtech,tagmet,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag13`, {params});
  }

  FindByQuery14(tagtech,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag14`, {params});
  }

  FindByQuery15(tagtech,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag15`, {params});
  }

  FindByQuery17(tagmet,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag17`, {params});
  }

  FindByQuery18(tagmet,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag18`, {params});
  }

  FindByQuery19(tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag19`, {params});
  }

  FindByQuery20(tagtech,tagmet,tagfonc,qualite): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag20`, {params});
  }

  FindByQuery21(tagtech,tagmet,tagfonc,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag21`, {params});
  }

  FindByQuery22(tagmet,tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag22`, {params});
  }

  FindByQuery23(tagtech,tagfonc,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag23`, {params});
  }

  FindByQuery24(tagtech,tagmet,qualite,dispo): Observable<any>{
    let params = new HttpParams();
    tagmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });

    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dispo`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag24`, {params});
  }

  FindByQuery29(tagtech,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag29`, {params});
  }

  FindByQuery30(tmet,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag30`, {params});
  }

  FindByQuery31(tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag31`, {params});
  }

  FindByQuery32(qualite,postal): Observable<any>{
    let params = new HttpParams();
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    //let params = new HttpParams();
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag32`, {params});
  }

  FindByQuery33(dispo,postal): Observable<any>{
    let params = new HttpParams();
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    //let params = new HttpParams();
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag33`, {params});
  }

  FindByQuery34(tagtech,tmet,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag34`, {params});
  }

  FindByQuery35(tagtech,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag35`,{params});
  }

  FindByQuery36(tagtech,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag36`, {params});
  }

  FindByQuery37(tagtech,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag37`, {params});
  }

  FindByQuery38(tmet,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag38`, {params});
  }

  FindByQuery39(tmet,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag39`, {params});
  }

  FindByQuery40(tmet,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag40`, {params});
  }

  FindByQuery41(tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag41`, {params});
  }

  FindByQuery42(tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag42`, {params});
  }

  FindByQuery43(tagtech,tmet,tfonc,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag43`, {params});
  }

  FindByQuery44(tagtech,tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagTech`, techn);
    });

    tfonc.forEach((techn: string) => {
      params = params.append(`tagFech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag44`, {params});
  }

  FindByQuery45(tagtech,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag45`, {params});
  }

  FindByQuery46(tagtech,tmet,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag46`, {params});
  }

  FindByQuery47(tagtech,tmet,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag47`, {params});
  }

  FindByQuery48(tmet,tfonc,qualite,postal): Observable<any>{
    let headers =  new HttpHeaders({
      'Content-Type':  'application/json'
    })
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag48`, {params});
  }

  FindByQuery49(tmet,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag49`, {params});
  }

  FindByQuery50(tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag50`, {params});
  }

  FindByQuery51(tagtech,tmet,tfonc,qualite,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });

    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag51`, {params});
  }

  FindByQuery52(tagtech,tmet,tfonc,dispo,postal): Observable<any>{
    let params = new HttpParams();
    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });

    return this.http.get(endpoint + `/public/rechtag52`, {params});
  }

  FindByQuery53(tagtech,tmet,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag53`, {params});
  }

  FindByQuery54(tagtech,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tagtech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag54`, {params});
  }

  FindByQuery55(tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag55`, {params});
  }

  FindByQuery56(ttech,tmet,tfonc,qualite,dispo,postal): Observable<any>{
    let params = new HttpParams();

    tfonc.forEach((techn: string) => {
      params = params.append(`tagfonc`, techn);
    });
    tmet.forEach((techn: string) => {
      params = params.append(`tagmet`, techn);
    });
    ttech.forEach((techn: string) => {
      params = params.append(`tagtech`, techn);
    });
    postal.forEach((techn: string) => {
      params = params.append(`zone`, techn);
    });
    dispo.forEach((techn: string) => {
      params = params.append(`dis`, techn);
    });
    qualite.forEach((techn: string) => {
      params = params.append(`qu`, techn);
    });
    return this.http.get(endpoint + `/public/rechtag56`, {params});
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  Consultants(request) {
    //let url = 'http://localhost:8080/tags';
    const params = request;
    return this.http.get(endpoint, { params });
  }
}
