import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { Chart } from 'chart.js';
import { backEndpoint } from '../../config'


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json'
  })
};

const API_URL = backEndpoint+"/api/drh/"

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit, AfterViewInit {

  constructor(private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.http.get(API_URL+"selections", httpOptions).subscribe(
      (data: any) => {
        this.trigrammes = data.trigrammes;
      }
    )

    this.consulChart = new Chart("consultantChart", {
      type: 'doughnut',
      data: {
          labels: [
            'En cours',
            'En cours nvx CVs',
            'En cours nvlles missions',
            'Anciens',
            'Jamais missionnés'
          ],
          datasets: [{
            label: 'My First Dataset',
            data: [300, 50, 100, 29, 89],
            backgroundColor: [
              'rgb(3, 153, 26)',
              'rgb(48, 44, 102)',
              'rgb(252, 186, 3)',
              'rgb(252, 3, 3)',
              'gray'
            ],
          }]
        },
      options: {
          cutoutPercentage: 60,
          responsive: false,
          legend: {
              position: 'right',
               
              labels: {
                  boxWidth: 20,
                  padding: 10
              }
          }
      }
    });
    
  }

  consulChart: any;
  suiviMissions: any;
  trigrammes = []

  @ViewChild('consultantMissionne') consultantMissioneCanvas!: ElementRef;
  @ViewChild('consultantMissionneContainer') consultantMissioneContainer!: ElementRef;

  consultantMissionneData = [
    {
      trigramme: "ABE",
      enCours: 20,
      enCoursNvxCv: 30,
      enCoursNvlMission: 40,
      ancien: 12,
      jamais: 10
    },
    {
      trigramme: "GFG",
      enCours: 20,
      enCoursNvxCv: 10,
      enCoursNvlMission: 60,
      ancien: 32,
      jamais: 23
    },
  ]

  suiviMissionsData = [
    {
      trigramme: "ABE",
      enCoursSatisfaisant : [
        {
          nombre: 21,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 10,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 18,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 14,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 22,
          date: new Date('10.12.2020'),
        }
      ],
      enCoursMoyen : [
        {
          nombre: 10,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 15,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 17,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 25,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 20,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 18,
          date: new Date('10.12.2020'),
        }
      ],
      enCoursInsatisfaisant : [
        {
          nombre: 19,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 26,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 17,
          date: new Date('10.12.2020'),
        }
      ],
      termineSatisfaisant : [
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 18,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 10,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 24,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 13,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 20,
          date: new Date('10.12.2020'),
        }
      ],
      termineMoyen : [
        {
          nombre: 10,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 15,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 17,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 25,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 20,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 18,
          date: new Date('10.12.2020'),
        }
      ],
      termineInsatisfaisant : [
        {
          nombre: 19,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 26,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 17,
          date: new Date('10.12.2020'),
        }
      ],
    },
    {
      trigramme: "GFG",
      enCoursSatisfaisant : [
        {
          nombre: 21,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 32,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 25,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 14,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 30,
          date: new Date('10.12.2020'),
        }
      ],
      enCoursMoyen : [
        {
          nombre: 10,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 24,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 15,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 25,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 26,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 8,
          date: new Date('10.12.2020'),
        }
      ],
      enCoursInsatisfaisant : [
        {
          nombre: 32,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 14,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 3,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 8,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 20,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        }
      ],
      termineSatisfaisant : [
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 34,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 32,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 23,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 12,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 29,
          date: new Date('10.12.2020'),
        }
      ],
      termineMoyen : [
        {
          nombre: 32,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 13,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 15,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 23,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 13,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 21,
          date: new Date('10.12.2020'),
        }
      ],
      termineInsatisfaisant : [
        {
          nombre: 21,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 28,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 23,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 13,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 32,
          date: new Date('10.12.2020'),
        },
        {
          nombre: 16,
          date: new Date('10.12.2020'),
        }
      ],
    }
  ]

  periodeConsMis = "semaine";
  trigConsMis = "Tous";
  consTagMet : any;
  consTagFonc : any;
  consTagTech : any;

  dataConsulMissionne: number[] = []

  setPeriodeConsMis() : void {
    if (this.trigConsMis === "ABE") {

      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [8, 13, 10, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [100, 47, 62, 29, 44],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [122, 50, 100, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

    } else {
      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [20, 13, 20, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [122, 50, 60, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [300, 50, 100, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }
    }
  }

  setTrigConsMis() : void {

    this.consultantMissionneData.forEach(element => {

      this.dataConsulMissionne = []

      if (element.trigramme === this.trigConsMis) {
        this.dataConsulMissionne.push(element.enCours)
        this.dataConsulMissionne.push(element.enCoursNvxCv)
        this.dataConsulMissionne.push(element.enCoursNvlMission)
        this.dataConsulMissionne.push(element.ancien)
        this.dataConsulMissionne.push(element.jamais)

        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: this.dataConsulMissionne,
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }
    });
  }

  setConsTagMet() : void {
    if (this.trigConsMis === "ABE") {

      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [8, 13, 10, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [60, 47, 62, 29, 44],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [90, 50, 100, 29, 70],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

    } else {
      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [25, 13, 20, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [111, 50, 60, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [233, 50, 100, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }
    }
  }

  setConsTagFonc() : void {
    if (this.trigConsMis === "ABE") {

      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [8, 13, 10, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [60, 47, 62, 29, 44],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [90, 50, 100, 29, 70],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

    } else {
      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [25, 13, 20, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [111, 50, 60, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [233, 50, 100, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }
    }
  }

  setConsTagTech() : void {
    if (this.trigConsMis === "ABE") {

      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [8, 13, 10, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [60, 47, 62, 29, 44],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [90, 50, 100, 29, 70],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

    } else {
      if (this.periodeConsMis === "semaine") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [25, 13, 20, 14, 11],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "mois") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [111, 50, 60, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }

      if (this.periodeConsMis === "semestre" || this.periodeConsMis === "annee") {
        this.consultantMissioneCanvas.nativeElement.remove();
        this.consultantMissioneContainer.nativeElement.innerHTML = `<canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>`

        this.consulChart = new Chart("consultantChart", {
          type: 'doughnut',
          data: {
              labels: [
                'En cours',
                'En cours nvx CVs',
                'En cours nvlles missions',
                'Anciens',
                'Jamais missionnés'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [233, 50, 100, 29, 89],
                backgroundColor: [
                  'rgb(3, 153, 26)',
                  'rgb(48, 44, 102)',
                  'rgb(252, 186, 3)',
                  'rgb(252, 3, 3)',
                  'gray'
                ],
              }]
            },
          options: {
              cutoutPercentage: 60,
              responsive: false,
              legend: {
                  position: 'right',
                   
                  labels: {
                      boxWidth: 20,
                      padding: 10
                  }
              }
          }
        });
      }
    }
  }


  periodeSuiviMis = "semaine";
  trigSuiviMis = "Tous";
  sectSuiviMis : any;
  fonctSuiviMis : any;
  techSuiviMis : any;

  setPeriodeSuiviMis(): void {

    this.uncheckAll("controlSuiviMissions");

    if (this.trigSuiviMis === "ABE") {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    } else {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    }

  }

  setTrigSuiviMis(): void {
    this.suiviMissionsData.forEach(element => {

      if (element.trigramme === this.trigSuiviMis) {

        let dataEnCoursSatis: number[] = []
        let dataEnCoursMoyen: number[] = []
        let dataEnCoursInsatis: number[] = []

        let dataTermSatis: number[] = []
        let dataTermMoyen: number[] = []
        let dataTermInsatis: number[] = []

        element.enCoursSatisfaisant.forEach(elt => {
          dataEnCoursSatis.push(elt.nombre)
        });
        element.enCoursInsatisfaisant.forEach(elt => {
          dataEnCoursInsatis.push(elt.nombre)
        });
        element.enCoursMoyen.forEach(elt => {
          dataEnCoursMoyen.push(elt.nombre)
        });

        element.termineSatisfaisant.forEach(elt => {
          dataTermSatis.push(elt.nombre)
        });
        element.termineInsatisfaisant.forEach(elt => {
          dataTermInsatis.push(elt.nombre)
        });
        element.termineMoyen.forEach(elt => {
          dataTermMoyen.push(elt.nombre)
        });

        this.uncheckAll("controlSuiviMissions");

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data: dataEnCoursSatis
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: dataEnCoursMoyen
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: dataEnCoursInsatis
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: dataTermSatis
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: dataTermMoyen
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: dataTermInsatis
            }
          }
        ]

        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    });
  }

  setSectSuiviMis(): void {
    this.uncheckAll("controlSuiviMissions");
    if (this.trigSuiviMis === "ABE") {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    } else {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    }
  }

  setFonctSuiviMis(): void {
    this.uncheckAll("controlSuiviMissions");
    if (this.trigSuiviMis === "ABE") {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    } else {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9,12,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9,5,10]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9,7,5]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9,5,10]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,8,10,13,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [5,8,10,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [10,3,4,7,9]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [5,8,10,7,9]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[15,8,10,7,19, 20,15,8,10,7,19, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [5,18,10,13,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [10,13,10,17,9, 20, 5,18,10,13,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [18,3,4,17,9, 20, 18,3,4,17,9, 20]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [15,18,10,7,19, 20, 18,3,4,17,9, 20]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    }
  }

  setTechSuiviMis(): void {
    this.uncheckAll("controlSuiviMissions");
    if (this.trigSuiviMis === "ABE") {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    } else {
      if (this.periodeSuiviMis === "semaine") {

        let dates: string[] = []
        for (let i = 7; i > 0; i--) {
          dates.push(this.datePipe.transform(new Date(Date.now()-i*86400000), 'dd-MM-yyyy')!);
        }

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: dates
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "mois") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_satisfaisant',
              type: 'spline',
              color: 'turquoise',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "semestre") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
      if (this.periodeSuiviMis === "annee") {

        this.series = [
          {
            display:false,
            index:0,
            serie: {
              name: 'satisfaisant',
              type: 'spline',
              color:'green',
              data:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'moyen',
              type: 'spline',
              color: 'orange',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'insatisfaisant',
              type: 'spline',
              color: 'red',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_moyen',
              type: 'spline',
              color: 'blue',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 't_insatisfaisant',
              type: 'spline',
              color: 'gray',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          }
        ]
  
        this.suiviMissions = Highcharts.chart('suiviMissions', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['mois 1', 'mois 2', 'mois 3', 'mois 4', 'mois 5', 'mois 6', 'mois 7', 'mois 8', 'mois 9', 'mois 10', 'mois 11', 'mois 12']
          },
          yAxis: {
            title: {
              text: 'Nombre'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },
          
          series: []
        })
      }
    }
  }


  series = [
    {
      display:false,
      index:0,
      serie: {
        name: 'satisfaisant',
        type: 'spline',
        color:'green',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'moyen',
        type: 'spline',
        color: 'orange',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'insatisfaisant',
        type: 'spline',
        color: 'red',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 't_satisfaisant',
        type: 'spline',
        color: 'turquoise',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 't_moyen',
        type: 'spline',
        color: 'blue',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 't_insatisfaisant',
        type: 'spline',
        color: 'gray',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  ]

  uncheckAll(divid: string): void {
    let checks: any;
    checks = document.querySelectorAll('#' + divid + ' input[type="checkbox"]');
    for(var i =0; i< checks.length;i++){
        var check = checks[i];
        if(!check.disabled){
            check.checked = false;
        }
    }
  }

  findSerieByName(name: String): number{
    let i: number = 0;
    this.series.forEach(element => {
      if (element.serie.name === name) {
        i = this.series.indexOf(element)
      }
    });
    return i;
  }

  getMax(){
    let max = 0;
    this.series.forEach(element => {
      if (element.index > max) {
        max = element.index
      }
    });
    return max;
  }

  updateSeriesList(i: number){
    this.series.forEach(element => {
      if (element.index > i) {
        element.index = element.index - 1
      }
    });
  }

  addSerie(name: any){
    let index = this.findSerieByName(name)
    if (this.series[index].display === false) {
      this.series[index].display = !this.series[index].display
      this.suiviMissions.addSeries({
        name: this.series[index].serie.name,
        type: 'spline',
        color: this.series[index].serie.color,
        data: this.series[index].serie.data,
      }, true, true);
      
      this.series[index].index = this.getMax() + 1;

      console.log(this.series)
      
    }else{
      let i = this.series[index].index
      this.suiviMissions.series[i-1].remove()
      this.updateSeriesList(i)
      this.series[index].index = 0;
      this.series[index].display = !this.series[index].display
    }
    
  }

  ngAfterViewInit(): void {
    this.suiviMissions = Highcharts.chart('suiviMissions', {
      chart: {
        type: 'spline'
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: {
          text: 'Nombre'
        }
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 3,
            lineWidth: 1
          }
        }
      },
      
      series: []
    })
  }


}
