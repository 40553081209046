<p style="text-align: center; font-size: 20px">Logo RH</p>
<hr style="border: 1px solid black">

<mat-dialog-content>
    <aside *ngIf="input[0]">
        <p *ngIf="counter>=1" style="font-size: 20px"> {{input[0]}}: recherche en cours</p>
        <p *ngIf="counter>=1" style="font-size: 20px"> {{input[0]}}: recherche terminée</p>
       
    </aside>
    <aside *ngIf="input[1]">

        <p *ngIf="counter>=2" style="font-size: 20px"> {{input[1]}}: recherche en cours</p>
        <p *ngIf="counter>=2" style="font-size: 20px"> {{input[1]}}: recherche terminée</p>
    </aside>
    <aside *ngIf="input[2]">

        <p *ngIf="counter>=3" style="font-size: 20px"> {{input[2]}}: recherche en cours</p>
        <p *ngIf="counter>=3" style="font-size: 20px"> {{input[2]}}: recherche terminée</p>
    </aside>
    <aside *ngIf="input[3]">

        <p *ngIf="counter>=4" style="font-size: 20px"> {{input[3]}}: recherche en cours</p>
        <p *ngIf="counter>=4" style="font-size: 20px"> {{input[3]}}: recherche terminée</p>
    </aside>
    <aside *ngIf="input[4]">

        <p *ngIf="counter>=5" style="font-size: 20px"> {{input[4]}}: recherche en cours</p>
        <p *ngIf="counter>=5" style="font-size: 20px"> {{input[4]}}: recherche terminée</p>
    </aside>


</mat-dialog-content>
<mat-dialog-actions align="center">
<button mdbBtn style="background-color: #BBE2BE; color:black;position:relative;font-size: 15px;font-weight: bold" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
