<div class="container-fluid" style="height: 98vh;">

    <div class="row">

        <div class="col-md-3 p-3">
            <p class="font-weight-bold" style="font-size: 20px;">Techniques</p>
            <div class="card">
                <div class="card-body" style="height: 70vh; overflow-y: scroll;">
                    <div *ngFor="let tech of TagTechnique">
                        <div style="margin: 0;padding: 0;display: flex;justify-content: space-between;align-items: center;">
                            <div><input style="padding: 0;margin: 0;" type="checkbox" (change)="RechTagTech(tech.libelle,$event.target.checked)"> &nbsp;{{ tech.libelle }}</div>
                            <p style="display: inline;margin-left: 20px;margin-bottom: 0;">{{ tech.nbreConsultant }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 p-3">
            <p class="font-weight-bold" style="font-size: 20px;">Métiers</p>
            <div class="card mb-5">
                <div class="card-body" style="height: 30vh; overflow-y: scroll;">
                    <div>
                        <div *ngFor="let met of TagMetier">
                            <div style="margin: 0;padding: 0;display: flex;justify-content: space-between;align-items: center;">
                                <div><input style="padding: 0;margin: 0;" type="checkbox" (change)="RechTagMet(met.libelle,$event.target.checked)"> &nbsp;{{ met.libelle }}</div>
                                <p style="display: inline;margin-left: 20px;margin-bottom: 0;">{{ met.nbreConsultant }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p class="font-weight-bold" style="font-size: 20px;">Fonctions</p>
            <div class="card">
                <div class="card-body" style="height: 30vh; overflow-y: scroll;">
                    <div *ngFor="let fonc of TagFonction">
                        <div style="margin: 0;padding: 0;display: flex;justify-content: space-between;align-items: center;">
                            <div><input style="padding: 0;margin: 0;" type="checkbox" (change)="RechTagFonc(fonc.libelle,$event.target.checked)"> &nbsp;{{ fonc.libelle }}</div>
                            <p style="display: inline;margin-left: 20px;margin-bottom: 0;">{{ fonc.nbreConsultant }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 p-3">

            <p class="font-weight-bold" style="font-size: 20px;">Zone géographique</p>
            <div class="card mb-2">
                <div class="card-body">
                    <div>

                        <input type="checkbox" (click)="selectCheckBox(check_box_type.TTZ,1,'Toutes les zones')" [checked]="currentlyChecked === check_box_type.TTZ"> &nbsp;Toutes les zones

                    </div>
                    <div>
                        <input type="checkbox" (click)="selectCheckBox(check_box_type.ZIDF,2,'Zone IDF')" [checked]="currentlyChecked === check_box_type.ZIDF"> &nbsp;Zone IDF
                    </div>
                    <div>
                        <input type="checkbox" (click)="selectCheckBox(check_box_type.ZIDFR,3,'Zone IDF ou non renseigné')" [checked]="currentlyChecked === check_box_type.ZIDFR"> &nbsp;Zone IDF ou non renseigné
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div>
                        <input type="checkbox" [checked]="codes.includes(75)===true" (click)="RechCodePostal(75,$event.target.checked)"> &nbsp;75
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(77)" (click)="RechCodePostal(77,$event.target.checked)"> &nbsp;77
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(78)" (click)="RechCodePostal(78,$event.target.checked)"> &nbsp;78
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(91)" (click)="RechCodePostal(91,$event.target.checked)"> &nbsp;91
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(92)" (click)="RechCodePostal(92,$event.target.checked)"> &nbsp;92
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(93)" (click)="RechCodePostal(93,$event.target.checked)"> &nbsp;93
                    </div>
                    <div>
                        <input type="checkbox" [checked]="codes.includes(94)" (click)="RechCodePostal(94,$event.target.checked)"> &nbsp;94
                    </div>
                    <div>
                        <input type="checkbox" (click)="RechCodePostal(95,$event.target.checked)" [checked]="codes.includes(95)"> &nbsp;95
                    </div>
                    <div>
                        <input type="checkbox" (click)="RechCodePostal(0,$event.target.checked)" [checked]="codes.includes(0)"> &nbsp;Non renseigné
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-3 p-3">
            <p class="font-weight-bold" style="font-size: 20px;">Qualité CV</p>
            <div class="card mb-4">
                <div class="card-body">
                    <div>
                        <input type="checkbox" (change)="RechQualite('Jouable',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:green;'>&nbsp;Jouable</i>
                    </div>
                    <div>
                        <input type="checkbox" (change)="RechQualite('Moyen',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:orange;'>&nbsp;Moyen</i>
                    </div>
                    <div>
                        <input type="checkbox" (change)="RechQualite('Injouable',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:red;'>&nbsp;Injouable</i>
                    </div>
                    <div>
                        <input type="checkbox" (change)="RechQualite('Neutre',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:grey;'>&nbsp;Neutre</i>
                    </div>
                </div>
            </div>


            <p class="font-weight-bold" style="font-size: 20px;">Disponibilité</p>
            <div class="card">
                <div class="card-body">
                    <div *ngFor="let dis of Dispo">
                        <input type="checkbox" (change)="RechDispo(dis,$event.target.checked)">&nbsp;&nbsp;{{ dis}}
                    </div>
                    <div>
                        <input type="checkbox" (change)="RechDispo('NR',$event.target.checked)">&nbsp;&nbsp;Non Renseigné
                    </div>
                </div>
            </div>


            <p class="font-weight-bold" style="font-size: 20px;">Expérience</p>
            <div class="card">
                <div class="card-body">
                    <div *ngFor="let dis of dispos">
                        <input type="checkbox" (change)="RechExp(dis.libelle,$event.target.checked)">&nbsp;&nbsp;{{dis.libelle}}
                    </div>
                    <div>
                        <input type="checkbox" (change)="RechExp('NR',$event.target.checked)">&nbsp;&nbsp;Non Renseigné
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="form-group" style="display: none;" id="warning">
        <p style="color: red;">Merci de sélectionner des éléments</p>
    </div>
    <div style="margin: auto;width: 100%;display: flex; margin-top: 25px;">
        <button class="btn btn-primary" type="button" style="font-size: 20px;" (click)="lancer()" style="margin:auto">Lancer la recherche <i class="fa fa-search ml-3" aria-hidden="true"></i></button>
    </div>
</div>