import { createReducer, on } from '@ngrx/store';
import { addWindow,closeWindow } from '../actions/window.action';


export interface State {
    data: any[];
    
  }

export const initialState:State = {
    data: []
};

const _windowReducer = createReducer(
    initialState,
    on(addWindow,(state,action)=>({
        data:[...state.data,JSON.parse(action.window)]
    })),
    on(closeWindow,(state)=> ({
        data:[]
    })),
)


export function windowReducer(state,action){
    return _windowReducer(state,action);
}