<div class="card mb-4">
    <div class="card-body">
        <p class="text-center main-title">Dashboard suivi missions</p>

        <div class="row d-flex justify-content-around">

            <div class="card col-md-5">
                <div class="card-body">
                    <div class="text-center mb-1 title">Consultants missionnés</div>

                    <div class="row d-flex justify-content-around">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeConsMis()" [(ngModel)]="periodeConsMis" name="periodeConsMis">       
                            </select>
                        </div>

                        <div class="row">
                            <div class="mr-2">Trigramme</div>
                            <ng-select bindLabel="trigrammes" #selectTrigramme (change)="setTrigConsMis()" placeholder="" style="width: 100px;" [(ngModel)]="trigConsMis">
                            </ng-select>
                        </div>
                    </div>

                    <div class="alert alert-danger text-center h5 mt-2">Données indisponibles</div>

                    <div class="d-flex justify-content-center" #consultantMissionneContainer>
                        <canvas id="consultantChart" width="350" height="350" #consultantMissionne></canvas>
                    </div>

                    <div class="d-flex justify-content-end">
                        <div style="margin-right: 20px;">
                            <div>Tag metier</div>
                            <select name="consTagMet" [(ngModel)]="consTagMet" (change)="setConsTagMet()" class="form-select">         
                            </select>
                        </div>
                        <div style="margin-right: 20px;">
                            <div>Tag fonction</div>
                            <select name="consTagFonc" [(ngModel)]="consTagFonc" (change)="setConsTagFonc()" class="form-select">               
                            </select>
                        </div>
                        <div>
                            <div>Tag technique</div>
                            <select name="consTagTech" [(ngModel)]="consTagTech" (change)="setConsTagTech()" class="form-select">                
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card col-md-6">
                <div class="card-body">
                    <div class="text-center mb-1 title">Suivi missions</div>

                    <div class="row d-flex justify-content-around mb-2">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeSuiviMis()" [(ngModel)]="periodeSuiviMis" name="periodeSuiviMis">
                    
                            </select>
                        </div>

                        <div class="row">
                            <div class="mr-2">Trigramme</div>
                            <ng-select bindLabel="trigrammes" #selectTrigramme (change)="setTrigSuiviMis()" placeholder="" style="width: 100px;" [(ngModel)]="trigSuiviMis">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-around">
                        <div>
                            Secteur
                            <select class="form-select" name="sectSuiviMis" [(ngModel)]="sectSuiviMis" (change)="setSectSuiviMis()">
                    
                            </select>
                        </div>

                        <div>
                            Fonction
                            <select class="form-select" name="fonctSuiviMis" [(ngModel)]="fonctSuiviMis" (change)="setFonctSuiviMis()">
                      
                            </select>
                        </div>

                        <div>
                            Technologie
                            <select class="form-select" name="techSuiviMis" [(ngModel)]="techSuiviMis" (change)="setTechSuiviMis()">
                
                            </select>
                        </div>
                    </div>

                    <div class="alert alert-danger text-center h5 mt-2">Données indisponibles</div>

                    <div class="row">
                        <div class="col-9 chart">
                            <div id="suiviMissions">

                            </div>
                        </div>

                        <div class="col-3 justify-content-center" style="margin-top: 40px;" id="controlSuiviMissions">

                            <div class="mt-1 mb-2 font-weight-bold">En cours</div>

                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="satisfaisant"
                                (click)="addSerie('satisfaisant')" value=""><div class="indicator" [style.background-color]="'green'"></div>Satisfaisant</label>
                            </div>
                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="moyen"
                                (click)="addSerie('moyen')" value=""><div class="indicator" [style.background-color]="'orange'"></div>Moyen</label>
                            </div>
                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="insatisfaisant"
                                (click)="addSerie('insatisfaisant')" value=""><div class="indicator" [style.background-color]="'red'"></div>Insatisfaisant</label>
                            </div>


                            <div class="mt-2 mb-2 font-weight-bold">Terminés</div>

                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="t_satisfaisant"
                                (click)="addSerie('t_satisfaisant')" value=""><div class="indicator" [style.background-color]="'turquoise'"></div>Satisfaisant</label>
                            </div>
                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="t_moyen"
                                (click)="addSerie('t_moyen')" value=""><div class="indicator" [style.background-color]="'blue'"></div>Moyen</label>
                            </div>
                            <div class="form-check" style="margin: 4px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name="t_insatisfaisant"
                                (click)="addSerie('t_insatisfaisant')" value=""><div class="indicator" [style.background-color]="'gray'"></div>Insatisfaisant</label>
                            </div>


                            <div class="font-weight-bold mt-5">
                                <div>En cours : <span class="text-danger">0</span></div>
                                <div>Dont renouvellées : <span class="text-danger">0</span></div>
                                <div>Terminées : <span class="text-danger">0</span></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>