import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ConsultantService } from '../services/consultant.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QualiteService } from '../services/qualite.service';
import { DisponibiliteService } from '../services/disponibilite.service';
import { CommunicationService } from '../services/communication.service';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import {RechercheService} from "../services/recherche.service";
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import {PopupdataComponent} from '../popupdata/popupdata.component'
import { TokenStorageService } from '../services/token-storage.service';
import { BASE_URL } from '../config';


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}



export interface Dispo {
  value: string;
  name: string;
}

export interface Quali {
  value: string;
  name: string;
}

@Component({
    selector: 'app-rechresult',
    templateUrl: './rechresult.component.html',
    styleUrls: ['./rechresult.component.scss']
  })
  export class RechresultComponent implements OnInit {
    private sub: any;

    tagtech: any[] = [];
    tagmet: any[] = [];
    tagfonc: any[] = [];
    sortBy:string;

    qu: any[] = [];
    dis: any[] = [];
    expert: any[] = [];
    //zone: String;
    zone: any[] = [];
    consultants: any = [];
    newArray: any = [];
    test: string;
    total:string;
    totalConsultants=0
    showfilter=false

      //Couleur 
      isQualiteFilter = false;
      isNomFilter  = false;
      isPrenomFilter = false;
      isTitreFilter = false;
      isTechFilter = false;
    
      isDispoFilter = false;
      isClientFilter = false;
   
      idDateFilter = false;

      
    Dispo: Dispo[] = [
      
    ];

    Quali: Quali[] = [
      

    ];
    Clients = [];
    TagTech = [];
    TagMetier = [];
    TagFonc = [];
    Tech = [];
    filtest: boolean = false;
    shownext=false;
    showprevious=false
    showlastpage=false;
    showfirstpage=false
  zonegeo: any;
    constructor(private route: ActivatedRoute, public us: ConsultantService, private dialog: MatDialog, public communicationService: CommunicationService,
                private router: Router, public ts: QualiteService,private tokenStorageService: TokenStorageService, public ds: DisponibiliteService,public rs: RechercheService) {
      this.communicationService.Broadcast('OnChild1', 'Résultat de la recherche par tags');

     }

    
      qualitecv="";
      title="";
      isquali = false;
      isnom = false;
      isprenom = false;
      istitre = false;
      istech = false;
      isdispo = false;
      isclient = false;
      isobservation=false
      styleState=false
      isdmaj = false;
      nbrfilter = 0;
      disponibilite="";

      disposearch=[];
      expsearch=[];
      qualitecvsearch=[];
      tagtechserach=[];
      tagmetiersearch=[];
      tagfoncsearch=[]
      zonesearch=[];

      pagination=0
      dispoFilter = new FormControl();
      qualiFilter = new FormControl();
      clientFilter = new FormControl("");
      tagtechFilter = new FormControl("");
      tagmetierFilter = new FormControl("");
      tagfoncFilter = new FormControl("");
      techFilter = new FormControl("");
      emailFilter = new FormControl("");
      nameFilter = new FormControl("");
      prenomFilter = new FormControl("");
      titreFilter = new FormControl("");
      dateDerniereMajFilter = new FormControl("");
      globalFilter = '';
      filteredValues = {
        _id: '',
        nom: '',
        prenom: '',
        titre: '',
        code_dispo: '',
        qualite: '',
        tjm: '',
        telephone_principal: '',
        email: '',
        linkedin: '',
        code_postal: '',
        ville: '',
        cv: '',
        dateDerniereMaj: '',
        tech: '',
        client: '',
        tagtech: '',
        tagmetier: '',
        tagfonc: '',
      };
      @ViewChildren(MatPaginator) paginator: MatPaginator;
      public pageSize = 40;
      public currentPage =1;
      public totalSize2 =0;
      public totalSize = 0;
      tag=""
      taglist:any = []
      totalPage: number;

      
    //Order

    nameOrder='asc';
    prenomOrder='asc';
    qualiteOrder='asc';
    emailOrder='asc';
    titreOrder='asc';
    technoOrder='asc';
    clientOrder='asc';
    postalOrder='asc';
    dateOrder='asc';


    titredesc=false
    clientdesc=false
    postaldesc=false
    emaildesc=false
    nomdesc=false
    prenomdesc=false
    qualitedesc=false
    datedesc=false
   
    dispodesc=false
    modifdesc=false
    obsdesc=false
    techdesc=false
    orderdesc="nmom";
    orderasc="nom";

    pageSizeElement = [40,80,120]
    width;
    height;

    userid: string = "";
    favoris: string[] = [];

    ngOnInit() {

      const user = this.tokenStorageService.getUser();
      this.userid =  user.id;
      this.getrhFavoris();

      let {width,height} = window.screen
      this.width = width;
      this.height = height;
      if((width>=1366*0.9 && width<=1366*1.1) && (height>=768*0.9 && height<=768*1.1) ){
        this.pageSizeElement = [25,50,100]
        this.pageSize=25
      }
      else if((width>=1920*0.9 && width<=1920*1.1) && (height>=1080*0.9 && height<=1080*1.1) ){
        this.pageSizeElement = [30,60,120]
        this.pageSize=30
      }
      //this.loader()
      window.scroll(0,0);
    

      this.sub = this.route
      .queryParams
      .subscribe(params => {
      this.tagtechserach = params.tagtech;
      this.tagmetiersearch = params.tagmet;
      this.tagfoncsearch = params.tagfonc;
      this.tagtech = params.tagtech
      this.tagmet = params.tagmet;
      this.tagfonc = params.tagfonc;
      this.qualitecvsearch=params.qualite;
      this.disposearch = params.dispo;
      this.expsearch = params.experience;
      this.zonesearch = params.codegeo;
      this.qu = params.qualite;
      this.dis = params.dispo;
      this.expert = params.experience;
      this.zone = params.zone;
      this.zonegeo = params.zonegeo;
      
      this.taglist = this.tagtech.concat(this.tagmet)
      this.taglist = this.taglist.concat(this.tagfonc)
      this.taglist = this.taglist.concat(this.zonegeo)
      

      this.taglist = this.taglist.concat(this.qu)
      this.taglist = this.taglist.concat(this.dis)
      this.taglist = this.taglist.concat(this.expert)
      //this.taglist = this.taglist.concat(this.zonesearch)

      this.tag = this.taglist.join(" / ")

      

    });
      this.getDisponibilite();
      this.getQualite();
    this.Search()

    }

    getrhFavoris(){
      this.us.getUserFavoris(this.userid).subscribe(
        data => {
          this.favoris = [...data]
        },
        error => console.log(error)
      )
    }

    emitPagination(e){
this.gotoTop()
      this.currentPage=e.pageIndex
      this.pageSize=e.pageSize
      this.currentPage=this.currentPage+1
          this.goto(e.pageIndex+1);
      
      
      
          this.iterator();
            }
      private iterator() {
  
        //this.dataSource = part;
      }
      openAlertDialog() {
        const dialogRef = this.dialog.open(AlertEmptyComponent, {
          data: {
            message: 'aucun consultant trouvé',
            buttonText: {
              cancel: 'OK'
            }
          },
        });
    
        dialogRef.afterClosed().subscribe(result => {
          this.dialog.closeAll()
        });
      }




 

    Select(row) {
    
     // this.router.navigate(['/fichec'], { queryParams: { id: row._id } });
      window.open(BASE_URL+"/fichec?id="+row._id,"_blank")
  }

  getDisponibilite() {

    if(this.disposearch.length==0){
      this.ds.getRefs().subscribe((data2: {}) => {
        // tslint:disable-next-line: forin
            for (const i in data2) {
              this.Dispo.push({ value: data2[i].libelle, name: data2[i].libelle });
          }
          this.Dispo.push({ value: "NR", name: "NR" });

          });
    }else{
      for (const i in this.disposearch) {
        this.Dispo.push({ value: this.disposearch[i], name: this.disposearch[i] });
    }
    }

  }
  getQualite() {
 
    if(this.qualitecvsearch.length==0){
      this.ts.getRefs().subscribe((data2: {}) => {
        // tslint:disable-next-line: forin
            for (const i in data2) {
              this.Quali.push({ value: data2[i].libelle, name: data2[i].libelle });
           
              
          }
          });
    }else{
      for (const i in this.qualitecvsearch) {
        this.Quali.push({ value: this.qualitecvsearch[i], name: this.qualitecvsearch[i] });
     
        
    }
    }


 

  }

  selectedRow: any;

  selectedRowIndex: number;



  activeColumn: string;




    yvesColorEltSearch(index,data){

      data[index]['nom'] =  (this.nameFilter.value != "")? data[index]['nom'].replace(new RegExp('('+this.escapeRegExp(this.nameFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['nom']
      data[index]['prenom'] =  ( this.prenomFilter.value != "")? data[index]['prenom'].toString().replace(new RegExp('('+this.escapeRegExp(this.prenomFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :  data[index]['prenom']
      data[index]['titre'] =  ( this.titreFilter.value != "")?  data[index]['titre'].toString().replace(new RegExp('('+this.escapeRegExp(this.titreFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['titre']
      data[index]['tech'] =  (this.techFilter.value != "")?  data[index]['tech'].toString().replace(new RegExp('('+this.escapeRegExp(this.techFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['tech']
      data[index]['codedispo']['libelle'] =  (this.dispoFilter.value != "")? data[index]['codedispo']['libelle'].toString().replace(new RegExp('('+this.escapeRegExp(this.dispoFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['codedispo']['libelle']
      data[index]['listClient'] =  (this.clientFilter.value != "")?  data[index]['listClient'].toString().replace(new RegExp('('+this.escapeRegExp(this.clientFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['listClient']
      data[index]['dateDerniereMaj'] =  (this.dateDerniereMajFilter.value != "")?  data[index]['dateDerniereMaj'].toString().replace(new RegExp('('+this.escapeRegExp(this.dateDerniereMajFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['dateDerniereMaj']
       
   }
   escapeRegExp(s) {
    if(s!=null){
     return s.replace(/[\\^$.*+?()[\]{}|]/g,"\\$&");

    }
    return ""
 }


    getRechercheTag(data2) {
 
      this.consultants = [];
      if(data2.consultants.length===0) {
        this.openAlertDialog();
        

      }
     
      this.consultants = data2.consultants;
    
         this.total = this.consultants.lenght
// tslint:disable-next-line: forin
      for (const i in data2.consultants) {
      
        let cli = new Array();
        let techno = new Array();
        for(const j in data2.consultants[i].client){
          cli.push(data2.consultants[i].client[j].libelle);
        }
        for(const j in data2.consultants[i].tech){
          techno.push(data2.consultants[i].tech[j].libelle);
        }
        let cl = "";
        let tech = "";
        cl = cli.join(",");
        tech = techno.join(",");
      
        let temptech = tech;
            
      
 
        this.dialog.closeAll()
       this.total = data2.consultants.length
        this.test = this.consultants.length;
        this.consultants[i].client = cl;
        this.consultants[i].tech = tech;

        this.yvesColorEltSearch(i,this.consultants)
    
    }
    

    this.totalSize = data2.total;
    this.totalPage = data2.totalPage;
    if(this.totalSize>this.totalSize2){
      this.totalSize2=data2.total
    }
    this.totalConsultants = data2.totalConsultants
   
    this.iterator();
      this.nameFilter.valueChanges.subscribe((nameFilterValue) => {
        this.filteredValues.nom = nameFilterValue;
        this.showfilter=true
        
        if (nameFilterValue === '') {
              this.isnom = false;
              this.nbrfilter = this.nbrfilter - 1;
            } else {
              this.isnom = true;
              this.nbrfilter = this.nbrfilter + 1;
            }
            });
      this.dateDerniereMajFilter.valueChanges.subscribe((dateDerniereMajFilterValue) => {
        this.filteredValues.dateDerniereMaj = dateDerniereMajFilterValue;
        
      });
      this.prenomFilter.valueChanges.subscribe((prenomFilterValue) => {
        this.filteredValues.prenom = prenomFilterValue;
        
        
        console.log(this.test);
        if (prenomFilterValue === '') {
          this.isprenom = false;
        } else {
          this.isprenom = true;
          this.nbrfilter = this.nbrfilter - 1;
        }
      });
      this.titreFilter.valueChanges.subscribe((titreFilterValue) => {
        this.filteredValues.titre = titreFilterValue;
        

        
        if (titreFilterValue === '') {
          this.istitre = false;
          this.nbrfilter = this.nbrfilter - 1;
        } else {
          this.istitre = true;
          this.nbrfilter = this.nbrfilter + 1;        }
      });
      this.dispoFilter.valueChanges.subscribe((dispoFilterValue) => {
        this.filteredValues.code_dispo = dispoFilterValue;
        
        
        if (dispoFilterValue === '') {
          this.isdispo = false;
          this.nbrfilter = this.nbrfilter - 1;
        } else {
          this.isdispo = true;
          this.nbrfilter = this.nbrfilter + 1;
        }
      });
      this.qualiFilter.valueChanges.subscribe((qualiFilterValue) => {
        this.filteredValues.qualite = qualiFilterValue;
        
        
        if (qualiFilterValue === '') {
          this.isquali = false;
          this.nbrfilter = this.nbrfilter - 1;
        } else {
          this.isquali = true;
          this.nbrfilter = this.nbrfilter + 1;
        }
      });

      this.clientFilter.valueChanges.subscribe((clientFilterValue) => {
        this.filteredValues.client = clientFilterValue;
        
        
        if (clientFilterValue === '') {
          this.isclient = false;
          this.nbrfilter = this.nbrfilter - 1;
        } else {
          this.isclient = true;
          this.nbrfilter = this.nbrfilter + 1;        }
      });

      this.tagtechFilter.valueChanges.subscribe((tagtechFilterValue) => {
        this.filteredValues.tagtech = tagtechFilterValue;
        
      });

      this.tagmetierFilter.valueChanges.subscribe((tagmetierFilterValue) => {
        this.filteredValues.tagmetier = tagmetierFilterValue;
        
      });

      this.tagfoncFilter.valueChanges.subscribe((tagfoncFilterValue) => {
        this.filteredValues.tagfonc = tagfoncFilterValue;
        
      });

      this.techFilter.valueChanges.subscribe((techFilterValue) => {
        this.filteredValues.tech = techFilterValue;
        
        
        if (techFilterValue === '') {
          this.istech = false;
          this.nbrfilter = this.nbrfilter - 1;
        } else {
          this.istech = true;
          this.nbrfilter = this.nbrfilter + 1;
        }
      });



     
    }


    stripHtml(html)
    {
       let tmp = document.createElement("DIV");
       tmp.innerHTML = html;
       return tmp.textContent || tmp.innerText || "";
    } 
    sort(e){
      this.sortBy = e
      if(e==="email"){
        this.emaildesc=!this.emaildesc
        if(this.emaildesc){
          this.orderasc = 'ema5il';
          this.orderdesc='trigramme'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'trigramme';
          this.orderdesc='ema5il'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.postaldesc=false
        this.nomdesc=false
        
        this.prenomdesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.datedesc=false
        this.techdesc=false
      }
      if(e==="nom"){
        this.nomdesc=!this.nomdesc
        if(this.nomdesc){
          this.orderasc = 'nokm';
          this.orderdesc='nom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'nom';
          this.orderdesc='nomm'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.postaldesc=false
        this.prenomdesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        
        this.qualitedesc=false

      }
      if(e==="prenom"){
        this.prenomdesc=!this.prenomdesc
        if(this.prenomdesc){
          this.orderasc = 'postakl';
          this.orderdesc='prenom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'prenom';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.postaldesc=false
        
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false

      }

      if(e==="client"){
        this.clientdesc=!this.clientdesc
        if(this.clientdesc){
          this.orderasc = 'nokm';
          this.orderdesc='listClient'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'listClient';
          this.orderdesc='clientm'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.emaildesc=false
        this.postaldesc=false
        this.qualitedesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        

      }
      if(e==="titre"){
        this.titredesc=!this.titredesc
        if(this.titredesc){
          this.orderasc = 'titre5';
          this.orderdesc='titre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'titre';
          this.orderdesc='qua55lite'
          this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.clientdesc=false
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        
        
      }
      if(e==="qualite"){
        this.qualitedesc=!this.qualitedesc
        if(this.qualitedesc){
          this.orderasc = 'null';
          this.orderdesc='qualitecv.ordre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'qualitecv.ordre';
          this.orderdesc='null'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        this.techdesc=false
        this.postaldesc=false

      }      

      if(e==="tech"){
        this.techdesc=!this.techdesc
        if(this.techdesc){
          this.orderasc = 'postakl';
          this.orderdesc='tech'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'tech';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        
        this.dispodesc=false
        this.modifdesc=false
        this.dispodesc=false
        this.obsdesc=false
        

      }
      if(e==="dispo"){
        this.dispodesc=!this.dispodesc
        if(this.dispodesc){
          this.orderasc = 'postakl';
          this.orderdesc='codedispo.ordre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'codedispo.ordre';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.datedesc=false
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.techdesc=false
        
        
        this.modifdesc=false
        this.obsdesc=false
        

      }
      
      if(e==="date"){
        this.datedesc=!this.datedesc
        if(this.datedesc){
          this.orderasc = 'postakl';
          this.orderdesc='dateDerniereMaj'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'dateDerniereMaj';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.techdesc=false
        
        
        this.modifdesc=false
        this.obsdesc=false
        

      }

    }

    openPopup(e,data){
      e.preventDefault()
      const dialogRef = this.dialog.open(PopupdataComponent, {
       data: {
         message:data,
       },
     });
 
    }

    getByName(
      nom,prenom,dispo,qualite,tech,titre,client,date,page
  ) {
  
      this.consultants = [];
    
      (async () => { 
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(1000);
        dialogRef.close();
      })();
     
      let user = this.tokenStorageService.getUser();
      /**
      this.rs.getRecherche11(nom,prenom,dispo,qualite,tech,titre,client,date,
        page-1,
        this.pageSize,
        this.orderdesc,this.orderasc,user.trigramme
        ).subscribe((data2) => {
     
       this.getRechercheTag(data2)
      
      });
 */
      this.rs.getRechercheConsultantsTags(qualite,dispo,this.orderdesc,this.orderasc,
        nom,prenom,titre,client,date,tech,
        this.tagtechserach,this.tagmetiersearch,this.tagfoncsearch,this.zonesearch,this.disposearch,this.expsearch,this.qualitecvsearch,       
        page-1,this.pageSize
        ).subscribe((data2) => {
          console.log(data2)
     
       this.getRechercheTag(data2)
      
      });
    }
  

    gotopage( nom,prenom,dispo,qualite,tech,titre,client,date,page) {
    
this.gotoTop()

      this.consultants = [];
      let user = this.tokenStorageService.getUser();
    
      (async () => { 
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();
      
/** 
      this.rs.getRecherche11(nom,prenom,dispo,qualite,tech,titre,client,date,
        page,
        this.pageSize,
        this.orderdesc,this.orderasc,user.trigramme
        ).subscribe((data2) => {

 
       this.getRechercheTag(data2)
 
      });
*/
      this.rs.getRechercheConsultantsTags(qualite,dispo, this.orderdesc,this.orderasc,
        nom,prenom,titre,client,date,tech,this.tagtechserach,this.tagmetiersearch,this.tagfoncsearch,this.zonesearch,this.disposearch,this.expsearch,this.qualitecvsearch,       
        page-1,this.pageSize
        ).subscribe((data2) => {
     
       this.getRechercheTag(data2)
      
      });
      
    }
    getname(e){
      if(e[0]==="+"){
        e = e.slice(1,e.lenght)
      }
      return e
    }
    Search(){
      this.styleState=true
      this.loader()
        this.getByName(
          this.nameFilter.value,
          this.prenomFilter.value,
          this.disponibilite,
              this.qualitecv,
          this.techFilter.value,
          this.titreFilter.value,
          this.clientFilter.value,
          this.dateDerniereMajFilter.value,
          this.currentPage
          )
      }  
      SearchTriFiltrage(){
        this.showfilter=true
        this.currentPage=1
          this.styleState=true
          this.getByName(
            this.nameFilter.value,
            this.prenomFilter.value,
            this.disponibilite,

                this.qualitecv,
            this.techFilter.value,
            this.titreFilter.value,
            this.clientFilter.value,
            this.dateDerniereMajFilter.value,
            1
            )
        }
      opentech(e,data){
        e.preventDefault()
        const dialogRef = this.dialog.open(PopupdataComponent, {
         data: {
           message:data.tech[1],
         },
       });
   
      }

      resetFilter(){
      this.disponibilite="";
      this.qualitecv="";

        this.dispoFilter = new FormControl();
        this.qualiFilter = new FormControl();
        this.clientFilter = new FormControl("");
        this.tagtechFilter = new FormControl("");
        this.tagmetierFilter = new FormControl("");
        this.dateDerniereMajFilter = new FormControl("");
        this.tagfoncFilter = new FormControl("");
        this.techFilter = new FormControl("");
        this.nameFilter = new FormControl("");
        this.prenomFilter = new FormControl("");
        this.titreFilter = new FormControl("");

        this.filteredValues.nom=""
        this.filteredValues.prenom=""
        this.filteredValues.code_dispo=""
        this.filteredValues.qualite=""
        this.filteredValues.email=""
        this.filteredValues.code_postal=""
        this.filteredValues.titre=""
        this.filteredValues.client=""
  
        this.filteredValues.telephone_principal=""
        this.filteredValues.dateDerniereMaj=""
        this.filteredValues.tech=""
        this.isquali = false;
        this.isnom = false;
        this.isprenom = false;
        this.istitre = false;
        this.istech = false;
        this.isdispo = false;
        this.isclient = false;
        this.showfilter=false
        this.Search()
      }

      loader(){
        this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
           
            message: `
             Chargement en cours`
            
          },
          disableClose: true,
        });
      }
      
      Truncate(text){
        return text.substr(0,40)
      }
      TruncateObs(text){
        return text.substr(0,40)
      }
      gotoTop() {
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }

      goto(page) {
        if(parseInt(page)){
  
          if(page-1 <= this.totalPage) {
           
            this.currentPage=page
            this.Search()
          
          }
          else {
            let total = this.totalPage
            this.Alert("il n'existe que " + total+" pages")
        }
        }
        
        else {
          this.Alert("Page invalide")
      }
        }
      Alert(msg) {
        const dialogRef = this.dialog.open(AlertEmptyComponent, {
          data: {
            message: msg,
            buttonText: {
              cancel: 'OK'
            }
          },
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      NexPage(){
        this.currentPage = this.currentPage-1
        this.currentPage = this.currentPage+2
        this.Search()
      }
      previousPage(){
        this.currentPage = this.currentPage-1
        this.Search()
      }
      FirstPage(){
        this.currentPage =1
        this.showprevious=false;
        this.showfirstpage=false;

        this.shownext=true;
        this.showlastpage=true;
        this.Search()
      }
      LastPage(){
        this.currentPage = this.totalPage
        this.shownext=true;
        this.showlastpage=true;
        this.showprevious=false;
        this.showfirstpage=false;
        if(this.totalPage===this.currentPage){
          this.shownext=false
          this.showlastpage=false
        }
        this.Search()
      }

      getNext(){
        if(this.currentPage<this.totalPage){
          return true
        }return false
      }
      getpreviousMove(){
        if(this.currentPage>1){
          return true
        }return false
      }
      getPrevious(){
        if(this.currentPage>1){
          return true
        }return false
      }

      getlastpage(){
        if(this.currentPage<this.totalPage){
          return true
        }return false
      }
      ChangeItemPerPage(e){
        this.pageSize=e
        this.SearchTriFiltrage()
      }
      
     
      TruncateTitre(text){
        return text.substr(0,26)
      }
      TruncateIntitule(text){
        return text.substr(0,26)
      }
      renderDispo(item){
        return item? item.libelle : ''
      }
      renderPrenom(prenom){
        return prenom[0].toUpperCase()+prenom.slice(1).toLowerCase()
      }
      
    setdata(e: string, event: any) {
      let k = document.getElementById(event.path[0].id)

      this.title = e;
      if(k){
  
        if(k.scrollWidth > k.clientWidth){
          event.path[0].bgColor="#FFFFCC";
  
          k.setAttribute("title",this.stripHtml(k.innerHTML))
          //this.open(event);
        }
      }
      }
    
      open(e: any) {
    
        const ele = document.getElementById('element');
        console.log(ele.getBoundingClientRect())
        const menu = document.getElementById('menu');
        if (ele != null && menu != null) {
          e.preventDefault();
    
          const rect = ele.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
    
          // Set the position for menu
          menu.style.top = `${y + 300}px`;
          menu.style.left = `${x}px`;
    
          // Show the menu
          menu.classList.remove('hidden');
        }
      }
 hover() {
        const ele = document.getElementById('element');
        const menu = document.getElementById('menu');
        if (ele != null && menu != null) {
          ele.addEventListener('context', function (e: any) {
            e.preventDefault();
    
            const rect = ele.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
    
            // Set the position for menu
            menu.style.top = `${y + 40}px`;
            menu.style.left = `${x}px`;
    
            // Show the menu
            menu.classList.remove('hidden');
    
            document.addEventListener('click', documentClickHandler);
          });
    
          // Hide the menu when clicking outside of it
          const documentClickHandler = function (e: any) {
            const isClickedOutside = !menu.contains(e.target);
            if (isClickedOutside) {
              menu.classList.add('hidden');
              document.removeEventListener('click', documentClickHandler);
            }
          };
        }
 }
 close(e: any) {
  e.path[0].bgColor="transparent";

    const menu = document.getElementById('menu');
    if (menu != null) {
      const isClickedOutside = !menu.contains(e.target);
      if (isClickedOutside) {
        menu.classList.add('hidden');
      }
    }
  }

  }


