import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from '../dialog-error/dialog-error.component';
import { DialogFileComponent } from '../dialog-file/dialog-file.component';

@Injectable({
  providedIn: 'root'
})
export class DialogValidationService {

  public inputColorErrorFicheClient = {
    'nom' : false,
    'secteur' : false,
    'activite' : false,
    'statut' : false,
    'trigramme' : false,
    'adresse' : false,
    'cp' : false,
    'tel1' : false,
    'ville' : false,
  }

  public inputColorErrorFicheContact = {
    'statut' : false,
    'nom' : false,
    'prenom' : false,
    'titre' : false
  }

  public inputColorErrorListClientNC = {
    'nom' : false,
    'secteur' : false,
  }

  constructor(public dialog:MatDialog) { }

 
  openDialogError(errorList){
    let dialogRef = this.dialog.open(DialogErrorComponent,{
      data:errorList,
      width:'450px',
      disableClose:true,
      panelClass : 'dialogErrorClass',
      position:{top:'0'}
    }
    )

    dialogRef.afterClosed().subscribe(
     ()=> {},
     ()=>{},
     () =>  {}

    )
  }

  openDialogFile(data,type){
    this.dialog.open(DialogFileComponent,{
      data:{'data':data,'type':type},
      minWidth:'350px',
      disableClose:true  
    }
    )
  }

}
