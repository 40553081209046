<div class="container-fluid" style="height: 98vh;">
    <form>
        <div class='row'>
            <div class="col-md-4">
                <p class="font-weight-bold" style="font-size: 20px;">Mots clés</p>
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row pl-1">
                            <div class="item">
                                <label for="yes" class="mr-1">ET</label>
                                <input (click)="checkData()" [checked]=AndOrSearch type="radio">
                            </div>

                            <div class="item">
                                <label for="yes" class="mr-1">OU</label>
                                <input (click)="checkData()" [checked]=!AndOrSearch type="radio">
                            </div>
                        </div>
                        <form>
                            <div class="row mb-1">
                                <div class="col-3">Priorité</div>
                                <div class="col-9">Mot clés</div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3">
                                    <select class="form-select form-select-sm" id="select1">
                                    <option value="0">..</option>
                                    <option *ngFor="let pos of Poids"  [value]="pos.poids">{{pos.priorite}}</option>
                                  </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm" id="input1">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3">
                                    <select class="form-select form-select-sm" id="select2">
                                    <option value="0">..</option>
                                    <option *ngFor="let pos of Poids" [value]="pos.poids">{{pos.priorite}}</option>

                                </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm" id="input2">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3">
                                    <select class="form-select form-select-sm" id="select3">
                                  <option value="0">..</option>
                                  <option *ngFor="let pos of Poids" [value]="pos.poids">{{pos.priorite}}</option>

                              </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm" id="input3">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3">
                                    <select class="form-select form-select-sm" id="select4">
                                    <option value="0">..</option>
                                    <option *ngFor="let pos of Poids" [value]="pos.poids">{{pos.priorite}}</option>
                                </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm" id="input4">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <select id="select5">
                                    <option value="0">..</option>
                                    <option *ngFor="let pos of Poids" [value]="pos.poids">{{pos.priorite}}</option>
                                </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" style="width: 100%;" class="form-control form-control-sm" id="input5">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <p class="font-weight-bold" style="font-size: 20px;">Disponibilité</p>
                <div class="card">
                    <div class="card-body">
                        <tbody>
                            <div *ngFor="let dis of Dispo">
                                <input type="checkbox" (change)="RechDispo(dis,$event.target.checked)">&nbsp;&nbsp;{{ dis}}
                            </div>
                            <div>
                                <input type="checkbox" (change)="RechDispo('NR',$event.target.checked)">&nbsp;&nbsp;Non Renseigné
                            </div>
                        </tbody>
                    </div>
                </div>

            </div>

            <div class="col-md-4">
                <p class="font-weight-bold" style="font-size: 20px;">Zone géographique</p>
                <div class="card mb-3">
                    <div class="card-body">
                        <div>
                            <input type="checkbox" (click)="selectCheckBox(check_box_type.TTZ,1)" [checked]="currentlyChecked === check_box_type.TTZ"> &nbsp;Toutes les zones
                        </div>
                        <div>
                            <input type="checkbox" (click)="selectCheckBox(check_box_type.ZIDF,2)" [checked]="currentlyChecked === check_box_type.ZIDF"> &nbsp;Zone IDF
                        </div>
                        <div>
                            <input type="checkbox" (click)="selectCheckBox(check_box_type.ZIDFR,3)" [checked]="currentlyChecked === check_box_type.ZIDFR"> &nbsp;Zone IDF ou non renseigné
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div>
                            <input type="checkbox" [checked]="codes.includes(75)===true" (click)="RechCodePostal(75)"> &nbsp;75
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(77)" (click)="RechCodePostal(77)"> &nbsp;77
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(78)" (click)="RechCodePostal(78)"> &nbsp;78
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(91)" (click)="RechCodePostal(91)"> &nbsp;91
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(92)" (click)="RechCodePostal(92)"> &nbsp;92
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(93)" (click)="RechCodePostal(93)"> &nbsp;93
                        </div>
                        <div>
                            <input type="checkbox" [checked]="codes.includes(94)" (click)="RechCodePostal(94)"> &nbsp;94
                        </div>
                        <div>
                            <input type="checkbox" (click)="RechCodePostal(95)" [checked]="codes.includes(95)"> &nbsp;95
                        </div>
                        <div>
                            <input type="checkbox" (click)="RechCodePostal(0)" [checked]="codes.includes(0)"> &nbsp;Non renseigné
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">

                <p class="font-weight-bold" style="font-size: 20px;">Qualité CV</p>
                <div class="card mb-4">
                    <div class="card-body">
                        <div>
                            <input type="checkbox" (change)="RechQualite('Jouable',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:green;'>&nbsp;Jouable</i>
                        </div>
                        <div>
                            <input type="checkbox" (change)="RechQualite('Moyen',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:orange;'>&nbsp;Moyen</i>
                        </div>
                        <div>
                            <input type="checkbox" (change)="RechQualite('Injouable',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:red;'>&nbsp;Injouable</i>
                        </div>
                        <div>
                            <input type="checkbox" (change)="RechQualite('Neutre',$event.target.checked)">&nbsp;&nbsp;<i class="fa fa-circle" aria-hidden="true" style='color:grey;'>&nbsp;Neutre</i>
                        </div>
                    </div>
                </div>

                <p class="font-weight-bold" style="font-size: 20px;">Expérience</p>
                <div class="card">
                    <div class="card-body">
                        <div *ngFor="let dis of dispos">
                            <input type="checkbox" (change)="RechExp(dis.libelle,$event.target.checked)">&nbsp;&nbsp;{{dis.libelle}}
                        </div>
                        <div>
                            <input type="checkbox" (change)="RechExp('NR',$event.target.checked)">&nbsp;&nbsp;Non Renseigné
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </form>

    <div style="margin: auto;width: 100%;display: flex; margin-top: 25px;">
        <button class="btn btn-primary" type="button" style="font-size: 20px;" (click)="save()" style="margin:auto">Lancer la recherche <i class="fa fa-search ml-3" aria-hidden="true"></i></button>
    </div>
</div>