import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ConsultantService } from '../services/consultant.service';
import { Router } from '@angular/router';

import { CommunicationService } from '../services/communication.service';
import { DatePipe } from '@angular/common';
import { RappelsService } from '../services/rappels.service';
import { Rappels } from '../Model/rappels';
import { TokenStorageService } from '../services/token-storage.service';

import { MatDialog } from '@angular/material';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import {Title} from "@angular/platform-browser";
import {PopupdataComponent} from '../popupdata/popupdata.component'
import {Observable, of} from 'rxjs';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { BASE_URL } from '../config';


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}



@Component({
  selector: 'app-rappels',
  templateUrl: './rappels.component.html',
  styleUrls: ['./rappels.component.scss'],
  providers: [DatePipe]
})
export class RappelsComponent implements OnInit {
    consultants: any = [];
    rappels: any = [];
    rapp= new Rappels();
    id: String;
    title=""
  styleState=false
    newArray: any = [];
    test: string;
    filtest: boolean = false;
    showfilter=false
    asyncData: Observable<Rappels[]>;
    sortBy:string;
    trigramme: string = "";
    roles: string[] = [];

    constructor(public us: ConsultantService, public communicationService: CommunicationService,
                private router: Router,private titleService:Title, public rs: RappelsService,private dialog: MatDialog,private tokenStorageService: TokenStorageService,  private datePipe: DatePipe,) {
      this.communicationService.Broadcast('OnChild1', 'Liste des rappels');

     }

      /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
      displayedColumns: string[] = ['Date','Nom', 'Prenom', 'Titre','rappel', 'Observation', 'traite','Trigramme'];

      date
      isdate = false;
      istrigramme = false;
      isactif = false;
      israppel = false;
      isnom = false;
      isprenom = false;
      istitre = false;
      isobservation = false;
      nbrfilter = 0;
      pagination=0




        //Couleur

    isNomFilter  = false;
    isPrenomFilter = false;
    isRappelFilter = false;
    isTitreFilter = false;
    isTrigrammeFilter = false;
    isClientFilter = false;
    isEmailFilter = false;
    isObsFilter = false;
    idDateFilter = false;


      shownext=false;
      showprevious=false
      showlastpage=false;
      showfirstpage=false

      dateFilter = new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      trigrammeFilter = new FormControl("");
      actifFilter = new FormControl("");
      observationFilter = new FormControl("");
      nameFilter = new FormControl("");
      emailFilter= new FormControl("")
      rappelFilter = new FormControl("");
      prenomFilter = new FormControl("");
      titreFilter = new FormControl("");
      globalFilter = '';
      filteredValues = {
        nom: '',
        prenom: '',
        titre: '',
        observation: '',
        date: '',
        trigramme: '',
        actif: '',
        rappel:''
      };
      @ViewChildren(MatPaginator) paginator: MatPaginator;
      public pageSize = 120;
      public currentPage = 1;
      public totalSize = 0;
      public totalSize2 = 0;
      totalPage: number;
      page=0

      rap: any = [];

      isemail=false
      titredesc=false
      clientdesc=false
      postaldesc=false
      emaildesc=false
      nomdesc=false
      prenomdesc=false
      rappeldesc=false
      qualitedesc=false
      trigdesc=false
      teldesc=false

      dispodesc=false

      obsdesc=false
      techdesc=false
      orderdesc="date";
      orderasc="dates";
      myDate = new Date();
      pageSizeElement = [40,80,120]
      width;
      height;
      ngOnInit() {
        const user = this.tokenStorageService.getUser();
        this.roles = user.roles;
        this.trigramme = user.trigramme

        let {width,height} = window.screen
        this.width = width;
        this.height = height;
        if((width>=1366*0.9 && width<=1366*1.1) && (height>=768*0.9 && height<=768*1.1) ){
          this.pageSizeElement = [25,50,100,120]
          this.pageSize=120
        }
        else if((width>=1920*0.9 && width<=1920*1.1) && (height>=1080*0.9 && height<=1080*1.1) ){
          this.pageSizeElement = [30,60,120]
          this.pageSize=120
        }
      this.loader()
      this.titleService.setTitle("Portail RH")
      window.scroll(0,0);
      this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),

      this.Search();
    }

    yvesColorEltSearch(index,data){

      data[index]['nom'] =  (this.nameFilter.value != "")? data[index]['nom'].replace(new RegExp('('+this.escapeRegExp(this.nameFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['nom']
       data[index]['prenom'] =  ( this.prenomFilter.value != "")? data[index]['prenom'].toString().replace(new RegExp('('+this.escapeRegExp(this.prenomFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :  data[index]['prenom']
       data[index]['titre'] =  ( this.titreFilter.value != "")?  data[index]['titre'].toString().replace(new RegExp('('+this.escapeRegExp(this.titreFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['titre']
       data[index]['date'] =  (this.dateFilter.value != "")?  data[index]['date'].toString().replace(new RegExp('('+this.escapeRegExp(this.dateFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['date']
       data[index]['email'] =  (this.emailFilter.value != "")? data[index]['email'].toString().replace(new RegExp('('+this.escapeRegExp(this.emailFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['email']
       data[index]['libelleRappel'] =  (this.rappelFilter.value != "")? data[index]['libelleRappel'].toString().replace(new RegExp('('+this.escapeRegExp(this.rappelFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  :data[index]['libelleRappel']
       data[index]['trigramme'] =  (this.trigrammeFilter.value != "")?data[index]['trigramme'].toString().replace(new RegExp('('+this.escapeRegExp(this.trigrammeFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['trigramme']
       data[index]['observation'] =  (this.observationFilter.value != "")?  data[index]['observation'].toString().replace(new RegExp('('+this.escapeRegExp(this.observationFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['observation']

   }
   escapeRegExp(s) {
    if(s!=null){
     return s.replace(/[\\^$.*+?()[\]{}|]/g,"\\$&");

    }
    return ""
 }
    getRappels(data2){

      this.rappels.splice(0, this.rappels.length);

      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();

      //this.rap = data2.rappels;
      for (const i in data2.rappels) {
        if(data2.rappels[i].actif!=="NON"){
        let obsrv = new Array();
        let obs = "";

          if(data2.rappels[i].observation){
            obsrv.push(data2.rappels[i].observation.libelleObs);
          }

        obs = obsrv.join(",");

        data2.rappels[i].observation = obs;
        this.rappels.push(data2.rappels[i]);

    }
     }
     for (let index = 0; index < this.rappels.length; index++) {
      this.yvesColorEltSearch(index,this.rappels)
     }


    this.dialog.closeAll()
      this.totalSize = data2.total;
      this.totalPage = data2.totalPage
      if(this.totalSize>this.totalSize2){
        this.totalSize2 = data2.total
      }
      if(this.totalPage+1){
        this.shownext=true;
        this.showlastpage=true
      }
      this.iterator();
    }



  selectedRow: any;

  selectedRowIndex: number;

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  activeColumn: string;

  discard(){
    this.router.navigate(['/Liste']);
  }

  Select(row){

    window.open(BASE_URL+"/fichec?id="+row.idC,"_blank")
  }

  emitPagination(e){

    this.currentPage=e.pageIndex
    this.pageSize=e.pageSize
    this.currentPage=this.currentPage+1
        this.goto(e.pageIndex);



        this.iterator();
          }
          private iterator() {
            (async () => {
              const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
                data: {
                  message: "Chargement en cours..."
                },
              });
              await delay(1000);
              dialogRef.close();
            })();

          }


          sort(e){
            this.sortBy = e
            this.page = 0;
            this.currentPage=0;
            if(e==="postal"){
              this.postaldesc=!this.postaldesc
              if(this.postaldesc){
                this.orderasc = 'postakl';
                this.orderdesc='postal'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'postal';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false

              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.rappeldesc=false
              this.obsdesc=false
              this.techdesc=false
              this.qualitedesc=false

            }
            if(e==="rappel"){
              this.rappeldesc=!this.rappeldesc
              if(this.rappeldesc){
                this.orderasc = 'postakl';
                this.orderdesc='libelleRappel'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'libelleRappel';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false

              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false

              this.obsdesc=false
              this.techdesc=false
              this.qualitedesc=false

            }
            if(e==="email"){
              this.emaildesc=!this.emaildesc
              if(this.emaildesc){
                this.orderasc = 'ema5il';
                this.orderdesc='email'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'email';
                this.orderdesc='ema5il'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.postaldesc=false
              this.nomdesc=false

              this.prenomdesc=false
              this.rappeldesc=false
              this.qualitedesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false
            }
            if(e==="nom"){
              this.nomdesc=!this.nomdesc
              if(this.nomdesc){
                this.orderasc = 'nokm';
                this.orderdesc='nom'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'nom';
                this.orderdesc='nomm'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.postaldesc=false
              this.prenomdesc=false
              this.dispodesc=false
              this.rappeldesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false

              this.qualitedesc=false

            }
            if(e==="prenom"){
              this.prenomdesc=!this.prenomdesc
              if(this.prenomdesc){
                this.orderasc = 'postakl';
                this.orderdesc='prenom'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'prenom';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.postaldesc=false

              this.qualitedesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.rappeldesc=false
              this.techdesc=false

            }
            if(e==="client"){
              this.clientdesc=!this.clientdesc
              if(this.clientdesc){
                this.orderasc = 'nokm';
                this.orderdesc='client'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'client';
                this.orderdesc='clientm'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.emaildesc=false
              this.postaldesc=false
              this.qualitedesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false
              this.rappeldesc=false


            }
            if(e==="titre"){
              this.titredesc=!this.titredesc
              if(this.titredesc){
                this.orderasc = 'titre5';
                this.orderdesc='titre'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'titre';
                this.orderdesc='qua55lite'
                this.SearchTriFiltrage()
              }

              this.clientdesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false
              this.postaldesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.rappeldesc=false
              this.qualitedesc=false


            }
            if(e==="qualite"){
              this.qualitedesc=!this.qualitedesc
              if(this.qualitedesc){
                this.orderasc = 'date';
                this.orderdesc='h'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'qualjite';
                this.orderdesc='date'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false

              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false
              this.rappeldesc=false
              this.postaldesc=false

            }
            if(e==="tel"){
              this.teldesc=!this.techdesc
              if(this.techdesc){
                this.orderasc = 'postakl';
                this.orderdesc='telephone_pricipal'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'telephone_principal';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.rappeldesc=false
              this.qualitedesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false

            }
            if(e==="obs"){
              this.obsdesc=!this.obsdesc
              if(this.obsdesc){
                this.orderasc = 'postakl';
                this.orderdesc='observation.libelleObs'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'observation.libelleObs';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.qualitedesc=false

              this.dispodesc=false
              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.rappeldesc=false
              this.techdesc=false


            }
            if(e==="tech"){
              this.techdesc=!this.techdesc
              if(this.techdesc){
                this.orderasc = 'postakl';
                this.orderdesc='tech'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'tech';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.qualitedesc=false

              this.dispodesc=false
              this.trigdesc=false
              this.dispodesc=false
              this.obsdesc=false
              this.rappeldesc=false


            }

            if(e==="trig"){
              this.trigdesc=!this.trigdesc
              if(this.trigdesc){
                this.orderasc = 'postakl';
                this.orderdesc='trigramme'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'trigramme';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.qualitedesc=false
              this.techdesc=false



              this.dispodesc=false
              this.obsdesc=false
              this.techdesc=false
              this.rappeldesc=false


            }
            if(e==="dispo"){
              this.dispodesc=!this.dispodesc
              if(this.dispodesc){
                this.orderasc = 'postakl';
                this.orderdesc='dispo'
                this.SearchTriFiltrage()
              }else{
                this.orderasc = 'dispo';
                this.orderdesc='po5stal'
              this.SearchTriFiltrage()
              }
              this.titredesc=false
              this.clientdesc=false
              this.emaildesc=false
              this.nomdesc=false
              this.prenomdesc=false
              this.qualitedesc=false
              this.techdesc=false

              this.rappeldesc=false
              this.trigdesc=false
              this.obsdesc=false


            }

          }

          getByName(
            email,nom,prenom,trigramme,obs,titre,rappel,date,page
        ) {


          const user = this.tokenStorageService.getUser();
            this.rappels.splice(0, this.rappels.length);

            this.consultants = [];

            (async () => {
              const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
                data: {
                  message: "Chargement en cours..."
                },
              });
              await delay(1000);
              dialogRef.close();
            })();

            this.rs.getRappels(email,nom,prenom,trigramme,obs,titre,rappel,date,
              page-1,this.pageSize,this.orderdesc,this.orderasc,user.trigramme
            ).subscribe((data2) => {

             this.getRappels(data2)


            });
          }


          gotopage(email, nom,prenom,trigarmme,obs,titre,rappel,date,page) {

            this.consultants = [];
            const user = this.tokenStorageService.getUser();
            (async () => {
              const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
                data: {
                  message: "Chargement en cours..."
                },
              });
              await delay(2000);
              dialogRef.close();
            })();



            this.rs.getRappels(email, nom,prenom,trigarmme,obs,titre,rappel,date,this.currentPage-1,this.pageSize,
              this.orderasc,
              this.orderdesc,user.trigramme
              ).subscribe((data2) => {


             this.getRappels(data2)

            });

          }
          Search(){

            this.loader()
            this.date = this.datePipe.transform(this.filteredValues.date, 'yyyy-MM-dd')
            if(this.date===null){
              this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
            }

            //this.currentPage=0
              this.getByName(
                this.emailFilter.value,
                this.nameFilter.value,
                this.prenomFilter.value,
                (this.roles.indexOf('Gestionnaire')>0 || this.roles.indexOf('Facturation')>0) ? this.trigrammeFilter.value : this.trigramme,
                this.observationFilter.value,
                this.titreFilter.value,
                this.rappelFilter.value,
                this.date,
                this.currentPage
                )
            }
            SearchTriFiltrage(){
              this.currentPage=1
              this.showfilter=true;
              this.date = this.datePipe.transform(this.filteredValues.date, 'yyyy-MM-dd')
              if(this.date===null){
                this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
              }

              //this.currentPage=0
                this.getByName(
                  this.emailFilter.value,
                  this.nameFilter.value,
                  this.prenomFilter.value,
                  (this.roles.indexOf('Gestionnaire')>0 || this.roles.indexOf('Facturation')>0) ? this.trigrammeFilter.value : this.trigramme,
                  this.observationFilter.value,
                  this.titreFilter.value,
                  this.rappelFilter.value,
                  this.dateFilter.value,
                  1
                  )
              }

            dateChange(){
             this.styleState=true
            }

            resetFilter(){

              this.isdate = false;
              this.istrigramme = false;
              this.isactif = false;
              this.isnom = false;
              this.isprenom = false;
              this.istitre = false;
              this.isobservation = false;
              this.israppel = false;


              this.dateFilter = new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
              this.trigrammeFilter = new FormControl("");
              this.actifFilter = new FormControl("");
              this.observationFilter = new FormControl("");
              this.nameFilter = new FormControl("");
              this.rappelFilter = new FormControl("");
              this.prenomFilter = new FormControl("");
              this.titreFilter = new FormControl("");
              this.emailFilter = new FormControl("")
              this.showfilter=false
              this.filteredValues.nom=""
              this.filteredValues.prenom=""
              this.filteredValues.trigramme=""
              this.filteredValues.date=this.datePipe.transform(new Date(), 'yyyy-MM-dd')
              this.filteredValues.observation=""
              this.filteredValues.titre=""
              this.filteredValues.actif=""
              this.filteredValues.rappel=""
              this.Search()
            }
            updateRappels(e){

              this.dialog.open(ProgressSpinnerDialogComponent, {
                data: {

                  message: `Chargement...`

                },
                disableClose: true,
              });
              e.actif="NON"

             this.rs.updateRappelStatut(e.id).subscribe((data)=>{
              if(data===1){
                this.SearchTriFiltrage();
             this.dialog.closeAll()
              }
             })
            }


            openPopup(e,data){
              e.preventDefault()
              const dialogRef = this.dialog.open(PopupdataComponent, {
               data: {
                 message:data,
               },
             });

            }
            Truncate(text){
              return text.substr(0,36)
            }
            TruncateTitre(text){
              return text.substr(0,26)
            }
            TruncateIntitule(text){
              return text.substr(0,26)
            }
            getPage(e){
              this.gotoTop()
              this.rappels.splice(0, this.rappels.length);
              this.currentPage = e-1;
              this.Search()
              return e;
            }
            ChangeItemPerPage(e){
              this.pageSize=e
              this.SearchTriFiltrage()
            }

            loader(){
              this.dialog.open(ProgressSpinnerDialogComponent, {
                data: {

                  message: `
                   Chargement en cours`

                },
                disableClose: true,
              });
            }
            gotoTop() {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
            goto(page) {
              if(parseInt(page)){

                if(page-1 <= this.totalPage) {

                  this.currentPage=page
                  this.Search()

                }
                else {
                  let total = this.totalPage+1
                  this.Alert("il n'existe que " + total+" pages")
              }
              }

              else {
                this.Alert("Page invalide")
            }
              }
            Alert(msg) {
              const dialogRef = this.dialog.open(AlertEmptyComponent, {
                data: {
                  message: msg,
                  buttonText: {
                    cancel: 'OK'
                  }
                },
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
            NexPage(){
              this.currentPage = this.currentPage-1
              this.currentPage = this.currentPage+2
              this.Search()
            }
            previousPage(){
              this.currentPage = this.currentPage-1
              this.Search()
            }
            FirstPage(){
              this.currentPage =1
              this.showprevious=false;
              this.showfirstpage=false;

              this.shownext=true;
              this.showlastpage=true;
              this.Search()
            }
            LastPage(){
              this.currentPage = this.totalPage+1
              this.shownext=true;
              this.showlastpage=true;
              this.showprevious=false;
              this.showfirstpage=false;
              if(this.totalPage+1===this.currentPage){
                this.shownext=false
                this.showlastpage=false
              }
              this.Search()
            }

            getNext(){
              if(this.currentPage<this.totalPage+1){
                return true
              }return false
            }
            getpreviousMove(){
              if(this.currentPage>1){
                return true
              }return false
            }
            getPrevious(){
              if(this.currentPage>1){
                return true
              }return false
            }

            getlastpage(){
              if(this.currentPage<this.totalPage+1){
                return true
              }return false
            }

            renderPrenom(prenom){
              return prenom[0].toUpperCase()+prenom.slice(1).toLowerCase()
            }
            stripHtml(html)
            {
               let tmp = document.createElement("DIV");
               tmp.innerHTML = html;
               return tmp.textContent || tmp.innerText || "";
            }

            setdata(e: string, event: any) {
              let k = event.target;
              this.title = e;
              if (k) {
                if (k.scrollWidth > k.clientWidth) {
                  k.style.backgroundColor = "#FFFFCC";

                  k.setAttribute("title", e);
                  this.open(event);
                }
              }
            }

              open(e: any) {

                const ele = document.getElementById('element');
                const menu = document.getElementById('menu');
                if (ele != null && menu != null) {
                  e.preventDefault();

                  const rect = ele.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  const y = e.clientY - rect.top;

                  // Set the position for menu
                  menu.style.top = `${y + 300}px`;
                  menu.style.left = `${x}px`;

                  // Show the menu
                  menu.classList.remove('hidden');
                }
              }
         hover() {
                const ele = document.getElementById('element');
                const menu = document.getElementById('menu');
                if (ele != null && menu != null) {
                  ele.addEventListener('context', function (e: any) {
                    e.preventDefault();

                    const rect = ele.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;

                    // Set the position for menu
                    menu.style.top = `${y + 40}px`;
                    menu.style.left = `${x}px`;

                    // Show the menu
                    menu.classList.remove('hidden');

                    document.addEventListener('click', documentClickHandler);
                  });

                  // Hide the menu when clicking outside of it
                  const documentClickHandler = function (e: any) {
                    const isClickedOutside = !menu.contains(e.target);
                    if (isClickedOutside) {
                      menu.classList.add('hidden');
                      document.removeEventListener('click', documentClickHandler);
                    }
                  };
                }
         }

         close(e: any) {
          e.target.style.backgroundColor="transparent";

            const menu = document.getElementById('menu');
            if (menu != null) {
              const isClickedOutside = !menu.contains(e.target);
              if (isClickedOutside) {
                menu.classList.add('hidden');
              }
            }
          }


          renderObs(obs){
            if(obs){
              return obs;
            }
            return "";
          }
  }


