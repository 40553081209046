import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { DashboardService } from '../services/dashboard.service';


@Component({
  selector: 'app-synthese',
  templateUrl: './synthese.component.html',
  styleUrls: ['./synthese.component.scss']
})
export class SyntheseComponent implements OnInit, OnChanges {

  constructor(private dservice: DashboardService, private tokenStorage: TokenStorageService, private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal) { }

  @Input() selections: any;
  globalData: any;

  loadTrigramme = true;
  makeCalcul = true;

  trigramme: string = "";
  isAdmin: boolean = false;
  userid: string = "";


  ngOnInit(): void {

    const user = this.tokenStorage.getUser();
    this.trigramme = user.trigramme;
    this.isAdmin = user.roles.lastIndexOf('Gestionnaire')>0 || user.roles.lastIndexOf('Facturation')>0;
    this.userid = user.id;

    if(this.isAdmin){
      this.dservice.getGlobalData().subscribe(
        (data: any) => {
          this.globalData = data
          let today = new Date()
          this.globalData.rappels.forEach((element: any) => {
            if ((this.datePipe.transform(element.date, 'yyyy-MM-dd') === this.datePipe.transform(today,'yyyy-MM-dd')) && element.actif === "OUI") {
              this.rappelDuJour += 1;
            }
            if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999))) && element.actif === "OUI") {
              this.rappelATemp += 1;
            }
            if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) < new Date(today.setUTCHours(0,0,0,0))) && element.actif === "OUI") {
              this.rappelEnRetard += 1;
            }
            if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999)) && new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) <= new Date(new Date().setDate(today.getDate() + 7)) && element.actif==="OUI") {
              this.rappelAVenir += 1;
            }
          });

          this.totalConsultants = this.globalData.totalConsultants;

          this.globalData.consultants.forEach((element: any) => {
              this.nouveauxConsultants += 1;
          });

          this.globalData.consultantMAJs.forEach((element: any) => {
              this.consultantsMAJ += 1;
          });

          this.makeCalcul = false;
          this.isLoading = (this.makeCalcul || this.loadTrigramme)
          this.loadChanged.emit(this.isLoading);
        }
      )

      this.dservice.getSyntheseCrmGlobalData().subscribe(
        (data: any) => {
          this.besoinCrmData = data;
          this.moisGlissantBesoins = this.besoinCrmData.length;
          this.besoinCrmData.forEach((elt: any) => {
            switch (elt.statut) {
              case "En Cours":
                this.besoinsEnCours += 1;
                break;
              case "Signé":
                this.besoinsSigne += 1
                break;
              case "Terminé":
                this.besoinsTermine += 1
                break;
              case "K.O":
                this.besoinsKo += 1
                break;
              case "Récurrent":
                this.besoinsRecurrent += 1;
                break;
              default:
                break;
            }
            // Présentation
            if(elt.nbrecvpresente > elt.nbrecv) {
              this.besoinsPresentation += 1
            }
          })
        }
      )

    } else {
      this.trigBesoinCRM = this.trigramme;
      this.trigConsutant = this.trigramme;
      this.trigMesRappels = this.trigramme;
      this.setTrigBesoinCRM();
      this.setTrigConsutant();
      this.setTrigMesRappels();
    }

    // this.dservice.getUserMessages(this.user.trigramme).subscribe(
    //   (data: any) => {
    //     this.messages = data.recus;
    //     this.sentMessages = data.envoyes;
    //   }
    // )

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.selections = changes.selections.currentValue
    this.trigrammes =  this.selections.trigrammes
    this.trigrammes2 = [...this.trigrammes]
    for( let i = 0; i < this.trigrammes2.length; i++){
      if ( this.trigrammes2[i] === this.trigramme) {
          this.trigrammes2.splice(i, 1);
      }
    }

    if (this.trigrammes.length > 0) {
      this.loadTrigramme = false;
    }
    this.isLoading = (this.makeCalcul || this.loadTrigramme)
    this.loadChanged.emit(this.isLoading);

  }

  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();

  besoinCrmData: any;

  trigrammes = []
  trigrammes2 = []

  destinataires: string[] = []
  message: string = ""
  messages: any[] = [];
  sentMessages: any[] = [];

  alertMessage = "";

  @ViewChild('alertModal', { read: TemplateRef }) alertModal!: TemplateRef<any>;

  openMessageModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  openSentMessageModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' });
  }

  sendMessage() {
    if (this.message === "") {
      this.alertMessage = "Entrez un message à envoyer"
      this.modalService.open(this.alertModal, {centered: true})
    } else {
      if(this.destinataires.length === 0) {
        this.alertMessage = "Vous devez selectionner des destinnataires"
        this.modalService.open(this.alertModal, {centered: true})
      } else {
        this.dservice.sendMessage(this.trigramme, {
          destinataires: this.destinataires,
          contenu: this.message
        }).subscribe(
          data => {
            this.modalService.dismissAll();
            this.alertMessage = "Message envoyé avec succès"
            this.modalService.open(this.alertModal, {centered: true})
          },
          error => alert("Erreur lors de l'envoie du message.")
        )
      }
    }
  }


  rappelATemp = 0
  rappelEnRetard = 0
  rappelDuJour = 0
  rappelAVenir = 0

  moisGlissantBesoins = 0
  besoinsEnCours = 0
  besoinsSigne = 0
  besoinsKo = 0
  besoinsTermine = 0
  besoinsPresentation = 0
  besoinsRecurrent = 0

  trigMesRappels = "Tous";
  trigBesoinCRM  = "Tous";
  trigConsutant = "Tous";

  nouveauxConsultants = 0
  consultantsMAJ = 0
  totalConsultants = 0

  setTrigMesRappels (): void {
    this.rappelATemp = 0
    this.rappelEnRetard = 0
    this.rappelDuJour = 0
    this.rappelAVenir = 0
    let today = new Date()
    if (this.trigMesRappels === "Tous") {
      this.globalData.rappels.forEach((element: any) => {
        if ((this.datePipe.transform(element.date, 'yyyy-MM-dd') === this.datePipe.transform(today,'yyyy-MM-dd')) && element.actif === "OUI") {
          this.rappelDuJour += 1;
        }
        if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999))) && element.actif === "OUI") {
          this.rappelATemp += 1;
        }
        if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) < new Date(today.setUTCHours(0,0,0,0))) && element.actif === "OUI") {
          this.rappelEnRetard += 1;
        }
        if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999)) && new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) <= new Date(today.setDate(today.getDate() + 7)) && element.actif==="OUI") {
          this.rappelAVenir += 1;
        }
      });
    } else {
      this.dservice.getSyntheseUserRappel(this.trigMesRappels).subscribe(
        (data: any) => {
          data.forEach((element: any) => {
            if ((this.datePipe.transform(element.date, 'yyyy-MM-dd') === this.datePipe.transform(today,'yyyy-MM-dd')) && element.actif === "OUI") {
              this.rappelDuJour += 1;
            }
            if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999))) && element.actif === "OUI") {
              this.rappelATemp += 1;
            }
            if ((new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) < new Date(today.setUTCHours(0,0,0,0))) && element.actif === "OUI") {
              this.rappelEnRetard += 1;
            }
            if (new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) > new Date(today.setUTCHours(23,59,59,999)) && new Date(this.datePipe.transform(element.date, 'yyyy-MM-dd')!) <= new Date(today.setDate(today.getDate() + 7)) && element.actif==="OUI") {
              this.rappelAVenir += 1;
            }
          });
        }
      )
    }
    this.makeCalcul = false;
    this.isLoading = (this.makeCalcul || this.loadTrigramme)
    this.loadChanged.emit(this.isLoading);
  }

  setTrigConsutant (): void {
    this.nouveauxConsultants = 0
    this.consultantsMAJ = 0
    this.totalConsultants = 0
    if (this.trigConsutant === "Tous") {
      this.totalConsultants = this.globalData.totalConsultants;

      this.globalData.consultants.forEach((element: any) => {

          this.nouveauxConsultants += 1;

      });

      this.globalData.consultantMAJs.forEach((element: any) => {
          this.consultantsMAJ += 1;
      });
    } else {
      this.dservice.getSyntheseUserConsultants(this.trigConsutant).subscribe(
        (data: any) => {
          this.totalConsultants = data.totalConsultants;

          data.consultants.forEach((element: any) => {
              this.nouveauxConsultants += 1;
          });

          data.consultantMAJs.forEach((element: any) => {
              this.consultantsMAJ += 1;
          });
        }
      )
    }
    this.makeCalcul = false;
    this.isLoading = (this.makeCalcul || this.loadTrigramme)
    this.loadChanged.emit(this.isLoading);
  }

  setTrigBesoinCRM (): void {
    this.moisGlissantBesoins = 0
    this.besoinsEnCours = 0
    this.besoinsRecurrent = 0
    this.besoinsSigne = 0
    this.besoinsKo = 0
    this.besoinsPresentation = 0
    this.besoinsTermine = 0

    if (this.trigBesoinCRM === "Tous") {
      this.moisGlissantBesoins = this.besoinCrmData.length;
      this.besoinCrmData.forEach((elt: any) => {
        switch (elt.statut) {
          case "En Cours":
            this.besoinsEnCours += 1;
            break;
          case "Signé":
            this.besoinsSigne += 1
            break;
          case "Terminé":
            this.besoinsTermine += 1
            break;
          case "K.O":
            this.besoinsKo += 1
            break;
          case "Récurrent":
            this.besoinsRecurrent += 1;
            break;
          default:
            break;
        }
        // Présentation
        if(elt.nbrecvpresente > elt.nbrecv) {
          this.besoinsPresentation += 1
        }
      });
    } else {
      this.dservice.getSyntheseUserBesoin(this.trigBesoinCRM).subscribe(
        (data: any) => {
          this.moisGlissantBesoins = data.length
          data.forEach((elt: any) => {
            switch (elt.statut) {
              case "En Cours":
                this.besoinsEnCours += 1;
                break;
              case "Signé":
                this.besoinsSigne += 1
                break;
              case "Terminé":
                this.besoinsTermine += 1
                break;
              case "K.O":
                this.besoinsKo += 1
                break;
              case "Récurrent":
                this.besoinsRecurrent += 1;
                break;
              default:
                break;
            }
            // Présentation
            if(elt.nbrecvpresente > elt.nbrecv) {
              this.besoinsPresentation += 1
            }
          });
        },
        (error) => console.log(error)
      )
    }
    this.makeCalcul = false;
    this.isLoading = (this.makeCalcul || this.loadTrigramme)
    this.loadChanged.emit(this.isLoading);
  }



}
