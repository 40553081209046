<div>
    <form class="container-form">

        <div>
            <label>Techniques</label>

            <div style="height: 555px;overflow: auto;">
                <table style="border:1px solid black;text-align: left;">
                    <tbody>
                        <tr *ngFor="let tech of TagTechnique">
                            <td> <input style="width:5%; height:auto; transform: scale(1.5);" type="checkbox" class="checkbox" (change)="deleteTagTech(tech.libelle)" [checked]="tagtech === tech.libelle"> &nbsp;{{tech.libelle}}
                            </td>
                            <td style="display: flex;justify-content: space-between;align-items: center;">


                                <p>{{tech.nbreConsultant}}</p>
                                <i class="fa fa-pencil" style="font-size: 15px;cursor: pointer;" (click)="showUpdate(tech,modal);"></i>
                                <img src="/assets/consultants.png" style="cursor: pointer;" (click)="seeConsultantsBytags(tech.libelle,'tagtech.libelle','Tag Tech')" width="25" height="25" alt="">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="margin-top: 5px;">
                <input style="border:1px solid black;" type="text" placeholder="Sélectionner le tag à supprimer" id="deltech">
                <input type="submit" value="-" (click)="deleteTechByInput()" class="bubtn btn-danger">
            </div>
            <div style="margin-top: 5px;">
                <input style="border:1px solid black;" type="text" placeholder="Saisir le nouveau tag..." id="addtech">
                <input type="submit" value="+" (click)="addTech()" class="bubtn btn-success">
            </div>

        </div>

        <div>

            <label>Métiers</label>

            <div style="height: 555px;overflow: auto;">
                <table style="border:1px solid black;">
                    <tbody>
                        <tr *ngFor="let met of TagMetier">
                            <td><input style="width:5%; height:auto; transform: scale(1.5);" class="checkbox" type="checkbox" (change)="deleteTagMet(met.libelle)" [checked]="tagmet === met.libelle">&nbsp;{{met.libelle}}</td>
                            <td style="display: flex;justify-content: space-between;align-items: center;">

                                <p>{{met.nbreConsultant}}</p>

                                <i class="fa fa-pencil" style="font-size: 15px;cursor: pointer;" (click)="showUpdateMet(met,modalMet);"></i>
                                <img src="/assets/consultants.png" style="cursor: pointer;" (click)="seeConsultantsBytags(met.libelle,'tagmetier.libelle','Tag Métier')" width="25" height="25" alt="">

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="margin-top: 5px;">
                <input style="border:1px solid black;" type="text" placeholder="Sélectionner le tag à supprimer" id="delmet">
                <input type="submit" value="-" (click)="deleteMetByInput()" class="bubtn btn-danger">
            </div>
            <div style="margin-top: 5px;">
                <input style="border:1px solid black;" type="text" placeholder="Saisir le nouveau tag..." id="addmet">
                <input type="submit" value="+" (click)="addMet()" class="bubtn btn-success">
            </div>
        </div>

        <div>

            <label>Fonctions</label>

            <div style="height: 555px;overflow: auto;">
                <table style="border:1px solid black;">
                    <tbody>
                        <tr *ngFor="let fonc of TagFonction">
                            <td><input style="width:5%; height:auto; transform: scale(1.5);" class="checkbox" type="checkbox" (change)="deleteTagFonc(fonc.libelle)" [checked]="tagfonc === fonc.libelle">&nbsp;{{fonc.libelle}}</td>
                            <td style="display: flex;justify-content: space-between;align-items: center;">
                                <p>{{fonc.nbreConsultant}}</p>

                                <i class="fa fa-pencil" style="font-size: 15px;cursor: pointer;" (click)="showUpdateFonc(fonc,modalFonc);"></i>
                                <img src="/assets/consultants.png" style="cursor: pointer;" (click)="seeConsultantsBytags(fonc.libelle,'tagfonc.libelle','Tag Fonction')" width="25" height="25" alt="">

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="margin-top: 5px;font-display: flex;">
                <input style="border:1px solid black;" type="text" placeholder="Sélectionner le tag à supprimer" id="delfonc">
                <input type="submit" value="-" (click)="deleteFoncByInput()" class="bubtn btn-danger">
            </div>
            <div style="margin-top: 5px;display: flex;">
                <input style="border:1px solid black;" type="text" placeholder="Saisir le nouveau tag..." id="addfonc">
                <input type="submit" value="+" (click)="addFonc()" class="bubtn btn-success">
            </div>
        </div>

    </form>
</div>

<br><br><br><br>
<ng-template #modal class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier une Technique</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <label for="nom">Libelle</label>
                <input type="text" class="form-control" value="{{updateTechnique.libelle}}" [(ngModel)]="updateTechnique.libelle" [ngModelOptions]="{standalone: true}" />
            </div>
            <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateTechniques()">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="CancelModal()">Cancel</button>
    </div>
</ng-template>
<ng-template #modalMet class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier une Métier</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <label for="nom">Libelle</label>
                <input type="text" class="form-control" value="{{updateMet.libelle}}" [(ngModel)]="updateMet.libelle" [ngModelOptions]="{standalone: true}" />
            </div>
            <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateMets()">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="CancelModal()">Cancel</button>
    </div>
</ng-template>
<ng-template #modalFonc class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier une Fonction</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <label for="nom">Libelle</label>
                <input type="text" class="form-control" value="{{updateFonc.libelle}}" [(ngModel)]="updateFonc.libelle" [ngModelOptions]="{standalone: true}" />
            </div>
            <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateFoncs()">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="CancelModal()">Cancel</button>
    </div>
</ng-template>