<div class="container-fluid my-4">
    <div class="card" style="background-color:#EEF6FC;">
        <div class="card-body">

            <div class="row">
                <div class="col-md-4">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">Nom du Client </label>
                    <input class="form-control" class="form-control" id="nom" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [value]="nomClient " disabled>
                </div>
                <div class="col-md-4">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">Nom Prenoms </label>
                    <input class="form-control" class="form-control" id="prenom" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="nomContact" disabled>
                </div>
                <div class="col-md-4">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;"> Description Besoins </label>
                    <input class="form-control" class="form-control" id="desc" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="besoin" disabled>
                </div>
            </div>

            <div class="row mt-2">

                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;"> Statut </label>
                    <input class="form-control" [value]="statut" type="text" [(ngModel)]="besoin" disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">Date</label>
                    <input class="form-control" type="text" [(ngModel)]="date" disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">AO</label>
                    <img (click)="consultAO()" *ngIf="ao!=='non renseigné'&& ao!==''" width="35" src="../../assets//oeil.jpg" style="cursor: pointer;width: 100%;width: 30px;text-align: center;" alt="">
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;"> IdC </label>
                    <input class="form-control" id="indice" (mouseenter)="this.proncate_in($event,$event.target.value)" (mouseleave)="this.proncate_out($event)" type="text" [(ngModel)]="ic" disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">Commercial </label>
                    <input class="form-control" type="text" [(ngModel)]="commercial" disabled>
                </div>
                <div class="col-md-2">
                    <label style="font-weight: bold;margin:0px;font-size: 15px;">Gest. RH </label>
                    <input class="form-control" type="text" [(ngModel)]="gestionnairerh" disabled>
                </div>

            </div>

        </div>
    </div>
</div>

<div style="display: flex;flex-direction: column;width: 100%;">
    <p style="font-size: 20px; font-weight: bolder;color: red;margin: 0;text-align: center;">Consultants trouvés : <span style="color: #ff0000">{{totalSize}}</span>
        <span style="color: black"> / </span>
        <span style="color: #ff0000">{{totalConsultants}}</span>
    </p>
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: row;">
        <p style="color:red;font-size: 15px;text-align: center;font-weight: bold;">{{mottext}}</p>
        <button *ngIf="showfilter" style="border: none;background-color: transparent;margin-top: -18px;margin-left: 10px;" (click)="resetFilter()">
    <img width="30" src="../../assets/filter.png" alt="">
  </button>
    </div>
    <div style="background-color: #ddeeff;margin-bottom: 10px;display: flex;justify-content: center;align-items: center;flex-direction: row;justify-content: space-evenly;">
        <div style="position:relative;" class="mt-3">

            <div style="padding-top:2px ;padding-bottom: 5px;padding-right: 10px;border:1px solid black;cursor: pointer;display: flex;justify-content: space-between; width: 250px;height: auto;align-items: center;">
                <p (click)="opentechchoice()" style="font-weight: bold;margin: 0;width: 100%;text-align: center;">Filtres TagTech</p>
                <img [ngClass]="{'rotateicon': techopen }" (click)="opentechchoice()" width="25" height="15" src="../../assets/arrow.png" alt="">
            </div>
            <p style="color:red;font-size: 15px;text-align: center;font-weight: bold;">{{techtext}}</p>

            <div style="margin-right: 100px;background-color: white;position: absolute;">

                <div *ngIf="techopen" style="height: 355px;overflow: auto;border:1px solid black;width: 260px;
      left: -0px;
      top: -20px;
      position: absolute;
      z-index: 555556565;
      background-color: white;
      padding: 5px;" class="popup">
                    <div style="background-color: white;">
                        <ul>
                            <li *ngFor="let tag of TagTechnique">
                                <div style="font-size: 12px;padding: 0;margin: 0;display:flex;justify-content: space-between;">
                                    <div style="width: 100%;">
                                        <input style="margin-left: 4px;margin-right: 6px;width:5%; height:auto; transform: scale(1.5);" class="checkbox" type="checkbox" (change)="updatetech(decode(tag.libelle))" [checked]="tech.includes(decode(tag.libelle))=== true">&nbsp;{{decode(tag.libelle)}}

                                    </div>
                                    <p>{{tag.nbreConsultant}}</p>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
        <div style="position:relative;" class="mt-3">

            <div (click)="opentechchoice2()" style="padding-top:2px ;padding-bottom: 5px;padding-right: 10px;border:1px solid black;cursor: pointer;display: flex;justify-content: space-between; width: 250px;height: auto;align-items: center;">
                <p style="font-weight: bold;margin: 0;width: 100%;text-align: center;">Filtres Tagmétier</p>
                <img [ngClass]="{'rotateicon': metopen }" width="25" height="15" src="../../assets/arrow.png" alt="">
            </div>
            <p style="color:red;font-size: 15px;text-align: center;font-weight: bold;">{{mettext}}</p>

            <div style="margin-right: 100px;background-color: white;position: absolute;">


                <div *ngIf="metopen" style="height: 355px;overflow: auto;border:1px solid black;width: 260px;
      left: -0px;
      top: -20px;
      position: absolute;
      z-index: 555556565;
      background-color: white;

      padding: 5px;" class="popup">
                    <div style="background-color: white;">
                        <ul>
                            <li *ngFor="let tag of TagMetier">
                                <div style="font-size: 12px;padding: 0;margin: 0;display: flex;justify-content: space-between;">
                                    <div style="width: 100%;">
                                        <input autocomplete="off" style="margin-left: 4px;margin-right: 6px;width:5%; height:auto; transform: scale(1.5);" class="checkbox" type="checkbox" (change)="updatemet(tag.libelle)" [checked]="met.includes(tag.libelle)=== true">&nbsp;{{tag.libelle}}

                                    </div>
                                    <p>{{tag.nbreConsultant}}</p>

                                </div>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
        <div style="position:relative;" class="mt-3">

            <div (click)="opentechchoice3()" style="padding-top:2px ;padding-bottom: 5px;padding-right: 10px;border:1px solid black;cursor: pointer;display: flex;justify-content: space-between; width: 250px;height: auto;align-items: center;">
                <p style="font-weight: bold;margin: 0;width: 100%;text-align: center;">Filtres TagFonction</p>
                <img [ngClass]="{'rotateicon': foncopen }" width="25" height="15" src="../../assets/arrow.png" alt="">
            </div>
            <p style="color:red;font-size: 15px;text-align: center;font-weight: bold;">{{fonctext}}</p>

            <div style="margin-right: 100px;background-color: white;position: absolute;">

                <div *ngIf="foncopen" style="height: 355px;overflow: auto;border:1px solid black;width: 260px;
      left: -0px;
      top: -20px;
      position: absolute;
      z-index: 555556565;
      background-color: white;

      padding: 5px;" class="popup">
                    <div style="background-color: white;">
                        <ul>
                            <li *ngFor="let tag of TagFonction">
                                <div style="font-size: 12px;padding: 0;margin: 0;display: flex;justify-content: space-between;">
                                    <div style="width: 100%;">

                                        <input autocomplete="off" style="margin-left: 4px;margin-right: 6px;width:5%; height:auto; transform: scale(1.5);" class="checkbox" type="checkbox" (change)="updatefonc(tag.libelle)" [checked]="fonc.includes(tag.libelle)=== true">&nbsp;{{tag.libelle}}
                                    </div>
                                    <p>{{tag.nbreConsultant}}</p>

                                </div>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>

        <div (click)="SearchTriFiltrage()" style="border: 3px solid lightgreen;cursor: pointer;padding: 2px;">
            <img width="30" height="30" src="../../assets/blankfilter.png" alt="">
        </div>
        <div (click)="resetFilter()" style="border: 3px solid orange;cursor: pointer;padding: 2px;">
            <img width="30" height="30" src="../../assets/filter.png" alt="">
        </div>

    </div>

</div>

<div style=" height: 80vh;" class="table-container">
    <table id="element" style="position:relative;margin: auto;width: 100%;">

        <thead>
            <tr>
                <th scope="col" class="score">
                    <div class="input" id="score">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Score</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="scoreFilter">
                        </mat-form-field>
                        <div (click)='sort("score")' class="sortbtn">
                            <img *ngIf="this.sortBy!='score'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!scoredesc && this.sortBy=='score'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id=' up ' alt="">
                            <img *ngIf="scoredesc && this.sortBy=='score'" style="background-color: #6ab38c;" id='desc ' width="25" src="../assets/desc.png" id='up ' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="qualite">
                    <div class="input" id="qualite">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Qualité</mat-label>
                            <select matNativeControl [(ngModel)]="qualitecv" (ngModelChange)="SearchTriFiltrage()">
                              <option ></option>
                              <option *ngFor='let option of this.Quali' [value]="option.value">{{option.name}}</option>
                            </select>
                        </mat-form-field>
                        <div (click)='sort("qualite")' class="sortbtn">
                            <img *ngIf="this.sortBy!='qualite'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="nom">
                    <div class="input" id="nom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Nom</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="nomFilter">
                        </mat-form-field>
                        <div (click)='sort("nom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='nom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!nomdesc  && this.sortBy=='nom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="nomdesc && this.sortBy=='nom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="prenom">
                    <div class="input" id="prenom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Prénoms</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="prenomFilter">
                        </mat-form-field>
                        <div (click)='sort("prenom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='prenom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="titre">
                    <div class="input" id="titre">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Titre</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="titreFilter">
                        </mat-form-field>
                        <div (click)='sort("titre")' class="sortbtn">
                            <img *ngIf="this.sortBy!='titre'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="techno">
                    <div class="input" id="techno">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Technologie</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="technoFilter">
                        </mat-form-field>
                        <div (click)='sort("tech")' class="sortbtn">
                            <img *ngIf="this.sortBy!='tech'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!techdesc && this.sortBy=='tech'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="techdesc && this.sortBy=='tech'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="exp">
                    <div class="input" id="exp">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Exp</mat-label>
                            <select matNativeControl [(ngModel)]="exp" (ngModelChange)="SearchTriFiltrage()">
                              <option ></option>
                              <option *ngFor='let option of this.exps' [value]="option.value">{{option.name}}</option>
                            </select>
                        </mat-form-field>
                        <div (click)='sort("exp")' class="sortbtn">
                            <img *ngIf="this.sortBy!='exp'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!expdesc && this.sortBy=='exp'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="expdesc&& this.sortBy=='exp'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="dispo">
                    <div class="input" id="dispo">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Dispo</mat-label>
                            <select matNativeControl [(ngModel)]="disponibilite" (ngModelChange)="SearchTriFiltrage()">
                              <option ></option>
                              <option *ngFor='let option of this.Dispo' [value]="option.value">{{option.name}}</option>
                            </select>
                        </mat-form-field>
                        <div (click)='sort( "dispo") ' class="sortbtn">
                            <img *ngIf="!dispodesc" width="25" src="../assets/asc.png" id='up ' alt="">
                            <img *ngIf='dispodesc ' id='desc ' width="25" src="../assets/desc.png" id='up ' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="observation">
                    <div class="input" id="observation">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Observation</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="obsFilter">
                        </mat-form-field>
                        <div (click)='sort("obs")' class="sortbtn">
                            <img *ngIf="this.sortBy!='obs'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!obsdesc  && this.sortBy=='obs'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="obsdesc && this.sortBy=='obs'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>
                <th scope="col" class="crm">
                    <div class="input" id="crm">
                        CRM

                    </div>
                </th>

        </thead>
        <tbody style="position: relative;">
            <tr *ngFor="let item of consultants; let i = index">

                <td [ngClass]="{ 'highlight': isScoreFilter, 'default': !isScoreFilter  }" style="text-align: center;"><i [ngClass]="{ 'highlight': isScoreFilter, 'default': !isScoreFilter  }" style='font-size:15px;cursor: pointer; margin-right: 10px;' class='fa fa-pencil' (click)="View(item)"></i> {{renderScore(item.scoreFinal)}}</td>

                <td (click)="Select(item)" style="text-align: center;"><img src="./assets/{{item.qualitecv.libelle}}.png"></td>


                <td [innerHTML]="item.nom" (click)="Select(item)" [ngClass]="{ 'highlight ': isNomFilter, 'default ': !isNomFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.nom,$event)" [id]="'nom '+i">



                </td>
                <td [innerHTML]="renderPrenom(item.prenom)" (click)="Select(item)" [ngClass]="{ 'highlight': isPrenomFilter, 'default': !isPrenomFilter  }" (mouseleave)="close($event)" [id]="'prenom '+i" (mouseenter)="setdata(item.prenom,$event)">

                </td>

                <td [innerHTML]="item.titre" (click)="Select(item)" [ngClass]="{ 'highlight': isTitreFilter, 'default': !isTitreFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.titre,$event)" class="titrepx" [id]="'titre '+i"></td>
                <td [innerHTML]="item.tech" (click)="Select(item)" [ngClass]="{ 'highlight': isTechFilter, 'default': !isTechFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.tech,$event)" class="technopx" [id]="'techno '+i"></td>

                <td [innerHTML]="item.exp" [ngClass]="{ 'highlight': isExpFilter, 'default': !isExpFilter  }" (click)="Select(item)" style="text-align: center;"></td>
                <td [innerHTML]="renderDispo(item.codedispo)" [ngClass]="{ 'highlight': isDispoFilter, 'default': !isDispoFilter  }" (click)="Select(item)" style="text-align: center;"></td>
                <td [innerHTML]="item.obs" (click)="Select(item)" [ngClass]="{ 'highlight': isObsFilter, 'default': !isObsFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.obs,$event)" class="obspx" [id]="'obs '+i"></td>

                <td width="120px"><input type="checkbox" (change)="AddCv(item,$event.target.checked)" style="margin-left: 20%;width: 55px;"></td>



            </tr>

        </tbody>
    </table>
</div>


<div class="has-text-centered " style="margin: auto;display: flex;justify-content: center;margin-top: 1vw;">

    <div style="flex-grow: 1;display: flex;justify-content: center;">
        <img *ngIf="getpreviousMove()" (click)="FirstPage()" style="cursor: pointer; margin-right: 1vw;" width="25" src="../../assets/fisrtPageActive.png" alt="">
        <img *ngIf="getPrevious()" (click)="previousPage()" style="cursor: pointer; margin-right: 3vw" width="20" src="../../assets/previousPageActive.png" alt="">

        <img *ngIf="!getpreviousMove()" style="margin-right: 1vw" width="25" src="../../assets/firstPage.png" alt="">
        <img *ngIf="!getPrevious()" style="margin-right: 3vw" width="20" src="../../assets/previousPage.png" alt="">

        <input autocomplete="off" (keyup.enter)="goto($event.target.value)" [(ngModel)]="currentPage" type="text" style="width: 90px;color: red;font-weight: bold;height: 25px;"> / {{totalPage+1}}
        <img *ngIf="getNext()" (click)="NexPage()" style="cursor: pointer;margin-left: 3vw; " width="20" src="../../assets/nextPageActive.png" alt="">
        <img *ngIf="getlastpage()" (click)="LastPage()" style="cursor: pointer;margin-left: 1vw;" width="25" src="../../assets/lastPageActive.png" alt="">

        <img *ngIf="!getNext()" style="margin-left: 3vw;" width="20" src="../../assets/nextPage.png" alt="">
        <img *ngIf="!getlastpage()" style="margin-left: 1vw;" width="25" src="../../assets/lastPage.png" alt="">
    </div>
    <div style="display: flex;justify-content: center;align-items: center;">
        <p style="margin: 0;margin-right: 5px;">Par Page:</p>
        <select (change)="ChangeItemPerPage($event.target.value)" name="" id="">
            <option *ngFor="let item of pageSizeElement" [value]="item" >{{item}}</option>

          </select>
    </div>
</div>

<div style="display: flex;justify-content: center;align-items: center;width: 100%;">


    <button type="btn" (click)="SelectionCV()" id="lance">Sélection CV pour CRM</button>
    <button type="btn" (click)="RetourRecherche()" id="lance">Lancer une nouvelle Recherche</button>
    <button type="btn" (click)="Retour()" id="lance2">Fermer</button>

</div>