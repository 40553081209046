import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Tagtech } from '../Model/tagtech';
import { backEndpoint } from '../config';

const endpoint =  backEndpoint+'/tagtech';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TagtechService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getTagtech(): Observable<any> {
    return this.http.get(endpoint + '/public/all').pipe(
      map(this.extractData));
  }

  getOneTagtech(libelle): Observable<any> {
    return this.http.get(endpoint + '/public/getone/'+libelle).pipe(
      map(this.extractData));
  }

  getTagtechRMC(trigramme,qualitecv,dispo,experience, nom,prenom,titre,observation,tech,tagfoncs,tagmetiers,tagtechs,zones,dispos,exps,qualitecvs): Observable<any> {
    
    let fd = new FormData();
    fd.append('tagTech',tagtechs)
    fd.append('tagFonc',tagfoncs)
    fd.append('tagmetier',tagmetiers)
    fd.append('zone',zones)
    fd.append('qualitecv',qualitecvs)
    fd.append('exp',exps)
    fd.append('dispo',dispos)
    fd.append('qualitesearch',qualitecv)
    fd.append('nom',nom)
    fd.append('prenom',prenom)
    fd.append('titre',titre)
    fd.append('exposearch',experience)
    fd.append('tech',tech)
    fd.append('disposearch',dispo)
    fd.append('observation',observation)
 
    
    return this.http.post(endpoint + '/getTagtechwithScore/'+trigramme
      ,fd).pipe(
      map(this.extractData));
    
    }

  addTagtech(libelle: String): Observable<any> {
    return this.http.post<any>(endpoint + '/admin/add', libelle);
  }

  addTags( tagtech): Observable<any> {
    return this.http.post<Tagtech>(endpoint + '/add' , tagtech  ) ;
  }

  delTagtech(libelle: String): Observable<any> {
    return this.http.delete<any>(endpoint + `/admin/delete/${libelle}`);
  }

  Tags(request) {
    //let url = 'http://localhost:8080/tags';
    const params = request;
    return this.http.get(endpoint, { params });
  }

  UpdateTechnique(technique, id,libelle) {
    let params = new HttpParams();
    params = params.append('id', id);
   params = params.append('libelle', libelle);
   console.log(params)
    return this.http.put<any>(endpoint + '/admin/update', technique, {params});
  }
}
