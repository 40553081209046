import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { TechnologieService } from '../services/technologie.service';
import { DisponibiliteService } from '../services/disponibilite.service';
import { ClientService } from '../services/client.service';
import { Disponiblite } from '../Model/disponiblite';
import { ConsultantService } from '../services/consultant.service';
import { Technologie } from '../Model/technologie';
import { Client } from '../Model/client';
import { Router } from '@angular/router';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { MatDialog } from '@angular/material';
import { DictioService } from '../services/dictio.service';
import { Dictionnaire } from '../Model/dictionnaire';
import { MotsDictionnaire } from '../Model/mots-dictionnaire';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {Title} from "@angular/platform-browser";
import { AuthService } from '../services/auth.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { BASE_URL } from '../config';

@Component({
  selector: 'app-liste-d',
  templateUrl: './liste-d.component.html',
  styleUrls: ['./liste-d.component.scss']
})
export class ListeDComponent implements OnInit {

  Dispo: Disponiblite[];
  dis: Disponiblite=  new Disponiblite();
  dispo: Disponiblite=  new Disponiblite();
  paramDis: String;
  modalRef: BsModalRef;
  Techno: Technologie[];
  tech: Technologie;
  paramTech: String;
  updateClient: Client = new Client();
  updateDispo: Disponiblite = new Disponiblite();
  clis = '';
  disp='';
  Client: Client[];
  cli: Client;
  dispon
  paramCli: String;
  client
  dictio= new Dictionnaire();
  motsDic = new MotsDictionnaire();
  disponibilite = "";
  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();

  constructor(public ds: DisponibiliteService,
    private communicationService: CommunicationService,
    private ts: TechnologieService, private dcs: DictioService,
    private cl: ClientService,
    private cs: ConsultantService,
    private modalService: BsModalService,
    private us:AuthService,
    private titleService:Title,
    private router: Router,private dialog: MatDialog) {
    this.communicationService.Broadcast('OnChild6', 'Paramétrage des listes déroulantes');
   }
dispoReady = false;
clientReady = false;
message="Recalcul du nombre de consultants par client et par disponibilité en cours"
  ngOnInit() {
    this.loader()
    this.Client = []
    this.Dispo = []
    this.ds.calculate().subscribe((data2) => {
      if(data2===1){
        this.getDispo();
      }
    });
    this.cl.calculate().subscribe((data=>{
      if(data===1){
        this.getClient();

      }
    }))

    window.scroll(0,0);
    if(!this.us.canAdmin()){
      this.router.navigate(['/dashboard']);
    }


    this.titleService.setTitle("Portail RH")


  }

  deleteclient(param){
    (<HTMLInputElement>document.getElementById("delclient")).value=param;
    if(this.client === param){
      this.client = "";
    }
    else{
      this.client = param;
    }
  }


  deletedisponibilite(param){
    (<HTMLInputElement>document.getElementById("deldispo")).value=param;
    if(this.disponibilite === param){
      this.disponibilite = "";
    }
    else{
      this.disponibilite = param;
    }
  }

  deletedis(param){
    this.loader()
    this.message="Supression en cours"
    this.ds.delDispo(param).subscribe((data)=>{

       this.ngOnInit()
    })
   setTimeout(() => {
    this.dialog.closeAll()
    this.ngOnInit()
   }, 2000);

  }

  getDispo(only=true) {

  this.Dispo = [];
    this.ds.getRefs().subscribe((data2) => {
        this.Dispo=data2
    });
    this.dispoReady = true;

    if(only){
      if(this.dispoReady && this.clientReady){
        this.dialog.closeAll()

      }
    }else{
      this.dialog.closeAll()

    }


  }

  Modifdispo(param){
    var input = document.getElementById("inputdispo");
    var input2 = document.getElementById("save");
    var input3 = document.getElementById("discard");

    this.ds.findByLibelle(param).subscribe((result) => {
      this.dis=result;
    });

    this.paramDis = param;

    if((input.style.display==="none")&&(input2.style.display==="none")&&(input3.style.display==="none")){
      input.style.display="initial";
      input2.style.display="initial";
      input3.style.display="initial";
      (<HTMLInputElement>input).value=param;
    }
    else if ((input.style.display==="none")&&(input2.style.display==="initial")&&(input3.style.display==="initial"))
    {
      input.style.display="initial";

      (<HTMLInputElement>input).value=param;

    }
    else{
      input.style.display="none";
      input2.style.display="none";
      input3.style.display="none";
    }
  }

  Deletedispo(param){
    this.loader()
    this.message="Supression en cours"
    this.ds.delDispo(param).subscribe((result) => {
     this.getDispo(false)
    });
   this.getDispo(false)
  }
  AjouterDisponibilite() {
    this.loader()
    if(this.dispo.ordre===undefined || this.dispo.libelle===undefined || this.dispo.libelle==""|| this.dispo.ordre===null || this.dispo.libelle===null){
      this.openAlertDialog('Remplir tous les champs!!')
      this.dialog.closeAll()
    }else{

      this.ds.findByLibelle(this.dispo.libelle).subscribe((data)=>{
        if(data===null){

                this.ds.checkordre(this.dispo.ordre).subscribe((data3)=>{

                  if(data3===null){
                    this.ds.addDispo(this.dispo).subscribe((data4) => {
                      if(data4){
                        this.dispo.libelle=null;
                        this.dispo.ordre=null;


                      }

                      this.dispo.libelle=null;
                      (<HTMLInputElement>document.getElementById("libelledispo")).value = "";
                      (<HTMLInputElement>document.getElementById("ordre")).value = "";

                      this.dispo.ordre=null;

                      this.getDispo(false)
                      this.dialog.closeAll()
                    });

                  }else{
                    this.openAlertDialog("l'ordre est  déjà utilisé!!")
               }
              })
        }else{
             this.openAlertDialog('Le libellé est  déjà utilisé!!')
        }
      })
    }
  }
  addDispo(){


    var libelle= (<HTMLInputElement>document.getElementById("adddispo")).value.toLowerCase();
    if(libelle===""){
      this.openAlertWarning("Disponibilité");
    }
    else{
      this.ds.findByLibelle(libelle).subscribe((data) => {
        console.log(data);
        if(data===null) {
          this.ds.addDispo(libelle).subscribe((result) => {
            this.ngOnInit();
            (<HTMLInputElement>document.getElementById("adddispo")).value="";
          });
        } else {
          this.openAlertDialog('disponibilite existe deja');
          (<HTMLInputElement>document.getElementById("adddispo")).value="";
        }
      });

    }
  }

  getTechno() {
    this.ts.getTechnologie().subscribe((data2) => {
        this.Techno=data2
    });
  }

  Modiftechno(param){
    var input = document.getElementById("inputtechno");
    var input2 = document.getElementById("save");
    var input3 = document.getElementById("discard");

    this.ts.findByLibelle(param).subscribe((result) => {
      this.tech=result;
    });

    this.paramTech = param;

    if((input.style.display==="none")&&(input2.style.display==="none")&&(input3.style.display==="none")){
      input.style.display="block";
      input2.style.display="block";
      input3.style.display="block";
      (<HTMLInputElement>input).value=param;
    }
    else{
      input.style.display="none";
      input2.style.display="none";
      input3.style.display="none";
    }
  }

  Deletetechno(param){
    this.ts.delTechno(param).subscribe((result) => {
      this.ngOnInit();
    });
  }

  openAlertWarning(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Champs "+param+" vide",
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  addTechno(){
    var libelle= (<HTMLInputElement>document.getElementById("addtechno")).value.toLowerCase();
    var type = "Technologie";
    if(libelle===""){
      this.openAlertWarning("Technologies");
    }
    else{
      this.ts.addTechno(libelle).subscribe(data =>{
        this.dcs.findDictiobyMot(libelle).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=libelle;
            this.motsDic.typeMotDictionnaire=type;
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
              this.ngOnInit();
            })
          }
          if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction")||(result.motsDictionnaire.TypeMotDictionnaire != "Technologie"))){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = type;
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
              this.ngOnInit();
            })
          }
        });
      });
    }
  }

  getClient() {
    this.Client = []

    this.cl.getClients().subscribe((data2) => {
      if(data2){
        this.Client=data2
        this.clientReady = true;
        if(this.dispoReady && this.clientReady){
          this.dialog.closeAll()

        }
      }


    });



  }

  Modifclient(param){
    this.loader()

    var input = document.getElementById("inputclient");
    var input2 = document.getElementById("save");
    var input3 = document.getElementById("discard");

    this.cl.findByLibelle(param).subscribe((result) => {
      this.cli=result;
    });

    this.paramDis = param;

    if((input.style.display==="none")&&(input2.style.display==="none")&&(input3.style.display==="none")){
      input.style.display="initial";
      input2.style.display="initial";
      input3.style.display="initial";
      (<HTMLInputElement>input).value=param;
    }
    else if ((input.style.display==="none")&&(input2.style.display==="initial")&&(input3.style.display==="initial"))
    {
      input.style.display="initial";

      (<HTMLInputElement>input).value=param;
    }
    else{
      input.style.display="none";
      input2.style.display="none";
      input3.style.display="none";
    }
    this.dialog.closeAll()
  }

  Deleteclient(){
    this.message="Supression en cours"

    this.loader()
    let param = (<HTMLInputElement>document.getElementById("delclient")).value
    this.cl.delClient(param).subscribe((result) => {
      if(result===1){
        this.getClient();
      }
    });
  }
  DeleteDis(){
    this.message="Supression en cours"

    this.loader()

    let e = (<HTMLInputElement>document.getElementById("deldispo")).value
    this.ds.delDispo(e).subscribe((result) => {
      if(result===1){
        this.getDispo(false);
      }
    });

    (<HTMLInputElement>document.getElementById("deldispo")).value = "";


  }

  addClient(){
    this.loader()

    var libelle= (<HTMLInputElement>document.getElementById("addclient")).value.toUpperCase();
    var type = "Client";
    if(libelle===""){
      this.openAlertWarning("Clients");
    }
    else{

      this.cl.findByLibelle(libelle).subscribe((data) => {
       console.log(data)
        if(data===null) {
      this.cl.addClient(libelle).subscribe(data =>{
       this.getClient();
       (<HTMLInputElement>document.getElementById("addclient")).value="";
        this.dcs.findDictiobyMot(libelle).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=libelle;
            this.motsDic.typeMotDictionnaire=type;
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
             this.getClient();
              (<HTMLInputElement>document.getElementById("addclient")).value="";
            })
          }
          if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction")||(result.motsDictionnaire.TypeMotDictionnaire != "Technologie")||(result.motsDictionnaire.TypeMotDictionnaire != "Client"))){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = type;
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{

             this.getClient();
              (<HTMLInputElement>document.getElementById("addclient")).value="";
            })
          }
        });
      });
    }
    else {
      this.openAlertDialog('client existe deja');
      (<HTMLInputElement>document.getElementById("addclient")).value="";
    }
  });
    }
  }

  save(){
    var libelle1 = (<HTMLInputElement>document.getElementById("inputdispo")).value;

    var libelle3 = (<HTMLInputElement>document.getElementById("inputclient")).value;

    if(libelle1!="null"){
      this.ds.updateDispo(this.dis._id,libelle1).subscribe((result) => {
        this.ngOnInit();
        document.getElementById("inputdispo").style.display="none";
      });
    }



    if(libelle3!="null"){
      this.cl.updateClient(this.cli._id,libelle3).subscribe((result) => {
        this.ngOnInit();
        document.getElementById("inputclient").style.display="none";
      });
    }

  }

  discard(){
    this.router.navigate(['/Liste']);
  }
  showUpdateDispo(item, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

    this.updateDispo = item;
    this.disp = item.libelle;

  }
  showUpdateClient(item, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

    this.updateClient = item;
    this.clis = item.libelle.toUpperCase();

  }
  updateClients() {
    this.message="Mise à jour des consultants concernés"
    this.loader()
    this.CancelModal();
    this.cl.findByLibelle(this.updateClient.libelle.toUpperCase()).subscribe((data) => {
      if(data===null){
        this.updateClient.libelle = this.updateClient.libelle.toUpperCase();
        this.cl
          .updateClients(this.updateClient,this.updateClient._id,this.clis)
          .subscribe((data) => {
            if(data===1){

              this.getClient()
            }

          });
      }else{
        this.openAlertDialog("Le Client existe déjà")
        this.getClient()
      }
    })



  }

  openAlertDialog(msg) {

   window.scrollTo(0,0)


    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll()


    });

  }
  updateDispos() {

this.CancelModal();


this.loader()
    this.message="Mise à jour des consultants concernés"

      if(this.updateDispo.libelle===""){
        this.openAlertWarning("Disponibilité");
      }
      else{
        this.ds.findByLibelle(this.updateDispo.libelle).subscribe((data) => {

          if(data===null) {
            this.ds
              .updateDispos(this.updateDispo,this.updateDispo._id,this.disp)
              .subscribe((data) => {
                if(data===1){
                  this.getDispo(false)

                }


              });

          } else {
            this.openAlertDialog('disponibilite existe deja');

          }
        });

      }
  }
  CancelModal() {
    this.modalRef.hide();
  }
  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message:this.message

      },
      disableClose: true,
    });
    this.message='Chargement en Cours'
  }

  changeOrdre(e){
    this.dispo.ordre=e;
  }
  changeLibelle(e){
    this.dispo.libelle=e;
  }

  seeConsultantsBytags(tag,type,name){

    window.localStorage.setItem("tag",tag);
    window.localStorage.setItem("tagname",name);
    window.localStorage.setItem("tagtype","client.libelle");
    window.open(BASE_URL+"/consultantsbytags")



   }
}
