import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { TagtechService } from '../services/tagtech.service';
import { Tagtech } from '../Model/tagtech';
import { TagmetService } from '../services/tagmet.service';
import { TagfoncService } from '../services/tagfonc.service';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { MatDialog } from '@angular/material';
import { DictioService } from '../services/dictio.service';
import { MotsDictionnaire } from '../Model/mots-dictionnaire';
import { Dictionnaire } from '../Model/dictionnaire';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Tagmet } from '../Model/tagmet';
import { Tagfonc } from '../Model/tagfonc';
import {Title} from "@angular/platform-browser";
import {RechercheService} from "../services/recherche.service"
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { BASE_URL } from '../config';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  TagTechnique : Tagtech[];
  updateTechnique: Tagtech = new Tagtech();
  updateMet: Tagmet = new Tagmet();
  updateFonc: Tagfonc = new Tagfonc();
  technique: string = '';
  met: string = '';
  fonc: string = '';
  message= `Recalcul du nombre de consultants par tag en cours`;
  TagMetier = [];
  TagFonction = [];

  motsDic = new MotsDictionnaire();

  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();
  modalRef: BsModalRef;
  tagtech: String = "";
  tagmet: String = "";
  tagfonc: String = "";
  loadtech=false;
  loadmet=false;
  loadfonc=false;
  constructor(private tt: TagtechService,
    private tm: TagmetService,
    private us:AuthService,private router: Router,
    private tf: TagfoncService,private dcs: DictioService,private rs:RechercheService,
    public communicationService: CommunicationService,
    private titleService:Title,private dialog: MatDialog, private modalService: BsModalService) {

      this.communicationService.Broadcast('OnChild5', 'Paramètrage des Tags');
   }

  ngOnInit() {
    this.loader()
    window.scroll(0,0);
    if(!this.us.canAdmin()){
      this.router.navigate(['/dashboard']);
    }
    this.titleService.setTitle("Portail RH")
    window.scroll(0,0);
    this.countconsultant()
  }

  //Fonction de calcul du nmbre de consultants par tags
  countconsultant(){
    this.rs.calculate().subscribe((data2)=>{
      if(data2===1){
        this.getTags();
      }
    })
  }

  //Récupération de la liste des tags 
  getTags(){
    this.tt.getTagtech().subscribe((data2) => {
      if(data2){
        this.TagTechnique = data2;
        this.loadtech = true;
    this.closeLoader()
        
      }
      
      }
    );
    this.tm.getTagmet().subscribe((data2) => {
      if(data2){
        this.TagMetier=data2;
        this.loadmet=true;
    this.closeLoader()

      }
          });
    this.tf.getTagfonc().subscribe((data2) => {
      if(data2){
        this.TagFonction=data2;
        this.loadfonc=true;
    this.closeLoader()

      }
    });
  }

closeLoader(){
  if(this.loadfonc && this.loadmet && this.loadtech){
    this.dialog.closeAll()
  }
}
//Chargement des information du tag tech pour la modification
  deleteTagTech(param){
    (<HTMLInputElement>document.getElementById("deltech")).value=param;
    if(this.tagtech === param){
      this.tagtech = "";
    }
    else{
      this.tagtech = param;
    }
  }

  //Suppression du ta Tech
  deleteTechByInput(){
    this.message="Supression en cours"
    this.loader()
    var param = (<HTMLInputElement>document.getElementById("deltech")).value;
    if(param===""){
      this.AlertWarning("Technique");
    }else{
      this.tt.delTagtech(param).subscribe(data =>{
        if(data===1){
          this.getTags()
        }
        (<HTMLInputElement>document.getElementById("deltech")).value="";
      });
    }
    
  }

  //Ajout d'un Tag tech
  addTech(){
    var param = (<HTMLInputElement>document.getElementById("addtech")).value.toUpperCase();

    if(param===""){
      this.openAlertWarning("Technique");
      
    }else{
      this.tt.addTagtech(param).subscribe(data =>{
    
	if(data!==null){
    this.TagTechnique.push(data)
        this.dcs.findDictiobyMot(param).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=param;
            this.motsDic.typeMotDictionnaire="Tag Technique";
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
              this.ngOnInit();
            })
          }
          if((result != null)&&(result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = "Tag Technique";
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
              this.ngOnInit();
            })
          }
        });
}
if(data === null) {
this.openAlertDialog("ce tag technique existe deja");
}
      });
      (<HTMLInputElement>document.getElementById("addtech")).value="";
    }
    
  }

  openAlertWarning(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Champs Tag "+param+" vide",
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  AlertWarning(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Aucun Tag "+param+" selectionné",
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteTagMet(param){
    (<HTMLInputElement>document.getElementById("delmet")).value=param;
    if(this.tagmet === param){
      this.tagmet = "";
    }
    else{
      this.tagmet = param;
    }

  }

  deleteMetByInput(){
    this.message="Supression en cours"
    this.loader()
    var param = (<HTMLInputElement>document.getElementById("delmet")).value.toUpperCase();
    if(param===""){
      this.AlertWarning("Métier");
    }else{
      this.tm.delTagmet(param).subscribe(data =>{
        if(data===1){
          this.getTags()
        }
        (<HTMLInputElement>document.getElementById("delmet")).value="";
      });
    }
    
  }

  addMet(){
    var param = (<HTMLInputElement>document.getElementById("addmet")).value.toUpperCase();
    if(param===""){
      this.openAlertWarning("Métier");
    }else{
      this.tm.addTagmet(param).subscribe(data =>{
        console.log(data)
if(data!==null){
  this.TagMetier.push(data)
        this.dcs.findDictiobyMot(param).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=param;
            this.motsDic.typeMotDictionnaire="Tag Metier";
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
              this.ngOnInit();
            })
          }
          if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier"))){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = "Tag Metier";
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
              this.ngOnInit();
            })
          }
        });
}
if(data===null) {
this.openAlertDialog("ce tag met existe deja");
}
      });
      (<HTMLInputElement>document.getElementById("addmet")).value=null;
    }
  }

  deleteTagFonc(param){
    

    (<HTMLInputElement>document.getElementById("delfonc")).value=param;
    if(this.tagfonc === param){
      this.tagfonc = "";
    }
    else{
      this.tagfonc = param;
    }
  }

  deleteFoncByInput(){
    this.message="Supression en cours"
    this.loader()
    var param = (<HTMLInputElement>document.getElementById("delfonc")).value.toUpperCase();
    if(param===""){
      this.AlertWarning("Fonction");
    }else{
      this.tf.delTagfonc(param).subscribe(data =>{
        if(data===1){
          this.getTags()
        }
        (<HTMLInputElement>document.getElementById("delfonc")).value="";
      });
    }
   
  }

  addFonc(){
    var param = (<HTMLInputElement>document.getElementById("addfonc")).value.toUpperCase();
   // param = 
    if(param===""){
      this.openAlertWarning("Fonction");
    }else{
      this.tf.addTagfonc(param).subscribe(data =>{
if(data!==null){
  this.TagFonction.push(data)
        this.dcs.findDictiobyMot(param).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=param;
            this.motsDic.typeMotDictionnaire="Tag Fonction";
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
              this.ngOnInit();
            })
          }
          if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction"))){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = "Tag Fonction";
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
              this.ngOnInit();
            })
          }
        });
}
if(data===null) {
this.openAlertDialog("ce tag fonc existe deja");
}
      });
      (<HTMLInputElement>document.getElementById("addfonc")).value=null;
    }
  }

  //Affichage de la popup de modification d'un tag
  showUpdate(item, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    
    this.updateTechnique = item;
    this.technique = item.libelle;
  
  }
  showUpdateMet(item, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    
    this.updateMet = item;
    this.met = item.libelle;
  
  }
  showUpdateFonc(item, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    
    this.updateFonc = item;
    this.fonc = item.libelle;
  
  }
  CancelModal() {
    this.modalRef.hide()
  }

  //Modification des tags avec le calcul du nombre de consultant
  updateTechniques() {
   
    this.CancelModal()
    this.message="Mise à jour des consultants concernés"
    this.loader()
    this.updateTechnique.libelle=this.updateTechnique.libelle.toUpperCase()
    this.tt.getOneTagtech(this.updateTechnique.libelle).subscribe(data=>{
      if(data===null){
        this.tt
        .UpdateTechnique(this.updateTechnique,this.updateTechnique._id,this.technique)
        .subscribe((data) => {
          if(data===1){
            this.getTags()
          }
        });
      }else{
        this.openAlertDialog("Le Tag existe déjà")
      }
    })

  }
  updateMets() {
    this.CancelModal()
    this.message="Mise à jour des consultants concernés"
    this.loader()
    this.updateMet.libelle=this.updateMet.libelle.toUpperCase()
   this.tm.getOneTagmet(this.updateMet.libelle).subscribe(data=>{
     if(data===null){

      this.tm
      .UpdateTechnique(this.updateMet,this.updateMet._id,this.met)
      .subscribe((data) => {
        if(data===1){
          this.getTags()
        }
       
      });
     }else{
       this.openAlertDialog("Le tag existe déjà")
     }
   })
  }
  updateFoncs() {
    this.CancelModal()
    this.message="Mise à jour des consultants concernés"
    this.loader()
    this.updateFonc.libelle=this.updateFonc.libelle.toUpperCase()
    this.tf.getOneTagfonc(this.updateFonc.libelle).subscribe(data=>{
      if(data===null){

        this.tf
        .UpdateTechnique(this.updateFonc,this.updateFonc._id,this.fonc)
        .subscribe((data) => {
          if(data===1){
            this.getTags()
          }
          
        });
      }else{
        this.openAlertDialog("Le tag existe déjà")
      }
    })
  }

  loader(){
    
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
       
        message:this.message
        
      },
      disableClose: true,
    });
    this.message="Chargement en Cours"
  }

  openAlertDialog(msg) {

    window.scrollTo(0,0)
   
  
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       data: {
         message: msg,
         buttonText: {
           cancel: 'OK'
         }
       },
     });
 
     dialogRef.afterClosed().subscribe(result => {
       this.dialog.closeAll()
       this.getTags()
     
      
     });
   
   }

   //Ouverture de la liste des consutants par tags
   seeConsultantsBytags(tag,type,name){
    window.localStorage.setItem("tag",tag);
    window.localStorage.setItem("tagtype",type);
    window.localStorage.setItem("tagname",name);
    window.open(BASE_URL+"/consultantsbytags")



    

   }
}
