<div class="container-fluid" *ngIf="!isLoading">
    <iframe [src]="filedata"></iframe>
    <a [href]="filedata">test</a>
</div>

<ng-template #factureModal let-modal class="fade" role="dialog" style="border:1px solid black">
    <div class="modal" draggable="true">
        <div class="modal-header text-center" style="background-color: whitesmoke;">
            <h5 class="text-center">Eléments de paiement consultant</h5>
        </div>
        <div class="modal-body p-4" style="background-color: whitesmoke;">
            <form #genfactureForm="ngForm" (ngSubmit)="genererFacture()">
                <div class="form-group">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Consultant</label>
                    <div class="row mb-1">
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" name="nomco" [(ngModel)]="eltfacture.nomconsultant" disabled>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" name="prenomco" [(ngModel)]="eltfacture.prenomconsultant" disabled>
                        </div>
                    </div>
                    <input type="text" placeholder="Téléphone" class="form-control form-control-sm mb-1" name="telco" [(ngModel)]="eltfacture.emailconsultant" required>
                    <input type="email" placeholder="Email" class="form-control form-control-sm mb-1" name="emailco" [(ngModel)]="eltfacture.emailconsultant">
                    <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="adreco" placeholder="Description" [(ngModel)]="eltfacture.adresseconsultant"></textarea>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="eltfacture.paiementsociete" disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="rc" placeholder="RC" [(ngModel)]="eltfacture.paiementrc" disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="eltfacture.paiementmf" disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA" [(ngModel)]="eltfacture.paiementtva" disabled>
                </div>
                <div class="form-group">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                    <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="eltfacture.contratcadre" disabled>
                </div>
                <div class="form-group">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                    <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="eltfacture.contrat" disabled>
                </div>
                <div class="form-group">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Informations bancaires</label>
                    <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="eltfacture.paiementtitulaire" required disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="eltfacture.paiementiban" required disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="eltfacture.paiementbic" required disabled>
                    <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="eltfacture.paiementdomiciliation" required disabled>
                    <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="eltfacture.paiementadresse" disabled></textarea>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre J travaillés</label>
                            <input type="number" class="form-control form-control-sm" name="jt" [(ngModel)]="eltfacture.totaljourtravaille" required disabled>
                        </div>
                        <div class="col-md-6">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre astreintes</label>
                            <input type="number" class="form-control form-control-sm" name="astreintes" [(ngModel)]="eltfacture.nbreastreintes" required disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">TJM client</label>
                            <input type="number" class="form-control form-control-sm" name="tjmconsultant" [(ngModel)]="eltfacture.tjmcconsultant" required disabled>
                        </div>
                        <div class="col-md-6">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Tarif unité astreinte</label>
                            <input type="number" class="form-control form-control-sm" name="tarifastreinte" [(ngModel)]="eltfacture.tarifastreinteconsultant" required disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total HT</label>
                            <input type="number" class="form-control form-control-sm" name="totalHt" #htinput [value]="eltfacture.tjmconsultant*eltfacture.totaljourtravaille + eltfacture.tarifastreinteconsultant*eltfacture.nbreastreintes" disabled>
                        </div>
                        <div class="col-md-4">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TVA</label>
                            <input type="number" class="form-control form-control-sm" name="ttva" [(ngModel)]="eltfacture.totaltva" required #tvainput>
                        </div>
                        <div class="col-md-4">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TTC</label>
                            <input type="number" class="form-control form-control-sm" name="tarifastreinte" [value]="htinput.value + tvainput.value" disabled>
                        </div>
                    </div>
                    <div>
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Numéro facture</label>
                        <input type="text" class="form-control form-control-sm mt-1" name="numf" placeholder="" [(ngModel)]="eltfacture.numerofacture" required>
                    </div>
                    <div>
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Date facturation</label>
                        <input type="date" class="form-control" name="datefact" placeholder="Date de fin" [min]="today" title="Date facturation" [(ngModel)]="eltfacture.datefacturation" required>
                    </div>
                    <div>
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Echéance paiement</label>
                        <input type="date" class="form-control" placeholder="Echéance" name="echeancepaiement" [min]="eltfacture.datefacturation" title="Echéance" [(ngModel)]="eltfacture.echeancepaiement" required>
                    </div>
                    <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="desc" placeholder="Description" [(ngModel)]="eltfacture.activite"></textarea>
                </div>

                <div class="d-flex justify-content-center mt-4">
                    <button type="submit" class="btn btn-success mr-4" [disabled]="!genfactureForm.valid">Valider la facture</button>
                    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>