import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backEndpoint } from '../config';

const endpoint =  backEndpoint+'/';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  constructor(private http: HttpClient) { }

  pushFileToStorage(file: File, date: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', file);
    formdata.append('date', date);
    const req = new HttpRequest('POST',  backEndpoint+'/mod/post', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  getFiles(): Observable<any> {
    return this.http.get('/mod/getallfiles');
  }

  deleteFile(filename:String, id:String): Observable<any> {
    return this.http.delete<any>(endpoint + `mod/delete/${filename}/${id}`);
  }

}
