import { ConsultantProfile } from './../models/consultant-profile';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "./../../Model/user";
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChildren,
  QueryList,
  ElementRef,
  TemplateRef,
  AfterContentInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsultantService } from "../../services/consultant.service";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
} from "@angular/forms";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AlertDialogComponent } from "../../alert-dialog/alert-dialog.component";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DisponibiliteService } from "../../services/disponibilite.service";
import { QualiteService } from "../../services/qualite.service";
import { DatePipe } from "@angular/common";
import { TechnologieService } from "../../services/technologie.service";
import { CommunicationService } from "../../services/communication.service";
import { ActionsService } from "../../services/actions.service";
import { Rappels } from "../../Model/rappels";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { Tagtech } from "../../Model/tagtech";
import { Tagmet } from "../../Model/tagmet";
import { Tagfonc } from "../../Model/tagfonc";
import { ClientService } from "../../services/client.service";
import { TagtechService } from "../../services/tagtech.service";
import { TagfoncService } from "../../services/tagfonc.service";
import { TagmetService } from "../../services/tagmet.service";
import { RappelsService } from "../../services/rappels.service";
import * as _ from "lodash";
import { UploadFileService } from "../../upload/upload-file.service";
import { CV } from "../../Model/cv";
import { CvService } from "../../services/cv.service";
import { AlertEmptyComponent } from "../../alert-empty/alert-empty.component";
import { RefRechercheService } from "../../services/ref-recherche.service";
import { Technologie } from "../../Model/technologie";
import { Client } from "../../Model/client";
import { Observation } from "../../Model/observation";
import { DictioService } from "../../services/dictio.service";
import { Dictionnaire } from "../../Model/dictionnaire";
import { MotsDictionnaire } from "../../Model/mots-dictionnaire";
import { TokenStorageService } from "../../services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { ProgressSpinnerDialogComponent } from "../../progress-spinner-dialog/progress-spinner-dialog.component";
import { flattenDiagnosticMessageText } from "typescript";
import { backEndpoint } from "../../config";
import * as fileSaver from "file-saver";
import { AuthService } from "src/app/services/auth.service";
import { PopupdataComponent } from "src/app/popupdata/popupdata.component";
import { ConsultantProfileService } from "src/app/services/consultants/consultant-profile.service";

export interface Element {
  _id: string;
  action: string;
  date: string;
  trigramme: string;
}

const ELEMENT_DATA: Element[] = [];

function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}

@Component({
  selector: "app-experiences-consultant",
  templateUrl: "./experiences-consultant.component.html",
  styleUrls: ["./experiences-consultant.component.scss"],
})
export class ExperiencesConsultantComponent implements OnInit {

  clientConsultantData2: { client: any };


  @HostListener("window:keyup", ["$event"]) Getregex(event: any) {
    if (event.target.id === "regTag") {
      this.regex = event.target.value;
      this.getTagTechPage({
        page: "0",
        size: this.size,
        regex: this.regex,
      });
    }
    if (event.target.id === "regtechn") {
      this.regexTechno = event.target.value;
      this.getTechnoPage({
        page: "0",
        size: this.sizeTechno,
        regex: event.target.value,
      });
    }
    if (event.target.id === "client") {
      this.regexClient = event.target.value;
      this.getClientPage({
        page: "0",
        size: this.sizeClient,
        regex: event.target.value,
      });
    }
    if (event.target.id === "metier") {
      this.regexMetier = event.target.value;
      this.getMetierPage({
        page: "0",
        size: this.sizeMetier,
        regex: event.target.value,
      });
    }
    if (event.target.id === "fonction") {
      this.regexFonction = event.target.value;
      this.getFonctionPage({
        page: "0",
        size: this.sizeFonction,
        regex: event.target.value,
      });
    }
  }

  TagTechTabFilter: Tagtech[] = [];
  TechnoTabFilter: Technologie[] = [];
  ClientTabFilter: Client[] = [];
  MetierTabFilter: Tagmet[] = [];
  FonctionTabFilter: Tagfonc[] = [];

  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  addresse = "";
  addresse2 = "";
  linkedindata = "";
  private sub: any;
  titree: string;
  name: string;
  ddm: string;
  ddm2: string;
  consultant: any;
  user: any = [];
  rappel = new Rappels();
  initcv = new CV();
  tabcv: any = [];
  idcv: String;
  filename: string;
  modalRef: BsModalRef;

  isModerateur = false;

  dictio = new Dictionnaire();
  motsDic = new MotsDictionnaire();

  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();

  registerForm: FormGroup;
  bodyText: string;
  submitted = false;

  //Récupération de tous
  Dispo = [];
  Qualite = [];
  Tech = [];
  Clients = [];
  TagTech = [];
  TagMetier = [];
  TagFonc = [];

  //Checkbox
  Techno = [];
  Cli = [];
  Tag1 = [];
  Tag2 = [];
  Tag3 = [];

  trigramme: string = "";
  userid: string = "";
  roles: string[] = [];

  profileid: string = "";

  John = "aaaa";
  nom = "";
  prenom = "";
  oldrappel = new Rappels();
  oldrappel2 = new Rappels();
  titre = "";
  tjm = "";
  dis = "";
  observation = [];
  quali = "";
  telp = "";
  email = "";
  linkedin = "";
  code = "";
  ville = "";
  cv = "";
  dmaj = "";
  green = "red";
  obs = "";
  tech = [];
  client = [];
  tagtech = [];
  tagmetier = [];
  tagfonc = [];
  dda = "";
  exp = "";

  data = Object.assign( ELEMENT_DATA);
  dataSource = new MatTableDataSource(this.data);

  orange = "orange";
  closeResult: string;
  updaterappel;
  myDate = new Date();
  newDate = new Date().getFullYear();
  dateDda = new Date();
  today = new Date().toJSON().split("T")[0];

  expanded1 = false;
  expanded2 = false;
  expanded3 = false;
  expanded4 = false;
  expanded5 = false;
  test: string;

  //CV propriété :
  days = new Date().getDate().toString();
  month = new Date().getMonth().toString();
  year = new Date().getFullYear().toString();
  hours = new Date().getHours().toString();
  minutes = new Date().getMinutes().toString();
  secondes = new Date().getSeconds().toString();

  /*  TagTech Pagination */
  libelle: string = "Ancunes Technologies";
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  tagTechUpdate: Tagtech = new Tagtech();
  totalElements: number = 0;
  tagTechPage: Tagtech[] = [];
  loading: boolean;
  suivant = 0;
  ordre = 2;
  dispoordre = 2;
  size: number = 25;
  totalPage: number;
  regex: string = "";
  TagTechPageUpdate: Tagtech[] = [];
  TagTechFilter: Tagtech[] = [];
  //isChecked: boolean = false;
  forceupdate = false;
  /* Techno Pagination */
  technoUpdate: Technologie = new Technologie();
  totalElementsTechno: number = 0;
  TechnoPage: Technologie[] = [];
  loadingTechno: boolean;
  suivantTechno = 0;
  sizeTechno: number = 25;
  totalPageTechno: number;
  regexTechno: string = "";
  TechnoPageUpdate: Technologie[] = [];
  TechnoFilter: Technologie[] = [];

  updated = false;
  /*  Client Pagination */
  clientUpdate: Client = new Client();
  totalElementsClient: number = 0;
  clientPage: Client[] = [];
  loadingClient: boolean;
  suivantClient = 0;
  sizeClient: number = 25;
  totalPageClient: number;
  dispolibelle = "";
  regexClient: string = "";
  ClientPageUpdate: Client[] = [];
  ClientFilter: Client[] = [];

  /*  Fonc Pagination */
  FonctionUpdate: Tagfonc = new Tagfonc();
  totalElementsFonction: number = 0;
  fonctionPage: Tagfonc[] = [];
  loadingFonction: boolean;
  suivantFonction = 0;
  sizeFonction: number = 25;
  totalPageFonction: number;
  regexFonction: string = "";
  FonctionPageUpdate: Tagfonc[] = [];
  FonctionFilter: Tagfonc[] = [];

  /*  metier Pagination */
  MetierUpdate: Tagmet = new Tagmet();
  totalElementsMetier: number = 0;
  metierPage: Tagmet[] = [];
  loadingMetier: boolean;
  suivantMetier = 0;
  sizeMetier: number = 25;
  totalPageMetier: number;
  regexMetier: string = "";
  MetierPageUpdate: Tagmet[] = [];
  MetierFilter: Tagmet[] = [];
  oldobs: String = "";
  csdata;

  date: string =
    this.year +
    this.month +
    this.days +
    "_" +
    this.hours +
    this.minutes +
    this.secondes;

  @Input() consultantData : ConsultantProfile = { id: '', userid: '', consultantid: '', nom: '', prenom: '', codedispo:{},
    tjm: '', telephone_principal: '', email: '',  linkedin: '', postal: '',
    ville: '', cv: [], dateDerniereMaj: '', client: [], tagtech: [],
    tagmetier: [], tagfonc: [],dda: '',
    competencestechnos: [],
    competencesactivites: [],
    competencesclients: [],
    competencessecteurs: [],
  };

  competencestechnos: any[] = [];
  competencesactivites: any[] = [];
  competencesclients: any[] = [];
  competencessecteurs: any[] = [];

  @Input() consultantData3 = { id: '', titre: '',codedispo:{},

    cv: [], client: [],  tagtech: [],
  tagmetier: [], tagfonc: []};
  @Input() consultantData2 = { id: '', titre: '',codedispo:{},

  cv: [], client: [], tagtech: [],
  tagmetier: [], tagfonc: []};


  // COMPETENCES
  // COMPETENCES

  profile: ConsultantProfile = null;
  reference: any = {
    societe: "",
    nom: "",
    prenom: "",
    fonction: "",
    email: "",
    adresse: "",
    telephone: ""
  }
  itemToUpdate: any;
  selectedItem: any;
  file: File;


  constructor(
    private router: Router,
    public cs: ConsultantService,
    public as: ActionsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public ds: DisponibiliteService,
    private titleService: Title,
    private modalService: BsModalService,
    public qs: QualiteService,
    private communicationService: CommunicationService,
    private dcs: DictioService,
    private ts: TechnologieService,
    private cl: ClientService,
    private tt: TagtechService,
    private tf: TagfoncService,
    private tm: TagmetService,
    private tokenStorageService: TokenStorageService,
    private datePipe: DatePipe,
    private SpinnerService: NgxSpinnerService,
    private profileService: ConsultantProfileService,
    private modalService2: NgbModal
  ) {
    this.communicationService.Broadcast(
      "OnChild3",
      "Consultation/Mise à jour Consultant"
    );

    this.registerForm = this.formBuilder.group({
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      tjm: ["", Validators.pattern("[0-9]+")],
      dis: [""],
      linkedin: [""],
      code: [""],
      ville: [""],
      tech: [""],
      tech2: [""],
      client: [""],
      client2: [""],
      tagtech: [""],
      tagmetier: [""],
      tagfonc: [""],
      addresse: [""],
      addresse2: [""],
      telp: [""],
      email: [""],
      cv: [""],
      dda: [""]
    });
  }

  ngOnInit() {
    //Toujours en haut de page
    window.scrollTo(0, 0);

    const user = this.tokenStorageService.getUser();
    this.trigramme = user.trigramme;
    this.userid = user.id;
    this.roles = user.roles;

    //Vider toutes les listes

    this.Qualite = [];
    this.Dispo = [];
    this.Loader();
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);

    this.getDispo();

    this.getConsultantbyUserid(this.userid);
    //this.getConsultantbyUserid("63ce4d5a1bfc347848fc2bf5");

  }

  getConsultantbyUserid(userid: string) {

    this.profileService.getProfileByUserid(userid).subscribe((data2: {}) => {
      if (data2) {
        this.consultant = data2;
        if(!this.consultant.codedispo){
          this.consultant.codedispo = {
            "_id":null,
            "libelle":"ASAP",
            "ordre":1,
            "nbreConsultant":0
          }
        }

        this.csdata = data2;

        this.titree = this.consultant.titre;
        this.name = this.consultant.nom;
        this.linkedindata = this.consultant.linkedin;

        //Ajout du Nom et du prénom du client dans l'onglet de la page
        this.titleService.setTitle(
          this.name
        );

        // this.consultant.prenom =
        //   this.consultant.prenom[0].toUpperCase() +
        //   this.consultant.prenom.slice(1).toLowerCase();

        //Vider la liste des tags affin d'éviter les doublons
        //Lors de l'enrégistrement des données du consultant
        this.TagTechPageUpdate = [];
        this.FonctionPageUpdate = [];
        this.MetierPageUpdate = [];
        this.ClientPageUpdate = [];
        this.TechnoPageUpdate = [];

        //Récupération des Tags des données du consultants afin de les afficher dans la colonne
        //des Tags déjà sélectionnés pour le Consultant
        this.consultant.tagtech.forEach((item) => {
          this.TagTechPageUpdate.push(item);
        });
        this.consultant.tagfonc.forEach((item) => {
          this.FonctionPageUpdate.push(item);
        });
        this.consultant.tagmetier.forEach((item) => {
          this.MetierPageUpdate.push(item);
        });
        this.consultant.client.forEach((item) => {
          this.ClientPageUpdate.push(item);
        });

        this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
        // this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno });
        this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
        this.getFonctionPage({
          page: "0",
          size: "25",
          regex: this.regexFonction,
        });

        this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });

        //Fermeture de la Popup
        this.dialog.closeAll();
      } else {
        this.openNothingAlert();
      }
    });
  }

  // COMPTENCES
  // COMPTENCES

  openModal(content, item){
    this.selectedItem = item;
    this.modalService2.open(content, {centered: true});
  }

  addReference(){
    this.selectedItem.references.push(this.reference);
    this.selectedItem.datemaj = this.today;
    this.reference = {
      societe: "",
      nom: "",
      prenom: "",
      fonction: "",
      email: "",
      adresse: "",
      telephone: ""
    };
    this.modalService2.dismissAll();
  }

  removeReference(item){
    let index = this.selectedItem.references.indexOf(item);
    this.selectedItem.references.splice(index,1);
    this.selectedItem.datemaj = this.today;
  }

  cancelReferenceFrom(){
    this.modalService2.dismissAll();
    this.reference = {
      societe: "",
      nom: "",
      prenom: "",
      fonction: "",
      email: "",
      adresse: "",
      telephone: ""
    };
  }

  deleteLink(item){
    item.lien = "";
    item.datemaj = this.datePipe.transform(this.today, "yyyy-MM-dd");
  }

  cancelLink(){
    this.modalService2.dismissAll();
    this.selectedItem.lien = "";
  }

  openFileInput(fileInput, item){
    this.selectedItem = item;
    console.log(fileInput)
    fileInput.click();
  }

  onFilechange(event: any){
    this.file = event.target.files[0];
    this.uploadFile();
  }

  uploadFile(){
    this.Loader();
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('profileid', this.consultant.id);
    formData.append('libelle', this.selectedItem.intitule);
    this.profileService.addDocument(formData).subscribe(
      (data) => {
        this.selectedItem.document = data.filename;
        this.selectedItem.datemaj = this.datePipe.transform(this.today, "yyyy-MM-dd");
        this.dialog.closeAll();
      },
      error => {
        this.dialog.closeAll();
        this.Alert("Echec de l'enregistrement du document")
      }
    )
  }

  deleteDocument(item){
    this.selectedItem = item;
    this.profileService.deleteDocument(item.document, item.intitule, this.consultant.id).subscribe(
      (data) => {
        this.selectedItem.document = "";
        this.selectedItem.datemaj = this.datePipe.transform(this.today, "yyyy-MM-dd");
        this.dialog.closeAll();
      },
     (error) => {
      this.dialog.closeAll();
      console.log(error)
     }
    )
  }

  openLink(e) {
    if (!e.includes("https") || !e.includes("http")) {
      e = "https://" + e;
    }
    window.open(e, "_blank");
  }

  validate(): boolean {

    for(let cl of this.consultant.competencesclients){
      if (cl.dureepratique <=0 || cl.references.length == 0) {
        this.Alert("Durée de pratique et reférence obligatoire pour chaque client.")
        return false;
      }
    }

    for(let cl of this.consultant.competencestechnos){
      if (cl.dureepratique <=0 || cl.references.length == 0 || cl.niveau == "") {
        this.Alert("Niveau, durée de pratique et reférence obligatoire pour chaque technologie.")
        return false;
      }
    }

    for(let cl of this.consultant.competencesactivites){
      if (cl.dureepratique <=0 || cl.references.length == 0 || cl.niveau == "") {
        this.Alert("Niveau, durée de pratique et reférence obligatoire pour chaque activité.")
        return false;
      }
    }

    for(let cl of this.consultant.competencessecteurs){
      if (cl.dureepratique <=0 || cl.references.length == 0) {
        this.Alert("Durée de pratique et reférence obligatoire pour chaque secteur.")
        return false;
      }
    }

    return true;

  }

  // save(){
  //   if(this.validate()){
  //     this.Loader();
  //     this.profileService.updateProfile(this.consultant).subscribe(
  //       (data: ConsultantProfile) => {
  //         this.consultant = data;
  //         this.profileService.setProfile(data);
  //         this.dialog.closeAll();
  //         this.Alert("Informations enregistrées avec succès");
  //       },
  //       (error) => {
  //         this.dialog.closeAll();
  //         this.Alert("Erreur lors de l'enregistrement");
  //       }
  //     )
  //   }

  // }

  // COMPTENCES
  // COMPTENCES

  //Vérifier que le linkedin est bon avant d'afficher l'icône
  linkedinTest(value) {
    var inValid = /(^(http(s)?)|^(www))[a-zA-Z0-9.?§:!^$&/]+/;
    return inValid.test(value);
  }

  TextAreaVal(item: Technologie, event) {
    this.TechnoPageUpdate[0] = event.target.value;
  }
  /* fonction get page tagtech*/

  getTagTechPage(request) {
    this.loading = true;
    this.SpinnerService.show();
    this.tt.Tags(request).subscribe(
      (data) => {
        if (data) {
          this.tagTechPage = data["content"];
          this.TagTechTabFilter = this.tagTechPage;
          this.totalElements = data["totalElements"];
          this.totalPage = Math.ceil(this.totalElements / this.size);
          this.loading = false;
          this.SpinnerService.hide();
          this.dialog.closeAll();

          if (this.TagTechPageUpdate.length !== 0) {
            this.TagTechPageUpdate.forEach(
              (action) =>
                (this.TagTechTabFilter = this.TagTechTabFilter.filter(
                  (u) => u.libelle !== action.libelle
                ))
            );
          }
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  ChangerTagsPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.size;
    request["regex"] = this.regex;

    this.getTagTechPage(request);
    this.suivant = 0;
  }

  PremiereTagsPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
    this.suivant = 0;
  }

  FinalTagsPage() {
    const request = {};
    this.suivant = this.totalPage - 1;
    request["page"] = this.totalPage - 1;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
    //console.log(this.totalElements / this.size);
  }

  nextTagsPage() {
    const request = {};
    request["page"] = ++this.suivant;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
  }

  PreviousTagsPage() {
    const request = {};
    request["page"] = --this.suivant;
    request["size"] = this.size;
    request["regex"] = this.regex;
    this.getTagTechPage(request);
  }

  ChangeTagTech(tg: Tagtech, isChecked: boolean) {
    if (!isChecked) {
      let index = this.TagTechPageUpdate.findIndex((x) => x === tg);
      if (index != -1) this.TagTechPageUpdate.splice(index, 1);
      this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
    }
    this.deleteCompetencesTechno();
  }

  AddTagsToUpdate(tgt: Tagtech, isChecked: boolean) {
    this.updated = true;

    if (isChecked) {
      this.TagTechFilter.push(tgt);
      this.TagTechPageUpdate.push(tgt);
      this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
      //console.log(this.TagTechPageUpdate);
    } else {
      let index = this.TagTechPageUpdate.findIndex((x) => x === tgt);
      if (index != -1) this.TagTechPageUpdate.splice(index, 1);
    }
    //this.isChecked = false;
    this.addCompetencesTechno();
  }

  AddTagTech() {
    if (this.tagTechUpdate.libelle === "")
      this.OpenPopupAlertMode("Merci de saisir une nouvelle action");
    else {
      this.TagTechPageUpdate.push(this.tagTechUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.tagTechUpdate = new Tagtech();
    }
  }

  ////////////////////////////////////////////  /* Fin Pagination*/

  /* fonction get page tagmet*/

  getMetierPage(request) {
    this.loadingMetier = true;
    this.SpinnerService.show();
    this.tm.mets(request).subscribe(
      (data) => {
        if (data) {
          this.metierPage = data["content"];

          this.MetierTabFilter = this.metierPage;
          this.totalElementsMetier = data["totalElements"];
          this.totalPageMetier = Math.ceil(
            this.totalElementsMetier / this.sizeMetier
          );
          this.loadingMetier = false;
          this.SpinnerService.hide();
          this.dialog.closeAll();
          if (this.MetierPageUpdate.length !== 0) {
            this.MetierPageUpdate.forEach(
              (action) =>
                (this.MetierTabFilter = this.MetierTabFilter.filter(
                  (u) => u.libelle !== action.libelle
                ))
            );
          }
        }
      },
      (error) => {
        this.loadingMetier = false;
      }
    );
  }

  ChangerMetierPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;

    this.getMetierPage(request);
    this.suivantMetier = 0;
  }

  PremiereMetierPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
    this.suivantMetier = 0;
  }

  FinalMetierPage() {
    const request = {};
    this.suivantMetier = this.totalPageMetier - 1;
    request["page"] = this.totalPageMetier - 1;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
    //console.log(this.totalElements / this.size);
  }

  nextMetierPage() {
    const request = {};
    request["page"] = ++this.suivantMetier;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
  }

  PreviousMetierPage() {
    const request = {};
    request["page"] = --this.suivantMetier;
    request["size"] = this.sizeMetier;
    request["regex"] = this.regexMetier;
    this.getMetierPage(request);
  }

  ChangeMetier(tg: Tagmet, isChecked: boolean) {
    if (!isChecked) {
      let index = this.MetierPageUpdate.findIndex((x) => x === tg);
      if (index != -1) this.MetierPageUpdate.splice(index, 1);
      this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });
    }
    this.deleteCompetencesMetier();
  }

  AddMetierToUpdate(tgt: Tagmet, isChecked: boolean) {
    this.updated = true;
    if (isChecked) {
      this.MetierFilter.push(tgt);
      this.MetierPageUpdate.push(tgt);
      this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });
    } else {
      let index = this.MetierPageUpdate.findIndex((x) => x === tgt);
      if (index != -1) this.MetierPageUpdate.splice(index, 1);
    }
    //this.isChecked = false;
    this.addCompetencesMetier();
  }

  AddMetier() {
    if (this.MetierUpdate.libelle === "")
      this.OpenPopupAlertMode("Merci de saisir un nouveau tag métier");
    else {
      this.MetierPageUpdate.push(this.MetierUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.MetierUpdate = new Tagmet();
    }
  }

  ////////////////////////////////////////////  /* Fin Pagination*/

  /* fonction get page FOnction*/

  getFonctionPage(request) {
    this.loadingFonction = true;
    this.SpinnerService.show();
    this.tf.foncs(request).subscribe(
      (data) => {
        if (data) {
          this.fonctionPage = data["content"];
          this.FonctionTabFilter = this.fonctionPage;
          this.totalElementsFonction = data["totalElements"];
          this.totalPageFonction = Math.ceil(
            this.totalElementsFonction / this.sizeFonction
          );
          this.loadingFonction = false;
          this.SpinnerService.hide();
          this.dialog.closeAll();
          if (this.FonctionPageUpdate.length !== 0) {
            this.FonctionPageUpdate.forEach(
              (action) =>
                (this.FonctionTabFilter = this.FonctionTabFilter.filter(
                  (u) => u.libelle !== action.libelle
                ))
            );
          }
        }
      },
      (error) => {
        this.loadingFonction = false;
      }
    );
  }

  ChangerFonctionPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;

    this.getFonctionPage(request);
    this.suivantFonction = 0;
  }

  PremiereFonctionPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
    this.suivantFonction = 0;
  }

  FinalFonctionPage() {
    const request = {};
    this.suivantFonction = this.totalPageFonction - 1;
    request["page"] = this.totalPageFonction - 1;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
    //console.log(this.totalElements / this.size);
  }

  nextFonctionPage() {
    const request = {};
    request["page"] = ++this.suivantFonction;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
  }
  PreviousFonctionPage() {
    const request = {};
    request["page"] = --this.suivantFonction;
    request["size"] = this.sizeFonction;
    request["regex"] = this.regexFonction;
    this.getFonctionPage(request);
  }
  ChangeFonction(tg: Tagfonc, isChecked: boolean) {
    if (!isChecked) {
      let index = this.FonctionPageUpdate.findIndex((x) => x === tg);
      if (index != -1) this.FonctionPageUpdate.splice(index, 1);
      this.getFonctionPage({
        page: "0",
        size: "25",
        regex: this.regexFonction,
      });
    }
    this.deleteCompetencesFonction();
  }
  AddFonctionToUpdate(tgt: Tagfonc, isChecked: boolean) {
    this.updated = true;
    if (isChecked) {
      this.FonctionFilter.push(tgt);
      this.FonctionPageUpdate.push(tgt);
      this.getFonctionPage({
        page: "0",
        size: "25",
        regex: this.regexFonction,
      });
      //console.log(this.TagTechPageUpdate);
    } else {
      let index = this.FonctionPageUpdate.findIndex((x) => x === tgt);
      if (index != -1) this.FonctionPageUpdate.splice(index, 1);
    }
    //this.isChecked = false;
    this.addCompetencesFonction();
  }
  AddFonction() {
    if (this.FonctionUpdate.libelle === "")
      this.OpenPopupAlertMode("Merci de saisir un nouveau tag fonction");
    else {
      this.FonctionPageUpdate.push(this.FonctionUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.FonctionUpdate = new Tagfonc();
    }
  }

  ////////////////////////////////////////////  /* Fin Pagination*/

  /* fonction get page Client*/

  getClientPage(request) {
    this.loadingClient = true;
    this.SpinnerService.show();
    this.cl.Clients(request).subscribe(
      (data) => {
        if (data) {
          this.clientPage = data["content"];
          this.ClientTabFilter = this.clientPage;
          this.totalElementsClient = data["totalElements"];
          this.totalPageClient = Math.ceil(
            this.totalElementsClient / this.sizeClient
          );
          this.loadingClient = false;
          this.SpinnerService.hide();
          this.dialog.closeAll();
          if (this.ClientPageUpdate.length !== 0) {
            this.ClientPageUpdate.forEach(
              (action) =>
                (this.ClientTabFilter = this.ClientTabFilter.filter(
                  (u) => u.libelle !== action.libelle
                ))
            );
          }
        }
      },
      (error) => {
        this.loadingClient = false;
      }
    );
  }

  ChangerClientPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;

    this.getClientPage(request);
    this.suivantClient = 0;
  }

  PremiereClientPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;
    this.getClientPage(request);
    this.suivantClient = 0;
  }

  FinalClientPage() {
    const request = {};
    this.suivantClient = this.totalPageClient - 1;
    request["page"] = this.totalPageClient - 1;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;
    this.getClientPage(request);
    //console.log(this.totalElements / this.size);
  }

  nextClientPage() {
    const request = {};
    request["page"] = ++this.suivantClient;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;
    this.getClientPage(request);
  }

  PreviousClientPage() {
    const request = {};
    request["page"] = --this.suivantClient;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;
    this.getClientPage(request);
  }

  ChangeClient(clt: Client, isChecked: boolean) {
    if (!isChecked) {
      let index = this.ClientPageUpdate.findIndex((x) => x === clt);
      if (index != -1) this.ClientPageUpdate.splice(index, 1);
      this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
    }
    this.deleteCompetencesClient();
  }

  AddClientToUpdate(clt: Client, isChecked: boolean) {
    this.updated = true;
    if (isChecked) {
      this.ClientFilter.push(clt);
      this.ClientPageUpdate.push(clt);
      this.getClientPage({ page: "0", size: "25", regex: this.regexClient })
      this.addCompetencesClient();
      //console.log(this.TagTechPageUpdate);
    } else {
      let index = this.ClientPageUpdate.findIndex((x) => x === clt);
      if (index != -1) this.ClientPageUpdate.splice(index, 1);
      this.deleteCompetencesClient();
    }
    //this.isChecked = false;
  }

  RemoveToUpdate() {
    this.updated = true;

    this.ClientPageUpdate.forEach((el) => {
      this.ClientTabFilter = this.ClientTabFilter.filter(
        (a) => a.libelle != el.libelle
      );
    });
  }

  AddClient() {
    if (this.clientUpdate.libelle === "")
      this.OpenPopupAlertMode("Merci de saisir un nouveau client");
    else {
      this.clientUpdate.libelle = this.clientUpdate.libelle.toUpperCase();
      //console.log(this.clientUpdate.libelle.toUpperCase);
      this.addClientTodatabase(this.clientUpdate.libelle);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.clientUpdate = new Client();
    }
  }

  ////////////////////////////////////////////  /* Fin Pagination*/

  /* Fonctions Techno Pagination */

  getTechnoPage(request) {
    this.loadingTechno = true;
    this.SpinnerService.show();
    this.ts.Technologies(request).subscribe(
      (data) => {
        this.TechnoPage = data["content"];
        this.TechnoTabFilter = this.TechnoPage;
        this.totalElementsTechno = data["totalElements"];
        this.totalPageTechno = Math.ceil(
          this.totalElementsTechno / this.sizeTechno
        );
        this.loadingTechno = false;
        this.SpinnerService.hide();
        this.dialog.closeAll();
        if (this.TechnoPageUpdate.length !== 0) {
          this.TechnoPageUpdate.forEach(
            (action) =>
              (this.TechnoTabFilter = this.TechnoTabFilter.filter(
                (u) => u.libelle !== action.libelle
              ))
          );
        }
      },
      (error) => {
        this.loadingTechno = false;
      }
    );
  }

  ChangerTechnosPageSize() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeTechno;
    request["regex"] = this.regexTechno;

    this.getTechnoPage(request);
    this.suivantTechno = 0;
  }
  PremiereTechnosPage() {
    const request = {};
    request["page"] = 0;
    request["size"] = this.sizeTechno;
    request["regex"] = this.regexTechno;
    this.getTechnoPage(request);
    this.suivantTechno = 0;
  }

  FinalTechnoPage() {
    const request = {};
    this.suivantTechno = this.totalPageTechno - 1;
    request["page"] = this.totalPageTechno - 1;
    request["size"] = this.sizeTechno;
    request["regex"] = this.regexTechno;
    this.getTechnoPage(request);
    //console.log(this.totalElements / this.size);
  }

  nextTechnosPage() {
    const request = {};
    request["page"] = ++this.suivantTechno;
    request["size"] = this.sizeTechno;
    request["regex"] = this.regexTechno;
    this.getTechnoPage(request);
  }

  PreviousTechnoPage() {
    const request = {};
    request["page"] = --this.suivantTechno;
    request["size"] = this.sizeTechno;
    request["regex"] = this.regexTechno;
    this.getTechnoPage(request);
  }

  ChangeTechnoTech(tg: Technologie, isChecked: boolean) {
    if (!isChecked) {
      let index = this.TechnoPageUpdate.findIndex((x) => x === tg);
      if (index != -1) this.TechnoPageUpdate.splice(index, 1);
      this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno });
    }
  }

  AddTechnoToUpdate(tgt: Technologie, isChecked: boolean) {
    this.updated = true;
    if (isChecked) {
      this.TechnoFilter.push(tgt);
      this.TechnoPageUpdate.push(tgt);
      this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno });
      //console.log(this.TagTechPageUpdate);
    } else {
      let index = this.TechnoPageUpdate.findIndex((x) => x === tgt);
      if (index != -1) this.TechnoPageUpdate.splice(index, 1);
    }
    //this.isChecked = false;
  }

  AddTechno() {
    if (this.technoUpdate.libelle === "")
      this.OpenPopupAlertMode("Merci de saisir une nouvelle technologie");
    else {
      this.TechnoPageUpdate.push(this.technoUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.technoUpdate = new Technologie();
    }
  }

  ////////////////////////////////////////////  /* Fin Pagination*/

  /**
   * Récupérer la liste de toutes les Disponiblités
   * @param  {void}
   * @param  {void}
   * @return {void}
   */
  getDispo() {
    this.Dispo = [];
    this.ds.getRefs().subscribe((data2: {}) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i]);
      }
    });
  }


  //inutile
  getTech() {
    this.ts.getTechnologie().subscribe((data2) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Tech.push(data2[i].libelle);
      }
    });
  }

  /**
   * Récupérer la liste de tous les Clients
   * @param  {void}
   * @param  {void}
   * @return {void}
   */
  getClients() {
    this.cl.getClients().subscribe((data2) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Clients.push(data2[i].libelle);
      }
    });
  }

  /**
   * Récupérer la liste de tous les Tags
   * Métier,Tech,Fonction
   * @param  {void}
   * @param  {void}
   * @return {void}
   */
  getTags() {
    this.tt.getTagtech().subscribe((data2) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.TagTech.push(data2[i].libelle);
      }
    });

    this.tm.getTagmet().subscribe((data2) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.TagMetier.push(data2[i].libelle);
      }
    });
    this.tf.getTagfonc().subscribe((data2) => {
      // tslint:disable-next-line: forin
      for (const i in data2) {
        this.TagFonc.push(data2[i].libelle);
      }
    });
  }

  cancelcv() {
    this.CancelModal();
    this.ngOnInit();
  }

  getActionbyUser(user) {
    this.user = [];
    this.as.findActionsbyId(user).subscribe((data2: {}) => {
      this.user = data2;
      // tslint:disable-next-line: forin
      for (const i in data2) {
        ELEMENT_DATA.push({
          _id: data2[i]._id,
          action: data2[i].action,
          date: data2[i].date,
          trigramme: data2[i].trigramme,
        });
      }
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    });
  }

  openAlertDialog() {
    this.registerForm.patchValue({
      observation: "",
    });

    window.scrollTo(0, 0);

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Enregistrement réussi",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.closeAll();

      this.getConsultantbyUserid(this.userid);
    });
  }

  openNothingAlert() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Désolé ce Consultant n'existe plus",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home"]);
    });
  }

  Annuler() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Abandon des modifications",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home"]);
    });
  }
  fermer() {
    this.router.navigate(["/home"]);
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  AfficheTech() {
    var doc = document.getElementById("Afftech");
    if (doc.style.display === "none") {
      doc.style.display = "block";
    } else {
      doc.style.display = "none";
    }
  }

  AfficheClient() {
    var doc = document.getElementById("Affclient");
    if (doc.style.display === "none") {
      doc.style.display = "block";
    } else {
      doc.style.display = "none";
    }
  }

  AfficheTag1() {
    var doc = document.getElementById("Afftag1");
    if (doc.style.display === "none") {
      doc.style.display = "block";
    } else {
      doc.style.display = "none";
    }
  }

  AfficheTag2() {
    var doc = document.getElementById("Afftag2");
    if (doc.style.display === "none") {
      doc.style.display = "block";
    } else {
      doc.style.display = "none";
    }
  }

  AfficheTag3() {
    var doc = document.getElementById("Afftag3");
    if (doc.style.display === "none") {
      doc.style.display = "block";
    } else {
      doc.style.display = "none";
    }
  }

  getTechno(t1) {
    var check = false;
    let tech = new Technologie();
    this.Techno.forEach((item, index) => {
      if (item === t1) {
        check = true;
        this.Techno = arrayRemove(this.Techno, item);
      }
    });
    if (check === false) {
      tech.libelle = t1;
      this.Techno.push(t1);
    }
  }

  getClient(t2) {
    var check = false;
    let cl = new Client();
    this.Cli.forEach((item, index) => {
      if (item === t2) {
        check = true;
        this.Cli = arrayRemove(this.Cli, item);
      }
    });
    if (check === false) {
      cl.libelle = t2;
      this.Cli.push(t2);
    }
  }

  getTagtech(t3) {
    var check = false;
    let t1 = new Tagtech();
    this.Tag1.forEach((item, index) => {
      if (item.libelle === t3) {
        check = true;
        this.Tag1 = arrayRemove(this.Tag1, item);
      }
    });
    if (check === false) {
      t1.libelle = t3;
      this.Tag1.push(t1);
    }
  }

  getTagmet(t4) {
    var check = false;
    let t2 = new Tagmet();
    this.Tag2.forEach((item, index) => {
      if (item === t4) {
        check = true;
        this.Tag2 = arrayRemove(this.Tag2, item);
      }
    });
    if (check === false) {
      t2.libelle = t4;
      this.Tag2.push(t4);
    }
  }

  getTagfonc(t5) {
    var check = false;
    let t3 = new Tagfonc();
    this.Tag3.forEach((item, index) => {
      if (item === t5) {
        check = true;
        this.Tag3 = arrayRemove(this.Tag3, item);
      }
    });
    if (check === false) {
      t3.libelle = t5;
      this.Tag3.push(t5);
    }
  }

  // checkTechno() {
  //   if (this.registerForm.value.tech2 != "") {
  //     var libelle = this.registerForm.value.tech2.toLowerCase();
  //     var type = "Technologie";
  //     this.ts.addTechno(libelle).subscribe((data) => {
  //       this.dcs.findDictiobyMot(libelle).subscribe((result) => {
  //         if (result === null) {
  //           this.motsDic.mot = libelle;
  //           this.motsDic.typeMotDictionnaire = type;
  //           this.dcs.addDictio(this.motsDic).subscribe((data2) => {
  //             this.getConsultantbyUserid(this.userid);
  //           });
  //         }
  //         if (
  //           result != null &&
  //           (result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Technologie")
  //         ) {
  //           this.newMot.mot = result.motsDictionnaire.mot;
  //           this.newMot.typeMotDictionnaire = type;
  //           this.dic.motsDictionnaire = this.newMot;
  //           this.dcs.updateDictio(result.id, this.dic).subscribe((res) => {
  //             this.getConsultantbyUserid(this.userid);
  //           });
  //         }
  //       });
  //     });
  //     let tech = new Technologie();
  //     tech.libelle = libelle;
  //     this.Techno.push(tech);
  //   }
  // }

  // checkClient() {
  //   if (this.registerForm.value.client2 != "") {
  //     var libelle = this.registerForm.value.client2.toLowerCase();
  //     var type = "Client";
  //     this.cl.addClient(libelle).subscribe((data) => {
  //       this.dcs.findDictiobyMot(libelle).subscribe((result) => {
  //         if (result === null) {
  //           this.motsDic.mot = libelle;
  //           this.motsDic.typeMotDictionnaire = type;
  //           this.dcs.addDictio(this.motsDic).subscribe((data2) => {
  //             this.getConsultantbyUserid(this.userid);
  //           });
  //         }
  //         if (
  //           result != null &&
  //           (result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Technologie" ||
  //             result.motsDictionnaire.TypeMotDictionnaire != "Client")
  //         ) {
  //           this.newMot.mot = result.motsDictionnaire.mot;
  //           this.newMot.typeMotDictionnaire = type;
  //           this.dic.motsDictionnaire = this.newMot;
  //           this.dcs.updateDictio(result.id, this.dic).subscribe((res) => {
  //             this.getConsultantbyUserid(this.userid);
  //           });
  //         }
  //       });
  //     });
  //     let cl = new Client();
  //     cl.libelle = libelle;
  //     this.Cli.push(cl);
  //   }
  // }

  Forceupdate() {
    this.forceupdate = true;
    this.update();
  }

  deleteCompetencesFonction(){
    this.consultant.competencesactivites.forEach(fonction => {
      let present = false;
      for(let fonc of this.FonctionPageUpdate){
        if (fonction.intitule == fonc.libelle) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencesactivites.push(
          fonction
        )
      }
    })
    let temp = this.competencesactivites;
    this.consultant.competencesactivites = this.consultant.competencesactivites.filter( function( el ) {
      return temp.indexOf( el ) < 0;
    } );
    this.competencesactivites = [];
  }

  deleteCompetencesTechno(){
    this.consultant.competencestechnos.forEach(techno => {
      let present = false;
      for(let tech of this.TagTechPageUpdate){
        if (techno.intitule == tech.libelle) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencestechnos.push(
          techno
        )
      }
    });
    let temp = this.competencestechnos;
    this.consultant.competencestechnos = this.consultant.competencestechnos.filter( function( el ) {
      return temp.indexOf( el ) < 0;
    } );
    this.competencestechnos = [];
  }

  deleteCompetencesMetier(){
    this.consultant.competencessecteurs.forEach(metier => {
      let present = false;
      for(let met of this.MetierPageUpdate){
        if (metier.intitule == met.libelle) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencessecteurs.push(
          metier
        )
      }
    })
    let temp = this.competencessecteurs;
    this.consultant.competencessecteurs = this.consultant.competencessecteurs.filter( function( el ) {
      return temp.indexOf( el ) < 0;
    } );
    this.competencessecteurs = [];
  }

  deleteCompetencesClient(){

    this.consultant.competencesclients.forEach(client => {
      let present = false;
      for(let cl of this.ClientPageUpdate){
        if (client.intitule == cl.libelle) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencesclients.push(
          client
        )
      }
    })
    let temp = this.competencesclients;
    this.consultant.competencesclients = this.consultant.competencesclients.filter( function( el ) {
      return temp.indexOf( el ) < 0;
    } );
    this.competencesclients = [];

  }

  addCompetencesTechno(){
    this.TagTechPageUpdate.forEach(techno => {
      let present = false;
      for(let tech of this.consultant.competencestechnos){
        if (techno.libelle == tech.intitule) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencestechnos.push(
          {
            intitule: techno.libelle,
            niveau: "",
            dureepratique: 0,
            lien: "",
            document: "",
            references: [],
            datemaj: this.datePipe.transform(new Date(), "yyyy-MM-dd")
          }
        )
      }
    })
    this.consultant.competencestechnos = this.consultant.competencestechnos.concat(this.competencestechnos);
    this.competencestechnos = [];
  }

  addCompetencesMetier(){
    this.MetierPageUpdate.forEach(metier => {
      let present = false;
      for(let met of this.consultant.competencessecteurs){
        if (metier.libelle == met.intitule) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencessecteurs.push(
          {
            intitule: metier.libelle,
            niveau: "",
            dureepratique: 0,
            lien: "",
            document: "",
            references: [],
            datemaj: this.datePipe.transform(new Date(), "yyyy-MM-dd")
          }
        )
      }
    })
    this.consultant.competencessecteurs = this.consultant.competencessecteurs.concat(this.competencessecteurs);
    this.competencessecteurs = [];
  }

  addCompetencesFonction(){
    this.FonctionPageUpdate.forEach(fonction => {
      let present = false;
      for(let fonc of this.consultant.competencesactivites){
        if (fonction.libelle == fonc.intitule) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencesactivites.push(
          {
            intitule: fonction.libelle,
            niveau: "",
            dureepratique: 0,
            lien: "",
            document: "",
            references: [],
            datemaj:""
          }
        )
      }
    });
    this.consultant.competencesactivites = this.consultant.competencesactivites.concat(this.competencesactivites);
    this.competencesactivites = [];
  }

  addCompetencesClient(){
    this.ClientPageUpdate.forEach(client => {
      let present = false;
      for(let cl of this.consultant.competencesclients){
        if (client.libelle == cl.intitule) {
          present = true;
          break;
        }
      }
      if(!present){
        this.competencesclients.push(
          {
            intitule: client.libelle,
            niveau: "",
            dureepratique: 0,
            lien: "",
            document: "",
            references: [],
            datemaj: this.datePipe.transform(new Date(), "yyyy-MM-dd")
          }
        )
      }
    })
    this.consultant.competencesclients = this.consultant.competencesclients.concat(this.competencesclients);
    this.competencesactivites = [];
  }

  update() {
    if (this.Tag1.length === 0) {
      this.consultant.tagtech.forEach((item, index) => {
        let t1 = new Tagtech();
        t1.libelle = item.libelle;
        this.Tag1.push(t1);
      });
    }

    if (this.Tag2.length === 0) {
      this.consultant.tagmetier.forEach((item, index) => {
        let t2 = new Tagmet();
        t2.libelle = item.libelle;
        this.Tag2.push(t2);
      });
    }

    if (this.Tag3.length === 0) {
      this.consultant.tagfonc.forEach((item, index) => {
        let t3 = new Tagfonc();
        t3.libelle = item.libelle;
        this.Tag3.push(t3);
      });
    }

    if (this.Cli.length === 0) {
      this.consultant.client.forEach((item, index) => {
        let cl = new Client();
        cl.libelle = item.libelle;
        this.Cli.push(cl);
      });
    }

    // this.checkTechno();
    // this.checkClient();

    if (this.validate()) {
      this.Loader();
      this.consultant.client = this.ClientPageUpdate;
      this.consultant.tagtech = this.TagTechPageUpdate;
      this.consultant.tagmetier = this.MetierPageUpdate;
      this.consultant.tagfonc = this.FonctionPageUpdate;
      this.consultant.codedispo = { libelle: this.dispolibelle, ordre: this.dispoordre },
      this.consultant.dateDerniereMaj = this.consultantData.dateDerniereMaj = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      );


      this.profileService.updateProfile(this.consultant).subscribe(
        (data: ConsultantProfile) => {
          this.consultant = data;
          this.profileService.setProfile(data);
          this.dialog.closeAll();
          this.Alert("Informations enregistrées avec succès");
        },
        (error) => {
          this.dialog.closeAll();
          this.Alert("Erreur lors de l'enregistrement");
        }
      )

    }
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.filename = this.date + "_" + this.selectedFiles.item(0).name;
    this.initcv.nomCV = this.filename;
    this.initcv.dateCV = this.today.toString();
    this.initcv.cheminCV = "../upload-dir";
    this.tabcv.push(this.initcv);
  }
  CancelModal() {
    this.modalRef.hide();
  }


  showCheckboxes1() {
    this.getTech();
    const checkboxes1 = document.getElementById("checkboxes1");
    if (!this.expanded1) {
      checkboxes1.style.display = "block";
      this.expanded1 = true;
    } else {
      checkboxes1.style.display = "none";
      this.expanded1 = false;
    }
  }

  showCheckboxes2() {
    this.getClients();
    const checkboxes2 = document.getElementById("checkboxes2");
    if (!this.expanded2) {
      checkboxes2.style.display = "block";
      this.expanded2 = true;
    } else {
      checkboxes2.style.display = "none";
      this.expanded2 = false;
    }
  }

  showCheckboxes3() {
    this.getTags();
    const checkboxes3 = document.getElementById("checkboxes3");
    if (!this.expanded3) {
      checkboxes3.style.display = "block";
      this.expanded3 = true;
    } else {
      checkboxes3.style.display = "none";
      this.expanded3 = false;
    }
  }

  showCheckboxes4() {
    const checkboxes4 = document.getElementById("checkboxes4");
    if (!this.expanded4) {
      checkboxes4.style.display = "block";
      this.expanded4 = true;
    } else {
      checkboxes4.style.display = "none";
      this.expanded4 = false;
    }
  }

  showCheckboxes5() {
    const checkboxes5 = document.getElementById("checkboxes5");
    if (!this.expanded5) {
      checkboxes5.style.display = "block";
      this.expanded5 = true;
    } else {
      checkboxes5.style.display = "none";
      this.expanded5 = false;
    }
  }


  getColor(param) {
    var doc = param;
    if (doc === "Jouable") {
      document.getElementById("selectquali").style.color = "green";
    }

    if (doc === "Moyen") {
      document.getElementById("selectquali").style.color = "orange";
    }

    if (doc === "Injouable") {
      document.getElementById("selectquali").style.color = "red";
    }

    if (doc === "Neutre") {
      document.getElementById("selectquali").style.color = "grey";
    }
  }

  Changecv() {
    var doc1 = document.getElementById("telcv");
    var doc2 = document.getElementById("chcv");
    if (doc1.style.display === "block") {
      doc1.style.display = "none";
      doc2.style.display = "block";
    } else {
      doc1.style.display = "block";
      doc2.style.display = "none";
    }
  }

  visualiser(fileURL) {
    this.cs.downloadFile(fileURL).subscribe(
      (blob) => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }

  ChangeLinked() {
    var doc1 = document.getElementById("inputLinkedMD");
    var doc2 = document.getElementById("LinkedAcc");
    var doc3 = document.getElementById("link");
    var doc4 = document.getElementById("hide");
    if (doc1.style.display === "block") {
      doc1.style.display = "none";
    } else {
      doc1.style.display = "block";
    }
    if (doc2.style.display === "none") {
      doc2.style.display = "block";
    } else {
      doc2.style.display = "none";
    }
  }

  voircv(cv) {
    if (cv && cv.length !== 0) {
      window.open(backEndpoint + "/downloadFile/" + cv[0].nomCV);
    }
  }

  openAlertWarning(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Champs " + param + " vide",
        buttonText: {
          cancel: "Retour",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getConsultantbyUserid(this.userid);
    });
  }

  addClientTodatabase(libelle) {
    this.Loader();
    var type = "Client";
    if (libelle === "") {
      this.openAlertWarning("Clients");
    } else {
      let clienttemp = this.clientUpdate;

      this.cl.findByLibelle(libelle).subscribe((data) => {
        if (data === null) {
          this.ClientPageUpdate.push(clienttemp);

          this.cl.addClient(libelle).subscribe((data) => {
            if (data === 1) {
              this.dialog.closeAll();
              this.getClientPage({
                page: "0",
                size: "25",
                regex: this.regexClient,
              });
              this.ClientTabFilter = this.ClientTabFilter.filter(
                (a) => a.libelle != this.clientUpdate.libelle
              );
            }
          });
        } else {
          this.dialog.closeAll();
          this.OpenPopupAlertMode(
            "client existe déjà, merci de le sélectionner dans la liste proposée"
          );
        }
      });
    }
  }

  openLinkekin(e) {
    if (!e.includes("https") || !e.includes("http")) {
      e = "https://" + e;
    }
    window.open(e, "_blank");
  }

  Loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
       Chargement en Cours`,
      },
      disableClose: true,
    });
  }

  RenderTechno(e) {
    if (e) {
      return e.libelle;
    }
    return "";
  }


  Truncate(text) {
    if (text) {
      return text.substr(0, 125);
    }
  }

  TruncateObs(text) {
    if (text) {
      return text.substr(0, 220);
    }
  }

  OpenPopup(text) {
    const dialogRef = this.dialog.open(PopupdataComponent, {
      data: {
        message: text,
      },
    });
  }

  OpenPopupAlertMode(text) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: text,
      },
    });
  }

  changeDispo(e) {
    this.Dispo.map((el) => {
      if (el.libelle === e) {
        this.dispoordre = el.ordre;
      } else if (e === "") {
        this.dispoordre = this.consultant.codedispo.ordre;
      }
    });

    this.dispolibelle = e;
    this.consultant.codedispo.libelle = e;
  }

  showLinkedin(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  showCV(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  changeLinkedin() {
    this.linkedindata = this.consultant.linkedin;
    this.CancelModal();
  }

  changeCV() {
    this.linkedindata = this.consultant.linkedin;
  }

  CamelCase(e) {
    return e[0] + e.substr(1, e.length);
  }
}
