import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChildren,
  TemplateRef,
} from "@angular/core";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { FormControl } from "@angular/forms";
import { ConsultantService } from "../services/consultant.service";
import { Router, ActivatedRoute } from "@angular/router";
import { QualiteService } from "../services/qualite.service";
import { DisponibiliteService } from "../services/disponibilite.service";
import { CommunicationService } from "../services/communication.service";
import { RefRechercheService } from "../services/ref-recherche.service";
import { RechercheService } from "../services/recherche.service";
import { PopresultComponent } from "../popresult/popresult.component";
import { PopupdataComponent } from "../popupdata/popupdata.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SpecBesoin } from '../Model/spcbesoin.model';

import { TagtechService } from "../services/tagtech.service";
import { Tagtech } from "../Model/tagtech";
import { TagmetService } from "../services/tagmet.service";
import { TagfoncService } from "../services/tagfonc.service";
import { TokenStorageService } from "../services/token-storage.service";
import { ProgressSpinnerDialogComponent } from "../progress-spinner-dialog/progress-spinner-dialog.component";
import { AlertEmptyComponent } from "../alert-empty/alert-empty.component";
import { BASE_URL } from "../config";
import { CV } from "../Model/cv.model";
import { ConsultantCrmService } from "../services/consultantcrm.service";
import { ClientServiceService } from "../services/client-service.service";
import { Title } from "@angular/platform-browser";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export interface Quali {
  value: string;
  name: string;
}

@Component({
  selector: "app-rechmcr",
  templateUrl: "./rechmcrrhcrm.component.html",
  styleUrls: ["./rechmcrrhcrm.component.scss"],
})
export class RechmcrRhCrmComponent implements OnInit {
 

  test: number;

  Quali: Quali[] = [];

  //rech: SCAMC[] = [];
  rech: any = [];
  qu: any[] = [];
  dis: any[] = [];
  expert: any[] = [];
  zone: any[] = [];
  input: any[] = [];
  TagTechnique: Tagtech[];
  sortBy: string;

  TagMetier = [];
  TagFonction = [];
  filtest: boolean = false;
  dialogRef: any;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;

  techopen = false;
  foncopen = false;
  metopen = false;
  showfilter = false;
  tech = [];
  techtext = "";
  met = [];
  mettext = "";
  fonc = [];
  fonctext = "";
  qualitecvsearch = [];
  expsearch = [];
  disposearch = [];
  zonesearch = [];
  zonesearchtemp: string[];
  searcthtext: string;
  motcletext: string;

  
idbesoin:String
clientId = ""
contactId=""
consultantnamedata=""
datesend=""
prenomcontact=""
datedata=""
date=""
ao=""
gestionnairerh=""
ic=""
commercial=""
obsdata=""
consultantname=""
consultantname2=""
statut=""
retenudata=null
data:any
sub:any;
nomClient='';
nomContact='';
besoin='';
id=""
trig: String='';
CvData:SpecBesoin[] = []

  constructor(
    private route: ActivatedRoute,
    public us: ConsultantService,
    public cs: ConsultantCrmService,
    private tf: TagfoncService,
    private tt: TagtechService,
    private tm: TagmetService,
    public communicationService: CommunicationService,
    private router: Router,
    public ts: QualiteService,
    public ds: DisponibiliteService,
    public refs: RefRechercheService,
  
    private serviceclient:ClientServiceService,

    private modalService: BsModalService,
    private rs: RechercheService,
    private dialog: MatDialog,
    private tokenStorageService: TokenStorageService,
    private titleService:Title,

  ) {
    this.communicationService.Broadcast(
      "OnChild1",
      "Résultat de la Recherche par Mots-Clés"
    );
  }

  isscore = false;
  isqualite = false;
  isnom = false;
  isprenom = false;
  istitre = false;
  istechno = false;
  isexp = false;
  isobs = false;
  searchtype = true;
  nbrfilter = 0;
  consultants = [];

  scoreFilter = new FormControl("");
  qualiteFilter = new FormControl();
  nomFilter = new FormControl("");
  prenomFilter = new FormControl("");
  titreFilter = new FormControl("");
  technoFilter = new FormControl("");
  expFilter = new FormControl();
  obsFilter = new FormControl("");
  dispoFilter = new FormControl();
  qualitecv = "";

  title = "";
  globalFilter = "";
  filteredValues = {
    score: "",
    qualite: "",
    nom: "",
    prenom: "",
    titre: "",
    techno: "",
    exp: "",
    obs: "",
    dispo: "",
  };

  //Couleur d
  isQualiteFilter = false;
  isNomFilter = false;
  isPrenomFilter = false;
  isTitreFilter = false;
  isTechFilter = false;
  isExpFilter = false;
  isDispoFilter = false;
  isScoreFilter = false;
  isObsFilter = false;
  idDateFilter = false;

  cv: CV = new CV();
  tabCV: any[] = [];
  //Order

  nameOrder = "asc";
  prenomOrder = "asc";
  qualiteOrder = "asc";
  emailOrder = "asc";
  titreOrder = "asc";
  technoOrder = "asc";
  scoreOrder = "asc";
  postalOrder = "asc";
  dateOrder = "asc";
  pagination = 0;
  exp = "";
  exps = [];
  consultant : any;

  titredesc = false;
  scoredesc = false;
  postaldesc = false;
  emaildesc = false;
  nomdesc = false;
  expdesc = false;
  prenomdesc = false;
  qualitedesc = false;
  datedesc = false;
  styleState = false;
  dataexp = [];
  dispodesc = false;
  modifdesc = false;
  obsdesc = false;
  techdesc = false;
  orderdesc = "scoreFinal";
  
  orderasc = "nojjom";
  totalConsultants = 0;
  mottext = "";
  mottext1 = "";
  mottext2 = "";
  mottext3 = "";
  mottext4 = "";
  disponibilite = "";

  dispos = [];

  isdispo = false;

  message = 'Résultat de la recherche par mots clés';

  

  poids;
  dispodata;
  Dispo = [];
  motcle = [];
  shownext = false;
  showprevious = false;
  showlastpage = false;
  showfirstpage = false;
  motrecherche = [];

  @ViewChildren(MatPaginator) paginator: MatPaginator;
  public pageSize = 30;
  public currentPage = 1;
  public totalSize = 0;
  size = 0;
  totalPage: number;
  AfterViewInit() {}
  pageSizeElement = [30, 60, 120];
  width;
  height;
  ngOnInit() {
   
    this.idbesoin = window.localStorage.getItem("idbesoin");

    this.sub = this.route
    this.cs.getbesoinbyid(this.idbesoin).subscribe((data)=>{
      if(data!==null){
        console.log(data)
        this.besoin=data.description
        this.statut=data.statut
        this.gestionnairerh = data.trigGestionnaireRH
        this.date = data.dateSaisie
        this.ao = data.ao.nomFichier
        this.ic = data.indiceDeConfiance===null ? 'Faible' :data.indiceDeConfiance
        this.commercial = data.trigBesoin
        window.localStorage.setItem("contact",data.idContact);
        window.localStorage.setItem("client",data.idClient);
      
        
        this.serviceclient.FindById(data.idClient).subscribe((data) => {
        
          this.nomClient = data.nom;  
      this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)

        });
    
        this.serviceclient.FindCotactById(data.idContact).subscribe((data) => {
          this.nomContact = data.nom + " "+ data.prenom
      this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)

        });
      }
      this.titleService.setTitle("Besoin : " + this.nomContact+"-"+this.nomClient)
    })

    this.qualitecvsearch=window.localStorage.getItem("qualite").split(',');
    this.expsearch=window.localStorage.getItem("exp").split(',');
    this.disposearch=window.localStorage.getItem("dispo").split(',');
    this.zonesearch=window.localStorage.getItem("geo").split(',');
    this.zonesearchtemp=window.localStorage.getItem("geotemp").split(',');

    if(this.zonesearchtemp[0]!==""){
      this.motrecherche=this.motrecherche.concat(this.zonesearchtemp)
    }

    if(this.qualitecvsearch[0]!==""){
      this.motrecherche=this.motrecherche.concat(this.qualitecvsearch)
    }

    if(this.disposearch[0]!==""){
      this.motrecherche=this.motrecherche.concat(this.disposearch)
    }
    
    if(this.expsearch[0]!==""){
      this.motrecherche=this.motrecherche.concat(this.expsearch)
    }
    
 
    
   

 


 
    this.loader()
    window.scroll(0,0);
   
        this.idbesoin = window.localStorage.getItem("idbesoin");
        this.besoin=  window.localStorage.getItem('Besoin');
        this.nomClient= window.localStorage.getItem('Nomclient');
        this.nomContact= window.localStorage.getItem('NomContact');
        let {width,height} = window.screen
        this.width = width;
        this.height = height;
        if((width>=1366*0.9 && width<=1366*1.1) && (height>=768*0.9 && height<=768*1.1) ){
          this.pageSizeElement = [25,50,100]
          this.pageSize=25
        }
        else if((width>=1920*0.9 && width<=1920*1.1) && (height>=1080*0.9 && height<=1080*1.1) ){
          this.pageSizeElement = [30,60,120]
          this.pageSize=30
        }

    this.getTags()
   
    
    const user = this.tokenStorageService.getUser()['trigramme'];
    
    this.sub = this.route
    .queryParams
    .subscribe(params => {
    this.qu = params.qualite;
    this.dis = params.dispo;
    this.expert = params.experience;
    this.zone = params.zone;
    this.searchtype=params.searchtype;
    this.size = params.input.length;
 
    this.poids=params.poids;
    



   
   if (typeof params.input === 'string' || params.input instanceof String){
  
    this.motcle.push(`${params.input}(P${1})`)

  }else{
    
   for(let i =0 ;i<params.input.length;i++){
    let k = i+1
    this.motcle.push(`${params.input[i]}(P${k})`)
  }
  }

  this.motcletext = this.motcle.join(` ${ window.localStorage.getItem("and")} `)
  

 
  this.mottext = this.motrecherche.join(" / ")
  this.mottext = this.motcletext +" / "+ this.mottext
  this.mottext = this.mottext.replace(/,/g, ' / ')
  



    });

    this.Search()
    this.getDisponibilite();
    this.getQualite();
    this.getexp()

  }
  AddCv(tgt: any, isChecked: boolean) {
    this.cs.findConsultantbyId(tgt.idC).subscribe((data2) => {
      console.log(data2)
      this.consultant = data2;

      this.cv.cheminCV = this.consultant.cv[0].cheminCV;
      this.cv.dateEnvoiCV = this.consultant.cv[0].dateCV;
      this.cv.idConsultant = tgt.idC;
      this.cv.nomCV = this.consultant.cv[0].nomCV;
      this.cv.nomConsultant = tgt.nom;
      this.cv.prenomConsultant = tgt.prenom;
      if (isChecked) {
        this.tabCV.push(this.cv);
      } else {
        let index = this.tabCV.findIndex((x) => x.idConsultant === tgt.idC);
        if (index != -1) this.tabCV.splice(index, 1);
      }
    });
    this.cv = new CV();
  }
  SelectionCV() {

 

    this.tabCV.map((data) => {
      data["dateEnvoiCV"] = "";
    });
    let data = {
      data: this.tabCV,
    };


  

    this.loader()
    
    this.cs.selectCV(data.data, this.idbesoin).subscribe((data) => {
     if(data){
      this.dialog.closeAll();
      let data2 = {
        data,
      };
      console.log(data)
      window.localStorage.setItem(
        this.tokenStorageService.getUser()['trigramme'],
        JSON.stringify(data2)
      );
      if (data) {
        this.Retour();
        window.localStorage.setItem(
          this.tokenStorageService.getUser()['trigramme'],
          JSON.stringify(data2)
        );
      }
     }
    });
    console.log(this.tabCV);
  }
  Retour() {
    this.router.navigate(["page-besoin"], { queryParams: {besoin: window.localStorage.getItem("idbesoin")}});
  }
  RetourRecherche() {
    this.router.navigate(["rechmcrhcrm"], { queryParams: {besoin: window.localStorage.getItem("idbesoin")}});
  }

  

  proncate_in(event,title){

    // let k = document.getElementById(event.path[0].id)
    // if(k.scrollWidth > k.clientWidth){
    //   k.style.backgroundColor = "#ffe268"
    //   k.setAttribute('title',title)
    // }
    // else{
    //   if(k.hasAttribute('title')){
    //     k.removeAttribute('title')
    //   }
    // }

  }
  proncate_out(event){
    // let k = document.getElementById(event.path[0].id)
    // if(k.scrollWidth > k.clientWidth){
    //   k.style.backgroundColor = "transparent"
     
    // }
  } 
  getexp() {
    if (this.expsearch.length == 0 || this.expsearch[0] === "") {
      this.rs.getexperience().subscribe((data) => {
        for (const i in data) {
          this.exps.push({ value: data[i].libelle, name: data[i].libelle });
        }
        this.exps.push({ value: "NR", name: "NR" });
      });
    } else {
      for (const i in this.expsearch) {
        this.exps.push({ value: this.expsearch[i], name: this.expsearch[i] });
      }
    }
  }

  getDisponibilite() {
    if (this.disposearch.length == 0 || this.disposearch[0] === "") {
      this.ds.getRefs().subscribe((data2: {}) => {
        // tslint:disable-next-line: forin
        for (const i in data2) {
          this.Dispo.push({ value: data2[i].libelle, name: data2[i].libelle });
        }
        this.Dispo.push({ value: "NR", name: "NR" });
      });
    } else {
      for (const i in this.disposearch) {
        this.Dispo.push({
          value: this.disposearch[i],
          name: this.disposearch[i],
        });
      }
    }
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0, no_words).join(" ");
  }

  getQualite() {
    if (this.qualitecvsearch.length == 0 || this.qualitecvsearch[0] === "") {
      this.ts.getRefs().subscribe((data2: {}) => {
        // tslint:disable-next-line: forin
        for (const i in data2) {
          this.Quali.push({ value: data2[i].libelle, name: data2[i].libelle });
        }
      });
    } else {
      for (const i in this.qualitecvsearch) {
        this.Quali.push({
          value: this.qualitecvsearch[i],
          name: this.qualitecvsearch[i],
        });
      }
    }
  }
  emitPagination(e) {
    this.gotoTop();
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.currentPage = this.currentPage + 1;
    this.goto(e.pageIndex + 1);

    this.iterator();
  }
  private iterator() {
    //this.dataSource = part;
  }
  openAlertDialog() {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "aucun consultant trouvé",
        buttonText: {
          cancel: "OK",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  yvesColorEltSearch(index, data) {
    data[index]["nom"] =
      this.nomFilter.value != ""
        ? data[index]["nom"].replace(
            new RegExp(
              "(" + this.escapeRegExp(this.nomFilter.value) + ")",
              "gi"
            ),
            "<strong class='strong'>$1</strong>"
          )
        : data[index]["nom"];
    data[index]["prenom"] =
      this.prenomFilter.value != ""
        ? data[index]["prenom"]
            .toString()
            .replace(
              new RegExp(
                "(" + this.escapeRegExp(this.prenomFilter.value) + ")",
                "gi"
              ),
              "<strong class='strong'>$1</strong>"
            )
        : data[index]["prenom"];
    data[index]["titre"] =
      this.titreFilter.value != ""
        ? data[index]["titre"]
            .toString()
            .replace(
              new RegExp(
                "(" + this.escapeRegExp(this.titreFilter.value) + ")",
                "gi"
              ),
              "<strong class='strong'>$1</strong>"
            )
        : data[index]["titre"];
    data[index]["tech"] =
      this.technoFilter.value != ""
        ? data[index]["tech"]
            .toString()
            .replace(
              new RegExp(
                "(" + this.escapeRegExp(this.technoFilter.value) + ")",
                "gi"
              ),
              "<strong class='strong'>$1</strong>"
            )
        : data[index]["tech"];
    data[index]["exp"] =
      this.expFilter.value != ""
        ? data[index]["exp"]
            .toString()
            .replace(
              new RegExp(
                "(" + this.escapeRegExp(this.expFilter.value) + ")",
                "gi"
              ),
              "<strong class='strong'>$1</strong>"
            )
        : data[index]["exp"];
    //data[index]['codedispo']["libelle"] =  (this.dispoFilter.value != "")? data[index]['codedispo']["libelle"].toString().replace(new RegExp('('+this.escapeRegExp(this.dispoFilter.value))+')', 'gi',"<strong class='strong'>$1</strong>") :data[index]['codedispo']["libelle"]
    data[index]["obs"] =
      this.obsFilter.value != ""
        ? data[index]["obs"]
            .toString()
            .replace(
              new RegExp(
                "(" + this.escapeRegExp(this.obsFilter.value) + ")",
                "gi"
              ),
              "<strong class='strong'>$1</strong>"
            )
        : data[index]["obs"];
  }

  escapeRegExp(s) {
    if (s != null) {
      return s.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&");
    }
    return "";
  }
  getRech14(data2) {
    window.scrollTo(0, 0);
    this.changeColor();
    this.getTags();
    for (let i = 0; i < this.tech.length; i++) {
      this.tech[i] = decodeURIComponent(this.tech[i]);
    }
    if (data2.total === 0) {
      this.openAlertDialog();
    }

    this.consultants = data2.consultants;

    for (const i in data2.consultants) {
      var techno = new Array();
      var obsrv = new Array();

      // if((data2.consultants[i].dispo === this.qu)&&(data2.consultants[i].exp === this.expert)&&(data2.consultants[i].postal === this.zone)){
      this.rech.push(data2.consultants[i]);

      for (const j in data2.consultants[i].obs) {
        obsrv.push(data2.consultants[i].obs[j].libelleObs);
      }
      for (const j in data2.consultants[i].techno) {
        techno.push(data2.consultants[i].techno[j].libelle);
      }
      var tech = techno.join(",");
      var obs = obsrv.join(",");

      data2.consultants[i].obs = obs;
      data2.consultants[i].tech = tech;

      this.rech.push(data2.consultants[i]);

      this.test = this.rech.length;

      // }
      this.yvesColorEltSearch(i, this.consultants);
      this.getTags();
    }

    this.totalSize = data2.total;
    this.totalPage = data2.totalPage;
    this.totalConsultants = data2.totalConsultants;
    this.dialog.closeAll();
  }

  getRech16(trigramme, input) {
    this.rs.getConsultants(input).subscribe(function (data) {
      this.tempdataConsultant = data;
    });
  }

  updateFilter(c: string, a: boolean, j: number) {}

  selectedRow: any;

  selectedRowIndex: number;

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  activeColumn: string;

  onSortEvent(event) {
    var ELEM: Element[] = [];
    if (this.filtest === false) {
      if (event.active === "Score") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.scoreFinal > b.scoreFinal) {
              return -1;
            } else if (a.scoreFinal < b.scoreFinal) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.scoreFinal < b.scoreFinal) {
              return -1;
            } else if (a.scoreFinal > b.scoreFinal) {
              return 1;
            }
            return 0;
          });
        }
      }
      if (event.active === "Prenom") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.prenom > b.prenom) {
              return -1;
            } else if (a.prenom < b.prenom) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.prenom < b.prenom) {
              return -1;
            } else if (a.prenom > b.prenom) {
              return 1;
            }
            return 0;
          });
        }
      }
      if (event.active === "Nom") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.nom > b.nom) {
              return -1;
            } else if (a.nom < b.nom) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.nom < b.nom) {
              return -1;
            } else if (a.nom > b.nom) {
              return 1;
            }
            return 0;
          });
        }
      }
      if (event.active === "Titre") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.titre > b.titre) {
              return -1;
            } else if (a.titre < b.titre) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.titre < b.titre) {
              return -1;
            } else if (a.titre > b.titre) {
              return 1;
            }
            return 0;
          });
        }
      }
      if (event.active === "Techno") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.techno > b.techno) {
              return -1;
            } else if (a.techno < b.techno) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.techno < b.techno) {
              return -1;
            } else if (a.techno > b.techno) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (event.active === "Exp") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.exp > b.exp) {
              return -1;
            } else if (a.exp < b.exp) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.exp < b.exp) {
              return -1;
            } else if (a.exp > b.exp) {
              return 1;
            }
            return 0;
          });
        }
      }
      if (event.active === "Obs") {
        if (event.direction === "asc") {
          this.rech.sort(function (a, b) {
            if (a.obs > b.obs) {
              return -1;
            } else if (a.obs < b.obs) {
              return 1;
            }
            return 0;
          });
        }
        if (event.direction === "desc") {
          this.rech.sort(function (a, b) {
            if (a.obs < b.obs) {
              return -1;
            } else if (a.obs > b.obs) {
              return 1;
            }
            return 0;
          });
        }
      }
    }
  }

  View(row) {
    const dialogRef = this.dialog.open(PopresultComponent, {
      data: {
        idC: row.idC,
        motcles: row.motsCles,
        buttonText: {
          cancel: "Retour",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      ///this.ngOnInit();
    });
  }

  Select(row) {
    // this.router.navigate(['/fichec'], { queryParams: { id: row.idC } });
    window.open(BASE_URL + "/fichec?id=" + row.idC, "_blank");
  }
  opentech(e, data) {
    e.preventDefault();
    const dialogRef = this.dialog.open(PopupdataComponent, {
      data: {
        message: data.techno[1],
      },
    });
  }
  openobservation(e, data) {
    e.preventDefault();
    const dialogRef = this.dialog.open(PopupdataComponent, {
      data: {
        message: data.obs[1],
      },
    });
  }

  sort(e) {
    this.sortBy = e;
    if (e === "email") {
      this.emaildesc = !this.emaildesc;
      if (this.emaildesc) {
        this.orderasc = "ema5il";
        this.orderdesc = "trigramme";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "trigramme";
        this.orderdesc = "ema5il";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.postaldesc = false;
      this.nomdesc = false;

      this.prenomdesc = false;
      this.qualitedesc = false;
      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.datedesc = false;
      this.techdesc = false;
    }
    if (e === "nom") {
      this.nomdesc = !this.nomdesc;
      if (this.nomdesc) {
        this.orderasc = "nokm";
        this.orderdesc = "nom";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "nom";
        this.orderdesc = "nomm";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.postaldesc = false;
      this.prenomdesc = false;
      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.techdesc = false;

      this.qualitedesc = false;
    }
    if (e === "prenom") {
      this.prenomdesc = !this.prenomdesc;
      if (this.prenomdesc) {
        this.orderasc = "postakl";
        this.orderdesc = "prenom";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "prenom";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.postaldesc = false;

      this.qualitedesc = false;
      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.techdesc = false;
    }
    if (e === "score") {
      this.scoredesc = !this.scoredesc;
      if (this.scoredesc) {
        this.orderasc = "nokm";
        this.orderdesc = "scoreFinal";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "scoreFinal";
        this.orderdesc = "scorem";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.titredesc = false;
      this.emaildesc = false;
      this.postaldesc = false;
      this.qualitedesc = false;
      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.techdesc = false;
    }
    if (e === "titre") {
      this.titredesc = !this.titredesc;
      if (this.titredesc) {
        this.orderasc = "titre5";
        this.orderdesc = "titre";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "titre";
        this.orderdesc = "qua55lite";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.scoredesc = false;
      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.techdesc = false;
      this.postaldesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;
    }
    if (e === "qualite") {
      this.qualitedesc = !this.qualitedesc;
      if (this.qualitedesc) {
        this.orderasc = "qualitecv.ordre";
        this.orderdesc = "h";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "qualjite";
        this.orderdesc = "qualitecv.ordre";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;

      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
      this.techdesc = false;
      this.postaldesc = false;
    }

    if (e === "tech") {
      this.techdesc = !this.techdesc;
      if (this.techdesc) {
        this.orderasc = "postakl";
        this.orderdesc = "techno";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "techno";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.expdesc = false;
      this.datedesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;

      this.dispodesc = false;
      this.modifdesc = false;
      this.dispodesc = false;
      this.obsdesc = false;
    }
    if (e === "dispo") {
      this.dispodesc = !this.dispodesc;
      if (this.dispodesc) {
        this.orderasc = "postakl";
        this.orderdesc = "codedispo.ordre";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "codedispo.ordre";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.datedesc = false;
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;
      this.techdesc = false;

      this.expdesc = false;
      this.modifdesc = false;
      this.obsdesc = false;
    }

    if (e === "date") {
      this.datedesc = !this.datedesc;
      if (this.datedesc) {
        this.orderasc = "postakl";
        this.orderdesc = "dateDerniereMaj";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "dateDerniereMaj";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;
      this.techdesc = false;

      this.expdesc = false;
      this.modifdesc = false;
      this.obsdesc = false;
    }
    if (e === "obs") {
      this.obsdesc = !this.obsdesc;
      if (this.obsdesc) {
        this.orderasc = "postakl";
        this.orderdesc = "obs.libelleObs";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "obs.libelleObs";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;
      this.techdesc = false;

      this.expdesc = false;

      this.modifdesc = false;
      this.datedesc = false;
    }

    if (e === "exp") {
      this.expdesc = !this.expdesc;
      if (this.expdesc) {
        this.orderasc = "postakl";
        this.orderdesc = "exp";
        this.SearchTriFiltrage();
      } else {
        this.orderasc = "exp";
        this.orderdesc = "po5stal";
        this.SearchTriFiltrage();
      }
      this.titredesc = false;
      this.scoredesc = false;
      this.emaildesc = false;
      this.nomdesc = false;
      this.prenomdesc = false;
      this.qualitedesc = false;
      this.techdesc = false;

      this.obsdesc = false;
      this.modifdesc = false;
      this.datedesc = false;
    }
  }
  openPopup(e, data) {
    e.preventDefault();
    const dialogRef = this.dialog.open(PopupdataComponent, {
      data: {
        message: data,
      },
    });
  }
  getByName(nom, prenom, qualite, techno, titre, obs, dispo, score, page, exp) {
    this.getTags();

    this.foncopen = false;
    this.techopen = false;
    this.metopen = false;
    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Chargement en cours...",
        },
      });
      await delay(1000);
      dialogRef.close();
    })();
    let user = this.tokenStorageService.getUser();

    this.rs
      .getRechercheConsultantsMC(
        this.size,
        this.searchtype,
        user.trigramme,
        qualite,
        dispo,
        exp,
        this.orderdesc,
        this.orderasc,
        nom,
        prenom,
        titre,
        obs,
        techno,
        this.tech,
        this.met,
        this.fonc,
        this.zonesearch,
        this.disposearch,
        this.expsearch,
        this.qualitecvsearch,
        page - 1,
        this.pageSize
      )
      .subscribe((data2) => {
        this.getRech14(data2);
      });
  }

  gotopage(nom, prenom, qualite, tech, titre, obs, dispo, score, page, exp) {
    this.gotoTop();
    let user = this.tokenStorageService.getUser();

    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Chargement en cours...",
        },
      });
      await delay(2000);
      dialogRef.close();
    })();

    this.rs
      .getRechercheConsultantsMC(
        this.size,
        this.searchtype,
        user.trigramme,
        qualite,
        dispo,
        exp,
        this.orderdesc,
        this.orderasc,
        nom,
        prenom,
        titre,
        obs,
        tech,
        this.tech,
        this.met,
        this.fonc,
        this.zonesearch,
        this.disposearch,
        this.expsearch,
        this.qualitecvsearch,
        page - 1,
        this.pageSize
      )
      .subscribe((data2) => {
        this.getRech14(data2);
      });
  }
  Search() {
    this.getTags();
    this.gotoTop();
    this.loader();

    this.getByName(
      this.nomFilter.value,
      this.prenomFilter.value,
      this.qualitecv,
      this.technoFilter.value,
      this.titreFilter.value,
      this.obsFilter.value,
      this.disponibilite,

      this.scoreFilter.value,
      this.currentPage,
      this.exp
    );
  }

  SearchTriFiltrage() {
    this.getTags();
    this.showfilter = true;
    this.currentPage = 1;
    this.styleState = true;
    this.gotoTop();
    this.loader();

    this.getByName(
      this.nomFilter.value,
      this.prenomFilter.value,
      this.qualitecv,
      this.technoFilter.value,
      this.titreFilter.value,
      this.obsFilter.value,

      this.disponibilite,

      this.scoreFilter.value,
      1,
      this.exp
    );
  }

  resetFilter() {
    this.qualitecv = "";
    this.disponibilite = "";
    this.exp = "";

    this.isscore = false;
    this.isqualite = false;
    this.isnom = false;
    this.isprenom = false;
    this.istitre = false;
    this.istechno = false;
    this.isexp = false;
    this.isobs = false;

    this.foncopen = false;
    this.techopen = false;
    this.metopen = false;
    this.scoreFilter = new FormControl("");
    this.qualiteFilter = new FormControl();
    this.nomFilter = new FormControl("");
    this.prenomFilter = new FormControl("");
    this.titreFilter = new FormControl("");
    this.technoFilter = new FormControl("");
    this.expFilter = new FormControl();
    this.obsFilter = new FormControl("");
    this.dispoFilter = new FormControl();

    this.showfilter = false;
    this.filteredValues = {
      score: "",
      qualite: "",
      nom: "",
      prenom: "",
      titre: "",
      techno: "",
      dispo: "",
      exp: "",
      obs: "",
    };
    this.tech = [];
    this.fonc = [];
    this.met = [];

    this.techtext = "";
    this.fonctext = "";
    this.mettext = "";
    this.techopen = false;
    this.metopen = false;
    this.foncopen = false;
    this.Search();
  }

  loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
           Chargement en cours`,
      },
      disableClose: true,
    });
  }

  opentechchoice() {
    this.techopen = !this.techopen;
    this.foncopen = false;
    this.metopen = false;
  }
  stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  opentechchoice2() {
    this.metopen = !this.metopen;
    this.techopen = false;
    this.foncopen = false;
  }
  opentechchoice3() {
    this.foncopen = !this.foncopen;
    this.techopen = false;
    this.metopen = false;
  }

  decode(e) {
    return decodeURIComponent(e);
  }
  updatetech(e) {
    e = decodeURIComponent(e);
    this.foncopen = false;
    this.metopen = false;
    if (!this.tech.includes(e)) {
      this.tech.push(e);
    } else {
      this.Removetech(e);
    }
    this.techtext = this.tech.join(" / ");
    this.techopen = false;
  }
  Removetech(id): void {
    if (this.tech.includes(id)) {
      this.tech = this.tech.filter((e) => e !== id);
    }
  }

  updatemet(e) {
    this.foncopen = false;
    this.techopen = false;
    if (!this.met.includes(e)) {
      this.met.push(e);
    } else {
      this.Removemet(e);
    }
    this.mettext = this.met.join(" / ");
  }
  Removemet(id): void {
    if (this.met.includes(id)) {
      this.met = this.met.filter((e) => e !== id);
    }
    this.metopen = false;
  }

  updatefonc(e) {
    this.techopen = false;
    this.metopen = false;
    if (!this.fonc.includes(e)) {
      this.fonc.push(e);
    } else {
      this.Removefonc(e);
    }
    this.fonctext = this.fonc.join(" / ");
  }
  Removefonc(id): void {
    if (this.fonc.includes(id)) {
      this.fonc = this.fonc.filter((e) => e !== id);
    }
    this.foncopen = false;
  }

  getTags() {
    let user = this.tokenStorageService.getUser();
    this.tt
      .getTagtechRMC(
        user.trigramme,
        this.qualitecv,
        this.disponibilite,
        this.exp,
        this.nomFilter.value,
        this.prenomFilter.value,
        this.titreFilter.value,
        this.obsFilter.value,
        this.technoFilter.value,
        this.fonc,
        this.met,
        this.tech,
        this.zonesearch,
        this.disposearch,
        this.expsearch,
        this.qualitecvsearch
      )
      .subscribe((data2) => {
        this.TagTechnique = data2;
      });
    this.tm
      .getTagmetRMC(
        user.trigramme,
        this.qualitecv,
        this.disponibilite,
        this.exp,
        this.nomFilter.value,
        this.prenomFilter.value,
        this.titreFilter.value,
        this.obsFilter.value,
        this.technoFilter.value,
        this.fonc,
        this.met,
        this.tech,
        this.zonesearch,
        this.disposearch,
        this.expsearch,
        this.qualitecvsearch
      )
      .subscribe((data2) => {
        this.TagMetier = data2;
      });
    this.tf
      .getTagfonRMC(
        user.trigramme,
        this.qualitecv,
        this.disponibilite,
        this.exp,
        this.nomFilter.value,
        this.prenomFilter.value,
        this.titreFilter.value,
        this.obsFilter.value,
        this.technoFilter.value,
        this.fonc,
        this.met,
        this.tech,
        this.zonesearch,
        this.disposearch,
        this.expsearch,
        this.qualitecvsearch
      )
      .subscribe((data2) => {
        this.TagFonction = data2;
      });
  }
  renderScore(score) {
    if (score === 0) {
      return "≈" + score;
    }
    return score;
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  Truncate(text) {
    return text.substr(0, 40);
  }
  TruncateObs(text) {
    return text.substr(0, 40);
  }

  goto(page) {
    if (parseInt(page)) {
      if (page - 1 <= this.totalPage) {
        this.currentPage = page;
        this.Search();
      } else {
        let total = this.totalPage + 1;
        this.Alert("il n'existe que " + total + " pages");
      }
    } else {
      this.Alert("Page invalide");
    }
  }
  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  NexPage() {
    this.currentPage = this.currentPage - 1;
    this.currentPage = this.currentPage + 2;
    this.Search();
  }
  previousPage() {
    this.currentPage = this.currentPage - 1;
    this.Search();
  }
  FirstPage() {
    this.currentPage = 1;
    this.showprevious = false;
    this.showfirstpage = false;

    this.shownext = true;
    this.showlastpage = true;
    this.Search();
  }
  LastPage() {
    this.currentPage = this.totalPage + 1;
    this.shownext = true;
    this.showlastpage = true;
    this.showprevious = false;
    this.showfirstpage = false;
    if (this.totalPage + 1 === this.currentPage) {
      this.shownext = false;
      this.showlastpage = false;
    }
    this.Search();
  }

  getNext() {
    if (this.currentPage < this.totalPage + 1) {
      return true;
    }
    return false;
  }
  getpreviousMove() {
    if (this.currentPage > 1) {
      return true;
    }
    return false;
  }
  getPrevious() {
    if (this.currentPage > 1) {
      return true;
    }
    return false;
  }

  getlastpage() {
    if (this.currentPage < this.totalPage + 1) {
      return true;
    }
    return false;
  }
  ChangeItemPerPage(e) {
    this.pageSize = e;
    this.SearchTriFiltrage();
  }

  TruncateTitre(text) {
    return text.substr(0, 26);
  }
  TruncateIntitule(text) {
    return text.substr(0, 26);
  }
  renderPrenom(prenom) {
    return prenom[0].toUpperCase() + prenom.slice(1).toLowerCase();
  }
  renderDispo(item) {
    return item ? item.libelle : "";
  }

  setdata(e: string, event: any) {
    // let k = document.getElementById(event.path[0].id);

    // this.title = e;
    // if (k) {
    //   if (k.scrollWidth > k.clientWidth) {
    //     event.path[0].bgColor = "#FFFFCC";

    //     k.setAttribute("title", this.stripHtml(k.innerHTML));
    //     //this.open(event);
    //   }
    // }
  }

  open(e: any) {
    const ele = document.getElementById("element");

    const menu = document.getElementById("menu");
    if (ele != null && menu != null) {
      e.preventDefault();

      const rect = ele.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Set the position for menu
      menu.style.top = `${y + 300}px`;
      menu.style.left = `${x}px`;

      // Show the menu
      menu.classList.remove("hidden");
    }
  }
  hover() {
    const ele = document.getElementById("element");
    const menu = document.getElementById("menu");
    if (ele != null && menu != null) {
      ele.addEventListener("context", function (e: any) {
        e.preventDefault();

        const rect = ele.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Set the position for menu
        menu.style.top = `${y + 40}px`;
        menu.style.left = `${x}px`;

        // Show the menu
        menu.classList.remove("hidden");

        document.addEventListener("click", documentClickHandler);
      });

      // Hide the menu when clicking outside of it
      const documentClickHandler = function (e: any) {
        const isClickedOutside = !menu.contains(e.target);
        if (isClickedOutside) {
          menu.classList.add("hidden");
          document.removeEventListener("click", documentClickHandler);
        }
      };
    }
  }
  close(e: any) {
   // e.path[0].bgColor = "transparent";

    const menu = document.getElementById("menu");
    if (menu != null) {
      const isClickedOutside = !menu.contains(e.target);
      if (isClickedOutside) {
        menu.classList.add("hidden");
      }
    }
  }

  changeColor() {
    if (this.scoreFilter.value === "") {
      this.isScoreFilter = false;
    } else {
      this.isScoreFilter = true;
      this.showfilter = true;
    }
    if (this.nomFilter.value === "") {
      this.isNomFilter = false;
    } else {
      this.isNomFilter = true;
      this.showfilter = true;
    }
    if (this.prenomFilter.value === "") {
      this.isPrenomFilter = false;
    } else {
      this.isPrenomFilter = true;
      this.showfilter = true;
    }
    if (this.titreFilter.value === "") {
      this.isTitreFilter = false;
    } else {
      this.isTitreFilter = true;
      this.showfilter = true;
    }
    if (this.technoFilter.value === "") {
      this.isTechFilter = false;
    } else {
      this.isTechFilter = true;
      this.showfilter = true;
    }

    if (this.exp === "") {
      this.isExpFilter = false;
    } else {
      this.isExpFilter = true;
      this.showfilter = true;
    }
    if (this.disponibilite === "") {
      this.isDispoFilter = false;
    } else {
      this.isDispoFilter = true;
      this.showfilter = true;
    }

    if (this.obsFilter.value === "") {
      this.isObsFilter = false;
    } else {
      this.isObsFilter = true;
      this.showfilter = true;
    }
  }
}
