import { TokenStorageService } from './../../services/token-storage.service';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';


@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, AfterViewInit{

  constructor(private dservice: DashboardService, private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal, private tokenService: TokenStorageService) { }

  @Input() selections: any;
  @Input() actionsData: any;
  trigrammes = []

  trigramme: string = "";
  isAdmin: boolean = false;
  roles: string[] = []

  ngOnInit(): void {
    const user = this.tokenService.getUser();
    this.trigramme = user.trigramme;
    this.isAdmin = user.roles.lastIndexOf('Gestionnaire')>0 || user.roles.lastIndexOf('Facturation')>0;

    this.trigrammes =  this.selections.trigrammes

    if(this.isAdmin) {
      this.dservice.getActions().subscribe(
        (data: any) => {
          this.actionsData = data;
          this.isLoading = false;
          this.filtrerAction()
          this.loadChanged.emit(this.isLoading);
        }
      )
    } else {
      this.trigAction = this.trigramme;
      this.isLoading = false;
      this.filtrerAction()
      this.loadChanged.emit(this.isLoading);
    }

  }

  ngAfterViewInit(): void {
    if(!this.isLoading) {
      this.actions = Highcharts.chart('actionChart', {
      chart: {
        type: 'spline'
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: {
          text: 'Nombre d\'actions'
        }
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 3,
            lineWidth: 1
          }
        }
      },

      series: []
    })
    }
  }

  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();

  periodeAction = "semaine";
  trigAction = "Tous";
  actions: any;

  uncheckAll(divid: string): void {
    let checks: any;
    checks = document.querySelectorAll('#' + divid + ' input[type="checkbox"]');
    for(var i =0; i< checks.length;i++){
        var check = checks[i];
        if(!check.disabled){
            check.checked = false;
        }
    }
  }


  filtrerAction() : void {

    let categories: string[] = []
    let temp = [...this.series4]

    if (this.periodeAction === "semaine") {

      let data1 = [0,0,0,0,0]
      let data2 = [0,0,0,0,0]
      let data3 = [0,0,0,0,0]
      let data4 = [0,0,0,0,0]
      let data5 = [0,0,0,0,0]
      let data6 = [0,0,0,0,0]
      let data7 = [0,0,0,0,0]
      let data8 = [0,0,0,0,0]
      let data9 = [0,0,0,0,0]
      let data10 = [0,0,0,0,0]
      let data11 = [0,0,0,0,0]
      let data12 = [0,0,0,0,0]
      let data13 = [0,0,0,0,0]
      let data14 = [0,0,0,0,0]

      let dates : Date[] = []

      let weekend : number[] = [0, 6]

      for (let i = 6; i >= 0; i--) {
        if (weekend.indexOf(new Date(Date.now()-i*86400000).getDay())<0) {
          dates.push(new Date(Date.now()-i*86400000));
        }
      }

      dates.forEach(elt => {
        categories.push(this.datePipe.transform(elt, 'dd-MM-yyyy')!);
      });

      if(this.trigAction === "Tous") {
        for (let i = 0; i < dates.length; i++) {
          this.actionsData.forEach((elt: any) => {
            if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
              switch (elt.type) {
                case "AJOUT_CONSULTANT":
                  data1[i] += 1
                  break;
                case "MAJ_CV":
                  data2[i] += 1
                  break;
                case "MAJ_QUALITE_CV":
                  data3[i] += 1
                  break;
                case "MAJ_TJM":
                  data4[i] += 1
                  break;
                case "MAJ_TECHNOLOGIE":
                  data5[i] += 1
                  break;
                case "MAJ_OBSERVATION":
                  data6[i] += 1
                  break;
                case "MAJ_TAG":
                  data7[i] += 1
                  break;
                case "AJOUT_RAPPEL":
                  data8[i] += 1
                  break;
                case "MAJ_CLIENT":
                  data9[i] += 1
                  break;
                case "MAJ_DISPO":
                  data10[i] += 1
                  break;
                case "MAJ_COLLABORATEUR":
                  data11[i] += 1
                  break;
                case "SUPPRESSION_CONSULTANT":
                  data12[i] += 1
                  break;
                case "RECHERCHE_PAR_MOT_CLE":
                  data13[i] += 1
                  break;
                case "RECHERCHE_PAR_TAG":
                  data14[i] += 1
                  break;
                default:
                  break;
              }
            }
          });
        }

        this.series4 = [
          {
            display:false,
            index:0,
            serie: {
              name: 'Ajout_consultant',
              type: 'spline',
              color:'black',
              data: data1
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_CV',
              type: 'spline',
              color: 'gray',
              data: data2
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_QualitéCV',
              type: 'spline',
              color: 'blue',
              data: data3
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_TJM',
              type: 'spline',
              color: 'purple',
              data: data4
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Technologie',
              type: 'spline',
              color: 'red',
              data: data5
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Observation',
              type: 'spline',
              color: 'yellow',
              data: data6
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Tags',
              type: 'spline',
              color: 'orange',
              data: data7
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Ajout_Rappel',
              type: 'spline',
              color: 'pink',
              data: data8
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Client',
              type: 'spline',
              color: 'green',
              data: data9
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Dispo',
              type: 'spline',
              color: 'brown',
              data: data10
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Collaborateur',
              type: 'spline',
              color: 'cyan',
              data: data11
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'suppression_Consultant',
              type: 'spline',
              color: 'magenta',
              data: data12
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parMotsClés',
              type: 'spline',
              color: 'tan',
              data: data13
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parTags',
              type: 'spline',
              color: 'navy',
              data: data14
            }
          }
        ]

        // this.uncheckAll("controlActions");

        this.actions = Highcharts.chart('actionChart', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: 'Nombre d\'actions'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },

          series: []
        })

        this.initGraph(temp)

      } else {
        this.dservice.getUserActions(this.trigAction).subscribe(
          (data: any) => {
            for (let i = 0; i < dates.length; i++) {
              data.forEach((elt: any) => {
                if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
                  switch (elt.type) {
                    case "AJOUT_CONSULTANT":
                      data1[i] += 1
                      break;
                    case "MAJ_CV":
                      data2[i] += 1
                      break;
                    case "MAJ_QUALITE_CV":
                      data3[i] += 1
                      break;
                    case "MAJ_TJM":
                      data4[i] += 1
                      break;
                    case "MAJ_TECHNOLOGIE":
                      data5[i] += 1
                      break;
                    case "MAJ_OBSERVATION":
                      data6[i] += 1
                      break;
                    case "MAJ_TAG":
                      data7[i] += 1
                      break;
                    case "AJOUT_RAPPEL":
                      data8[i] += 1
                      break;
                    case "MAJ_CLIENT":
                      data9[i] += 1
                      break;
                    case "MAJ_DISPO":
                      data10[i] += 1
                      break;
                    case "MAJ_COLLABORATEUR":
                      data11[i] += 1
                      break;
                    case "SUPPRESSION_CONSULTANT":
                      data12[i] += 1
                      break;
                    case "RECHERCHE_PAR_MOT_CLE":
                      data13[i] += 1
                      break;
                    case "RECHERCHE_PAR_TAG":
                      data14[i] += 1
                      break;
                    default:
                      break;
                  }
                }
              });
            }

            this.series4 = [
              {
                display:false,
                index:0,
                serie: {
                  name: 'Ajout_consultant',
                  type: 'spline',
                  color:'black',
                  data: data1
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_CV',
                  type: 'spline',
                  color: 'gray',
                  data: data2
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_QualitéCV',
                  type: 'spline',
                  color: 'blue',
                  data: data3
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_TJM',
                  type: 'spline',
                  color: 'purple',
                  data: data4
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Technologie',
                  type: 'spline',
                  color: 'red',
                  data: data5
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Observation',
                  type: 'spline',
                  color: 'yellow',
                  data: data6
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Tags',
                  type: 'spline',
                  color: 'orange',
                  data: data7
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Ajout_Rappel',
                  type: 'spline',
                  color: 'pink',
                  data: data8
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Client',
                  type: 'spline',
                  color: 'green',
                  data: data9
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Dispo',
                  type: 'spline',
                  color: 'brown',
                  data: data10
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Collaborateur',
                  type: 'spline',
                  color: 'cyan',
                  data: data11
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'suppression_Consultant',
                  type: 'spline',
                  color: 'magenta',
                  data: data12
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parMotsClés',
                  type: 'spline',
                  color: 'tan',
                  data: data13
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parTags',
                  type: 'spline',
                  color: 'navy',
                  data: data14
                }
              }
            ]

            // this.uncheckAll("controlActions");

            this.actions = Highcharts.chart('actionChart', {
              chart: {
                type: 'spline'
              },
              legend: {
                enabled: false
              },
              title: {
                text: ''
              },
              xAxis: {
                categories: categories
              },
              yAxis: {
                title: {
                  text: 'Nombre d\'actions'
                }
              },
              plotOptions: {
                spline: {
                  marker: {
                    radius: 3,
                    lineWidth: 1
                  }
                }
              },

              series: []
            })

            this.initGraph(temp)
          }
        )
      }


    }

    if (this.periodeAction === "mois") {

      let data1 = [0,0,0,0,0]
      let data2 = [0,0,0,0,0]
      let data3 = [0,0,0,0,0]
      let data4 = [0,0,0,0,0]
      let data5 = [0,0,0,0,0]
      let data6 = [0,0,0,0,0]
      let data7 = [0,0,0,0,0]
      let data8 = [0,0,0,0,0]
      let data9 = [0,0,0,0,0]
      let data10 = [0,0,0,0,0]
      let data11 = [0,0,0,0,0]
      let data12 = [0,0,0,0,0]
      let data13 = [0,0,0,0,0]
      let data14 = [0,0,0,0,0]

      categories = ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']

      let dates : Date[] = []
      let semaine1: string[] = []
      let semaine2: string[] = []
      let semaine3: string[] = []
      let semaine4: string[] = []
      let semaine5: string[] = []

      for (let i = 34; i >= 0; i--) {
        dates.push(new Date(Date.now()-i*86400000));
      }

      for (let i = 0; i < 7; i++) {
        semaine1.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 7; i < 14; i++) {
        semaine2.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 14; i < 21; i++) {
        semaine3.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 21; i < 28; i++) {
        semaine4.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 28; i < 35; i++) {
        semaine5.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      if (this.trigAction === "Tous") {

        this.actionsData.forEach((elt: any) => {
          if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[0] += 1
                break;
              case "MAJ_CV":
                data2[0] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[0] += 1
                break;
              case "MAJ_TJM":
                data4[0] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[0] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[0] += 1
                break;
              case "MAJ_TAG":
                data7[0] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[0] += 1
                break;
              case "MAJ_CLIENT":
                data9[0] += 1
                break;
              case "MAJ_DISPO":
                data10[0] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[0] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[0] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[0] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[0] += 1
                break;
              default:
                break;
            }
          } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[1] += 1
                break;
              case "MAJ_CV":
                data2[1] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[1] += 1
                break;
              case "MAJ_TJM":
                data4[1] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[1] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[1] += 1
                break;
              case "MAJ_TAG":
                data7[1] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[1] += 1
                break;
              case "MAJ_CLIENT":
                data9[1] += 1
                break;
              case "MAJ_DISPO":
                data10[1] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[1] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[1] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[1] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[1] += 1
                break;
              default:
                break;
            }
          } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[2] += 1
                break;
              case "MAJ_CV":
                data2[2] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[2] += 1
                break;
              case "MAJ_TJM":
                data4[2] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[2] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[2] += 1
                break;
              case "MAJ_TAG":
                data7[2] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[2] += 1
                break;
              case "MAJ_CLIENT":
                data9[2] += 1
                break;
              case "MAJ_DISPO":
                data10[2] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[2] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[2] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[2] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[2] += 1
                break;
              default:
                break;
            }
          } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[3] += 1
                break;
              case "MAJ_CV":
                data2[3] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[3] += 1
                break;
              case "MAJ_TJM":
                data4[3] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[3] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[3] += 1
                break;
              case "MAJ_TAG":
                data7[3] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[3] += 1
                break;
              case "MAJ_CLIENT":
                data9[3] += 1
                break;
              case "MAJ_DISPO":
                data10[3] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[3] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[3] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[3] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[3] += 1
                break;
              default:
                break;
            }
          } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[4] += 1
                break;
              case "MAJ_CV":
                data2[4] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[4] += 1
                break;
              case "MAJ_TJM":
                data4[4] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[4] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[4] += 1
                break;
              case "MAJ_TAG":
                data7[4] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[4] += 1
                break;
              case "MAJ_CLIENT":
                data9[4] += 1
                break;
              case "MAJ_DISPO":
                data10[4] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[4] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[4] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[4] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[4] += 1
                break;
              default:
                break;
            }
          }
        });

        this.series4 = [
          {
            display:false,
            index:0,
            serie: {
              name: 'Ajout_consultant',
              type: 'spline',
              color:'black',
              data: data1
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_CV',
              type: 'spline',
              color: 'gray',
              data: data2
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_QualitéCV',
              type: 'spline',
              color: 'blue',
              data: data3
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_TJM',
              type: 'spline',
              color: 'purple',
              data: data4
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Technologie',
              type: 'spline',
              color: 'red',
              data: data5
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Observation',
              type: 'spline',
              color: 'yellow',
              data: data6
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Tags',
              type: 'spline',
              color: 'orange',
              data: data7
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Ajout_Rappel',
              type: 'spline',
              color: 'pink',
              data: data8
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Client',
              type: 'spline',
              color: 'green',
              data: data9
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Dispo',
              type: 'spline',
              color: 'brown',
              data: data10
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Collaborateur',
              type: 'spline',
              color: 'cyan',
              data: data11
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'suppression_Consultant',
              type: 'spline',
              color: 'magenta',
              data: data12
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parMotsClés',
              type: 'spline',
              color: 'tan',
              data: data13
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parTags',
              type: 'spline',
              color: 'navy',
              data: data14
            }
          }
        ]

        // this.uncheckAll("controlActions");

        this.actions = Highcharts.chart('actionChart', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: 'Nombre d\'actions'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },

          series: []
        })

        this.initGraph(temp)

      } else {
        this.dservice.getUserActions(this.trigAction).subscribe(
          (data: any) => {
            data.forEach((elt: any) => {
              if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[0] += 1
                    break;
                  case "MAJ_CV":
                    data2[0] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[0] += 1
                    break;
                  case "MAJ_TJM":
                    data4[0] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[0] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[0] += 1
                    break;
                  case "MAJ_TAG":
                    data7[0] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[0] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[0] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[0] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[0] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[0] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[0] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[0] += 1
                    break;
                  default:
                    break;
                }
              } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[1] += 1
                    break;
                  case "MAJ_CV":
                    data2[1] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[1] += 1
                    break;
                  case "MAJ_TJM":
                    data4[1] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[1] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[1] += 1
                    break;
                  case "MAJ_TAG":
                    data7[1] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[1] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[1] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[1] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[1] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[1] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[1] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[1] += 1
                    break;
                  default:
                    break;
                }
              } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[2] += 1
                    break;
                  case "MAJ_CV":
                    data2[2] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[2] += 1
                    break;
                  case "MAJ_TJM":
                    data4[2] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[2] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[2] += 1
                    break;
                  case "MAJ_TAG":
                    data7[2] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[2] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[2] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[2] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[2] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[2] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[2] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[2] += 1
                    break;
                  default:
                    break;
                }
              } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[3] += 1
                    break;
                  case "MAJ_CV":
                    data2[3] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[3] += 1
                    break;
                  case "MAJ_TJM":
                    data4[3] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[3] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[3] += 1
                    break;
                  case "MAJ_TAG":
                    data7[3] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[3] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[3] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[3] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[3] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[3] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[3] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[3] += 1
                    break;
                  default:
                    break;
                }
              } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[4] += 1
                    break;
                  case "MAJ_CV":
                    data2[4] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[4] += 1
                    break;
                  case "MAJ_TJM":
                    data4[4] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[4] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[4] += 1
                    break;
                  case "MAJ_TAG":
                    data7[4] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[4] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[4] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[4] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[4] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[4] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[4] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[4] += 1
                    break;
                  default:
                    break;
                }
              }
            });

            this.series4 = [
              {
                display:false,
                index:0,
                serie: {
                  name: 'Ajout_consultant',
                  type: 'spline',
                  color:'black',
                  data: data1
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_CV',
                  type: 'spline',
                  color: 'gray',
                  data: data2
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_QualitéCV',
                  type: 'spline',
                  color: 'blue',
                  data: data3
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_TJM',
                  type: 'spline',
                  color: 'purple',
                  data: data4
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Technologie',
                  type: 'spline',
                  color: 'red',
                  data: data5
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Observation',
                  type: 'spline',
                  color: 'yellow',
                  data: data6
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Tags',
                  type: 'spline',
                  color: 'orange',
                  data: data7
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Ajout_Rappel',
                  type: 'spline',
                  color: 'pink',
                  data: data8
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Client',
                  type: 'spline',
                  color: 'green',
                  data: data9
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Dispo',
                  type: 'spline',
                  color: 'brown',
                  data: data10
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Collaborateur',
                  type: 'spline',
                  color: 'cyan',
                  data: data11
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'suppression_Consultant',
                  type: 'spline',
                  color: 'magenta',
                  data: data12
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parMotsClés',
                  type: 'spline',
                  color: 'tan',
                  data: data13
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parTags',
                  type: 'spline',
                  color: 'navy',
                  data: data14
                }
              }
            ]

            // this.uncheckAll("controlActions");

            this.actions = Highcharts.chart('actionChart', {
              chart: {
                type: 'spline'
              },
              legend: {
                enabled: false
              },
              title: {
                text: ''
              },
              xAxis: {
                categories: categories
              },
              yAxis: {
                title: {
                  text: 'Nombre d\'actions'
                }
              },
              plotOptions: {
                spline: {
                  marker: {
                    radius: 3,
                    lineWidth: 1
                  }
                }
              },

              series: []
            })

            this.initGraph(temp)
          }
        )
      }

    }

    if (this.periodeAction === "semestre") {

      let data1 = [0,0,0,0,0,0]
      let data2 = [0,0,0,0,0,0]
      let data3 = [0,0,0,0,0,0]
      let data4 = [0,0,0,0,0,0]
      let data5 = [0,0,0,0,0,0]
      let data6 = [0,0,0,0,0,0]
      let data7 = [0,0,0,0,0,0]
      let data8 = [0,0,0,0,0,0]
      let data9 = [0,0,0,0,0,0]
      let data10 = [0,0,0,0,0,0]
      let data11 = [0,0,0,0,0,0]
      let data12 = [0,0,0,0,0,0]
      let data13 = [0,0,0,0,0,0]
      let data14 = [0,0,0,0,0,0]

      categories = []

      let today: Date = new Date();

      for (let i = 5; i >= 0; i--) {
        categories.push(new Date(today.getFullYear(), today.getMonth()-i, 1).toLocaleString('default', { month: 'long' }))
      }

      if (this.trigAction === "Tous") {

        this.actionsData.forEach((elt:any) => {

          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[5] += 1
                break;
              case "MAJ_CV":
                data2[5] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[5] += 1
                break;
              case "MAJ_TJM":
                data4[5] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[5] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[5] += 1
                break;
              case "MAJ_TAG":
                data7[5] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[5] += 1
                break;
              case "MAJ_CLIENT":
                data9[5] += 1
                break;
              case "MAJ_DISPO":
                data10[5] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[5] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[5] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[5] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[5] += 1
                break;
              default:
                break;
            }
          }

          for (let i = 0; i < 5; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              switch (elt.type) {
                case "AJOUT_CONSULTANT":
                  data1[4-i] += 1
                  break;
                case "MAJ_CV":
                  data2[4-i] += 1
                  break;
                case "MAJ_QUALITE_CV":
                  data3[4-i] += 1
                  break;
                case "MAJ_TJM":
                  data4[4-i] += 1
                  break;
                case "MAJ_TECHNOLOGIE":
                  data5[4-i] += 1
                  break;
                case "MAJ_OBSERVATION":
                  data6[4-i] += 1
                  break;
                case "MAJ_TAG":
                  data7[4-i] += 1
                  break;
                case "AJOUT_RAPPEL":
                  data8[4-i] += 1
                  break;
                case "MAJ_CLIENT":
                  data9[4-i] += 1
                  break;
                case "MAJ_DISPO":
                  data10[4-i] += 1
                  break;
                case "MAJ_COLLABORATEUR":
                  data11[4-i] += 1
                  break;
                case "SUPPRESSION_CONSULTANT":
                  data12[4-i] += 1
                  break;
                case "RECHERCHE_PAR_MOT_CLE":
                  data13[4-i] += 1
                  break;
                case "RECHERCHE_PAR_TAG":
                  data14[4-i] += 1
                  break;
                default:
                  break;
              }
            }
          }
        });

        this.series4 = [
          {
            display:false,
            index:0,
            serie: {
              name: 'Ajout_consultant',
              type: 'spline',
              color:'black',
              data: data1
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_CV',
              type: 'spline',
              color: 'gray',
              data: data2
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_QualitéCV',
              type: 'spline',
              color: 'blue',
              data: data3
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_TJM',
              type: 'spline',
              color: 'purple',
              data: data4
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Technologie',
              type: 'spline',
              color: 'red',
              data: data5
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Observation',
              type: 'spline',
              color: 'yellow',
              data: data6
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Tags',
              type: 'spline',
              color: 'orange',
              data: data7
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Ajout_Rappel',
              type: 'spline',
              color: 'pink',
              data: data8
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Client',
              type: 'spline',
              color: 'green',
              data: data9
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Dispo',
              type: 'spline',
              color: 'brown',
              data: data10
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Collaborateur',
              type: 'spline',
              color: 'cyan',
              data: data11
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'suppression_Consultant',
              type: 'spline',
              color: 'magenta',
              data: data12
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parMotsClés',
              type: 'spline',
              color: 'tan',
              data: data13
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parTags',
              type: 'spline',
              color: 'navy',
              data: data14
            }
          }
        ]

        // this.uncheckAll("controlActions");

        this.actions = Highcharts.chart('actionChart', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: 'Nombre d\'actions'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },

          series: []
        })

        this.initGraph(temp)

      } else {
        this.dservice.getUserActions(this.trigAction).subscribe(
          (data: any) => {
            data.forEach((elt:any) => {

              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[5] += 1
                    break;
                  case "MAJ_CV":
                    data2[5] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[5] += 1
                    break;
                  case "MAJ_TJM":
                    data4[5] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[5] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[5] += 1
                    break;
                  case "MAJ_TAG":
                    data7[5] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[5] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[5] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[5] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[5] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[5] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[5] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[5] += 1
                    break;
                  default:
                    break;
                }
              }

              for (let i = 0; i < 5; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  switch (elt.type) {
                    case "AJOUT_CONSULTANT":
                      data1[4-i] += 1
                      break;
                    case "MAJ_CV":
                      data2[4-i] += 1
                      break;
                    case "MAJ_QUALITE_CV":
                      data3[4-i] += 1
                      break;
                    case "MAJ_TJM":
                      data4[4-i] += 1
                      break;
                    case "MAJ_TECHNOLOGIE":
                      data5[4-i] += 1
                      break;
                    case "MAJ_OBSERVATION":
                      data6[4-i] += 1
                      break;
                    case "MAJ_TAG":
                      data7[4-i] += 1
                      break;
                    case "AJOUT_RAPPEL":
                      data8[4-i] += 1
                      break;
                    case "MAJ_CLIENT":
                      data9[4-i] += 1
                      break;
                    case "MAJ_DISPO":
                      data10[4-i] += 1
                      break;
                    case "MAJ_COLLABORATEUR":
                      data11[4-i] += 1
                      break;
                    case "SUPPRESSION_CONSULTANT":
                      data12[4-i] += 1
                      break;
                    case "RECHERCHE_PAR_MOT_CLE":
                      data13[4-i] += 1
                      break;
                    case "RECHERCHE_PAR_TAG":
                      data14[4-i] += 1
                      break;
                    default:
                      break;
                  }
                }
              }
            });

            this.series4 = [
              {
                display:false,
                index:0,
                serie: {
                  name: 'Ajout_consultant',
                  type: 'spline',
                  color:'black',
                  data: data1
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_CV',
                  type: 'spline',
                  color: 'gray',
                  data: data2
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_QualitéCV',
                  type: 'spline',
                  color: 'blue',
                  data: data3
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_TJM',
                  type: 'spline',
                  color: 'purple',
                  data: data4
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Technologie',
                  type: 'spline',
                  color: 'red',
                  data: data5
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Observation',
                  type: 'spline',
                  color: 'yellow',
                  data: data6
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Tags',
                  type: 'spline',
                  color: 'orange',
                  data: data7
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Ajout_Rappel',
                  type: 'spline',
                  color: 'pink',
                  data: data8
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Client',
                  type: 'spline',
                  color: 'green',
                  data: data9
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Dispo',
                  type: 'spline',
                  color: 'brown',
                  data: data10
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Collaborateur',
                  type: 'spline',
                  color: 'cyan',
                  data: data11
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'suppression_Consultant',
                  type: 'spline',
                  color: 'magenta',
                  data: data12
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parMotsClés',
                  type: 'spline',
                  color: 'tan',
                  data: data13
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parTags',
                  type: 'spline',
                  color: 'navy',
                  data: data14
                }
              }
            ]

            // this.uncheckAll("controlActions");

            this.actions = Highcharts.chart('actionChart', {
              chart: {
                type: 'spline'
              },
              legend: {
                enabled: false
              },
              title: {
                text: ''
              },
              xAxis: {
                categories: categories
              },
              yAxis: {
                title: {
                  text: 'Nombre d\'actions'
                }
              },
              plotOptions: {
                spline: {
                  marker: {
                    radius: 3,
                    lineWidth: 1
                  }
                }
              },

              series: []
            })

            this.initGraph(temp)
          }
        )
      }

    }

    if (this.periodeAction === "annee") {

      let data1 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data2 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data3 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data4 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data5 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data6 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data7 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data8 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data9 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data10 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data11 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data12 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data13 = [0,0,0,0,0,0,0,0,0,0,0,0]
      let data14 = [0,0,0,0,0,0,0,0,0,0,0,0]

      categories = []

      let today: Date = new Date();

      for (let i = 11; i >= 0; i--) {
        categories.push(new Date(today.getFullYear(), today.getMonth()-i, 1).toLocaleString('default', { month: 'long' }))
      }

      if (this.trigAction === "Tous") {

        this.actionsData.forEach((elt:any) => {

          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            switch (elt.type) {
              case "AJOUT_CONSULTANT":
                data1[11] += 1
                break;
              case "MAJ_CV":
                data2[11] += 1
                break;
              case "MAJ_QUALITE_CV":
                data3[11] += 1
                break;
              case "MAJ_TJM":
                data4[11] += 1
                break;
              case "MAJ_TECHNOLOGIE":
                data5[11] += 1
                break;
              case "MAJ_OBSERVATION":
                data6[11] += 1
                break;
              case "MAJ_TAG":
                data7[11] += 1
                break;
              case "AJOUT_RAPPEL":
                data8[11] += 1
                break;
              case "MAJ_CLIENT":
                data9[11] += 1
                break;
              case "MAJ_DISPO":
                data10[11] += 1
                break;
              case "MAJ_COLLABORATEUR":
                data11[11] += 1
                break;
              case "SUPPRESSION_CONSULTANT":
                data12[11] += 1
                break;
              case "RECHERCHE_PAR_MOT_CLE":
                data13[11] += 1
                break;
              case "RECHERCHE_PAR_TAG":
                data14[11] += 1
                break;
              default:
                break;
            }
          }

          for (let i = 0; i < 11; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              switch (elt.type) {
                case "AJOUT_CONSULTANT":
                  data1[10-i] += 1
                  break;
                case "MAJ_CV":
                  data2[10-i] += 1
                  break;
                case "MAJ_QUALITE_CV":
                  data3[10-i] += 1
                  break;
                case "MAJ_TJM":
                  data4[10-i] += 1
                  break;
                case "MAJ_TECHNOLOGIE":
                  data5[10-i] += 1
                  break;
                case "MAJ_OBSERVATION":
                  data6[10-i] += 1
                  break;
                case "MAJ_TAG":
                  data7[10-i] += 1
                  break;
                case "AJOUT_RAPPEL":
                  data8[10-i] += 1
                  break;
                case "MAJ_CLIENT":
                  data9[10-i] += 1
                  break;
                case "MAJ_DISPO":
                  data10[10-i] += 1
                  break;
                case "MAJ_COLLABORATEUR":
                  data11[10-i] += 1
                  break;
                case "SUPPRESSION_CONSULTANT":
                  data12[10-i] += 1
                  break;
                case "RECHERCHE_PAR_MOT_CLE":
                  data13[10-i] += 1
                  break;
                case "RECHERCHE_PAR_TAG":
                  data14[10-i] += 1
                  break;
                default:
                  break;
              }
            }
          }
        });

        this.series4 = [
          {
            display:false,
            index:0,
            serie: {
              name: 'Ajout_consultant',
              type: 'spline',
              color:'black',
              data: data1
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_CV',
              type: 'spline',
              color: 'gray',
              data: data2
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_QualitéCV',
              type: 'spline',
              color: 'blue',
              data: data3
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_TJM',
              type: 'spline',
              color: 'purple',
              data: data4
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Technologie',
              type: 'spline',
              color: 'red',
              data: data5
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Observation',
              type: 'spline',
              color: 'yellow',
              data: data6
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Tags',
              type: 'spline',
              color: 'orange',
              data: data7
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Ajout_Rappel',
              type: 'spline',
              color: 'pink',
              data: data8
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Client',
              type: 'spline',
              color: 'green',
              data: data9
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Dispo',
              type: 'spline',
              color: 'brown',
              data: data10
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'MàJ_Collaborateur',
              type: 'spline',
              color: 'cyan',
              data: data11
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'suppression_Consultant',
              type: 'spline',
              color: 'magenta',
              data: data12
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parMotsClés',
              type: 'spline',
              color: 'tan',
              data: data13
            }
          },
          {
            display: false,
            index: 0,
            serie: {
              name: 'Recherche_parTags',
              type: 'spline',
              color: 'navy',
              data: data14
            }
          }
        ]

        // this.uncheckAll("controlActions");

        this.actions = Highcharts.chart('actionChart', {
          chart: {
            type: 'spline'
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: 'Nombre d\'actions'
            }
          },
          plotOptions: {
            spline: {
              marker: {
                radius: 3,
                lineWidth: 1
              }
            }
          },

          series: []
        })

        this.initGraph(temp)

      } else {
        this.dservice.getUserActions(this.trigAction).subscribe(
          (data: any) => {
            data.forEach((elt:any) => {
              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                switch (elt.type) {
                  case "AJOUT_CONSULTANT":
                    data1[11] += 1
                    break;
                  case "MAJ_CV":
                    data2[11] += 1
                    break;
                  case "MAJ_QUALITE_CV":
                    data3[11] += 1
                    break;
                  case "MAJ_TJM":
                    data4[11] += 1
                    break;
                  case "MAJ_TECHNOLOGIE":
                    data5[11] += 1
                    break;
                  case "MAJ_OBSERVATION":
                    data6[11] += 1
                    break;
                  case "MAJ_TAG":
                    data7[11] += 1
                    break;
                  case "AJOUT_RAPPEL":
                    data8[11] += 1
                    break;
                  case "MAJ_CLIENT":
                    data9[11] += 1
                    break;
                  case "MAJ_DISPO":
                    data10[11] += 1
                    break;
                  case "MAJ_COLLABORATEUR":
                    data11[11] += 1
                    break;
                  case "SUPPRESSION_CONSULTANT":
                    data12[11] += 1
                    break;
                  case "RECHERCHE_PAR_MOT_CLE":
                    data13[11] += 1
                    break;
                  case "RECHERCHE_PAR_TAG":
                    data14[11] += 1
                    break;
                  default:
                    break;
                }
              }

              for (let i = 0; i < 11; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  switch (elt.type) {
                    case "AJOUT_CONSULTANT":
                      data1[10-i] += 1
                      break;
                    case "MAJ_CV":
                      data2[10-i] += 1
                      break;
                    case "MAJ_QUALITE_CV":
                      data3[10-i] += 1
                      break;
                    case "MAJ_TJM":
                      data4[10-i] += 1
                      break;
                    case "MAJ_TECHNOLOGIE":
                      data5[10-i] += 1
                      break;
                    case "MAJ_OBSERVATION":
                      data6[10-i] += 1
                      break;
                    case "MAJ_TAG":
                      data7[10-i] += 1
                      break;
                    case "AJOUT_RAPPEL":
                      data8[10-i] += 1
                      break;
                    case "MAJ_CLIENT":
                      data9[10-i] += 1
                      break;
                    case "MAJ_DISPO":
                      data10[10-i] += 1
                      break;
                    case "MAJ_COLLABORATEUR":
                      data11[10-i] += 1
                      break;
                    case "SUPPRESSION_CONSULTANT":
                      data12[10-i] += 1
                      break;
                    case "RECHERCHE_PAR_MOT_CLE":
                      data13[10-i] += 1
                      break;
                    case "RECHERCHE_PAR_TAG":
                      data14[10-i] += 1
                      break;
                    default:
                      break;
                  }
                }
              }
            });

            this.series4 = [
              {
                display:false,
                index:0,
                serie: {
                  name: 'Ajout_consultant',
                  type: 'spline',
                  color:'black',
                  data: data1
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_CV',
                  type: 'spline',
                  color: 'gray',
                  data: data2
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_QualitéCV',
                  type: 'spline',
                  color: 'blue',
                  data: data3
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_TJM',
                  type: 'spline',
                  color: 'purple',
                  data: data4
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Technologie',
                  type: 'spline',
                  color: 'red',
                  data: data5
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Observation',
                  type: 'spline',
                  color: 'yellow',
                  data: data6
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Tags',
                  type: 'spline',
                  color: 'orange',
                  data: data7
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Ajout_Rappel',
                  type: 'spline',
                  color: 'pink',
                  data: data8
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Client',
                  type: 'spline',
                  color: 'green',
                  data: data9
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Dispo',
                  type: 'spline',
                  color: 'brown',
                  data: data10
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'MàJ_Collaborateur',
                  type: 'spline',
                  color: 'cyan',
                  data: data11
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'suppression_Consultant',
                  type: 'spline',
                  color: 'magenta',
                  data: data12
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parMotsClés',
                  type: 'spline',
                  color: 'tan',
                  data: data13
                }
              },
              {
                display: false,
                index: 0,
                serie: {
                  name: 'Recherche_parTags',
                  type: 'spline',
                  color: 'navy',
                  data: data14
                }
              }
            ]

            // this.uncheckAll("controlActions");

            this.actions = Highcharts.chart('actionChart', {
              chart: {
                type: 'spline'
              },
              legend: {
                enabled: false
              },
              title: {
                text: ''
              },
              xAxis: {
                categories: categories
              },
              yAxis: {
                title: {
                  text: 'Nombre d\'actions'
                }
              },
              plotOptions: {
                spline: {
                  marker: {
                    radius: 3,
                    lineWidth: 1
                  }
                }
              },

              series: []
            })

            this.initGraph(temp)
          }
        )
      }

    }
  }

  setPeriodeAction(): void {
    this.filtrerAction()
  }

  setTrigAction(): void {
    this.filtrerAction()
  }

  series4 = [
    {
      display:false,
      index:0,
      serie: {
        name: 'Ajout_consultant',
        type: 'spline',
        color:'black',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_CV',
        type: 'spline',
        color: 'gray',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_QualitéCV',
        type: 'spline',
        color: 'blue',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_TJM',
        type: 'spline',
        color: 'purple',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Technologie',
        type: 'spline',
        color: 'red',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Observation',
        type: 'spline',
        color: 'yellow',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Tags',
        type: 'spline',
        color: 'orange',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'Ajout_Rappel',
        type: 'spline',
        color: 'pink',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Client',
        type: 'spline',
        color: 'green',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Dispo',
        type: 'spline',
        color: 'brown',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'MàJ_Collaborateur',
        type: 'spline',
        color: 'cyan',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'suppression_Consultant',
        type: 'spline',
        color: 'magenta',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'Recherche_parMotsClés',
        type: 'spline',
        color: 'tan',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    {
      display: false,
      index: 0,
      serie: {
        name: 'Recherche_parTags',
        type: 'spline',
        color: 'navy',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    }
  ]

  findSerieByName4(name: String): number{
    let i: number = 0;
    this.series4.forEach(element => {
      if (element.serie.name === name) {
        i = this.series4.indexOf(element)
      }
    });
    return i;
  }

  getMax4(){
    let max = 0;
    this.series4.forEach(element => {
      if (element.index > max) {
        max = element.index
      }
    });
    return max;
  }

  updateSeriesList4(i: number){
    this.series4.forEach(element => {
      if (element.index > i) {
        element.index = element.index - 1
      }
    });
  }

  addSerie4(name: any){
    let index = this.findSerieByName4(name)
    if (this.series4[index].display === false) {
      this.series4[index].display = !this.series4[index].display
      this.actions.addSeries({
        name: this.series4[index].serie.name,
        type: 'spline',
        color: this.series4[index].serie.color,
        data: this.series4[index].serie.data,
      }, true, true);

      this.series4[index].index = this.getMax4() + 1;

    }else{
      let i = this.series4[index].index
      this.actions.series[i-1].remove()
      this.updateSeriesList4(i)
      this.series4[index].index = 0;
      this.series4[index].display = !this.series4[index].display
    }

  }

  initGraph(series: any[]){
    for (let i = 0; i < series.length; i++) {
      this.series4[i].display = series[i].display
      this.series4[i].index = series[i].index
    }

    for (let i = 0; i < this.series4.length; i++) {
      if (this.series4[i].display) {
        this.actions.addSeries({
          name: this.series4[i].serie.name,
          type: 'spline',
          color: this.series4[i].serie.color,
          data: this.series4[i].serie.data,
        }, true, true);
      }
    }
  }



}
