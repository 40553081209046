<div class="container-fluid mt-5">
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="main-title"> Synthèse </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-synthese [selections]="selections" (loadChanged)="syntheseChangedHandler($event)"></app-synthese>
            </ng-template>
        </ngb-panel>

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between" style="width: 125vw;">
                    <div class="main-title"> Rappels </div>
                    <div style="min-height: 20px;" *ngIf="loadRappel">
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-rappel [selections]="selections" (loadChanged)="rappelChangedHandler($event)"></app-rappel>
            </ng-template>
        </ngb-panel>

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between" style="width: 125vw;">
                    <div class="main-title"> Consultants </div>
                    <div style="min-height: 20px;" *ngIf="loadConsultant">
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-consultant [selections]="selections" (loadChanged)="consultantChangedHandler($event)"></app-consultant>
            </ng-template>
        </ngb-panel>

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between" style="width: 125vw;">
                    <div class="main-title"> Recherche </div>
                    <div style="min-height: 20px;" *ngIf="loadRecherche">
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-recherche (loadChanged)="rechercheChangedHandler($event)"></app-recherche>
            </ng-template>
        </ngb-panel>

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between" style="width: 125vw;">
                    <div class="main-title"> Actions </div>
                    <div style="min-height: 20px;" *ngIf="loadAction">
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-action [selections]="selections" (loadChanged)="actionChangedHandler($event)"></app-action>
            </ng-template>
        </ngb-panel>

        <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between" style="width: 125vw;">
                    <div class="main-title"> Qualité de données </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-qualite (loadChanged)="qualiteChangedHandler($event)"></app-qualite>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>

    <!-- <div>
        <div *ngIf="loadConsultant" class="d-flex justify-content-center" style="min-height: 200px;">
            <div class="spinner-border text-secondary mt-5" style="height: 4rem; width: 4rem;" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <app-mission *ngIf="!loadConsultant"></app-mission>
    </div> -->


</div>


<ng-template #spinnerModal let-modal>
    <div class="modal-body" style="background-color: white;">
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</ng-template>