<div style="display: flex;background-color: #EFEEEE;width: 100%; justify-content: center;padding: 14px;">
    <p style="font-size: 20px; font-weight: bolder;color: red;margin: 0;text-align: center;">Besoins trouvés : <span style="color: #ff0000">{{totalElt}}</span>

    </p>


    <button *ngIf="showfilter" style="border: none;background-color: transparent;margin-top: -18px;margin-left: 10px;" (click)="resetFilter()">
        <img width="30" src="../../assets/filter.png" alt="">
  </button>

</div>

<div class="table-container">
    <table id="element" class="table table-striped table-hover">

        <thead>
            <tr class="table-secondary">
                <th scope="col" class="nomCli">
                    <div class="input" id="nomCli">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Nom du Client</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="nomcli">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('nomClient')">
                            <img *ngIf="this.sortBy != 'nomClient'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomClient' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomClient' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="nomcontact">
                    <div class="input" id="nomcontact">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Nom contact</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="nomcontact">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('nomContact')">
                            <img *ngIf="this.sortBy != 'nomContact'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomContact' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomContact' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="prenomcontact">
                    <div class="input" id="prenomcontact">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Prénom</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="prenomcontact">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('prenomContact')">
                            <img *ngIf="this.sortBy != 'prenomContact'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'prenomContact' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'prenomContact' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>



                <th scope="col" class="datesaisie">
                    <div class="input" id="datesaisie">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Date Saisie Besoin</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="datesaisie">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('dateSaisie')">
                            <img *ngIf="this.sortBy != 'dateSaisie'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'dateSaisie' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'dateSaisie' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="description">
                    <div class="input" id="description">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Description Besoin</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="description">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('description')">
                            <img *ngIf="this.sortBy != 'description'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'description' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'description' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="status">
                    <div class="input" id="status">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Statut</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="status">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('statutBesoin.ordre')">
                            <img *ngIf="this.sortBy != 'statutBesoin.ordre'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'statutBesoin.ordre' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'statutBesoin.ordre' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>


                <th scope="col" class="statutao">
                    <div class="input" id="statutao">
                        AO
                        <!-- <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Ao</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="ao">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('statutao')">
                            <img *ngIf="this.sortBy != 'email'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'email' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'email' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div> -->
                    </div>
                </th>

                <th scope="col" class="cv">
                    <div class="input" id="cv">
                        Cvs
                        <!-- <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Cvs</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="cv">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('cv')">
                            <img *ngIf="this.sortBy != 'notes'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'notes' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'notes' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div> -->
                    </div>
                </th>

                <!--
<th scope="col" class="dateenvoi">
                    <div class="input" id="dateenvoi">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Date EnvoiCv</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="dateenvoi">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('dateenvoi')">
                            <img *ngIf="this.sortBy != 'trigramme'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigramme' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigramme' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>
                -->


                <th scope="col" class="crm">
                    <div class="input" id="crm">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Trig CRM</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="crm">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('trigBesoin')">
                            <img *ngIf="this.sortBy != 'trigBesoin'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigBesoin' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigBesoin' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="rh" *ngIf="roles.indexOf('Gestionnaire')>0 || roles.indexOf('Facturation')>0 ">
                    <div class="input" id="rh">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Trig RH</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="rh">
                        </mat-form-field>
                        <div class="sort" (click)="this.yvesSort('trigGestionnaireRH')">
                            <img *ngIf="this.sortBy != 'trigGestionnaireRH'" src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigGestionnaireRH' && this.sortDirection > 0 " src="../../assets/asc.png" alt="">
                            <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'trigGestionnaireRH' && this.sortDirection < 0" src="../../assets/desc.png" alt="">
                        </div>
                    </div>
                </th>

            </tr>
        </thead>
        <tbody style="position: relative;">
            <tr *ngFor="let item of besoins; let i = index">

                <td [innerHTML]="item.nomClient" (click)="Select(item)" [ngClass]="{ 'highlight': isNomClientFilter, 'default': !isNomClientFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.nomClient,$event)" [id]="'nom'+i">

                    {{item.nomClient}}

                </td>

                <td (click)="Select(item)" [ngClass]="{ 'highlight': isNomContactFilter, 'default': !isNomContactFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.nomContact,$event)" id="nomContact" [innerHTML]="item.nomContact" [id]="'nomContact'+i">{{item.nomContact}}</td>
                <td (click)="Select(item)" [ngClass]="{ 'highlight': isPrenomContactFilter, 'default': !isPrenomContactFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.prenomContact,$event)" [id]="'prenomContact'+i" [innerHTML]="item.prenomContact"
                    class="prenomContactpx">{{item.prenomContact}}</td>
                <td (click)="Select(item)" [ngClass]="{ 'highlight': isDateSaisieFilter, 'default': !isDateSaisieFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.dateSaisie,$event)" style="text-align: center; " [innerHTML]="item.dateSaisie" [id]="'tel'+i"
                    class="dateSaisiepx"> {{item.dateSaisie}}</td>

                <td (click)="Select(item)" [ngClass]="{ 'highlight': isdescriptionFilter, 'default': !isdescriptionFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.description,$event)" [id]="'description'+i" class="cppx" [innerHTML]="item.description">
                    {{item.description}}</td>
                <td (click)="Select(item)" [ngClass]="{ 'highlight': isstatutFilter, 'default': !isstatutFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.statut,$event)" style="text-align: center; " [id]="'statut'+i" class="statutpx" [innerHTML]="item.statut">
                    {{item.statut}}</td>

                <td [ngClass]="{ 'highlight': isaoFilter, 'default': !isaoFilter  }" (mouseleave)="close($event)" class="aoervationpx"><img *ngIf='item.ao.nomFichier' style="height: 10px;" src="../../assets/oeil.jpeg" (click)="this.consultAO(item.ao.nomFichier)"></td>

                <td [ngClass]="{ 'highlight': isCvsFilter, 'default': !isCvsFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.outils,$event)" [id]="'outils'+i" class="outilspx">
                    {{item.nbCVs}}
                </td>
                <!--
<td (click)="Select(item)" [ngClass]="{ 'highlight': isDateFilter, 'default': !isDateFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.outils,$event)" style="text-align: center; " [id]="'datecv'+i" [innerHTML]="item.dateSaisie" class="outilspx">
                    {{item.dateSaisie}}</td>
-->
                <td (click)="Select(item)" [ngClass]="{ 'highlight': isCrmFilter, 'default': !isCrmFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.statut,$event)" [id]="'crm'+i" style="text-align: center; " class="crmpx" [innerHTML]="item.trigBesoin">
                    {{item.trigBesoin}}</td>
                <td *ngIf="roles.indexOf('Gestionnaire')>0 || roles.indexOf('Facturation')>0" (click)="Select(item)" [ngClass]="{ 'highlight': isRhFilter, 'default': !isRhFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.statut,$event)" [id]="'rh'+i"
                    style="text-align: center; " class="rhpx" [innerHTML]="item.trigGestionnaireRH">
                    {{item.trigGestionnaireRH}}</td>
            </tr>

        </tbody>
    </table>
</div>




<div style="display: flex;justify-content: center;margin-top: 1vw;position: relative;">

    <div>
        <img *ngIf="getpreviousMove()" (click)="FirstPage()" style="cursor: pointer; margin-right: 1vw;" width="25" src="../../assets/fisrtPageActive.png" alt="">
        <img *ngIf="getPrevious()" (click)="previousPage()" style="cursor: pointer; margin-right: 3vw" width="20" src="../../assets/previousPageActive.png" alt="">

        <img *ngIf="!getpreviousMove()" style="margin-right: 1vw" width="25" src="../../assets/firstPage.png" alt="">
        <img *ngIf="!getPrevious()" style="margin-right: 3vw" width="20" src="../../assets/previousPage.png" alt="">

        <input (keyup.enter)="goto($event.target.value)" [(ngModel)]="currentPage" type="text" style="width: 90px;color: red;font-weight: bold;height: 25px;"> / {{totalPage+1}}
        <img *ngIf="getNext()" (click)="NexPage()" style="cursor: pointer;margin-left: 3vw; " width="20" src="../../assets/nextPageActive.png" alt="">
        <img *ngIf="getlastpage()" (click)="LastPage()" style="cursor: pointer;margin-left: 1vw;" width="25" src="../../assets/lastPageActive.png" alt="">

        <img *ngIf="!getNext()" style="margin-left: 3vw;" width="20" src="../../assets/nextPage.png" alt="">
        <img *ngIf="!getlastpage()" style="margin-left: 1vw;" width="25" src="../../assets/lastPage.png" alt="">
    </div>

    <div style="display: flex;justify-content: center;align-items: center;position: absolute;right: 0;margin-right: 10px;">
        <p style="margin: 0;margin-right: 5px;">Par Page:</p>
        <select (change)="ChangeItemPerPage($event.target.value)" name="size" [(ngModel)]="pageSize" id="">
            <option *ngFor="let item of pageSizeElement" [value]="item" >{{item}}</option>

          </select>
    </div>

</div>
