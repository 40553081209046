import { DomSanitizer } from '@angular/platform-browser';
import { backEndpoint } from 'src/app/config';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import * as fileSaver from 'file-saver';
import { ConsultantService } from '../services/consultant.service';
import { MatDialog } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BASE_URL } from '../config';

@Component({
  selector: 'app-page-facturation',
  templateUrl: './page-facturation.component.html',
  styleUrls: ['./page-facturation.component.scss']
})
export class PageFacturationComponent implements OnInit {

  today = new Date().toJSON().split('T')[0];

  // CLIENT
  sortBy: string = "datedebut";
  sortDirection = "DESC";
  nomconsultant = "";
  prenomconsultant = "";
  datedebut = "";
  datefin = "";
  nomclient = "";
  elementFacturation: any = [];
  contrats: string[] = [];
  contrat: string = "";
  trigcrm:string = "";
  missions: any[] = [];
  missionsToDisplay: any[] = [];
  consultants: any[] = [];
  missionToUpdate: any;
  cra: any;
  facturation = {
    id: "",
    contratcadre : "",
    contrat : "",
    contratsclient : "",
    facturationrc : "",
    facturationmf : "",
    facturationtva : "",
    facturationadresse : "",
    facturationreferantnom : "",
    facturationreferantprenom : "",
    facturationreferantfonction : "",
    facturationreferanttelephone : "",
    facturationreferantemail : "",
    facturationintermediairetitulaire : "",
    facturationintermediaireiban : "",
    facturationintermediairebic : "",
    facturationintermediairedomiciliation : "",
    facturationintermediaireadresse : ""
  }
  facturation1: any = {
    "paiementsociete" : "",
    "paiementrc" : "",
    "paiementmf" : "",
    "paiementtva" : "",
    "paiementtitulaire" : "",
    "paiementiban" : "",
    "paiementbic" : "",
    "paiementdomiciliation" : "",
    "paiementadresse" : ""
  }
  eltfactureclient: any;
  eltfacture: any;
  contrath: string = "";
  tjmclienth: string = "";
  tjmconsultanth: string = "";
  page: number=0;
  currentPage: number = 1;
  size: number=10;
  pages: number[] = [0];
  totalelements: number = 0;
  @ViewChild('craModalClient', {read: TemplateRef}) craModal! : TemplateRef<any>;
  @ViewChild('factureModal', {read: TemplateRef}) factureModal! : TemplateRef<any>;

  constructor(public cs: ConsultantService, private dialog: MatDialog, private modalService: NgbModal,
    private datepipe: DatePipe, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.Loader();
    this.filterMissions();
  }

  showConsultant(id: string){
    window.open(BASE_URL+"/fichec?id="+id,"_blank");
  }

  validerFactureConsultant(e: Event, id: string, idcra: string, filelocation: string){
    e.preventDefault();
    window.open(BASE_URL+"/valider-facture?id="+id+"&cra="+idcra+"&file="+filelocation,"_blank");
  }

  downloadFile(location: string){
    this.cs.downloadFile(location).subscribe(
      blob => {
        this.saveFile(blob, location.slice(location.lastIndexOf("/")+1));
      },
      error => console.log(error)
    )
  }

  openModal(content) {
    this.modalService.open(content, {centered: true})
  }

  openFactureConsultantModal(e: Event, mission: any){
    e.preventDefault();
    this.cs.downloadFile(mission.cra.facturefile).subscribe(
      blob => {
        const data = new Blob([blob], { type: 'application/pdf' });
        let url = URL.createObjectURL(data);
        let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        window.open(url, '_blank');
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
    this.eltfacture = {};
    this.eltfacture.idmission = mission.id;
    this.eltfacture.idcra = mission.cra.idcra;
    this.eltfacture.mois = this.datepipe.transform(mission.date, 'yyyy-MM');
    this.eltfacture.paiementsociete = mission.paiementsociete;
    this.eltfacture.contratcadre = mission.contratcadre;
    this.eltfacture.contrat = mission.contrat;
    this.eltfacture.paiementrc = mission.paiementrc;
    this.eltfacture.paiementmf = mission.paiementmf;
    this.eltfacture.paiementtva = mission.paiementtva;
    this.eltfacture.paiementtitulaire = mission.paiementtitulaire;
    this.eltfacture.paiementiban = mission.paiementiban;
    this.eltfacture.paiementbic = mission.paiementbic;
    this.eltfacture.paiementdomiciliation = mission.paiementdomiciliation;
    this.eltfacture.paiementadresse = mission.paiementadresse;
    this.eltfacture.tjmconsultant = mission.tjmconsultant;
    this.eltfacture.totaljourtravaille =  mission.cra.totaljourtravaille;
    this.eltfacture.nbreastreintes = mission.cra.nbreastreintes;
    this.eltfacture.tarifastreinteconsultant = mission.tarifastreinteconsultant;
    this.eltfacture.totaltva = 0;
    this.eltfacture.numerofacture = "";
    this.eltfacture.datefacturation = "";
    this.eltfacture.echeancepaiement = "";
    this.eltfacture.nomconsultant = mission.nomconsultant;
    this.eltfacture.prenomconsultant = mission.prenomconsultant;
    this.eltfacture.telephoneconsultant = "";
    this.eltfacture.emailconsultant = "";
    this.eltfacture.adresseconsultant = "";
    this.eltfacture.activite = "";

    this.openModal(this.factureModal);
  }

  selectcolor1(mission){
    switch (mission.cra.reglementclient) {
      case "Regle":
        return "green"
        break;
      case "En_retard":
        return "red"
        break;
      case "En_attente":
        return "white"
        break;

      default:
        break;
    }
  }

  selectcolor2(mission){
    switch (mission.cra.reglementclient) {
      case "Regle":
        return "green"
        break;
      case "En_retard":
        return "red"
        break;
      case "En_attente":
        return "white"
        break;

      default:
        break;
    }
  }

  genererFactureConsulant(){
    this.modalService.dismissAll();
    this.Loader();
    this.cs.genererFactureConsultant(this.eltfacture).subscribe(
      blob => {
        this.dialog.closeAll();
        this.saveFile(blob, "Facture_"+this.eltfacture.nomconsultant+"_"+this.eltfacture.mois+".pdf");
        this.filterMissions();
      },
      error => {
        console.log(error);
        this.dialog.closeAll();
        this.Alert("Erreur lors de la génération de la facture");
      }
    );
  }

  openFacturationModal(mission: any, content){
    this.facturation.id = mission.id;
    this.facturation.contrat = mission.contrat;
    this.facturation.contratcadre = mission.contratcadre;
    this.facturation.facturationrc = mission.facturationrc;
    this.facturation.facturationmf = mission.facturationmf;
    this.facturation.facturationtva = mission.facturationtva;
    this.facturation.facturationadresse = mission.facturationadresse;
    this.facturation.facturationreferantnom = mission.facturationreferantnom;
    this.facturation.facturationreferantprenom = mission.facturationreferantprenom;
    this.facturation.facturationreferantfonction = mission.facturationreferantfonction;
    this.facturation.facturationreferanttelephone = mission.facturationreferanttelephone;
    this.facturation.facturationreferantemail = mission.facturationreferantemail;
    this.facturation.facturationintermediairetitulaire = mission.facturationintermediairetitulaire;
    this.facturation.facturationintermediaireiban = mission.facturationintermediaireiban;
    this.facturation.facturationintermediairebic = mission.facturationintermediairebic;
    this.facturation.facturationintermediairedomiciliation = mission.facturationintermediairedomiciliation;
    this.facturation.facturationintermediaireadresse= mission.facturationintermediaireadresse;

    this.openModal(content);
  }

  openPaiementModal(mission: any, content){
    this.facturation1.paiementsociete = mission.paiementsociete;
    this.facturation1.contratcadre = mission.contratcadre;
    this.facturation1.contrat = mission.contrat;
    this.facturation1.paiementrc = mission.paiementrc;
    this.facturation1.paiementmf = mission.paiementmf;
    this.facturation1.paiementtva = mission.paiementtva;
    this.facturation1.paiementtitulaire = mission.paiementtitulaire;
    this.facturation1.paiementiban = mission.paiementiban;
    this.facturation1.paiementbic = mission.paiementbic;
    this.facturation1.paiementdomiciliation = mission.paiementdomiciliation;
    this.facturation1.paiementadresse = mission.paiementadresse;

    this.openModal(content);
  }

  openFactureClientModal(e: Event, mission: any, content) {
    e.preventDefault();
    this.eltfactureclient = {};
    this.eltfactureclient.mois = this.datepipe.transform(mission.date, 'yyyy-MM');
    this.eltfactureclient.idmission = mission.id;
    this.eltfactureclient.idcra = mission.cra.idcra;
    this.eltfactureclient.client = mission.client;
    this.eltfactureclient.contrat = mission.contrat;
    this.eltfactureclient.contratcadre = mission.contratcadre;
    this.eltfactureclient.facturationrc = mission.facturationrc;
    this.eltfactureclient.facturationmf = mission.facturationmf;
    this.eltfactureclient.facturationtva = mission.facturationtva;
    this.eltfactureclient.facturationadresse = mission.facturationadresse;
    this.eltfactureclient.facturationreferantnom = mission.facturationreferantnom;
    this.eltfactureclient.facturationreferantprenom = mission.facturationreferantprenom;
    this.eltfactureclient.facturationreferantfonction = mission.facturationreferantfonction;
    this.eltfactureclient.facturationreferanttelephone = mission.facturationreferanttelephone;
    this.eltfactureclient.facturationreferantemail = mission.facturationreferantemail;
    this.eltfactureclient.facturationintermediairetitulaire = mission.facturationintermediairetitulaire;
    this.eltfactureclient.facturationintermediaireiban = mission.facturationintermediaireiban;
    this.eltfactureclient.facturationintermediairebic = mission.facturationintermediairebic;
    this.eltfactureclient.facturationintermediairedomiciliation = mission.facturationintermediairedomiciliation;
    this.eltfactureclient.facturationintermediaireadresse= mission.facturationintermediaireadresse;
    this.eltfactureclient.tjmclient = mission.tjmclient;
    this.eltfactureclient.totaljourtravaille =  mission.cra.totaljourtravaille;
    this.eltfactureclient.nbreastreintes = mission.cra.nbreastreintes;
    this.eltfactureclient.tarifastreinteclient = mission.tarifastreinteclient;
    this.eltfactureclient.totaltva = 0;
    this.eltfactureclient.numerofacture = "";
    this.eltfactureclient.datefacturation = "";
    this.eltfactureclient.echeancepaiement = "";
    this.eltfactureclient.nomconsultant = mission.nomconsultant;
    this.eltfactureclient.prenomconsultant = mission.prenomconsultant;
    this.eltfactureclient.description = "";
    this.openModal(content);
  }

  generateFacture(){
    this.modalService.dismissAll();
    this.Loader();
    this.cs.genererFactureClient(this.eltfactureclient).subscribe(
      blob => {
        this.dialog.closeAll();
        this.filterMissions();
        this.saveFile(blob, "Facture_"+this.eltfactureclient.client+"_"+this.eltfactureclient.mois+".pdf");
      },
      error => {
        console.log(error);
        this.dialog.closeAll();
        this.Alert("Erreur lors de la génération de la facture");
      }
    );
  }

  changeStatutReglementClient(mission: any){
    this.Loader()
    this.cs.changeReglementClient(mission.cra.reglementclient, mission.cra.idcra, mission.id).subscribe(
      (data) => {
        this.filterMissions();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    )
  }

  changeStatutReglementConsultant(mission: any){
    this.Loader()
    this.cs.changeReglementConsultant(mission.cra.reglementconsultant, mission.cra.idcra, mission.id).subscribe(
      (data) => {
        this.filterMissions();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    )
  }

  updateFacturation(){
    this.modalService.dismissAll();
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == this.facturation.id) {
        elt.facturationrc = this.facturation.facturationrc;
        elt.facturationmf = this.facturation.facturationmf;
        elt.facturationtva = this.facturation.facturationtva;
        elt.facturationadresse = this.facturation.facturationadresse;
        elt.facturationreferantnom = this.facturation.facturationreferantnom;
        elt.facturationreferantprenom = this.facturation.facturationreferantprenom;
        elt.facturationreferantfonction = this.facturation.facturationreferantfonction;
        elt.facturationreferanttelephone = this.facturation.facturationreferanttelephone;
        elt.facturationreferantemail = this.facturation.facturationreferantemail;
        elt.facturationintermediairetitulaire = this.facturation.facturationintermediairetitulaire;
        elt.facturationintermediaireiban = this.facturation.facturationintermediaireiban;
        elt.facturationintermediairebic = this.facturation.facturationintermediairebic;
        elt.facturationintermediairedomiciliation = this.facturation.facturationintermediairedomiciliation;
        elt.facturationintermediaireadresse = this.facturation.facturationintermediaireadresse;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.facturation = {
              id: "",
              contratcadre : "",
              contrat : "",
              contratsclient : "",
              facturationrc : "",
              facturationmf : "",
              facturationtva : "",
              facturationadresse : "",
              facturationreferantnom : "",
              facturationreferantprenom : "",
              facturationreferantfonction : "",
              facturationreferanttelephone : "",
              facturationreferantemail : "",
              facturationintermediairetitulaire : "",
              facturationintermediaireiban : "",
              facturationintermediairebic : "",
              facturationintermediairedomiciliation : "",
              facturationintermediaireadresse : ""
            }
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  openCraModalClient(idcra: string){
    this.cs.getCra(idcra).subscribe(
      (data) => {
        this.cra = data;
        this.openXlModal(this.craModal)
      }
    )
  }

  validateCra(mission: any){
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == mission.id) {
        elt.cras.forEach(cra => {
          if(cra.idra == mission.cra.idcra){
            cra.validationcra = mission.cra.validationcra
          }
          this.cs.updateMission(elt).subscribe(
            (data:any) => {
              this.filterMissions();
              this.missionToUpdate = null;
              this.dialog.closeAll();
            },
            (error) => {
              this.dialog.closeAll();
              console.log(error);
            }
          );
        });
      }
    })
  }

  openDateRenouvModal(mission: any, content){
    this.missionToUpdate = {...mission}
    this.modalService.open(content, { centered: true });
  }

  updateDate(){
    this.modalService.dismissAll();
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == this.missionToUpdate.id) {
        elt.datefin = this.missionToUpdate.datefin;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.missionToUpdate = null;
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  updateSatisfaction(mission:any){
    this.Loader()
    this.missions.forEach((elt: any) => {
      if (elt.id == mission.id) {
        elt.satisfactionclient = mission.satisfactionclient;
        elt.datesatclient = this.today;
        this.cs.updateMission(elt).subscribe(
          (data:any) => {
            this.filterMissions();
            this.missionToUpdate = null;
            this.dialog.closeAll();
          },
          (error) => {
            this.dialog.closeAll();
            console.log(error);
          }
        );
      }
    })
  }

  setElementFacturation(){
    this.contrats = this.elementFacturation.contrats
  }

  setSort(sortcolumn: string){
    if(this.sortBy == sortcolumn){
      this.sortDirection == "ASC" ? this.sortDirection = "DESC" : this.sortDirection = "ASC";
    } else {
      this.sortBy = sortcolumn
      this.sortDirection = "ASC";
    }
    this.filterMissions();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.filterMissions();
    }
  }

  changePage(){
    if(this.currentPage>0 && this.currentPage<=this.pages.length){
      this.page = this.currentPage - 1;
      this.filterMissions();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.filterMissions();
  }

  filterMissions(){
    this.cs.getMissions("", "", this.datedebut, this.datefin, this.nomclient, this.nomconsultant, this.prenomconsultant,
     this.contrath, "", "", this.trigcrm, this.tjmclienth, this.tjmconsultanth, this.sortBy, this.sortDirection, this.page.toString(), this.size.toString()).subscribe(
      (data) => {
        this.missions = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.displayMissionPerMonth();
      },
      (error) => {
        console.log(error);
      }
     )
  }

  displayMissionPerMonth(){
    this.missionsToDisplay = [];
    let now = new Date(this.today)
    this.missions.forEach((elt: any) => {
      let fin = new Date(elt.datefin);
      for (let d = new Date(new Date(elt.datedebut).setDate(1)); d <= now; d.setMonth(d.getMonth() + 1)) {
        if (d < fin ) {
          let mis = {...elt};
          mis.date = new Date(d);
          mis.cra = null;
          elt.cras.forEach(cra => {
            if(cra.mois-1 == d.getMonth()){
              mis.cra = cra;
            }
          });
          this.missionsToDisplay.push(mis)
        } else {
          break;
        }
      }
    });
    this.dialog.closeAll();
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data]);
    fileSaver.saveAs(blob, filename);
  }

  openXlModal(content) {
		this.modalService.open(content, { size: 'xl', centered: true });
	}

  Loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en Cours`

      },
      disableClose: true,
    });
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
