import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { backEndpoint, CRM_BACK_ENDPOINT } from 'src/app/config';


const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json'
  })
};

const API_URL = backEndpoint+"/api/drh/";
const CRM_API_URL = CRM_BACK_ENDPOINT+"/api/dcrm/";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getSyntheseUserBesoin(trigramme: string) : Observable<any> {
    return this.http.get(CRM_API_URL+"synthese/besoins/rh/"+trigramme)
  }

  getSyntheseCrmGlobalData(): Observable<any> {
    return this.http.get(CRM_API_URL+"synthese/rh");
  }

  saveTagSearch(trigramme: string): Observable<any> {
    return this.http.get(API_URL+"tagsearch/"+trigramme);
  }

  getSeclections (): Observable<any> {
    return this.http.get(API_URL+"selections", httpOptions);
  }

  getGlobalData(): Observable<any> {
    return this.http.get(API_URL+"drhGlobal");
  }

  getSyntheseUserConsultants(trigramme: string){
    return this.http.get(API_URL+"getSyntheseUserConsultants/"+trigramme);
  }

  getSyntheseUserRappel(trigramme: string) : Observable<any> {
    return this.http.get(API_URL+"getSyntheseUserRappels"+"/"+trigramme, httpOptions)
  }

  getRappel() : Observable<any> {
    return this.http.get(API_URL+"getRappels", httpOptions)
  }

  getUserRappel(trigramme: string) : Observable<any> {
    return this.http.get(API_URL+"getRappels"+"/"+trigramme, httpOptions)
  }

  getUserMessages(trigramme: string) : Observable<any> {
    return this.http.get(API_URL+"getMessages"+"/"+trigramme, httpOptions)
  }

  sendMessage(trigramme: string, data: any){
    return this.http.post(API_URL+"newMessage/"+trigramme, data);
  }

  getConsultants(){
    return this.http.get(API_URL+"getUserConsultants/");
  }

  getUserConsultants(trigramme: string){
    return this.http.get(API_URL+"getUserConsultants/"+trigramme);
  }

  getDataRecherche(){
    return this.http.get(API_URL+"getDataRecherche");
  }

  getActions(){
    return this.http.get(API_URL+"getActions");
  }

  getUserActions(trigramme: string){
    return this.http.get(API_URL+"getActions/"+trigramme);
  }

  getQualiteFiche() : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche", httpOptions);
  }

  getTendanceFiche() : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche", httpOptions);
  }

  getFilter1(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/email?size="+size+"&page="+page, httpOptions)
  }

  getFilter2(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/qualite?size="+size+"&page="+page, httpOptions)
  }

  getFilter3(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/cv?size="+size+"&page="+page, httpOptions)
  }

  getFilter4(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/disponibilite?size="+size+"&page="+page, httpOptions)
  }

  getFilter5(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/titre?size="+size+"&page="+page, httpOptions)
  }

  getFilter6(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/dda?size="+size+"&page="+page, httpOptions)
  }

  getFilter7(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/tjm?size="+size+"&page="+page, httpOptions)
  }

  getFilter8(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/techno?size="+size+"&page="+page, httpOptions)
  }

  getFilter9(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/tagtech?size="+size+"&page="+page, httpOptions)
  }

  getFilter10(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/tagmetier?size="+size+"&page="+page, httpOptions)
  }

  getFilter11(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/tagfonc?size="+size+"&page="+page, httpOptions)
  }

  getFilter12(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"qualite-fiche/client?size="+size+"&page="+page, httpOptions)
  }

  getFilter13(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche/techno?size="+size+"&page="+page, httpOptions)
  }

  getFilter14(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche/tagtech?size="+size+"&page="+page, httpOptions)
  }

  getFilter15(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche/tagmetier?size="+size+"&page="+page, httpOptions)
  }

  getFilter16(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche/tagfonc?size="+size+"&page="+page, httpOptions)
  }

  getFilter17(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"alignement-fiche/client?size="+size+"&page="+page, httpOptions)
  }

  getFilter18(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"nonalignement-fiche/techno?size="+size+"&page="+page, httpOptions)
  }

  getFilter19(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"nonalignement-fiche/tagtech?size="+size+"&page="+page, httpOptions)
  }

  getFilter20(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"nonalignement-fiche/tagmetier?size="+size+"&page="+page, httpOptions)
  }

  getFilter21(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"nonalignement-fiche/tagfonc?size="+size+"&page="+page, httpOptions)
  }

  getFilter22(page: number, size: number) : Observable<any> {
    return this.http.get(API_URL+"nonalignement-fiche/client?size="+size+"&page="+page, httpOptions)
  }




}
