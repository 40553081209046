import { ConsultantProfile } from './../models/consultant-profile';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertEmptyComponent } from 'src/app/alert-empty/alert-empty.component';
import { ProgressSpinnerDialogComponent } from 'src/app/progress-spinner-dialog/progress-spinner-dialog.component';
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { ConsultantService } from 'src/app/services/consultant.service';

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit {

  userid: string = "";
  profile: ConsultantProfile = null;
  reference: any = {
    societe: "",
    nom: "",
    prenom: "",
    fonction: "",
    email: "",
    adresse: "",
    telephone: ""
  }
  itemToUpdate: any;
  selectedItem: any;
  file: File;
  today = new Date();

  constructor(
    private tokenStorageService: TokenStorageService,
    private profileService: ConsultantProfileService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private cs: ConsultantService,
    private datepipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = this.tokenStorageService.getUser();
    this.userid = user.id;
    this.profile = this.profileService.getProfile();
    if(this.profile == null) {
      this.Alert("Pas de profil en cours de modification");
      this.router.navigateByUrl('consultant/experiences')
    }
    // this.profileService.getProfileByUserid(this.userid).subscribe(
    //   (data) => {
    //     this.profile = data;
    //     this.dialog.closeAll();
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.dialog.closeAll();
    //     this.Alert("Erreur lors du chargement du profil.");
    //   }
    // )
  }

  openModal(content, item){
    this.selectedItem = item;
    this.modalService.open(content, {centered: true});
  }

  addReference(){
    this.selectedItem.references.push(this.reference);
    this.selectedItem.datemaj = this.datepipe.transform(this.today, "yyyy-MM-dd");
    this.reference = {
      societe: "",
      nom: "",
      prenom: "",
      fonction: "",
      email: "",
      adresse: "",
      telephone: ""
    };
    this.modalService.dismissAll();
  }

  removeReference(item){
    let index = this.selectedItem.references.indexOf(item);
    this.selectedItem.references.splice(index,1);
    this.selectedItem.datemaj = this.datepipe.transform(this.today, "yyyy-MM-dd");
  }

  cancelReferenceFrom(){
    this.modalService.dismissAll();
    this.reference = {
      societe: "",
      nom: "",
      prenom: "",
      fonction: "",
      email: "",
      adresse: "",
      telephone: ""
    };
  }

  deleteLink(item){
    item.lien = "";
    item.datemaj = this.datepipe.transform(this.today, "yyyy-MM-dd");
  }

  cancelLink(){
    this.modalService.dismissAll();
    this.selectedItem.lien = "";
  }

  openFileInput(fileInput, item){
    this.selectedItem = item;
    fileInput.click();
  }

  onFilechange(event: any){
    this.file = event.target.files[0];
    this.uploadFile();
  }

  uploadFile(){
    this.Loader();
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('profileid', this.profile.id);
    formData.append('libelle', this.selectedItem.intitule);
    this.profileService.addDocument(formData).subscribe(
      (data) => {
        this.selectedItem.document = data.filename;
        this.selectedItem.datemaj = this.datepipe.transform(this.today, "yyyy-MM-dd");
        this.dialog.closeAll();
      },
      error => {
        this.dialog.closeAll();
        this.Alert("Echec de l'enregistrement du document")
      }
    )
  }

  deleteDocument(item){
    this.selectedItem = item;
    this.profileService.deleteDocument(item.document, item.intitule, this.profile.id).subscribe(
      (data) => {
        this.selectedItem.document = "";
        this.selectedItem.datemaj = this.datepipe.transform(this.today, "yyyy-MM-dd");
        this.dialog.closeAll();
      },
     (error) => {
      this.dialog.closeAll();
      console.log(error)
     }
    )
  }

  openLink(e) {
    if (!e.includes("https") || !e.includes("http")) {
      e = "https://" + e;
    }
    window.open(e, "_blank");
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  Loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
       Chargement en Cours`,
      },
      disableClose: true,
    });
  }

  visualiser(fileURL) {
    this.cs.downloadFile(fileURL).subscribe(
      blob => {
        const data = new Blob([blob], { type: 'application/pdf' });
        let url = URL.createObjectURL(data);
        let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        window.open(url, '_blank');
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }

  validate(): boolean {

    for(let cl of this.profile.competencesclients){
      if (cl.dureepratique <=0 || cl.references.length == 0) {
        this.Alert("Durée de pratique et reférence obligatoire pour chaque client.")
        return false;
      }
    }

    for(let cl of this.profile.competencestechnos){
      if (cl.dureepratique <=0 || cl.references.length == 0 || cl.niveau == "") {
        this.Alert("Niveau, durée de pratique et reférence obligatoire pour chaque technologie.")
        return false;
      }
    }

    for(let cl of this.profile.competencesactivites){
      if (cl.dureepratique <=0 || cl.references.length == 0 || cl.niveau == "") {
        this.Alert("Niveau, durée de pratique et reférence obligatoire pour chaque activité.")
        return false;
      }
    }

    for(let cl of this.profile.competencessecteurs){
      if (cl.dureepratique <=0 || cl.references.length == 0) {
        this.Alert("Durée de pratique et reférence obligatoire pour chaque secteur.")
        return false;
      }
    }

    return true;

  }

  update(){
    if(this.validate()){
      this.Loader();
      this.profileService.updateProfile(this.profile).subscribe(
        (data: ConsultantProfile) => {
          this.profile = data;
          this.profileService.setProfile(data);
          this.dialog.closeAll();
          this.Alert("Informations enregistrées avec succès");
        },
        (error) => {
          this.dialog.closeAll();
          this.Alert("Erreur lors de l'enregistrement");
        }
      )
    }

  }

}
