import { TokenStorageService } from './../../services/token-storage.service';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, OnChanges, SimpleChanges, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { backEndpoint } from '../../config'
import { DashboardService } from '../services/dashboard.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json'
  })
};

const API_URL = backEndpoint+"/api/drh/"

@Component({
  selector: 'app-rappel',
  templateUrl: './rappel.component.html',
  styleUrls: ['./rappel.component.scss']
})
export class RappelComponent implements OnInit, AfterViewInit {

  constructor(private datePipe: DatePipe, private http: HttpClient, private modalService: NgbModal,
    private dservice: DashboardService, private tokenStorage: TokenStorageService) { }

  @Input() selections: any;
  @Input() rappelData: any;

  trigramme: string = "";
  isAdmin: boolean = false;

  ngOnInit(): void {
    const user = this.tokenStorage.getUser();
    this.trigramme = user.trigramme;
    this.isAdmin = user.roles.lastIndexOf('Gestionnaire')>0 || user.roles.lastIndexOf('Facturation')>0;

    this.trigrammes =  this.selections.trigrammes

    if (!this.isAdmin) {
      this.trigSuiviRappel = this.trigramme;
      this.filtrerSuiviRappel();
      this.isLoading = false;
      this.loadChanged.emit(this.isLoading);
    } else {
      this.dservice.getRappel().subscribe(
        (data: any) => {
          this.rappelData = data;
          this.filtrerSuiviRappel();
          this.isLoading = false;
          this.loadChanged.emit(this.isLoading);
        }
      )
    }

  }


  isLoading = true;
  @Output() loadChanged: EventEmitter<boolean> = new EventEmitter();


  rappelChart: any;

  trigrammes = []


  periodeSuiviRappel = "semaine";
  trigSuiviRappel = "Tous";

  filtrerSuiviRappel(): void {
    this.data1 = []
    this.data2 = []
    let categories: string[] = []

    if (this.periodeSuiviRappel === "semaine") {

      this.data1 = [0,0,0,0,0]
      this.data2 = [0,0,0,0,0]

      let dates : Date[] = []

      let weekend : number[] = [0, 6]

      for (let i = 6; i >= 0; i--) {
        if (weekend.indexOf(new Date(Date.now()-i*86400000).getDay())<0) {
          dates.push(new Date(Date.now()-i*86400000));
        }
      }

      dates.forEach(elt => {
        categories.push(this.datePipe.transform(elt, 'dd-MM-yyyy')!);
      });


      if(this.trigSuiviRappel === "Tous") {

        for (let i = 0; i < dates.length; i++) {
          this.rappelData.rappelEnRetards.forEach((elt: any) => {
            if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
              this.data1[i]+=1;
            }
          });

          this.rappelData.rappelATemps.forEach((elt: any) => {
            if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
              this.data2[i] += 1;
            }
          });

        }

        this.rappelChart = Highcharts.chart('chartRappel', {
          chart: {
              type: 'areaspline',
              height: 400,
          },
          legend: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
              categories: categories,
          },
          yAxis: {
              title: {
                  text: 'Nombre de rappels'
              }
          },
          tooltip: {
              shared: true,
              valueSuffix: ' units'
          },
          credits: {
              enabled: false
          },
          plotOptions: {
              areaspline: {
                  fillOpacity: 0.1
              }
          },
          series: [{
            name:'En retard',
              type: 'spline',
              color: 'red',
              data: this.data1
          }, {
              name:'A temps',
              type:'spline',
              color: 'green',
              data: this.data2
          }]
        })

      } else {
        this.dservice.getUserRappel(this.trigSuiviRappel).subscribe(
          (data: any) => {
            for (let i = 0; i < dates.length; i++) {
              data.rappelEnRetards.forEach((elt: any) => {
                if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
                  this.data1[i]+=1;
                }
              });

              data.rappelATemps.forEach((elt: any) => {
                if (this.datePipe.transform(elt.date, 'dd-MM-yyyy') === this.datePipe.transform(dates[i],'dd-MM-yyyy')) {
                  this.data2[i] += 1;
                }
              });
            }
            this.rappelChart = Highcharts.chart('chartRappel', {
              chart: {
                  type: 'areaspline',
                  height: 400,
              },
              legend: {
                enabled: false
              },
              title: {
                  text: ''
              },
              xAxis: {
                  categories: categories,
              },
              yAxis: {
                  title: {
                      text: 'Nombre de rappels'
                  }
              },
              tooltip: {
                  shared: true,
                  valueSuffix: ' units'
              },
              credits: {
                  enabled: false
              },
              plotOptions: {
                  areaspline: {
                      fillOpacity: 0.1
                  }
              },
              series: [{
                name:'En retard',
                  type: 'spline',
                  color: 'red',
                  data: this.data1
              }, {
                  name:'A temps',
                  type:'spline',
                  color: 'green',
                  data: this.data2
              }]
            })
          }
        )
      }


    }

    if (this.periodeSuiviRappel === "mois") {

      this.data1 = [0,0,0,0,0]
      this.data2 = [0,0,0,0,0]

      categories = ['semaine 1', 'semaine 2', 'semaine 3', 'semaine 4', 'semaine 5']

      let dates : Date[] = []
      let semaine1: string[] = []
      let semaine2: string[] = []
      let semaine3: string[] = []
      let semaine4: string[] = []
      let semaine5: string[] = []

      for (let i = 34; i >= 0; i--) {
        dates.push(new Date(Date.now()-i*86400000));
      }


      for (let i = 0; i < 7; i++) {
        semaine1.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 7; i < 14; i++) {
        semaine2.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 14; i < 21; i++) {
        semaine3.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 21; i < 28; i++) {
        semaine4.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      for (let i = 28; i < 35; i++) {
        semaine5.push(this.datePipe.transform(dates[i], 'dd-MM-yyyy')!)
      }

      if (this.trigSuiviRappel === "Tous") {

        this.rappelData.rappelATemps.forEach((elt: any) => {
          if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data2[0] += 1;
          } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data2[1] += 1;
          } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data2[2] += 1;
          } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data2[3] += 1;
          } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data2[4] += 1;
          }
        });

        this.rappelData.rappelEnRetards.forEach((elt: any) => {
          if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data1[0] = this.data1[0] + 1;
          } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data1[1] = this.data1[1] + 1;
          } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data1[2] = this.data1[2] + 1;
          } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data1[3] = this.data1[3] + 1;
          } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
            this.data1[4] = this.data1[4] + 1;
          }
        });

        this.rappelChart = Highcharts.chart('chartRappel', {
          chart: {
              type: 'areaspline',
              height: 400,
          },
          legend: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
              categories: categories,
          },
          yAxis: {
              title: {
                  text: 'Nombre de rappels'
              }
          },
          tooltip: {
              shared: true,
              valueSuffix: ' units'
          },
          credits: {
              enabled: false
          },
          plotOptions: {
              areaspline: {
                  fillOpacity: 0.1
              }
          },
          series: [{
            name:'En retard',
              type: 'spline',
              color: 'red',
              data: this.data1
          }, {
              name:'A temps',
              type:'spline',
              color: 'green',
              data: this.data2
          }]
        })
      } else {
        this.dservice.getUserRappel(this.trigSuiviRappel).subscribe(
          (data: any) => {

            data.rappelATemps.forEach((elt: any) => {
              if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data2[0] += 1;
              } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data2[1] += 1;
              } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data2[2] += 1;
              } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data2[3] += 1;
              } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data2[4] += 1;
              }
            });

            data.rappelEnRetards.forEach((elt: any) => {
              if (semaine1.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data1[0] = this.data1[0] + 1;
              } else if (semaine2.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data1[1] = this.data1[1] + 1;
              } else if (semaine3.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data1[2] = this.data1[2] + 1;
              } else if (semaine4.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data1[3] = this.data1[3] + 1;
              } else if (semaine5.indexOf(this.datePipe.transform(elt.date, 'dd-MM-yyyy')!)>=0) {
                this.data1[4] = this.data1[4] + 1;
              }
            });

            this.rappelChart = Highcharts.chart('chartRappel', {
              chart: {
                  type: 'areaspline',
                  height: 400,
              },
              legend: {
                enabled: false
              },
              title: {
                  text: ''
              },
              xAxis: {
                  categories: categories,
              },
              yAxis: {
                  title: {
                      text: 'Nombre de rappels'
                  }
              },
              tooltip: {
                  shared: true,
                  valueSuffix: ' units'
              },
              credits: {
                  enabled: false
              },
              plotOptions: {
                  areaspline: {
                      fillOpacity: 0.1
                  }
              },
              series: [{
                name:'En retard',
                  type: 'spline',
                  color: 'red',
                  data: this.data1
              }, {
                  name:'A temps',
                  type:'spline',
                  color: 'green',
                  data: this.data2
              }]
            })
          }
        )
      }

    }

    if (this.periodeSuiviRappel === "semestre") {

      this.data1 = [0,0,0,0,0,0]
      this.data2 = [0,0,0,0,0,0]

      categories = []

      let today: Date = new Date();

      for (let i = 5; i >= 0; i--) {
        categories.push(new Date(today.getFullYear(), today.getMonth()-i, 1).toLocaleString('default', { month: 'long' }))
      }

      if (this.trigSuiviRappel === "Tous") {

        this.rappelData.rappelATemps.forEach((elt:any) => {

          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            this.data2[5] += 1
          }

          for (let i = 0; i < 5; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              this.data2[4-i] += 1
            }
          }
        });

        this.rappelData.rappelEnRetards.forEach((elt:any) => {
          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            this.data1[5] += 1
          }

          for (let i = 0; i < 5; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              this.data1[4-i] += 1
            }
          }
        });

        this.rappelChart = Highcharts.chart('chartRappel', {
          chart: {
              type: 'areaspline',
              height: 400,
          },
          legend: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
              categories: categories,
          },
          yAxis: {
              title: {
                  text: 'Nombre de rappels'
              }
          },
          tooltip: {
              shared: true,
              valueSuffix: ' units'
          },
          credits: {
              enabled: false
          },
          plotOptions: {
              areaspline: {
                  fillOpacity: 0.1
              }
          },
          series: [{
            name:'En retard',
              type: 'spline',
              color: 'red',
              data: this.data1
          }, {
              name:'A temps',
              type:'spline',
              color: 'green',
              data: this.data2
          }]
        })

      } else {
        this.dservice.getUserRappel(this.trigSuiviRappel).subscribe(
          (data: any) => {
            data.rappelATemps.forEach((elt:any) => {

              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                this.data2[5] += 1
              }

              for (let i = 0; i < 5; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  this.data2[4-i] += 1
                }
              }
            });

            data.rappelEnRetards.forEach((elt:any) => {
              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                this.data1[5] += 1
              }

              for (let i = 0; i < 5; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  this.data1[4-i] += 1
                }
              }
            });

            this.rappelChart = Highcharts.chart('chartRappel', {
              chart: {
                  type: 'areaspline',
                  height: 400,
              },
              legend: {
                enabled: false
              },
              title: {
                  text: ''
              },
              xAxis: {
                  categories: categories,
              },
              yAxis: {
                  title: {
                      text: 'Nombre de rappels'
                  }
              },
              tooltip: {
                  shared: true,
                  valueSuffix: ' units'
              },
              credits: {
                  enabled: false
              },
              plotOptions: {
                  areaspline: {
                      fillOpacity: 0.1
                  }
              },
              series: [{
                name:'En retard',
                  type: 'spline',
                  color: 'red',
                  data: this.data1
              }, {
                  name:'A temps',
                  type:'spline',
                  color: 'green',
                  data: this.data2
              }]
            })
          }
        )
      }

    }

    if (this.periodeSuiviRappel === "annee") {

      this.data1 = [0,0,0,0,0,0,0,0,0,0,0,0]
      this.data2 = [0,0,0,0,0,0,0,0,0,0,0,0]

      categories = []

      let today: Date = new Date();

      for (let i = 11; i >= 0; i--) {
        categories.push(new Date(today.getFullYear(), today.getMonth()-i, 1).toLocaleString('default', { month: 'long' }))
      }

      if (this.trigSuiviRappel === "Tous") {

        this.rappelData.rappelATemps.forEach((elt:any) => {

          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            this.data2[11] += 1
          }

          for (let i = 0; i < 11; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              this.data2[10-i] += 1
            }
          }
        });

        this.rappelData.rappelEnRetards.forEach((elt:any) => {
          if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
            this.data1[11] += 1
          }

          for (let i = 0; i < 11; i++) {
            if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
              this.data1[10-i] += 1
            }
          }
        });

        this.rappelChart = Highcharts.chart('chartRappel', {
          chart: {
              type: 'areaspline',
              height: 400,
          },
          legend: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
              categories: categories,
          },
          yAxis: {
              title: {
                  text: 'Nombre de rappels'
              }
          },
          tooltip: {
              shared: true,
              valueSuffix: ' units'
          },
          credits: {
              enabled: false
          },
          plotOptions: {
              areaspline: {
                  fillOpacity: 0.1
              }
          },
          series: [{
            name:'En retard',
              type: 'spline',
              color: 'red',
              data: this.data1
          }, {
              name:'A temps',
              type:'spline',
              color: 'green',
              data: this.data2
          }]
        })

      } else {
        this.dservice.getUserRappel(this.trigSuiviRappel).subscribe(
          (data: any) => {
            data.rappelATemps.forEach((elt:any) => {

              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                this.data2[11] += 1
              }

              for (let i = 0; i < 11; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  this.data2[10-i] += 1
                }
              }
            });

            data.rappelEnRetards.forEach((elt:any) => {
              if ( new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth(), 1)) {
                this.data1[11] += 1
              }

              for (let i = 0; i < 11; i++) {
                if ((new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) >= new Date(today.getFullYear(), today.getMonth()-(i+1), 1)) && (new Date(this.datePipe.transform(elt.date, 'yyyy-MM-dd')!) < new Date(today.getFullYear(), today.getMonth()-i, 1))) {
                  this.data1[10-i] += 1
                }
              }
            });

            this.rappelChart = Highcharts.chart('chartRappel', {
              chart: {
                  type: 'areaspline',
                  height: 400,
              },
              legend: {
                enabled: false
              },
              title: {
                  text: ''
              },
              xAxis: {
                  categories: categories,
              },
              yAxis: {
                  title: {
                      text: 'Nombre de rappels'
                  }
              },
              tooltip: {
                  shared: true,
                  valueSuffix: ' units'
              },
              credits: {
                  enabled: false
              },
              plotOptions: {
                  areaspline: {
                      fillOpacity: 0.1
                  }
              },
              series: [{
                name:'En retard',
                  type: 'spline',
                  color: 'red',
                  data: this.data1
              }, {
                  name:'A temps',
                  type:'spline',
                  color: 'green',
                  data: this.data2
              }]
            })
          }
        )
      }

    }

  }

  setPeriodeSuiviRappel (): void {
    this.filtrerSuiviRappel();
  }

  setTrigSuiviRappel (): void {
    this.filtrerSuiviRappel();
  }

  categories : String[] = []
  data1: number[] = []
  data2: number[] = []


  ngAfterViewInit(): void {
    this.rappelChart = Highcharts.chart('chartRappel', {
      chart: {
          type: 'areaspline',
          height: 400,
      },
      legend: {
        enabled: false
      },
      title: {
          text: ''
      },
      xAxis: {
          categories: [
              'M1',
              'M2',
              'M3',
              'M4',
          ],
      },
      yAxis: {
          title: {
              text: 'Nombre de rappels'
          }
      },
      tooltip: {
          shared: true,
          valueSuffix: ' units'
      },
      credits: {
          enabled: false
      },
      plotOptions: {
          areaspline: {
              fillOpacity: 0.1
          }
      },
      series: [{
        name:'En retard',
          type: 'spline',
          color: 'red',
          data: this.data1
      }, {
          name:'A temps',
          type:'spline',
          color: 'green',
          data: this.data2
      }]
  })
  }

}
