<div class="container-fluid mb-5">
    <div class="d-flex justify-content-center" style="min-height: 200px;" *ngIf="makeCalcul || loadTrigramme">
        <div class="spinner-border text-secondary mt-5" style="height: 4rem; width: 4rem;" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>
    <div class="row" *ngIf="!makeCalcul && !loadTrigramme">
        <div class="col-md-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center mb-1 title">Mes rappels</div>
                    <div class="font-weight-bold">
                        Rappels du jour: <span class="text-green">{{rappelDuJour}}</span> <br> Rappels à venir: <span class="text-green">{{rappelAVenir}}</span> <br> Rappels en retard: <span class="text-danger">{{rappelEnRetard}}</span> <br> Rappels à
                        temps: <span>{{rappelATemp}}</span>
                    </div>
                    <div class="d-flex justify-content-center mt-1" *ngIf="isAdmin">
                        <div class="row">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigMesRappels()" placeholder="" style="width: 100px;" [(ngModel)]="trigMesRappels">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center mb-1 title">Besoins CRM</div>
                    <div class="font-weight-bold">
                        1 mois glissant: <span class="text-green">{{moisGlissantBesoins}}</span> <br> En cours: <span class="text-danger">{{besoinsEnCours}}</span>
                        <br> Reccurents: <span>{{besoinsRecurrent}}</span>
                        <br> K.O: <span>{{besoinsKo}}</span>
                        <br> Traités: <span>{{besoinsTermine}}</span>
                        <br> Présentation: <span>{{besoinsPresentation}}</span>
                        <br> Signés: <span>{{besoinsSigne}}</span>
                    </div>
                    <div class="d-flex justify-content-center mt-1" *ngIf="isAdmin">
                        <div class="row">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigBesoinCRM()" placeholder="" style="width: 100px;" [(ngModel)]="trigBesoinCRM">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center mb-1 title">Consultants par mois glissant</div>
                    <div class="font-weight-bold">
                        Nouveaux: <span class="text-green">{{nouveauxConsultants}}</span> <br> Mis à jour: <span class="text-danger">{{consultantsMAJ}}</span> <br> Total consultants: <span>{{totalConsultants}}</span>
                    </div>
                    <div class="d-flex justify-content-center mt-1" *ngIf="isAdmin">
                        <div class="row">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigConsutant()" placeholder="" style="width: 100px;" [(ngModel)]="trigConsutant">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--
              <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center mb-1 title">Mon actualité</div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Messages</th>
                                    <th scope="col">Emis le:</th>
                                    <th scope="col">Expire le:</th>
                                    <th scope="col">Emetteur</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of messages">
                                    <td>{{item.contenu}}</td>
                                    <td>{{item.date | date:'dd/MM/yyy hh:mm a'}}</td>
                                    <td>{{item.expiration | date:'dd/MM/yyy hh:mm a'}}</td>
                                    <td>{{item.emetteur}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div *ngIf="user.roles[0] === 'Gestionnaire'" class="d-flex flex-row-reverse mt-1">
                            <button class="btn btn-primary" (click)="openSentMessageModal(sentMessageModal)">Messages envoyés</button>
                            <button class="btn btn-success mr-2" (click)="openMessageModal(messageModal)">Nouveau message</button>
                        </div>
                    </div>
                </div>
            </div>
            -->

    </div>
</div>


<ng-template #messageModal let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Nouveau message</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="background-color: white;">
        <div class="row mt-2 mb-2">
            <div class="mr-2 ml-2">Envoyer à</div>
            <ng-select [items]="trigrammes2" [multiple]='true' bindLabel="trigrammes" #selectTrigramme placeholder="Trigrammes" style="width: 250px;" [(ngModel)]="destinataires">
            </ng-select>
        </div>
        <div class="row mt-2 mb-2">
            <div class="mr-2 ml-2">Message</div>
            <textarea name="message" id="message" cols="20" rows="5" class="form-control" [(ngModel)]="message"></textarea>
        </div>
    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-success" (click)="sendMessage()">Envoyer</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Annuler</button>
    </div>
</ng-template>


<ng-template #alertModal let-modal>
    <div class="modal-body" style="background-color: white;">
        <div class="d-flex flex-row-reverse">
            <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="close">&times;</span>
        </div>
        <div class="text-center h4 mt-2 mb-2">{{alertMessage}}</div>
    </div>
</ng-template>

<ng-template #sentMessageModal let-modal>
    <div class="modal-header" style="background-color: white;">
        <h4 class="modal-title">Nouveau message</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="background-color: white;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Messages</th>
                    <th scope="col">Emis le:</th>
                    <th scope="col">Expire le:</th>
                    <th scope="col">Emetteur</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sentMessages">
                    <td>{{item.contenu}}</td>
                    <td>{{item.date | date:'dd/MM/yyy hh:mm a'}}</td>
                    <td>{{item.expiration | date:'dd/MM/yyy hh:mm a'}}</td>
                    <td>{{item.emetteur}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
