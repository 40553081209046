<div class="container-fluid mb-5">

    <div class="row">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="text-center mb-1 title">Suivi des actions</div>

                    <div class="row d-flex justify-content-around">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeAction()" [(ngModel)]="periodeAction" name="periodeAction">
                    <option value="semaine" selected>1 Semaine</option>
                    <option value="mois">1 Mois</option>
                    <option value="semestre">1 Semestre</option>
                    <option value="annee">1 Année</option>
                  </select>
                        </div>

                        <div class="row" *ngIf="isAdmin">
                            <div class="mr-2">Tigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigAction()" placeholder="Tous" style="width: 100px;" [(ngModel)]="trigAction">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-9 chart">
                            <div id="actionChart">

                            </div>
                        </div>
                        <div class="col-3 justify-content-center" style="margin-top: 15px;" id="controlActions">
                            <div class="form-check" *ngFor="let serie of series4" style="margin: 2px;">
                                <label class="form-check-label"><input class="check1 form-check-input" type="checkbox" name=serie.serie.name
                              (click)="addSerie4(serie.serie.name)" value="" [(ngModel)]="serie.display"><div class="indicator" [style.background-color]="serie.serie.color"></div>{{serie.serie.name}}</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</div>