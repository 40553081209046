<div class="container-fluid">

    <div class="container-fluid mt-2 mb-5">

        <div class="table-container table-responsive">
            <div class="w-100 text-center py-1" style="font-size: 18px; background-color: #EFEEEE; font-weight: 600;">
                Suivi de la facturation
            </div>
            <table id="element" class="table table-striped table-hover">
                <thead>
                    <tr class="table-secondary">
                        <th scope="col">
                            Mois
                        </th>
                        <th scope="col">
                            <div class="input">
                                <mat-form-field class="form-fiel" style="width: 60%" title="nom client">
                                    <mat-label class="label">Nom client</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="ncl" [(ngModel)]="nomclient">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('client')">
                                    <img *ngIf="this.sortBy != 'client'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'client' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'client' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>
                        <th scope="col" style="width: 130px">
                            <div class="input">
                                <mat-form-field class="form-fiel" style="width: 60%" title="nom consultant">
                                    <mat-label class="label">Nom consultant</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="nomcons" [(ngModel)]="nomconsultant">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('nomconsultant')">
                                    <img *ngIf="this.sortBy != 'nomconsultant'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomconsultant' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'nomconsultant' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>

                        <th scope="col" class="secteur" style="width: 130px">
                            <div class="input" id="secteur">
                                <mat-form-field class="form-fiel" style="width: 60%" title="prénom consultant">
                                    <mat-label class="label">Prénom consultant</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="prenomcons" [(ngModel)]="prenomconsultant">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('prenomconsultant')">
                                    <img *ngIf="this.sortBy != 'prenomconsultant'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'prenomconsultant' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'prenomconsultant' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>

                        <th scope="col" class="note">
                            <div class="input" id="secteur">
                                <mat-form-field class="form-fiel" style="width: 60%" title="numéro contrat">
                                    <mat-label class="label">Numéro contrat</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="contrath" [(ngModel)]="contrath">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('contrat')">
                                    <img *ngIf="this.sortBy != 'contrat'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'contrat' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'contrat' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="note">
                            Référant contrat chez client
                        </th>
                        <th scope="col" class="note">
                            Nombre de jours travaillés
                        </th>
                        <th scope="col" class="note">
                            <div class="input" id="secteur">
                                <mat-form-field class="form-fiel" style="width: 60%" title="TJM client">
                                    <mat-label class="label">TJM client</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="tjmclienth" [(ngModel)]="tjmclienth">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('tjmclient')">
                                    <img *ngIf="this.sortBy != 'tjmclient'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'tjmclient' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'tjmclient' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="note">
                            <div class="input" id="secteur">
                                <mat-form-field class="form-fiel" style="width: 60%" title="TJM consultant">
                                    <mat-label class="label">TJM consultant</mat-label>
                                    <input matInput (keyup.enter)="filterMissions()" name="tjmconsultanth" [(ngModel)]="tjmconsultanth">
                                </mat-form-field>
                                <div class="sort" (click)="this.setSort('tjmconsultant')">
                                    <img *ngIf="this.sortBy != 'tjmconsultant'" src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'tjmconsultant' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                    <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'tjmconsultant' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="note">
                            Nombre d'unités d'astreinte
                        </th>
                        <th scope="col" class="note">
                            Tarif unité d'astreinte client
                        </th>
                        <th scope="col" class="note">
                            Tarif unités d'astreinte consultant
                        </th>
                        <th scope="col" class="note">
                            Montant total TTC
                        </th>
                        <th scope="col" class="note">
                            CRA validé
                        </th>
                        <th scope="col" class="note">
                            Générer facture client
                        </th>
                        <th scope="col" class="note">
                            Suivi règlements client
                        </th>
                        <th scope="col" class="note">
                            Elt paiement client
                        </th>
                        <th scope="col" class="note">
                            Délai paiement client
                        </th>
                        <th>
                            Valider facture consultant
                        </th>
                        <th>
                            Elt paiement consultant
                        </th>
                        <th>
                            Délai paiement consultant
                        </th>
                        <th>
                            Suvi règlements consultant
                        </th>
                    </tr>
                </thead>
                <tbody style="position: relative;">
                    <tr *ngFor="let mission of missionsToDisplay; let i = index">
                        <td>{{mission.date | date:'MMM y'}}</td>
                        <td [innerHTML]="mission.client | highlight:nomclient"></td>
                        <td [innerHTML]="mission.nomconsultant | highlight:nomconsultant"></td>
                        <td [innerHTML]="mission.prenomconsultant | highlight:prenomconsultant"></td>
                        <td [innerHTML]="mission.contrat | highlight:contrath" [title]="mission.contrat"></td>
                        <td [title]="mission.facturationreferantprenom +' '+ mission.facturationreferantnom"> {{mission.facturationreferantprenom +' '+ mission.facturationreferantnom}} </td>
                        <td> {{ mission.cra ? mission.cra.totaljourtravaille : "" }} </td>
                        <td> {{mission.tjmclient}}</td>
                        <td> {{mission.tjmconsultant}} </td>
                        <td> {{ mission.cra ? mission.cra.nbreastreintes : "" }} </td>
                        <td> {{mission.tarifastreinteclient}}</td>
                        <td> {{mission.tarifastreinteconsultant}}</td>
                        <td> {{mission.cra? mission.cra.totaljourtravaille*mission.tjmconsultant + mission.cra.nbreastreintes*mission.tarifastreinteconsultant : ""}}</td>
                        <td>
                            <input type="checkbox" class="form-check-input mr-2" [name]="'validcra'+i" [(ngModel)]="mission.cra.validationcra" (change)="validateCra(mission)" [disabled]="mission.datefin < today" *ngIf="mission.cra" disabled>
                            <i class="bi bi-eye-fill" (click)="openCraModalClient(mission.cra.idcra)" *ngIf="mission.cra"></i>
                        </td>
                        <td>
                            <span class="text-danger" *ngIf="!mission.cra">Attente CRA</span>
                            <span class="text-warning" *ngIf="mission.cra && mission.cra.validationcra && (mission.cra.factureclient == null || mission.cra.factureclient =='')">A générer</span>
                            <span class="text-success" *ngIf="mission.cra && mission.cra.validationcra && mission.cra.factureclient != null && mission.cra.factureclient !=''">Générée</span>
                            <input type="checkbox" class="form-check-input mr-2" (click)="openFactureClientModal($event, mission, genClient)" *ngIf="mission.cra && mission.cra.validationcra && (mission.cra.factureclient == null || mission.cra.factureclient == '')">
                            <i class="bi bi-eye-fill" (click)="downloadFile(mission.cra.factureclient)" *ngIf="mission.cra && mission.cra.validationcra && mission.cra.factureclient != null && mission.cra.factureclient != ''"></i>
                        </td>
                        <td>
                            <select [name]="'regclient'+i" [ngStyle]="{ color: selectcolor1(mission) }" [(ngModel)]="mission.cra.reglementclient" style="width: 85%;" [disabled]="mission.cra.reglementclient == ''" (change)="changeStatutReglementClient(mission)" *ngIf="mission.cra && mission.cra.factureclient">
                            <option value="En_attente" style="color: white;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="En_retard" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="Regle" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                          </select>
                        </td>
                        <td>
                            <div (click)="openFacturationModal(mission, factModal)">
                                <i class="bi bi-receipt mr-1"></i> <i class="bi bi-pencil-fill"></i>
                            </div>
                        </td>
                        <td>{{mission.delaipaiementclient}} j</td>
                        <td>
                            <span class="text-danger" *ngIf="!mission.cra">Attente CRA</span>
                            <span class="text-warning" *ngIf="mission.cra && mission.cra.validationcra && (mission.cra.factureconsultant == null || mission.cra.factureconsultant =='')">A générer</span>
                            <span class="text-success" *ngIf="mission.cra && mission.cra.validationcra && mission.cra.factureconsultant != null && mission.cra.factureconsultant !=''">Validée</span>
                            <input type="checkbox" class="form-check-input mr-2" (click)="openFactureConsultantModal($event, mission)" *ngIf="mission.cra && mission.cra.validationcra && (mission.cra.factureconsultant == null || mission.cra.factureconsultant == '')">
                            <i class="bi bi-eye-fill" (click)="downloadFile(mission.cra.factureconsultant)" *ngIf="mission.cra && mission.cra.validationcra && mission.cra.factureconsultant != null && mission.cra.factureconsultant != ''"></i>
                        </td>
                        <td>
                            <div (click)="openPaiementModal(mission, paiementModal)">
                                <i class="bi bi-receipt mr-1"></i> <i class="bi bi-pencil-fill"></i>
                            </div>
                        </td>
                        <td>{{mission.delaipaiementconsultant}} j</td>
                        <td>
                            <select [name]="'regcons'+i" [ngStyle]="{ color: selectcolor2(mission) }" [(ngModel)]="mission.cra.reglementconsultant" style="width: 85%;" [disabled]="mission.cra.reglementconsultant == ''" (change)="changeStatutReglementConsultant(mission)" *ngIf="mission.cra && mission.cra.factureconsultant">
                            <option value="En_attente" style="color: white;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="En_retard" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="Regle" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                          </select>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>

        <hr>

        <div class="row d-flex pb-1 mb-4" *ngIf="pages.length>0">
            <div class="col-md-4 d-flex justify-content-center">

            </div>
            <div class="col-md-4 align-self-center text-center page-info">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" href="" (click)="setPage(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" role="button" href="" (click)="setPage(page-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                    </li>

                    <!-- <li class="nav-item" *ngFor="let p of pages; let i=index">
                    <a class="nav-link" *ngIf="i===page || i===page-1 || i===page+1" [ngClass]="{active: i===page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li> -->
                    <div class="mt-2">
                        <input type="number" style="width: 50px; height: 25px; color: red;" [(ngModel)]="currentPage" #pageInput (keyup)="changePage()"> / <span>{{pages.length}}</span>
                    </div>


                    <li class="nav-item">
                        <a class="nav-link" href="" (click)="setPage(page+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" (click)="setPage(pages.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 d-flex justify-content-center">
                <span class="mt-1">Par page:</span>
                <select class="form-select form-select-sm" id="size-select" name="size" #selectSize [ngModel]="size" (change)="setSize(+(selectSize.value))">
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="120">120</option>
            </select>
            </div>
        </div>

    </div>

</div>



<ng-template #craModalClient let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">CRA consultant</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <div>
            <table class="table table-striped">
                <thead>
                    <tr class="table-secondary">
                        <th>
                            Date
                        </th>
                        <th>
                            Matin
                        </th>
                        <th>
                            Après-midi
                        </th>
                        <th style="max-width: 150px">
                            Unités d'astreinte
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let day of cra.days; let i = index">
                        <td class="text-center">
                            {{day.date | date:'fullDate'}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.matin}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.apresmidi}}
                        </td>
                        <td class="p-2 text-center">
                            {{day.astreinte}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-100 text-center py-2" style=" background-color: #EFEEEE;">
                <div style="font-weight: bold; font-size: 20px;">
                    Total
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">Jours travaillés: <span class="text-info">{{cra.totaltravail}} j</span></div>
                    <div class="col-md-4">Astreintes: <span class="text-info">{{cra.totalastreintes}} Unités</span></div>
                    <div class="col-md-4">CP: <span class="text-info">{{cra.totalcp}} j</span></div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">RTT: <span class="text-info">{{cra.totalrtt}} j</span></div>
                    <div class="col-md-4">CSS: <span class="text-info">{{cra.totalcss}} j</span></div>
                    <div class="col-md-4">Maladie: <span class="text-info">{{cra.totalmaladie}} j</span></div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
        </div>
    </div>
</ng-template>

<ng-template #factModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Modifier les informations de facturation</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #updateFacturationForm="ngForm" (ngSubmit)="updateFacturation()">
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" name="rc" placeholder="RC" [(ngModel)]="facturation.facturationrc" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="facturation.facturationmf" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA inter." [(ngModel)]="facturation.facturationtva" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="facturation.contratcadre" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="facturation.contrat" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Référant de facturation</label>
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="refnom" placeholder="Nom" [(ngModel)]="facturation.facturationreferantnom" required disabled>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="refprenom" placeholder="Prénom" [(ngModel)]="facturation.facturationreferantprenom" required disabled>
                    </div>
                </div>
                <input type="text" class="form-control form-control-sm mt-1" name="fref" placeholder="Fonction" [(ngModel)]="facturation.facturationreferantfonction" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tref" placeholder="Téléphone" [(ngModel)]="facturation.facturationreferanttelephone" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="eref" placeholder="Email" [(ngModel)]="facturation.facturationreferantemail" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="adf" placeholder="Adresse de facturation" [(ngModel)]="facturation.facturationadresse" disabled></textarea>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Notre compte à créditer</label>
                <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="facturation.facturationintermediairetitulaire" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="facturation.facturationintermediaireiban" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="facturation.facturationintermediairebic" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="facturation.facturationintermediairedomiciliation" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse de facturation" [(ngModel)]="facturation.facturationintermediaireadresse" disabled></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mr-4" disabled>Enregistrer</button>
                <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #paiementModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Eléments de paiement consultant</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #updatePaiementForm="ngForm" (ngSubmit)="updatePaiement()">
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="facturation1.paiementsociete" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="rc" placeholder="RC" [(ngModel)]="facturation1.paiementrc" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="facturation1.paiementmf" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA" [(ngModel)]="facturation1.paiementtva" disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="facturation1.contratcadre" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="facturation1.contrat" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Informations bancaires</label>
                <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="facturation1.paiementtitulaire" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="facturation1.paiementiban" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="facturation1.paiementbic" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="facturation1.paiementdomiciliation" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="facturation1.paiementadresse" disabled></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mr-4" disabled>Enregistrer</button>
                <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #genClient let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Générer la facture client</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #generateFacturationForm="ngForm" (ngSubmit)="generateFacture()">
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" name="rc" placeholder="RC" [(ngModel)]="eltfactureclient.facturationrc" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="eltfactureclient.facturationmf" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA inter." [(ngModel)]="eltfactureclient.facturationtva" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="eltfactureclient.contratcadre" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                <input type="text" class="form-control form-control-sm" name="ct" [(ngModel)]="eltfactureclient.contrat" required disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Référant de facturation</label>
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="refnom" placeholder="Nom" [(ngModel)]="eltfactureclient.facturationreferantnom" required disabled>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="refprenom" placeholder="Prénom" [(ngModel)]="eltfactureclient.facturationreferantprenom" required disabled>
                    </div>
                </div>
                <input type="text" class="form-control form-control-sm mt-1" name="fref" placeholder="Fonction" [(ngModel)]="eltfactureclient.facturationreferantfonction" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tref" placeholder="Téléphone" [(ngModel)]="eltfactureclient.facturationreferanttelephone" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="eref" placeholder="Email" [(ngModel)]="eltfactureclient.facturationreferantemail" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="adf" placeholder="Adresse de facturation" [(ngModel)]="eltfactureclient.facturationadresse" disabled></textarea>
            </div>
            <div class="form-group mb-2">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Notre compte à créditer</label>
                <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="eltfactureclient.facturationintermediairetitulaire" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="eltfactureclient.facturationintermediaireiban" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="eltfactureclient.facturationintermediairebic" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="eltfactureclient.facturationintermediairedomiciliation" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse de facturation" [(ngModel)]="eltfactureclient.facturationintermediaireadresse" disabled></textarea>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre J travaillés</label>
                        <input type="number" class="form-control form-control-sm" name="jt" [(ngModel)]="eltfactureclient.totaljourtravaille" required disabled>
                    </div>
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre astreintes</label>
                        <input type="number" class="form-control form-control-sm" name="astreintes" [(ngModel)]="eltfactureclient.nbreastreintes" required disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">TJM client</label>
                        <input type="number" class="form-control form-control-sm" name="tjmclient" [(ngModel)]="eltfactureclient.tjmclient" required disabled>
                    </div>
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Tarif unité astreinte</label>
                        <input type="number" class="form-control form-control-sm" name="tarifastreinte" [(ngModel)]="eltfactureclient.tarifastreinteclient" required disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total HT</label>
                        <input type="number" class="form-control form-control-sm" name="totalHt" #htinput [value]="eltfactureclient.tjmclient*eltfactureclient.totaljourtravaille + eltfactureclient.tarifastreinteclient*eltfactureclient.nbreastreintes" disabled>
                    </div>
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TVA</label>
                        <input type="number" class="form-control form-control-sm" name="ttva" [(ngModel)]="eltfactureclient.totaltva" required #tvainput>
                    </div>
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TTC</label>
                        <input type="number" class="form-control form-control-sm" name="tarifastreinte" [value]="htinput.value + tvainput.value" disabled>
                    </div>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Numéro facture</label>
                    <input type="text" class="form-control form-control-sm mt-1" name="numf" placeholder="" [(ngModel)]="eltfactureclient.numerofacture" required>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Date facturation</label>
                    <input type="date" class="form-control" name="datefact" placeholder="Date de fin" [min]="today" title="Date facturation" [(ngModel)]="eltfactureclient.datefacturation" required>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Echéance paiement</label>
                    <input type="date" class="form-control" placeholder="Echéance" name="echeancepaiement" [min]="eltfactureclient.datefacturation" title="Echéance" [(ngModel)]="eltfactureclient.echeancepaiement" required>
                </div>
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Consultant</label>
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="nomco" [(ngModel)]="eltfactureclient.nomconsultant" disabled>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="prenomco" [(ngModel)]="eltfactureclient.prenomconsultant" disabled>
                    </div>
                </div>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="desc" placeholder="Description" [(ngModel)]="eltfactureclient.description"></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-success mr-4" [disabled]="!generateFacturationForm.valid">Générer la facture</button>
                <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #factureModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Eléments de paiement consultant</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #genfactureForm="ngForm" (ngSubmit)="genererFactureConsulant()">
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Consultant</label>
                <div class="row mb-1">
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="nomco" [(ngModel)]="eltfacture.nomconsultant" disabled>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm" name="prenomco" [(ngModel)]="eltfacture.prenomconsultant" disabled>
                    </div>
                </div>
                <input type="text" placeholder="Téléphone" class="form-control form-control-sm mb-1" name="telco" [(ngModel)]="eltfacture.telephoneconsultant" required>
                <input type="email" placeholder="Email" class="form-control form-control-sm mb-1" name="emailco" [(ngModel)]="eltfacture.emailconsultant">
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="adreco" placeholder="Adresse" [(ngModel)]="eltfacture.adresseconsultant"></textarea>
            </div>
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="eltfacture.paiementsociete" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="rc" placeholder="RC" [(ngModel)]="eltfacture.paiementrc" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="mf" placeholder="MF" [(ngModel)]="eltfacture.paiementmf" disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="tva" placeholder="N° TVA" [(ngModel)]="eltfacture.paiementtva" disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat cadre</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="eltfacture.contratcadre" disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Contrat</label>
                <input type="text" class="form-control form-control-sm" name="cc" [(ngModel)]="eltfacture.contrat" disabled>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Informations bancaires</label>
                <input type="text" class="form-control form-control-sm mt-1" name="tcompte" placeholder="Titulaire" [(ngModel)]="eltfacture.paiementtitulaire" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="icompte" placeholder="IBAN" [(ngModel)]="eltfacture.paiementiban" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="bcompte" placeholder="BIC" [(ngModel)]="eltfacture.paiementbic" required disabled>
                <input type="text" class="form-control form-control-sm mt-1" name="dcompte" placeholder="Domiciliation" [(ngModel)]="eltfacture.paiementdomiciliation" required disabled>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="eltfacture.paiementadresse" disabled></textarea>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre J travaillés</label>
                        <input type="number" class="form-control form-control-sm" name="jt" [(ngModel)]="eltfacture.totaljourtravaille" required disabled>
                    </div>
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nbre astreintes</label>
                        <input type="number" class="form-control form-control-sm" name="astreintes" [(ngModel)]="eltfacture.nbreastreintes" required disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">TJM client</label>
                        <input type="number" class="form-control form-control-sm" name="tjmconsultant" [(ngModel)]="eltfacture.tjmcconsultant" required disabled>
                    </div>
                    <div class="col-md-6">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Tarif unité astreinte</label>
                        <input type="number" class="form-control form-control-sm" name="tarifastreinte" [(ngModel)]="eltfacture.tarifastreinteconsultant" required disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total HT</label>
                        <input type="number" class="form-control form-control-sm" name="totalHt" #htinput [value]="eltfacture.tjmconsultant*eltfacture.totaljourtravaille + eltfacture.tarifastreinteconsultant*eltfacture.nbreastreintes" disabled>
                    </div>
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TVA</label>
                        <input type="number" class="form-control form-control-sm" name="ttva" [(ngModel)]="eltfacture.totaltva" required #tvainput>
                    </div>
                    <div class="col-md-4">
                        <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Total TTC</label>
                        <input type="number" class="form-control form-control-sm" name="tarifastreinte" [value]="htinput.value + tvainput.value" disabled>
                    </div>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Numéro facture</label>
                    <input type="text" class="form-control form-control-sm mt-1" name="numf" placeholder="" [(ngModel)]="eltfacture.numerofacture" required>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Date facturation</label>
                    <input type="date" class="form-control" name="datefact" placeholder="Date de fin" [min]="today" title="Date facturation" [(ngModel)]="eltfacture.datefacturation" required>
                </div>
                <div>
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Echéance paiement</label>
                    <input type="date" class="form-control" placeholder="Echéance" name="echeancepaiement" [min]="eltfacture.datefacturation" title="Echéance" [(ngModel)]="eltfacture.echeancepaiement" required>
                </div>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="desc" placeholder="Activité" [(ngModel)]="eltfacture.activite"></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-success mr-4" [disabled]="!genfactureForm.valid">Valider la facture</button>
                <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>
