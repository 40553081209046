import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from './../Model/user';
import { Component, OnInit, Input, HostListener, ViewChildren, QueryList,ElementRef,TemplateRef,AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultantService } from '../services/consultant.service';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DisponibiliteService } from '../services/disponibilite.service';
import { QualiteService } from '../services/qualite.service';
import { DatePipe } from '@angular/common';
import { TechnologieService } from '../services/technologie.service';
import { CommunicationService } from '../services/communication.service';
import { ActionsService } from '../services/actions.service';
import { Rappels } from '../Model/rappels';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {PopupdataComponent} from '../popupdata/popupdata.component'

import { Tagtech } from '../Model/tagtech';
import { Tagmet } from '../Model/tagmet';
import { Tagfonc } from '../Model/tagfonc';
import { ClientService } from '../services/client.service';
import { TagtechService } from '../services/tagtech.service';
import { TagfoncService } from '../services/tagfonc.service';
import { TagmetService } from '../services/tagmet.service';
import { RappelsService } from '../services/rappels.service';
import * as _ from 'lodash';
import { UploadFileService } from '../upload/upload-file.service';
import { CV } from '../Model/cv';
import { CvService } from '../services/cv.service';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { RefRechercheService } from '../services/ref-recherche.service';
import { Technologie } from '../Model/technologie';
import { Client } from '../Model/client';
import { Observation } from '../Model/observation';
import { DictioService } from '../services/dictio.service';
import { Dictionnaire } from '../Model/dictionnaire';
import { MotsDictionnaire } from '../Model/mots-dictionnaire';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from "ngx-spinner";
import {Title} from "@angular/platform-browser";
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { flattenDiagnosticMessageText } from 'typescript';
import { backEndpoint } from '../config';
import * as fileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsultantProfileService } from '../services/consultants/consultant-profile.service';


export interface Element {
  _id: string;
  action: string;
  date: string;
  trigramme: string;
}

const ELEMENT_DATA: Element[] = [];


function arrayRemove(arr, value) {
  return arr.filter(
    function(ele){
      return ele != value;
    })
  ;
}


@Component({
  selector: 'app-fiche-consultant',
  templateUrl: './fiche-consultant.component.html',
  styleUrls: ['./fiche-consultant.component.scss'],
  providers: [DatePipe]
})

export class FicheConsultantComponent implements OnInit {

  red;
  grey;
  yellow;


  updateobs;
  clientConsultantData2: { client: any; };

  // Liste des missions

  // header du tableau des missions
  sortBy1: string = "datedebut";
  sortDirection1 = "DESC";
  nomclient = "";
  datedebut = "";
  datefin = "";
  caracteristiques = "";


  missions: any[] = []
  missionToUpdate: any;
  facturation: any = {
    "paiementsociete" : "",
    "paiementrc" : "",
    "paiementmf" : "",
    "paiementtva" : "",
    "paiementtitulaire" : "",
    "paiementiban" : "",
    "paiementbic" : "",
    "paiementdomiciliation" : "",
    "paiementadresse" : ""
  }
  idcra: string = "";
  cra: any;

  // Liste des missions


  @HostListener("window:keyup", ["$event"]) Getregex(event: any) {
    if (event.target.id === "regTag") {
      this.regex = event.target.value;
      this.getTagTechPage({
        page: "0",
        size: this.size,
        regex: this.regex,
      });
    }
    if (event.target.id === "regtechn") {
      this.regexTechno = event.target.value;
      this.getTechnoPage({
        page: "0",
        size: this.sizeTechno,
        regex: event.target.value,
      });
    }
    if (event.target.id === "client") {
      this.regexClient = event.target.value;
      this.getClientPage({
        page: "0",
        size: this.sizeClient,
        regex: event.target.value,
      });
    }
    if (event.target.id === "metier") {
      this.regexMetier = event.target.value;
      this.getMetierPage({
        page: "0",
        size: this.sizeMetier,
        regex: event.target.value,
      });
    }
    if (event.target.id === "fonction") {
      this.regexFonction = event.target.value;
      this.getFonctionPage({
        page: "0",
        size: this.sizeFonction,
        regex: event.target.value,
      });
    }
  }

  TagTechTabFilter: Tagtech[] = [];
  TechnoTabFilter: Technologie[] = [];
  ClientTabFilter: Client[] = [] ;
  MetierTabFilter: Tagmet[] = [];
  FonctionTabFilter: Tagfonc[] = [];

  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  addresse=""
  addresse2=""
  linkedindata=""
  private sub: any;
  id: string;
  titree: string;
  name: string;
  ddm: string;
  updaterappeldata =new Rappels();
  ddm2: string;
  consultant: any;
  user: any = [];
  rappel= new Rappels();
  initcv= new CV();
  tabcv : any = [];
  idcv: String;
  filename: string;
  conrappel : any = [];
  rptest= new Rappels();
  obstab : any = [];
  obstest = new Observation();
  old = new Observation();
  modalRef: BsModalRef;
   obstest2 = new Observation();
  numrappel: number;
  rapp= new Rappels();
  rappid: string ="";
  oldrappelcancel:String = ''
  rp: any = [];

  isModerateur = false;

  dictio= new Dictionnaire();
  motsDic = new MotsDictionnaire();

  dic = new Dictionnaire();
  newMot = new MotsDictionnaire();

  displayedColumns: string[] = ['Actions', 'Date', 'Trigramme' ];
  data = Object.assign( ELEMENT_DATA);
  dataSource = new MatTableDataSource(this.data);

  registerForm: FormGroup;
  bodyText: string;
 submitted = false;

 //Récupération de tous
 Dispo = [];
 Qualite = [];
 Tech = [];
 Clients = [];
 TagTech = [];
 TagMetier = [];
 TagFonc = [];

 //Checkbox
 Techno = [];
 Cli = [];
 Tag1 = [];
 Tag2 = [];
 Tag3 = [];

 @Input() consultantData = { nom: '', prenom: '', titre: '',qualitecv:{},codedispo:{},
 tjm: '', telephone_principal: '', email: '', source: '', linkedin: '', code_postal: '',
 ville: '', cv: [], dateDerniereMaj: '',updated:0, observation: [], tech: [], client: [], collaborateur: '', tagtech: [],
 tagmetier: [], tagfonc: [],dda: '', exp: '', rappels: ''};

 @Input() consultantData3 = { titre: '',qualitecv:{},codedispo:{},

  cv: [], observation: [], tech: [], client: [],  tagtech: [],
 tagmetier: [], tagfonc: [], rappels: ''};
 @Input() consultantData2 = { titre: '',qualitecv:{},codedispo:{},

 cv: [], observation: [], tech: [], client: [], tagtech: [],
tagmetier: [], tagfonc: [], rappels: ''};


trigramme: string = "";
userid: string = "";
roles: string[] = [];

 John = 'aaaa';
 nom = '';
 prenom = '';
 oldrappel = new Rappels();
 oldrappel2 = new Rappels();
 titre = '';
 tjm = '';
 dis = '';
 observation = [];
 quali = '';
 telp = '';
 email = '';
 linkedin = '';
 code = '';
 ville = '';
 cv = [];
 dmaj = '';
 green ="red"
 obs = '';
 tech = [];
 client = [];
 tagtech = [];
 tagmetier = [];
 tagfonc = [];
 dda = '';
 exp = '';

 rappels = '';
 collaborateur = '';
 orange = "orange"
 closeResult: string;
 updaterappel
 myDate = new Date();
 newDate = new Date().getFullYear();
 dateDda = new Date();
 today = new Date().toJSON().split('T')[0];

 expanded1 = false;
 expanded2 = false;
 expanded3 = false;
 expanded4 = false;
 expanded5 = false;
  cvv: any = [];
  test: string;

  //CV propriété :
  days = new Date().getDate().toString();
  month = new Date().getMonth().toString();
  year = new Date().getFullYear().toString();
  hours = new Date().getHours().toString();
  minutes = new Date().getMinutes().toString();
  secondes = new Date().getSeconds().toString();

      /*  TagTech Pagination */
      libelle: string = 'Ancunes Technologies';
      @ViewChildren("inputs") inputs: QueryList<ElementRef>;
      tagTechUpdate: Tagtech = new Tagtech();
      totalElements: number = 0;
      tagTechPage: Tagtech[] = [];
      loading: boolean;
      suivant = 0;
      ordre = 2;
      dispoordre = 2;
      size: number = 25;
      totalPage: number;
      regex: string = "";
      TagTechPageUpdate: Tagtech[] = [];
      TagTechFilter: Tagtech[] = [];
      //isChecked: boolean = false;
      forceupdate=false
      /* Techno Pagination */
      technoUpdate: Technologie = new Technologie();
      totalElementsTechno: number = 0;
      TechnoPage: Technologie[] = [];
      loadingTechno: boolean;
      suivantTechno = 0;
      sizeTechno: number = 25;
      totalPageTechno: number;
      regexTechno: string = "";
      TechnoPageUpdate: Technologie[] = [];
      TechnoFilter: Technologie[] = [];

      updated=false;
      /*  Client Pagination */
      clientUpdate: Client = new Client();
      totalElementsClient: number = 0;
      clientPage: Client[] = [];
      loadingClient: boolean;
      suivantClient = 0;
      sizeClient: number = 25;
      totalPageClient: number;
      dispolibelle="";
      regexClient: string = "";
      ClientPageUpdate: Client[] = [];
      ClientFilter: Client[] = [];

       /*  Fonc Pagination */
       FonctionUpdate: Tagfonc = new Tagfonc();
       totalElementsFonction: number = 0;
       fonctionPage: Tagfonc[] = [];
       loadingFonction: boolean;
       suivantFonction = 0;
       sizeFonction: number = 25;
       totalPageFonction: number;
       regexFonction: string = "";
       FonctionPageUpdate: Tagfonc[] = [];
       FonctionFilter: Tagfonc[] = [];

       /*  metier Pagination */
       MetierUpdate: Tagmet = new Tagmet();
       totalElementsMetier: number = 0;
       metierPage: Tagmet[] = [];
       loadingMetier: boolean;
       suivantMetier = 0;
       sizeMetier: number = 25;
       totalPageMetier: number;
       regexMetier: string = "";
       MetierPageUpdate: Tagmet[] = [];
       MetierFilter: Tagmet[] = [];
       oldobs: String=""
       csdata

      date: string = this.year+this.month+this.days+"_"+this.hours+this.minutes+this.secondes;

      favoris : string[] = [];

      // GESTION DES MISSIONS

      reference: any = {
        societe: "",
        nom: "",
        prenom: "",
        fonction: "",
        email: "",
        adresse: "",
        telephone: ""
      }

      profile: any = null;

      references: any[] = [];

  tjmconsultanth: string = "";
  contrath: string = "";
  page: number=0;
  currentPage: number = 1;
  size1: number=10;
  pages: number[] = [0];
  totalelements: number = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public cs: ConsultantService,
              public as: ActionsService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              public ds: DisponibiliteService,
              private titleService:Title,
              private modalService: BsModalService,
              public qs: QualiteService,
              private communicationService: CommunicationService,
              private dcs: DictioService,
              private ts: TechnologieService,
              private cl: ClientService,
              private tt: TagtechService,
              private tf: TagfoncService,
              private tm: TagmetService,
              private rs: RappelsService,
              private rr: RefRechercheService,private tokenStorageService: TokenStorageService,
              private uploadService: UploadFileService,
              private datePipe: DatePipe, private fb: FormBuilder,
              private SpinnerService: NgxSpinnerService,
              private modalService1: NgbModal,
              private sanitizer: DomSanitizer,
              private profileService: ConsultantProfileService,
              public auth : AuthService) {
                this.communicationService.Broadcast('OnChild3', 'Consultation/Mise à jour Consultant');

                this.registerForm = this.formBuilder.group({
                  nom: ['', Validators.required],
                  prenom: ['', Validators.required],
                  titre: ['', Validators.required],
                  tjm: ['',Validators.pattern('[0-9]+')],
                  dis: [''],
                  quali: [''],
                  linkedin: [''],
                  code: [''],
                  ville: [''],
                  dmaj: [''],
                  observation: [''],
                  tech: [''],
                  tech2: [''],
                  client: [''],
                  client2: [''],
                  tagtech: [''],
                  tagmetier: [''],
                  tagfonc: [''],
                  source: ['', Validators.required],
                  collaborateur : [''],
                  addresse: [''],
                  addresse2: [''],
                  telp: [''],
                  email: [''],
                  cv: [''],
                  dda: [''],
                  exp: ['']
              });
              }

  ngOnInit() {


    //Toujours en haut de page
   window.scrollTo(0,0)

   const user = this.tokenStorageService.getUser();
   this.trigramme = user.trigramme;
   this.userid = user.id;
   this.roles = user.roles;

   this.getrhFavoris();

   //Vider toutes les listes

    this.Qualite = [];
    this.Dispo = [];
   this.Loader()
    this.isModerateur = this.auth.canModerate();
    ELEMENT_DATA.splice(0,ELEMENT_DATA.length);

    this.getDispo();
    this.getQualite();

   // this.RemoveToUpdate()

    this.sub = this.route
    .queryParams
    .subscribe(params => {

      //Récupérer l'ID du consultant
      this.id = params.id;
      //Afficher les données du consultants via son ID

      this.getConsultantbyId(this.id);
      this.filterMissions();
    });

  }

  visualiserDocument(fileURL) {
    this.profileService.downloadFile(fileURL).subscribe(
      blob => {
        const data = new Blob([blob], { type: 'application/pdf' });
        let url = URL.createObjectURL(data);
        window.open(url, '_blank');
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }


  openListRefModal(content, item){
    this.references = item.references;
    this.openModal(content);
  }

  openReference(content, item) {
    this.reference = item;
    this.modalService1.dismissAll();
    this.openModal(content);
  }

  // Gestion des missions

  setSort(sortcolumn: string){
    if(this.sortBy1 == sortcolumn){
      this.sortDirection1 == "ASC" ? this.sortDirection1 = "DESC" : this.sortDirection1 = "ASC";
    } else {
      this.sortBy1 = sortcolumn
      this.sortDirection1 = "ASC";
    }
    this.filterMissions();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.filterMissions();
    }
  }

  changePage(){
    if(this.currentPage>0 && this.currentPage<=this.pages.length){
      this.page = this.currentPage - 1;
      this.filterMissions();
    }
  }

  setSize(i: number) {
    this.size1 = i;
    this.filterMissions();
  }

  filterMissions(){
    this.cs.getMissions("", this.id, this.datedebut, this.datefin, this.nomclient, "", "",
    this.contrath, this.caracteristiques, "", "", "0", this.tjmconsultanth, this.sortBy1, this.sortDirection1, this.page.toString(), this.size1.toString()).subscribe(
      (data) => {
        this.missions = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
      },
      (error) => {
        console.log(error);
      }
     )
  }

  openModal(content) {
    this.modalService1.open(content, {centered: true})
  }

  updateSatisfactionConsultant(mission:any){
    this.Loader()
    mission.datesatconsultant = this.today;
    this.cs.updateMission(mission).subscribe(
      (data:any) => {
        this.filterMissions();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );
  }

  updateSatisfactionContact(mission:any){
    this.Loader()
    mission.datesatcontact = this.today;
    this.cs.updateMission(mission).subscribe(
      (data:any) => {
        this.filterMissions();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );
  }

  openPaiementModal(mission: any, content){
    this.missionToUpdate = {...mission}
    this.facturation.paiementsociete = mission.paiementsociete;
    this.facturation.paiementrc = mission.paiementrc;
    this.facturation.paiementmf = mission.paiementmf;
    this.facturation.paiementtva = mission.paiementtva;
    this.facturation.paiementtitulaire = mission.paiementtitulaire;
    this.facturation.paiementiban = mission.paiementiban;
    this.facturation.paiementbic = mission.paiementbic;
    this.facturation.paiementdomiciliation = mission.paiementdomiciliation;
    this.facturation.paiementadresse = mission.paiementadresse;

    this.openModal(content);
  }

  updatePaiement(){
    this.modalService1.dismissAll();
    this.Loader()
    this.missionToUpdate.paiementsociete = this.facturation.paiementsociete;
    this.missionToUpdate.paiementrc = this.facturation.paiementrc;
    this.missionToUpdate.paiementmf = this.facturation.paiementmf;
    this.missionToUpdate.paiementtva = this.facturation.paiementtva;
    this.missionToUpdate.paiementtitulaire = this.facturation.paiementtitulaire;
    this.missionToUpdate.paiementiban = this.facturation.paiementiban;
    this.missionToUpdate.paiementbic = this.facturation.paiementbic;
    this.missionToUpdate.paiementdomiciliation = this.facturation.paiementdomiciliation;
    this.missionToUpdate.paiementadresse = this.facturation.paiementadresse;
    this.cs.updateMission(this.missionToUpdate).subscribe(
      (data:any) => {
        this.filterMissions();
        this.facturation = {
          "paiementsociete" : "",
          "paiementrc" : "",
          "paiementmf" : "",
          "paiementtva" : "",
          "paiementtitulaire" : "",
          "paiementiban" : "",
          "paiementbic" : "",
          "paiementdomiciliation" : "",
          "paiementadresse" : ""
        }
        this.missionToUpdate = null;
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        console.log(error);
      }
    );

  }

  openCraModal(content){
    if(this.idcra != ""){
      this.cs.getCra(this.idcra).subscribe(
        (data) => {
          console.log("cra", data)
          this.cra = data;
          this.openXlModal(content)
        }
      )
    }
  }

  openDelaiModal(mission:any, content){
    this.missionToUpdate = {...mission};
    this.openModal(content);
  }

  updateDelai(){
    this.modalService1.dismissAll();
    this.Loader();
    this.cs.updateMission(this.missionToUpdate).subscribe(
      (data) => {
        this.filterMissions();
        this.dialog.closeAll();
      },
      (error) => {
        this.dialog.closeAll();
        this.Alert('Erreur lors de la mise à jour');
      }
    )
  }

  downloadFile(location: string){
    this.cs.downloadFile(location).subscribe(
      blob => {
        this.saveFile(blob, location.slice(location.lastIndexOf("/")+1));
      },
      error => console.log(error)
    )
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data]);
    fileSaver.saveAs(blob, filename);
  }

  openXlModal(content) {
		this.modalService1.open(content, { size: 'xl', centered: true });
	}

  selectcolor(mission){
    switch (mission.satisfactioncontact) {
      case "1":
        return "red"
        break;
      case "2":
        return "yellow"
        break;
      case "3":
        return "orange"
        break;
      case "4":
        return "blue"
        break;
      case "5":
        return "green"
        break;

      default:
        break;
    }
  }

  selectcolor1(mission){
    switch (mission.satisfactionconsultant) {
      case "1":
        return "red"
        break;
      case "2":
        return "yellow"
        break;
      case "3":
        return "orange"
        break;
      case "4":
        return "blue"
        break;
      case "5":
        return "green"
        break;

      default:
        break;
    }
  }

  moisCra = ["Jan","Fev","Mars","Avr","Mai","Juin","Juil","Aou","Sept","Oct","Nov","Dec"]


  // Gestion favoris
  addToFavoris(){
    this.cs.addToFavoris(this.userid, this.id).subscribe(
      data => {
        this.favoris.push(this.id)
        this.Alert("CV ajouté à vos favoris")
      },
      error => console.log(error)
    )
  }

  removeFromFavoris(){
    this.cs.removeFromFavoris(this.userid, this.id).subscribe(
      data => {
        const index = this.favoris.indexOf(this.id);
        this.favoris.splice(index, 1)
        this.Alert("CV retiré de vos favoris")
      },
      error => console.log(error)
    )
  }

  getrhFavoris(){
    this.cs.getUserFavoris(this.userid).subscribe(
      data => {
        this.favoris = [...data]
      },
      error => console.log(error)
    )
  }

  //Vérifier que le linkedin est bon avant d'afficher l'icône
  linkedinTest(value){
    var inValid = /(^(http(s)?)|^(www))[a-zA-Z0-9.?§:!^$&/]+/;
    return inValid.test(value);
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  TextAreaVal(item: Technologie,event) {
    this.TechnoPageUpdate[0] = event.target.value;
  }
  Test() {

  }
      /* fonction get page tagtech*/

       getTagTechPage(request) {
        this.loading = true;
        this.SpinnerService.show();
        this.tt.Tags(request)
          .subscribe(data => {
           if(data){
            this.tagTechPage = data['content'];
            this.TagTechTabFilter = this.tagTechPage;
            this.totalElements = data['totalElements'];
            this.totalPage = Math.ceil((this.totalElements/this.size));
            this.loading = false;
            this.SpinnerService.hide();
            this.dialog.closeAll()


            if (this.TagTechPageUpdate.length !== 0) {

              this.TagTechPageUpdate.forEach(
              (action) =>
                (this.TagTechTabFilter = this.TagTechTabFilter.filter((u) => u.libelle !== action.libelle))
            );

        }
           }
          }, error => {
            this.loading = false;
          }
          );
      }

      ChangerTagsPageSize() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.size;
        request["regex"] = this.regex;

        this.getTagTechPage(request);
        this.suivant = 0;
      }
      PremiereTagsPage() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.size
        request["regex"] = this.regex;
        this.getTagTechPage(request);
        this.suivant = 0;
      }

      FinalTagsPage() {
        const request = {};
        this.suivant = this.totalPage - 1;
        request["page"] = this.totalPage - 1;
        request["size"] = this.size;
        request["regex"] = this.regex;
        this.getTagTechPage(request);
        //console.log(this.totalElements / this.size);
      }

      nextTagsPage() {
        const request = {};
        request["page"] = ++this.suivant;
        request["size"] = this.size;
        request["regex"] = this.regex;
        this.getTagTechPage(request);

      }
      PreviousTagsPage() {
        const request = {};
        request["page"] = --this.suivant;
        request["size"] = this.size;
        request["regex"] = this.regex;
        this.getTagTechPage(request);
      }
      ChangeTagTech(tg: Tagtech, isChecked: boolean) {
        if (!isChecked) {
          let index = this.TagTechPageUpdate.findIndex(
            (x) => x === tg
          );
          if (index != -1) this.TagTechPageUpdate.splice(index,1);
          this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
    }
  }
  AddTagsToUpdate(tgt: Tagtech, isChecked: boolean) {
  this.updated=true;

    if (isChecked) {
      this.TagTechFilter.push(tgt);
        this.TagTechPageUpdate.push(tgt);
        this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
        //console.log(this.TagTechPageUpdate);
      }
   else {
    let index = this.TagTechPageUpdate.findIndex(
      (x) => x === tgt
    );
    if (index != -1) this.TagTechPageUpdate.splice(index,1);
   }
   //this.isChecked = false;
  }
  AddTagTech() {

    if(this.tagTechUpdate.libelle === '')
      this.OpenPopupAlertMode("Merci de saisir une nouvelle action");
    else {
      this.TagTechPageUpdate.push(this.tagTechUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.tagTechUpdate = new Tagtech();
    }
  }

    ////////////////////////////////////////////  /* Fin Pagination*/


       /* fonction get page tagmet*/

       getMetierPage(request) {
        this.loadingMetier = true;
        this.SpinnerService.show();
        this.tm.mets(request)
          .subscribe(data => {

           if(data){
            this.metierPage = data['content'];

            this.MetierTabFilter = this.metierPage;
            this.totalElementsMetier = data['totalElements'];
            this.totalPageMetier = Math.ceil((this.totalElementsMetier/this.sizeMetier));
            this.loadingMetier = false;
            this.SpinnerService.hide();
            this.dialog.closeAll()
            if (this.MetierPageUpdate.length !== 0) {
              this.MetierPageUpdate.forEach(
              (action) =>
                (this.MetierTabFilter = this.MetierTabFilter.filter((u) => u.libelle !== action.libelle))
            );

        }
           }
          }, error => {
            this.loadingMetier = false;
          }
          );
      }

      ChangerMetierPageSize() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.sizeMetier;
        request["regex"] = this.regexMetier;

        this.getMetierPage(request);
        this.suivantMetier = 0;
      }
      PremiereMetierPage() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.sizeMetier
        request["regex"] = this.regexMetier;
        this.getMetierPage(request);
        this.suivantMetier = 0;
      }

      FinalMetierPage() {
        const request = {};
        this.suivantMetier = this.totalPageMetier - 1;
        request["page"] = this.totalPageMetier - 1;
        request["size"] = this.sizeMetier;
        request["regex"] = this.regexMetier;
        this.getMetierPage(request);
        //console.log(this.totalElements / this.size);
      }

      nextMetierPage() {
        const request = {};
        request["page"] = ++this.suivantMetier;
        request["size"] = this.sizeMetier;
        request["regex"] = this.regexMetier;
        this.getMetierPage(request);

      }
      PreviousMetierPage() {
        const request = {};
        request["page"] = --this.suivantMetier;
        request["size"] = this.sizeMetier;
        request["regex"] = this.regexMetier;
        this.getMetierPage(request);
      }
      ChangeMetier(tg: Tagmet, isChecked: boolean) {
        if (!isChecked) {
          let index = this.MetierPageUpdate.findIndex(
            (x) => x === tg
          );
          if (index != -1) this.MetierPageUpdate.splice(index,1);
          this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });
    }
  }
  AddMetierToUpdate(tgt: Tagmet, isChecked: boolean) {
    this.updated=true;
    if (isChecked) {
      this.MetierFilter.push(tgt);
        this.MetierPageUpdate.push(tgt);
        this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });

      }
   else {
    let index = this.MetierPageUpdate.findIndex(
      (x) => x === tgt
    );
    if (index != -1) this.MetierPageUpdate.splice(index,1);
   }
   //this.isChecked = false;
  }
  AddMetier() {

    if(this.MetierUpdate.libelle === '')
      this.OpenPopupAlertMode("Merci de saisir un nouveau tag métier");
    else {
      this.MetierPageUpdate.push(this.MetierUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.MetierUpdate = new Tagmet();
    }
  }

    ////////////////////////////////////////////  /* Fin Pagination*/



         /* fonction get page FOnction*/

       getFonctionPage(request) {
        this.loadingFonction = true;
        this.SpinnerService.show();
        this.tf.foncs(request)
          .subscribe(data => {
           if(data){
            this.fonctionPage = data['content'];
            this.FonctionTabFilter = this.fonctionPage;
            this.totalElementsFonction = data['totalElements'];
            this.totalPageFonction = Math.ceil((this.totalElementsFonction/this.sizeFonction));
            this.loadingFonction = false;
            this.SpinnerService.hide();
            this.dialog.closeAll()
            if (this.FonctionPageUpdate.length !== 0) {
              this.FonctionPageUpdate.forEach(
              (action) =>
                (this.FonctionTabFilter = this.FonctionTabFilter.filter((u) => u.libelle !== action.libelle))
            );

        }
           }
          }, error => {
            this.loadingFonction = false;
          }
          );
      }

      ChangerFonctionPageSize() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.sizeFonction;
        request["regex"] = this.regexFonction;

        this.getFonctionPage(request);
        this.suivantFonction = 0;
      }
      PremiereFonctionPage() {
        const request = {};
        request["page"] = 0;
        request["size"] = this.sizeFonction
        request["regex"] = this.regexFonction;
        this.getFonctionPage(request);
        this.suivantFonction = 0;
      }

      FinalFonctionPage() {
        const request = {};
        this.suivantFonction = this.totalPageFonction - 1;
        request["page"] = this.totalPageFonction - 1;
        request["size"] = this.sizeFonction;
        request["regex"] = this.regexFonction;
        this.getFonctionPage(request);
        //console.log(this.totalElements / this.size);
      }

      nextFonctionPage() {
        const request = {};
        request["page"] = ++this.suivantFonction;
        request["size"] = this.sizeFonction;
        request["regex"] = this.regexFonction;
        this.getFonctionPage(request);

      }
      PreviousFonctionPage() {
        const request = {};
        request["page"] = --this.suivantFonction;
        request["size"] = this.sizeFonction;
        request["regex"] = this.regexFonction;
        this.getFonctionPage(request);
      }
      ChangeFonction(tg: Tagfonc, isChecked: boolean) {
        if (!isChecked) {
          let index = this.FonctionPageUpdate.findIndex(
            (x) => x === tg
          );
          if (index != -1) this.FonctionPageUpdate.splice(index,1);
          this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });
    }
  }
  AddFonctionToUpdate(tgt: Tagfonc, isChecked: boolean) {
    this.updated=true;
    if (isChecked) {
      this.FonctionFilter.push(tgt);
        this.FonctionPageUpdate.push(tgt);
        this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });
        //console.log(this.TagTechPageUpdate);
      }
   else {
    let index = this.FonctionPageUpdate.findIndex(
      (x) => x === tgt
    );
    if (index != -1) this.FonctionPageUpdate.splice(index,1);
   }
   //this.isChecked = false;
  }
  AddFonction() {

    if(this.FonctionUpdate.libelle === '')
      this.OpenPopupAlertMode("Merci de saisir un nouveau tag fonction");
    else {
      this.FonctionPageUpdate.push(this.FonctionUpdate);

      this.inputs.forEach((element) => {
        element.nativeElement.value = "";
      });

      this.FonctionUpdate = new Tagfonc();
    }
  }

    ////////////////////////////////////////////  /* Fin Pagination*/


    /* fonction get page Client*/

    getClientPage(request) {
      this.loadingClient = true;
      this.SpinnerService.show();
      this.cl.Clients(request)
        .subscribe(data => {
       if(data){

        this.clientPage = data['content'];
        this.ClientTabFilter = this.clientPage;
        this.totalElementsClient = data['totalElements'];
        this.totalPageClient = Math.ceil((this.totalElementsClient/this.sizeClient));
        this.loadingClient = false;
        this.SpinnerService.hide();
        this.dialog.closeAll()
        if (this.ClientPageUpdate.length !== 0) {
          this.ClientPageUpdate.forEach(
          (action) =>
            (this.ClientTabFilter = this.ClientTabFilter.filter((u) => u.libelle !== action.libelle))
        );

    }
       }
        }, error => {
          this.loadingClient = false;
        }
        );
    }

    ChangerClientPageSize() {
      const request = {};
      request["page"] = 0;
      request["size"] = this.sizeClient;
      request["regex"] = this.regexClient;

      this.getClientPage(request);
      this.suivantClient = 0;
    }
    PremiereClientPage() {
      const request = {};
      request["page"] = 0;
      request["size"] = this.sizeClient
      request["regex"] = this.regexClient;
      this.getClientPage(request);
      this.suivantClient = 0;
    }

    FinalClientPage() {
      const request = {};
      this.suivantClient = this.totalPageClient - 1;
      request["page"] = this.totalPageClient - 1;
      request["size"] = this.sizeClient;
      request["regex"] = this.regexClient;
      this.getClientPage(request);
      //console.log(this.totalElements / this.size);
    }

    nextClientPage() {
      const request = {};
      request["page"] = ++this.suivantClient;
      request["size"] = this.sizeClient;
      request["regex"] = this.regexClient;
      this.getClientPage(request);

    }
  PreviousClientPage() {
    const request = {};
    request["page"] = --this.suivantClient;
    request["size"] = this.sizeClient;
    request["regex"] = this.regexClient;
    this.getClientPage(request);
  }
  ChangeClient(clt: Client, isChecked: boolean) {
    if (!isChecked) {
      let index = this.ClientPageUpdate.findIndex(
        (x) => x === clt
      );
      if (index != -1) this.ClientPageUpdate.splice(index,1);
      this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
  }
  }
AddClientToUpdate(clt: Client, isChecked: boolean) {
  this.updated=true;
  if (isChecked) {
    this.ClientFilter.push(clt);
      this.ClientPageUpdate.push(clt);
      this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
      //console.log(this.TagTechPageUpdate);
    }
 else {
  let index = this.ClientPageUpdate.findIndex(
    (x) => x === clt
  );
  if (index != -1) this.ClientPageUpdate.splice(index,1);
 }
 //this.isChecked = false;
}
RemoveToUpdate(){
  this.updated=true;

  this.ClientPageUpdate.forEach((el)=>{
    this.ClientTabFilter = this.ClientTabFilter.filter((a)=> a.libelle!=el.libelle)

  })

}
AddClient() {

  if(this.clientUpdate.libelle === '')
    this.OpenPopupAlertMode("Merci de saisir un nouveau client");
  else {
    this.clientUpdate.libelle = this.clientUpdate.libelle.toUpperCase();
    //console.log(this.clientUpdate.libelle.toUpperCase);
    this.addClientTodatabase(this.clientUpdate.libelle)


    this.inputs.forEach((element) => {
      element.nativeElement.value = "";
    });

    this.clientUpdate = new Client();
  }
}

  ////////////////////////////////////////////  /* Fin Pagination*/

    /* Fonctions Techno Pagination */

    getTechnoPage(request) {
      this.loadingTechno = true;
      this.SpinnerService.show();
      this.ts.Technologies(request)
        .subscribe(data => {
          this.TechnoPage = data['content'];
          this.TechnoTabFilter = this.TechnoPage;
          this.totalElementsTechno = data['totalElements'];
          this.totalPageTechno = Math.ceil((this.totalElementsTechno/this.sizeTechno));
          this.loadingTechno = false;
          this.SpinnerService.hide();
          this.dialog.closeAll()
          if (this.TechnoPageUpdate.length !== 0) {
            this.TechnoPageUpdate.forEach(
            (action) =>
              (this.TechnoTabFilter = this.TechnoTabFilter.filter((u) => u.libelle !== action.libelle))
          );

      }
        }, error => {
          this.loadingTechno = false;
        }
        );
    }

    ChangerTechnosPageSize() {
      const request = {};
      request["page"] = 0;
      request["size"] = this.sizeTechno;
      request["regex"] = this.regexTechno;

      this.getTechnoPage(request);
      this.suivantTechno = 0;
    }
    PremiereTechnosPage() {
      const request = {};
      request["page"] = 0;
      request["size"] = this.sizeTechno
      request["regex"] = this.regexTechno;
      this.getTechnoPage(request);
      this.suivantTechno = 0;
    }

    FinalTechnoPage() {
      const request = {};
      this.suivantTechno = this.totalPageTechno - 1;
      request["page"] = this.totalPageTechno - 1;
      request["size"] = this.sizeTechno;
      request["regex"] = this.regexTechno;
      this.getTechnoPage(request);
      //console.log(this.totalElements / this.size);
    }

    nextTechnosPage() {
      const request = {};
      request["page"] = ++this.suivantTechno;
      request["size"] = this.sizeTechno;
      request["regex"] = this.regexTechno;
      this.getTechnoPage(request);

    }
    PreviousTechnoPage() {
      const request = {};
      request["page"] = --this.suivantTechno;
      request["size"] = this.sizeTechno;
      request["regex"] = this.regexTechno;
      this.getTechnoPage(request);
    }
    ChangeTechnoTech(tg: Technologie, isChecked: boolean) {
      if (!isChecked) {
        let index = this.TechnoPageUpdate.findIndex(
          (x) => x === tg
        );
        if (index != -1) this.TechnoPageUpdate.splice(index,1);
        this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno });
  }
}
AddTechnoToUpdate(tgt: Technologie, isChecked: boolean) {
  this.updated=true;
  if (isChecked) {
    this.TechnoFilter.push(tgt);
      this.TechnoPageUpdate.push(tgt);
      this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno});
      //console.log(this.TagTechPageUpdate);
    }
 else {
  let index = this.TechnoPageUpdate.findIndex(
    (x) => x === tgt
  );
  if (index != -1) this.TechnoPageUpdate.splice(index,1);
 }
 //this.isChecked = false;
}
AddTechno() {

  if(this.technoUpdate.libelle === '')
    this.OpenPopupAlertMode("Merci de saisir une nouvelle technologie");
  else {
    this.TechnoPageUpdate.push(this.technoUpdate);

    this.inputs.forEach((element) => {
      element.nativeElement.value = "";
    });

    this.technoUpdate = new Technologie();
  }
}

  ////////////////////////////////////////////  /* Fin Pagination*/

 /**
 * Récupérer la liste de toutes les Disponiblités
 * @param  {void}
 * @param  {void}
 * @return {void}
 */
  getDispo() {
    this.Dispo = [];
    this.ds.getRefs().subscribe((data2: {}) => {
// tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i]);
    }
    });
  }

   /**
 * Récupérer la liste de toutes les Qualités CV
 * @param  {void}
 * @param  {void}
 * @return {void}
 */
  getQualite() {
    this.Qualite = [];

    this.qs.getRefs().subscribe((data2: {}) => {
// tslint:disable-next-line: forin
      for (const i in data2) {

        this.Qualite.push(data2[i]);
    }

    });
  }

  //inutile
  getTech(){
    this.ts.getTechnologie().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.Tech.push(data2[i].libelle);
              }

          });
  }


   /**
 * Récupérer la liste de tous les Clients
 * @param  {void}
 * @param  {void}
 * @return {void}
 */
  getClients(){
    this.cl.getClients().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.Clients.push(data2[i].libelle);
              }


          });
  }

   /**
 * Récupérer la liste de tous les Tags
 * Métier,Tech,Fonction
 * @param  {void}
 * @param  {void}
 * @return {void}
 */
  getTags(){
    this.tt.getTagtech().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagTech.push(data2[i].libelle);
              }
          });

    this.tm.getTagmet().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagMetier.push(data2[i].libelle);
              }
          });
    this.tf.getTagfonc().subscribe((data2) => {
      // tslint:disable-next-line: forin
              for(const i in data2){
                this.TagFonc.push(data2[i].libelle);
              }
          });
  }

  cancelcv(){

    this.CancelModal()
    this.ngOnInit()

    }
 /**
 * Récupérer les information du consultant via son ID dans la base
 * @param  {String} id ID du consultant passé en paramètre
 * @param  {void}
 * @return {void}
 */
  getConsultantbyId(id) {
    this.obstab=[]
    this.registerForm.value.observation=""

    this.cs.findConsultantbyId(id).subscribe((data2: {}) => {
      if(data2){
        this.consultant = data2;


      this.csdata = data2

      if(!this.consultant.deleted){
        this.profileService.getProfileByConsultantid(this.id).subscribe(
          (data) => {
            this.profile = data;
          },
          (error) => {
            console.log(error);
            // this.dialog.closeAll();
          }
        );
      }
      // Debut récupération des données de facturation

      // Fin
      this.titree = this.consultant.titre;
      this.name = this.consultant.nom;
      this.linkedindata = this.consultant.linkedin
      //Ajout du Nom et du prénom du client dans l'onglet de la page
      this.titleService.setTitle(this.name.toUpperCase() + ' '
      + this.consultant.prenom[0].toUpperCase()+this.consultant.prenom.slice(1).toLowerCase()
      )
      this.ddm2 = this.consultant.dateDerniereMaj;
      this.cvv = this.consultant.cv;
      this.rp = this.consultant.rappels;
      this.consultant.prenom= this.consultant.prenom[0].toUpperCase()+this.consultant.prenom.slice(1).toLowerCase()
      this.conrappel=[]

      //Récupération des rappel ACTIF
      this.consultant.rappels ? this.consultant.rappels.forEach((item, index)=>{
        if(item.actif ==="OUI"){
          this.conrappel.push(item);
        }
      }) : this.consultant.rappels = [];
    //Rangement des rappels du plus récent au moins récent
      this.conrappel = this.conrappel.sort(function(a,b){
        if(new Date(a.date) > new Date(b.date)) return -1;
        if(new Date(a.date) < new Date(b.date)) return 1;
        return 0;
    })


  //Vider la liste des tags affin d'éviter les doublons
  //Lors de l'enrégistrement des données du consultant
    this.TagTechPageUpdate=[]
    this.FonctionPageUpdate= []
    this.MetierPageUpdate = []
    this.ClientPageUpdate = []
    this.TechnoPageUpdate =[]

  //Récupération des Tags des données du consultants afin de les afficher dans la colonne
  //des Tags déjà sélectionnés pour le Consultant
      this.consultant.tagtech.forEach((item)=>{
        this.TagTechPageUpdate.push(item);
      });
      this.consultant.tagfonc.forEach((item)=>{
        this.FonctionPageUpdate.push(item);
      });
      this.consultant.tagmetier.forEach((item)=>{
        this.MetierPageUpdate.push(item);
      });
      this.consultant.tech ? this.consultant.tech.forEach((item)=>{
        this.TechnoPageUpdate.push(item);
      }) : this.consultant.tech = [];
      this.consultant.client.forEach((item)=>{
        this.ClientPageUpdate.push(item);
      });

      this.getTagTechPage({ page: "0", size: "25", regex: this.regex });
      // this.getTechnoPage({ page: "0", size: "25", regex: this.regexTechno });
       this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
       this.getFonctionPage({ page: "0", size: "25", regex: this.regexFonction });
       this.getMetierPage({ page: "0", size: "25", regex: this.regexMetier });

      this.numrappel = this.conrappel.length;
      this.obstab=[]

      this.consultant.observation ? this.consultant.observation.forEach((item, index)=>{
          this.obstab.push(item);
      }) : this.consultant.observation = [];

      //Classement des Observations de la plus récente à la plus ancienne
      this.obstab = this.obstab.sort(function(a,b){
        if(new Date(a.dateObs) > new Date(b.dateObs)) return -1;
        if(new Date(a.dateObs) < new Date(b.dateObs)) return 1;
        return 0;
    })

    //Fermeture de la Popup
    this.dialog.closeAll()

      }else{
        this.openNothingAlert()
      }
    });
  }
  changeColor() {
    switch (this.consultant.qualitecv.libelle) {
      case "Jouable":
        return "green";
      case "Moyen":
        return "orange";
      case "Injouable":
        return 'red';
      case "Neutre":
        return 'grey';
    }
  }
  ShowRappel(){
    var doc1 = document.getElementById("shrp");
    var doc2 = document.getElementById("hdrp");
    var doc3 = document.getElementById("rp");
    if((doc1.style.display === "block")&&(doc2.style.display === "none")&&(doc3.style.display === "none")){
      doc1.style.display = "none";
      doc2.style.display = "block";
      doc3.style.display = "block";
    }
  }

  HideRappel(){
    var doc1 = document.getElementById("shrp");
    var doc2 = document.getElementById("hdrp");
    var doc3 = document.getElementById("rp");
    if((doc2.style.display === "block")&&(doc1.style.display === "none")&&(doc3.style.display === "block")){
      doc2.style.display = "none";
      doc1.style.display = "block";
      doc3.style.display = "none";
    }
  }

  getActionbyUser(user) {
    this.user = [];
    this.as.findActionsbyId(user).subscribe((data2: {}) => {
      this.user = data2;
      // tslint:disable-next-line: forin
      for (const i in data2) {
        ELEMENT_DATA.push({
          _id: data2[i]._id,
          action: data2[i].action,
          date: data2[i].date,
          trigramme: data2[i].trigramme
        });
    }
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);

    });
  }

  openAlertDialog() {
    this.registerForm.patchValue({
      observation:""
    })

   window.scrollTo(0,0)


    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: 'Enregistrement de la fiche Consultant a bien été effectué',
        buttonText: {
          cancel: 'OK'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll()

      this. getConsultantbyId(this.id)
    });

  }

  openNothingAlert() {

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: "Désolé ce Consultant n'existe plus",
        buttonText: {
          cancel: 'OK'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/home']);

    });

  }

  Annuler() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: 'Abandon des modifications',
        buttonText: {
          cancel: 'OK'
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/home']);
    });
  }
  fermer() {
    this.router.navigate(['/home']);
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  AfficheTech(){
    var doc = document.getElementById("Afftech");
    if(doc.style.display === "none"){
      doc.style.display = "block";
    }
    else{
      doc.style.display = "none";
    }
  }

  AfficheClient(){
    var doc = document.getElementById("Affclient");
    if(doc.style.display === "none"){
      doc.style.display = "block";
    }
    else{
      doc.style.display = "none";
    }
  }

  AfficheTag1(){
    var doc = document.getElementById("Afftag1");
    if(doc.style.display === "none"){
      doc.style.display = "block";
    }
    else{
      doc.style.display = "none";
    }
  }

  AfficheTag2(){
    var doc = document.getElementById("Afftag2");
    if(doc.style.display === "none"){
      doc.style.display = "block";
    }
    else{
      doc.style.display = "none";
    }
  }

  AfficheTag3(){
    var doc = document.getElementById("Afftag3");
    if(doc.style.display === "none"){
      doc.style.display = "block";
    }
    else{
      doc.style.display = "none";
    }
  }

  getTechno(t1){
    var check = false;
    let tech = new Technologie();
    this.Techno.forEach( (item, index) => {
      if(item === t1){
          check=true;
          this.Techno=arrayRemove(this.Techno, item);
      }
    });
    if(check===false){
      tech.libelle = t1;
      this.Techno.push(t1);
    };

  }

  getClient(t2){
    var check = false;
    let cl = new Client();
    this.Cli.forEach( (item, index) => {
      if(item === t2){
          check=true;
          this.Cli=arrayRemove(this.Cli, item);
      }
    });
    if(check===false){
      cl.libelle = t2;
      this.Cli.push(t2);
    };

  }
  getTagtech(t3){
    var check = false;
    let t1 = new Tagtech();
    this.Tag1.forEach( (item, index) => {
      if(item.libelle === t3){
          check=true;
          this.Tag1=arrayRemove(this.Tag1, item);
      }
    });
    if(check===false){
      t1.libelle = t3;
      this.Tag1.push(t1);
    };
  }

  getTagmet(t4){
    var check = false;
    let t2 = new Tagmet();
    this.Tag2.forEach( (item, index) => {
      if(item === t4){
          check=true;
          this.Tag2=arrayRemove(this.Tag2, item);
      }
    });
    if(check===false){
      t2.libelle = t4;
      this.Tag2.push(t4);
    };

  }
  getTagfonc(t5){
    var check = false;
    let t3 = new Tagfonc();
    this.Tag3.forEach( (item, index) => {
      if(item === t5){
          check=true;
          this.Tag3=arrayRemove(this.Tag3, item);
      }
    });
    if(check===false){
      t3.libelle = t5;
      this.Tag3.push(t5);
    };

  }

  checkTechno(){
    if(this.registerForm.value.tech2 != ""){
      var libelle= this.registerForm.value.tech2.toLowerCase();
      var type = "Technologie";
      this.ts.addTechno(libelle).subscribe(data =>{
        this.dcs.findDictiobyMot(libelle).subscribe(result=>{
          if(result===null){
            this.motsDic.mot=libelle;
            this.motsDic.typeMotDictionnaire=type;
            this.dcs.addDictio(this.motsDic).subscribe(data2=>{
                    this. getConsultantbyId(this.id)

            })
          }
          if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction")||(result.motsDictionnaire.TypeMotDictionnaire != "Technologie"))){
            this.newMot.mot = result.motsDictionnaire.mot;
            this.newMot.typeMotDictionnaire = type;
            this.dic.motsDictionnaire = this.newMot;
            this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
                    this. getConsultantbyId(this.id)

            })
          }
        });
      });
      let tech = new Technologie();
      tech.libelle = libelle;
      this.Techno.push(tech);
    }
  }

  checkClient(){
    if(this.registerForm.value.client2 != ""){
    var libelle= this.registerForm.value.client2.toLowerCase();
    var type = "Client";
    this.cl.addClient(libelle).subscribe(data =>{
      this.dcs.findDictiobyMot(libelle).subscribe(result=>{
        if(result===null){
          this.motsDic.mot=libelle;
          this.motsDic.typeMotDictionnaire=type;
          this.dcs.addDictio(this.motsDic).subscribe(data2=>{
                  this. getConsultantbyId(this.id)

          })
        }
        if((result != null)&&((result.motsDictionnaire.TypeMotDictionnaire != "Tag Technique")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Metier")||(result.motsDictionnaire.TypeMotDictionnaire != "Tag Fonction")||(result.motsDictionnaire.TypeMotDictionnaire != "Technologie")||(result.motsDictionnaire.TypeMotDictionnaire != "Client"))){
          this.newMot.mot = result.motsDictionnaire.mot;
          this.newMot.typeMotDictionnaire = type;
          this.dic.motsDictionnaire = this.newMot;
          this.dcs.updateDictio(result.id, this.dic).subscribe(res =>{
                  this. getConsultantbyId(this.id)

          })
        }
      });
    });
    let cl = new Client();
    cl.libelle = libelle;
    this.Cli.push(cl);
    }
  }

  Forceupdate(){
    this.forceupdate=true;
    this.update()
  }

  update(updaterappel=true) {

   window.scrollTo(0,0)
   this.Loader()
   this.consultantData2 = {
   titre: this.consultant.titre,

   cv: this.consultant.cv,

   observation: this.consultant.observation,
   tech: this.consultant.tech,
   client: this.consultant.client,
   //tagtech: this.Tag1,
   tagtech: this.consultant.tagtech,
   tagmetier: this.consultant.tagmetier,
   tagfonc: this.consultant.tagfonc,
   rappels: this.consultant.rappels,
   codedispo:{libelle: this.consultant.codedispo.libelle,ordre:this.consultant.codedispo.ordre},
        qualitecv:{
          libelle:this.consultant.qualitecv.libelle,
          ordre:this.consultant.qualitecv.ordre
        }
    };
    this.clientConsultantData2 = {

      client: this.consultant.client,

    };

   this.onChange(this.registerForm.value.quali);
   this.changeDispo(this.registerForm.value.dis);
   this.changeCollaborateur(this.registerForm.value.collaborateur)
    const user = this.tokenStorageService.getUser();

    if(this.Tag1.length === 0){
      this.consultant.tagtech.forEach((item, index)=>{
        let t1 = new Tagtech();
        t1.libelle = item.libelle;
        this.Tag1.push(t1);
      });
    }

    if(this.Tag2.length === 0){
      this.consultant.tagmetier.forEach((item, index)=>{
        let t2 = new Tagmet();
        t2.libelle = item.libelle;
        this.Tag2.push(t2);
      });
    }

    if(this.Tag3.length === 0){
      this.consultant.tagfonc.forEach((item, index)=>{
        let t3 = new Tagfonc();
        t3.libelle = item.libelle;
        this.Tag3.push(t3);
      });
    }

    if(this.Cli.length === 0){
      this.consultant.client.forEach((item, index)=>{
        let cl = new Client();
        cl.libelle = item.libelle;
        this.Cli.push(cl);
      });
    }

    if(this.Techno.length === 0){
      this.consultant.tech.forEach((item, index)=>{
        let tech = new Technologie();
        tech.libelle = item.libelle;
        this.Techno.push(tech);
      });
    }


    this.submitted = true;
    if (this.registerForm.invalid) {
  }
    if ( this.name !== this.registerForm.value.nom) {
      this.ddm = this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm');
    } else {
      this.ddm = this.ddm2;
    }

    var dateDda = new Date(this.registerForm.value.dda);
    var daysDiff =new Date().getFullYear() - dateDda.getFullYear();



    var experience="";

    if (daysDiff<=3){
      experience = '0-3 ans';
    }
    if ((daysDiff>=4)&&(daysDiff<=7)){
      experience = '4-7 ans';
    }
    if (daysDiff>7){
      experience = '>7 ans';
    }


    this.checkTechno();

    this.checkClient();



    if(this.registerForm.value.observation!==""){
      var datetoday = new Date();
      var hours = new Date().getHours();
      var Min = new Date().getMinutes();

      var obsdate = this.datePipe.transform(datetoday, 'yyyy-MM-dd');
      this.obstest.dateObs = obsdate.toString()+" "+hours.toString()+":"+Min.toString();
      this.obstest.libelleObs = this.registerForm.value.observation;
      this.obstest.trigrammeObs = user.trigramme;

      this.obstab.push(this.obstest);
    }

    if(this.rappid !== ""){
      for(const j in this.conrappel){
        if(this.conrappel[j].numrappel === this.rapp.numrappel){
          this.conrappel[j].actif = "NON";
        }
      }
      //this.updateRappel();
    }
    this.registerForm.patchValue({
      observation:""
    })
    if(this.selectedFiles!=undefined){
      this.consultantData = {
         nom: this.registerForm.value.nom.toUpperCase(),
        prenom: this.registerForm.value.prenom.toUpperCase(),
        titre: this.registerForm.value.titre,
        tjm: this.registerForm.value.tjm,
        source: this.registerForm.value.source,
        telephone_principal: this.registerForm.value.telp,
        email: this.registerForm.value.email,
        linkedin:  this.linkedindata,
        code_postal: this.registerForm.value.code,
        ville: this.registerForm.value.ville,
        cv: this.tabcv,
           dateDerniereMaj:this.consultant.dateDerniereMaj,

        dda:  this.registerForm.value.dda ? this.datePipe.transform(this.registerForm.value.dda, 'yyyy-MM-dd HH:mm') :"" ,
        exp: experience,
        observation: this.obstab,
        collaborateur: this.collaborateur,
        tech: this.TechnoPageUpdate,
        client: this.ClientPageUpdate,
        updated:1,
        tagtech: this.TagTechPageUpdate,
        tagmetier: this.MetierPageUpdate,
        tagfonc: this.FonctionPageUpdate,
        rappels: this.conrappel,
        codedispo:{libelle: this.dispolibelle,ordre:this.dispoordre},
        qualitecv:{
          libelle:this.registerForm.value.quali,
          ordre:this.ordre
        }

        //
         };
         this.consultantData3 = {
          titre: this.consultantData.titre,

          cv: this.consultantData.cv,

          observation: this.consultantData.observation,
          tech: this.consultantData.tech,
          client: this.consultantData.client,
          //tagtech: this.Tag1,
          tagtech: this.consultantData.tagtech,
          tagmetier: this.consultantData.tagmetier,
          tagfonc: this.consultantData.tagfonc,
          rappels: this.consultantData.rappels,
          codedispo:{libelle: this.dispolibelle,ordre:this.dispoordre},
          qualitecv:{
            libelle:this.registerForm.value.quali,
            ordre:this.ordre
          }

          //
           };
         if((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2==="")){

          if(
           this.forceupdate
            ){
              this.myDate = new Date();
              this.consultantData.dateDerniereMaj=this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm')
          }

          this.cs.updateConsultant(this.id, this.consultantData, this.trigramme).subscribe((result) => {
           if(result===1){
            this.upload();
            this.openAlertDialog();
           }
          });
        }
        if((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2!=="")){


          if(
            this.forceupdate
             ){
               this.myDate = new Date();
               this.consultantData.dateDerniereMaj=this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm')
           }
          this.cs.updateConsultant(this.id, this.consultantData, this.trigramme).subscribe((result) => {
          if(result===1){
              this.addRappel(user.trigramme);
            this.upload();
            this.openAlertDialog();
          }
          });
        }
        if(((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2!==""))||((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2===""))){
          this.OpenPopupAlertMode("Merci de vérifier la date ou l'objet du rappel")
        }

    }
    else{
    this.consultantData = {
      updated:1,
       nom: this.registerForm.value.nom.toUpperCase(),
        prenom: this.registerForm.value.prenom.toUpperCase(),
        titre: this.registerForm.value.titre,
        tjm: this.registerForm.value.tjm,
        source: this.registerForm.value.source,
        telephone_principal: this.registerForm.value.telp,
        email: this.registerForm.value.email,
        linkedin:  this.linkedindata,
        code_postal: this.registerForm.value.code,
        ville: this.registerForm.value.ville,
        cv: this.cvv,
           dateDerniereMaj:this.consultant.dateDerniereMaj,

        observation: this.obstab,
        collaborateur: this.collaborateur,
        dda:  this.registerForm.value.dda ? this.datePipe.transform(this.registerForm.value.dda, 'yyyy-MM-dd') :"" ,
        exp: experience,
        tech: this.TechnoPageUpdate,
        client: this.ClientPageUpdate,
        //tagtech: this.Tag1,
        tagtech: this.TagTechPageUpdate,
        tagmetier: this.MetierPageUpdate,
        tagfonc: this.FonctionPageUpdate,
        rappels: this.conrappel,
        codedispo:{libelle: this.dispolibelle,ordre:this.dispoordre},
        qualitecv:{
          libelle:this.registerForm.value.quali,
          ordre:this.ordre
        }
  };

  this.consultantData3 = {
    titre: this.consultantData.titre,

    cv: this.consultantData.cv,

    observation: this.consultantData.observation,
    tech: this.consultantData.tech,
    client: this.consultantData.client,
    //tagtech: this.Tag1,
    tagtech: this.consultantData.tagtech,
    tagmetier: this.consultantData.tagmetier,
    tagfonc: this.consultantData.tagfonc,
    rappels: this.consultantData.rappels,
    codedispo:{libelle: this.dispolibelle,ordre:this.dispoordre},
    qualitecv:{
      libelle:this.registerForm.value.quali,
      ordre:this.ordre
    }

    //
     };
  if((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2==="")){


    if(

      this.forceupdate
       ){
         this.myDate = new Date();
         this.consultantData.dateDerniereMaj=this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm')
     }
    this.cs.updateConsultant(this.id, this.consultantData, this.trigramme).subscribe((result) => {
      if(result===1){
        this.openAlertDialog();
      }
    }, (err) => {
      console.log(err);
    });
  }

  if((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2!=="")){


    if(
      this.forceupdate
       ){
         this.myDate = new Date();
         this.consultantData.dateDerniereMaj=this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm')
     }
    this.cs.updateConsultant(this.id, this.consultantData, this.trigramme).subscribe((result) => {

      if(result===1){
        this.addRappel(user.trigramme);
        this.openAlertDialog();
      }
    }, (err) => {
      console.log(err);
    });
  }
  if(((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2!==""))||((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2===""))){
    this.Alert("Merci de vérifier la date ou l'objet du rappel")
  }

    };

   if(<HTMLInputElement>document.getElementById("addresse") && <HTMLInputElement>document.getElementById("addresse2") && <HTMLInputElement>document.getElementById("obs")){
    (<HTMLInputElement>document.getElementById("addresse")).value="";
    (<HTMLInputElement>document.getElementById("addresse2")).value="";

   }
   (<HTMLInputElement>document.getElementById("obs")).value="";

   if(updaterappel){
    this.rs.updateallrapel(this.id).subscribe((data)=>{

    })
   }

 this.forceupdate=false;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.filename = this.date+"_"+this.selectedFiles.item(0).name;
    this.initcv.nomCV = this.filename;
    this.initcv.dateCV =  this.today.toString();
    this.initcv.cheminCV = "../upload-dir"
    this.tabcv.push(this.initcv);
  }
  CancelModal() {
    this.modalRef.hide();
  }
  upload() {

    this.uploadService.deleteFile(this.cvv[0].nomCV,this.id).subscribe(result =>{

    });

    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.uploadService.pushFileToStorage(this.currentFileUpload,this.date).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {

        this.rr.updateRef(this.filename).subscribe(result =>{

        });
      }

    });

    this.selectedFiles = undefined;
  }

  addRappel(param){
  this.updated=true;

    this.rappel.idC = this.id;
    this.rappel.nom = this.csdata.nom;
    this.rappel.prenom = this.csdata.prenom;


    this.rappel.observation = this.obstab[0];


    this.rappel.titre = this.csdata.titre;
    this.rappel.libelleRappel = this.registerForm.value.addresse;
    this.rappel.date = this.datePipe.transform(this.registerForm.value.addresse2, 'yyyy-MM-dd');
    this.rappel.actif = "OUI";
    this.rappel.trigramme = param;
    this.rappel.numrappel = this.numrappel;
    this.rappel.email = this.consultant.email
    this.rs.addRappel(this.rappel).subscribe((result) => {

    });

    this.registerForm.patchValue({
      addresse:"",
      addresse2:""
    })
  }


  ChangeAct(element,param){

this.Loader()
    if(element.actif==="OUI"){

      element.actif = "NON"
      this.rs.findSpRappels(this.id, param).subscribe(result =>{
       if(result){

         element.id = result.id;
        element.date = result.date;
        element.idC = result.idC;
        element.nom = result.nom;
        element.prenom = result.prenom;
        element.libelleRappel = result.libelleRappel;
        element.observation = result.observation;
        element.titre = result.titre;
        element.trigramme = result.trigramme;
        element.actif = 'NON';
        element.numrappel = result.numrappel;
        this.rs.updateRappelStatut(element.id).subscribe(data=>{
          if(data){
            this.dialog.closeAll()
            this.update()
          }
        })
       }
      });
    }
    else{
      element.actif = "OUI"
      this.rs.findSpRappels(this.id, param).subscribe(result =>{
        if(result){
          this.dialog.closeAll()
          element.id = result.id;
         element.date = result.date;
         element.idC = result.idC;
         element.nom = result.nom;
         element.prenom = result.prenom;
         element.libelleRappel = result.libelleRappel;
         element.observation = result.observation;
         element.titre = result.titre;
         element.trigramme = result.trigramme;
         element.actif = 'OUI';
         element.numrappel = result.numrappel;
        }
       });
    }

  }

  updateRappel(){
    this.rs.updateRappel(this.rappid,this.rapp).subscribe(result =>{
    });
  }

 showCheckboxes1() {
  this.getTech();
  const checkboxes1 = document.getElementById('checkboxes1');
  if (!this.expanded1) {
    checkboxes1.style.display = 'block';
    this.expanded1 = true;
  } else {
    checkboxes1.style.display = 'none';
    this.expanded1 = false;
  }
}

showCheckboxes2() {
  this.getClients();
  const checkboxes2 = document.getElementById('checkboxes2');
  if (!this.expanded2) {
    checkboxes2.style.display = 'block';
    this.expanded2 = true;
  } else {
    checkboxes2.style.display = 'none';
    this.expanded2 = false;
  }
}

showCheckboxes3() {
  this.getTags();
  const checkboxes3 = document.getElementById('checkboxes3');
  if (!this.expanded3) {
    checkboxes3.style.display = 'block';
    this.expanded3 = true;
  } else {
    checkboxes3.style.display = 'none';
    this.expanded3 = false;
  }
}

showCheckboxes4() {
  const checkboxes4 = document.getElementById('checkboxes4');
  if (!this.expanded4) {
    checkboxes4.style.display = 'block';
    this.expanded4 = true;
  } else {
    checkboxes4.style.display = 'none';
    this.expanded4 = false;
  }
}

showCheckboxes5() {
  const checkboxes5 = document.getElementById('checkboxes5');
  if (!this.expanded5) {
    checkboxes5.style.display = 'block';
    this.expanded5 = true;
  } else {
    checkboxes5.style.display = 'none';
    this.expanded5 = false;
  }
}

onChange(deviceValue) {
  if (deviceValue === 'Jouable') {
    document.getElementById("selectquali").style.color="green";
    this.ordre=1;
  }

  if (deviceValue === 'Moyen') {
    document.getElementById("selectquali").style.color="orange";
    this.ordre =3;
    }

  if (deviceValue === 'Injouable') {
    document.getElementById("selectquali").style.color="red";
    this.ordre =4;
    }

  if (deviceValue === 'Neutre') {
    document.getElementById("selectquali").style.color="grey";
    this.ordre =2;
      }
}

getColor(param){
  var doc = param;
  if (doc === 'Jouable') {
    document.getElementById("selectquali").style.color="green";
    }

    if (doc === 'Moyen') {
      document.getElementById("selectquali").style.color="orange";
      }

    if (doc === 'Injouable') {
      document.getElementById("selectquali").style.color="red";
      }

    if (doc === 'Neutre') {
      document.getElementById("selectquali").style.color="grey";
        }
}

Changecv(){
  var doc1 = document.getElementById("telcv");
  var doc2 = document.getElementById("chcv");
  if(doc1.style.display==="block"){
    doc1.style.display="none";
    doc2.style.display="block";
  }
  else{
    doc1.style.display="block";
    doc2.style.display="none";
  }
}

visualiser(fileURL){

  //this.OpenPopupAlertMode("Visualisation du CV non disponible pour cette version de demo");

  this.Loader()
  this.cs.verifyCV(fileURL).subscribe(data=>{
   if(data===1){
     this.dialog.closeAll()
     window.open( backEndpoint+'/downloadFile/'+fileURL, '_blank');
   }else{
    this.dialog.closeAll()
    this.OpenPopupAlertMode("Le CV est absent");
   }
  })
}

ChangeLinked(){
  var doc1 = document.getElementById("inputLinkedMD");
  var doc2 = document.getElementById("LinkedAcc");
  var doc3 = document.getElementById("link");
  var doc4 = document.getElementById("hide");
  if(doc1.style.display==="block"){
    doc1.style.display="none";

  }
  else{
    doc1.style.display="block";

  }
  if(doc2.style.display==="none"){

    doc2.style.display="block";

  }
  else{

    doc2.style.display="none";

  }
}

SupprimerConsultant() {
  this.Loader();
  this.cs.deleteConsultant(this.id).subscribe((data) => {

  });
  this.consultant.cv.forEach(element => {
    this.cs.deletefile(element.nomCV).subscribe((data) => {

    });
  });
  setTimeout(() => {
    this.router.navigate(['/home']);
  }, 40000);
}
voircv(cv){

 if(cv && cv.length!==0){
  window.open( backEndpoint+"/downloadFile/"+cv[0].nomCV)
 }
}
openAlertWarning(param) {
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message: "Attention : Champs "+param+" vide",
      buttonText: {
        cancel: 'Retour'
      }
    },
  });
  dialogRef.afterClosed().subscribe(result => {
          this. getConsultantbyId(this.id)

  });
}
addClientTodatabase(libelle){
  this.Loader()
  var type = "Client";
  if(libelle===""){
    this.openAlertWarning("Clients");
  }

  else{
    let clienttemp = this.clientUpdate;

    this.cl.findByLibelle(libelle).subscribe((data) => {

      if(data===null) {

        this.ClientPageUpdate.push(clienttemp);

            this.cl.addClient(libelle).subscribe(data =>{
              if(data===1){
                this.dialog.closeAll()
    this.getClientPage({ page: "0", size: "25", regex: this.regexClient });
    this.ClientTabFilter = this.ClientTabFilter.filter((a)=> a.libelle!=this.clientUpdate.libelle)


              }
        });
  }
  else {

    this.dialog.closeAll()
    this.OpenPopupAlertMode('client existe déjà, merci de le sélectionner dans la liste proposée');

  }
});
  }
}
openLinkekin(e){
  if(!e.includes("https") || !e.includes("http") ){
    e = "https://"+e
  }
  window.open(e,"_blank")
}
Loader(){
  this.dialog.open(ProgressSpinnerDialogComponent, {
    data: {

      message: `
       Chargement en Cours`

    },
    disableClose: true,
  });
}

updateObs(){

  if(this.updateobs.libelleObs!==""){
    this.obstab = this.obstab.filter((a)=> a.libelleObs!=this.old.libelleObs)
    this.obstest2.dateObs = this.old.dateObs
    this.obstest2.libelleObs = this.updateobs.libelleObs;
    this.obstest2.trigrammeObs = this.old.trigrammeObs;

    this.obstab.push(this.obstest2);

  }
  this.CancelModal()
  this.obstab = this.obstab.sort(function(a,b){
    if(new Date(a.dateObs) > new Date(b.dateObs)) return -1;
    if(new Date(a.dateObs) < new Date(b.dateObs)) return 1;
    return 0;
})
}
  updateRapels(){
  this.updated=true;

    if(this.updaterappeldata.libelleRappel!==""){
      this.Loader()
      this.conrappel = this.conrappel.filter((a)=> a.libelleRappel!=this.oldrappel.libelleRappel)

      this.oldrappel2.numrappel = this.oldrappel.numrappel
      this.oldrappel2.observation = this.oldrappel.observation
      this.oldrappel2.date=this.oldrappel.date
      this.oldrappel2.titre = this.oldrappel.titre
      this.oldrappel2.actif = "OUI"
      this.oldrappel2.nom = this.oldrappel.nom
      this.oldrappel2.prenom = this.oldrappel.prenom
      this.oldrappel2.libelleRappel = this.updaterappeldata.libelleRappel;
      this.oldrappel2.trigramme = this.oldrappel.trigramme;

      this.conrappel.push(this.oldrappel2);
      this.rs.updateRappeldata(
        this.updaterappeldata.libelleRappel,
        this.oldrappelcancel,this.id).subscribe((data)=>{
          if(data===1){
            this.dialog.closeAll()
          this.update(false)
          }
        })


}
this.CancelModal()
}
showUpdateObs(item, template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template);
  this.updateobs = item
  this.old = item
  this.oldobs = item.libelleObs
}
showUpdateRappels(item, template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template);
  this.updaterappeldata = item
  this.oldrappel = item
  this.oldrappelcancel = item.libelleRappel
}
cancelobs(){

this.CancelModal()
  this.updateobs.libelleObs = this.oldobs
}
cancelrappel(){

  this.CancelModal()
    this.updaterappeldata.libelleRappel = this.oldrappelcancel
  }
deleteobs(ob){
  this.obstab = this.obstab.filter((a)=> a.libelleObs!=ob.libelleObs)
}
deleterappel(ob){

  this.conrappel = this.conrappel.filter((a)=> a.libelleRappel!=ob.libelleRappel)
  this.rs.deleterappel(ob.libelleRappel,this.csdata.titre,ob.date,this.id,ob.numrappel).subscribe((data)=>{

  })
 this.update()


}
removeDouble(){
  let ids = this.obstab.map(o => o.libelleObs)
let filtered = this.obstab.filter(({libelleObs}, index) => !ids.includes(libelleObs, index + 1))
this.obstab = filtered
}
RenderTechno(e){
 if(e)
{
  return e.libelle
}
return "";
}
AjoutObservation(){
  this.updated=true;

  this.update()
}
AjouterRappel(){
  if(((this.registerForm.value.addresse==="")&&(this.registerForm.value.addresse2!==""))||((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2===""))){
    this.OpenPopupAlertMode("Merci de vérifier la date ou l'objet du rappel")
  }else{

    const user = this.tokenStorageService.getUser()
    if((this.registerForm.value.addresse!=="")&&(this.registerForm.value.addresse2!=="")){
      this.numrappel++;
      this.rptest.libelleRappel = this.registerForm.value.addresse;
      this.rptest.date = this.datePipe.transform(this.registerForm.value.addresse2, 'yyyy-MM-dd');
      this.rptest.actif = "OUI";
      this.rptest.trigramme = user.trigramme;
      this.rptest.numrappel = this.numrappel;

    this.conrappel.push(this.rptest);
    }

    if( (<HTMLInputElement>document.getElementById("rappeltext")) &&  (<HTMLInputElement>document.getElementById("dateinput"))){
      (<HTMLInputElement>document.getElementById("rappeltext")).value="";
      (<HTMLInputElement>document.getElementById("dateinput")).value="";
    }

    this.update()
  }


}

Truncate(text){
  if(text){
  return text.substr(0,125)
  }
}
TruncateObs(text){
 if(text){
  return text.substr(0,220)
 }
}
OpenPopup(text){
  const dialogRef = this.dialog.open(PopupdataComponent, {
    data: {
      message:text,
    },
  });
}

OpenPopupAlertMode(text){
  const dialogRef = this.dialog.open(AlertEmptyComponent, {
    data: {
      message:text,
    },
  });
}


changeDispo(e){

  this.Dispo.map((el)=>{

    if(el.libelle===e){
    this.dispoordre=el.ordre
    }else if(e===""){
      this.dispoordre=this.consultant.codedispo.ordre
    }
  })

  this.dispolibelle = e;

}
showLinkedin(template: TemplateRef<any>){
  this.modalRef = this.modalService.show(template);

}

showCV(template: TemplateRef<any>){
  this.modalRef = this.modalService.show(template);

}

changeLinkedin(){
  this.linkedindata = this.consultant.linkedin
  this.CancelModal()
}

changeCV(){
  this.linkedindata = this.consultant.linkedin

}

CamelCase(e){
  return e[0]+e.substr(1,e.length);
}
changeCollaborateur(e){
  this.collaborateur = e
}
}
