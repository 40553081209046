import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { backEndpoint } from '../config'

const endpoint =  backEndpoint+'/dictio/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DictioService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getDictio(): Observable<any> {
    return this.http.get(endpoint + 'get').pipe(
      map(this.extractData));
  }

  findDictiobyMot(mot): Observable<any> {
    return this.http.get<any>(endpoint + `findbymot/${mot}`, httpOptions);
  }

  addDictio(motsDic): Observable<any> {
    return this.http.post<any>(endpoint + 'add', JSON.stringify(motsDic), httpOptions);
  }

  updateDictio(id,dic): Observable<any>{
    return this.http.put(endpoint + 'update/' + id, JSON.stringify(dic), httpOptions);
  }
}
