<h2>{{data.type | uppercase }} </h2>
<mat-dialog-content>
    <ul>
        <li *ngIf="data.type=='ao'">
            <div class="file"> {{data.data.nomFichier}} </div>
        </li>

        <div *ngIf="data.type=='cvs'">
            <li *ngFor="let d of data.data">
                <div class="file"> {{d.prenomConsultant}} </div>
            </li>
        </div>


    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-dialog-close>Fermer</button>
</mat-dialog-actions>