import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { backEndpoint, CRM_BACK_ENDPOINT } from '../config';

const endpoint = backEndpoint+'/api/notification';
const crmEndpoint = CRM_BACK_ENDPOINT+'/api/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient){}

  getNotifications(trigramme: string): Observable<any>{
    return this.http.get(endpoint, {params: {"trigramme": trigramme}});
  }

  getNotificationsFromCRM(trigramme: string): Observable<any>{
    return this.http.get(crmEndpoint+"/get-rh-notifications", {params: {"trigramme": trigramme}})
  }

  updateNotification(data): Observable<any>{
    return this.http.put(endpoint+"/update", data);
  }

  updateCrmNotification(data): Observable<any>{
    return this.http.put(crmEndpoint+"/update", data);
  }
}
