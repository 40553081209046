import { EnterCodeComponent } from './enter-code/enter-code.component';
import { HomeConsultantComponent } from './consultants/home-consultant/home-consultant.component';
import { PageManagerComponent } from './page-manager/page-manager.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AjoutConsultantComponent } from './ajout-consultant/ajout-consultant.component';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material';
import { ListeComponent } from './liste/liste.component';
import { FicheConsultantComponent } from './fiche-consultant/fiche-consultant.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TagsComponent } from './tags/tags.component';
import { ListeDComponent } from './liste-d/liste-d.component';
import { RechtagsComponent } from './rechtags/rechtags.component';
import { RechresultComponent } from './rechresult/rechresult.component';
import { RappelsComponent } from './rappels/rappels.component';
import { UserComponent } from './user/user.component';
import { RechmcComponent } from './rechmc/rechmc.component';
import { RechmcrComponent } from './rechmcr/rechmcr.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import {HomeComponent} from "./home/home.component"
import { ConsultantbytagsComponent } from './consultantbytags/consultantbytags.component';
import { RechercheBesoinComponent } from './recherche-besoins/recherche-besoin.component';
import { PagebesoinComponent } from './pagebesoin/pagebesoin.component';
import { RechmcRhcrmComponent } from './rechmcrhcrm/rechmcrhcrm.component';
import { RechmcrRhCrmComponent } from './rechmcrrhcrm/rechmcrrhcrm.component';
import { CraConsultantComponent } from './consultants/cra-consultant/cra-consultant.component';
import { PageFacturationComponent } from './page-facturation/page-facturation.component';
import { ValiderFactureConsultantComponent } from './valider-facture-consultant/valider-facture-consultant.component';
import { ManagerGuard } from './services/guards/managerGuard.service';
import { AdministrativeGuard } from './services/guards/administrativeGuard.service';
import { ConsultantGuard } from './services/guards/consultantGuard.service';
import { CompetencesComponent } from './consultants/competences/competences.component';
import { ExperiencesConsultantComponent } from './consultants/experiences-consultant/experiences-consultant.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InfosConsultantComponent } from './consultants/infos-consultant/infos-consultant.component';
import { RegisterComponent } from './register/register.component';
import { OpportuniteConsultantComponent } from './consultants/opportunite-consultant/opportunite-consultant.component';
import { ValidateConsultantComponent } from './validate-consultant/validate-consultant.component';
import { ConfigsComponent } from './cartographie-configs/configs/configs.component';
import { CartographieComponent } from './consultants/cartographie/cartographie/cartographie.component';
import { TrainingPageComponent } from './consultants/cartographie/training-page/training-page.component';
import { TestPageComponent } from './consultants/cartographie/test-page/test-page.component';


const routes: Routes = [
  {path: 'ajoutc', component: AjoutConsultantComponent,canActivate:[AuthService]},
  {path: 'Liste', component: ListeComponent,canActivate:[AuthService]},
  {path: 'consultantsbytags', component: ConsultantbytagsComponent,canActivate:[AuthService]},
  {path: 'fichec', component: FicheConsultantComponent,canActivate:[AuthService]},
  {path: 'dashboard', component: DashboardComponent,canActivate:[AuthService]},
  {path: 'tags', component: TagsComponent,canActivate:[AuthService]},
  { path: "RechercheBesoin",  canActivate: [AuthService], component: RechercheBesoinComponent },
  { path: "RechercheBesoin/:besoin",  canActivate: [AuthService], component: RechercheBesoinComponent },
  {path: 'page-besoin',  canActivate: [AuthService], component: PagebesoinComponent},

  {path: "listeD", component: ListeDComponent,canActivate:[AuthService]},
  {path: "config-competences", component: ConfigsComponent,canActivate:[AuthService]},
  {path: "rechtags", component: RechtagsComponent,canActivate:[AuthService]},
  {path: "rechresult", component: RechresultComponent,canActivate:[AuthService]},
  {path: "rechmc", component: RechmcComponent,canActivate:[AuthService]},
  {path: "rechmcrhcrm", component: RechmcRhcrmComponent,canActivate:[AuthService]},
  {path: "rappels", component: RappelsComponent,canActivate:[AuthService]},
  {path: "users", component: UserComponent,canActivate:[AuthService]},
  {path: "rechmcr", component: RechmcrComponent,canActivate:[AuthService]},
  {path: "rechmcrrhcrm", component: RechmcrRhCrmComponent,canActivate:[AuthService]},
  {path: "consultant", component: HomeConsultantComponent,canActivate:[AuthService, ConsultantGuard], children: [
    {path: "", redirectTo: "cra", pathMatch: "full"},
    {path: "cra", component: CraConsultantComponent},
    {path: "experiences", component: ExperiencesConsultantComponent},
    {path: "competences", component: CompetencesComponent},
    {path: "informations", component: InfosConsultantComponent},
    {path: "notif-opportunite", component: OpportuniteConsultantComponent},
    {path: "cartographie", component: CartographieComponent},
    {path:"training-page/:category/:name", component: TrainingPageComponent},
    {path:"test-page/:category/:name", component: TestPageComponent}
  ]},
  {path: "login", component: LoginComponent},
  {path: "register", component: RegisterComponent},
  {path: "request-password", component: RequestPasswordComponent},
  {path:'reset-password', component: ResetPasswordComponent},
  {path:'enter-code', component: EnterCodeComponent},
  {path: "page-manager", component: PageManagerComponent,  canActivate:[AuthService, ManagerGuard]},
  {path: "page-facturation", component: PageFacturationComponent,canActivate:[AuthService, AdministrativeGuard]},
  {path: 'valider-profil/:id', component: ValidateConsultantComponent},
  //{path: "valider-facture", component: ValiderFactureConsultantComponent, canActivate:[AuthService]},
  {path: "**", component: HomeComponent,canActivate:[AuthService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true}), MatFormFieldModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
