import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { backEndpoint } from '../config';

const endpoint =  backEndpoint+'/refrecherche/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RefRechercheService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  updateRef(filename): Observable<any> {
    return this.http.post<any>(endpoint + `update/${filename}`, httpOptions);
  }

  addRef(filename): Observable<any> {
    return this.http.post<any>(endpoint + `add/${filename}`, httpOptions);
  }
  getConsultants(input): Observable<any> {
    let params = new HttpParams();
    params = params.append('input',input);
    return this.http.get<any>(endpoint + `getConsultants/`,{params});
  }
  

}
