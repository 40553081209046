<div class="container-fluid mb-5">

    <div class="row">

        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="text-center mb-1 title">Qualité CVs</div>
                    <div class="row d-flex justify-content-around">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeQualiteCv()" [(ngModel)]="periodeQualiteCv" name="periodeQualiteCv">
              <option value="semaine" selected>1 Semaine</option>
              <option value="mois">1 Mois</option>
              <option value="semestre">1 Semestre</option>
              <option value="annee">1 Année</option>
            </select>
                        </div>

                        <div class="row" *ngIf="isAdmin">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigQualiteCv()" placeholder="" style="width: 100px;" [(ngModel)]="trigQualiteCv">
                            </ng-select>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center" #qualiteCvContainer>
                        <canvas id="cvChart" width="350" height="350" #qualiteCv></canvas>
                    </div>

                    <div class="d-flex justify-content-end">
                        <div style="margin-right: 20px;">
                            <div>Secteur</div>
                            <ng-select [items]="tagmets" bindLabel="tagmets" #selectTrigramme (change)="setSecteurQualiteCv()" placeholder="" style="width: 100px;" [(ngModel)]="secteurQualiteCv">
                            </ng-select>
                        </div>
                        <div style="margin-right: 20px;">
                            <div>Fonction</div>
                            <ng-select [items]="tagfoncs" bindLabel="tagfoncs" #selectTagTech (change)="setFonctionQualiteCv()" placeholder="" style="width: 100px;" [(ngModel)]="fonctionQualiteCv">
                            </ng-select>
                        </div>
                        <div>
                            <div>Technologie</div>
                            <ng-select [items]="tagtechs" bindLabel="tagtechs" #selectTagTech (change)="setTechnoQualiteCv()" placeholder="" style="width: 100px;" [(ngModel)]="technoQualiteCv">
                            </ng-select>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="text-center mb-1 title">Top 5 des tags (par type)</div>
                    <div class="row d-flex justify-content-around">
                        <div>
                            Période
                            <select class="form-select" aria-label="Default select example" (change)="setPeriodeTop5Tag()" [(ngModel)]="periodeTop5Tag" name="periodeTop5Tag">
              <option value="semaine" selected>1 Semaine</option>
              <option value="mois">1 Mois</option>
              <option value="semestre">1 Semestre</option>
              <option value="annee">1 Année</option>
            </select>
                        </div>

                        <div class="row" *ngIf="isAdmin">
                            <div class="mr-2">Trigramme</div>
                            <ng-select [clearable]="false" [items]="trigrammes" bindLabel="trigrammes" #selectTrigramme (change)="setTrigTop5Tag()" placeholder="" style="width: 100px;" [(ngModel)]="trigTop5Tag">
                            </ng-select>
                        </div>
                    </div>

                    <div id='top5'></div>

                </div>
            </div>
        </div>



    </div>
</div>