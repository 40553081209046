import { Component, OnInit } from '@angular/core';
import { TagtechService } from '../services/tagtech.service';
import { TagmetService } from '../services/tagmet.service';
import { TagfoncService } from '../services/tagfonc.service';
import { CommunicationService } from '../services/communication.service';
import { Tagtech } from '../Model/tagtech';
import { DisponibiliteService } from '../services/disponibilite.service';
import { ConsultantService } from '../services/consultant.service';
import { Router } from '@angular/router';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { MatDialog } from '@angular/material';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { TokenStorageService } from '../services/token-storage.service';
import {RechercheService}  from "../services/recherche.service";
import {Title} from "@angular/platform-browser";
import { ActionsService } from '../services/actions.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DashboardService } from '../dashboard/services/dashboard.service';


enum checkBoxStateTop {TTZ,ZIDF,ZHIDF,ZIDFR,ZIDFNR,NONE}
enum checkBoxStateBottom  {ONE,TWO,THREE,FOUR,FIVE,SIX,SEVEN,EIGHT,NONE}


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
function arrayRemove(arr, value) {
  return arr.filter(
    function(ele){
      return ele != value;
    })
  ;
}

@Component({
  selector: 'app-rechtags',
  templateUrl: './rechtags.component.html',
  styleUrls: ['./rechtags.component.scss']
})
export class RechtagsComponent implements OnInit {
  check_box_type = checkBoxStateTop;
  currentlyChecked: checkBoxStateTop;
  check_box_type2 = checkBoxStateBottom;
  currentlyChecked2: checkBoxStateBottom;

  TagTechnique : Tagtech[];
  TagMetier = [];
  windowRef=[]
  TagFonction = [];

  Dispo = [];

  consultants: any =[];

  tagtech: any [] = [];
  tagmet:any [] = [];
  tagfonc: any [] = [];
  quali: any[] = [];
  dispo: any[] = [];
  expert: any[] = [];
  zone: String[] = [];
  code: any[] = ['75','77','78','91','92','93','94','95','vide']
  check = true;
  checkk = false;
  codes: any = [];
  codes2: any = [];
  testquali : any = [];
  testzone : any = [];
  testexp : any = [];
  dispos=[]

  user: any;

  constructor(
    private actionservice:ActionsService,
    private tt: TagtechService,
    private tm: TagmetService,
    private tf: TagfoncService,
    private ds: DisponibiliteService,
    private cr: ConsultantService,
    private rs:RechercheService,
    private router: Router,

    private titleService:Title,
    public communicationService: CommunicationService,
    private tokenStorageService: TokenStorageService,
    private dialog: MatDialog,
    // Arold Donang Cam
    private dservice: DashboardService

    ) {
      this.communicationService.Broadcast('OnChild7', 'Recherche par Tags');
     }

  ngOnInit() {
    this.loader()
    window.scroll(0,0);
    this.getTags();
    this.titleService.setTitle("Portail RH")
    this.getDispo();
    this.getexp()
    this.currentlyChecked = this.check_box_type.ZIDFR
    this.user = this.tokenStorageService.getUser();
  }
  getexp(){
    this.rs.getexperience().subscribe((data)=>{

      this.dispos=data
      this.dialog.closeAll()
    })
  }
  getTags(){
    this.tt.getTagtech().subscribe((data2) => {
        this.TagTechnique = data2;
      }
    );
    this.tm.getTagmet().subscribe((data2) => {
        this.TagMetier=data2;
      this.dialog.closeAll()

          });
    this.tf.getTagfonc().subscribe((data2) => {
        this.TagFonction=data2;
      this.dialog.closeAll()

    });
  }

  getDispo() {
    this.ds.getRefs().subscribe((data2: {}) => {
      console.log(data2);
      this.dialog.closeAll()

// tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push(data2[i].libelle);
    }
    });
  }
  getname(e){

    return e
  }
  RechTagTech(t1:String , isChecked: boolean){
    console.log(t1)
    if (isChecked) {
        this.tagtech.push(t1);
      }
   else {
    let index = this.tagtech.findIndex(
      (x) => x === t1
    );
    if (index != -1) this.tagtech.splice(index,1);
   }
   console.log(this.tagtech);
  }

  RechTagMet(t1:String , isChecked: boolean){
    if (isChecked) {
      this.tagmet.push(t1);
    }
 else {
  let index = this.tagmet.findIndex(
    (x) => x === t1
  );
  if (index != -1) this.tagmet.splice(index,1);
 }

  }

  RechTagFonc(t1:String , isChecked: boolean){
    if (isChecked) {
      this.tagfonc.push(t1);
    }
 else {
  let index = this.tagfonc.findIndex(
    (x) => x === t1
  );
  if (index != -1) this.tagfonc.splice(index,1);
 }
  }



  openAlert(param) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: 'Attention : '+param,
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  RechQualite(t1:String , isChecked: boolean){
    if (isChecked) {
        this.quali.push(t1);
      }
   else {
    let index = this.quali.findIndex(
      (x) => x === t1
    );
    if (index != -1) this.quali.splice(index,1);
   }

  }

  RechDispo(t1:any , isChecked: boolean){
    if (isChecked) {
      console.log(t1);
        this.dispo.push(this.getname(t1));
      }
   else {
    let index = this.dispo.findIndex(
      (x) => x === this.getname(t1)
    );
    if (index != -1) this.dispo.splice(index,1);
   }

  }

  RechExp(t1:String , isChecked: boolean){
    if (isChecked) {
        this.expert.push(t1);
      }
   else {
    let index = this.expert.findIndex(
      (x) => x === t1
    );
    if (index != -1) this.expert.splice(index,1);
   }

  }
  selectCheckBox(targetType: checkBoxStateTop,num,name) {
    this.codes = []
    this.zone=[]
    if(this.codes){
      this.codes.slice(0,this.codes.length)
    }
    this.codes2.push(num)
    this.zone.push(name)
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = checkBoxStateTop.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }
  RechCodePostal(targetType, isChecked: boolean) {
    this.codes2 = []

    this.currentlyChecked = checkBoxStateTop.NONE;
    if (isChecked) {
      this.codes.push(targetType)
    }
    else {
      let index = this.codes.findIndex(
        (x) => x === targetType
      );
      if (index != -1) this.codes.splice(index,1);
     }

  }




  lancer(){
    this.zone = this.codes.concat(this.zone)
    this.codes = this.codes.concat(this.codes2)

    console.log(this.zone)
    if(this.codes.length===0){
      this.codes.push(3)
    }
    let codegeo = this.codes;
    console.log(codegeo);
    const user = this.tokenStorageService.getUser()

    if((this.tagtech.length!==0)||(this.tagmet.length!==0)||(this.tagfonc.length!==0)){
      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
})();
    }

    else{
      document.getElementById("warning").style.display="block";


    }




    let temp = []
    this.dialog.closeAll()
    this.codes.forEach(function(item, i) {
    if (item === 1) {temp.push("Toutes les zones"); }
    if (item === 2) {temp.push("Zone IDF"); }
    if (item === 3){ temp.push("Zone IDF ou non renseigné"); }
    if (item === 4) {temp.push("Zone hors IDF");}
    if (item === 5) {temp.push("Zone hors IDF ou non renseigné"); }
    if (item === 0){ temp.push("Non renseigné"); }
    if (item === 96) {temp.push("Non renseigné");}
    else{
    temp.push(item)
    }
    })
    if(temp[temp.length-1]===1 || temp[temp.length-1]===2 || temp[temp.length-1]===3 || temp[temp.length-1]===0  ){
      temp.pop()

    }


    this.router.navigate(['/rechresult'], { queryParams: { codegeo:codegeo,tagtech: this.tagtech,tagmet: this.tagmet,tagfonc: this.tagfonc, qualite: this.quali, dispo: this.dispo, experience: this.expert, zone: this.zone,zonegeo:temp}})

    // Arold Donang  enregistrer la recherche par tag ici
    this.dservice.saveTagSearch(user.trigramme).subscribe(
      data => console.log(data)
    );
    // Arold
  }

  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en cours`

      },
      disableClose: true,
    });
  }
}
