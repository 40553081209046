<div id="app">

    <div class="container-fluid" *ngIf="!authService.getauth() || roles.includes('Consultant')">
        <div style="min-height: 98vh;">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>



    <div class="page-wrapper" [ngClass]="{'toggled' : getSideBarState()}" *ngIf="authService.getauth() && !roles.includes('Consultant')">

        <div class="sticky-header">
            <nav *ngIf="authService.getauth()" class="navbar navbar-expand navbar-dark bg-white px-2" style="position: relative;padding: 0;margin: 0;">
                <!-- <a class="navbar-brand" href="#" style="color: green; font-weight: bold;font-size: 16px;padding: 0;"><img src="../assets/RH.png" alt=""></a> -->
                <!-- <a class="navbar-brand" href="#" style="color: green; font-weight: bold;font-size: 16px;padding: 0;"><img src="../assets/itns1.jpg" alt=""></a> -->
                <p style="display: flex;align-items: center; color: black; font-weight: bold;margin: 0; font-size: 16px;"><img height="25px" style="margin-left: 10px;" src="../assets/RH.png" alt=""></p>
                <span style="font-size:large;font-weight: bold; width: 100%;margin: 0;text-align: center;padding: 0;"><font style="color:black" *ngIf="bool"> </font><font style="color:red;font-weight: bold;font-size: 20px;">{{title}}</font></span>

                <ul class="navbar-nav ml-auto">
                    <div class="mr-3">
                        <a role="button" type="button" [ngbPopover]="popContent" [popoverTitle]="popTitle" placement="bottom">
                            <div class="d-flex">
                                <div class="position-relative me-3">
                                    <i class="bi bi-bell-fill" style="font-size: 18px !important;"></i>
                                    <span *ngIf="notifications.length>0" class="position-absolute top-0 mt-1 start-100 translate-middle badge rounded-pill bg-danger" style="font-size: 9px;">
                                    {{notifications.length}}
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>

                    <form style="text-align: center; display: flex;justify-content: center;align-items: center;width: 120px;" class="form-inline">
                        <img src="./assets/user.png" width="20px">&nbsp;&nbsp;&nbsp;&nbsp;
                        <a class="navbar-brand" href="#" style="color: black;font-size: 16px;padding: 0; ">{{trigramme}}</a>

                        <button (click)="logout()" style="background-color: transparent; border: none;">
                      <img width="20px" src="../assets/deconnexion.png" alt="">
                    </button>

                    </form>
                </ul>
            </nav>
            <hr style="margin-top: 0;border: 1px solid black;">
            <app-nav></app-nav>
        </div>

        <main class="page-content" style="padding-top: 200px;">
            <div class="container-fluid">
                <div style="min-height: 98vh;">
                    <router-outlet></router-outlet>
                </div>
                <app-footer></app-footer>
            </div>
            <div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : !getSideBarState()}"></div>
        </main>

    </div>
</div>

<ng-template #popTitle>
    <div class="notif-title">Vos notifications</div>
</ng-template>
<ng-template #popContent>
    <div class="notification-content">
        <div class="notification mt-0" *ngFor="let notif of notifications; let i = index">
            <div *ngIf="notif.type=='ATTRIBUTION_BESOIN'">
                <div class="text-center text-warning font-weight-bold mb-2">Besoin à gérer</div>
                <div class="row">
                    <div class="col-5">Besoin :</div>
                    <div class="col-7 font-weight-bold"><i class="bi bi-person-video3 action-icon" (click)="openBesoin(notif)"></i></div>
                </div>
                <div class="row">
                    <div class="col-6">Description :</div>
                    <div class="col-6 font-weight-bold">{{notif.description}}</div>
                </div>
                <div class="row">
                    <div class="col-5">CRM :</div>
                    <div class="col-7 font-weight-bold">{{notif.trigcrm}}</div>
                </div>
                <div class="row">
                    <div class="col-5">Date :</div>
                    <div class="col-7 font-weight-bold">{{notif.date}}</div>
                </div>
                <div class="text-center mt-1">
                    <span class="font-weight-bold mr-2">Supprimer notification</span>
                    <input type="checkbox" (click)="deleteCrmNotification(notif, $event)">
                </div>
            </div>
            <div *ngIf="notif.type=='NOUVEAU_CONSULTANT'">
                <div class="text-center text-success font-weight-bold mb-2">Consultant inscrit</div>
                <div class="row">
                    <div class="col-8">Fiche consultant :</div>
                    <div class="col-4 font-weight-bold"><i class="bi bi-person-fill action-icon" (click)="openFicheConsultant(notif)"></i></div>
                </div>
                <div class="row">
                    <div class="col-7">Nom et prénom:</div>
                    <div class="col-5 font-weight-bold">{{notif.nomconsultant}}</div>
                </div>
                <div class="row">
                    <div class="col-6">Parrainé par :</div>
                    <div class="col-6 font-weight-bold">{{notif.nomparrain}}</div>
                </div>
                <div class="row">
                    <div class="col-5">Date :</div>
                    <div class="col-7 font-weight-bold">{{notif.date}}</div>
                </div>
                <div class="text-center mt-1">
                    <span class="font-weight-bold mr-2">Supprimer notification</span>
                    <input type="checkbox" (click)="deleteNotification(notif, $event)">
                </div>
            </div>
            <div *ngIf="notif.type=='MAJ_CONSULTANT'">
                <div class="text-center text-secondary font-weight-bold mb-2">Mise à jour CV</div>
                <div class="row">
                    <div class="col-8">Fiche consultant :</div>
                    <div class="col-3 font-weight-bold"><i class="bi bi-person-fill action-icon" (click)="openFicheConsultant(notif)"></i></div>
                </div>
                <div class="row">
                    <div class="col-7">Nom et prénom:</div>
                    <div class="col-5 font-weight-bold">{{notif.nomconsultant}}</div>
                </div>
                <div class="row">
                    <div class="col-5">Date :</div>
                    <div class="col-7 font-weight-bold">{{notif.date}}</div>
                </div>
                <div class="text-center mt-1">
                    <span class="font-weight-bold mr-2">Supprimer notification</span>
                    <input type="checkbox" (click)="deleteNotification(notif, $event)">
                </div>
            </div>
        </div>
    </div>
</ng-template>
