import { Component, OnInit, ViewChildren } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { FormControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ClientServiceService } from "../services/client-service.service";
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material";
import { ProgressSpinnerDialogComponent } from "../progress-spinner-dialog/progress-spinner-dialog.component";


import { Title } from "@angular/platform-browser";
import { AlertEmptyComponent } from "../alert-empty/alert-empty.component";
import { BASE_URL,CRM_BACK_ENDPOINT } from "../config";
import { TokenStorageService } from "../services/token-storage.service";
import { DialogValidationService } from "../services/dialog-validation.service";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export interface Element {
  dateSaisie: string;
  description: string;
  trigGestionnaireRH: string;
  trigBesoin: string;
  idContact: string;
  nomcontact: string;
  prenomcontact: string;
  nomclient: string;
  idClient: string;
  idbesoin: string;
  nomFichierAO: string;
  nomConsultantCv: string;
  nomFichierCv: string;
  dateEnvoirCv: string;
  statut: string;
}
let ELEMENT_DATA: Element[] = [];
@Component({
  selector: "app-recherche-besoin",
  templateUrl: "./recherche-besoin.component.html",
  styleUrls: ["./recherche-besoin.component.scss"],
})
export class RechercheBesoinComponent implements OnInit {
  @ViewChildren("paginator") paginator: MatPaginator;
  totalElementsConsultant: number = 0;
  message1: String = "";
  message: String = "Besoin-CVs";
  bool = false;
  loadingConsultant: boolean;
  suivantConsultant = 0;
  regex: string = "";
  sizeConsultant: number = 25;
  totalPage: number;
  selected: any;
  mod: boolean = true;
  besoins: any = [];
  consultqnts: any = [];
  currentpage2 = 0;
  pageSize2 = 25;
  title = "";
  orderasc = "null";
  orderdesc = "dateSaisie";
  orderasc2 = "dateSaisie";
  orderdesc2 = "trigrammnulle";
  pagination = 0;
  filtest: boolean = false;
  public pagesize = 25;
  public currentPage = 1;
  public totalSize = 0;
  ClientFilter: string = "";
  BesoinFilter: string = "";
  ContactFilter: string = "";
  date: string;
  trigramme: string = "";
  roles: string[] = [];

  //yves
  sortDirection = 1;
  sortBy = "dateSaisie";
  totalElt = 0;
  //yves

  constructor(
    private titleService: Title,
    private token: TokenStorageService,
    private dialog: MatDialog,
    private service: ClientServiceService,
    private router: ActivatedRoute,
    private route: Router,
    private datePipe: DatePipe,
    private dialogFileService:DialogValidationService
  ) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
       this.loadBesoin(this.router.snapshot.params.besoin)
      }

      if (event instanceof NavigationEnd) {
       this.loadBesoin(this.router.snapshot.params.besoin)

      }

      if (event instanceof NavigationError) {
      }
    });
  }
  displayedColumns: string[] = [
    "NomDuClient",
    "NomDuContact",
    "DateSaisieBesoin",
    "Prénom",
    "DescriptionBesoin",
    "Statut",
    "AO",
    "CVs",
    "DateEnvoiCv",
    "trigcrm",
    "trigrh",
  ];

  shownext = false;
  showprevious = false;
  showlastpage = false;
  showfirstpage = false;
  nameFilter = new FormControl("");
  nomcli = new FormControl("");
  status = new FormControl("");
  datesaisie = new FormControl("");
  nomcontact = new FormControl("");
  prenom = new FormControl("");
  prenomcontact = new FormControl("");
  description = new FormControl("");
  ao = new FormControl("");
  cv = new FormControl("");
  rh = new FormControl("");
  crm = new FormControl("");
  dateenvoi = new FormControl("");
  showfilter = false;
  pageSize = 120;

  nomdesc;
  nomclidesc;
  statusdesc;
  nomcontactdesc;
  prenomcontactdesc;
  statusaodesc;
  prenomdesc;
  cvdesc;
  aodesc;
  descriptiondesc;
  dateenvoidesc;
  rhdesc;
  datesaisiedesc;
  crmdesc;

  data = Object.assign(ELEMENT_DATA);
  dataSource: MatTableDataSource<Element> = new MatTableDataSource(this.data);

  pageSizeElement = [30, 86, 120];
  width;
  height;
  styleState = false;

  //Couleur
  isNomClientFilter = false;
  isNomContactFilter = false;
  isPrenomContactFilter = false;
  isDateSaisieFilter = false;
  isdescriptionFilter = false;
  isstatutFilter = false;
  isaoFilter = false;
  isCvsFilter = false;
  isCvFilter = false;
  isRhFilter = false;
  isCrmFilter = false;

  ngOnInit() {
    this.loader();

    const user = this.token.getUser();
    this.trigramme = user.trigramme;
    this.roles = user.roles;

    //Récupération de la largeur et la hauteur de l'écran
    let { width, height } = window.screen;
    this.width = width;
    this.height = height;

    //changement du nom d'élément par page ainsi que de la liste des éléments par page
    //en fonction de la taille de l'écran
    if (
      width >= 1366 * 0.9 &&
      width <= 1366 * 1.1 &&
      height >= 768 * 0.9 &&
      height <= 768 * 1.1
    ) {
      this.pageSizeElement = [25, 50, 100, 120];
      this.pageSize = 120;
    } else if (
      width >= 1920 * 0.9 &&
      width <= 1920 * 1.1 &&
      height >= 1080 * 0.9 &&
      height <= 1080 * 1.1
    ) {
      this.pageSizeElement = [30, 60, 120];
      this.pageSize = 120;
    }

    this.titleService.setTitle("Portail RH");
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);
    this.BesoinFilter = this.router.snapshot.params.besoin;
    if (this.BesoinFilter == undefined || this.BesoinFilter == null) {
      this.BesoinFilter = "";
    }

    this.Search2();
    // this.service.filterbesoin(this.BesoinFilter,this.token.getUsername()).subscribe((data) => {
    //   if(data){
    //     this.Search2();
    //   }
    // });
  }

  Select(row) {
    window.open(BASE_URL + "/page-besoin?besoin=" + row.id, "_blank");
  }

  getBesoin(data) {
    this.changeColor();
    this.besoins = data.allRechercheBesoin;

    this.totalSize = data.total;

    this.totalPage = Math.round(data.total / this.pageSize);
    this.dialog.closeAll();
    if (this.totalSize === 0) {
      this.Alert(
        "Il existe aucune Besoin avec la Description " + this.BesoinFilter
      );
    }
  }
  filter() {
    if (this.ClientFilter === "") {
      this.route.navigate(["/listeClients"]);
    }
    if (this.ClientFilter !== "") {
      this.route.navigate(["/ClientsContacts", this.ClientFilter]);
    }
  }

  RechercheRappel() {
    this.date = this.datePipe.transform(this.date, "yyyy-MM-dd");
    if (this.date === null) {
      this.route.navigate(["/rechercheRappel2"]);
    } else {
      this.route.navigate(["/rechercheRappel", this.date]);
    }
  }
  RechercheBesoin() {
    this.loader();
    this.service
      .filterbesoin(this.BesoinFilter, this.token.getUser().trigramme)
      .subscribe((data) => {
        if (data === 1) {
          this.Search2();
        }
      });
  }
  RechercheContact() {
    this.route.navigate(["/rechercheContact", this.ContactFilter]);
  }
  parametrage(param) {
    if (param === "Pushs/Secteurs") {
      this.route.navigate(["/parametrage1"]);
    } else if (param === "Statuts/Actions") {
      this.route.navigate(["/parametrage2"]);
    } else if (param === "Gestion Utilisateurs") {
      this.route.navigate(["/GestionUsers"]);
    }
  }
  onSortEvent() {
    this.showfilter = true;
  }

  yvesSort(sortBy) {
    this.sortBy = sortBy;
    this.yvesGetBesoin(true);
  }

  yvesGetBesoin(_sort?) {
    if (_sort === true) {
      this.sortDirection = this.sortDirection * -1;
    }
    let pageNum;
    if (this.currentPage <= 0) {
      pageNum = 0;
    } else if (this.currentPage > this.pageSize) {
      pageNum = this.pageSize - 1;
    } else {
      pageNum = this.currentPage - 1;
    }
    console.log("Besoin filter", this.BesoinFilter);
    this.service
      .yvesgetBesoin(
        pageNum,
        this.pageSize,
        this.nomcli.value,
        this.nomcontact.value,
        this.prenomcontact.value,
        this.datesaisie.value,
        this.description.value,
        this.status.value,
        this.ao.value,

        (this.roles.indexOf('Gestionnaire')>0 || this.roles.indexOf('Facturation')>0) ? this.rh.value : this.trigramme,
        this.crm.value,
        this.sortBy,
        this.BesoinFilter,
        this.dateenvoi.value,
        this.sortDirection.toString()
      )
      .subscribe((resp) => {
        this.besoins = resp["data"];
        this.totalPage = resp["total_num_of_page"] - 1;
        this.totalElt = resp["total_elt"];
        this.yvesColorEltSearchcontact();
        this.dialog.closeAll();
        //this.yvesColorEltSearch()
      });
  }

  Search2() {
    this.loader();
    this.yvesGetBesoin();
    // this.getByName2(
    //   this.prenomcontact.value,
    //   this.nomcli.value,
    //   this.status.value,
    //   this.nomcontact.value,
    //   this.prenom.value,
    //   this.description.value,
    //   this.ao.value,
    //   this.cv.value,
    //   this.dateenvoi.value,
    //   this.rh.value,
    //   this.crm.value,
    //   this.currentPage
    //   )
  }
  Search() {
    this.loader();

    this.getByName(
      this.prenomcontact.value,
      this.nomcli.value,
      this.status.value,
      this.nomcontact.value,
      this.prenom.value,
      this.description.value,
      this.ao.value,
      this.cv.value,
      this.dateenvoi.value,
      (this.roles.indexOf('Gestionnaire')>0 || this.roles.indexOf('Facturation')>0) ? this.rh.value : this.trigramme,
      this.crm.value,
      this.currentPage
    );

  }
  SearchTriFiltrage() {
    this.loader();
    this.showfilter = true;

    this.currentPage = 1;
    this.yvesGetBesoin();
    // this.getByName(
    //   this.prenomcontact.value,
    //   this.nomcli.value,
    //   this.status.value,
    //   this.nomcontact.value,
    //   this.prenom.value,
    //   this.description.value,
    //   this.ao.value,
    //   this.cv.value,
    //   this.dateenvoi.value,
    //   this.rh.value,
    //   this.crm.value,
    //   1
    //   )
  }

  escapeRegExp(s) {
    if (s != null) {
      return s.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&");
    }
    return "";
  }
  getByName2(
    prenomcontact,
    nomcli,
    status,
    nomcontact,
    prenom,
    description,
    ao,
    cv,
    dateenvoi,
    rh,
    crm,
    page
  ) {
    this.besoins = [];
    this.Search();

    /** =
    this.service.RechercheBesoin(this.BesoinFilter,this.token.getUsername(),prenomcontact,nomcli,status,nomcontact,prenom,description,ao,cv,dateenvoi,page-1,this.pageSize,this.orderasc2,this.orderdesc2,rh,crm,this.datesaisie.value).subscribe(data => {

      if(data===1){
        this.Search()
      }


    });
    */
  }
  getByName(
    prenomcontact,
    nomcli,
    status,
    nomcontact,
    prenom,
    description,
    ao,
    cv,
    dateenvoi,
    rh,
    crm,
    page
  ) {
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);
    this.besoins = [];

    /**

    this.service.RechercheBesoinTri(this.BesoinFilter,this.token.getUsername(),prenomcontact,nomcli,status,nomcontact,prenom,description,ao,cv,dateenvoi,page-1,this.pageSize,this.orderasc2,this.orderdesc2,rh,crm,this.datesaisie.value).subscribe((data2) => {

      this.getBesoin(data2)


    });
     */
  }

  gotopage2(
    prenomcontact,
    nomcli,
    status,
    nomcontact,
    prenom,
    description,
    ao,
    cv,
    dateenvoi,
    rh,
    crm,
    page
  ) {
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);

    this.besoins = [];

    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Chargement en cours...",
        },
      });
      await delay(2000);
      dialogRef.close();
    })();
    /**
    this.service.RechercheBesoin(this.BesoinFilter,this.token.getUsername(),prenomcontact,nomcli,status,nomcontact,prenom,description,ao,cv,dateenvoi,page,this.pageSize,this.orderasc2,this.orderdesc2,rh,crm,this.datesaisie.value).subscribe((data2) => {

      this.getBesoin(data2)

     });
     */
  }

  goto2(page) {
    if (page - 1 <= this.totalPage) {
      this.gotopage2(
        this.prenomcontact.value,
        this.nomcli.value,
        this.status.value,
        this.nomcontact.value,
        this.prenom.value,
        this.description.value,
        this.ao.value,
        this.cv.value,
        this.dateenvoi.value,
        this.rh.value,
        this.crm.value,
        page - 1
      );
    } else {
      this.Alert("il n'existe que " + this.totalPage + " pages");
    }
  }

  yvesColorEltSearchcontact() {
    for (let index = 0; index < this.besoins.length; index++) {
      this.besoins[index]["nomClient"] =
        this.nomcli.value != ""
          ? this.besoins[index]["nomClient"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.nomcli.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" + this.nomcli.value + "</strong>"
              )
          : this.besoins[index]["nomClient"];
      this.besoins[index]["nomContact"] =
        this.nomcontact.value != ""
          ? this.besoins[index]["nomContact"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.nomcontact.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" + this.nomcontact.value + "</strong>"
              )
          : this.besoins[index]["nomContact"];
      this.besoins[index]["prenomContact"] =
        this.prenomcontact.value != ""
          ? this.besoins[index]["prenomContact"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.prenomcontact.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" +
                  this.prenomcontact.value +
                  "</strong>"
              )
          : this.besoins[index]["prenomContact"];
      this.besoins[index]["dateSaisie"] =
        this.datesaisie.value != ""
          ? this.besoins[index]["dateSaisie"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.datesaisie.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" + this.datesaisie.value + "</strong>"
              )
          : this.besoins[index]["dateSaisie"];
      this.besoins[index]["description"] =
        this.description.value != ""
          ? this.besoins[index]["description"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.description.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" + this.description.value + "</strong>"
              )
          : this.besoins[index]["description"];

      if (this.besoins[index]["statut"]) {
        this.besoins[index]["statut"] =
          this.status.value != ""
            ? this.besoins[index]["statut"]
                .toString()
                .replace(
                  new RegExp(
                    "(" + this.escapeRegExp(this.status.value) + ")",
                    "gi"
                  ),
                  "<strong class='strong'>" + this.status.value + "</strong>"
                )
            : this.besoins[index]["statut"];
      }

      this.besoins[index]["dateSaisie"] =
        this.dateenvoi.value != ""
          ? this.besoins[index]["dateSaisie"]
              .toString()
              .replace(
                new RegExp(
                  "(" + this.escapeRegExp(this.dateenvoi.value) + ")",
                  "gi"
                ),
                "<strong class='strong'>" + this.dateenvoi.value + "</strong>"
              )
          : this.besoins[index]["dateSaisie"];
      this.besoins[index]["trigBesoin"] =
        this.crm.value != ""
          ? this.besoins[index]["trigBesoin"]
              .toString()
              .replace(
                new RegExp("(" + this.escapeRegExp(this.crm.value) + ")", "gi"),
                "<strong class='strong'>" + this.crm.value + "</strong>"
              )
          : this.besoins[index]["trigBesoin"];
      this.besoins[index]["trigGestionnaireRH"] =
        this.rh.value != ""
          ? this.besoins[index]["trigGestionnaireRH"]
              .toString()
              .replace(
                new RegExp("(" + this.escapeRegExp(this.rh.value) + ")", "gi"),
                "<strong class='strong'>" + this.rh.value + "</strong>"
              )
          : this.besoins[index]["trigGestionnaireRH"];
    }
  }

  emitPagination(e) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.currentPage = this.currentPage + 1;
    this.goto(e.pageIndex + 1);

    this.iterator();
  }
  private iterator() {
    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Chargement en cours...",
        },
      });
      await delay(2000);
      dialogRef.close();
    })();
  }

  resetFilter() {
    this.nameFilter = new FormControl("");
    this.nomcli = new FormControl("");
    this.status = new FormControl("");
    this.nomcontact = new FormControl("");
    this.prenom = new FormControl("");
    this.datesaisie = new FormControl("");
    this.rh = new FormControl("");
    this.crm = new FormControl("");
    this.prenomcontact = new FormControl("");
    this.description = new FormControl("");
    this.ao = new FormControl("");
    this.cv = new FormControl("");
    this.dateenvoi = new FormControl("");
    this.showfilter = false;
    this.Search2();
  }

  loader() {
    window.scrollTo(0, 0);

    (async () => {
      const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {
          message: "Chargement en cours ...",
        },
        disableClose: true,
      });
    })();
  }

  home() {
    this.route.navigate(["/listeClients"]);
  }

  gotoTop() {
    window.scrollTo(0, 0);
  }

  openPopup(e, data) {
    e.preventDefault();
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: data,
      },
    });
  }
  goto(page) {
    if (parseInt(page)) {
      if (page - 1 <= this.totalPage) {
        this.currentPage = page;
        this.Search2();
      } else {
        let total = this.totalPage + 1;
        this.Alert("il n'existe que " + total + " pages");
      }
    } else {
      this.Alert("Page invalide");
    }
  }

  NexPage() {
    this.currentPage = this.currentPage - 1;
    this.currentPage = this.currentPage + 2;
    this.Search2();
  }
  previousPage() {
    this.currentPage = this.currentPage - 1;
    this.Search2();
  }
  FirstPage() {
    this.currentPage = 1;
    this.showprevious = false;
    this.showfirstpage = false;

    this.shownext = true;
    this.showlastpage = true;
    this.Search2();
  }
  LastPage() {
    this.currentPage = this.totalPage + 1;
    this.shownext = true;
    this.showlastpage = true;
    this.showprevious = false;
    this.showfirstpage = false;
    if (this.totalPage + 1 === this.currentPage) {
      this.shownext = false;
      this.showlastpage = false;
    }
    this.Search2();
  }

  getNext() {
    if (this.currentPage < this.totalPage + 1) {
      return true;
    }
    return false;
  }
  getpreviousMove() {
    if (this.currentPage > 1) {
      return true;
    }
    return false;
  }
  getPrevious() {
    if (this.currentPage > 1) {
      return true;
    }
    return false;
  }

  getlastpage() {
    if (this.currentPage < this.totalPage + 1) {
      return true;
    }
    return false;
  }
  ChangeItemPerPage(e) {
    this.pageSize = e;
    this.SearchTriFiltrage();
  }

  TruncateTitre(text) {
    return text.substr(0, 26);
  }
  TruncateIntitule(text) {
    return text.substr(0, 26);
  }
  renderAo(ao) {
    if (ao && ao.statut) {
      return ao.statut;
    }
    return "";
  }
  renderDateCv(cv) {
    if (cv && cv[0].dateEnvoiCV) {
      return cv[0].dateEnvoiCV;
    }
    return "";
  }

  renderNomCv(cv) {
    if (cv && cv[0].nomConsultant) {
      return cv[0].nomConsultant;
    }
    return "";
  }

  setdata(e: string, event: any) {
    let k = event.target;
    this.title = e;
    if (k) {
      if (k.scrollWidth > k.clientWidth) {
        k.style.backgroundColor = "#FFFFCC";

        k.setAttribute("title", e);
        this.open(event);
      }
    }
  }

  open(e: any) {
    const ele = document.getElementById("element");
    const menu = document.getElementById("menu");
    if (ele != null && menu != null) {
      e.preventDefault();

      const rect = ele.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Set the position for menu
      menu.style.top = `${y + 300}px`;
      menu.style.left = `${x}px`;

      // Show the menu
      menu.classList.remove("hidden");
    }
  }
  hover() {
    const ele = document.getElementById("element");
    const menu = document.getElementById("menu");
    if (ele != null && menu != null) {
      ele.addEventListener("context", function (e: any) {
        e.preventDefault();

        const rect = ele.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Set the position for menu
        menu.style.top = `${y + 40}px`;
        menu.style.left = `${x}px`;

        // Show the menu
        menu.classList.remove("hidden");

        document.addEventListener("click", documentClickHandler);
      });

      // Hide the menu when clicking outside of it
      const documentClickHandler = function (e: any) {
        const isClickedOutside = !menu.contains(e.target);
        if (isClickedOutside) {
          menu.classList.add("hidden");
          document.removeEventListener("click", documentClickHandler);
        }
      };
    }
  }

  close(e: any) {
    e.target.style.backgroundColor="transparent";

      const menu = document.getElementById('menu');
      if (menu != null) {
        const isClickedOutside = !menu.contains(e.target);
        if (isClickedOutside) {
          menu.classList.add('hidden');
        }
      }
    }


  changeColor() {
    if (this.nomcli.value === "") {
      this.isNomClientFilter = false;
    } else {
      this.isNomClientFilter = true;
    }

    if (this.nomcontact.value === "") {
      this.isNomContactFilter = false;
    } else {
      this.isNomContactFilter = true;
    }
    if (this.prenomcontact.value === "") {
      this.isPrenomContactFilter = false;
    } else {
      this.isPrenomContactFilter = true;
    }
    if (this.datesaisie.value === "") {
      this.isDateSaisieFilter = false;
    } else {
      this.isDateSaisieFilter = true;
    }
    if (this.description.value === "") {
      this.isdescriptionFilter = false;
    } else {
      this.isdescriptionFilter = true;
    }
    if (this.status.value === "") {
      this.isstatutFilter = false;
    } else {
      this.isstatutFilter = true;
    }
    if (this.ao.value === "") {
      this.isaoFilter = false;
    } else {
      this.isaoFilter = true;
    }
    if (this.cv.value === "") {
      this.isCvsFilter = false;
    } else {
      this.isCvsFilter = true;
    }
    if (this.dateenvoi.value === "") {
      this.isDateSaisieFilter = false;
    } else {
      this.isDateSaisieFilter = true;
    }
    if (this.rh.value === "") {
      this.isRhFilter = false;
    } else {
      this.isRhFilter = true;
    }
    if (this.crm.value === "") {
      this.isCrmFilter = false;
    } else {
      this.isCrmFilter = true;
    }
  }
  /**
   * Fonction qui fait appel au Loader  pour le chargement des données
   */
  Loader() {
    window.scroll(0, 0);
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
           Chargement en cours`,
      },
      disableClose: true,
    });
  }
  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogFile(ao, type) {
     this.dialogFileService.openDialogFile(ao,type)

  }

  loadBesoin(event: string) {
    this.BesoinFilter = event;
    this.Search2();
  }

  consultAO(ao) {
    window.open(
      CRM_BACK_ENDPOINT+"/downloadFile/" + ao,"_blank"
    );


  }

}
