import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { RechercheService } from '../services/recherche.service';
import { RPUMC } from '../Model/RPUMC';
import { CAMC } from '../Model/CAMC';
import { AfficheCAMC } from '../Model/affiche-camc';
import { PrioriteService } from '../services/priorite.service';
import { TokenStorageService } from '../services/token-storage.service';

@Component({
  selector: 'app-popresult',
  templateUrl: './popresult.component.html',
  styleUrls: ['./popresult.component.scss']
})
export class PopresultComponent implements OnInit {

  Priorite : any = [];

  ngOnInit() {
  }

  test= new RPUMC();
  recherche: any = [];
  idC: string;
  motcles:any;
  cancelButtonText = "Cancel";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PopresultComponent>,
    private rs: RechercheService, private pos: PrioriteService,private tokenStorageService: TokenStorageService) {
    this.idC=data.idC;
    this.motcles = data.motcles;
    this.getPriorite();
    
    if (data.buttonText) {
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
    this.dialogRef.updateSize('300vw','300vw')
  }

  getPriorite(){
    this.pos.getPriorite().subscribe(data => {
      
      for (let i in data) {
        this.Priorite.push(data[i]);
    }
    this.getRech();
    });
  }

  getRech(){
      const user = this.tokenStorageService.getUser();

      for(let i =0;i<this.motcles.length;i++){
        let rechtest = new AfficheCAMC();
        rechtest.mot=this.motcles[i].mot
        rechtest.nbre_occurence=this.motcles[i].occ
        for(let x in this.Priorite){
          if(this.motcles[i].poids === this.Priorite[x].poids){
            rechtest.poids = this.Priorite[x].priorite;
          }
        }
        this.recherche.push(rechtest);
      }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
