<div style="display: flex;justify-content: center;background-color: #EFEEEE;width: 100%; justify-content: center;padding: 14px;">
    <p style="font-size: 20px; font-weight: bolder;color: red;margin: 0;text-align: center;">Consultants trouvés : <span style="color: #ff0000">{{totalSize}}</span>
        <span style="color: red"> / </span>
        <span style="color: #ff0000">{{totalConsultants}}</span>
    </p>

    <button *ngIf="showfilter" style="border: none;background-color: transparent;margin-top: -18px;margin-left: 10px;" (click)="resetFilter()">
    <img width="30" src="../../assets/filter.png" alt="">
  </button>
</div>
<p style="text-align: center; font-weight: bold; font-size: 18px;">
    Critères de recherche: {{tag}}
</p>

<div style=" height: 80vh;" class="table-container">
    <table id="element" style="position:relative;" class="table table-striped table-hover">

        <thead>
            <tr class = "table-secondary">
                <th scope="col" class="qualite">
                    <div class="input" id="qualite">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Qualité</mat-label>
                            <select matNativeControl [(ngModel)]="qualitecv" (ngModelChange)="SearchTriFiltrage()">
                              <option ></option>
                              <option *ngFor='let option of this.Quali' [value]="option.value">{{option.name}}</option>
                            </select>
                        </mat-form-field>
                        <div (click)='sort("qualite")' class="sortbtn">
                            <img *ngIf="this.sortBy!='qualite'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="qualitedesc && this.sortBy=='qualite'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="nom">
                    <div class="input" id="nom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Nom</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="nameFilter">
                        </mat-form-field>
                        <div (click)='sort("nom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='nom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!nomdesc  && this.sortBy=='nom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="nomdesc && this.sortBy=='nom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="prenom">
                    <div class="input" id="prenom">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Prénoms</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="prenomFilter">
                        </mat-form-field>
                        <div (click)='sort("prenom")' class="sortbtn">
                            <img *ngIf="this.sortBy!='prenom'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="prenomdesc  && this.sortBy=='prenom'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="titre">
                    <div class="input" id="titre">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Titre</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="titreFilter">
                        </mat-form-field>
                        <div (click)='sort("titre")' class="sortbtn">
                            <img *ngIf="this.sortBy!='titre'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="titredesc && this.sortBy=='titre'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="techno">
                    <div class="input" id="techno">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Technologie</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="techFilter">
                        </mat-form-field>
                        <div (click)='sort("tech")' class="sortbtn">
                            <img *ngIf="this.sortBy!='tech'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!techdesc && this.sortBy=='tech'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="techdesc && this.sortBy=='tech'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="dispo">
                    <div class="input" id="dispo">
                        <mat-form-field class="form-fiel" style="width: 50%;">
                            <mat-label class="label">Dispo</mat-label>
                            <select matNativeControl [(ngModel)]="disponibilite" (ngModelChange)="SearchTriFiltrage()">
                              <option ></option>
                              <option *ngFor='let option of this.Dispo' [value]="option.value">{{option.name}}</option>
                            </select>
                        </mat-form-field>
                        <div (click)='sort("dispo")' class="sortbtn">
                            <img *ngIf="this.sortBy!='dispo'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!dispodesc && this.sortBy=='dispo'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="dispodesc && this.sortBy=='dispo'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="client">
                    <div class="input" id="client">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Client</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="clientFilter">
                        </mat-form-field>
                        <div (click)='sort("client")' class="sortbtn">
                            <img *ngIf="this.sortBy!='client'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!clientdesc  && this.sortBy=='client'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="clientdesc  && this.sortBy=='client'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>

                <th scope="col" class="date">
                    <div class="input" id="date">
                        <mat-form-field class="form-fiel" style="width: 70%;">
                            <mat-label class="label">Date Modif</mat-label>
                            <input matInput (keyup.enter)="SearchTriFiltrage()" [formControl]="dateDerniereMajFilter">
                        </mat-form-field>
                        <div (click)='sort("date")' class="sortbtn">
                            <img *ngIf="this.sortBy!='date'" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="!datedesc  && this.sortBy=='date'" style="background-color: #6ab38c;" width="25" src="../assets/asc.png" id='up' alt="">
                            <img *ngIf="datedesc  && this.sortBy=='date'" style="background-color: #6ab38c;" id='desc' width="25" src="../assets/desc.png" id='up' alt="">
                        </div>
                    </div>
                </th>
                <th scope="col" style="width: 60px; padding:auto;">
                    <i class="bi bi-suit-heart-fill mb-5 mr-2"></i>
                </th>
            </tr>
        </thead>
        <tbody style="position: relative;">
            <tr *ngFor="let item of consultants; let i = index">
                <td (click)="Select(item)" style="text-align: center;"><img src="./assets/{{item.qualitecv.libelle}}.png"></td>
                <td [innerHTML]="item.nom" (click)="Select(item)" [ngClass]="{ 'highlight ': isNomFilter, 'default ': !isNomFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.nom,$event)" [id]="'nom '+i">



                </td>
                <td [innerHTML]="renderPrenom(item.prenom)" (click)="Select(item)" [ngClass]="{ 'highlight ': isPrenomFilter, 'default ': !isPrenomFilter  }" (mouseleave)="close($event)" [id]="'prenom '+i" (mouseenter)="setdata(item.prenom,$event)">

                </td>

                <td [innerHTML]="item.titre" (click)="Select(item)" [ngClass]="{ 'highlight ': isTitreFilter, 'default ': !isTitreFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.titre,$event)" class="titrepx" [id]="'titre '+i"></td>
                <td [innerHTML]="item.tech" (click)="Select(item)" [ngClass]="{ 'highlight ': isTechFilter, 'default ': !isTechFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.tech,$event)" class="techpx" [id]="'tech '+i"></td>
                <td [innerHTML]="renderDispo(item.codedispo)" (click)="Select(item)" [ngClass]="{ 'highlight ': isDispoFilter, 'default ': !isDispoFilter  }" style="text-align: center;" (mouseleave)="close($event)" (mouseenter)="setdata(item.nom,$event)" class="dispopx"
                    [id]="'dispo '+i"></td>
                <td [innerHTML]="item.listClient" (click)="Select(item)" [ngClass]="{ 'highlight ': isClientFilter, 'default ': !isClientFilter  }" (mouseleave)="close($event)" (mouseenter)="setdata(item.listClient,$event)" class="telpx" [id]="'listClient '+i"></td>
                <td [innerHTML]="item.dateDerniereMaj" (click)="Select(item)" [ngClass]="{ 'highlight ': idDateFilter, 'default ': !idDateFilter  }" style="text-align: center;" (mouseleave)="close($event)" (mouseenter)="setdata(item.dateDerniereMaj,$event)" class="dateDerniereMajpx"
                    [id]="'dateDerniereMaj '+i"></td>
                <td class="text-center">
                    <i class="bi bi-suit-heart-fill" style="color: tomato;" *ngIf="favoris.includes(item._id)"></i>
                </td>
    


            </tr>

        </tbody>
    </table>
</div>


<div class="has-text-centered " style="margin: auto;display: flex;justify-content: center;margin-top: 1vw;">

    <div style="flex-grow: 1;display: flex;justify-content: center;">
        <img *ngIf="getpreviousMove()" (click)="FirstPage()" style="cursor: pointer; margin-right: 1vw;" width="25" src="../../assets/fisrtPageActive.png" alt="">
        <img *ngIf="getPrevious()" (click)="previousPage()" style="cursor: pointer; margin-right: 3vw" width="20" src="../../assets/previousPageActive.png" alt="">

        <img *ngIf="!getpreviousMove()" style="margin-right: 1vw" width="25" src="../../assets/firstPage.png" alt="">
        <img *ngIf="!getPrevious()" style="margin-right: 3vw" width="20" src="../../assets/previousPage.png" alt="">

        <input autocomplete="off" (keyup.enter)="goto($event.target.value)" [(ngModel)]="currentPage" type="text" style="width: 90px;color: red;font-weight: bold;height: 25px;"> / {{totalPage}}
        <img *ngIf="getNext()" (click)="NexPage()" style="cursor: pointer;margin-left: 3vw; " width="20" src="../../assets/nextPageActive.png" alt="">
        <img *ngIf="getlastpage()" (click)="LastPage()" style="cursor: pointer;margin-left: 1vw;" width="25" src="../../assets/lastPageActive.png" alt="">

        <img *ngIf="!getNext()" style="margin-left: 3vw;" width="20" src="../../assets/nextPage.png" alt="">
        <img *ngIf="!getlastpage()" style="margin-left: 1vw;" width="25" src="../../assets/lastPage.png" alt="">
    </div>
    <div style="display: flex;justify-content: center;align-items: center;">
        <p style="margin: 0;margin-right: 5px;">Par Page:</p>
        <select (change)="ChangeItemPerPage($event.target.value)" name="" id="">
            <option *ngFor="let item of pageSizeElement" [value]="item" >{{item}}</option>
       
          </select>
    </div>
</div>