import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { ConsultantService } from '../services/consultant.service';
import * as fileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { SafeResourceUrl, DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-valider-facture-consultant',
  templateUrl: './valider-facture-consultant.component.html',
  styleUrls: ['./valider-facture-consultant.component.scss']
})
export class ValiderFactureConsultantComponent implements OnInit {

  idmission: string = "";
  idcra: string = "";
  filelocation: string = "";
  mission: any;
  filedata: any = "";
  isLoading: boolean = true;
  eltfacture: any;
  @ViewChild('factureModal', {read: TemplateRef}) factureModal! : TemplateRef<any>;
  safeResourceUrl : SafeResourceUrl;

  constructor(private route : ActivatedRoute, private cs: ConsultantService, private dialog: MatDialog, private modalService: NgbModal,
    config: NgbModalConfig, private datePipe: DatePipe, private sanitizer: DomSanitizer) {
    config.backdrop = 'static';
		config.keyboard = false;
  }

  ngOnInit(): void {
    this.Loader();
    this.route
    .queryParams
    .subscribe(params => {
      this.idmission = params.id;
      this.idcra = params.cra;
      this.filelocation = params.file;
      this.downloadFile(this.filelocation);
      this.cs.getMissionById(this.idmission).subscribe(
        (data) => {
          console.log("mission", data)
          this.mission = data;
          for(let elt of this.mission.cras) {
            if(elt.idcra == this.idcra){
              this.mission.cra = elt;
              break;
            }
          }
          let fin = new Date(this.mission.datefin);
          let now = new Date()
          for (let d = new Date(new Date(this.mission.datedebut).setDate(1)); d <= now; d.setMonth(d.getMonth() + 1)) {
            if (d < fin ) {
              if(this.mission.cra.mois == d.getMonth()-1){
                this.mission.date = new Date(d);
                break;
              }
            } else {
              break;
            }
          }

        }
      )
    });
  }

  downloadFile(location: string){
    this.cs.downloadFile(location).subscribe(
      blob => {
        const data = new Blob([blob], { type: 'application/pdf' });
        let url = URL.createObjectURL(data);
        this.filedata = this.sanitizer.bypassSecurityTrustUrl(url);
        //this.filedata = blob;
        this.dialog.closeAll();
        this.isLoading = false;
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }

  openModal(content) {
    this.modalService.open(content, {centered: true})
  }

  openFactureConsultantModal(){
    this.eltfacture = {};
    this.eltfacture.idmission = this.idmission;
    this.eltfacture.idcra = this.idcra;
    this.eltfacture.mois = this.datePipe.transform(this.mission.date, 'yyyy-MM');
    this.eltfacture.paiementsociete = this.mission.paiementsociete;
    this.eltfacture.contratcadre = this.mission.contratcadre;
    this.eltfacture.contrat = this.mission.contrat;
    this.eltfacture.paiementrc = this.mission.paiementrc;
    this.eltfacture.paiementmf = this.mission.paiementmf;
    this.eltfacture.paiementtva = this.mission.paiementtva;
    this.eltfacture.paiementtitulaire = this.mission.paiementtitulaire;
    this.eltfacture.paiementiban = this.mission.paiementiban;
    this.eltfacture.paiementbic = this.mission.paiementbic;
    this.eltfacture.paiementdomiciliation = this.mission.paiementdomiciliation;
    this.eltfacture.paiementadresse = this.mission.paiementadresse;
    this.eltfacture.tjmconsultant = this.mission.tjmconsultant;
    this.eltfacture.totaljourtravaille =  this.mission.cra.totaljourtravaille;
    this.eltfacture.nbreastreintes = this.mission.cra.nbreastreintes;
    this.eltfacture.tarifastreinteconsultant = this.mission.tarifastreinteconsultant;
    this.eltfacture.totaltva = 0;
    this.eltfacture.numerofacture = "";
    this.eltfacture.datefacturation = "";
    this.eltfacture.echeancepaiement = "";
    this.eltfacture.nomconsultant = this.mission.nomconsultant;
    this.eltfacture.prenomconsultant = this.mission.prenomconsultant;
    this.eltfacture.telephoneconsultant = "";
    this.eltfacture.emailconsultant = "";
    this.eltfacture.adresseconsultant = "";
    this.eltfacture.activite = "";

    this.openModal(this.factureModal);
  }

  genererFactureConsulant(){
    this.modalService.dismissAll();
    this.Loader();
    this.cs.genererFactureConsultant(this.eltfacture).subscribe(
      blob => {
        this.dialog.closeAll();
        this.saveFile(blob, "aaaaaa.pdf");
      },
      error => {
        console.log(error);
        this.dialog.closeAll();
        this.Alert("Erreur lors de la génération de la facture");
      }
    );
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data]);
    fileSaver.saveAs(blob, filename);
  }

  Loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en Cours`

      },
      disableClose: true,
    });
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
