import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';


import { TitleService } from './services/title.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { LOCALE_ID, NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatDialogModule } from '@angular/material';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material'
import { DropdownModule } from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';





import { MatInputModule } from '@angular/material/input';

// Perfect Scroll Bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AjoutConsultantComponent } from './ajout-consultant/ajout-consultant.component';
import { ListeComponent } from './liste/liste.component';
import { FilterPipe } from "./filter.pipe";
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { FicheConsultantComponent } from './fiche-consultant/fiche-consultant.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TagsComponent } from './tags/tags.component';
import { ListeDComponent } from './liste-d/liste-d.component';
import { RechtagsComponent } from './rechtags/rechtags.component';
import { RechresultComponent } from './rechresult/rechresult.component';
import { RappelsComponent } from './rappels/rappels.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { AlertEmptyComponent } from './alert-empty/alert-empty.component';
import { RechmcComponent } from './rechmc/rechmc.component';
import { RechmcrComponent } from './rechmcr/rechmcr.component';
import { PopresultComponent } from './popresult/popresult.component';
import { authInterceptorProviders } from './_helper/auth.interceptor';
import { RouterModule } from '@angular/router';
import {ModalModule} from 'ngx-bootstrap/modal';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopupdataComponent } from './popupdata/popupdata.component';
import { PopuploaderComponent } from './popuploader/popuploader.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';

import {NgxPaginationModule} from 'ngx-pagination';
import { FooterComponent } from './footer/footer.component';
import { ConsultantbytagsComponent } from './consultantbytags/consultantbytags.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StoreModule } from '@ngrx/store';
import { windowReducer } from './reducers/window.reducer';
import { ListeBesoinsComponent } from './liste-besoins/liste-besoins.component';
import { RechercheBesoinComponent } from './recherche-besoins/recherche-besoin.component';
import { PagebesoinComponent } from './pagebesoin/pagebesoin.component';
import { DialogFileComponent } from './dialog-file/dialog-file.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { RechmcRhcrmComponent } from './rechmcrhcrm/rechmcrhcrm.component';
import { RechmcrRhCrmComponent } from './rechmcrrhcrm/rechmcrrhcrm.component';

// Arold Cam
import { NgSelectModule } from '@ng-select/ng-select';
import { ActionComponent } from './dashboard/action/action.component';
import { RappelComponent } from './dashboard/rappel/rappel.component';
import { RechercheComponent } from './dashboard/recherche/recherche.component';
import { SyntheseComponent } from './dashboard/synthese/synthese.component';
import { ConsultantComponent } from './dashboard/consultant/consultant.component';
import { MissionComponent } from './dashboard/mission/mission.component';
import { QualiteComponent } from './dashboard/qualite/qualite.component';
import { CraConsultantComponent } from './consultants/cra-consultant/cra-consultant.component';
import localeFr from '@angular/common/locales/fr';
import { PageMissionsComponent } from './page-missions/page-missions.component';
import { PageManagerComponent } from './page-manager/page-manager.component';
import { ChartsModule } from 'ng2-charts';
import { PageFacturationComponent } from './page-facturation/page-facturation.component';
import { ValiderFactureConsultantComponent } from './valider-facture-consultant/valider-facture-consultant.component';
import { NgDraggableModule } from 'angular-draggable';
import { CompetencesComponent } from './consultants/competences/competences.component';
import { ExperiencesConsultantComponent } from './consultants/experiences-consultant/experiences-consultant.component';
import { HomeConsultantComponent } from './consultants/home-consultant/home-consultant.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EnterCodeComponent } from './enter-code/enter-code.component';
import { InfosConsultantComponent } from './consultants/infos-consultant/infos-consultant.component';
import { RegisterComponent } from './register/register.component';
import { OpportuniteConsultantComponent } from './consultants/opportunite-consultant/opportunite-consultant.component';
import { ValidateConsultantComponent } from './validate-consultant/validate-consultant.component';
import { HighlightSearch } from './highlight-pipe';
import { CartographieComponent } from './consultants/cartographie/cartographie/cartographie.component';
import { ConsultantTestComponent } from './consultants/cartographie/consultant-test/consultant-test.component';
import { ConsultantTrainingComponent } from './consultants/cartographie/consultant-training/consultant-training.component';
import { ListTestComponent } from './consultants/cartographie/list-test/list-test.component';
import { ListTrainingComponent } from './consultants/cartographie/list-training/list-training.component';
import { ConfigsComponent } from './cartographie-configs/configs/configs.component';
import { ConfigsDomainComponent } from './cartographie-configs/configs-domain/configs-domain.component';
import { ConfigsSubdomainComponent } from './cartographie-configs/configs-subdomain/configs-subdomain.component';
import { ConfigsTechnoComponent } from './cartographie-configs/configs-techno/configs-techno.component';
import { ConfigsTestsComponent } from './cartographie-configs/configs-tests/configs-tests.component';
import { ConfigsTrainingComponent } from './cartographie-configs/configs-training/configs-training.component';
import { TestPageComponent } from './consultants/cartographie/test-page/test-page.component';
import { TrainingPageComponent } from './consultants/cartographie/training-page/training-page.component';
registerLocaleData(localeFr);
// Arold Cam

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
//


@NgModule({
  declarations: [
    FilterPipe,
    AppComponent,
    SidebarComponent,
    AjoutConsultantComponent,
    ListeComponent,
    FicheConsultantComponent,
    AlertDialogComponent,
    DashboardComponent,
    TagsComponent,
    ListeDComponent,
    RechmcRhcrmComponent,
    RechmcrRhCrmComponent,
    RechtagsComponent,
    RechresultComponent,
    RappelsComponent,
    UserComponent,
    LoginComponent,
    AlertEmptyComponent,
    RechmcComponent,
    RechmcrComponent,
    PopresultComponent,
    ProgressSpinnerDialogComponent,
    PopupdataComponent,
    PopuploaderComponent,
    ConsultantbytagsComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    ListeBesoinsComponent,
    RechercheBesoinComponent,
    PagebesoinComponent,
    DialogFileComponent,
    DialogErrorComponent,
    ActionComponent,
    RappelComponent,
    RechercheComponent,
    SyntheseComponent,
    ConsultantComponent,
    MissionComponent,
    QualiteComponent,
    CraConsultantComponent,
    PageMissionsComponent,
    PageManagerComponent,
    PageFacturationComponent,
    ValiderFactureConsultantComponent,
    CompetencesComponent,
    ExperiencesConsultantComponent,
    HomeConsultantComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    EnterCodeComponent,
    InfosConsultantComponent,
    RegisterComponent,
    OpportuniteConsultantComponent,
    ValidateConsultantComponent,
    HighlightSearch,
    CartographieComponent,
    ConsultantTestComponent,
    ConsultantTrainingComponent,
    ListTestComponent,
    ListTrainingComponent,
    ConfigsComponent,
    ConfigsDomainComponent,
    ConfigsSubdomainComponent,
    ConfigsTechnoComponent,
    ConfigsTestsComponent,
    ConfigsTrainingComponent,
    TestPageComponent,
    TrainingPageComponent
  ],
  entryComponents: [AlertDialogComponent,AlertEmptyComponent,PopresultComponent,ProgressSpinnerDialogComponent],
  imports: [
    ModalModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    CommonModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    FormsModule,
    MatDialogModule,
    InputTextModule,
    MatTableModule,
    DropdownModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MDBBootstrapModule.forRoot(),
    NgbModule,
    StoreModule.forRoot({window:windowReducer}),

    // Arold Cam
    ChartsModule,
    NgSelectModule,
    NgDraggableModule
  ],
  providers: [TitleService, DatePipe,{
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    authInterceptorProviders,
    { provide: LOCALE_ID, useValue: "fr-FR" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
