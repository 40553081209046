<!-- Extended material form grid -->
<div class="container-fluid p-2">
    <form [formGroup]="registerForm" (ngSubmit)="addConsultant()" *ngIf="isModerateur">

        <div class="row">
            <!-- Grid column -->
            <div class="col-md-2">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputEmail4MD">Nom <font size="2" face="georgia" color="red">(*)</font></label>
                    <input mdbInput type="text" formControlName="nom" class="form-control" id="inputEmail4MD" name="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" required>
                </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputEmail4MD">Prénom <font size="2" face="georgia" color="red">(*)</font></label>
                    <input mdbInput type="text" class="form-control" id="inputEmail4MD" formControlName="prenom" name="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" required>
                </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputPassword4MD">Titre <font size="2" face="georgia" color="red">(*)</font></label>
                    <input mdbInput type="text" class="form-control" id="inputPassword4MD" formControlName="titre" [ngClass]="{ 'is-invalid': submitted && f.titre.errors }" name="titre" required>

                </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-1">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputPassword4MD">TJM</label>
                    <input mdbInput type="text" class="form-control" id="inputPassword4MD" formControlName="tjm" name="tjm">
                </div>
            </div>
            <!-- Grid column -->
            <!-- Grid column -->
            <div class="col-md-1">
                <!-- Material input -->
                <div class=" form-group">
                    <label for="inputPassword4MD">Disponibilité</label>
                    <select class="browser-default custom-select" (change)="changeDispo($event.target.value)" formControlName="dis" name="dis">
              <option value="{{disp.libelle}}" *ngFor="let disp of Dispo">{{disp.libelle}}</option>
            </select>
                </div>
            </div>
            <!-- Grid column -->

            <div class="col-md-2">
                <!-- Material input -->
                <div class=" form-group">
                    <label for="inputPassword4MD">Qualité CV</label>
                    <select class="browser-default custom-select" ngModel="Neutre" (change)="onChangeQualiteCv($event.target.value)" [ngClass]="{ 'browser-default custom-selectred ng-valid ng-dirty ng-touched': red, 'browser-default custom-selectgreen ng-valid ng-dirty ng-touched': green, 'browser-default custom-selectgrey ng-valid ng-dirty ng-touched': grey  }"
                        formControlName="quali" name="quali" style='font-family:Arial, FontAwesome;padding: 0;'>
              <option value="Jouable"  style='color:green;'>&#xf111; &nbsp;Jouable</option>
              <option value="Moyen"  style='color:orange;'>&#xf111; &nbsp;Moyen</option>
              <option value="Injouable"  style='color:red;'>&#xf111; &nbsp;Injouable</option>
              <option value="Neutre" selected style='color:grey;'>&#xf111; &nbsp;Neutre</option>
            </select>
                </div>
            </div>

            <div class="col-md-2">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputPassword4MD">Source</label>
                    <select class="browser-default custom-select" [ngClass]="{ 'browser-default custom-selectred ng-valid ng-dirty ng-touched': red, 'browser-default custom-selectgreen ng-valid ng-dirty ng-touched': green, 'browser-default custom-selectgrey ng-valid ng-dirty ng-touched': grey  }"
                        formControlName="source" name="source" style='font-family:Arial, FontAwesome;'>
                      <option value=""></option>
                      <option value="Turnover IT">Turnover IT</option>
                      <option value="LinkedIn" >LinkendIn</option>
                      <option value="Autres jobboards" >Autres Jobboards</option>
                      <option value="Réseau">Réseau</option>
                    </select>
                </div>



            </div>
        </div>

        <div class="row">

            <!-- Grid column -->
            <div class="col-md-2">
                <!-- Material input -->
                <div class="form-group">
                    <label for="inputAddressMD" style="margin-right: 3px;">Tél Principal<font size="2" face="georgia" color="red">(*)</font></label>
                    <input mdbInput type="text" class="form-control" id="inputAddressMD" formControlName="telp" id="telp" name="telp" [ngClass]="{ 'is-invalid': submitted && f.telp.errors }">
                </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2">
                <!-- Material input -->
                <div class=" form-group">
                    <label for="inputAddressMD">E-mail <font size="2" face="georgia" color="red">(*)</font></label>
                    <input mdbInput mdbValidate type="email" class="form-control" id="inputEmailMD" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" name="email">
                </div>
            </div>
            <!-- Grid column -->
            <div class="col-md-1">
                <!-- Material input -->
                <div class=" form-group">
                    <label for="inputAddressMD">C.P.</label>
                    <input mdbInput type="text" class="form-control" id="inputPostalMD" formControlName="code" name="code">
                </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-1">
                <!-- Material input -->
                <div class=" form-group">
                    <label for="inputVilleMD">Ville</label>
                    <input mdbInput type="text" class="form-control" id="inputAddress2MD" formControlName="ville" name="ville">
                </div>
            </div>

            <div class="col-md-2">
                <!-- Material input -->
                <div class="  form-group ">
                    <label for="inputCollabMD " style="margin: 0!important;">Collaborateur</label>
                    <select class="browser-default custom-select " (change)="changeCollaborateur($event.target.value)" formControlName="collaborateur" name="collaborateur " style='font-family:Arial, FontAwesome;'>
                        <option value="En cours ">En cours</option>
                        <option value="Non ">Non</option>
                        <option value="Ancien ">Ancien</option>
                    </select>
                </div>
            </div>
            <div class="col-md-1">
                <div class=" form-group ">
                    <label for="inputDDAMD ">Date début activité</label><br>
                    <input style="font-size: 1rem; " class="form-control " [max]="today " formControlName="dda" name="dda" type="date">
                </div>
            </div>
            <div class="col-md-1">
                <!-- Material input -->
                <div class=" form-group mjaBlock ">
                    <div class=" form-group">
                        <label for="inputAddressMD">CV <font size="2" face="georgia" color="red">(*)</font></label>
                        <input mdbInput class="form-control" id="inputAddressMD" style="padding: 0;" style="padding: 0;" [ngClass]="{ 'is-invalid': submitted && f.cv.errors }" type="file" (change)="selectFile($event)" formControlName="cv" name="cv">
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <!-- Material input -->
                <div class=" form-group ">
                    <div class=" form-group">
                        <label for="inputAddressMD">LinkedIn</label>
                        <input mdbInput type="text" placeholder="Saisir URL" class="form-control" id="inputAddressMD" formControlName="linkedin" name="linkedin">
                    </div>
                </div>
            </div>
            <!-- Grid column -->
        </div>
        <!-- Grid row -->



        <div class="mt-4 mb-4">
            <!-- Grid column -->
            <div class="col-md-12">
                <!--Material textarea-->
                <div class=" md-outline">
                    <label for="form75" style="font-weight: bold;">Technologies</label>
                    <textarea type="text" id="form75" class="form-control md-textarea" rows="3" placeholder="Saisir les technologies du consultant" (change)="ChangeTEch($event.target.value)" formControlName="tech2" name="tech2"></textarea>
                </div>
            </div>
            <!-- Grid column -->
        </div>

        <div class="mb-4">
            <!-- Grid column -->
            <div class="col-md-12">
                <!--Material textarea-->
                <div class=" md-outline">
                    <label style="font-weight: bold;" for="form75">Observations</label>
                    <textarea type="text" id="form75" class="form-control md-textarea" rows="3" placeholder="Saisir les observations" dbInput formControlName="observation" name="observation"></textarea>
                </div>
            </div>
            <!-- Grid column -->
        </div>


        <div class="form-row">
            <div class="elementFlex">

                <div class="bordDiv" style="margin-bottom: 50px;">
                    <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;">Tags Techniques </span><br>
                    <div style="display: flex;flex-wrap: wrap;">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <input type="text" style="height: 20px;margin-left: 8px;font-size: 14px;flex: 3;" id="regTag" name="regTag" placeholder="Filtre..."> <br>

                    </div>
                    <div style="display: flex;justify-content: center;flex-wrap: wrap;">
                        <div class="bordInterDiv" style="float: left;margin-top: 10px;margin-bottom: 6px;">
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of TagTechPageUpdate | filter : 'libelle' : regex; let i = index ">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer; height: auto;" checked (change)="ChangeTagTech(item,$event.target.checked)" />
                                    </td>
                                    <td style="padding-right: 70px;">
                                        {{item.libelle}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="bordInterDiv" style="margin-top: 10px;margin-bottom: 6px;">
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of TagTechTabFilter">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" (change)="AddTagsToUpdate(item,$event.target.checked)">
                                    </td>
                                    <td style="padding-right: 55px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                            <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                        </div>

                    </div>
                    <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                        <div class="marginright" style="float: left;">
                            <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                            <input type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="size" [ngModelOptions]="{standalone: true}" (change)="ChangerTagsPageSize()" step="25" min="25" max="100">

                        </div>

                        <div style="width: 200px;">
                            <span style="font-size: 12px;margin-left:6%;"> page {{suivant+1}} sur {{totalPage}}</span>
                            <button class="fleche" (click)="PremiereTagsPage()" [disabled]="suivant===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                  </svg>
                </button>
                            <button class="fleche" (click)="PreviousTagsPage()" [disabled]="suivant===0"><i class="fa fa-angle-left"></i></button>
                            <button class="fleche" (click)="nextTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><i
                    class="fa fa-angle-right"></i>
                  </button>
                            <button class="fleche" (click)="FinalTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><svg width="1em" height="1em"
                    viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </button>
                        </div>
                    </div>


                </div>
                <div class="bordDiv" style="margin-bottom: 50px;">
                    <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;">Tags Fonctions </span><br>

                    <div style="display: flex;flex-wrap: wrap ;">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <input type="text" style="height: 20px;margin-left: 8px;font-size: 14px;flex: 1;" id="fonction" name="fonction" placeholder="Filtre..."> <br>

                    </div>
                    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                        <div class="bordInterDiv" style="float: left;margin-top: 10px;margin-bottom: 6px">

                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of FonctionPageUpdate | filter : 'libelle' : regexFonction; let i = index ">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" checked (change)="ChangeFonction(item,$event.target.checked)" />
                                    </td>
                                    <td style="padding-right: 70px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                        </div>
                        <div class="bordInterDiv" style="margin-top: 10px;margin-bottom: 6px;">
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of FonctionTabFilter">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" (change)="AddFonctionToUpdate(item,$event.target.checked)">
                                    </td>
                                    <td style="padding-right: 55px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                            <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                        </div>
                    </div>


                    <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
                        <div style="float: left;" class="marginright">
                            <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                            <input type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeFonction" [ngModelOptions]="{standalone: true}" (change)="ChangerFonctionPageSize()" step="25" min="25" max="100">

                        </div>

                        <div style="width: 200px;">
                            <span style="font-size: 12px;margin-left:6%;"> page {{suivantFonction+1}} sur {{totalPageFonction}}</span>
                            <button class="fleche" (click)="PremiereFonctionPage()" [disabled]="suivantFonction===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                  </svg>
                </button>
                            <button class="fleche" (click)="PreviousFonctionPage()" [disabled]="suivantFonction===0"><i class="fa fa-angle-left"></i></button>
                            <button class="fleche" (click)="nextFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><i
                    class="fa fa-angle-right"></i>
                  </button>
                            <button class="fleche" (click)="FinalFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><svg width="1em" height="1em"
                    viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Grid column -->
        </div>

        <div class="form-row">
            <div class="elementFlex">

                <div class="bordDiv" style="margin-bottom: 50px;">
                    <span style="padding-left: 43%;font-weight: bold;">Tags Métiers </span><br>
                    <div style="display: flex;flex-wrap: wrap;">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <input type="text" style="flex: 3;height: 20px;margin-left: 8px;font-size: 14px;" id="metier" name="metier" placeholder="Filtre..."> <br>

                    </div>

                    <div style="display: flex;justify-content: center;flex-wrap: wrap;">

                        <div class="bordInterDiv" style="float: left;margin-top: 10px;margin-bottom: 6px;">
                            <!-- >
                  <div style="margin-top: 7px;">
                  <input type="text" style="height: 20px;width: 175px; margin-right: 3px;font-size: 14px;"
                  placeholder="Ajouter Metier ..."
                   [(ngModel)]="MetierUpdate.libelle"
                   [ngModelOptions]="{standalone: true}"
                   #inputs>
                  <button class="icon" (click)="AddMetier()"> <i class="fa fa-plus"></i></button>
                </div>
                <-->
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of MetierPageUpdate | filter : 'libelle' : regexMetier; let i = index ">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" checked (change)="ChangeMetier(item,$event.target.checked)" />
                                    </td>
                                    <td style="padding-right: 70px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                        </div>
                        <div class="bordInterDiv" style="margin-top: 10px;margin-bottom: 6px;">
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of MetierTabFilter">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" (change)="AddMetierToUpdate(item,$event.target.checked)">
                                    </td>
                                    <td style="padding-right: 55px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                            <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                        <div class="marginright" style="float: left;">
                            <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                            <input type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeMetier" [ngModelOptions]="{standalone: true}" (change)="ChangerMetierPageSize()" step="25" min="25" max="100">

                        </div>

                        <div style="width: 200px;">
                            <span style="font-size: 12px;margin-left:6%;"> page {{suivantMetier+1}} sur {{totalPageMetier}}</span>
                            <button class="fleche" (click)="PremiereMetierPage()" [disabled]="suivantMetier===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                  </svg>
                </button>
                            <button class="fleche" (click)="PreviousMetierPage()" [disabled]="suivantMetier===0"><i class="fa fa-angle-left"></i></button>
                            <button class="fleche" (click)="nextMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><i
                    class="fa fa-angle-right"></i>
                  </button>
                            <button class="fleche" (click)="FinalMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><svg width="1em" height="1em"
                    viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </button>
                        </div>
                    </div>
                </div>
                <div class="bordDiv" style="margin-bottom: 50px;">
                    <span style="font-weight: bold;text-align: center;padding-left: 47%;">Clients</span><br>
                    <div style="display: flex;flex-wrap: wrap;">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <input type="text" style="flex: 3;height: 20px;margin-left: 8px;font-size: 14px;" id="client" name="client" placeholder="Filtre..."> <br>

                    </div>
                    <div style="display: flex;justify-content: center;flex-wrap: wrap;">
                        <div class="bordInterDiv" style="float: left;margin-top: 10px;margin-bottom: 6px;">
                            <div style="margin-top: 7px;display: flex;">
                                <input type="text" style="height: 20px;width: 177px; margin-right: 3px;font-size: 14px;" placeholder="Ajouter Client ..." [(ngModel)]="clientUpdate.libelle" [ngModelOptions]="{standalone: true}" #inputs>
                                <div class="icon" style="max-width: 45px;cursor: pointer;padding-left: 5px;" (click)="AddClient()"> <i class="fa fa-plus"></i></div>
                            </div>
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of ClientPageUpdate | filter : 'libelle' : regexClient; let i = index ">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" checked (change)="ChangeClient(item,$event.target.checked)" />
                                    </td>
                                    <td style="padding-right: 70px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                        </div>
                        <div class="bordInterDiv" style="margin-top: 10px;margin-bottom: 6px;">
                            <table style="margin-top: 10px;border: none;">
                                <tr *ngFor="let item of ClientTabFilter">
                                    <td>
                                        <input type="checkbox" style="cursor: pointer;" (change)="AddClientToUpdate(item,$event.target.checked)">
                                    </td>
                                    <td style="padding-right: 55px;">
                                        {{item.libelle}}
                                    </td>

                                </tr>
                            </table>
                            <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                        <div style="float: left;" class="marginright">
                            <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                            <input type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeClient" [ngModelOptions]="{standalone: true}" (change)="ChangerClientPageSize()" step="25" min="25" max="100">

                        </div>

                        <div style="width: 200px;">
                            <span style="font-size: 12px;margin-left:6%;"> page {{suivantClient+1}} sur {{totalPageClient}}</span>
                            <button class="fleche" (click)="PremiereClientPage()" [disabled]="suivantClient===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                  </svg>
                </button>
                            <button class="fleche" (click)="PreviousClientPage()" [disabled]="suivantClient===0"><i class="fa fa-angle-left"></i></button>
                            <button class="fleche" (click)="nextClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><i
                    class="fa fa-angle-right"></i>
                  </button>
                            <button class="fleche" (click)="FinalClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><svg width="1em" height="1em"
                    viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </button>
                        </div>
                    </div>

                </div>

            </div>
            <!-- Grid column -->
        </div>


        <label for="form75" style="font-weight: bold;">Rappel</label>
        <br>
        <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
            <input mdbInput type="text" class="mr-2" style="float:left;flex: 20;" class="form-control" id="inputObjetMD" placeholder="Saisir l'objet du rappel" formControlName="addresse" name="addresse">
            <input mdbInput type="date" style="flex: 2;" class="form-control" placeholder="Sélectionner date" formControlName="addresse2" name="addresse2" [min]="today">

        </div>
        <br>
        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
            <button class="btn btn-primary" type="submit" style="background-color: #BBE2BE; color:black;position:relative;border: 1px solid black;
        font-size: 20px;font-weight: bold">Enregistrer</button>
            <button (click)="CancelAdd()" class="btn btn-secondary" style="cursor: pointer;background-color: rgb(199, 196, 196); color:black;position:relative;border: 1px solid black;
       margin-left:7%;font-size: 20px;font-weight: bold">Annuler</button>
        </div>

    </form>
</div>
<!-- Extended material form grid -->
