import { DatePipe } from '@angular/common';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ConsultantService } from '../services/consultant.service';
import { Router } from '@angular/router';
import { QualiteService } from '../services/qualite.service';
import { DisponibiliteService } from '../services/disponibilite.service';
import { CommunicationService } from '../services/communication.service';
import { Consultant } from '../Model/Consultant';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { Observation } from '../Model/observation';
import {PopupdataComponent} from '../popupdata/popupdata.component'

import { Observable } from 'rxjs';
import {Title} from "@angular/platform-browser";
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { BASE_URL } from '../config';

import * as XLSX from 'xlsx';


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}



export interface Dispo {
  value: string;
  name: string;
}

export interface Quali {
  value: string;
  name: string;
}

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss']
})
export class ListeComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator: MatPaginator;
  public pageSize = 120;
  public currentPage = 1;
  public totalSize = 0;

  consultants: any = [];
  newArray: any = [];
  test: string;

  Dispo: Dispo[] = [];

  Quali: Quali[] = [
    {value:"",name:"Tout"}
  ];
  sortBy:string;
  Clients = [];
  TagTech = [];
  TagMetier = [];
  TagFonc = [];
  Tech = [];
  shownext=false;
  showprevious=false
  showlastpage=false;
  showfirstpage=false
  filtest: boolean = false;
  showfilter=false
  totalElements: number = 0;
  ConsultantPage: Consultant[] = [];
  regex: string = "";
  loading: boolean;
  suivant = 0;
  size: number = 25;
  title=""
  totalPage: number;

  isExporting : boolean  = false;


    constructor(public us: ConsultantService, public communicationService: CommunicationService,private SpinnerService: NgxSpinnerService,
                private router: Router, private token: TokenStorageService, private titleService:Title,
                public ts: QualiteService, public ds: DisponibiliteService,private dialog: MatDialog, private datePipe: DatePipe) {
      this.communicationService.Broadcast('OnChild1', 'Liste des consultants');

     }


      isquali = false;
      isnom = false;
      isprenom = false;
      istitre = false;
      istech = false;
      isdispo = false;
      isclient = false;
      isemail = false;
      iscode_postal = false;
      istelephone_principal = false;
      ispostal = false;


        //Couleur
    isQualiteFilter = false;
    isNomFilter  = false;
    isPrenomFilter = false;
    isTitreFilter = false;
    isTechFilter = false;
    isClientFilter = false;
    isExpFilter = false;
    isDispoFilter = false;
    isEmailfilter = false;
    isCodePostalFilter = false;
    isTelFilter = false;
    isDateFilter=false;

      qualitecv="";
      isdmaj = false;
      nbrfilter = 0;
      paginationType=0;
      tempdata:any
      totalconsultant=0;
      dispoFilter = new FormControl("");
      qualiFilter = new FormControl();
      clientFilter = new FormControl("");
      tagtechFilter = new FormControl("");
      tagmetierFilter = new FormControl("");
      tagfoncFilter = new FormControl("");
      observationFilter = new FormControl("");
      techFilter = new FormControl("");
      nameFilter = new FormControl("");
      prenomFilter = new FormControl("");
      titreFilter = new FormControl("");
      emailFilter = new FormControl("");
      telephone_principalFilter = new FormControl("");
      code_postalFilter = new FormControl("");
      date_derniere_majFilter = new FormControl("");
      globalFilter = '';


      titredesc=false
      techdesc=false
      clientdesc=false
      postaldesc=false
      trigdesc=false
      emaildesc=false
      nomdesc=false
      prenomdesc=false
      qualitedesc=false
      styleState=false
      teldesc=false
      modifdesc=false
      obsdesc=false
      filteredValues = {
        _id: '',
        nom: '',
        prenom: '',
        titre: '',
        code_dispo: '',
        qualite: '',
        tjm: '',
        telephone_principal: '',
        email: '',
        linkedin: '',
        code_postal: '',
        ville: '',
        cv: '',
        dateDerniereMaj: '',
        tech: '',
        client: '',
        observation: '',
        tagtech: '',
        tagmetier: '',
        tagfonc: '',
      };
      datedesc


    //Order

    nameOrder='asc';
    prenomOrder='asc';
    qualiteOrder='asc';
    emailOrder='asc';
    titreOrder='asc';
    techOrder='asc';
    telOrder='asc';
    clientOrder='asc';
    postalOrder='asc';
    orderdesc="dateDerniereMaj";
    orderasc="ggg";

    pageSizeElement = [30,60,120]
    width;
    height;

    userid: string  = "";
    favoris: string[] = [];

    ngOnInit() {
      this.loader()
      const user = this.token.getUser();
      this.userid = user.id;
      this.getrhFavoris();
      let {width,height} = window.screen;
      this.width = width;
      this.height = height;
      if(height>=810*0.9 && height<=810*1.1 ){
        this.pageSizeElement = [25,50,100,120]
        this.pageSize=120
      }
      else if(height>=810){
        this.pageSizeElement =  [30,60,120]
        this.pageSize=120
      }

      this.us.orderConsultantsClient().subscribe((data)=>{
        if(data===1){
          this.resetFilter()
          window.scroll(0,0);
          this.titleService.setTitle("Portail RH")


          this.Search();

          this.getDisponibilite();
          this.getQualite();

        }
      })
     }

    getrhFavoris(){
      this.us.getUserFavoris(this.userid).subscribe(
        data => {
          console.log("favoris", data)
          this.favoris = [...data]
        },
        error => console.log(error)
      )
    }


emitPagination(e){
this.gotoTop()
this.currentPage=e.pageIndex
this.pageSize=e.pageSize
this.currentPage=this.currentPage+1
this.goto(e.pageIndex+1);



  //  this.iterator();
}



    Select(row) {
     // this.router.navigate(['/fichec'], { queryParams: { id: row._id } });
      window.open(BASE_URL+"/fichec?id="+row._id,"_blank")
  }

  openLink(link: string) {
    window.open(link,"_blank")
  }

  getDisponibilite() {
    this.ds.getRefs().subscribe((data2: {}) => {
  // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Dispo.push({ value: data2[i].libelle, name: data2[i].libelle });
    }
    });
  }
  getQualite() {
    this.ts.getRefs().subscribe((data2: {}) => {
  // tslint:disable-next-line: forin
      for (const i in data2) {
        this.Quali.push({ value: data2[i].libelle, name: data2[i].libelle });
    }
    });
  }

  selectedRow: any;

  selectedRowIndex: number;

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  activeColumn: string;
    keyupnom() {
      console.log('key up !!!');
    }
    sort(e){
      this.sortBy=e
      if(e==="tech"){
        this.techdesc=!this.techdesc
        if(this.techdesc){
          this.orderasc = 'postakl';
          this.orderdesc='tech'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'tech';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.postaldesc=false
        this.trigdesc=false
        this.trigdesc=false

        this.teldesc=false
        this.qualitedesc=false

      }
            if(e==="postal"){
        this.postaldesc=!this.postaldesc
        if(this.postaldesc){
          this.orderasc = 'postakl';
          this.orderdesc='postal'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'postal';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.qualitedesc=false
        this.techdesc=false
        this.trigdesc=false

      }
      if(e==="email"){
        this.emaildesc=!this.emaildesc
        if(this.emaildesc){
          this.orderasc = 'ema5il';
          this.orderdesc='email'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'email';
          this.orderdesc='ema5il'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.teldesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.trigdesc=false
      }
      if(e==="nom"){
        this.nomdesc=!this.nomdesc
        if(this.nomdesc){
          this.orderasc = 'nokm';
          this.orderdesc='nom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'nom';
          this.orderdesc='nomm'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.techdesc=false
        this.emaildesc=false
        this.postaldesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.qualitedesc=false
        this.trigdesc=false

      }
      if(e==="prenom"){
        this.prenomdesc=!this.prenomdesc
        if(this.prenomdesc){
          this.orderasc = 'postakl';
          this.orderdesc='prenom'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'prenom';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.techdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.postaldesc=false
        this.teldesc=false
        this.qualitedesc=false
        this.trigdesc=false

      }
      if(e==="client"){
        this.clientdesc=!this.clientdesc
        if(this.clientdesc){
          this.orderasc = 'nokm';
          this.orderdesc='listClient'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'listClient';
          this.orderdesc='clientm'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.emaildesc=false
        this.techdesc=false
        this.postaldesc=false
        this.qualitedesc=false
        this.teldesc=false
        this.trigdesc=false
      }
      if(e==="titre"){
        this.titredesc=!this.titredesc
        if(this.titredesc){
          this.orderasc = 'titre5';
          this.orderdesc='titre'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'titre';
          this.orderdesc='qua55lite'
          this.SearchTriFiltrage()
        }

        this.clientdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.teldesc=false
        this.trigdesc=false
      }
      if(e==="qualite"){
        this.qualitedesc=!this.qualitedesc
        if(this.qualitedesc){
          this.orderasc = 'null';
          this.orderdesc='qualitecv.ordre'
          this.SearchTriFiltrage()
        }else{

          this.orderasc = 'qualitecv.ordre';
          this.orderdesc='null'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.techdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.teldesc=false
        this.postaldesc=false
        this.trigdesc=false
      }
      if(e==="tel"){
        this.teldesc=!this.teldesc
        if(this.teldesc){
          this.orderasc = 'postakl';
          this.orderdesc='telephone_principal'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'telephone_principal';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.techdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.trigdesc=false
      }

      if(e==="date"){
        this.datedesc=!this.datedesc
        if(this.datedesc){
          this.orderasc = 'postakl';
          this.orderdesc='dateDerniereMaj'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'dateDerniereMaj';
          this.orderdesc='po5stal'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.techdesc=false
        this.clientdesc=false
        this.emaildesc=false
        this.nomdesc=false
        this.prenomdesc=false
        this.qualitedesc=false
        this.trigdesc=false
      }

      if(e==="trigrh"){
        this.trigdesc=!this.trigdesc
        if(this.emaildesc){
          this.orderasc = 'ema5il';
          this.orderdesc='trigrh'
          this.SearchTriFiltrage()
        }else{
          this.orderasc = 'trigrh';
          this.orderdesc='ema5il'
        this.SearchTriFiltrage()
        }
        this.titredesc=false
        this.clientdesc=false
        this.techdesc=false
        this.postaldesc=false
        this.nomdesc=false
        this.teldesc=false
        this.prenomdesc=false
        this.qualitedesc=false
      }

    }

    yvesColorEltSearch(index,data){

      data[index]['nom'] =  (this.nameFilter.value != "")? data[index]['nom'].replace(new RegExp('('+this.escapeRegExp(this.nameFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['nom']
      data[index]['prenom'] =  ( this.prenomFilter.value != "")? data[index]['prenom'].toString().replace(new RegExp('('+this.escapeRegExp(this.prenomFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :  data[index]['prenom']
      data[index]['titre'] =  ( this.titreFilter.value != "")?  data[index]['titre'].toString().replace(new RegExp('('+this.escapeRegExp(this.titreFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>")  : data[index]['titre']
      data[index]['tech'] =  (this.techFilter.value != "")?  data[index]['tech'].toString().replace(new RegExp('('+this.escapeRegExp(this.techFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['tech']
       data[index]['email'] =  (this.emailFilter.value != "")? data[index]['email'].toString().replace(new RegExp('('+this.escapeRegExp(this.emailFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['email']
       data[index]['listClient'] =  (this.clientFilter.value != "")?data[index]['listClient'].toString().replace(new RegExp('('+this.escapeRegExp(this.clientFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['listClient']
       data[index]['derniereObs'] =  (this.observationFilter.value != "")?  data[index]['derniereObs'].toString().replace(new RegExp('('+this.escapeRegExp(this.observationFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") : data[index]['derniereObs']
       data[index]['dateDerniereMaj'] =  (this.date_derniere_majFilter.value != "")? data[index]['dateDerniereMaj'].toString().replace(new RegExp('('+this.escapeRegExp(this.date_derniere_majFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['dateDerniereMaj']
       data[index]['code_postal'] =  (this.code_postalFilter.value != "")? data[index]['code_postal'].toString().replace(new RegExp('('+this.escapeRegExp(this.code_postalFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['code_postal']
   data[index]['telephone_principal'] =  (this.telephone_principalFilter.value != "")? data[index]['telephone_principal'].toString().replace(new RegExp('('+this.escapeRegExp(this.telephone_principalFilter.value)+')', 'gi'),"<strong class='strong'>$1</strong>") :data[index]['telephone_principal']

   }

   escapeRegExp(s) {
    if(s!=null){
     return s.replace(/[\\^$.*+?()[\]{}|]/g,"\\$&");

    }
    return ""
 }
    getUsers2(data2) {

      if(this.totalconsultant<=data2.total){
        this.totalconsultant=data2.total
      }
      this.consultants = [];
      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();
            console.log("data2",data2)
            this.consultants = data2.consultants;

            // tslint:disable-next-line: forin
                  for (const i in data2.consultants) {
                    let cli = new Array();
                    let techno = new Array();
                    let obsrv = new Array();
                    for(const j in data2.consultants[i].client){
                      cli.push(data2.consultants[i].client[j].libelle);
                    }
                    for(const j in data2.consultants[i].tech){
                      techno.push(data2.consultants[i].tech[j].libelle);
                    }
                    for(const j in data2.consultants[i].observation){
                      obsrv.push(data2.consultants[i].observation[j].libelleObs);
                    }

                    let cl = "";
                    let tech = "";
                    let obs = "";
                    cl = cli.join(", ");
                    tech = techno.join(", ");
                    obs = obsrv.join(",");


                    this.consultants[i].client = cl;
                    this.consultants[i].tech = tech;
                    //this.consultants[i].observation = obs;

                    // if(this.consultants[i].observation && this.consultants[i].observation.length > 0){
                    //   let obstab: any[] = [];
                    //   obstab = [...this.consultants[i].observation]

                    //   //Classement des Observations de la plus récente à la plus ancienne
                    //   obstab = obstab.sort(function(a,b){
                    //     if(new Date(a.dateObs) > new Date(b.dateObs)) return -1;
                    //     if(new Date(a.dateObs) < new Date(b.dateObs)) return 1;
                    //     return 0;
                    //   })
                    //   this.consultants[i].trig = obstab[0].trigrammeObs ;
                    // } else {
                    //   this.consultants[i].trig = "";
                    // }

                    this.test = this.consultants.length;
                    this.yvesColorEltSearch(i,this.consultants)

                }
                this.dialog.closeAll()

                this.totalSize = data2.total;
                this.totalPage = data2.totalPage

  }

    getByName(nom,prenom,dispo,qualite,email,postal,titre,client,tech,tel,date,page) {

      this.consultants = [];

      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();
      this.us.getConsultants(nom,prenom,dispo,qualite,email,postal,titre,tel,client,tech,date,page-1,this.pageSize,'','',this.orderdesc,this.orderasc
      ).subscribe((data2) => {


       this.getUsers2(data2)

      });
    }

    gotopage(nom,prenom,dispo,qualite,email,postal,titre,tel,client,tech,date,page) {

      this.gotoTop()

      this.consultants = [];

      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(2000);
        dialogRef.close();
      })();
      this.us.getConsultants(nom,prenom,dispo,qualite,email,postal,titre,client,tech,date,tel,page,this.pageSize,'','',this.orderdesc,this.orderasc).subscribe((data2) => {

       this.getUsers2(data2)

      });
    }
    Search(){
      this.styleState=true

      if(
        this.filteredValues.qualite==='Tout'
        ){
        this.getByName(
          this.nameFilter.value,
          this.prenomFilter.value,
          this.dispoFilter.value,
          'e',
          this.emailFilter.value,
          this.code_postalFilter.value,
          this.titreFilter.value,
            this.clientFilter.value,
          this.techFilter.value,
          this.telephone_principalFilter.value,
          this.date_derniere_majFilter.value,
          this.currentPage
          )
      } else{
        this.getByName(
          this.nameFilter.value,
          this.prenomFilter.value,
          this.dispoFilter.value,
          this.qualitecv,

          this.emailFilter.value,
          this.code_postalFilter.value,
          this.titreFilter.value,
            this.clientFilter.value,
          this.techFilter.value,
          this.telephone_principalFilter.value,
          this.date_derniere_majFilter.value,

          this.currentPage
          )
      }
        this.paginationType=1;
    }

    SearchTriFiltrage(){
      this.showfilter=true
      this.currentPage=1
      this.styleState=true
      if(
        this.filteredValues.qualite==='Tout'
        ){
        this.getByName(
          this.nameFilter.value,
          this.prenomFilter.value,
          this.dispoFilter.value,
          'e',
          this.emailFilter.value,
          this.code_postalFilter.value,
          this.titreFilter.value,
            this.clientFilter.value,
          this.techFilter.value,
          this.telephone_principalFilter.value,
          this.date_derniere_majFilter.value,

          1
          )
      } else{
        this.getByName(
          this.nameFilter.value,
          this.prenomFilter.value,
          this.dispoFilter.value,
          this.qualitecv,

          this.emailFilter.value,
          this.code_postalFilter.value,
          this.titreFilter.value,
            this.clientFilter.value,
          this.techFilter.value,
          this.telephone_principalFilter.value,
          this.date_derniere_majFilter.value,

          1
          )
      }

        this.paginationType=1;
    }

    filterData(data: any[]): any[] {
      return data.map(item => ({
        Nom: item.nom,
        Prenom: item.prenom,
        Email: item.email,
        Telephone: item.telephone_principal,
        Titre: item.titre,
        Technologies: item.tech,
        LinkedIn: item.linkedin,
        Clients: item.client,
        Code_postal: item.postal,
        Trigramme : item.trigrh,
        Derniere_MAJ: item.dateDerniereMaj
      }));
    }

    exportToExcel(data: any[], filename: string): void {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, filename);
    }

    exportData() {
      if(this.isExporting) return ;

      this.isExporting = true;
      this.us.getConsultantsForExportation(
        this.nameFilter.value,
        this.nameFilter.value,
        this.dispoFilter.value,
        this.filteredValues.qualite==='Tout'? 'e' : this.qualitecv,
        this.emailFilter.value,
        this.code_postalFilter.value,
        this.titreFilter.value,
        this.telephone_principalFilter.value,
        this.clientFilter.value,
        this.techFilter.value,
        this.date_derniere_majFilter.value,
        '',
        '',
        '',
        '',
        this.orderdesc,
        this.orderasc
      ).subscribe(
        (data : any[]) => {
          data.forEach(element => {
            let cli = new Array();
            let techno = new Array();

            element.client.forEach(client => {
              cli.push(client.libelle);
            });

            element.tech.forEach(tech => {
              techno.push(tech.libelle);
            });

            let cl = "";
            let tech = "";
            cl = cli.join(", ");
            tech = techno.join(", ");

            element.client = cl;
            element.tech = tech;
          });

          const filteredData = this.filterData(data);

          const currentDate = new Date();
          const date = this.datePipe.transform(currentDate, 'yyyyMMdd-HHmm') || '';

          this.exportToExcel(filteredData, date+'_consultants.xlsx');
          this.isExporting = false;
        },
        error => {
          this.isExporting = false;
        }
      );
    }

    resetFilter(){
      this.qualitecv="";
      this.dispoFilter = new FormControl("");
      this.qualiFilter = new FormControl();
      this.clientFilter = new FormControl("");
      this.tagtechFilter = new FormControl("");
      this.tagmetierFilter = new FormControl("");
      this.tagfoncFilter = new FormControl("");
     this.observationFilter = new FormControl("");
      this.techFilter = new FormControl("");
      this.nameFilter = new FormControl("");
      this.prenomFilter = new FormControl("");
      this.titreFilter = new FormControl("");
      this.emailFilter = new FormControl("");
      this.telephone_principalFilter = new FormControl("");
      this.code_postalFilter = new FormControl("");
      this.date_derniere_majFilter = new FormControl("");
      this.filteredValues.nom=""
      this.filteredValues.prenom=""
      this.filteredValues.code_dispo=""
      this.filteredValues.qualite=""
      this.filteredValues.email=""
      this.filteredValues.code_postal=""
      this.filteredValues.titre=""
      this.filteredValues.client=""

      this.filteredValues.telephone_principal=""
      this.filteredValues.dateDerniereMaj=""
      this.filteredValues.observation=""
      this.filteredValues.tech=""
      this.isquali = false;
      this.isnom = false;
      this.isprenom = false;
      this.istitre = false;
      this.istech = false;
      this.isdispo = false;
      this.isclient = false;
      this.ispostal = false;
      this.isdmaj = false;
      this.isemail = false;
      this.iscode_postal = false;
      this.istelephone_principal = false;
      this.showfilter=false
      this.Search()
    }

    openPopup(e,data){
      e.preventDefault()
      const dialogRef = this.dialog.open(PopupdataComponent, {
       data: {
         message:data,
       },
     });

    }

    loader(){
      this.dialog.open(ProgressSpinnerDialogComponent, {
        data: {

          message: `
           Chargement en cours`

        },
        disableClose: true,
      });
    }
    gotoTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    Truncate(text){
      return text.substr(0,40)
    }
    TruncateObs(text){
      return text.substr(0,40)
    }



    goto(page) {
      if(parseInt(page)){

        if(page-1 <= this.totalPage) {

          this.currentPage=page
          this.Search()

        }
        else {
          let total = this.totalPage+1
          this.Alert("il n'existe que " + total+" pages")
      }
      }

      else {
        this.Alert("Page invalide")
    }
      }
    Alert(msg) {
      const dialogRef = this.dialog.open(AlertEmptyComponent, {
        data: {
          message: msg,
          buttonText: {
            cancel: 'OK'
          }
        },
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    NexPage(){
      this.currentPage = this.currentPage-1
      this.currentPage = this.currentPage+2
      this.Search()
    }
    previousPage(){
      this.currentPage = this.currentPage-1
      this.Search()
    }
    FirstPage(){
      this.currentPage =1
      this.showprevious=false;
      this.showfirstpage=false;

      this.shownext=true;
      this.showlastpage=true;
      this.Search()
    }
    renderCp(data){
      if(data){
        return data
      }else{
        return ""
      }
    }
    LastPage(){
      this.currentPage = this.totalPage+1
      this.shownext=true;
      this.showlastpage=true;
      this.showprevious=false;
      this.showfirstpage=false;
      if(this.totalPage+1===this.currentPage){
        this.shownext=false
        this.showlastpage=false
      }
      this.Search()
    }

    getNext(){
      if(this.currentPage<this.totalPage+1){
        return true
      }return false
    }
    getpreviousMove(){
      if(this.currentPage>1){
        return true
      }return false
    }
    getPrevious(){
      if(this.currentPage>1){
        return true
      }return false
    }

    getlastpage(){
      if(this.currentPage<this.totalPage+1){
        return true
      }return false
    }
    ChangeItemPerPage(e){
      this.pageSize=e
      this.SearchTriFiltrage()
    }


    TruncateTitre(text){
      return text.substr(0,26)
    }
    TruncateIntitule(text){
      return text.substr(0,26)
    }

    renderPrenom(prenom){
      return prenom[0].toUpperCase()+prenom.slice(1).toLowerCase()
    }

     stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

setdata(e: string, event: any) {
  let k = event.target;
  this.title = e;
  if (k) {
    if (k.scrollWidth > k.clientWidth) {
      k.style.backgroundColor = "#FFFFCC";

      k.setAttribute("title", e);
      this.open(event);
    }
  }
}

      open(e: any) {

        const ele = document.getElementById('element');
        const menu = document.getElementById('menu');
        if (ele != null && menu != null) {
          e.preventDefault();

          const rect = ele.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;

          // Set the position for menu
          menu.style.top = `${y + 300}px`;
          menu.style.left = `${x}px`;

          // Show the menu
          menu.classList.remove('hidden');
        }
      }
 hover() {
        const ele = document.getElementById('element');
        const menu = document.getElementById('menu');
        if (ele != null && menu != null) {
          ele.addEventListener('context', function (e: any) {
            e.preventDefault();

            const rect = ele.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            // Set the position for menu
            menu.style.top = `${y + 40}px`;
            menu.style.left = `${x}px`;

            // Show the menu
            menu.classList.remove('hidden');

            document.addEventListener('click', documentClickHandler);
          });

          // Hide the menu when clicking outside of it
          const documentClickHandler = function (e: any) {
            const isClickedOutside = !menu.contains(e.target);
            if (isClickedOutside) {
              menu.classList.add('hidden');
              document.removeEventListener('click', documentClickHandler);
            }
          };
        }
 }
 close(e: any) {
  e.target.style.backgroundColor="transparent";

    const menu = document.getElementById('menu');
    if (menu != null) {
      const isClickedOutside = !menu.contains(e.target);
      if (isClickedOutside) {
        menu.classList.add('hidden');
      }
    }
  }

}


